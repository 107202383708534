import React from 'react';
import PropTypes from 'prop-types';
import { LinkIcon } from 'UI/CustomIcon/CustomIcon';
import Thumbnail from 'UI/Thumbnail/Thumbnail';
import { Typography, Space } from 'antd';
import ActionGroup from 'UI/ActionGroup/ActionGroup';
import { EXPORT_POPOVER_SOURCE } from 'constants/constants';
import KeyValueWithIcon from '../KeyValueWithIcon/KeyValueWithIcon';

const { Text } = Typography;

// const dataList = [
//   { key: 'Year', value: <Text type="secondary">Jun 2019 – Nov 2019</Text>, icon: <YearIcon width="15px" fill="#697390" /> },
//   { key: 'Location', value: <Text>Bangalore</Text>, icon: <LocationIcon width="15px" fill="#697390" /> },
//   {
//     key: 'Location', value: <Space split={<Divider type="vertical" />} size={2}>
//       <Text>mailchimp.com</Text>
//       <Space size={6}>
//         <LinkedinIcon width="15px" fill="#5753B5" />
//         <TwitterIcon width="15px" fill="#5753B5" />
//         <FacebookIcon width="15px" fill="#5753B5" />
//       </Space>
//     </Space>, icon: <SocialLinksIcon width="15px" fill="#697390" />,
//   },
// ];

const singleaction = [
  {
    icons: [LinkIcon],
    toggle: false,
    actions: 'ADD_TO_LIST',
    onClick: () => {},
    tooltip: true,
    title: 'Add to Smart List',
  },
];

const QuickViewCard = ({
  titleIcon,
  title,
  subTitle,
  isActionBtn,
  isSingleActionBtn,
  content,
  actions,
}) => {
  return (
    <div className="card-block" style={{ width: '100%' }}>
      <div className="card-header">
        <div className="icon-holder">
          <Thumbnail.Icon
            size={titleIcon.size}
            Icon={titleIcon.icon}
            backgroundColor={titleIcon.backgroundColor}
            iconColor={titleIcon.iconColor}
          />
        </div>
        <div className="title-holder">
          <Space direction="vertical" size={4}>
            <Text
              strong
              size={5}
              style={{ width: 200 }}
              ellipsis={{
                tooltip: title,
              }}
            >
              {title}
            </Text>
            <Text>{subTitle}</Text>
          </Space>
        </div>
        {isActionBtn && !isSingleActionBtn && (
          <div>
            <ActionGroup actions={actions} source={EXPORT_POPOVER_SOURCE.QUICKVIEW} />
          </div>
        )}
        {isActionBtn && isSingleActionBtn && (
          <div>
            <ActionGroup actions={singleaction} source={EXPORT_POPOVER_SOURCE.QUICKVIEW} />
          </div>
        )}
      </div>
      {content && content.length ? (
        <div className="card-content">
          <div className="table-info-block">
            <Space direction="vertical" size={10}>
              {content.map((item) => (
                <KeyValueWithIcon key={item.value} item={item} />
              ))}
            </Space>
          </div>
        </div>
      ) : null}
    </div>
  );
};

QuickViewCard.propTypes = {
  titleIcon: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isActionBtn: PropTypes.bool,
  isSingleActionBtn: PropTypes.bool,
  content: PropTypes.array,
  actions: PropTypes.array,
};

QuickViewCard.defaultProps = {
  titleIcon: {},
  title: '',
  subTitle: '',
  isActionBtn: true,
  isSingleActionBtn: false,
  content: [],
  actions: [],
};

export default QuickViewCard;

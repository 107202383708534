/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { baseURL, endPoints } from 'api/apiEndpoints';
import { postRequest } from 'api/apiRequests';
import { getLoggedInUser, headerGenerator } from 'utils/common';
import PropTypes from 'prop-types';
import { showError } from './errorMessage';

const PresignedButton = ({ element, url, isElementFunction = false, disabled = false }) => {
  const user = getLoggedInUser();
  const headers = headerGenerator(user.token, user.session_id);

  const getPrivateUrl = async () => {
    const reqUrl = `${baseURL}${endPoints.generatePrivateUrl}`;
    const data = {
      s3_url: url,
    };
    const response = await postRequest(reqUrl, data, headers);
    if (response.error) {
      showError(response.message);
    }
    if (response.presigned_url) {
      window.open(response.presigned_url);
    }
  };

  return (
    <div
      style={{ display: 'inline' }}
      onClick={() => {
        if (!disabled) {
          getPrivateUrl();
        }
      }}
    >
      {isElementFunction && element ? element.func(() => getPrivateUrl) : element}
    </div>
  );
};

PresignedButton.propTypes = {
  element: PropTypes.object,
  url: PropTypes.string,
  isElementFunction: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default PresignedButton;

import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Space, Typography, Upload } from 'antd';
import { showError } from 'components/common/errorMessage';
import { baseURL, endPoints } from 'api/apiEndpoints';
import { useSelector } from 'react-redux';
import { headerGenerator } from 'utils/common';

const { Text, Link, Title } = Typography;

const AddLeads = ({
  icon,
  title,
  style = {},
  direction = 'vertical',
  link,
  uploadText = 'Upload',
  type,
  onFileUpload,
  data,
}) => {
  const user = useSelector((state) => state.auth.user);
  const headers = headerGenerator(user.token, user.session_id);
  const beforeUpload = (file) => {
    // const isCSV = ['text/csv', 'application/vnd.ms-excel'].includes(file.type);
    const isCSV = file.name.split('.');
    if (!(isCSV && isCSV[isCSV.length - 1] === 'csv')) {
      showError('Please upload csv file only!');
    }
    const allowedFileSize = file.size / 1024 / 1024 < 20;
    if (!allowedFileSize) {
      showError('File should be smaller than 20MB!');
    }
    return isCSV && allowedFileSize;
  };
  const titleStyle =
    direction === 'vertical'
      ? {}
      : {
          flex: '1',
          marginLeft: '10px',
        };

  return (
    <div
      style={{
        background: '#FAFAFB',
        width: '248px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: direction === 'vertical' ? 'space-evenly' : 'space-around',
        flexDirection: 'column',
        padding: direction === 'vertical' ? '5px' : '5px 15px',
        position: 'relative',
        border: '1px dashed #929CB7',
        borderRadius: '5px',
        ...style,
      }}
    >
      {icon}
      <Title
        style={{
          textAlign: direction === 'vertical' ? 'center' : 'left',
          ...titleStyle,
        }}
        className="no-margin"
        level={5}
      >
        {title}
        <br />
        {type === 'file' ? (
          <Link
            style={{ fontSize: '14px', position: 'relative', zIndex: 1 }}
            type="link"
            href={link}
            target="_blank"
          >
            Sample CSV
          </Link>
        ) : (
          <Text style={{ color: '#929CB7', fontSize: '14px' }}>Click below</Text>
        )}
      </Title>
      <div>
        <Space>
          {type === 'file' ? (
            <>
              <Text type="secondary" className="ant-upload-hint">
                Drag and Drop (OR)
              </Text>
            </>
          ) : null}
          <div className="dummy-btn">{uploadText}</div>
        </Space>
      </div>
      {type === 'file' ? (
        <Upload
          action={`${baseURL}${endPoints.listFileUpload}`}
          headers={{
            'x-auth-token': headers['x-auth-token'],
            session_id: headers.session_id,
          }}
          data={data}
          beforeUpload={beforeUpload}
          showUploadList={false}
          name="file"
          accept=".csv"
          className="upload-block"
          onChange={onFileUpload}
        >
          <Button className="upload-btn">Upload</Button>
        </Upload>
      ) : (
        <Link className="upload-block" type="link" href={link}>
          Sample CSV
        </Link>
      )}
    </div>
  );
};

AddLeads.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  style: PropTypes.object,
  direction: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.string,
  uploadText: PropTypes.string,
  onFileUpload: PropTypes.func,
  data: PropTypes.object,
};

export default AddLeads;

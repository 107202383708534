/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Card, Input, Layout, Skeleton, Space, Table, Tooltip, Typography } from 'antd';
import Thumbnail from 'UI/Thumbnail';
import { CompanyIcon } from 'UI/CustomIcon/CustomIcon';
import Link from 'UI/Link';
import ShowTotal from 'UI/ShowTotal';
import { Spinner } from 'components/common/loader';
import { createDummyData, getPlanConfig } from 'utils/common';
import { TechnologyService } from 'api/services';
import { DUMMY_COMPANY_DATA, GLOBAL_SEARCH_CHAR_LIMIT } from 'constants/constants';
// import { eventActions, eventList, eventManager } from 'utils/eventManager';
import TechPsychContext from '../TechPsychContext';
import CompanyFilterContainer from './CompanyFilterContainer';
import { timeConverter } from './helper';
import { showExtraLabels as showExtralabels } from './common';

const { Text } = Typography;
const PAGE_SIZE = 25;
const { getTechnologyCompaniesListData, fetchTechnologyCategoryList } = TechnologyService;

export const checkIsSortOrderChanged = (newSorting, selectedSort, selectedOrder) => {
  const isOrderEqual = newSorting.order === selectedOrder;
  const isKeyEqual = newSorting.column?.key === selectedSort;

  if (!isOrderEqual) {
    return true;
  }
  if (isKeyEqual) {
    return false;
  }
  return true;
};
// TODO: add loader and no data
const Companies = ({ type }) => {
  const { data: contextData, openQuickView } = useContext(TechPsychContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortData, setSortData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [shimmers, setShimmers] = useState(false);
  const [filterData, setFilterData] = useState({
    company_location: [],
    company_size: [],
    categories: [],
  });
  const [data, setData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const newData = useCallback(() => {
    return shimmers ? createDummyData(DUMMY_COMPANY_DATA) : data;
  }, [shimmers, data]);

  const columns = [
    {
      title: 'COMPANY',
      dataIndex: 'company_name',
      key: 'company_name',
      width: 225,
      render: (item, record, rowIndex) => {
        return shimmers ? (
          <div className="list-view-title">
            <Skeleton.Avatar active shape="square" className="skeleton-active-avatar" />
            <Skeleton.Input size="small" active />
          </div>
        ) : (
          <div className="list-view-title">
            <Link
              style={{ padding: 0 }}
              text={getThumbnail(record)}
              onClick={() => openQuickViewHandler(record, rowIndex)}
            />
            <Link
              onClick={() => openQuickViewHandler(record, rowIndex)}
              type="text"
              style={{ padding: 0 }}
              text={
                <Tooltip
                  mouseEnterDelay={0.3}
                  placement="topLeft"
                  title={record._source.company_name}
                >
                  <Space direction="vertical" size={0} align="start">
                    <Text
                      style={{
                        maxWidth: '150px',
                        color: '#29275f',
                      }}
                      ellipsis
                    >
                      {record._source.company_name}
                    </Text>
                    <Text
                      style={{
                        fontSize: '12px',
                        color: '#697390',
                        fontWeight: '10',
                        position: 'relative',
                        top: '-6px',
                      }}
                      ellipsis
                    >
                      {`${record._source.city || ''}${
                        record._source.country
                          ? `${record._source.city ? ', ' : ''} ${record._source.country}`
                          : ''
                      }`}
                    </Text>
                  </Space>
                </Tooltip>
              }
            />
          </div>
        );
      },
    },
    {
      title: 'SIZE',
      dataIndex: 'company_size',
      key: 'max_employee_count',
      width: 100,
      sorter: true,
      render: (item, record) =>
        shimmers ? (
          <Skeleton.Input size="small" style={{ width: 150 }} active />
        ) : (
          record._source.company_size
        ),
    },
    {
      title: 'CATEGORIES',
      dataIndex: 'categories',
      key: 'categories',
      width: 150,
      render: (item, record) =>
        shimmers ? (
          <Skeleton.Input size="small" style={{ width: 150 }} active />
        ) : (
          showExtralabels(record._source.categories)
        ),
    },
    {
      title: 'SOURCES',
      dataIndex: 'sources',
      key: 'sources',
      width: 150,
      render: (item, record) =>
        shimmers ? (
          <Skeleton.Input size="small" style={{ width: 150 }} active />
        ) : (
          showExtralabels(record._source.sources)
        ),
    },
    {
      title: 'CONFIDENCE SCORE(%)',
      key: 'confidence_score',
      dataIndex: 'confidence_score',
      width: 110,
      render: (item, record) =>
        shimmers ? (
          <Skeleton.Input size="small" style={{ width: 150 }} active />
        ) : (
          record._source.confidence_score
        ),
    },
    {
      title: 'LAST DETECTED ON',
      key: 'last_detected',
      dataIndex: 'last_detected',
      width: 100,
      render: (item, record) =>
        shimmers ? (
          <Skeleton.Input size="small" style={{ width: 150 }} active />
        ) : (
          timeConverter(record._source.last_detected)
        ),
    },
  ];

  useEffect(() => {
    getCategoryListData();
  }, []);
  useEffect(() => {
    getData();
  }, [filterData, currentPage, searchTerm, sortData]);

  const getData = async () => {
    setShimmers(true);
    const sortConfig = { ascend: 'asc', descend: 'desc' };
    try {
      const params = {
        id: contextData.company_id?.value,
        limit: PAGE_SIZE,
        offset: (currentPage - 1) * PAGE_SIZE,
        sort_key: sortData.selectedSort ?? 'confidence_score',
        sort_order: sortConfig[sortData.selectedOrder] ?? 'desc',
      };
      if (searchTerm) {
        params.q = searchTerm;
      }
      Object.entries(filterData).forEach(
        ([key, item]) =>
          item.length && (params[`${key}[]`] = item.map((value) => encodeURIComponent(value)))
      );

      const response = await getTechnologyCompaniesListData(params);
      setData(response.data.hits);
      setTotal(response.data.total);
      // setData(sdata.data.hits);
      // setTotal(sdata.data.total);
    } catch (e) {
      // handle error
    }
    setShimmers(false);
  };

  const getCategoryListData = async () => {
    try {
      const params = {
        id: contextData.company_id?.value,
      };
      const response = await fetchTechnologyCategoryList(params);
      setCategoryList(
        (response.data?.data || []).filter((item) => item.key).map((item) => item.key)
      );
    } catch (e) {
      // handle error
    }
    setIsLoading(false);
  };

  // TODO: check console error for text expecting string; getting object
  const getThumbnail = (record) => {
    return record?.company_profile_image_url ? (
      <Thumbnail.Image size="small" imageUrl={record?.company_profile_image_url} />
    ) : (
      <Thumbnail.Icon size="small" Icon={CompanyIcon} />
    );
  };

  // TODO: check this part for responsiveness
  const getHeight = () => {
    const containerBottomMargin = 16;
    const otherContentArea = 200;
    const changedHeight = otherContentArea + 98 + 100 + containerBottomMargin;

    const height = `calc(100vh - ${changedHeight}px)`;
    return height;
  };

  const planConfig = getPlanConfig();
  const pagination = {
    current: currentPage,
    position: ['bottomLeft'],
    total: total >= planConfig.total ? planConfig.total : total,
    showSizeChanger: false,
    responsive: true,
    pageSize: planConfig.pageSize,
    showTotal: (tableTotal, range) => <ShowTotal total={total} type="Companies" range={range} />,
    defaultCurrent: 1,
    onChange: (page, pageSize) => setCurrentPage(page),
  };

  const handleFilterChange = async (filter, value) => {
    const tempFilterData = [...value];
    setFilterData({ ...filterData, [filter]: tempFilterData });
    setCurrentPage(1);
  };

  const openQuickViewHandler = (record, index) => {
    openQuickView({
      data: {
        id: { value: record?._source.company_id },
        name: record?._source.company_name,
        index,
      },
      view: 'company',
    });
  };

  const onSearch = (searchText) => {
    if (searchText) {
      setSearchTerm(searchText);
      setCurrentPage(1);
    }
  };
  const handleReset = () => {
    setSearchTerm('');
    setCurrentPage(1);
  };
  const handleChange = (e) => {
    if (e.type !== 'change' || e.target.value === '') {
      handleReset();
    }
  };
  const onChange = (paginationData, filters, sorter) => {
    const { selectedSort, selectedOrder } = sortData;
    if (checkIsSortOrderChanged(sorter, selectedSort, selectedOrder)) {
      setSortData({ selectedSort: sorter.column?.key, selectedOrder: sorter.order });
      setCurrentPage(1);
    }
  };
  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Layout className="technology-container background-white">
            <CompanyFilterContainer
              updateFilter={handleFilterChange}
              tabHeading="Filter"
              categoryList={categoryList}
              filterData={filterData}
              type={type}
            />
            <div style={{ width: 'calc(100% - 300px)' }} className="table-view-container p-0">
              <Card className="inner-tab-container" style={{ margin: '16px' }}>
                <Input.Search
                  defaultValue=""
                  allowClear
                  onSearch={onSearch}
                  placeholder="Search Companies"
                  maxLength={GLOBAL_SEARCH_CHAR_LIMIT}
                  style={{ width: '25%', padding: '16px', float: 'right' }}
                  onChange={handleChange}
                />
                <Table
                  onChange={onChange}
                  size="small"
                  pagination={pagination}
                  scroll={{
                    y: 300,
                  }}
                  columns={columns}
                  dataSource={newData()}
                  rowKey={(record) => record?._source?.company_id}
                />
              </Card>
            </div>
          </Layout>
        </>
      )}
    </div>
  );
};
export default Companies;

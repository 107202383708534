import React, { useState, useEffect, useContext } from 'react';
import { TechnologyService } from 'api/services';
import { Table, Input, Space, Tooltip, Typography, Skeleton } from 'antd';
import { GLOBAL_SEARCH_CHAR_LIMIT } from 'constants/constants';
import LinkButton from 'UI/Link';
import ShowTotal from 'UI/ShowTotal';
import { getPlanConfig } from 'utils/common';
import { timeConverter } from './helper';
import TechPsychContext from '../TechPsychContext';
import { showExtraLabels, getThumbnail } from './common';

const { Text } = Typography;

const dummyData = Array.from({ length: 15 }, (v, i) => ({
  _source: {},
}));

const status = {
  addition: {
    apiMethod: TechnologyService.fetchTechnologyAddition,
  },
  deletion: {
    apiMethod: TechnologyService.fetchTechnologyDeletion,
  },
  renewal: {
    apiMethod: TechnologyService.fetchTechnologyRenewal,
  },
};

const getColumns = (type, contextData, openQuickView) => {
  const handleClick = (record, index) => {
    const companyId = record._source?.company_id ?? record.company_id;
    if (companyId) {
      openQuickView({
        data: {
          id: { value: companyId },
          name: record._source?.company_name,
          index,
        },
        view: 'company',
      });
    }
  };

  if (type === 'addition' || type === 'deletion') {
    return [
      {
        title: `${
          type === 'addition' ? 'STARTED' : 'STOPPED'
        } USING ${contextData?.title?.toUpperCase()}`,
        dataIndex: 'company_name',
        key: 'company_name',
        width: 50,
        render: (item, record, index) => {
          return (
            <div className="list-view-title">
              <LinkButton
                style={{ padding: 0 }}
                text={getThumbnail(record)}
                onClick={() => handleClick(record, index)}
              />

              <LinkButton
                onClick={() => handleClick(record, index)}
                type="text"
                style={{ padding: 0 }}
                text={
                  <Tooltip
                    mouseEnterDelay={0.3}
                    placement="topLeft"
                    title={record._source?.company_name}
                  >
                    <Space direction="vertical" size={0} align="start">
                      <Text
                        style={{
                          maxWidth: '200px',
                          color: '#29275f',
                        }}
                        ellipsis
                      >
                        {record._source?.company_name}
                      </Text>
                      <Text
                        style={{
                          fontSize: '12px',
                          color: '#697390',
                          fontWeight: '10',
                          position: 'relative',
                          top: '-6px',
                        }}
                        ellipsis
                      >
                        {`${record._source?.domain}`}
                      </Text>
                    </Space>
                  </Tooltip>
                }
              />
            </div>
          );
        },
      },
      {
        title: 'HEADQUARTERS',
        dataIndex: ['_source', 'headquarters'],
        render: (item, record) => {
          return (
            <div className="pl-4">
              {record._source.company_location?.filter((val) => val).join(', ')}
            </div>
          );
        },
        key: 'HEADQUARTERS',
        width: 50,
      },
      {
        title: 'SIZE',
        dataIndex: ['_source', 'company_size'],
        key: 'SIZE',
        width: 30,
      },
      {
        title: 'CATEGORIES',
        dataIndex: 'category',
        key: 'CATEGORIES',
        width: 50,
        render: (item, record) => showExtraLabels(record._source.categories),
      },
      type === 'addition'
        ? {
            dataIndex: ['_source', 'added_on'],
            render: (item, record) => timeConverter(record._source.created_at),
            title: 'ADDED ON',
            key: 'ADDED_ON',
            width: 50,
          }
        : {
            dataIndex: ['_source', 'created_at'],
            render: (item, record) => timeConverter(record._source.created_at),
            title: 'DROPPED ON',
            key: 'DROPPED_ON',
            width: 50,
          },
    ];
  }
  return [
    {
      title: 'COMPANY',
      dataIndex: 'company_name',
      key: 'company_name',
      // width: 100,
      render: (item, record) => {
        return (
          <div className="list-view-title">
            <LinkButton
              style={{ padding: 0 }}
              text={getThumbnail(record)}
              onClick={() => handleClick(record)}
            />

            <LinkButton
              onClick={() => handleClick(record)}
              type="text"
              style={{ padding: 0 }}
              text={
                <Tooltip mouseEnterDelay={0.3} placement="topLeft" title={record?.company_name}>
                  <Space direction="vertical" size={0} align="start">
                    <Text
                      style={{
                        maxWidth: '200px',
                        color: '#29275f',
                      }}
                      ellipsis
                    >
                      {record?.company_name}
                    </Text>
                    <Text
                      style={{
                        fontSize: '12px',
                        color: '#697390',
                        fontWeight: '10',
                        position: 'relative',
                        top: '-6px',
                      }}
                      ellipsis
                    >
                      {`${record?.domain}`}
                    </Text>
                  </Space>
                </Tooltip>
              }
            />
          </div>
        );
      },
    },
    {
      title: 'CONTRACT START',
      dataIndex: 'moved_on',
      key: 'moved_on',
      // width: 50,
    },
    {
      title: () => {
        return (
          <div className="d-flex">
            PREDICTED CONTRACT RENEWAL
            <div className="beta-tag">Beta</div>
          </div>
        );
      },
      dataIndex: 'renewal_on',
      key: 'renewal_on',
      // width: 50,
    },
  ];
  // 	CONTRACT START
};

const getDummyColumns = (type, contextData) => {
  if (type === 'addition' || type === 'deletion') {
    return [
      {
        title: `${
          type === 'addition' ? 'STARTED' : 'STOPPED'
        } USING ${contextData?.title?.toUpperCase()}`,
        dataIndex: 'company_name',
        key: 'company_name',
        width: 100,
        render: (item, record, index) => {
          return (
            <div className="list-view-title">
              <Skeleton.Avatar active shape="square" className="skeleton-active-avatar" />
              <Skeleton.Input size="small" active />
            </div>
          );
        },
      },
      {
        title: 'HEADQUARTERS',
        dataIndex: ['_source', 'headquarters'],
        render: (item, record) => {
          return <Skeleton.Input size="small" style={{ width: 150 }} active />;
        },
        key: 'HEADQUARTERS',
        width: 50,
      },
      {
        title: 'SIZE',
        render: (item, record) => {
          return <Skeleton.Input size="small" style={{ width: 150 }} active />;
        },
        key: 'SIZE',
        width: 50,
      },
      {
        title: 'CATEGORIES',
        dataIndex: 'category',
        key: 'CATEGORIES',
        width: 50,
        render: (item, record) => {
          return <Skeleton.Input size="small" style={{ width: 150 }} active />;
        },
      },
      type === 'addition'
        ? {
            dataIndex: ['_source', 'added_on'],
            render: (item, record) => {
              return <Skeleton.Input size="small" style={{ width: 150 }} active />;
            },
            title: 'ADDED ON',
            key: 'ADDED_ON',
            width: 50,
          }
        : {
            dataIndex: ['_source', 'created_at'],
            render: (item, record) => {
              return <Skeleton.Input size="small" style={{ width: 150 }} active />;
            },
            title: 'DROPPED ON',
            key: 'DROPPED_ON',
            width: 50,
          },
    ];
  }
  return [
    {
      title: 'COMPANY',
      dataIndex: 'company_name',
      key: 'company_name',
      width: 100,
      render: (item, record) => {
        return (
          <div className="list-view-title">
            <Skeleton.Avatar active shape="square" className="skeleton-active-avatar" />
            <Skeleton.Input size="small" active />
          </div>
        );
      },
    },
    {
      title: 'CONTRACT START',
      render: (item, record) => {
        return <Skeleton.Input size="small" style={{ width: 150 }} active />;
      },
      key: 'moved_on',
      width: 50,
    },
    {
      title: 'PREDICTED CONTRACT RENEWAL',
      render: (item, record) => {
        return <Skeleton.Input size="small" style={{ width: 150 }} active />;
      },
      key: 'renewal_on',
      width: 50,
    },
  ];
  // 	CONTRACT START
};

const TechnologyStatusInCompanies = ({ technologyId, type = 'addition' }) => {
  const STATUS = status[type];
  const [data, setData] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const { data: contextData, openQuickView } = useContext(TechPsychContext);

  const fetchData = async (searchText, pageNumber = 1) => {
    setIsLoading(true);
    const response = await STATUS.apiMethod(technologyId, pageNumber, searchText, {
      technology: contextData?.title,
      subcategory: contextData?.subcategory,
    });
    if (searchText) {
      setFilterData(response.data);
    } else {
      setData(response.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const planConfig = getPlanConfig();
  const total = searchTerm ? filterData?.total : data?.total;
  const pagination = {
    current: page,
    position: ['bottomLeft'],
    total: total >= planConfig.total ? planConfig.total : total,
    showSizeChanger: false,
    responsive: true,
    pageSize: planConfig.pageSize,
    showTotal: (tableTotal, range) => <ShowTotal total={total} type="Companies" range={range} />,
    defaultCurrent: 1,
  };
  const columns = isLoading
    ? getDummyColumns(type, contextData)
    : getColumns(type, contextData, openQuickView);

  const onSearch = (searchText) => {
    if (searchText) {
      setIsLoading(true);
      setSearchTerm(searchText);
      fetchData(searchText);
      setPage(1);
    }
  };
  const handleReset = () => {
    setFilterData(null);
    setSearchTerm('');
    setPage(1);
  };
  const handleChange = (e) => {
    if (e.type !== 'change' || e.target.value === '') {
      handleReset();
    }
  };
  const paginationChange = (paginationData) => {
    setPage(paginationData.current);
    fetchData(searchTerm, paginationData.current);
  };

  return (
    <>
      <Input.Search
        defaultValue=""
        allowClear
        onSearch={onSearch}
        placeholder="Search Companies"
        maxLength={GLOBAL_SEARCH_CHAR_LIMIT}
        style={{ width: '25%', padding: '16px', float: 'right' }}
        onChange={handleChange}
      />
      <div className="table-view-container">
        <Table
          // loading={isLoading}
          onChange={paginationChange}
          size="small"
          pagination={!isLoading ? pagination : {}}
          scroll={{
            x: '100%',
            y: '40vh',
          }}
          columns={columns}
          dataSource={isLoading ? dummyData : filterData?.data ?? data?.data ?? []}
          rowKey={(record) => record._id}
        />
      </div>
    </>
  );
};

export default TechnologyStatusInCompanies;

/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Collapse, Checkbox, Input } from 'antd';
import CheckboxList from 'UI/CheckBoxList/CheckboxList';
import { nanoid } from 'nanoid';
import scssVariables from 'src/_vars.scss';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
const form = new Map();
const expandIcon = ({ isActive }) =>
  isActive ? (
    <MinusOutlined style={{ color: scssVariables.grey }} />
  ) : (
    <PlusOutlined style={{ color: scssVariables.grey }} />
  );

const TechnologyExportSelection = ({
  technologies,
  onChange,
  value,
  totalAvailable,
  renderFrom,
  selectedTech,
}) => {
  const [activeKey, setActiveKey] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [values, setValues] = useState('');

  useEffect(() => {
    if (technologies) {
      form.clear();
      for (const tech of technologies) {
        const selectedTechnologies = tech.value.filter((v) => selectedTech.includes(v));
        if (selectedTechnologies.length) {
          form.set(tech.label, selectedTechnologies);
        }
      }
      setActiveKey([]);
    }
  }, []);

  useEffect(() => {
    if (values) onChange(values);
  }, [values]);

  // eslint-disable-next-line no-shadow
  const hasSearchKey = ({ label, value }, key) => {
    if (!key) {
      return true;
    }
    return (
      label.toLowerCase().indexOf(key.toLowerCase()) > -1 ||
      value.some((val) => val && val.toLowerCase().indexOf(key.toLowerCase()) > -1)
    );
  };

  // Optimised to change only when there is search else cache the states for panels
  const filterData = useMemo(
    () => technologies.filter((item) => hasSearchKey(item, searchTerm)),
    [searchTerm, technologies]
  );

  // Provides value from map when selected values changes from use input
  const checkedList = useCallback(
    (item) => {
      return value.length === totalAvailable ? item.value : form.get(item.label);
    },
    [values, value]
  );

  useEffect(() => {
    // Detect value change and set the local Map
    if (value?.length === totalAvailable) {
      technologies.map((item) => form.set(item.label, item.value));
    } else if (value?.length === 0) {
      form.clear();
    }
  }, [value]);

  const onSelectionChange = (item, checked, filteredValues, plainOptions) => {
    const { label, value: allSubCats } = item;
    if (filteredValues.length < allSubCats.length) {
      const oldValues = form.get(label);
      if (oldValues) {
        // alreadyCheckedItems are all checked items but dont included in filter value
        const alreadyCheckedItems = oldValues.filter(
          (oldChecked) => !filteredValues.includes(oldChecked)
        );
        if (alreadyCheckedItems.length) {
          checked.push(...alreadyCheckedItems);
        }
      }
    }
    const allOptionExceptCurrentChecked = plainOptions.filter(
      (filterValue) => !checked.includes(filterValue)
    );
    const checkedItems = allSubCats.filter(
      (subCat) => !allOptionExceptCurrentChecked.includes(subCat)
    );
    form.set(label, checkedItems);
    setValues(Array.from(form.values()).flat());
  };

  const onSearch = ({ target }) => {
    const term = target.value.trim();
    const activekey =
      term === ''
        ? []
        : technologies.filter((item) => hasSearchKey(item, term)).map((val) => val.label);

    if (term.length > 1 || term === '') {
      setSearchTerm(term);
      setActiveKey(activekey);
    }
  };

  const handleAllSelection = (item, isAllCheked) => {
    let selectedValues = Array.from(form.values()).flat();
    selectedValues = isAllCheked
      ? selectedValues.concat(item.value.filter((val) => !form.get(item.label)?.includes(val)))
      : selectedValues.filter((val) => !item.value.includes(val));
    form.set(item.label, isAllCheked ? item.value : []);
    setValues(selectedValues);
  };

  const getPanelHeader = (item) => {
    return (
      <div className="expand-filter-header">
        <span role="presentation" onClick={(event) => event.stopPropagation()}>
          <Checkbox
            indeterminate={
              !!checkedList(item)?.length && item?.value?.length > checkedList(item)?.length
            }
            onChange={({ target }) => handleAllSelection(item, target.checked)}
            checked={item?.value?.length === checkedList(item)?.length}
          />
        </span>
        <span className="expand-filter-header_title"> {item.label} </span>
      </div>
    );
  };

  return (
    <div className="expand-collpse-header">
      {renderFrom === 'list' ? (
        <div className="technology-search">
          <Input.Search
            allowClear
            enterButton={false}
            // prefix={<SearchOutlined />}
            placeholder="Search technology subcategory"
            onChange={onSearch}
          />
        </div>
      ) : (
        <div className="technology-search search-box">
          <Input
            style={{ width: 271, height: 36 }}
            allowClear
            placeholder="Search"
            onChange={onSearch}
          />
        </div>
      )}
      <Collapse
        accordion
        expandIcon={expandIcon}
        expandIconPosition="right"
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
        className="technology-items"
      >
        {filterData.map((item) => {
          return (
            <Panel header={getPanelHeader(item)} key={item.label}>
              <CheckboxList
                className="export-selection-technology"
                key={nanoid()}
                searchTerm={searchTerm}
                options={item.value}
                onChange={(checked, filteredValues, plainOptions) =>
                  onSelectionChange(item, checked, filteredValues, plainOptions)
                }
                checkedList={checkedList(item)}
              />
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default TechnologyExportSelection;

/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, NavLink } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { Avatar, Layout, Menu, Skeleton } from 'antd';
import Common from 'containers/common/commonRedux';
import useProgress from 'UI/CustomHooks/Progress';
// import { getDomainFromEmail } from 'utils/common';
import { eventManager, eventList, eventActions } from 'utils/eventManager';
import { LogoIcon } from '../CustomIcon/CustomIcon';
import SideNavbarMenu from './SideNavbarMenu';
import './SideNavbar.scss';
import PlanIndicatiorPill from '../PlanIndicatiorPill/PlanIndicatiorPill';

const { Sider } = Layout;

const SideNavbar = (props) => {
  const [isCreditHover, setIsCreditHover] = useState(false);
  const planPermission = useSelector((state) => state.common.planPermission);
  const credits = useSelector((state) => state.common.credits);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    isCreditDrawer: false,
    selectedKeys: [],
  });

  const location = useLocation();

  const CreditProgress = useProgress(credits, 40);

  const { isCreditDrawer, selectedKeys } = state;

  const toggle = (value) => {
    setState({
      isCreditDrawer: value,
    });
  };

  // const userDomain = getDomainFromEmail(userEmail);

  const handleOnClick = ({ key }) => {
    if (key === 'credit') {
      setState({
        isCreditDrawer: !isCreditDrawer,
        selectedKeys: isCreditDrawer ? [] : ['credit'],
      });
    }
  };

  const closeDrawer = () => {
    const openDrawers = {};
    if (isCreditDrawer) {
      openDrawers.isCreditDrawer = false;
    }
    if (Object.keys(openDrawers).length) {
      setState(openDrawers);
    }
  };

  const handleSelect = ({ key }) => {
    eventManager.track(eventList.SIDENAVBAR_ITEM_CLICK, {
      payload: { source: key, version: eventActions.NDF },
    });
  };

  const getLockedPages = () => {
    return planPermission && planPermission.notAllowed ? planPermission.notAllowed.page : [];
  };

  const getPlanTheme = () => {
    return planPermission && planPermission.theme ? planPermission.theme : '';
  };

  const getPlan = useMemo(() => {
    return planPermission && planPermission.plan;
  }, [planPermission]);

  const hasPermission = () => {
    return planPermission && planPermission.showUpgrade;
  };

  const hasEvent = () => {
    return planPermission && planPermission.event;
  };

  const creditHover = (isHover) => {
    setIsCreditHover(isHover);
  };

  const rednerPlanIndicator = () => {
    return typeof getPlan === 'number' ? (
      <PlanIndicatiorPill plan={getPlan} />
    ) : (
      <Skeleton.Button active size="small" shape="round" />
    );
  };

  const regex = new RegExp(/\b(?:dashboard|list|discover|job-posting|technologies|keywords)\b/gi);
  const menuKeys = location.pathname.match(regex) || [];
  return (
    <div className="side-navbar">
      <Sider className="side-navbar" trigger={null} collapsed collapsible collapsedWidth="56">
        <div className="menu-block">
          <div className="side-navbar__logo">
            {hasEvent() ? (
              <NavLink disabled={hasPermission()} to="/dashboard" style={{ display: 'flex' }}>
                <Avatar src={planPermission.event.logo} shape="square" />
              </NavLink>
            ) : (
              <NavLink
                disabled={hasPermission()}
                to="/dashboard"
                style={{ width: '25px', height: '25px' }}
              >
                <LogoIcon fill="#fff" width={25} height={25} />
              </NavLink>
            )}
          </div>
          <SideNavbarMenu
            theme={getPlanTheme()}
            lockedPages={getLockedPages()}
            className="side-navbar__menu-container"
            selectedKeys={menuKeys}
            defaultSelectedKeys={['dashboard']}
            onSelect={handleSelect}
            onClick={closeDrawer}
          />
        </div>
        <Menu
          className="side-navbar__menu-container side-navbar__progress"
          onClick={handleOnClick}
          selectedKeys={selectedKeys}
        >
          <Menu.Item
            key="credit"
            title="Credits"
            className="creditsMenu"
            onMouseEnter={() => creditHover(true)}
            onMouseLeave={() => creditHover(false)}
          >
            <div style={{ position: 'relative' }}>
              {hasEvent() && (
                <Avatar
                  src={
                    !isCreditHover
                      ? planPermission.event.credit_img
                      : planPermission.event.credit_hover_img
                  }
                  shape="square"
                  style={{
                    width: 50,
                    position: 'absolute',
                    top: -35,
                    left: -5,
                  }}
                />
              )}
              {CreditProgress}
            </div>
          </Menu.Item>
          <Common
            toggle={toggle}
            isCreditDrawer={isCreditDrawer}
            profileToggle={() => {}}
            isProfile={false}
          />
          {rednerPlanIndicator()}
        </Menu>
      </Sider>
    </div>
  );
};

SideNavbar.props = {
  credits: PropTypes.string.isRequired,
};

export default SideNavbar;

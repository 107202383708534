import {
  PROFILE_REMOVED_LISTS_INIT,
  PROFILE_REMOVED_LISTS_SUCCESS,
  PROFILE_REMOVED_LISTS_FAILURE,
} from 'store/actionTypes';

export const profileListSuccess = (data) => {
  return {
    type: PROFILE_REMOVED_LISTS_SUCCESS,
    data,
  };
};

export const profileListFailure = (error) => {
  return {
    type: PROFILE_REMOVED_LISTS_FAILURE,
    error,
  };
};

export const profileLists = (params, headers) => {
  const payload = {
    params,
    headers,
  };
  return {
    type: PROFILE_REMOVED_LISTS_INIT,
    payload,
  };
};

import { UPDATE_USER_INFO } from 'store/actionTypes';

const updateUser = (data) => {
  return {
    type: UPDATE_USER_INFO,
    data,
  };
};

export default updateUser;

/* eslint-disable no-param-reassign */
import { CommonService, DiscoveryService } from 'api/services';
import {
  COMPANY_SORTING_OPTIONS,
  DELAY_MS,
  LEAD_SORTING_OPTIONS,
  SORT_ORDERS,
} from 'constants/constants';
import { call, put, takeLatest, delay } from 'redux-saga/effects';
import {
  SEARCH_COMPANIES,
  SEARCH_LEADS,
  SEARCH_NET_NEW_COMPANIES,
  SEARCH_NET_NEW_LEADS,
} from 'store/actionTypes';
import { mapMetadata } from 'utils/common';
import {
  netNewSearchCompaniesError,
  netNewSearchLeadError,
  newNewCompaniesSuccess,
  newNewLeadsSuccess,
  searchCompaniesError,
  searchCompaniesSuccess,
  searchLeadsError,
  searchLeadSuccess,
  showShimmers,
} from './discoveryActions';

const defaultLeadSorting = [{ key: LEAD_SORTING_OPTIONS[0].key, order: SORT_ORDERS[1].key }];
const defaultCompanySorting = [{ key: COMPANY_SORTING_OPTIONS[0].key, order: SORT_ORDERS[1].key }];

function* fetchLeads(action) {
  let leads = {};
  try {
    const { params, headers } = action.payload;
    if (params && !params.sort) {
      params.sort = defaultLeadSorting;
    }
    yield put(showShimmers(true));
    yield delay(DELAY_MS);
    leads = yield call(DiscoveryService.fetchTotalLeads, params, headers);
    if (!leads?.error) {
      leads = {
        ...leads,
        data: leads?.data || [],
        total_results: leads?.total_results || leads?.count || 0,
      };
      yield put(searchLeadSuccess(leads));
      const ids = leads.data.map((item) => item.id.value) || [];
      if (ids.length) {
        const notAvailableInfo = yield call(CommonService.getNotAvailableInstaInfo, ids, headers);
        leads.data = mapMetadata(leads.data, notAvailableInfo);
      }
      yield put(searchLeadSuccess(leads));
    } else {
      const { message } = leads;
      const toastNotificationPayload = { type: 'ERROR', message };
      yield put(searchLeadsError(toastNotificationPayload));
    }
  } catch (err) {
    yield put(searchLeadsError({ type: 'ERROR', message: err.message }));
  }
}

function* fetchNetNewLeads(action) {
  let leads = {};
  try {
    const { params, headers } = action.payload;
    if (params && !params.sort) {
      params.sort = defaultLeadSorting;
    }
    yield put(showShimmers(true));
    yield delay(DELAY_MS);
    leads = yield call(DiscoveryService.fetchNetNewLeads, params, headers);
    if (!leads?.error) {
      const ids = leads.data.map((item) => item.id.value) || [];
      if (ids.length) {
        const notAvailableInfo = yield call(CommonService.getNotAvailableInstaInfo, ids, headers);
        leads.data = mapMetadata(leads.data, notAvailableInfo);
      }
      yield put(newNewLeadsSuccess(leads));
    } else {
      const { message } = leads;
      const toastNotificationPayload = { type: 'ERROR', message };
      yield put(netNewSearchLeadError(toastNotificationPayload));
    }
  } catch (err) {
    yield put(netNewSearchLeadError({ type: 'ERROR', message: err.message }));
  }
}

function* fetchCompanies(action) {
  try {
    const { params, headers } = action.payload;
    if (params && !params.sort) {
      params.sort = defaultCompanySorting;
    }
    let response = {};
    yield put(showShimmers(true));
    yield delay(DELAY_MS);
    response = yield call(DiscoveryService.fetchCompanyList, params, headers);
    if (!response?.error) {
      yield put(searchCompaniesSuccess(response));
    } else {
      const { message } = response;
      const toastNotificationPayload = { type: 'ERROR', message };
      yield put(searchCompaniesError(toastNotificationPayload));
    }
  } catch (err) {
    yield put(searchCompaniesError({ type: 'ERROR', message: err.message }));
  }
  return action;
}

function* fetchNetNewCompanies(action) {
  try {
    const { params, headers } = action.payload;
    if (params && !params.sort) {
      params.sort = defaultCompanySorting;
    }
    let response = {};
    yield put(showShimmers(true));
    yield delay(DELAY_MS);
    response = yield call(DiscoveryService.fetchNetNewCompanies, params, headers);
    if (!response?.error) {
      yield put(newNewCompaniesSuccess(response));
    } else {
      const { message } = response;
      const toastNotificationPayload = { type: 'ERROR', message };
      yield put(netNewSearchCompaniesError(toastNotificationPayload));
    }
  } catch (err) {
    yield put(netNewSearchCompaniesError({ type: 'ERROR', message: err.message }));
  }
  return action;
}

export function* searchLeadSaga() {
  yield takeLatest(SEARCH_LEADS, fetchLeads);
  yield takeLatest(SEARCH_NET_NEW_LEADS, fetchNetNewLeads);
}

export function* searchCompaniesSaga() {
  yield takeLatest(SEARCH_COMPANIES, fetchCompanies);
  yield takeLatest(SEARCH_NET_NEW_COMPANIES, fetchNetNewCompanies);
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, forwardRef, useImperativeHandle, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Space, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { headerGenerator, getLoggedInUser, debounce, updateSortingForSearch } from 'utils/common';
import ListService from 'api/services/listService';
import { CustomAlert } from 'UI/CustomAlert/CustomAlert';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Title from 'antd/lib/typography/Title';
import { getSearchQueryParams } from 'utils/discovery/common';
import { COMPANY_SORTING_OPTIONS, LEAD_SORTING_OPTIONS, SORT_ORDERS } from 'constants/constants';
import AddtoListSelection from '../AddtoListSelection';
import './AddtoListForm.scss';
import scssVariables from '../../../../_vars.scss';

const { useForm } = Form;

const { Text } = Typography;

const AddtoListForm = forwardRef(
  ({ onSubmit, onValuesChange, isAnySmartListSelected = true }, ref) => {
    const locationPath = window.location.pathname.split('/') || [];
    const tab = locationPath.length > 2 ? locationPath[2] : '';
    const [form] = useForm();
    const { t } = useTranslation(['addtolist']);
    const userInfo = getLoggedInUser();
    const headers = headerGenerator(userInfo.token, userInfo.session_id);
    const [smartListLoading, setSmartListLoading] = useState(false);
    const [smartListOptions, setSmartList] = useState([]);
    const [exclusionListOptions, setExclusionList] = useState([]);
    const [checked, setChecked] = useState(false);
    const { ids, listType, shouldTotalBeSelected, source, jobFilters } = useSelector(
      (state) => state.discovery.addToListItems
    );
    const {
      isCheckedItems,
      defaultInnerActiveKey,
      totalSelected,
      leadFilters,
      netNewLeads,
      netNewCompanies,
      companyFilters,
      selectionParam,
      page: PAGE,
    } = useSelector((state) => state.discovery);
    useImperativeHandle(ref, () => ({
      onSubmit() {
        form.submit();
      },
    }));

    const handelChange = (e) => {
      setChecked(e.target.checked);
    };

    const getSearchParams = () => {
      const filters = tab === 'lead' ? leadFilters : companyFilters;
      const netNewData = tab === 'lead' ? netNewLeads : netNewCompanies;

      const defaultLeadSorting = [{ key: LEAD_SORTING_OPTIONS[0].key, order: SORT_ORDERS[1].key }];
      const defaultCompanySorting = [
        { key: COMPANY_SORTING_OPTIONS[0].key, order: SORT_ORDERS[1].key },
      ];
      const defaultSorting = tab === 'lead' ? defaultLeadSorting : defaultCompanySorting;
      const {
        SORT: { values: selectedSort },
        ORDER: { values: selectedOrder },
      } = filters;

      let data = getSearchQueryParams(
        filters,
        {
          sort: selectedSort[0],
          order: selectedOrder[0],
          page: PAGE,
          fields: [],
          innerTab: defaultInnerActiveKey,
        },
        selectionParam
      );
      if (data && !data.sort) {
        data.sort = defaultSorting;
      }
      data = updateSortingForSearch(data, tab);

      return data;
    };

    const handleFormSubmit = (values) => {
      const params = { ...values, ids, listType };
      if (!checked) {
        delete params.exclusion_list_ids;
      } else {
        params.exclusion_list_ids = params.exclusion_list_ids.map((item) => item.value);
      }
      params.metadata = getSearchParams();
      params.entity = tab;
      params.source = source;
      if (jobFilters) {
        params.jobFilters = jobFilters;
      }
      onSubmit(params);
    };

    const onSmartListSearch = async (value) => {
      const params = value ? { searchTerm: value.trim(), type: listType } : { type: listType };
      setSmartListLoading(true);
      try {
        const response = await ListService.fetchSmartList(params, headers);
        const options = response.rows.map((row) => {
          // DS-5528 lead to people change
          const rowListType = row.listType === 'lead' ? 'people' : row.listType;
          return {
            label: row.title,
            value: row.id,
            listType: `${rowListType.replace('_', ' ')} smart list`,
          };
        });
        setSmartListLoading(false);
        setSmartList(options);
      } catch (e) {
        setSmartList([]);
        setSmartListLoading(false);
      }
    };

    const onExclusionListSearch = async (value) => {
      const type =
        listType === 'lead' ? ['lead_exclusion', 'company_exclusion'] : 'company_exclusion';
      const params = value ? { searchTerm: value.trim(), type } : { type };

      setSmartListLoading(true);
      try {
        const response = await ListService.fetchExclusionList(params, headers);
        const options = response.rows.map((row) => ({
          label: row.title,
          value: row.id,
          listType: `${row.listType.replace('_', ' ')} list`,
        }));
        setSmartListLoading(false);
        setExclusionList(options);
      } catch (e) {
        setExclusionList([]);
        setSmartListLoading(false);
      }
    };

    useEffect(() => {
      onSmartListSearch('');
      onExclusionListSearch('');
    }, []);

    const customValidator = (rule, value) => {
      const hasValue = Array.isArray(value) ? value.length > 0 : value;
      if (checked && !hasValue) {
        return Promise.reject(rule.message);
      }
      if (!checked && hasValue) {
        return Promise.resolve();
      }
      return Promise.resolve();
    };

    const selectedRecordMessage = useMemo(() => {
      let count;
      let message;
      if (jobFilters?.totalCount) {
        count = jobFilters.totalCount;
        message = `Add ${jobFilters.type === 'lead' ? 'people from ' : ''} ${count} ${
          count === 1 ? 'company' : 'companies'
        }`;
      } else {
        count = shouldTotalBeSelected ? totalSelected : ids.length;
        message = t(`${count === 1 ? 'labels.RECORD_SELECTED' : 'labels.RECORDS_SELECTED'}`);
        message = `${count} ${message}`;
      }
      return `${message}`;
    }, [ids]);

    return (
      <div className="addto-list-form">
        {ids?.length || jobFilters?.totalCount ? (
          <CustomAlert message={selectedRecordMessage} />
        ) : null}
        <Form
          labelCol={{
            span: 24,
          }}
          colon={false}
          form={form}
          requiredMark={false}
          onFinish={handleFormSubmit}
          onValuesChange={onValuesChange}
        >
          <Form.Item
            name="listId"
            label={<Title level={5}>{t('labels.SELECT_SMART_LIST')}</Title>}
            rules={[{ required: true, message: t('validation.static.REQUIRED_SMART_LIST') }]}
            style={{ marginTop: '24px' }}
          >
            <AddtoListSelection
              placeholder={t('placeholders.form.SEARCH_SMART_LIST')}
              loading={smartListLoading}
              onSearch={debounce(onSmartListSearch, 500)}
              options={smartListOptions}
              listType="smart_list"
            />
          </Form.Item>
          <div
            style={{
              background: isAnySmartListSelected
                ? scssVariables.slintelBlueGray4
                : scssVariables.slintelBlueGray5,
              borderColor: !checked ? scssVariables.slintelBlueGray2 : scssVariables.slintelBlue1,
            }}
            className="exclusion-list-container"
          >
            <Space direction="vertical">
              <Form.Item onChange={handelChange}>
                <Space direction="vertical" size={0}>
                  <Checkbox name="selection" value="selected" disabled={isAnySmartListSelected}>
                    <Title level={5}>{t('labels.APPLY_EXLUSION_LIST')}</Title>
                  </Checkbox>
                  <Text style={{ marginLeft: 28, fontSize: '12px', color: scssVariables.grey }}>
                    {' '}
                    {t('labels.EXCLUDE_UP_TO')}
                  </Text>
                </Space>
              </Form.Item>
            </Space>
            <Form.Item
              name="exclusion_list_ids"
              rules={[
                {
                  message: t('validation.static.REQUIRED_EXCLUSION_LIST'),
                  validator: customValidator,
                },
              ]}
            >
              <AddtoListSelection
                disabled={isAnySmartListSelected || !checked}
                placeholder={t(`placeholders.form.SEARCH_EXCLUSION_LIST_${tab.toUpperCase()}`)}
                loading={smartListLoading}
                onSearch={debounce(onExclusionListSearch, 500)}
                options={exclusionListOptions}
                listType="exclusion_list"
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }
);

AddtoListForm.propTypes = {
  onSubmit: PropTypes.func,
  onValuesChange: PropTypes.func,
  tab: PropTypes.string,
  isAnySmartListSelected: PropTypes.bool,
};

export default AddtoListForm;

import { all, spawn } from 'redux-saga/effects';
import * as smartListSagas from 'containers/smartList/smartListSaga';
import * as profileRemovedListSaga from 'containers/profileRemovedList/profileRemovedListSaga';
import * as outreachSagas from 'containers/integrations/outreach/outreachSaga';
import * as newsSagas from '../containers/news/newsSaga';
import * as commonSagas from '../containers/common/commonSaga';
import * as GenericChannel from '../containers/discovery/genericChannel';
import * as discoverySaga from '../containers/discovery/discoverySaga';
import * as registerSaga from '../containers/register/registerSaga';

export default function* rootSaga() {
  yield all(
    [
      ...Object.values(newsSagas),
      ...Object.values(commonSagas),
      ...Object.values(smartListSagas),
      ...Object.values(outreachSagas),
      ...Object.values(profileRemovedListSaga),
      ...Object.values(GenericChannel),
      ...Object.values(discoverySaga),
      ...Object.values(registerSaga),
    ].map(spawn)
  );
}

import { getRequest, postRequest } from 'api/apiRequests';
import { baseURL, endPoints, searchClientID } from 'api/apiEndpoints';
import { updateSortingForSearch } from 'utils/common';
import { MAX_NETNEW_LIMIT } from 'constants/constants';
/**
 * Discovery search searvice for lead and companies search
 */
const DiscoveryService = {
  async fetchLeadList(params, headers, ignore = false) {
    const url = `${baseURL}${endPoints.SEARCH_LEADS}`;
    const updatedHeaders = { ...headers };
    let queryParams = params;
    if (!ignore) {
      queryParams = updateSortingForSearch(params, 'lead');
    }
    updatedHeaders['X-Search-Client-Id'] = searchClientID;

    const response = await postRequest(url, queryParams, updatedHeaders);
    LogResponse('fetchLeadList', response);
    return { data: response };
  },
  async fetchCompanyList({ isJustPageChange, ...params }, headers, ignore = false) {
    if (params?.filters?.length) {
      const url = `${baseURL}${endPoints.SEARCH_COMPANIES}`;
      const updatedHeaders = { ...headers };
      let queryParams = params;
      if (!ignore) {
        queryParams = updateSortingForSearch(params, 'company');
      }
      updatedHeaders['X-Search-Client-Id'] = searchClientID;

      const response = await postRequest(url, queryParams, updatedHeaders);
      LogResponse('fetchCompanyList', response);
      return response;
    }
    const companyCount = await DiscoveryService.fetchCompaniesCount(
      {
        filters: params?.filters || [],
        initial_size: params.initial_size,
      },
      headers
    );
    return { data: [], total_results: companyCount.data?.count || 0 };
  },
  async fetchLeadSuggest(params, headers) {
    const url = `${baseURL}${endPoints.LEADS_SUGGESTER}`;
    const updatedHeaders = { ...headers };
    updatedHeaders['X-Search-Client-Id'] = searchClientID;
    const response = await postRequest(url, params, updatedHeaders);
    LogResponse('fetchLeadSuggest', response);
    return { data: response };
  },
  async fetchCompanySuggest(params, headers) {
    const url = `${baseURL}${endPoints.COMPANIES_SUGGESTER}`;
    const updatedHeaders = { ...headers };
    updatedHeaders['X-Search-Client-Id'] = searchClientID;
    const response = await postRequest(url, params, updatedHeaders);
    LogResponse('fetchCompanySuggest', response);
    return { data: response };
  },
  async fetchLeadsIds(params, headers) {
    const url = `${baseURL}${endPoints.LEAD_IDS}`;
    const updatedHeaders = { ...headers };
    const queryParams = updateSortingForSearch(params, 'lead');
    updatedHeaders['X-Search-Client-Id'] = searchClientID;
    const response = await postRequest(url, queryParams, updatedHeaders);
    LogResponse('fetchLeadsIds', response);
    return { data: response };
  },
  async fetchCompaniesIds(params, headers) {
    const url = `${baseURL}${endPoints.COMPANY_IDS}`;
    const updatedHeaders = { ...headers };
    const queryParams = updateSortingForSearch(params, 'company');
    updatedHeaders['X-Search-Client-Id'] = searchClientID;
    const response = await postRequest(url, queryParams, updatedHeaders);
    LogResponse('fetchCompaniesIds', response);
    return { data: response };
  },
  async fetchNetNewLeadIdCount(params, headers) {
    const queryParams = updateSortingForSearch(
      {
        filters: params.filters,
        fields: params.fields,
        sort: params.sort,
        size: params.size || MAX_NETNEW_LIMIT,
      },
      'lead'
    );
    if (params.leads_per_company && params.leads_per_company > 0) {
      queryParams.leads_per_company = params.leads_per_company;
    }
    const updatedHeaders = { ...headers };
    updatedHeaders['X-User-Service-Client-Id'] = searchClientID;
    const baseUrl = baseURL.replace('api/', '');
    const url = `${baseUrl}user-service/v1/lead/ids/count?user_id=${params.user_id}`;
    const response = await postRequest(url, queryParams, updatedHeaders);
    LogResponse('fetchNetNewLeadsIds', response);
    return { data: response };
  },
  async fetchNetNewCompaniesIdCount(params, headers) {
    const queryParams = updateSortingForSearch(
      {
        filters: params.filters,
        fields: params.fields,
        sort: params.sort,
        size: params.size || MAX_NETNEW_LIMIT,
      },
      'company'
    );
    const updatedHeaders = { ...headers };
    updatedHeaders['X-User-Service-Client-Id'] = searchClientID;
    const baseUrl = baseURL.replace('api/', '');
    const url = `${baseUrl}user-service/v1/company/ids/count?user_id=${params.user_id}`;
    const response = await postRequest(url, queryParams, updatedHeaders);
    LogResponse('fetchNetNewCompaniesIds', response);
    return { data: response };
  },
  async fetchLeadCompaniesCount(params, headers) {
    const url = `${baseURL}${endPoints.UNIQUE_COMPANY_COUNT}`;
    const updatedHeaders = { ...headers };
    const { sort, ...queryParams } = params;
    updatedHeaders['X-Search-Client-Id'] = searchClientID;
    const response = await postRequest(url, queryParams, updatedHeaders);
    LogResponse('fetchCompaniesCount', response);
    return { data: response };
  },
  async fetchLeadsCount(params, headers) {
    const url = `${baseURL}${endPoints.LEAD_COUNT}`;
    const updatedHeaders = { ...headers };
    updatedHeaders['X-Search-Client-Id'] = searchClientID;
    const response = await postRequest(url, params, updatedHeaders);
    LogResponse('fetchLeadsCount', response);
    return { data: response };
  },
  async fetchCompaniesCount(params, headers) {
    const url = `${baseURL}${endPoints.COMPANY_COUNT}`;
    const updatedHeaders = { ...headers };
    updatedHeaders['X-Search-Client-Id'] = searchClientID;
    const response = await postRequest(url, params, updatedHeaders);
    LogResponse('fetchCompaniesCount', response);
    return { data: response };
  },
  async fetchLeadDetailById(id, headers) {
    const url = `${baseURL}v1/leads/${id}`;
    const updatedHeaders = { ...headers };
    updatedHeaders['X-Search-Client-Id'] = searchClientID;
    const response = await getRequest(url, {}, updatedHeaders);
    LogResponse('fetchLeadDetailById', response);
    return { data: response };
  },
  async fetchLeadExtraDetailById(id, headers) {
    const url = `${baseURL}leads-extra-info/${id}`;
    const updatedHeaders = { ...headers };
    updatedHeaders['X-Search-Client-Id'] = searchClientID;
    const response = await getRequest(url, {}, updatedHeaders);
    LogResponse('fetchLeadExtraDetailById', response);
    if (response.education?.length) {
      response.education = response.education.filter(
        (item) => item.degrees?.length && item.school_name
      );
    }
    return { data: response };
  },
  async fetchCompanyDetailById(id, headers) {
    const url = `${baseURL}v1/companies/${id}`;
    const updatedHeaders = { ...headers };
    updatedHeaders['X-Search-Client-Id'] = searchClientID;
    const response = await getRequest(url, {}, updatedHeaders);
    LogResponse('fetchCompanyDetailById', response);
    return { data: response };
  },
  async fetchLeadsByCompanyId(id, headers) {
    const url = `${baseURL}companies/get-lead-from-company/${id}`;
    const updatedHeaders = { ...headers };
    updatedHeaders['X-Search-Client-Id'] = searchClientID;
    const response = await getRequest(url, {}, updatedHeaders);
    LogResponse('fetchLeadsByCompanyId', response);
    return { data: response };
  },
  async fetchTotalLeads({ isCustomSelection, isJustPageChange, ...params }, headers) {
    const promises = [];
    const isFiltersAvailable = params?.filters?.length;
    if (isFiltersAvailable) {
      const leads = DiscoveryService.fetchLeadList(params, headers);
      promises.push(leads);

      if (!isJustPageChange) {
        const uniqueCompanies = DiscoveryService.fetchLeadCompaniesCount(params, headers);
        promises.push(uniqueCompanies);
      }
    }
    if ((params.initial_size && isCustomSelection) || !isFiltersAvailable) {
      const leadCount = DiscoveryService.fetchLeadsCount(
        {
          filters: params?.filters || [],
          initial_size: params.initial_size,
          leads_per_company: params.leads_per_company,
        },
        headers
      );
      promises.push(leadCount);
    }
    const responseArray = await Promise.allSettled(promises);
    const totalLeadsData = responseArray.reduce((acc, res) => ({ ...acc, ...res.value.data }), {});

    LogResponse('fetchTotalLeads', totalLeadsData);
    return totalLeadsData;
  },
  async fetchNetNewLeads({ isCustomSelection, isJustPageChange, ...params }, headers) {
    let response = { data: [] };
    if (params?.filters?.length) {
      const queryParams = updateSortingForSearch(params, 'lead');
      if (!isJustPageChange) {
        const leadCount = await DiscoveryService.fetchNetNewLeadIdCount(params, headers);
        if (leadCount.data) {
          response.total_results = leadCount.data?.count || 0;
        }
      }
      const leadList = await DiscoveryService.fetchLeadList(
        queryParams,
        { ...headers, 'X-User-Id': params.user_id },
        true
      );
      if (leadList.data?.data) {
        const { total_results, ...leadListdata } = leadList.data;
        response = { ...response, ...leadListdata };
      }
      if (isCustomSelection) {
        const leadCount = await DiscoveryService.fetchLeadsCount(
          {
            filters: queryParams.filters,
            initial_size: queryParams.initial_size,
            leads_per_company: queryParams.leads_per_company,
            is_net_new: true,
          },
          { ...headers, 'X-User-Id': params.user_id }
        );
        response = { ...response, count: leadCount.data?.count || 0 };
      }
    }
    LogResponse('fetchNetNewLeads', response);
    return response;
  },
  async fetchNetNewCompanies({ isJustPageChange, ...params }, headers) {
    let response = { data: [] };
    if (params?.filters?.length) {
      const queryParams = updateSortingForSearch(params, 'company');
      if (!isJustPageChange) {
        const companyCount = await DiscoveryService.fetchNetNewCompaniesIdCount(params, headers);
        if (companyCount.data) {
          response.total_results = companyCount.data?.count || 0;
        }
      }

      const companyList = await DiscoveryService.fetchCompanyList(
        queryParams,
        { ...headers, 'X-User-Id': params.user_id },
        true
      );
      if (companyList?.data) {
        const { total_results, ...companyListdata } = companyList;
        response = { ...response, ...companyListdata };
      }
    }
    LogResponse('fetchNetNewCompanies', response);

    return response;
  },
  async fetchTechnographicsById(id, headers) {
    try {
      const url = `${baseURL}v2/company/get-mappers-by-id?companyId=${id}`;
      const updatedHeaders = { ...headers };
      const response = await getRequest(url, {}, updatedHeaders);
      LogResponse('fetchLeadsByCompanyId', response);
      return { data: response };
    } catch (ex) {
      LogResponse('fetchLeadsByCompanyId', ex);
      return { error: ex.message };
    }
  },
  async fetchTechStatus(id, type, headers) {
    try {
      const url = `${baseURL}${endPoints.TECH_MOVEMENTS}/${id}/${type}`;
      const updatedHeaders = { ...headers };
      const response = await getRequest(url, {}, updatedHeaders);
      LogResponse('fetchTechStatus', response);
      return { data: response?.items ? response.items : { categories: {}, total: 0 } };
    } catch (ex) {
      LogResponse('fetchTechStatus', ex);
      return { error: ex.message };
    }
  },
  async fetchTwitter(twitterName, headers) {
    try {
      const url = `${baseURL}${endPoints.COMPANY_TWEETS}?tweeter_user=${twitterName}`;
      const updatedHeaders = { ...headers };
      const response = await getRequest(url, {}, updatedHeaders);
      LogResponse('fetchTwitter', response);
      return { data: response };
    } catch (ex) {
      LogResponse('fetchTwitter', ex);
      return { error: ex.message };
    }
  },
  async fetchCompanyRecommendation(companyId, headers) {
    try {
      const url = `${baseURL}${endPoints.COMPANY_RECOMMENDATION}/${companyId}`;
      const response = await getRequest(url, {}, headers);
      LogResponse('fetchCompanyRecommendation', response);
      return { data: response };
    } catch (ex) {
      LogResponse('fetchCompanyRecommendation', ex);
      return { error: ex.message };
    }
  },
  async fetchSmartListForExclusion(searchTerm, type, headers) {
    const url = `${baseURL}${endPoints.lists}?type=${type}&searchTerm=${searchTerm}`;
    const updatedHeaders = { ...headers };
    updatedHeaders['X-Search-Client-Id'] = searchClientID;
    const response = await getRequest(url, {}, updatedHeaders);
    LogResponse('fetchCompanyDetailById', response);
    return { data: response };
  },
};

const LogResponse = (message, response) => {
  // eslint-disable-next-line no-unused-expressions
  process.env.REACT_APP_ENV !== 'production' &&
    // eslint-disable-next-line no-console
    console.log(`🚀 ~ file: discoveryService.js ~ ${message} ~ response'`, response);
};

export default DiscoveryService;

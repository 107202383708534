import React, { useState, useEffect } from 'react';
import { Layout, Menu, Space, Tooltip, Typography } from 'antd';
import Common from 'containers/common/commonRedux';
import useProfile from 'UI/CustomHooks/Profile';
import { useLocation, Link as RouterLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './header.scss';
import UpgradeButton from 'components/common/upgradeButton';
import { LeftOutlined } from '@ant-design/icons';
import { NotificationBellIcon } from 'UI/CustomIcon/CustomIcon';
import HeadwayWidget from 'third-party/HeadwayWidget';
import { getCookie } from 'utils/cookie';
import scssVariables from 'src/_vars.scss';
import GlobalSearch from 'components/common/globalSearch';
import NotificationWindow from 'components/notification/notificationWindow';
import { TYPES_MAPPER } from 'constants/constants';

const { Text } = Typography;
const { Header: AntdHeader } = Layout;
const { technology: technologyPath, keyword: keywordPath } = TYPES_MAPPER;

const displayValueMap = {
  integrations: {
    link: '/my-account',
    name: 'Integrations',
  },
};

const headingMap = {
  'my-account': 'settings',
  'profile-deletion-request-compliance': 'opt outs',
  dashboard: 'dashboard',
  discover: 'discover',
  lists: 'lists',
  technologies: 'technologies',
  keywords: 'psychographics',
  'job-posting': 'job postings',
  [technologyPath]: 'technographics details',
  [keywordPath]: 'psychographics details',
};

// add values in displayValueMap for heading
const getDisplayValue = (location, history) => {
  // page title
  // need to add conditions in case the page url doesn't match with the page functionality
  const pathValues = location.pathname.split('/');

  let displayValue = pathValues.filter((path) => path.length > 0).shift();

  if (displayValue === 'list') {
    displayValue = pathValues.length > 2 ? 'smart list' : 'lists';
    if (pathValues.length > 2) {
      return (
        <RouterLink to="/list?type=lead,company">
          <Space>
            <LeftOutlined
              style={{
                color: scssVariables.toggleActiveColor,
              }}
              width="6px"
              height="10px"
            />
            <Text style={{ textTransform: 'capitalize' }}>{displayValue}</Text>
          </Space>
        </RouterLink>
      );
    }
  }

  if (displayValue === technologyPath || displayValue === keywordPath) {
    const backPath = history?.location?.state?.from || '';
    const parts = backPath.split('/') || [];
    const subcategory = parts[parts.length - 1] || '';
    return subcategory ? (
      <RouterLink to={backPath}>
        <Space>
          <LeftOutlined
            style={{
              color: scssVariables.toggleActiveColor,
            }}
            width="6px"
            height="10px"
          />
          <Tooltip placement="bottom" title={subcategory} mouseEnterDelay={0.3}>
            <Text ellipsis style={{ textTransform: 'capitalize', maxWidth: '250px' }}>
              {subcategory}
            </Text>
          </Tooltip>
        </Space>
      </RouterLink>
    ) : null;
  }

  const displayValueMeta = displayValueMap[displayValue];
  if (displayValueMeta) {
    return (
      <RouterLink to={displayValueMeta.link} className="breadcumb-link">
        <LeftOutlined className="breadcumb-icon" />
        <Text className="breadcumb-text">{displayValueMeta.name} </Text>
      </RouterLink>
    );
  }

  const heading = headingMap[displayValue] || '';
  return heading;
};

export default function Header() {
  const [profileDrawerVisibility, setProfileDrawerVisibility] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [notificationPanelVisibility, setNotificationPanelVisibility] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const displayValue = getDisplayValue(location, history);
  const planPermission = useSelector((state) => state.common.planPermission);
  // top right corner - user profile style
  const userProfileStyle = {
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    marginLeft: '20px',
    borderRadius: '50%',
    background: '#3C3A79',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#F64B53',
    fontSize: '16px',
  };
  const UserProfile = useProfile(userProfileStyle);
  const isSLSuperUser = getCookie('isSLSuperUser') === 'true';

  useEffect(() => {
    if (isSLSuperUser) {
      const root = document.documentElement;
      root.style.setProperty('--header-height', scssVariables.headerWithStripHeight);
      root.style.setProperty('--stripe-height', scssVariables.realStripHeight);
    }
  }, []);

  useEffect(() => {
    if (planPermission?.bannerMsg) {
      const root = document.documentElement;
      root.style.setProperty('--header-height', scssVariables.headerWithStripHeight);
      root.style.setProperty('--stripe-height', scssVariables.realStripHeight);
    }
  }, [planPermission?.bannerMsg]);

  // open/close the side user profile drawer
  const handleOnClick = (key) => {
    if (key === 'profile') {
      setProfileDrawerVisibility(!profileDrawerVisibility);
      setSelectedKeys(['profile']);
    } else if (key === 'notification') {
      setNotificationPanelVisibility(!notificationPanelVisibility);
    } else {
      setProfileDrawerVisibility(false);
      setSelectedKeys([]);
    }
  };

  return (
    <AntdHeader id="slintel-top-header" className="top-header">
      <div className="header-info-container header-title">
        <span className="header-page-title">
          {displayValue}
          {/* {displayValue && displayValue.startsWith('smart')
            ? (
              <RouterLink to={'/list?type="lead,company"'}>
                <Space>
                  <LeftOutlined
                    style={{
                      color: '#5E81F4',
                    }}
                    width="6px"
                    height="10px"
                  />
                  <Text style={{ textTransform: 'capitalize' }}>
                    {displayValue}
                  </Text>
                </Space>
              </RouterLink>
            )
            : displayValue} */}
        </span>
      </div>
      <GlobalSearch />
      <NotificationWindow
        visible={notificationPanelVisibility}
        toggleVisibility={() => setNotificationPanelVisibility(!notificationPanelVisibility)}
      />
      <div className="header-info-container header-actions">
        <UpgradeButton permission={planPermission} onClick={() => null} />
        <HeadwayWidget />
        <button
          type="button"
          shape="circle"
          onClick={() => setNotificationPanelVisibility(!notificationPanelVisibility)}
          className={`notification-icon-container flex-center ${
            notificationPanelVisibility ? 'active' : ''
          }`}
        >
          <div className="notification-icon">
            <NotificationBellIcon width="22" height="22" fill="#697390" />
            <div className="notification-more-dot" />
          </div>
        </button>
        {/* </Popover> */}
        <Menu
          className="top-header__menu-container"
          onClick={({ key }) => handleOnClick(key)}
          selectedKeys={selectedKeys}
        >
          <Menu.Item key="profile" title="Profile">
            {UserProfile}
          </Menu.Item>
        </Menu>
        <Common
          toggle={() => {}}
          isCreditDrawer={false}
          profileToggle={() => {
            setProfileDrawerVisibility(!profileDrawerVisibility);
          }}
          isProfile={profileDrawerVisibility}
        />
      </div>
      {isSLSuperUser && (
        <div id="superUserAlertDiv">
          --- You are a SUPER USER. With Great Power Comes Great Responsibility ---
        </div>
      )}
      {!isSLSuperUser && planPermission?.bannerMsg && (
        <div id="bannerMsgAlertDiv">{planPermission.bannerMsg}</div>
      )}
    </AntdHeader>
  );
}

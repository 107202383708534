/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import { showError } from 'components/common/errorMessage';
import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { baseURL } from 'api/apiEndpoints';
import { getRequest } from 'api/apiRequests';
import { debounce, headerGenerator } from 'utils/common';
import MultipleSelect from './multipleSelect';

const DynamicAutoSuggest = ({
  apiEndPoint = '',
  optionList = [],
  defaultOptionList,
  formatResults,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const headers = headerGenerator(user.token, user.session_id);

  useEffect(() => {
    setOptionList();
  }, []);

  const setOptionList = () => {
    if (!apiEndPoint && (optionList.length || typeof optionList === 'object')) {
      setOptions(optionList);
    }
  };

  const getOptionsFormat = ({ data }) => {
    // Call Custom formatter for formatting data;
    if (Array.isArray(data)) {
      return data.map((list) => ({ ...list._source, type: list._type }));
    }
    if (Array.isArray(data.data)) {
      if (typeof data.data[0] === 'string') {
        return data.data;
      }
      return data.data?.map((list) => list._source) || [];
    }
    return data;
  };

  const resetDropdownOptions = (open, isLoading = false) => {
    if (isLoading) {
      setLoading(true);
    }
    return !open && setOptions([]);
  };

  // eslint-disable-next-line no-shadow
  const handleSearch = async (value) => {
    if (!value || !apiEndPoint) {
      return;
    }
    const url = `${baseURL}${apiEndPoint}`;
    setLoading(true);

    const response = await getRequest(url, { q: value }, headers);
    setLoading(false);
    if (response.error) {
      showError(response.message);
      return;
    }
    if (response) {
      const suggestions = getOptionsFormat(response);
      setOptions(suggestions);
    } else {
      setOptions([]);
    }
  };

  return (
    <MultipleSelect
      {...props}
      staticOptions={!apiEndPoint}
      options={options}
      handleSearch={debounce(handleSearch, 500)}
      loading={loading}
      resetDropdownOptions={resetDropdownOptions}
    />
  );
};

export default DynamicAutoSuggest;

/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Space, Skeleton } from 'antd';
import Drawer from 'UI/CustomDrawer/CustomDrawer';
import Avatar from 'UI/Avatar';
import {
  SmallCloseIcon,
  CompanyIcon,
  EmployeeIcon,
  JobPostingIcon,
  ReportIcon,
} from 'UI/CustomIcon/CustomIcon';
import OpenerButton from 'UI/OpenerButton';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Thumbnail from 'UI/Thumbnail';
import SocialLinks from 'UI/SocialLinks';
import { DiscoveryService, CommonService } from 'api/services';
import { headerGenerator, getPlanConfig, getEmailScoreValue, getClickableLink } from 'utils/common';
import { showError } from 'components/common/errorMessage';
// TODO: can move to common from discovery?
import AddToListButton from 'components/discovery/actions/AddToListButton';
import ExportPopover from 'components/discovery/actions/ExportPopover/ExportPopover';
import ExtraActionPopover from 'components/discovery/actions/ExtraActionPopover';
import CompanyFollowButtom from 'components/discovery/actions/CompanyFollowButtom';
import { openReportDrawer } from 'containers/common/commonActions';
import { LEAD_SORTING_OPTIONS, SORT_ORDERS, QUICKVIEW_MODULES, LEAD } from 'constants/constants';
import { eventManager, eventList, eventActions } from 'utils/eventManager';
import LeadQuickView from './LeadQuickView';
import CompanyQuickView from './CompanyQuickView';

const {
  fetchCompanyDetailById,
  fetchLeadDetailById,
  fetchLeadExtraDetailById,
  fetchLeadList,
  fetchTechnographicsById,
} = DiscoveryService;
const { Title } = Typography;

// TODO: move to a common place and export
const defaultData = { data: [], total_results: 0 };
const {
  discover: discoverPage,
  dashboard: dashboardPage,
  smartList: smartListPage,
  jobPosting: jobPostingPage,
} = QUICKVIEW_MODULES;

const QuickViewContent = ({
  closeQuickView,
  goToNextPage,
  goToPrevPage,
  isNextPrevVisible,
  openAddToList,
  openQuickView,
  pageSize,
  showAddToList,
  storeData,
  module,
  tab,
  updateLead,
}) => {
  const { t } = useTranslation(['quickview']);
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const headers = headerGenerator(user.token, user.session_id);
  const {
    quickView,
    viewName,
    leads: leadsData = defaultData,
    companies: companiesData = defaultData,
    netNewLeads = defaultData,
    netNewCompanies = defaultData,
    defaultInnerActiveKey,
    page,
  } = storeData;
  const showLeadAddToList = module !== QUICKVIEW_MODULES.smartList || viewName !== LEAD;

  const [quickViewData, setQuickViewData] = useState({});
  const [leads, setLeads] = useState(defaultData);
  const [leadsExtraDetails, setLeadsExtraDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [technographicsData, setTechnographicsData] = useState({});

  const dispatch = useDispatch();

  const getAllIds = (items) => {
    if (Array.isArray(items?.data)) {
      return JSON.stringify(items.data.map((item) => item.id.value));
    }
    return '[]';
  };
  const getViewData = {
    lead: {
      total: leadsData,
      net_new: netNewLeads,
    },
    company: {
      total: companiesData,
      net_new: netNewCompanies,
    },
  };
  const $leads = viewName === 'lead' ? getViewData[viewName][defaultInnerActiveKey] : leadsData;
  const $companies =
    viewName === 'company' ? getViewData[viewName][defaultInnerActiveKey] : companiesData;

  useEffect(() => {
    if (quickView.isQuickView && quickView.data?.id?.value) {
      if (viewName === 'lead') {
        if (quickView.viewName === 'lead') {
          fetchLeadData(quickView.data.id.value);
        } else if (quickView.viewName === 'company') {
          if (
            quickView &&
            quickView.data &&
            quickView.data.company_id &&
            quickView.data.company_id.value
          )
            fetchCompanyData(quickView.data.company_id.value);
        }
      } else if (viewName === 'company') {
        fetchCompanyData(quickView.data.id.value);
      }
    }
  }, [viewName, quickView.viewName, quickView.isQuickView, quickView.data.id]);

  useEffect(() => {
    keepOpenQuickView();
  }, [getAllIds($leads), getAllIds($companies)]);

  const planConfig = getPlanConfig();
  const LIST_PAGE_SIZE = pageSize || planConfig.pageSize;

  const keepOpenQuickView = () => {
    const { data, viewName: quickViewName, actionType, isQuickView } = quickView;
    if (!isQuickView) {
      return;
    }
    const newViewIndex = !data?.index && actionType === 'PREV' ? LIST_PAGE_SIZE - 1 : 0;

    if (quickViewName === 'lead' && $leads.data?.length && data.index >= 0) {
      const rowData = { ...$leads.data[newViewIndex], index: newViewIndex };
      openQuickView({ view: quickViewName, data: rowData });
      return;
    }
    if (quickViewName === 'company' && $companies.data?.length && data.index >= 0) {
      const rowData = { ...$companies.data[newViewIndex], index: newViewIndex };
      openQuickView({ view: quickViewName, data: rowData });
      return;
    }
    closeQuickView({ view: quickViewName });
  };

  const fetchLeadData = async (id) => {
    setIsLoading(true);
    const response = await fetchLeadDetailById(id, headers);
    if (response.data?.error) {
      showError(response.data?.message);
      closeQuickView({ view: tab });
      return;
    }
    const dataId = response.data?.id?.value;
    const notAvailableInfo = await CommonService.getNotAvailableInstaInfo([dataId], headers);

    response.data.emailNotAvailable = false;
    response.data.phoneNotAvailable = false;
    if (notAvailableInfo.emails.includes(dataId)) {
      response.data.emailNotAvailable = true;
    }
    if (notAvailableInfo.phoneNumbers.includes(dataId)) {
      response.data.phoneNotAvailable = true;
    }

    setQuickViewData(response.data);
    setIsLoading(false);
    fetchLeadExtraDetail(id);
    if (response.data?.company_id?.value) {
      fetchTechnographicsData(response.data.company_id.value);
    }
  };

  const fetchLeadExtraDetail = async (id) => {
    const response = await fetchLeadExtraDetailById(id, headers);
    if (response.data?.error) {
      showError(response.data?.message);
      return;
    }
    setLeadsExtraDetails(response.data);
  };

  const sendEvent = (action) => {
    eventManager.track(eventList.QUICK_VIEW_ACTIONS, {
      payload: {
        tab,
        type:
          quickView.viewName === 'lead'
            ? eventActions.LEAD_QUICK_VIEW
            : eventActions.COMPANY_QUICK_VIEW,
        navigation: defaultInnerActiveKey,
        action,
        version: eventActions.NDF,
        ...(viewName === 'company'
          ? { company_id: quickViewData.id.value }
          : { lead_id: quickViewData.id.value }),
        module,
      },
    });
  };

  const fetchCompanyData = async (id) => {
    setIsLoading(true);
    const response = await fetchCompanyDetailById(id, headers);
    if (response.data.error) {
      showError(response.data.message);
      closeQuickView({ view: viewName });
      return;
    }
    setQuickViewData(response.data);
    setIsLoading(false);
    if (response.data && response.data.id && response.data.id.value) {
      fetchLeads(response.data.id.value);
    }
    fetchTechnographicsData(id);
  };
  const fetchTechnographicsData = async (id) => {
    const response = await fetchTechnographicsById(id, headers);
    if (response.error) {
      showError(response.message);
      return;
    }
    setTechnographicsData(response.data);
  };
  const handleExtraActionBtnClick = (action) => {
    switch (action) {
      case 'View Jobs':
        if (quickViewData.company_name && quickViewData.company_name.value) {
          // \ is used at end to continue template strings
          // even though code is split onto new line
          const url = `/job-posting?\
company_name_keyword=${quickViewData.company_name.value}`;
          sendEvent(eventActions.VIEW_JOBS);
          window.open(url, '_blank');
        }
        break;
      case 'View People':
        if (quickViewData?.company_name?.value && quickViewData?.id?.value) {
          // dispatch(tabChange('lead'));
          // history.push({
          //   pathname: '/discover/lead',
          //   search: `company_id=${quickViewData.id.value}`,
          // });
          // TODO: if the page is discovery do not open new tab
          const url = `/discover/lead?company_id=${quickViewData.id.value}\
&company_name=${quickViewData.company_name.value}`;
          sendEvent(eventActions.VIEW_LEADS);
          if (module !== 'Discover') {
            window.open(url, '_blank');
          } else {
            window.location.href = url;
          }
        }
        break;
      // TODO: check this logic
      case 'Report': {
        const repostPayload = {};
        if (quickView.viewName === 'lead') {
          repostPayload.flaggedFieldOriginalValue = quickViewData.name
            ? quickViewData.name.value
            : '';
          repostPayload.slintelObjectIdentifier = quickViewData.id ? quickViewData.id.value : '';
          repostPayload.objectLinkedinIdentifier = quickViewData.linkedin_url
            ? quickViewData.linkedin_url.value
            : '';
          repostPayload.objectType = 'lead';
        } else {
          repostPayload.flaggedFieldOriginalValue = quickViewData.company_name
            ? quickViewData.company_name.value
            : '';
          repostPayload.slintelObjectIdentifier = quickViewData.id ? quickViewData.id.value : '';
          repostPayload.objectLinkedinIdentifier = quickViewData.company_linkedin_page
            ? quickViewData.company_linkedin_page.value
            : '';
          repostPayload.objectType = 'company';
        }
        dispatch(openReportDrawer(repostPayload));
        break;
      }
      default:
      // no default case as of now
      // console.log('default');
    }
  };

  const fetchLeads = async (id) => {
    const queryParams = {
      fields: [
        'name',
        'lead_titles',
        'email',
        'phone',
        'work_phone',
        'linkedin_url',
        'company_website',
        'company_name',
      ],
      filters: [
        {
          key: 'company_id',
          query: id,
        },
      ],
      pagination: {
        page: 1,
        size: 25,
      },
      sort: [
        {
          key: LEAD_SORTING_OPTIONS[0].key,
          order: SORT_ORDERS[1].key,
        },
      ],
    };

    const response = await fetchLeadList(queryParams, headers);
    if (response.error) {
      showError(response.message);
      return;
    }
    let updateData = defaultData;
    if (response.data && response.data.total_results) {
      const leadIds = response.data.data.map((item) => item.id.value);
      const notAvailableInfo = await CommonService.getNotAvailableInstaInfo(leadIds, headers);

      updateData = response.data.data.map((item) => {
        item.emailNotAvailable = false;
        item.phoneNotAvailable = false;
        if (notAvailableInfo.emails.includes(item.id.value)) {
          item.emailNotAvailable = true;
        }
        if (notAvailableInfo.phoneNumbers.includes(item.id.value)) {
          item.phoneNotAvailable = true;
        }
        return item;
      });
    }
    setLeads({ ...response.data, data: updateData });
  };

  const getLeadfromStore = (id) => {
    return $leads.data.find((value) => {
      return value.id.value === id;
    });
  };

  // TODO: test for smart list companies
  const requestEmail = (id, value) => {
    const { email, emailScore } = value;
    const lead = getLeadfromStore(id);
    if (quickView.viewName === 'lead') {
      const quickViewDataClone = { ...quickViewData };
      if (quickViewDataClone.id.value === id) {
        if (email) {
          if (!quickViewDataClone.email) {
            quickViewDataClone.email = {
              value: '',
            };
          }
          quickViewDataClone.email = { value: email };
          quickViewDataClone.email_score = { value: emailScore };
          lead.email = { value: email };
          lead.email_score = { value: emailScore };
          lead.emailScore = getEmailScoreValue(emailScore);
        } else {
          quickViewDataClone.emailNotAvailable = true;
        }
        lead.emailNotAvailable = true;
      }
      setQuickViewData(quickViewDataClone);
      updateLead(lead);
    } else if (quickView.viewName === 'company') {
      const updatedLeades = leads.data.map((item) => {
        if (item.id.value === id) {
          if (email) {
            item.email = {
              value: email,
            };
          } else {
            item.emailNotAvailable = true;
          }
        }
        return item;
      });
      setLeads({ ...leads, data: updatedLeades });
    }
    sendEvent(eventActions.INSTA_REVEAL_EMAIL);
  };

  const requestPhoneNumber = (id, value) => {
    const lead = getLeadfromStore(id);
    if (quickView.viewName === 'lead') {
      const quickViewDataClone = { ...quickViewData };
      if (quickViewDataClone.id.value === id) {
        if (value) {
          if (!quickViewDataClone.phone) {
            quickViewDataClone.phone = {
              value: '',
            };
          }
          quickViewDataClone.phone.value = value;
          lead.phone = {};
          lead.phone.value = value;
        } else {
          quickViewDataClone.phoneNotAvailable = true;
        }
        lead.phoneNotAvailable = true;
      }
      setQuickViewData(quickViewDataClone);
      updateLead(lead);
    } else if (quickView.viewName === 'company') {
      const updatedLeades = leads.data.map((item) => {
        if (item.id.value === id) {
          if (value) {
            if (!item.phone) {
              item.phone = {
                value: '',
              };
            }
            item.phone.value = value;
          } else {
            item.phoneNotAvailable = true;
          }
        }
        return item;
      });
      setLeads({ ...leads, data: updatedLeades });
    }
    sendEvent(eventActions.INSTA_REVEAL_PHONE);
  };

  const getTitle = () => {
    const { data, viewName: quickViewName, isViewLoading } = quickView;
    const totalRows = quickViewName === 'lead' ? $leads.total_results : $companies.total_results;
    const TOTAL_ITEMS = totalRows >= planConfig.total ? planConfig.total : totalRows;
    const TOTAL_LIST_PAGE = Math.ceil(TOTAL_ITEMS / LIST_PAGE_SIZE);
    const ITEMS_ON_PAGE =
      page === TOTAL_LIST_PAGE ? TOTAL_ITEMS - (page - 1) * LIST_PAGE_SIZE : LIST_PAGE_SIZE;

    const showPreviousView = () => {
      const prev = data.index - 1;
      const rowData = quickViewName === 'lead' ? $leads.data[prev] : $companies.data[prev];
      if (rowData) {
        openQuickView({ view: quickViewName, data: { ...rowData, index: prev } });
      } else if (page > 1 && data.index === 0) {
        openQuickView({ view: quickViewName, data, loading: true, actionType: 'PREV' });
        goToPrevPage(page - 1);
      }
    };

    const showNextView = () => {
      const next = data.index + 1;
      const rowData = quickViewName === 'lead' ? $leads.data[next] : $companies.data[next];
      if (rowData) {
        openQuickView({ view: quickViewName, data: { ...rowData, index: next } });
      } else if (page < TOTAL_LIST_PAGE && data.index === LIST_PAGE_SIZE - 1) {
        openQuickView({ view: quickViewName, data, loading: true, actionType: 'NEXT' });
        goToNextPage(page + 1);
      }
    };

    const nextPrevBlock = data?.index >= 0 && (
      <div className="prev-next">
        <OpenerButton
          disabled={data?.index === 0 && page === 1}
          onOpenClick={showPreviousView}
          direction="left"
          shape="square"
          className="quick-view-arrow-btn"
        />
        <OpenerButton
          disabled={data?.index === ITEMS_ON_PAGE - 1 && page === TOTAL_LIST_PAGE}
          onOpenClick={showNextView}
          direction="right"
          shape="square"
          className="quick-view-arrow-btn"
        />
      </div>
    );

    let extraMenuItems = [];
    if (isLoading || isViewLoading) {
      return <Skeleton paragraph={{ rows: 2 }} active />;
    }
    if (quickViewName === 'company') {
      extraMenuItems = [
        {
          icon: EmployeeIcon,
          title: 'View People',
          isDisabled: !(quickViewData?.lead_count?.value > 0),
        },
        {
          icon: JobPostingIcon,
          title: 'View Jobs',
          isDisabled: !(quickViewData?.job_count?.value > 0),
        },
        // TODO: test this in another dev PC. getting 400 error
        { icon: ReportIcon, title: 'Report' },
      ];
    }

    return quickViewName === 'lead' ? (
      <>
        <div className="quick-view-title" style={{ background: '#F8FAFF' }}>
          <Space size={16}>
            <Avatar title={quickViewData?.name?.value} />
            <Space direction="vertical">
              <Title className="main-title" level={4} style={{ width: 280 }}>
                {quickViewData?.name?.value}
              </Title>
              <SocialLinks showText={false} data={quickViewData} viewName={quickViewName} />
            </Space>
            {isNextPrevVisible ? nextPrevBlock : null}
          </Space>

          <div onClick={() => closeQuickView({ view: quickViewName })} className="close-btn">
            <SmallCloseIcon width="10px" fill="#697390" />
          </div>
        </div>
        <div className="quick-action-bar-fixed">
          <Space size={10}>
            {showAddToList ? (
              <AddToListButton
                onAddToList={() => {
                  openAddToList({
                    ids: [quickViewData?.id?.value],
                    listType: 'lead',
                    source: 'quick_view',
                  });
                  sendEvent(eventActions.ADD_TO_SMART_LIST);
                }}
                source="quick_view"
              />
            ) : null}
            {/* TODO: test this */}
            <ExportPopover
              tab="lead"
              id={quickViewData?.id?.value}
              source="quickview"
              onClick={() => sendEvent(eventActions.EXPORT)}
            />
          </Space>
          <ExtraActionPopover viewName={viewName} handleClick={handleExtraActionBtnClick} />
        </div>
      </>
    ) : (
      <>
        <div className="quick-view-title">
          <Space size={16}>
            {quickViewData.company_profile_image_url &&
            quickViewData.company_profile_image_url.value ? (
              <Thumbnail.Image
                imageUrl={getClickableLink(quickViewData.company_profile_image_url.value)}
                size="medium"
              />
            ) : (
              <Thumbnail.Icon Icon={CompanyIcon} size="big" />
            )}
            <Space direction="vertical">
              <Title className="main-title" level={4} style={{ width: 280 }}>
                {quickViewData?.company_name?.value}
              </Title>
              <SocialLinks showText={false} data={quickViewData} viewName={quickViewName} />
            </Space>
            {isNextPrevVisible ? nextPrevBlock : null}
          </Space>
          <div onClick={() => closeQuickView({ view: tab })} className="close-btn">
            <SmallCloseIcon width="10px" fill="#697390" />
          </div>
        </div>
        <div className="quick-action-bar-fixed">
          <Space size={8}>
            {showAddToList ? (
              <AddToListButton
                onAddToList={() => {
                  openAddToList({
                    ids: [quickViewData?.id?.value],
                    listType: 'company',
                    source: 'quick_view',
                  });
                  sendEvent(eventActions.ADD_TO_SMART_LIST);
                }}
                source="quick_view"
              />
            ) : null}
            <ExportPopover
              tab="company"
              id={quickViewData?.id?.value}
              source="quickview"
              onClick={() => sendEvent(eventActions.EXPORT)}
            />
            <CompanyFollowButtom
              id={quickViewData?.id?.value}
              companyName={quickViewData?.company_name?.value}
              sendEvent={sendEvent}
              translation={t}
            />
          </Space>
          <ExtraActionPopover
            viewName={quickView.viewName}
            menuItems={extraMenuItems}
            handleClick={handleExtraActionBtnClick}
          />
        </div>
      </>
    );
  };

  const getContent = () => {
    if (quickView.isViewLoading) {
      return <Skeleton paragraph={{ rows: 15 }} active />;
    }
    return quickView.viewName === 'lead' ? (
      <LeadQuickView
        quickViewData={quickViewData}
        leadsExtraDetails={leadsExtraDetails}
        requestPhoneNumber={requestPhoneNumber}
        requestEmail={requestEmail}
        viewName={quickView.viewName}
        technographicsData={technographicsData}
        sendEvent={sendEvent}
      />
    ) : (
      <CompanyQuickView
        quickViewData={quickViewData}
        leads={leads}
        requestPhoneNumber={requestPhoneNumber}
        requestEmail={requestEmail}
        viewName={quickView.viewName}
        technographicsData={technographicsData}
        sendEvent={sendEvent}
        openAddToList={openAddToList}
        showLeadAddToList={showLeadAddToList}
      />
    );
  };

  return quickView.isQuickView ? (
    <Drawer
      content={!isLoading ? getContent() : <Skeleton paragraph={{ rows: 15 }} active />}
      title={getTitle()}
      visible={quickView.isQuickView}
      sidedrawerwidth={420}
      getContainer={false}
      mask={false}
      headerStyle={{ background: '#F8FAFF' }}
      style={{ position: 'absolute' }}
      className="quick-view-drawer"
    />
  ) : null;
};

QuickViewContent.propTypes = {
  tab: PropTypes.string,
  storeData: PropTypes.object.isRequired,
  module: PropTypes.oneOf([dashboardPage, discoverPage, smartListPage, jobPostingPage]).isRequired,
};

export default QuickViewContent;

/* eslint-disable no-nested-ternary */
import React from 'react';
import { Typography, Space, Button } from 'antd';
import {
  PhoneIcon,
  EmailIcon,
  LocationIcon,
  SectorIcon,
  IndustryIcon,
  CompanyTabIcon,
  RevenueIcon,
  YearIcon,
  EmployeeIcon,
  AddToListIcon,
  ExportIcon,
} from 'UI/CustomIcon/CustomIcon';
import QuickViewCardSecond from 'UI/QuickViewCardSecond/QuickViewCardSecond';
import KeyValueWithIcon from 'UI/KeyValueWithIcon/KeyValueWithIcon';
import { getInitials } from 'utils/common';
import {
  ACTION_BUTTON,
  ADD_TO_SMART_LIST,
  EXPORT,
  EXPORT_POPOVER_SOURCE,
  LEAD,
} from 'constants/constants';
import InstaRevealPhone from 'components/common/instaRevealPhone';
import InstaRevealEmail from 'components/common/instaRevealEmail';
import TechnographicsView from 'components/discovery/quickView/TechnographicsView/TechnographicsView';
import { eventActions } from 'utils/eventManager';
import PillsGroup from 'UI/PillsGroup/PillsGroup';
import { APP_ROUTE_SRC } from 'constants/multimedia';

const { Text, Paragraph } = Typography;

// const aboutDataList = [
//   { title: 'Type', value: 'Private', icon: <CompanyTabIcon width="15px" fill="#697390" /> },
//   { title: 'Industry', value: 'Ecommerce', icon: <IndustryIcon width="15px" fill="#697390" /> },
//   {
//     title: 'Sector',
//     value: 'Healthcare and Lifesciences',
//     icon: <SectorIcon width="15px" fill="#697390" />,
//   },
//   { title: 'Revenue', value: '$1M to $10M', icon: <RevenueIcon width="15px" fill="#697390" /> },
//   { title: 'Fiscal year', value: 'Jan-Dec', icon: <YearIcon width="15px" fill="#697390" /> },
//   { title: 'Employees', value: '10,000+', icon: <EmployeeIcon width="15px" fill="#697390" /> },
// ];

// const fundingData = [
//   {
//     titleIcon: {
//       size: 'small',
//       Icon: FundingIcon,
//       backgroundColor: '#E3CA68',
//       iconColor: 'white',
//     },
//     title: 'Angel Investment',
//     subTitle: '05 Jan, 2020',
//     isActionBtn: true,
//     isSingleActionBtn: true,
//     content: [
//       {
//         title: 'Funding amount',
//         value: <Text>10M</Text>,
//         icon: <AmountIcon width="15px" fill="#697390" />,
//       },
//       {
//         title: 'Investors',
//         value: <Text>Manhattan Venture Partners Soft Bank Vision Fund</Text>,
//         icon: <InvestorIcon width="15px" fill="#697390" />,
//       },
//     ],
//   },
//   {
//     titleIcon: {
//       size: 'small',
//       Icon: FundingIcon,
//       backgroundColor: '#E3CA68',
//       iconColor: 'white',
//     },
//     title: 'Angel Investment',
//     subTitle: '05 Jan, 2020',
//     isActionBtn: true,
//     isSingleActionBtn: true,
//     content: [
//       {
//         title: 'Funding amount',
//         value: <Text>10M</Text>,
//         icon: <AmountIcon width="15px" fill="#697390" />,
//       },
//       {
//         title: 'Investors',
//         value: <Text>Manhattan Venture Partners Soft Bank Vision Fund</Text>,
//         icon: <InvestorIcon width="15px" fill="#697390" />,
//       },
//     ],
//   },
// ];

// const executiveData = [
//   {
//     titleIcon: {
//       size: 'small',
//       Icon: 'DE',
//       backgroundColor: '#38C5AF',
//       iconColor: 'white',
//     },
//     title: 'Jonathan Nolan',
//     subTitle: 'UX Manager',
//     isActionBtn: true,
//     content: [
//       {
//         title: 'Insta reveal email',
//         value: '                ',
//         icon: <EmailIcon width="15px" fill="#697390" />,
//       },
//       {
//         title: 'Insta reveal email',
//         value: '                ',
//         icon: <PhoneIcon width="15px" fill="#697390" />,
//       },
//     ],
//   },
// ];

const CompanyQuickView = ({
  tab,
  quickViewData,
  leads,
  requestPhoneNumber,
  requestEmail,
  viewName,
  technographicsData = {},
  sendEvent,
  openAddToList,
  showLeadAddToList = true,
}) => {
  const getInstaEmailRes = (res, item) => {
    requestEmail(item.id.value, res);
  };

  const getInstaPhoneRes = (res, item) => {
    requestPhoneNumber(item.id.value, res);
  };

  const seeMoreLeads = () => {
    const companyName = quickViewData?.company_name?.value;
    const companyId = quickViewData?.id?.value;
    if (companyName && companyId) {
      const url = `/discover/lead?company_id=${companyId}&company_name=${companyName}`;
      window.open(url, '_blank');
      sendEvent(eventActions.FIND_LEADS);
    }
  };

  const getActionButtons = (list) => {
    const actions = [];
    if (showLeadAddToList) {
      actions.push({
        icons: [AddToListIcon],
        toggle: false,
        actions: ACTION_BUTTON.ADD_TO_LIST,
        onClick: () => {
          openAddToList({
            ids: [list.id.value],
            listType: LEAD,
            source: EXPORT_POPOVER_SOURCE.QUICKVIEW,
          });
          sendEvent(eventActions.ADD_TO_SMART_LIST);
        },
        tooltip: true,
        title: ADD_TO_SMART_LIST,
      });
    }
    actions.push({
      icons: [ExportIcon],
      toggle: false,
      actions: ACTION_BUTTON.EXPORT,
      onClick: () => {
        sendEvent(eventActions.EXPORT);
      },
      tooltip: true,
      title: EXPORT,
      data: list,
    });
    return actions;
  };

  const getContent = () => {
    return (
      <>
        <Space className="space-full-width" direction="vertical" size={20}>
          {/* <div className="quick-action-bar">
            <Space size={8}>
              <AddToListButton
                onAddToList={() =>
                  dispatch(openAddtoList({ ids: [quickViewData?.id?.value], listType: viewName }))
                }
              />
              <ExportPopover tab="company" id={quickViewData.id && quickViewData.id.value} />
            </Space>
            <ExtraActionPopover viewName={viewName} handleClick={handleExtraActionBtnClick} />
          </div> */}
          <div className="quick-view-content">
            <Space className="space-full-width" direction="vertical" size={32}>
              {(quickViewData.company_address && quickViewData.company_address.value) ||
              (quickViewData.company_phone_number && quickViewData.company_phone_number.value) ? (
                <div className="quick-view-info">
                  <Space direction="vertical" size={6}>
                    {/* <div className="info-block">
                    <div>
                      <EmailIcon width="15px" fill="#697390" />
                    </div>
                    <div className="info-content-block">
                      <div className="content-title">
                        <div className="content-key">
                          <Copy text="info@flipkart.com" element={<Text className="info-text">info@flipkart.com</Text>} />
                        </div>
                      </div>
                    </div>
                  </div> */}

                    {/* {quickViewData.company_website && quickViewData.company_website.value ? (
                    <div className="info-block">
                      <div>
                        <SocialLinksIcon width="15px" fill="#697390" />
                      </div>
                      <div className="info-content-block">
                        <div className="content-title">
                          <div className="content-key">
                            <Space split={<Divider type="vertical" />} size={2}>
                              <a
                                className="info-text"
                                target="_blank"
                                href={getClickableLink(quickViewData.company_website.value)}
                                rel="noreferrer"
                              >
                                {quickViewData.company_website.value}
                              </a>
                              <Space size={8}>
                                {quickViewData.company_linkedin_page &&
                                quickViewData.company_linkedin_page.value ? (
                                  <a
                                    target="_blank"
                                    href={getClickableLink(
                                      quickViewData.company_linkedin_page.value
                                    )}
                                    rel="noreferrer"
                                  >
                                    <LinkedinIcon
                                      width="15px"
                                      fill="#697390"
                                      className="socialmedia-icon"
                                    />
                                  </a>
                                ) : null}
                                {quickViewData.company_twitter_page &&
                                quickViewData.company_twitter_page.value ? (
                                  <a
                                    target="_blank"
                                    href={getClickableLink(
                                      quickViewData.company_twitter_page.value
                                    )}
                                    rel="noreferrer"
                                  >
                                    <TwitterIcon
                                      width="15px"
                                      fill="#697390"
                                      className="socialmedia-icon"
                                    />
                                  </a>
                                ) : null}
                                {quickViewData.company_facebook_page &&
                                quickViewData.company_facebook_page.value ? (
                                  <a
                                    target="_blank"
                                    href={getClickableLink(
                                      quickViewData.company_facebook_page.value
                                    )}
                                    rel="noreferrer"
                                  >
                                    <FacebookIcon
                                      width="15px"
                                      fill="#697390"
                                      className="socialmedia-icon"
                                    />
                                  </a>
                                ) : null}
                              </Space>
                            </Space>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null} */}

                    {quickViewData.company_phone_number &&
                    quickViewData.company_phone_number.value ? (
                      <div className="info-block">
                        <div>
                          <PhoneIcon width="15px" fill="#697390" />
                        </div>
                        <div className="info-content-block">
                          <div className="content-title">
                            {/* <Copy
                              text={quickViewData.company_phone_number.value}
                              element={
                                <Text className="info-text">
                                  {quickViewData.company_phone_number.value}
                                </Text>
                              }
                            /> */}
                            <Text className="info-text">
                              {quickViewData.company_phone_number.value}
                            </Text>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {quickViewData.company_address && quickViewData.company_address.value && (
                      <div className="info-block">
                        <LocationIcon width="15px" fill="#697390" />
                        <div className="info-content-block">
                          <div className="content-title flex-column">
                            <Text>{quickViewData.company_address.value}</Text>
                            {quickViewData.company_location_text?.value && (
                              <div className="mt-1">
                                {quickViewData.postal_code?.value
                                  ? quickViewData.company_location_text.value.concat(
                                      ', ',
                                      quickViewData.postal_code.value
                                    )
                                  : quickViewData.company_location_text.value}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </Space>
                </div>
              ) : null}

              <div className="about-view">
                <Space direction="vertical" size={16}>
                  <Text className="quick-view-content-header">ABOUT</Text>
                  {quickViewData.company_description && quickViewData.company_description.value ? (
                    <Paragraph
                      className="drawer-desc"
                      ellipsis={{
                        rows: 3,
                        expandable: true,
                        symbol: 'Read more',
                      }}
                    >
                      {quickViewData.company_description.value}
                    </Paragraph>
                  ) : null}

                  <div className="table-info-block">
                    <Space direction="vertical" size={16}>
                      {quickViewData.company_type && quickViewData.company_type.value ? (
                        <KeyValueWithIcon
                          item={{
                            title: 'Type',
                            value: quickViewData.company_type.value,
                            icon: <CompanyTabIcon width="15px" fill="#697390" />,
                          }}
                        />
                      ) : null}

                      {quickViewData.company_industry && quickViewData.company_industry.value ? (
                        <KeyValueWithIcon
                          item={{
                            title: 'Industry',
                            value: quickViewData.company_industry.value,
                            icon: <IndustryIcon width="15px" fill="#697390" />,
                          }}
                        />
                      ) : null}

                      {quickViewData.company_sector && quickViewData.company_sector.value && (
                        <KeyValueWithIcon
                          item={{
                            title: 'Sector',
                            value: quickViewData.company_sector.value,
                            icon: <SectorIcon width="15px" fill="#697390" />,
                          }}
                        />
                      )}

                      {quickViewData.revenue_range && quickViewData.revenue_range.value ? (
                        <KeyValueWithIcon
                          item={{
                            title: 'Revenue',
                            value: quickViewData.revenue_range.value,
                            icon: <RevenueIcon width="15px" fill="#697390" />,
                          }}
                        />
                      ) : null}

                      {quickViewData.fiscal_year && quickViewData.fiscal_year.value ? (
                        <KeyValueWithIcon
                          item={{
                            title: 'Fiscal year',
                            value: quickViewData.fiscal_year.value,
                            icon: <YearIcon width="15px" fill="#697390" />,
                          }}
                        />
                      ) : null}

                      {quickViewData.company_size && quickViewData.company_size.value ? (
                        <KeyValueWithIcon
                          item={{
                            title: 'Employees',
                            value: quickViewData.company_size.value,
                            icon: <EmployeeIcon width="15px" fill="#697390" />,
                          }}
                          actionBtn={
                            quickViewData.company_name ? (
                              <Button type="link" onClick={seeMoreLeads}>
                                Find people
                              </Button>
                            ) : null
                          }
                        />
                      ) : null}
                    </Space>
                  </div>
                </Space>
              </div>

              {quickViewData.company_products_services &&
              quickViewData.company_products_services.value ? (
                <div className="pill-section">
                  <Space direction="vertical" size={16} className="space-width">
                    <div>
                      <Text className="quick-view-content-header">
                        PRODUCT & SERVICES
                        <Text className="quick-view-content-header__count">
                          {' '}
                          ({quickViewData.company_products_services.value.length})
                        </Text>
                      </Text>
                    </div>
                    <PillsGroup list={quickViewData.company_products_services.value} />
                  </Space>
                </div>
              ) : null}

              {/* did not found funding data as it was coming from crunchbase */}
              {/* <div>
                <Space align="baseline" direction="vertical" size={16} className="space-width">
                  <div>
                    <Text className='quick-view-content-header'>FUNDING</Text>
                  </div>
                  {fundingData.map((list) => (
                    <QuickViewCard
                      title={list.title}
                      subTitle={list.subTitle}
                      titleIcon={list.titleIcon}
                      content={list.content}
                      isSingleActionBtn={list.isSingleActionBtn}
                    />
                  ))}
                </Space>
              </div>

              <Button
                type="primary"
                block
                style={{ borderColor: '#E9F6FE', background: '#E9F6FE', color: '#4A6EE5' }}
              >
                See More
              </Button> */}

              {leads.data && leads.data.length ? (
                <>
                  <div className="education-cert-section">
                    <Space align="baseline" direction="vertical" size={16} className="space-width">
                      <div>
                        <Text className="quick-view-content-header" strong>
                          EXECUTIVES
                          <Text className="quick-view-content-header__count">
                            {' '}
                            ({leads?.total_results || 0})
                          </Text>
                        </Text>
                      </div>
                      {leads.data &&
                        leads.data.slice(0, 2).map((list) => (
                          <QuickViewCardSecond
                            key={list.name.value}
                            actions={getActionButtons(list)}
                            title={list.name.value}
                            subTitle={list.lead_titles.value}
                            titleIcon={{
                              size: 'small',
                              icon: getInitials(list.name.value),
                              backgroundColor: '#38C5AF',
                              iconColor: 'white',
                            }}
                            content={[
                              {
                                title: '',
                                value:
                                  list.email && list.email.value ? (
                                    // <Copy text={list.email.value} element={list.email.value} />
                                    <Text className="info-text">{list.email.value}</Text>
                                  ) : !list.emailNotAvailable &&
                                    list.id &&
                                    list.id.value &&
                                    list.company_name &&
                                    list.company_name.value &&
                                    list.company_website &&
                                    list.company_website.value &&
                                    list.name &&
                                    list.name.value ? (
                                    <InstaRevealEmail
                                      payload={{
                                        leadId: list.id.value,
                                        companyName: list.company_name.value,
                                        website: list.company_website.value,
                                        name: list.name.value,
                                      }}
                                      getInstaRes={(instaRes) => getInstaEmailRes(instaRes, list)}
                                    />
                                  ) : (
                                    'Not Available'
                                  ),
                                icon: <EmailIcon width="15px" fill="#697390" />,
                              },
                              {
                                title: '',
                                value:
                                  (list.phone && list.phone.value) ||
                                  (list.work_phone && list.work_phone.value) ? (
                                    <>
                                      {list.phone && list.phone.value ? (
                                        // <Copy
                                        //   text={list.phone.value}
                                        //   element={
                                        //     <Text className="info-text">
                                        //       {`${list.phone.value} (Phone)`}
                                        //     </Text>
                                        //   }
                                        // />
                                        <Text className="info-text">
                                          {`${list.phone.value} (Phone)`}
                                        </Text>
                                      ) : null}
                                      {list.work_phone && list.work_phone.value ? (
                                        // <Copy
                                        //   text={list.work_phone.value}
                                        //   element={
                                        //     <Text className="info-text">
                                        //       {`${list.work_phone.value} (Work)`}
                                        //     </Text>
                                        //   }
                                        // />
                                        <Text className="info-text">
                                          {`${list.work_phone.value} (Work)`}
                                        </Text>
                                      ) : null}
                                    </>
                                  ) : !list.phoneNotAvailable &&
                                    list.id &&
                                    list.id.value &&
                                    list.linkedin_url &&
                                    list.linkedin_url.value ? (
                                    <InstaRevealPhone
                                      payload={{
                                        leadId: list.id.value,
                                        linkedinUrl: list.linkedin_url.value,
                                      }}
                                      getInstaRes={(instaRes) => getInstaPhoneRes(instaRes, list)}
                                    />
                                  ) : (
                                    'Not Available'
                                  ),
                                icon: <PhoneIcon width="15px" fill="#697390" />,
                              },
                            ]}
                            tab="lead"
                          />
                        ))}
                      {quickViewData.company_name && (
                        <Button
                          type="primary"
                          block
                          style={{
                            borderColor: '#E9F6FE',
                            background: '#E9F6FE',
                            color: '#4A6EE5',
                          }}
                          onClick={seeMoreLeads}
                          // href={`${process.env.OLD_DASHBOARD_URL}leads?company_name=${quickViewData.company_name.value}`}
                          // target="_blank"
                        >
                          See All People
                        </Button>
                      )}
                    </Space>
                  </div>
                </>
              ) : null}
              {technographicsData.technologies_mapper_view ? (
                <TechnographicsView
                  title="TECHNOLOGY STACK"
                  data={technographicsData.technologies_mapper_view}
                />
              ) : null}
              {technographicsData.keywords_mapper_view ? (
                <TechnographicsView
                  title="PSYCHOGRAPHICS"
                  data={technographicsData.keywords_mapper_view}
                />
              ) : null}
            </Space>
          </div>
          {quickViewData.id ? (
            <div className="see-detail-view">
              <Button
                type="link"
                block
                href={`${APP_ROUTE_SRC.COMPANY_DETAILS}/${quickViewData.id.value}`}
                target="_blank"
                onClick={() => sendEvent(eventActions.SEE_DETAILED_VIEW)}
              >
                See detailed view
              </Button>
            </div>
          ) : null}
        </Space>
      </>
    );
  };

  return getContent();
};

export default CompanyQuickView;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ListService, TechnologyService } from 'api/services';
import { Skeleton } from 'antd';
import AddtoList from 'components/discovery/addtolist/AddtoList';
import { openAddtoList } from 'containers/discovery/discoveryActions';
import { useDispatch } from 'react-redux';
import { eventActions, eventList, eventManager } from 'utils/eventManager';
import PageLayout from 'components/common/PageLayout';
import QuickViewContent from '../../discovery/layouts/QuickViewContent';
import TechPsychContext from '../TechPsychContext';
import Profile from '../common/Profile';
import Companies from '../common/Companies';
import Overview from '../common/OverView';
import TabList from '../common/TabList';
import '../technology-psychographics.scss';

const COMPANY_LIST_API_REQUEST_LIMIT = 50;
const defaultData = { data: [], total_results: 0 };

const initialQuickView = {
  actionType: null, // PREV, NEXT
  data: {},
  isQuickView: false,
  isViewLoading: false,
  viewName: '',
};

const initialStoreData = {
  defaultInnerActiveKey: 'total',
  quickView: initialQuickView,
  viewName: 'company',
  companies: defaultData,
  page: 1,
};
const { fetchSmartList, addItemsToList, createList } = ListService;

const sendEvent = (action, companyId) => {
  eventManager.track(eventList.DETAIL_VIEW_ACTION, {
    payload: {
      type: 'company-detail',
      action,
      version: eventActions.NDF,
      company_id: companyId,
    },
  });
};

const tabsData = [
  {
    key: 'overview',
    name: 'Overview',
    component: <Overview type="keyword" />,
  },
  {
    key: 'companies',
    name: 'Companies',
    component: <Companies type="keywords" />,
  },
];

const KeywordDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [companyData, setCompanyData] = useState(null);
  const [context, setContext] = useState({});
  const [storeData, setStoreData] = useState(initialStoreData);
  const [addToListDrawerVisiblility, setAddToListDrawerVisiblility] = useState(false);

  const updateContext = (values) => {
    setContext({ ...context, ...values });
  };

  useEffect(() => {
    fetchCompanyData(id);
    return () => {};
  }, []);

  const fetchCompanyData = async (companyId) => {
    const [psychographicsData] = await Promise.all([
      TechnologyService.getCompanyBasicInfo({ id: companyId }),
    ]);
    setCompanyData({
      ...psychographicsData,
      mapData: [],
      closestCompetitorsData: {},
      competitorsBarData: {},
      addsData: {},
      dropsData: {},
      companySizeData: [],
      competitorsPieData: [],
      customerCategoryData: [],
      worldMapData: {},
      company_id: { value: companyId },
    });
  };

  const openQuickView = (payload) => {
    const company = payload?.data?.name || '';
    const companyId = payload?.data?.id?.value || '';
    setStoreData({
      ...storeData,
      quickView: {
        isQuickView: true,
        data: payload.data,
        viewName: payload.view,
        isViewLoading: !!payload.loading,
        actionType: payload.actionType ?? null,
      },
    });
    // eventManager.track(eventList.INTENT_TAB, {
    //   payload: {
    //     name: eventActions.COMPANY_QUICK_VIEW_OPENED,
    //     companyId,
    //     company,
    //   },
    // });
  };

  const closeQuickView = () => {
    setStoreData({
      ...storeData,
      quickView: initialQuickView,
    });
  };
  const openAddToList = ({ ids = [], listType, source }) => {
    dispatch(
      openAddtoList({
        ids,
        listType: 'company',
        source: 'psychographics-details',
      })
    );
    sendEvent(eventActions.ADD_TO_SMART_LIST, ids[0]);
    setAddToListDrawerVisiblility(!addToListDrawerVisiblility);
  };
  return (
    <>
      <PageLayout>
        <Skeleton loading={!companyData}>
          <TechPsychContext.Provider
            value={{
              data: companyData,
              openQuickView,
              ...context,
              updateContext,
            }}
          >
            {!!companyData && (
              <>
                <div className="gray-box">
                  <Profile data={companyData} type="keyword" />
                </div>
                <div className="empty-sticky-box" />
                <div className="technology-tab-container tab-container">
                  <TabList tabsData={tabsData} />
                </div>
              </>
            )}
          </TechPsychContext.Provider>
        </Skeleton>
      </PageLayout>
      <QuickViewContent
        openAddToList={openAddToList}
        closeQuickView={closeQuickView}
        module="PSYCHOGRAPHICS"
        openQuickView={openQuickView}
        pageSize={COMPANY_LIST_API_REQUEST_LIMIT}
        showAddToList
        storeData={storeData}
      />
      <AddtoList tab="company" />
    </>
  );
};

export default KeywordDetails;

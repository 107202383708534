import { DeleteOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Dropdown, Menu, Space, Table, Tooltip } from 'antd';
import NoItem from 'components/common/noItem';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Link from 'antd/lib/typography/Link';
import UILink from 'UI/Link';
import Confirm from 'UI/ConfirmModal/Confirm';
import Text from 'antd/lib/typography/Text';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'utils/hooks/customHooks';
import { isEqual, getPlanConfig } from 'utils/common';
import ExportFieldSelection from 'components/common/exportFieldSelection';
import {
  addtoList,
  openQuickView,
  setCompanySmartListAction,
} from 'containers/smartList/smartListActions';
import CSVImg from 'assets/svg/csv.png';
import HubspotImg from 'assets/svg/hubspot.png';
import SalesforceImg from 'assets/svg/salesforce.png';
import scssVariables from 'src/_vars.scss';
import AddToListDrawer from './addToListDrawer';
import CreateListDrawer from './createListDrawer';

const planConfig = getPlanConfig();
const CompanyListItem = ({
  listItems,
  fetchListItems,
  deleteListItems,
  exportListItems,
  getLists,
  createNewList,
  createDiscoverLeadsJob,
  params = { page: 1 },
  loading = false,
  openAddToList,
  closeAddToList,
}) => {
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const addToListDrawerVisibility = useSelector(
    (state) => state.smartList.addToListDrawerVisibility
  );
  const [createListDrawerVisiblility, setCreateListDrawerVisiblility] = useState(false);
  const [isFetchListItems, setIsFetchListItems] = useState(false);
  const hubspotConfig = useSelector((state) => state.common.hubspotConfig);
  const salesForceConfig = useSelector((state) => state.common.salesforceConfig);
  const action = useSelector((state) => state.smartList.companySmartListAction);
  const prevParams = usePrevious(params);
  const quickView = useSelector((state) => state.smartList.quickView);
  const addToListSource = useSelector((state) => state.smartList.addToListSource);

  useEffect(() => {
    if (prevParams && params) {
      const { page: prevPage, ...prevListParams } = prevParams;
      const { page: currentPage, ...listParams } = params;
      if (!isEqual(prevListParams, listParams)) {
        setSelectedRowKeys([]);
      }
    }
  }, [params]);

  const onSelectChange = (newSelectedKeys) => {
    setSelectedRowKeys(newSelectedKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
    fixed: true,
  };

  const updateSetAction = (event, type = 'single', targetItem = {}, confirm = true) => {
    dispatch(
      setCompanySmartListAction({
        confirm,
        targetItem,
        type,
        event,
      })
    );
  };

  const singleExportOptions = (id) => (
    <Menu>
      <Menu.Item
        disabled={hubspotConfig?.disable_csv_export}
        onClick={() => updateSetAction('export-csv', undefined, { id })}
      >
        <img alt="csv" src={CSVImg} />
        <Text style={{ color: '#5E81F4', marginLeft: '5px' }}>CSV</Text>
      </Menu.Item>
      {salesForceConfig && salesForceConfig.isConnected && salesForceConfig.isPackageInstalled ? (
        <Menu.Item onClick={() => updateSetAction('export-sf', undefined, { id })}>
          <img alt="salesforce" src={SalesforceImg} />
          <Text style={{ color: '#5E81F4', marginLeft: '5px' }}>Salesforce</Text>
        </Menu.Item>
      ) : (
        <></>
      )}
      {hubspotConfig && hubspotConfig.status === '1' && (
        <Menu.Item onClick={() => updateSetAction('export-hb', undefined, { id })}>
          <img alt="hubspot" src={HubspotImg} />
          <Text style={{ color: '#5E81F4', marginLeft: '5px' }}>Hubspot</Text>
        </Menu.Item>
      )}
    </Menu>
  );

  const deleteOptions = (
    <Menu>
      <Menu.Item
        disabled={!selectedRowKeys.length}
        onClick={() => updateSetAction('delete', 'selected')}
      >
        <Text>{`Delete selected ${selectedRowKeys.length} item`}</Text>
      </Menu.Item>
      <Menu.Item disabled={!listItems.total} onClick={() => updateSetAction('delete', 'bulk')}>
        <Text>{`Delete all ${listItems.total} items`}</Text>
      </Menu.Item>
    </Menu>
  );

  const discoverLeadsOptions = (
    <Menu>
      <Menu.Item
        disabled={!selectedRowKeys.length}
        onClick={() => {
          updateSetAction('discoverLeads', 'selected', {}, false);
          openAddToList();
        }}
      >
        <Text>{`Discover related people profiles from selected ${selectedRowKeys.length} company(s)`}</Text>
      </Menu.Item>
      <Menu.Item
        disabled={!listItems.total}
        onClick={() => {
          updateSetAction('discoverLeads', 'bulk', {}, false);
          openAddToList();
        }}
      >
        <Text>{`Discover related people profiles from all ${listItems.total} company(s)`}</Text>
      </Menu.Item>
    </Menu>
  );

  const exportButtons = (numberOfItems, type) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            marginRight: '20px',
            width: '150px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Text style={{ color: '#29275F', fontSize: '14px' }}>
            {`Export ${type === 'selected' ? 'selected' : 'all'}`}
          </Text>
          <Text style={{ color: '#929CB7', fontSize: '12px' }}>
            {`${numberOfItems} ${numberOfItems > 1 ? 'items' : 'item'}`}
          </Text>
        </div>
        <Space>
          <Button
            style={{ background: '#E6F4FF', borderRadius: '3px', border: 'none' }}
            disabled={hubspotConfig?.disable_csv_export || !numberOfItems}
            onClick={() => updateSetAction('export-csv', type)}
          >
            <img alt="csv" src={CSVImg} />
            <Text style={{ color: '#5E81F4', marginLeft: '5px' }}>CSV</Text>
          </Button>
          {salesForceConfig &&
          salesForceConfig.isConnected &&
          salesForceConfig.isPackageInstalled ? (
            <Button
              style={{ background: '#E6F4FF', borderRadius: '3px', border: 'none' }}
              disabled={!numberOfItems}
              onClick={() => updateSetAction('export-sf', type)}
            >
              <img alt="salesforce" src={SalesforceImg} />
              <Text style={{ color: '#5E81F4', marginLeft: '5px' }}>Salesforce</Text>
            </Button>
          ) : (
            <></>
          )}
          {hubspotConfig && hubspotConfig.status === '1' && (
            <Button
              style={{ background: '#E6F4FF', borderRadius: '3px', border: 'none' }}
              disabled={!numberOfItems}
              onClick={() => updateSetAction('export-hb', type)}
            >
              <img alt="hubspot" src={HubspotImg} />
              <Text style={{ color: '#5E81F4', marginLeft: '5px' }}>Hubspot</Text>
            </Button>
          )}
        </Space>
      </div>
    );
  };

  const exportOptions = (
    <Menu>
      <Menu.Item>{exportButtons(selectedRowKeys.length, 'selected')}</Menu.Item>
      <Menu.Item>{exportButtons(listItems.total, 'bulk')}</Menu.Item>
    </Menu>
  );

  const hasMore = params.page * 25 < listItems.total;
  const onChange = (pagination, filter, sorter, extra) => {
    const payload = JSON.parse(JSON.stringify(params));
    if (sorter.order) {
      payload.key = sorter.field;
      payload.order = sorter.order === 'descend' ? 'desc' : 'asc';
    } else {
      payload.key = 'created_at';
      payload.order = 'desc';
    }
    if (hasMore || extra.action === 'paginate') {
      if (extra.action === 'paginate') {
        payload.page = pagination.current;
      } else {
        setSelectedRowKeys([]);
        payload.page = 1;
      }
      fetchListItems(payload);
    }
  };

  const handleOk = async (exportKeys) => {
    let selectedItems = [];
    let isBulk = false;
    if (action.type === 'bulk') {
      isBulk = true;
    } else if (action.type === 'single') {
      selectedItems.push(action.targetItem.id);
    } else if (action.type === 'selected') {
      selectedItems = selectedRowKeys;
    } else {
      return;
    }
    handleCancel();
    if (action.event === 'delete') {
      await deleteListItems(isBulk, selectedItems);
      const filteredSelectedRowKeys = selectedRowKeys.filter(
        (item) => !selectedItems.includes(item)
      );
      setSelectedRowKeys(filteredSelectedRowKeys);
    } else if (['export-hb', 'export-sf', 'export-csv'].includes(action.event)) {
      exportListItems(action.event, isBulk, selectedItems, null, exportKeys);
    }
    // else if (action.event === 'discoverLeads') {
    //   await createDiscoverLeadsJob(isBulk, selectedItems);
    // }
  };

  const handleCancel = () => {
    dispatch(
      setCompanySmartListAction({
        confirm: false,
        targetItem: {},
        type: '',
      })
    );
  };

  const getIdWithoutListId = (idWithListId = '') => idWithListId.split('_')[1];

  const getColor = (record) => {
    return quickView.isQuickView && quickView.data?.id?.value === getIdWithoutListId(record.id)
      ? scssVariables.selectedQuickViewItem
      : scssVariables.slintelDarkBlue;
  };

  const openQuickViewHandler = (record, rowIndex) => {
    const data = {
      id: { value: getIdWithoutListId(record.id) },
      index: rowIndex,
    };
    dispatch(openQuickView({ data, view: 'company' }));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'company_name',
      key: 'name',
      width: '200px',
      render: (text, record, rowIndex) => (
        <UILink
          type="text"
          text={
            <Tooltip placement="topLeft" title={text}>
              <Text style={{ maxWidth: '150px', color: getColor(record) }} ellipsis>
                {text}
              </Text>
            </Tooltip>
          }
          onClick={() => openQuickViewHandler(record, rowIndex)}
        />
      ),
      fixed: 'left',
    },
    {
      title: 'Company Domain',
      dataIndex: 'company_website',
      key: 'company_website',
      width: '200px',
      render: (text, record) => (
        <Link
          style={{ color: '#5753B5' }}
          href={`//${record.company_website}`}
          key={record.company_website}
          target="_blank"
        >
          {record.company_website}
        </Link>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'company_location',
      key: 'company_location',
      width: '250px',
      render: (text) => (text && Array.isArray(text) ? text.filter((t) => t).join(', ') : ''),
    },
    {
      title: 'Company Sector',
      dataIndex: 'company_sector',
      key: 'company_sector',
      width: '200px',
    },
    {
      title: 'Company Industry',
      dataIndex: 'company_industry',
      key: 'company_industry',
      width: '200px',
    },
    {
      title: 'Company Size',
      dataIndex: 'company_size',
      key: 'company_size',
      width: '200px',
    },
    {
      title: 'Company Phone Number',
      dataIndex: 'company_phone_number',
      key: 'company_phone_number',
      width: '200px',
      render: (text) => <span className="remove-mouse-selection">{text}</span>,
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: '140px',
      className: 'action-btn-holder',
      render: (text, record, index) => (
        <div className="actions-btn" id={record.id}>
          <Dropdown
            getPopupContainer={() => document.getElementsByClassName('list-item-block')[index]}
            overlay={() => {
              return singleExportOptions(record.id);
            }}
            placement="bottomLeft"
            arrow
          >
            <Button className="export-btn">Export</Button>
          </Dropdown>
          <Button
            onClick={() => updateSetAction('delete', 'single', { id: record.id })}
            className="delete-btn"
            icon={<DeleteOutlined style={{ fill: '#5E81F4' }} />}
            type="link"
          />
        </div>
      ),
    },
  ];

  const confirmProps = {
    title: 'Delete Item(s)',
    desc: 'Are you sure you want to delete these company(s)?',
  };

  if (action.confirm) {
    if (action.event === 'export-hb') {
      confirmProps.title = 'Export to Hubspot';
      confirmProps.desc = 'Are you sure you want to export the item(s)?';
    } else if (action.event === 'export-sf') {
      confirmProps.title = 'Export to Salesforce';
      confirmProps.desc = 'Are you sure you want to export the item(s)?';
    } else if (action.event === 'export-csv') {
      confirmProps.title = 'Export to CSV';
      confirmProps.desc = 'Are you sure you want to export the item(s)?';
    }
  }

  const addItemsToList = async (selectedList) => {
    let selectedItems = [];
    let isBulk = false;
    if (action.type === 'bulk') {
      isBulk = true;
    } else if (action.type === 'single') {
      selectedItems.push(action.targetItem.id);
    } else if (action.type === 'selected') {
      selectedItems = selectedRowKeys;
    } else if (addToListSource?.ids?.length > 0) {
      const addToListParams = {
        listId: { value: selectedList.id },
        listType: addToListSource.listType,
        ids: addToListSource.ids,
      };
      dispatch(addtoList({ params: addToListParams }));
      handleCancel();
      return;
    } else {
      return;
    }
    handleCancel();
    if (action.event === 'discoverLeads') {
      await createDiscoverLeadsJob(isBulk, selectedItems, selectedList);
    }
  };

  const hasItems = () => {
    return listItems && Array.isArray(listItems.items) && listItems.items.length > 0;
  };

  return (
    <>
      <ConfigProvider
        renderEmpty={() =>
          !loading && (
            <div className="list-item-table_empty">
              <NoItem text="No results found" />
            </div>
          )
        }
      >
        <Table
          style={{
            position: 'relative',
            height: '100%',
          }}
          className="list-item-table"
          rowSelection={rowSelection}
          rowClassName="list-item-block"
          columns={columns}
          loading={loading}
          dataSource={listItems.items}
          rowKey="id"
          total={listItems.total}
          onChange={onChange}
          pagination={{
            position: ['topRight'],
            style: { marginRight: '15px', marginBottom: '0px' },
            current: params.page,
            total: listItems.total >= planConfig.total ? planConfig.total : listItems.total,
            showSizeChanger: false,
            pageSize: 25,
            showTotal: (total, range) => (
              <Text type="secondary">{`${range[0]}-${range[1]} of ${total}`}</Text>
            ),
            size: 'small',
          }}
          scroll={{
            y: '100%',
            x: 1300,
          }}
          showSorterTooltip
          title={() =>
            hasItems() && (
              <div className="action-btn-group">
                <Space>
                  <Dropdown overlay={exportOptions} placement="bottomLeft" arrow>
                    <Button
                      style={{
                        border: '1px solid #5e81f4',
                        borderRadius: '5px',
                        color: '#5e81f4',
                      }}
                    >
                      Export
                    </Button>
                  </Dropdown>
                  <Dropdown overlay={discoverLeadsOptions} placement="bottomLeft" arrow>
                    <Button
                      style={{
                        border: '1px solid #5e81f4',
                        borderRadius: '5px',
                        color: '#5e81f4',
                      }}
                    >
                      Discover People
                    </Button>
                  </Dropdown>
                  <Dropdown overlay={deleteOptions} placement="bottomLeft" arrow>
                    <Button
                      style={{ background: '#E6F4FF' }}
                      className="delete-btn"
                      icon={<DeleteOutlined style={{ fill: '#5E81F4' }} />}
                    />
                  </Dropdown>
                </Space>

                <Confirm
                  visible={action.confirm && action.event !== 'export-csv'}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  okText={action.event === 'delete' ? 'Delete' : 'Export'}
                  titleText={confirmProps.title}
                  descText={confirmProps.desc}
                  width="400px"
                />
              </div>
            )
          }
        />
      </ConfigProvider>
      <AddToListDrawer
        visible={addToListDrawerVisibility}
        getLists={getLists}
        isFetchListItems={isFetchListItems}
        setReloadListItems={setIsFetchListItems}
        toggleCreateListDrawerVisibility={setCreateListDrawerVisiblility}
        onClose={closeAddToList}
        addItemsToList={addItemsToList}
      />
      <CreateListDrawer
        visible={createListDrawerVisiblility}
        onClose={setCreateListDrawerVisiblility}
        onSubmit={createNewList}
        setReloadListItems={setIsFetchListItems}
      />
      <ExportFieldSelection
        visible={action.confirm && action.event === 'export-csv'}
        selectedValues={{}}
        onClose={handleCancel}
        onExport={handleOk}
        listType="company"
        exportCount={listItems.total}
        isBulk={action.type === 'bulk'}
        renderFrom="list"
      />
    </>
  );
};

CompanyListItem.propTypes = {
  listItems: PropTypes.object.isRequired,
  fetchListItems: PropTypes.func,
  defaultPage: PropTypes.number,
  loading: PropTypes.bool,
  params: PropTypes.object,
  deleteListItems: PropTypes.func,
  exportListItems: PropTypes.func,
  createDiscoverLeadsJob: PropTypes.func,
  getLists: PropTypes.func.isRequired,
  createNewList: PropTypes.func.isRequired,
  openAddToList: PropTypes.func.isRequired,
  closeAddToList: PropTypes.func.isRequired,
};

export default CompanyListItem;

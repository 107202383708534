import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Dropdown, Menu } from 'antd';
import { formatDateFromString, getParagraphWithSafeHTML } from 'utils/common';
import { useHistory } from 'react-router-dom';
import './notifications.scss';

// notification card
const NotificationCard = ({
  dataId,
  notificationType,
  changeType,
  imageLink,
  viewLink,
  message,
  read,
  timestamp,
  onClick,
  viewType = 'full', // compact or full
}) => {
  const history = useHistory();

  // call the api only if the status is unseen
  const markAsRead = () => {
    if (!read) {
      onClick(dataId);
    }
  };

  const cardClick = (e) => {
    if (e.target.localName === 'a') {
      return false;
    }
    markAsRead();
    return viewLink.trim() && window.open(viewLink, '_blank');
  };

  const notificationMenu = () => (
    <Menu
      onClick={({ key, domEvent }) => {
        // prevent bubble
        domEvent.preventDefault();
        domEvent.stopPropagation();
        if (key === 'read') {
          markAsRead();
        } else {
          history.push('/my-account/notifications');
        }
      }}
    >
      <Menu.Item key="read">Mark as read</Menu.Item>
      <Menu.Item key="settings">Notification Settings</Menu.Item>
    </Menu>
  );

  // for notification panel
  if (viewType === 'compact') {
    return (
      <div
        tabIndex={0}
        role="button"
        onClick={cardClick}
        className={`compact notification-card-container ${!read ? 'unread' : ''}`}
      >
        <div className="notification-card-type flex-center">
          {imageLink.length > 0 ? (
            <img className="notification-company-icon" src={imageLink} alt="company logo" />
          ) : (
            <div className={`${notificationType} ${changeType} notification-type-icon`} />
          )}
        </div>
        <div className="notification-card-content">
          {getParagraphWithSafeHTML(message)}
          <div className="notification-card-meta">
            <p className="notification-card-time">{formatDateFromString(timestamp)}</p>
            <a
              href={viewLink}
              target="_blank"
              rel="noreferrer"
              className="notification-card-article"
            >
              View
            </a>
          </div>
        </div>
      </div>
    );
  }

  // for notification page
  return (
    // eslint-disable-next-line
    <div
      role="button"
      onClick={cardClick}
      className={`full notification-card-container ${!read ? 'unread' : ''}`}
    >
      <div className="notification-card-type flex-center">
        {imageLink.length > 0 ? (
          <img className="notification-company-icon" src={imageLink} alt="company logo" />
        ) : (
          <div className={`${notificationType} ${changeType} notification-type-icon`} />
        )}
      </div>
      <div className="notification-card-content">
        <div className="notification-info">
          <div className="notification-card-stack">
            {getParagraphWithSafeHTML(message)}
            <p className="notification-card-time">{formatDateFromString(timestamp)}</p>
          </div>

          {viewLink && viewLink.length > 0 ? (
            <div className="notification-card-meta vertical">
              <Button
                onClick={() => window.open(viewLink, '_blank').focus()}
                type="primary"
                className="white-primary-button notification-center notification-card-article"
              >
                View
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
        <Dropdown
          trigger="click"
          overlay={notificationMenu}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          <div className="notification-action-menu-container flex-center">
            <div className="notification-action-menu" />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

NotificationCard.propTypes = {
  dataId: PropTypes.number,
  notificationType: PropTypes.string,
  message: PropTypes.string,
  read: PropTypes.number,
  timestamp: PropTypes.number,
  viewType: PropTypes.string,
  onClick: PropTypes.func,
  changeType: PropTypes.string,
  viewLink: PropTypes.string,
  imageLink: PropTypes.string,
};

export default NotificationCard;

/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { Tag, Tooltip, Divider } from 'antd';
import PropTypes from 'prop-types';
import './FilterTagPill.scss';
import { CloseOutlined, PlusOutlined, StopOutlined } from '@ant-design/icons';

const longTextLength = 8;

const FilterTagPill = ({
  label = '',
  type = 'negation',
  showTooltip = false,
  isTooltipRequired = true,
  onDelete,
  showFullText,
  onToggleNegate,
  skipParsing,
  longText = longTextLength,
  closable,
  ...props
}) => {
  const [isVisible, setvisible] = useState(false);
  const [isLongTag, setIsLongTag] = useState(false);
  const [isNegative, setIsNegative] = useState(false);
  const [filteredLabel, setFilteredLabel] = useState('');

  useEffect(() => {
    if (!showFullText) {
      setIsLongTag(parseLabel(label).length > longText);
    }
    setIsNegative(checkIfNegative(label));
    setFilteredLabel(parseLabel(label));
  }, [label]);

  const parseLabel = (text) => {
    let labelText = text;
    if (checkIfNegative(text)) {
      labelText = label.slice(1);
    }
    return typeof labelText === 'string' && labelText.includes('|') && !skipParsing
      ? labelText.split('|').pop()
      : labelText;
  };

  const checkIfNegative = (value) => {
    // somehow some labels are coming as array... need to check the cause
    if (!(typeof value === 'string')) {
      return false;
    }
    return value.charAt(0) === '!';
  };

  const handleClose = (e) => {
    e.stopPropagation();
    onDelete();
  };

  const toggleNegate = (e, isNegation) => {
    e.stopPropagation();
    onToggleNegate(isNegation);
  };

  const getTag = () => {
    return (
      <div
        onMouseEnter={() => setvisible(true)}
        onMouseLeave={() => setvisible(false)}
        onClick={(e) => e.stopPropagation()}
      >
        <Tag
          closable={false}
          {...props}
          className={{
            'tag-hover': isVisible,
            'negative-tag': isNegative,
          }}
        >
          {isLongTag ? `${filteredLabel.slice(0, longText)}...` : filteredLabel}
          {isVisible && (
            <div className="tag-action-btn-group" onClick={(e) => e.stopPropagation()}>
              {type === 'negation' ? (
                <div className="icon-block" onClick={(e) => toggleNegate(e, !isNegative)}>
                  {!isNegative ? (
                    <StopOutlined
                      rotate={90}
                      className="icon-color"
                      width="10"
                      height="10"
                      fill="#5753B5"
                      onClick={(e) => toggleNegate(e, !isNegative)}
                    />
                  ) : (
                    <PlusOutlined
                      className="icon-color"
                      width="10"
                      height="10"
                      fill="#5753B5"
                      onClick={(e) => toggleNegate(e, !isNegative)}
                    />
                  )}
                </div>
              ) : null}
              <Divider className="divider" type="vertical" />
              {closable && (
                <div className="icon-block" onClick={handleClose}>
                  <CloseOutlined
                    className="icon-color"
                    width="10"
                    height="10"
                    onClick={handleClose}
                  />
                </div>
              )}
            </div>
          )}
        </Tag>
      </div>
    );
  };
  const getToolTip = () => {
    const { value } = props;
    if (!value || !value.groupId) {
      return filteredLabel;
    }
    const isGroupIdPresent = ['TECHNOLOGIES', 'KEYWORDS'].some((item) =>
      value.groupId.includes(item)
    );
    if (isGroupIdPresent) {
      const [category, subCategory, companyName] = label.split('|');
      return (
        <div className="tooltip-label-text">
          <span>{companyName}</span>
          <span>{`${category} > ${subCategory}`}</span>
        </div>
      );
    }

    return filteredLabel;
  };
  return (
    <div className="slintel-tag">
      {showTooltip || (isLongTag && isTooltipRequired) ? (
        <Tooltip title={getToolTip()} key={filteredLabel}>
          {getTag()}
        </Tooltip>
      ) : (
        getTag()
      )}
    </div>
  );
};

FilterTagPill.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  onDelete: PropTypes.func,
  onToggleNegate: PropTypes.func,
  showFullText: PropTypes.bool,
  closable: PropTypes.bool,
  longText: PropTypes.number,
};

FilterTagPill.defaultProps = {
  label: 'Close',
  type: 'normal', // Possible values [normal, negation]
  closable: true,
};

export default FilterTagPill;

/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */

import React, { Component } from 'react';
import { Spin, Layout, Card } from 'antd';
import PropTypes from 'prop-types';

import { QUICKVIEW_MODULES } from 'src/constants/constants';
import QuickViewContent from 'containers/discovery/layouts/QuickViewContent';
import { eventManager, eventList, eventActions } from 'utils/eventManager';
import Cookies from '../../services/cookies/cookies';

import PreferencesDrawer from '../../components-modules/business-modules/preferences-drawer/src/preferences-drawer';

import CompaniesToTargetAPI from '../../apis/companies-to-target/src/requests';
import ListsAPI from '../../apis/lists/src/requests';

import AddToListDrawer from '../../components-modules/business-modules/add-to-list-drawer/src/add-to-list-drawer';
import CreateListDrawer from '../../components-modules/business-modules/create-list-drawer/src/create-list-drawer';
import FeedTabs from './feed-tabs';
import CompaniesToTargetContext from './companies-to-target-context';
import { COMPANIES_TO_TARGET } from '../../configs-constants/constants/src/constants';

const { COMPANY_LIST_API_REQUEST_LIMIT } = COMPANIES_TO_TARGET;

const { Content } = Layout;

const defaultData = { data: [], total_results: 0 };

const initialQuickView = {
  actionType: null, // PREV, NEXT
  data: {},
  isQuickView: false,
  isViewLoading: false,
  viewName: '',
};

const initialStoreData = {
  defaultInnerActiveKey: 'total',
  quickView: initialQuickView,
  viewName: 'company',
  companies: defaultData,
  page: 1,
};

const DASHBOARD = QUICKVIEW_MODULES.dashboard;

class CompaniesToTargetFilterTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // TODO: fetch user from redux state or as props
      preferencesDrawerVisiblility: false,
      addToListDrawerVisiblility: false,
      createListDrawerVisiblility: false,
      isFetchListItems: false,
      user: {},
      tabs: {
        preferences: null,
        filters: null,
      },
      isBulkSelected: false,
      loading: false,
      apiRequests: {
        companiesToTargetAPI: CompaniesToTargetAPI({
          baseAPIURL: props.environmentVariables.baseAPIURL,
        }),
        listsAPI: ListsAPI({
          baseAPIURL: props.environmentVariables.baseAPIURL,
        }),
      },
      cookies: Cookies({ domainName: props.environmentVariables.domainName }),
      checkedItems: [],
      currentFilterSet: {},
      currentTotalDataCount: 0,
      tab: 'preferences',
      activeCompanyId: '',
      storeData: initialStoreData,
      tabsCompaniesData: {},
      tabsRef: {},
      addToListEntity: {},
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    await this.getUserDetails();
    await this._apiCall_getUserPreferences();
    await this._apiCall_getCustomFilters();
    this.setState({
      loading: false,
    });
  }

  /**
   * Fetch User preferences, add it to tabs
   */
  _apiCall_getUserPreferences = async () => {
    const { user, tabs, apiRequests } = this.state;

    const { getUserPreferences } = apiRequests.companiesToTargetAPI;

    try {
      const preferences = await getUserPreferences(user.userId);
      const preferencesRef = React.createRef();
      await new Promise((resolve) => {
        this.setState(
          {
            tabs: {
              preferences,
              ...tabs.filters,
            },
            tabsRef: {
              preferences: preferencesRef,
            },
          },
          resolve
        );
      });
    } catch (e) {
      console.log(e);
    }
  };

  _apiCall_getCustomFilters = async () => {
    const { user, tabs, apiRequests, tabsRef } = this.state;

    const { getCustomFilters } = apiRequests.companiesToTargetAPI;
    try {
      let filters = await getCustomFilters(user.userId);
      filters = filters.filter((v) => v.type === 'company');
      const filtersRef = {};
      for (const filter of filters) {
        filtersRef[filter.name] = React.createRef(null);
      }

      await new Promise((resolve) => {
        this.setState(
          {
            tabs: {
              preferences: tabs.preferences,
              filters,
            },
            tabsRef: {
              preferences: tabsRef.preferences,
              ...filtersRef,
            },
          },
          resolve
        );
      });
    } catch (e) {
      console.log(e);
    }
  };

  getUserDetails = async () => {
    const { cookies } = this.state;
    const user = cookies.getCookie('userToken');
    return new Promise((resolve) => {
      this.setState({ user }, resolve);
    });
  };

  togglePreferencesDrawerVisibility = () => {
    const { preferencesDrawerVisiblility, storeData } = this.state;
    const { quickView } = storeData;
    if (!preferencesDrawerVisiblility && quickView.isQuickView) {
      this.closeQuickView();
    }
    this.setState({
      preferencesDrawerVisiblility: !preferencesDrawerVisiblility,
    });
  };

  toggleAddToListDrawerVisibility = (value, source) => {
    const { addToListDrawerVisiblility } = this.state;
    this.setState({
      addToListEntity: source,
      addToListDrawerVisiblility: !addToListDrawerVisiblility,
      isBulkSelected: value,
    });
  };

  toggleCreateListDrawerVisibility = (value) => {
    this.setState({
      createListDrawerVisiblility: value,
    });
  };

  setCheckedItems = (items) => {
    this.setState({
      checkedItems: items,
    });
  };

  setCurrentFilterSet = (value) => {
    this.setState({
      currentFilterSet: value,
    });
  };

  setCurrentTotalDataCount = (value) => {
    this.setState({
      currentTotalDataCount: value,
    });
  };

  setUserPreferences = async (preferences) => {
    const { tabs } = this.state;
    const { mixpanel } = this.props;

    this.setState({
      tabs: {
        preferences,
        filters: tabs.filters,
      },
    });

    if (mixpanel) {
      const { keywords, companyFunction, ...payload } = preferences;
      mixpanel.track('intent_tab', { payload: { name: 'Save Preferences', preferences: payload } });
    }
  };

  setReloadListItems = (value) => {
    this.setState({
      isFetchListItems: value,
    });
  };

  onCompaniesFilterTabChange = (value) => {
    const { tabsCompaniesData } = this.state;
    let companies = defaultData;
    let page = 1;
    if (tabsCompaniesData[value]) {
      const cached = tabsCompaniesData[value];
      companies = cached.companies;
      page = cached.page;
    }
    this.setState((state) => {
      return {
        ...state,
        storeData: {
          ...state.storeData,
          companies,
          page,
          quickView: initialQuickView,
        },
        tab: value,
      };
    });
  };

  handleQuickViewOpenAddToList = (payload) => {
    this.toggleAddToListDrawerVisibility(false, payload);
  };

  setActiveCompanyId = (id) => {
    this.setState({
      activeCompanyId: id,
    });
  };

  // fetch data for current tab through ref
  goToPage = (goToPage) => {
    const { tabsRef, tab } = this.state;
    if (tabsRef[tab]?.current?._apiCall_getCompainesToTargetForFiltersAndUserPreferences) {
      tabsRef[tab].current._apiCall_getCompainesToTargetForFiltersAndUserPreferences(goToPage);
    }
  };

  getQuickViewFormatCompanies = (companies, totalResults) => {
    const formattedCompanies = companies.map((entry) => ({
      id: {
        value: entry.company.id,
      },
      name: entry.company.name,
    }));
    return {
      data: formattedCompanies,
      total_results: totalResults,
    };
  };

  setQuickViewData = (companies = [], totalResults, page, tabName) => {
    const { tab } = this.state;
    const formattedCompanies = this.getQuickViewFormatCompanies(companies, totalResults);
    this.setState((state) => {
      let updatedProps = {
        tabsCompaniesData: {
          ...state.tabsCompaniesData,
          [tabName]: {
            companies: formattedCompanies,
            page,
          },
        },
      };

      if (tabName === tab) {
        updatedProps = {
          ...updatedProps,
          storeData: {
            ...state.storeData,
            companies: formattedCompanies,
            page,
          },
        };
      }

      return {
        ...state,
        ...updatedProps,
      };
    });
  };

  openQuickView = (payload) => {
    const company = payload?.data?.name || '';
    const companyId = payload?.data?.id?.value || '';
    this.setActiveCompanyId(companyId);
    this.setState((state) => {
      return {
        ...state,
        storeData: {
          ...state.storeData,
          quickView: {
            isQuickView: true,
            data: payload.data,
            viewName: payload.view,
            isViewLoading: !!payload.loading,
            actionType: payload.actionType ?? null,
          },
        },
      };
    });
    eventManager.track(eventList.INTENT_TAB, {
      payload: {
        name: eventActions.COMPANY_QUICK_VIEW_OPENED,
        companyId,
        company,
      },
    });
  };

  closeQuickView = () => {
    this.setState((state) => {
      return {
        ...state,
        activeCompanyId: '',
        storeData: {
          ...state.storeData,
          quickView: initialQuickView,
        },
      };
    });
  };

  render() {
    const {
      preferencesDrawerVisiblility,
      user,
      tabs,
      loading,
      apiRequests,
      addToListDrawerVisiblility,
      checkedItems,
      createListDrawerVisiblility,
      isFetchListItems,
      isBulkSelected,
      currentFilterSet,
      currentTotalDataCount,
      tab,
      activeCompanyId,
      storeData,
      tabsRef,
      addToListEntity,
    } = this.state;
    const { mixpanel, environmentVariables } = this.props;
    const {
      getUserPreferences,
      getLocationsSuggestions,
      getTechnologiesSuggestions,
      getSubCategoriesSuggestions,
      updateUserPreferences,
    } = apiRequests.companiesToTargetAPI;

    const { getLists, addItemsToList, createNewList, addIntentBulkItemsToList } =
      apiRequests.listsAPI;

    return (
      <>
        <Layout className="outer-tab-container">
          <Content className="outer-tab-content">
            <Card className="inner-tab-container">
              <Spin spinning={loading}>
                <div className="companies-to-target-filter-tabs">
                  {tabs.preferences && tabs.filters && (
                    <CompaniesToTargetContext.Provider
                      value={{
                        apiRequests,
                        oldDashboardURL: environmentVariables.oldDashboardURL,
                        toggleAddToListDrawerVisibility: this.toggleAddToListDrawerVisibility,
                        addToListDrawerVisiblility,
                        setCheckedItems: this.setCheckedItems,
                        setCurrentFilterSet: this.setCurrentFilterSet,
                        setCurrentTotalDataCount: this.setCurrentTotalDataCount,
                        openQuickView: this.openQuickView,
                        closeQuickView: this.closeQuickView,
                        setQuickViewData: this.setQuickViewData,
                        storeData,
                        activeCompanyId,
                      }}
                    >
                      <FeedTabs
                        // toggleFiltersDrawerVisibility={this.toggleFiltersDrawerVisibility}
                        togglePreferencesDrawerVisibility={this.togglePreferencesDrawerVisibility}
                        onTabChange={this.onCompaniesFilterTabChange}
                        tabs={tabs}
                        mixpanel={mixpanel}
                        tabsRef={tabsRef}
                      />
                    </CompaniesToTargetContext.Provider>
                  )}
                  {user.userId && (
                    <>
                      <PreferencesDrawer
                        visible={preferencesDrawerVisiblility}
                        onClose={this.togglePreferencesDrawerVisibility}
                        onSave={this.setUserPreferences}
                        userId={user.userId}
                        mixpanel={mixpanel}
                        tab={tab}
                        getUserPreferences={getUserPreferences}
                        getLocationsSuggestions={getLocationsSuggestions}
                        getTechnologiesSuggestions={getTechnologiesSuggestions}
                        getSubCategoriesSuggestions={getSubCategoriesSuggestions}
                        updateUserPreferences={updateUserPreferences}
                      />
                      <AddToListDrawer
                        visible={addToListDrawerVisiblility}
                        onClose={this.toggleAddToListDrawerVisibility}
                        getLists={getLists}
                        addItemsToList={addItemsToList}
                        addIntentBulkItemsToList={addIntentBulkItemsToList}
                        checkedItems={checkedItems}
                        toggleCreateListDrawerVisibility={this.toggleCreateListDrawerVisibility}
                        isFetchListItems={isFetchListItems}
                        setReloadListItems={this.setReloadListItems}
                        isBulkSelected={isBulkSelected}
                        currentFilterSet={currentFilterSet}
                        currentTotalDataCount={currentTotalDataCount}
                        addToListEntity={addToListEntity}
                      />
                      <CreateListDrawer
                        visible={createListDrawerVisiblility}
                        onClose={this.toggleCreateListDrawerVisibility}
                        onSubmit={createNewList}
                        setReloadListItems={this.setReloadListItems}
                        type={addToListEntity?.listType || 'company'}
                      />
                    </>
                  )}
                </div>
              </Spin>
            </Card>
          </Content>
        </Layout>
        <QuickViewContent
          openAddToList={this.handleQuickViewOpenAddToList}
          closeQuickView={this.closeQuickView}
          goToNextPage={this.goToPage}
          goToPrevPage={this.goToPage}
          isNextPrevVisible
          module={DASHBOARD}
          openQuickView={this.openQuickView}
          pageSize={COMPANY_LIST_API_REQUEST_LIMIT}
          showAddToList
          storeData={storeData}
        />
      </>
    );
  }
}

CompaniesToTargetFilterTabs.propTypes = {
  mixpanel: PropTypes.shape({
    track: PropTypes.func,
  }),
  environmentVariables: PropTypes.shape({
    baseAPIURL: PropTypes.string.isRequired,
    oldDashboardURL: PropTypes.string.isRequired,
    domainName: PropTypes.string.isRequired,
  }).isRequired,
};
CompaniesToTargetFilterTabs.defaultProps = {
  mixpanel: null,
};
export default CompaniesToTargetFilterTabs;

import React from 'react';
import { NoItemsIcon } from 'UI/CustomIcon/CustomIcon';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import './noItem.scss';

const NoItem = ({
  icon = <NoItemsIcon className="no-item-icon" />,
  style,
  text = 'No items to display',
}) => {
  return (
    <div className="no-item" style={style}>
      {icon}
      <Text
        strong="true"
        style={{
          fontSize: '18px',
          color: '#C6CDE0',
        }}
      >
        {text}
      </Text>
    </div>
  );
};

NoItem.propTypes = {
  style: PropTypes.object,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
};

export default NoItem;

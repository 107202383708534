import React from 'react';
// import PropTypes from 'prop-types';
import Button from 'UI/Button';
import { Tooltip } from 'antd';

import { ExportIcon } from 'UI/CustomIcon/CustomIcon';

const ExportButton = ({ ...props }) => (
  <div className="export-button-container">
    <Tooltip
      placement="bottom"
      title={props.disabled ? 'Select records from below to take an action' : ''}
    >
      <span>
        <Button
          {...props}
          theme="primary"
          icon={<ExportIcon fill="#ffff" width="15" height="15" />}
          style={props.disabled ? { pointerEvents: 'none' } : {}}
        >
          Export
        </Button>
      </span>
    </Tooltip>
  </div>
);

ExportButton.propTypes = {
  // bla: PropTypes.string,
};

ExportButton.defaultProps = {
  // bla: 'test',
};

export default ExportButton;

/* eslint-disable import/prefer-default-export */

let store;

export const injectStore = (_store) => {
  store = _store;
};

export const getStore = () => {
  return store;
};

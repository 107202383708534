/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Collapse, Space, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
// TODO: change category icon
import 'components/discovery/filters/FilterList/FilterList.scss';
// TODO: should copy css from above file to below
import './FilterList.scss';

const { Panel } = Collapse;

// TODO: add translation?
// TODO: show loading or data come on component load?
const FilterList = ({ tabHeading = 'Category', updateFilter, filterData = {} }) => {
  const getSortedTechnologyKeys = (filters) => {
    return Object.keys(filters).sort();
  };

  // TODO: move out to make common - this is local fn
  const handleChange = (heading, option) => {
    updateFilter({
      heading,
      option,
    });
  };

  return (
    <div className="slintel-filter-list">
      {/* TODO: add news css class/ add new css file and params classname */}
      <div className="discovery-filters">
        <Collapse
          accordion
          bordered={false}
          className="filter-group"
          collapsible="disabled"
          defaultActiveKey={tabHeading}
        >
          {/* TODO: change icon , make header component, var css */}
          <Panel
            header={
              <div className="filter-header">
                <div className="filter-header-text">
                  <Space size={5}>
                    <Text>{tabHeading}</Text>
                  </Space>
                </div>
              </div>
            }
            key={tabHeading}
            showArrow={false}
          >
            {/* TODO: Check if memoizing can be useful for sorting */}
            {getSortedTechnologyKeys(filterData).map((level1Heading) => {
              // TODO: Make component
              return (
                <Collapse
                  accordion
                  bordered={false}
                  className="filter-group-item"
                  expandIconPosition="right"
                  key={level1Heading}
                >
                  {/* TODO: make header component */}
                  <Panel
                    header={
                      <div className="filter-header">
                        <Space size={5}>
                          <div className="filter-group-head">
                            <Tooltip placement="right" title={level1Heading}>
                              <Text>{level1Heading}</Text>
                            </Tooltip>
                          </div>
                        </Space>
                      </div>
                    }
                    key={level1Heading}
                  >
                    {/* Add style */}
                    <div style={{ marginBottom: '16px' }}>
                      {filterData[level1Heading].sort().map((option) => {
                        return (
                          // Add style
                          <Tooltip title={option} key={option}>
                            <div
                              className="filter-label-option-container"
                              onClick={() => handleChange(level1Heading, option)}
                            >
                              <Text ellipsis>{option}</Text>
                            </div>
                          </Tooltip>
                        );
                      })}
                    </div>
                  </Panel>
                </Collapse>
              );
            })}
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default FilterList;

import axios from 'axios';
import { getLoggedInUser, headerGenerator } from 'utils/common';
import { removeCookie } from 'utils/cookie';
import { toggleSessionTimeoutModal } from 'containers/common/commonActions';
import { TIMEOUT } from '../constants/constants';
import { getStore } from '../store/helper';

// eslint-disable-next-line import/prefer-default-export
export const getRequest = async (url, params, headers, cancelToken) => {
  let queryParams = '';
  if (params && Object.keys(params).length) {
    queryParams = Object.keys(params)
      .map((key) => {
        if (Array.isArray(params[key])) {
          return params[key].map((p) => `${key}=${p}`).join('&');
        }
        return `${key}=${params[key]}`;
      })
      .join('&');
    queryParams = `?${queryParams}`;
  }
  const finalUrl = `${url}${queryParams}`;
  const data = await axios
    .get(finalUrl, { headers, timeout: TIMEOUT, cancelToken })
    .then((resp) => resp.data)
    .catch((err) => getErrorResponse(err));
  return data;
};

export const getRequestWithConfig = async ({
  url,
  params,
  headers,
  cancelToken,
  method,
  defaultAxios,
  config,
}) => {
  let queryParams = '';
  if (params && Object.keys(params).length) {
    queryParams = Object.keys(params)
      .map((key) => {
        if (Array.isArray(params[key])) {
          return params[key].map((p) => `${key}=${p}`).join('&');
        }
        return `${key}=${params[key]}`;
      })
      .join('&');
    queryParams = `?${queryParams}`;
  }
  const finalUrl = `${url}${queryParams}`;
  const option = {
    method,
    url: finalUrl,
    headers,
    timeout: TIMEOUT,
    cancelToken,
    ...config,
  };
  if (defaultAxios) {
    Object.keys(defaultAxios).forEach((key) => {
      axios.defaults[key] = defaultAxios[key];
    });
  }
  axios.defaults.withCredentials = true;
  const data = await axios(option)
    .then((resp) => resp.data)
    .catch((err) => getErrorResponse(err));
  return data;
};

export const postRequest = async (url, params, headers, cancelToken, config, csrfToken) => {
  if (csrfToken) {
    axios.defaults.headers.post['xsrf-token'] = csrfToken;
  }
  const data = await axios({
    method: 'post',
    url,
    data: params,
    headers,
    cancelToken,
    timeout: TIMEOUT,
    ...config,
  })
    .then((resp) => resp.data)
    .catch((err) => getErrorResponse(err));
  return data;
};

export const uplodFile = async (url, formData, config) => {
  const data = await axios
    .post(url, formData, config)
    .then((resp) => resp.data)
    .catch((err) => getErrorResponse(err));
  return data;
};

export const deleteRequest = async (url, params, headers) => {
  const data = await axios
    .delete(url, {
      headers,
      timeout: TIMEOUT,
    })
    .then((resp) => resp.data)
    .catch((err) => getErrorResponse(err));
  return data;
};

/**
 * NOTE - DONT USE PUT REQUEST FROM NOW ONWARDS - Soc2
 */
export const putRequest = async (url, params, headers, cancelToken) => {
  const data = await axios({
    method: 'put',
    url,
    data: params,
    headers,
    cancelToken,
    timeout: TIMEOUT,
  })
    .then((resp) => resp.data)
    .catch((err) => getErrorResponse(err));
  return data;
};

const getErrorResponse = (err) => {
  let errorMsg = 'We cannot reach the server at the moment. Please try again later';
  let errorField;
  let errorCode;
  if (err.response) {
    if (err.response.status === 401 || err.response.status === 511 || err.response.status === 440) {
      errorMsg = 'Unauthorized';
      // required to avoid page load in loop
      removeCookie('userToken');
      // using sessionStorage as page reload clears state
      sessionStorage.setItem('showTimeoutModal', true);
      getStore().dispatch(toggleSessionTimeoutModal(true));
    } else if (err.response.status === 403 && err.response.statusText === 'Forbidden') {
      errorMsg =
        err.response.data.msg || 'Missing authentication token. Please contact support team';
    } else if (err.response.status === 500) {
      errorMsg = err.response.data.msg || 'Internal server error, try again later';
    } else if (err.response.status > 500) {
      errorMsg = 'We cannot reach the server at the moment. Please try again later';
    } else if (err.response.data?.msg) {
      errorMsg = err.response.data.msg || errorMsg;
    } else if (err.response.data?.error) {
      errorMsg = err.response.data.error.message;
    } else if (err.response.status === 429 && typeof err.response?.data === 'string') {
      errorMsg = err.response.data;
    }
    if (err.response.data && err.response.data.field) {
      errorField = err.response.data.field || errorField;
    }
    if (err.response.data && err.response.data.errorCode) {
      errorCode = err.response.data.errorCode || errorCode;
    }
  }
  return {
    error: true,
    message: errorMsg,
    errorField,
    errorCode,
  };
};

const authInterceptor = (request) => {
  const userInfo = getLoggedInUser();
  const headers = headerGenerator(userInfo?.token, userInfo?.session_id);
  request.headers.common = { ...request.headers.common, ...headers };
  return request;
};

axios.interceptors.request.use(authInterceptor);

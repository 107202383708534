import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { LockedIcon } from '../CustomIcon/CustomIcon';

const withWrapper = (WrappedComp) => (props) => <WrappedComp {...props} />;

const SideNavMenuIcon = ({ component, path, locked, type, theme }) => {
  const Wrapper = withWrapper(component);

  const renderLink = () => {
    return type === 'link' ? (
      <a style={{ display: 'flex' }} href={path}>
        <Wrapper fill="#D6E0FF" />
      </a>
    ) : (
      <NavLink style={{ display: 'flex' }} to={`${path}`}>
        <Wrapper fill="#D6E0FF" />
      </NavLink>
    );
  };

  return locked ? (
    <div className="locked-nav__item">
      <Wrapper className="locked-nav" />
      <sub className="nav-lock">
        <LockedIcon className={`lock-icon-${theme}`} />
      </sub>
    </div>
  ) : (
    renderLink()
  );
};

SideNavMenuIcon.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  path: PropTypes.string,
  locked: PropTypes.bool,
  type: PropTypes.string,
  theme: PropTypes.string,
};

export default SideNavMenuIcon;

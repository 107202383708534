import React from 'react';
import * as PropTypes from 'prop-types';
import { Drawer, Button } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import './creditInfo.scss';
import useProgress from 'UI/CustomHooks/Progress';
import { USER_ROLES_CONSTANT } from 'constants/constants';
import { toggleUpgradeModal } from 'containers/common/commonActions';
import { showError } from './errorMessage';

const CreditInfo = ({
  toggle, credits, isCreditDrawer, errorMessage,
}) => {
  const dispatch = useDispatch();
  const Progress = useProgress(credits, 56, '#DFE7F0');
  const userRole = useSelector((state) => state.auth.user.userRole, shallowEqual);
  const planPermission = useSelector((state) => state.common.planPermission);

  const getPlanTheme = () => {
    return planPermission && planPermission.theme ? planPermission.theme : '';
  };

  const getClassName = () => {
    switch (getPlanTheme()) {
      case 'blue':
        return 'upgrade-blue';
      case 'red':
        return 'upgrade-red';
      default:
        return '';
    }
  };

  const upgradeBtnClick = () => {
    toggle(false);
    dispatch(toggleUpgradeModal(true));
  };

  if (errorMessage) {
    showError(errorMessage);
  }
  return (
    <Drawer
      onClose={() => toggle(false)}
      visible={isCreditDrawer}
      style={{
        marginLeft: '60px',
      }}
      width="400px"
      title={(
        <Text
          style={{
            color: '#29275F',
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'left',
            letterSpacing: '0.8px',
            textTransform: 'uppercase',
            opacity: 1,
          }}
        >
          Credits
        </Text>
      )}
      placement="left"
    >
      {credits !== null
      && credits !== undefined
      && credits.credits_left !== null
      && credits.credits_left !== undefined ? (
        <div className="credit-info-body">
          <div className="credit-card">
            <div className="credit-card__header">
              <Text className="credit-card__header-text">Credit Balance</Text>
            </div>
            <div className="credit-card__content">
              {Progress}
              <div className="credit-card__content-body" style={{ marginLeft: '10px' }}>
                <Text className="credit-card__content-text">{credits.credits_left}</Text>
                <Text className="credit-card__content-small-text">
                  {`/${(credits.credits_left || 0) + (credits.credits_used || 0)} `}
                  Credits
                </Text>
              </div>
            </div>
          </div>

          {/* <div className="credit-card">
            <div className="credit-card__header">
              <Text className="credit-card__header-text"> Exported To CSV </Text>
              <Link to="/" className="credit-card__header-link">
                See History
                <RightOutlined />
              </Link>
            </div>
            <div className="credit-card__content">
              <div className="credit-card__content-body">
                <Text className="credit-card__content-text"> 373103 </Text>
              </div>
            </div>
          </div>

          <div className="credit-card">
            <div className="credit-card__header">
              <Text className="credit-card__header-text"> Exported To Hubspot </Text>
              <Link to="/" className="credit-card__header-link">
                See History
                <RightOutlined />
              </Link>
            </div>
            <div className="credit-card__content">
              <div className="credit-card__content-body">
                <Text className="credit-card__content-text"> 373103 </Text>
              </div>
            </div>
          </div>

          <div className="credit-card">
            <div className="credit-card__header">
              <Text className="credit-card__header-text"> Exported To Salesforce </Text>
              <Link to="/" className="credit-card__header-link">
                See History
                <RightOutlined />
              </Link>
            </div>
            <div className="credit-card__content">
              <div className="credit-card__content-body">
                <Text className="credit-card__content-text"> 373103 </Text>
              </div>
            </div>
          </div> */}

          <div className="credit-card no-border">
            <div className="role-card">
              <div className="role-card__content">
                <Text className="role-card__small-text"> You are on the </Text>
                <Text className="role-card__head-text">{`${USER_ROLES_CONSTANT[userRole]}`}</Text>
              </div>
              {[10, 11].includes(userRole) ? (
                <div className="upgrade-button">
                  {' '}
                  <Button
                    type="primary"
                    className={getClassName()}
                    onClick={() => upgradeBtnClick()}
                  >
                    Upgrade
                  </Button>
                </div>
              ) : (
                <Button
                  className="role-card__button"
                  type="danger"
                  target="_blank"
                  href="https://www.slintel.com/contact"
                >
                  Contact Us
                </Button>
              )}
            </div>
          </div>
        </div>
        ) : null}
    </Drawer>
  );
};

CreditInfo.propTypes = {
  toggle: PropTypes.func,
  isCreditDrawer: PropTypes.bool,
  credits: PropTypes.object,
  errorMessage: PropTypes.string,
};

export default CreditInfo;

import { Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import Button from 'UI/Button';
import { NotificationBellIcon, UnfollowIcon } from 'UI/CustomIcon/CustomIcon';
import CommonService from 'api/services/commonService';
import './CompanyFollowButtom.scss';
import { shallowEqual, useSelector } from 'react-redux';
import { headerGenerator } from 'utils/common';
import { showError, showSuccess } from 'components/common/errorMessage';
import { eventActions } from 'utils/eventManager';

const CompanyFollowButtom = ({ translation: t, id, companyName, sendEvent }) => {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const headers = headerGenerator(user.token, user.session_id);
  const [loading, setLoading] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [followId, setFollowId] = useState(null);

  useEffect(() => {
    if (id) {
      getFollowInfo();
    }
    return () => {
      // TODO: cancel request. memory leak
    };
  }, [id]);

  const getFollowInfo = async () => {
    setLoading(true);
    const res = await CommonService.getCompanyFollow(id, headers);
    setLoading(false);
    if (res && res.id) {
      setIsFollow(true);
      setFollowId(res.id);
    } else {
      setIsFollow(false);
    }
  };

  const handleFollow = async () => {
    setLoading(true);
    const res = await CommonService.followCompany(
      {
        cId: id,
        companyName,
        userId: user.userId,
      },
      headers
    );
    if (res.error) {
      showError(res.message);
    } else {
      showSuccess(t('labels.COMPANY_FOLLOWED'));
    }
    setLoading(false);
    if (res && res.id) {
      setIsFollow(true);
      setFollowId(res.id);
    }
    sendEvent(eventActions.COMPANY_FOLLOW);
  };

  const handleUnfollow = async () => {
    setLoading(true);
    const res = await CommonService.unFollowCompany(followId, headers);
    if (res.error) {
      showError(res.message);
    } else {
      showSuccess(t('labels.COMPANY_UNFOLLOWED'));
    }
    setLoading(false);
    if (res.msg === 'success') {
      setIsFollow(false);
    }
    sendEvent(eventActions.COMPANY_UNFOLLOW);
  };

  const getIcon = () => {
    if (loading) {
      return <Spin size="small" />;
    }
    if (isFollow) {
      return <UnfollowIcon onClick={handleUnfollow} fill="#4a6ee5" width={18} />;
    }
    return <NotificationBellIcon onClick={handleFollow} fill="#4a6ee5" width={18} />;
  };

  return (
    <div className="addtolist-button-container">
      <Tooltip placement="top" title={isFollow ? t('labels.UNFOLLOW') : t('labels.FOLLOW')}>
        <Button style={{ background: '#E9F6FE' }} theme="secondary" icon={getIcon()} />
      </Tooltip>
    </div>
  );
};

export default CompanyFollowButtom;

import React from 'react';
import { Space } from 'antd';
import FollowButton from './FollowButton';

const ProfileActionsContainer = function ({ handleUnfollow, handleFollow, record }) {
  return (
    <div className="d-flex">
      <Space size={10} className="me-3">
        {handleFollow && (
          <FollowButton data={record} handleFollow={handleFollow} handleUnfollow={handleUnfollow} />
        )}
      </Space>
    </div>
  );
};

export default ProfileActionsContainer;

import { Button } from 'antd';
import React from 'react';
import * as PropTypes from 'prop-types';
import './Pills.scss';

const Pills = ({ customStyle = {}, IconComponent, disabled = false, onClick }) => {
  const renderButton = () => (
    <Button
      size="small"
      className="pills-button"
      style={customStyle}
      onClick={onClick}
      disabled={disabled}
    >
      {IconComponent}
    </Button>
  );

  return renderButton();
};

Pills.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  IconComponent: PropTypes.node.isRequired,
  customStyle: PropTypes.object,
};

export default Pills;

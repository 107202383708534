import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import './RoutedTabs.scss';

const { TabPane } = Tabs;
/**
 * RoutedTabs is Ant Design based dynamic navigation tabs component.
 * It takes router config as prop and render the component as well as update the
 * window location corrosponding to the tab.
 * Example of routeConfig
 * const routeConfig = {
    'news-feed': {
      label: 'NEWS FEEDS',
      component: News,
      getRoute: (url) => `${url}/news-feed`,
    },
    companies: {
      label: 'TOP COMPANIES TO TARGET',
      getRoute: (url) => `${url}/companies`,
    },
    'target-companies': {
      label: 'COMPETITORS TARGETING',
      getRoute: (url) => `${url}/target-companies`,
    },
  };
    This will render three tabs and routes
    based on what getRoute method returns.
 */
const RoutedTabs = (props) => {
  const { tabsProps, routeConfig, defaultTabRoute } = props;

  const match = useRouteMatch();
  const history = useHistory();

  const { url, path } = match;
  const tabToRouteMap = {};
  const routeToTabsMap = {};
  Object.keys(routeConfig).forEach((key) => {
    const configObj = routeConfig[key];
    const routeURL = configObj.getRoute(url);
    tabToRouteMap[key] = routeURL;
    routeToTabsMap[routeURL] = key;
  });
  const defaultActiveKey = routeToTabsMap[history.location.pathname];

  useEffect(() => {
    const routeMap = Object.keys(routeConfig).map((key) => routeConfig[key]);
    if (typeof tabsProps.onChange === 'function') {
      tabsProps.onChange(defaultActiveKey);
    }
    if (
      !routeMap.find(
        (e) =>
          e.getRoute(match.path) === history.location.pathname ||
          `${e.getRoute(match.path)}/` === history.location.pathname
      )
    ) {
      if (match.path === history.location.pathname) {
        history.push(`${match.path}/${defaultTabRoute}`);
      } else {
        window.location.replace(match.path);
      }
    }
  }, [history.location.pathname]);

  const tabInfo = (configObj) => {
    return configObj.icon ? (
      <span>
        <configObj.icon height="16" width="16" className="tab-icon" />
        {configObj.label}
      </span>
    ) : (
      <span>{configObj.label}</span>
    );
  };

  const tabPaneNodes = Object.keys(routeConfig).map((routeKey) => {
    const configObj = routeConfig[routeKey];
    return (
      <TabPane tab={tabInfo(configObj)} key={routeKey}>
        <Route
          path={configObj.getRoute(path)}
          key={routeKey}
          component={() => <configObj.component tab={routeKey} />}
        />
      </TabPane>
    );
  });

  const onTabChange = (activeKey) => {
    if (defaultActiveKey === activeKey) return;
    history.push({
      pathname: tabToRouteMap[activeKey],
      search: history.location.search,
    });
    if (typeof tabsProps.onChange === 'function') {
      tabsProps.onChange(activeKey);
    }
  };

  return (
    <>
      <div className="card-container">
        <Tabs {...tabsProps} onChange={onTabChange} size="large" activeKey={defaultActiveKey}>
          {tabPaneNodes}
        </Tabs>
      </div>
    </>
  );
};

export default RoutedTabs;

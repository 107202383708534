export const VISUAL_WALKTHROUGH_SRC = 'https://www.youtube.com/embed/W8tSZWOKzWI';
export const CHROME_EXTENSION_SRC =
  'https://chrome.google.com/webstore/detail/slintel-technology-insigh/mkpoonlmiaknmcdcmdhbnehhglndcolf';
export const SLINTEL_SUPPORT_SRC = 'https://slintel.freshdesk.com/support/solutions';

const DASHBOARD_URL = process.env.DASHBOARD_URL;
export const APP_ROUTE_SRC = {
  LEAD_DETAILS: `${DASHBOARD_URL}/lead-details`,
  COMPANY_DETAILS: `${DASHBOARD_URL}/company-details`,
  TECHNOLOGY_DETAILS: `${DASHBOARD_URL}/technologies-details`,
  PSYCHOGRAPHICS_DETAILS: `${DASHBOARD_URL}/keywords-details`,
};

import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Spin } from 'antd';

import App from './App';
import store from './store/store';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import { injectStore } from './store/helper';

// To Do: To check if this can be moved out of index.js
/* Sentry configuration begins */

const isProduction = process.env.REACT_APP_ENV === 'production';
if (isProduction) {
  Sentry.init({
    dsn: process.env.DSN_URL,
    tracesSampleRate: 0.5,
  });
}
/* Sentry configuration ends */

const Root = () => (
  // <React.StrictMode>
  <React.Suspense
    fallback={
      <div className="loader-container">
        <Spin size="large" />
      </div>
    }
  >
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </React.Suspense>
  // </React.StrictMode>
);
injectStore(store);

ReactDOM.render(<Root />, document.getElementById('root'));

// window.addEventListener('storage', (event) => {
//   if (event.key === 'updatedStore') {
//     window.isDirty = true;
//   }
// });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useEffect, useState, useRef } from 'react';
import { Button, Tooltip } from 'antd';
import { usePrevious } from 'utils/hooks/customHooks';
import { isEqual } from 'utils/common';
import './email-availability.scss';

const EMAIL_FILTER_MAPPER = { 'A+': 1, A: 2, B: 3 };

const getEmailValue = (filters) => filters.map((key) => EMAIL_FILTER_MAPPER[key] || 0);

const EmailAvailability = ({ onChange, filters = [] }) => {
  const [customClass, setCustomClass] = useState({
    email: getEmailValue(filters),
    phone: 0,
  });
  const isFirstRun = useRef(true);

  const previousFilter = usePrevious(filters);

  useEffect(() => {
    if (filters && !isEqual(previousFilter, filters)) {
      setCustomClass({
        ...customClass,
        email: getEmailValue(filters),
      });
    }
  }, [filters]);

  function setEmailFilter(param) {
    if (param === 0) {
      setCustomClass({ ...customClass, email: [] });
      return;
    }
    const selectivity = Object.entries(EMAIL_FILTER_MAPPER).reduce((acc, [key, value]) => {
      acc[key] = customClass.email.includes(value);
      return acc;
    }, {});

    if (
      (param === EMAIL_FILTER_MAPPER.B && !selectivity.B && selectivity.A && selectivity['A+']) ||
      (param === EMAIL_FILTER_MAPPER['A+'] &&
        !selectivity['A+'] &&
        selectivity.B &&
        selectivity.A) ||
      (param === EMAIL_FILTER_MAPPER.A && !selectivity.A && selectivity['A+'] && selectivity.B)
    ) {
      setCustomClass({ ...customClass, email: [1, 2, 3] });
      return;
    }

    const index = customClass.email.indexOf(param);
    if (index !== -1) {
      const newArray = customClass.email;
      newArray.splice(index, 1);
      setCustomClass({ ...customClass, email: newArray });
    } else {
      setCustomClass({ ...customClass, email: [...customClass.email, param] });
    }
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    onChange(customClass.email);
  }, [customClass]);

  return (
    <div>
      <Button
        onClick={() => {
          setEmailFilter(0);
        }}
        className={`custom-btn-group btn-rounded-right ${
          customClass.email.length === 0 ? 'custom-btn-off-active' : ''
        }`}
      >
        <Tooltip placement="topLeft" title="All people">
          Off
        </Tooltip>
      </Button>
      <Button
        onClick={() => {
          setEmailFilter(3);
        }}
        className={`custom-btn-group btn-custom-square ${
          customClass.email.indexOf(3) !== -1 ? 'custom-btn-on' : ''
        } ${customClass.email.length === 0 ? 'custom-btn-off' : ''} `}
      >
        <Tooltip placement="topLeft" title="Emails with moderate confidence.">
          B
        </Tooltip>
      </Button>
      <Button
        onClick={() => {
          setEmailFilter(2);
        }}
        className={`custom-btn-group btn-custom-square ${
          customClass.email.indexOf(2) !== -1 ? 'custom-btn-on' : ''
        } ${customClass.email.length === 0 ? 'custom-btn-off' : ''}`}
      >
        <Tooltip
          placement="topLeft"
          title="Emails with an estimated 10-20% bounce rate. These have passed 2 levels of validation."
        >
          A
        </Tooltip>
      </Button>
      <Button
        onClick={() => {
          setEmailFilter(1);
        }}
        className={`custom-btn-group btn-rounded-left ${
          customClass.email.indexOf(1) !== -1 ? 'custom-btn-on' : ''
        } ${customClass.email.length === 0 ? 'custom-btn-off' : ''}`}
      >
        <Tooltip
          placement="topLeft"
          title="Emails with an estimated <10% bounce rate. These have passed 3 levels of validation, and have highest deliverability."
        >
          A+
        </Tooltip>
      </Button>
    </div>
  );
};
const PhoneAvailability = ({ onChange }) => {
  const [customClass, setCustomClass] = useState({
    email: [],
    phone: 0,
  });
  return (
    <div>
      <Button
        onClick={() => {
          onChange(0);
          setCustomClass({ ...customClass, phone: 0 });
        }}
        className={`custom-btn-group btn-rounded-right ${
          customClass.phone === 0 ? 'custom-btn-off-active' : ''
        } `}
      >
        <Tooltip placement="topLeft" title="All people">
          Off
        </Tooltip>
      </Button>
      <Button
        onClick={() => {
          onChange(1);
          setCustomClass({ ...customClass, phone: 1 });
        }}
        className={`custom-btn-group btn-rounded-left ${
          customClass.phone === 1 ? 'custom-btn-on' : ''
        } ${customClass.phone === 0 ? 'custom-btn-off' : ''} `}
      >
        <Tooltip placement="topLeft" title="All people with work & other phone numbers">
          All
        </Tooltip>
      </Button>
    </div>
  );
};

export { PhoneAvailability, EmailAvailability };

import {
  PROFILE_REMOVED_LISTS_INIT,
  PROFILE_REMOVED_LISTS_SUCCESS,
  PROFILE_REMOVED_LISTS_FAILURE,
} from 'store/actionTypes';

const initialState = {
  lists: [],
  total: 0,
  errorMessage: '',
  successMessage: '',
};

const ProfileRemovedList = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_REMOVED_LISTS_INIT:
      return {
        ...state,
      };
    case PROFILE_REMOVED_LISTS_SUCCESS: {
      const {
        data: { data, total },
      } = action;
      let { lists } = state;
      lists = [...data];
      return {
        ...state,
        lists,
        total,
        errorMessage: '',
        successMessage: 'List fetched successfully',
      };
    }
    case PROFILE_REMOVED_LISTS_FAILURE: {
      const { error } = action;
      return {
        ...state,
        errorMessage: error,
        successMessage: '',
      };
    }
    default:
      return state;
  }
};

export default ProfileRemovedList;

/* eslint-disable import/no-unresolved */
import React from 'react';
import FullStory from 'react-fullstory';

const ORG_ID = process.env.FULL_STORY_ORG_ID;

const FullstoryConfig = () => {
  return (
    <div className="app">
      <FullStory org={ORG_ID} />
    </div>
  );
};

export default FullstoryConfig;

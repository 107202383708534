import React from 'react';
import * as PropTypes from 'prop-types';
import CreditInfo from 'components/common/creditInfo';
import Profile from 'components/common/profile';

const Common = (props) => {
  const {
    credits, isCreditDrawer, toggle, isProfile, profileToggle,
  } = props;
  return (
    <div>
      <CreditInfo toggle={toggle} isCreditDrawer={isCreditDrawer} credits={credits} />
      <Profile toggle={profileToggle} isProfile={isProfile} />
    </div>
  );
};

Common.propTypes = {
  credits: PropTypes.object,
  isCreditDrawer: PropTypes.bool,
  toggle: PropTypes.func,
  isProfile: PropTypes.bool,
  profileToggle: PropTypes.func,
};

export default Common;

import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import scssVariables from 'src/_vars.scss';

const BarChart = ({ options, highcharts }) => {
  const { xtitle, ytitle, data } = options;
  const barOptions = {
    credits: {
      enabled: false,
    },
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: xtitle,
      },
      categories: Object.keys(data),
    },
    yAxis: {
      min: 0,
      title: {
        text: ytitle,
      },
      labels: {
        // eslint-disable-next-line
        formatter: function () {
          // eslint-disable-next-line
          return highcharts.numberFormat(this.value, 0, '', '');
        },
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
      // eslint-disable-next-line
      formatter: function () {
        // eslint-disable-next-line
        return highcharts.numberFormat(this.y, 0, '', '');
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        data: Object.values(data),
        states: {
          hover: {
            color: scssVariables.selectedQuickViewItem,
            borderColor: scssVariables.selectedQuickViewItem,
          },
        },
      },
    ],
    colors: [scssVariables.slintelBlue1],
  };
  return (
    Object.keys(data).length && <HighchartsReact highcharts={highcharts} options={barOptions} />
  );
};
export default BarChart;

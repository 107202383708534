import { Button, Tooltip } from 'antd';
import React from 'react';
import * as PropTypes from 'prop-types';
import './IconButton.scss';

const IconButton = ({
  tooltip = '',
  tooltipPlacement = 'bottom',
  customStyle = {},
  IconComponent,
  disabled = false,
  onClick,
}) => {
  const renderButton = () => (
    <Button
      className="custom-icon-button"
      style={customStyle}
      onClick={onClick}
      disabled={disabled}
    >
      {IconComponent}
    </Button>
  );

  // check if need to render tooltip
  if (tooltip) {
    return (
      <Tooltip placement={tooltipPlacement} title={tooltip}>
        {renderButton()}
      </Tooltip>
    );
  }
  return renderButton();
};

IconButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  IconComponent: PropTypes.element.isRequired,
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  customStyle: PropTypes.object,
};

export default IconButton;

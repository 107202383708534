import React from 'react';
import { Alert, Row, Col } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import './CustomAlert.scss';
import { InfoNewIcon } from 'UI/CustomIcon/CustomIcon';

export const CustomAlert = (props) => {
  const { message } = props;
  return <Alert message={message} type="info" className="custom-info-alert" {...props} />;
};

export const CustomWarningAlert = (props) => {
  const { message } = props;
  return (
    <Alert
      icon={<WarningOutlined style={{ color: '#FF7C0B', fontSize: '24px' }} />}
      message={message}
      type="warning"
      className="custom-warning-alert"
      {...props}
      showIcon
    />
  );
};

export const CustomInfoAlert = ({ message, description }) => {
  return (
    <Alert
      message={message}
      type="info"
      className="custom-info-alert"
      description={
        <Row>
          <Col span={1} className="custom-alert-info-icon">
            <InfoNewIcon height="14px" width="14px" />
          </Col>
          <Col span={23}>{description}</Col>
        </Row>
      }
    />
  );
};

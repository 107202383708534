import React, { Component, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import './i18n';
import Dashboard from 'containers/dashboard/dashboardIndex';
import updateUser from 'containers/auth/authActions';
import TagManager from 'react-gtm-module';
import {
  getCreditInfo,
  getHubspotConfig,
  getSalesForceConfig,
  getPlanPermission,
  getUserInfoRedis,
  getOutreachConfig,
  getPipedriveConfig,
} from 'containers/common/commonActions';
import { Spinner } from 'components/common/loader';
import {
  NOTIFICATION_DURATION,
  MINIMUM_REQUIRED_SCREEN_WIDTH,
  SMALL_SCREEN_INFO_TOASTER_MESSAGE,
  USER_ROLES_MAP,
} from 'constants/constants';

// Third Party
import LogRocket from 'logrocket';
import Pusher from 'pusher-js';
import * as Sentry from '@sentry/react';
import FullstoryConfig from 'third-party/FullstoryConfig';
import Mixpanel from 'third-party/MixpanelConfig';
import NotFound from 'components/common/notFound';
import SmartList from 'containers/smartList/smartListIndex';
import Technology from 'containers/technology-psychographics/technology/TechnologyIndex';
import TechnologyDetails from 'containers/technology-psychographics/technology/TechnologyDetailsIndex';
import Keyword from 'containers/technology-psychographics/psychographics/KeywordIndex';
import KeywordDetails from 'containers/technology-psychographics/psychographics/KeywordDetailsIndex';
import SmartListItem from './components/list/smartListItem';
import { headerGenerator, getLoggedInUser } from './utils/common';
import PrivateRoute from './privateRoute';
import './App.scss';
import { showInfo } from './components/common/errorMessage';
import ToastNotification from './components/common/toastNotification';

const OutreachIntegration = lazy(() =>
  import(
    /* webpackChunkName: "OutreachIntegration" */ './components/integrations/outreachIntegration'
  )
);

const DownloadChromeIndex = lazy(() =>
  import(
    /* webpackChunkName: "DownloadChromeIndex" */ 'containers/register/downloadChrome/downloadChromeIndex'
  )
);

const ResetPasswordIndex = lazy(() =>
  import(
    /* webpackChunkName: "resetPasswordIndex" */ 'containers/register/resetPassword/resetPasswordIndex'
  )
);

// const SmartListItem = lazy(() =>
//   import(/* webpackChunkName: "smartListItem" */ 'components/list/smartListItem')
// );

// const SmartList = lazy(() =>
//   import(/* webpackChunkName: "smartList" */ 'containers/smartList/smartListIndex')
// );

const JobPosting = lazy(() =>
  import(/* webpackChunkName: "jobPosting" */ 'containers/JobPosting/JobPosting')
);

const Discover = lazy(() =>
  import(/* webpackChunkName: "discover" */ 'containers/discovery/discoveryIndex')
);
const LeadDetails = lazy(() =>
  import(/* webpackChunkName: "LeadDetails" */ 'containers/leadCompanyDetails/LeadDetails')
);
const CompanyDetails = lazy(() =>
  import(/* webpackChunkName: "LeadDetails" */ 'containers/leadCompanyDetails/CompanyDetails')
);

const Notifications = lazy(() => import('components/notification/notifications'));

const Pipedrive = lazy(() => import('components/integrations/pipedrive'));

const SalesforceIntegration = lazy(() => import('components/integrations/salesforce/salesforce'));

const HubspotIntegration = lazy(() => import('components/integrations/hubspot/hubspot'));

const settings = lazy(() => import('components/settings/settings'));

const profileRemovedList = lazy(() =>
  import('containers/profileRemovedList/profileRemovedListIndex')
);

const hubspotEnrichment = lazy(() => import('components/integrations/hubspotEnrichment'));

const OpenExtensionIndex = lazy(() =>
  import('containers/register/openExtension/openExtensionIndex')
);

const ForgotPasswordIndex = lazy(() =>
  import('containers/register/forgotPassword/forgotPasswordIndex')
);

const LoginIndex = lazy(() => import('containers/register/login/loginIndex'));

const SignupIndex = lazy(() => import('containers/register/signup/signupIndex'));

const SignupVerifyIndex = lazy(() => import('containers/register/signupVerify/signupVerifyIndex'));

const SignupVerifiedIndex = lazy(() =>
  import('containers/register/signupVerified/signupVerifiedIndex')
);

const isProduction = process.env.REACT_APP_ENV === 'production';
function showMobileInfoToaster() {
  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  if (width < MINIMUM_REQUIRED_SCREEN_WIDTH) {
    showInfo('Information message', {
      placement: 'topLeft',
      duration: 2 * NOTIFICATION_DURATION,
      description: SMALL_SCREEN_INFO_TOASTER_MESSAGE,
    });
    window.removeEventListener('resize', showMobileInfoToaster);
    window.removeEventListener('load', showMobileInfoToaster);
  }
}

if (!isProduction) {
  Pusher.logToConsole = true;
}
const pusher = new Pusher(process.env.PUSHER_APP_KEY, {
  cluster: process.env.PUSHER_APP_CLUSTER,
});

// const { Content } = Layout;

const mapStateToProps = (state = {}) => ({
  credits: state.common.credits,
  userEmail: state.auth.user.userEmail || '',
  planPermission: state.common.planPermission,
  sessionTimeout: state.common.sessionTimeoutModal,
});

const tagManagerArgs = {
  gtmId: 'GTM-P7X23FN',
};
TagManager.initialize(tagManagerArgs);
const privateRoutes = [
  {
    path: '/dashboard',
    component: Dashboard,
  },
  {
    path: '/discover/:id',
    component: Discover,
  },
  {
    path: '/lead-details/:id',
    component: LeadDetails,
  },
  {
    path: '/company-details/:id',
    component: CompanyDetails,
  },
  {
    path: '/list/:id',
    component: SmartListItem,
  },
  {
    path: '/list',
    component: SmartList,
  },
  {
    path: '/integrations/pipedrive',
    component: Pipedrive,
  },
  {
    path: '/integrations/hubspot-enrich',
    component: hubspotEnrichment,
  },
  {
    path: '/integrations/outreach/:id?',
    component: OutreachIntegration,
  },
  {
    path: '/integrations/salesforce/:id',
    component: SalesforceIntegration,
  },
  {
    path: '/integrations/hubspot/:id',
    component: HubspotIntegration,
  },
  {
    path: 'integrations/outreach',
    component: OutreachIntegration,
  },
  {
    path: '/notifications',
    component: Notifications,
  },
  {
    exact: true,
    path: '/my-account',
    component: settings,
  },
  {
    path: '/my-account/:tab?',
    component: settings,
  },
  {
    exact: true,
    path: '/profile-deletion-request-compliance',
    component: profileRemovedList,
  },
  {
    path: '/technologies/:category/:subcategory',
    component: Technology,
  },
  {
    path: '/technologies-details/:id',
    component: TechnologyDetails,
  },
  {
    path: '/keywords/:category/:subcategory',
    component: Keyword,
  },
  {
    path: '/keywords-details/:id',
    component: KeywordDetails,
    exact: true,
  },
  {
    path: '/job-posting',
    component: JobPosting,
  },
];

const publicRoutes = [
  {
    exact: true,
    path: '/',
    component: LoginIndex,
    activeSessionRedirectionPath: '/dashboard',
  },
  { path: '/signup', component: SignupIndex, activeSessionRedirectionPath: '/dashboard' },
  {
    path: '/verification',
    component: SignupVerifyIndex,
    activeSessionRedirectionPath: '/dashboard',
  },
  {
    path: '/verified',
    component: SignupVerifiedIndex,
    activeSessionRedirectionPath: '/dashboard',
  },
  { path: '/login', component: LoginIndex, activeSessionRedirectionPath: '/dashboard' },
  {
    path: '/forgot-password',
    component: ForgotPasswordIndex,
    activeSessionRedirectionPath: '/dashboard',
  },
  { path: '/reset-password/:hash/:email', component: ResetPasswordIndex },
  {
    path: '/free-leads-extension',
    component: DownloadChromeIndex,
    activeSessionRedirectionPath: '/dashboard',
  },
  { path: '*', component: NotFound },
];
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      isLogin: false,
      token: '',
      session_id: '',
    };
    this.setupThirdPartyTracking();
  }

  componentDidMount = () => {
    window.addEventListener('resize', showMobileInfoToaster);
    window.addEventListener('load', showMobileInfoToaster);

    this.bootstrapWithInitialData();
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', showMobileInfoToaster);
    window.removeEventListener('load', showMobileInfoToaster);
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    const { sessionTimeout } = this.props;
    // added to solve security bug - https://6sense.atlassian.net/browse/APP-5833
    if (nextProps.sessionTimeout !== sessionTimeout && nextProps.sessionTimeout) {
      // redirect to  login
      window.location.replace(`${process.env.DASHBOARD_URL}/login`);
      return false;
    }
    return (
      nextState.isLogin !== this.getUserLoginInfo() || nextProps.sessionTimeout !== sessionTimeout
    );
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.setState({ isLogin: this.getUserLoginInfo() });
  };

  getUserLoginInfo = () => {
    return !!getLoggedInUser();
  };

  setupThirdPartyTracking = () => {
    const userInfo = getLoggedInUser();
    const { updateUserInfo, history } = this.props;

    if (userInfo) {
      updateUserInfo({
        user: userInfo,
        pusherChannel: pusher.subscribe(`dashboard-channel-${userInfo.userId}`),
      });
      this.state.isLogin = true;
      this.state.token = userInfo.token;
      this.state.session_id = userInfo.session_id;
      this.state.userId = userInfo.userId;
      this.state.userName = userInfo.userName;
      this.state.userEmail = userInfo.userEmail;
      this.state.accountId = userInfo.accountId;

      if (isProduction) {
        if (userInfo?.userRole !== USER_ROLES_MAP.FOREVER_FREE) {
          LogRocket.init(process.env.LOG_ROCKET_KEY);
          LogRocket.identify(userInfo.userId, {
            name: userInfo.userName,
            email: userInfo.userEmail,
          });
        }
        if (Mixpanel) {
          Mixpanel.identify(userInfo.userId);
          Mixpanel.people.set({
            $email: userInfo.userEmail,
            user_id: userInfo.userId,
            user_role: userInfo.userRole,
            has_onboarded: userInfo.has_onboarded,
            account_id: userInfo.accountId,
          });
        }
      }

      if (window.Appcues) {
        window.Appcues.identify(userInfo.userId, {
          createdAt: userInfo.createdAt,
          userName: userInfo.userName,
          userEmail: userInfo.userEmail,
          plan: userInfo.userRole,
        });
        window.Appcues.page();
        if (history) {
          history.listen(() => {
            window.Appcues.page();
          });
        }
      }
    }
  };

  bootstrapWithInitialData = () => {
    const {
      getCreditInfo: getCreditInfoFunction,
      getHubspotConfig: getHubspotConfigFunction,
      getSalesForceConfig: getSalesForceFunction,
      getPlanPermission: getPlanPermissionFunction,
      getUserInfoRedis: getUserInfoRedisFunction,
      getOutreachConfig: getOutreachFunction,
      getPipedriveConfig: getPipedriveConfigFuction,
    } = this.props;
    const { token, session_id: sessionId, accountId, userEmail } = this.state;
    if (!token || !sessionId) {
      return;
    }
    const headers = headerGenerator(token, sessionId);
    getPlanPermissionFunction(headers);
    getCreditInfoFunction(headers);
    getHubspotConfigFunction(headers);
    getSalesForceFunction(headers);
    getUserInfoRedisFunction(headers);
    getOutreachFunction(headers);
    getPipedriveConfigFuction(headers);
    if (window.ChurnZero) {
      window.ChurnZero.push(['setAppKey', process.env.CHURNZERO_APP_KEY]);
      window.ChurnZero.push(['setContact', accountId, userEmail]);
    }
  };

  toggle = () => {
    const { collapsed } = this.state;
    this.setState({
      collapsed: !collapsed,
    });
  };

  render() {
    const { isLogin } = this.state;
    const { credits, planPermission, sessionTimeout } = this.props;
    const isActiveSession = !sessionTimeout && isLogin;
    return (
      <>
        {isProduction && <FullstoryConfig />}
        <ToastNotification />
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route path="/installed">
              {!isActiveSession && <Redirect to={{ pathname: '/signup' }} />}
              {isActiveSession && <OpenExtensionIndex />}
            </Route>
            {privateRoutes.map(({ path, component, exact }) => (
              <PrivateRoute
                key={path}
                path={path}
                authDetails={{ isLogin, credits, planPermission }}
                component={component}
                exact={exact}
              />
            ))}
            {publicRoutes.map(
              ({ path, component: PublicComponent, exact, activeSessionRedirectionPath }) => (
                <Route key={path} path={path} exact={exact}>
                  {isActiveSession && activeSessionRedirectionPath ? (
                    <Redirect to={{ pathname: activeSessionRedirectionPath }} />
                  ) : (
                    <PublicComponent />
                  )}
                </Route>
              )
            )}
          </Switch>
        </Suspense>
      </>
    );
  }
}
const mapDispatchTopProps = (dispatch) => {
  return {
    updateUserInfo: (params) => dispatch(updateUser(params)),
    getCreditInfo: (headers) => dispatch(getCreditInfo(headers)),
    getHubspotConfig: (headers) => dispatch(getHubspotConfig(headers)),
    getSalesForceConfig: (headers) => dispatch(getSalesForceConfig(headers)),
    getPlanPermission: (headers) => dispatch(getPlanPermission(headers)),
    getUserInfoRedis: (headers) => dispatch(getUserInfoRedis(headers)),
    getOutreachConfig: (headers) => dispatch(getOutreachConfig(headers)),
    getPipedriveConfig: (headers) => dispatch(getPipedriveConfig(headers)),
  };
};

App.propTypes = {
  history: PropTypes.object.isRequired,
  updateUserInfo: PropTypes.func.isRequired,
  getCreditInfo: PropTypes.func.isRequired,
  getHubspotConfig: PropTypes.func.isRequired,
  getSalesForceFunction: PropTypes.func,
  getUserInfoRedisFunction: PropTypes.func,
  getOutreachFunction: PropTypes.func,
  getPipedriveConfigFuction: PropTypes.func,
};

const enhance = compose(
  Sentry.withProfiler,
  withRouter,
  connect(mapStateToProps, mapDispatchTopProps)
);

export default enhance(App);

import { getRequest, postRequest } from 'api/apiRequests';
import { baseURL, endPoints } from 'api/apiEndpoints';

const TechnologyService = {
  async fetchTechnologyAddition(technologyId, page = 1, searchTerm) {
    const offset = (page - 1) * 25;
    let url = `${baseURL}${endPoints.technologyAddition.replace(
      ':id',
      technologyId
    )}?limit=25&offset=${offset}&sort_key=created_at&sort_order=desc`;
    if (searchTerm) {
      url = `${url}&q=${searchTerm}`;
    }
    const response = await getRequest(url);
    return { data: response };
  },
  async fetchTechnologyDeletion(technologyId, page = 1, searchTerm) {
    const offset = (page - 1) * 25;

    let url = `${baseURL}${endPoints.technologyDeletion.replace(
      ':id',
      technologyId
    )}?limit=25&offset=${offset}&sort_key=created_at&sort_order=desc`;
    if (searchTerm) {
      url = `${url}&q=${searchTerm}`;
    }
    const response = await getRequest(url);
    return { data: response };
  },
  async fetchTechnologyRenewal(technologyId, page = 1, searchTerm, data = {}) {
    data.page = page;
    if (searchTerm) data.searchString = searchTerm;

    const url = `${baseURL}${endPoints.technologyRenewal}`;
    const response = await postRequest(url, data);
    response.data = response.items;
    return { data: response };
  },

  async fetchTechnologyCategories() {
    const url = `${baseURL}${endPoints.technologyCategories}`;
    const response = await getRequest(url);
    LogResponse('fetchTechnologyCategories', response);
    return { data: response };
  },
  async fetchKeywordCategories() {
    const url = `${baseURL}${endPoints.keywordCategories}`;
    const response = await getRequest(url);
    LogResponse('fetchKeywordCategories', response);
    return { data: response };
  },
  async fetchTechnologyCategoryList(params) {
    const url = `${baseURL}${endPoints.technologyCategoryList}`;
    const response = await getRequest(url, params);
    LogResponse('fetchTechnologyCategoryList', response);
    return { data: response };
  },
  async fetchTechnologyForSubCategory(params) {
    const url = `${baseURL}${endPoints.technology}`;
    const response = await getRequest(url, params);
    LogResponse('fetchTechnologyForSubCategory', response);
    return { data: response };
  },
  async fetchKeywordForSubCategory(params) {
    const url = `${baseURL}${endPoints.keywords}`;
    params.offset = 0;
    params.size = 500;
    const response = await getRequest(url, params);
    LogResponse('fetchKeywordForSubCategory', response);
    return { data: response };
  },
  async getCompanyBasicInfo(params, headers) {
    const url = `${baseURL}${endPoints.TECHNOLOGY_OVERVIEW}`;
    const updatedHeaders = { ...headers };
    const response = await getRequest(url, params, updatedHeaders);
    LogResponse('getCompanyBasicInfo', response);
    return response;
  },
  async getCountryHeatMap(params, headers) {
    const { techId } = params;
    const url = `${baseURL}${endPoints.TECHNOLOGY_GRAPH}/${techId}/country-heat-map`;
    const updatedHeaders = { ...headers };

    const response = await getRequest(url, {}, updatedHeaders);
    LogResponse('getCountryHeatMap', response);
    return response;
  },
  async getClosestCompetitors(params, headers) {
    const { techId } = params;
    const url = `${baseURL}${endPoints.TECHNOLOGY_GRAPH}/${techId}/closest-competitors`;
    const updatedHeaders = { ...headers };

    const response = await getRequest(url, {}, updatedHeaders);
    LogResponse('getClosestCompetitors', response);
    return response;
  },
  async getCompetitors(params, headers) {
    const { techId } = params;
    const url = `${baseURL}${endPoints.TECHNOLOGY_GRAPH}/${techId}/competitors`;
    const updatedHeaders = { ...headers };

    const response = await getRequest(url, {}, updatedHeaders);
    LogResponse('getCompetitors', response);
    return response;
  },
  async getAdds(params, headers) {
    const { techId } = params;
    const url = `${baseURL}${endPoints.TECHNOLOGY_GRAPH}/${techId}/adds`;
    const updatedHeaders = { ...headers };

    const response = await getRequest(url, {}, updatedHeaders);
    LogResponse('getAdds', response);
    return response;
  },
  async getDrops(params, headers) {
    const { techId } = params;
    const url = `${baseURL}${endPoints.TECHNOLOGY_GRAPH}/${techId}/drops`;
    const updatedHeaders = { ...headers };

    const response = await getRequest(url, {}, updatedHeaders);
    LogResponse('getDrops', response);
    return response;
  },
  async getCompanySize(params, headers) {
    const { techId } = params;
    const url = `${baseURL}${endPoints.TECHNOLOGY_GRAPH}/${techId}/company-size`;
    const updatedHeaders = { ...headers };

    const response = await getRequest(url, {}, updatedHeaders);
    LogResponse('getCompanySize', response);
    return response;
  },
  async getProductCategories(params, headers) {
    const { techId } = params;
    const url = `${baseURL}${endPoints.TECHNOLOGY_GRAPH}/${techId}/product-categories`;
    const updatedHeaders = { ...headers };

    const response = await getRequest(url, {}, updatedHeaders);
    LogResponse('getProductCategories', response);
    return response;
  },
  async getTechnologyLocation(params) {
    const url = `${baseURL}${endPoints.TECHNOLOGY_LOCATION_SUGGESTER}`;
    const response = await getRequest(url, params);
    LogResponse('getTechnologyLocation', response);
    return response;
  },
  async getTechnology(params, headers) {
    const url = `${baseURL}${endPoints.TECHNOLOGY_ID_MAPPER}`;
    const updatedHeaders = { ...headers };
    const response = await getRequest(url, params, updatedHeaders);
    LogResponse('getTechnology', response);
    return response;
  },
  async getCustomWorld() {
    const url = endPoints.CUSTOM_WORLD_GEO_JSON;
    const response = await getRequest(url, {}, {});
    LogResponse('getCustomWorld', response);
    return response;
  },
  async followTechnologyAndKeyword(payload) {
    const url = `${baseURL}${endPoints.follow}`;
    const response = await postRequest(url, payload);
    LogResponse('followTechnologyAndKeyword', response);
    return response;
  },
  async deleteFollowFiltersById(payload) {
    const url = `${baseURL}${endPoints.deleteFollow}`;
    const response = await postRequest(url, payload);
    LogResponse('deleteFollowFiltersById', response);
    return response;
  },
  async getCustomFiltersTechnology(params) {
    const url = `${baseURL}${endPoints.savedFilters}`;
    const response = await getRequest(url, params);
    LogResponse('getCustomFiltersTechnology', response);
    return response;
  },
  async getTechnologyCompaniesListData(params) {
    const url = `${baseURL}${endPoints.technologyCompanyDetails}`;
    const response = await getRequest(url, params);
    LogResponse('getTechnologyCompaniesListData', response);
    return response;
  },
  async fetchSmartLists(params) {
    const url = `${baseURL}${endPoints.lists}`;
    const response = await getRequest(url, params);
    LogResponse('fetchSmartLists', response);
    return response;
  },
};

const LogResponse = (message, response) => {
  // eslint-disable-next-line no-unused-expressions
  process.env.REACT_APP_ENV !== 'production' &&
    // eslint-disable-next-line no-console
    console.log(`🚀 ~ file: technologyService.js ~ ${message} ~ response'`, response);
};

export default TechnologyService;

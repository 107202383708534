import { put, takeLatest, call } from 'redux-saga/effects';
import { baseURL, endPoints } from 'api/apiEndpoints';
import { modifyStaticFilters } from 'utils/common';
import {
  CREDIT_INIT,
  FILTERS_INIT,
  DOMAIN_USERS_INIT,
  DELETE_FILTERS_INIT,
  OUTREACH_CONFIG,
  HUBSPOT_CONFIG,
  SALESFORCE_CONFIG,
  FETCH_PERMISSION,
  USER_INFO_REDIS,
  FILTER_LIST_DATA,
  SEARCH_LEADS_ERROR,
  SEARCH_COMPANIES_ERROR,
  UPDATE_FILTERS,
  DUPLICATE_FILTER_INIT,
  SHARE_SAVED_FILTER,
  ADDTO_LIST_END,
  PIPEDRIVE_CONFIG,
  SAVE_FILTERS,
} from 'store/actionTypes';
import { getRequest, deleteRequest, postRequest } from 'api/apiRequests';
import {
  creditSuccess,
  creditInfoFailure,
  filtersSuccess,
  filtersFailure,
  domainUsersListSuccess,
  domainUsersListFailure,
  deleteFiltersSuccess,
  deleteFiltersFailure,
  getHubspotConfigSuccess,
  getHubspotConfigFailure,
  getPipedriveConfigSuccess,
  getPipedriveConfigFailure,
  getSalesForceConfigSuccess,
  getSalesForceConfigFailure,
  getOutreachConfigSuccess,
  getOutreachConfigFailure,
  getFetchPermissionSuccess,
  getUserInfoRedisSuccess,
  getUserInfoRedisFailure,
  filterListSuccess,
  filterListError,
  showToastNotification,
  updateSuccess,
  duplicateFilterSuccess,
  shareFilterSuccess,
  shareFilterFailure,
  saveFilterSuccess,
  getSavedFilters as handleGetSavedFilter,
} from './commonActions';

// TODO: handle failure cases
function* getCreditinfo(action) {
  try {
    const { headers } = action.payload;
    const url = `${baseURL}${endPoints.creditInfo}`;
    const credits = yield call(getRequest, url, {}, headers);
    if (!credits.error) {
      yield put(
        creditSuccess({
          credits,
        })
      );
    } else {
      yield put(creditInfoFailure(credits.message));
    }
  } catch (err) {
    yield put(creditInfoFailure(err.message));
  }
}

function* getSavedFilters(action) {
  try {
    const { headers } = action.payload;
    const url = `${baseURL}${endPoints.savedFilters}`;
    const filters = yield call(getRequest, url, {}, headers);
    if (!filters.error) {
      yield put(
        filtersSuccess({
          filters,
        })
      );
    } else {
      yield put(filtersFailure(filters.message));
    }
  } catch (err) {
    yield put(filtersFailure(err.message));
  }
}

function* deleteSavedFilter(action) {
  try {
    const { headers, params } = action.payload;
    const url = `${baseURL}${endPoints.savedFilters}/${params.id}`;
    const filters = yield call(deleteRequest, url, {}, headers);
    if (!filters.error) {
      yield put(
        showToastNotification({ type: 'SUCCESS', message: 'Filter is deleted successfully' })
      );
      yield put(
        deleteFiltersSuccess({
          id: params.id,
        })
      );
    } else {
      yield put(deleteFiltersFailure(filters.message));
    }
  } catch (err) {
    yield put(deleteFiltersFailure(err.message));
  }
}

function* updateSavedFilters(action) {
  try {
    const { headers, params, data } = action.payload;
    const url = `${baseURL}${endPoints.updateFilters}/${params.id}`;
    const filters = yield call(postRequest, url, data, headers);
    if (!filters.error) {
      let toastMsg = 'Filter updated successfully';
      if (typeof data.isSubscribed !== 'undefined') {
        if (data.isSubscribed === 1) {
          toastMsg = 'Filter followed successfully';
        } else if (data.isSubscribed === 0) {
          toastMsg = 'Filter un-followed successfully';
        }
      }
      yield put(showToastNotification({ type: 'SUCCESS', message: toastMsg }));
      yield put(
        updateSuccess({
          params,
          data,
        })
      );
    } else {
      yield put(filtersFailure(filters.message));
    }
  } catch (err) {
    yield put(filtersFailure(err.message));
  }
}

function* saveFilters(action) {
  let toastMsg = 'Filter saved successfully';
  try {
    const { headers, params, data } = action.payload;
    const url = `${baseURL}${endPoints.savedFilters}`;
    const filters = yield call(postRequest, url, data, headers);
    if (!filters.error) {
      toastMsg = 'Filter saved successfully';
      yield put(showToastNotification({ type: 'SUCCESS', message: toastMsg }));
      yield put(handleGetSavedFilter(headers));
      yield put(
        saveFilterSuccess({
          params,
          data,
        })
      );
    } else {
      toastMsg = 'Error in saving filter';
      if (filters.message === 'ER_DUP_ENTRY') {
        toastMsg = 'Filter name is already in use';
      }
      yield put(showToastNotification({ type: 'ERROR', message: toastMsg }));
      yield put(filtersFailure(filters.message));
    }
  } catch (err) {
    toastMsg = 'Error in saving filter';
    yield put(showToastNotification({ type: 'ERROR', message: toastMsg }));
    yield put(filtersFailure(err.message));
  }
}

function* duplicateSavedFilters(action) {
  try {
    const { headers, data } = action.payload;
    const url = `${baseURL}${endPoints.savedFilters}`;
    const filters = yield call(postRequest, url, data, headers);
    if (!filters.error) {
      yield put(
        showToastNotification({
          type: 'SUCCESS',
          message: 'Your filter has been copied successfully!',
        })
      );
      yield put(
        duplicateFilterSuccess({
          filters,
        })
      );
    } else {
      yield put(filtersFailure(filters.message));
    }
  } catch (err) {
    yield put(filtersFailure(err.message));
  }
}

function* shareSavedFilters(action) {
  try {
    const { headers, data } = action.payload;
    const url = `${baseURL}${endPoints.shareFilter}`;
    const filters = yield call(postRequest, url, data, headers);
    if (!filters.error) {
      yield put(
        shareFilterSuccess({
          filters,
        })
      );
    } else {
      yield put(shareFilterFailure(filters.message));
    }
  } catch (err) {
    yield put(shareFilterFailure(err.message));
  }
}

function* getDomainUsersList(action) {
  try {
    const { headers } = action.payload;
    const url = `${baseURL}${endPoints.emailSuggest}`;
    const data = yield call(getRequest, url, {}, headers);
    if (!data.error) {
      yield put(
        domainUsersListSuccess({
          data,
        })
      );
    } else {
      yield put(domainUsersListFailure(data.message));
    }
  } catch (err) {
    yield put(domainUsersListFailure(err.message));
  }
}

function* getHubspotConfig(action) {
  try {
    const { headers } = action.payload;
    const url = `${baseURL}${endPoints.hubspotConfig}`;
    const data = yield call(getRequest, url, {}, headers);
    if (!data.error) {
      yield put(getHubspotConfigSuccess(data));
    } else {
      yield put(getHubspotConfigFailure(data.message));
    }
  } catch (err) {
    yield put(getHubspotConfigFailure(err.message));
  }
}

function* getPipedriveConfig(action) {
  try {
    const { headers, params = {} } = action.payload;
    const url = `${baseURL}${endPoints.pipedriveSettings}`;
    const data = yield call(getRequest, url, params, headers);
    if (!data.error) {
      yield put(getPipedriveConfigSuccess(data));
    } else {
      yield put(getPipedriveConfigFailure(data.message));
    }
  } catch (err) {
    yield put(getPipedriveConfigFailure(err.message));
  }
}

function* getSalesForceConfig(action) {
  try {
    const { headers } = action.payload;
    const url = `${baseURL}${endPoints.salesforceSettingsInfo}`;
    const data = yield call(getRequest, url, {}, headers);
    if (!data.error) {
      yield put(getSalesForceConfigSuccess(data));
    } else {
      yield put(getSalesForceConfigFailure(data.message));
    }
  } catch (err) {
    yield put(getSalesForceConfigFailure(err.message));
  }
}

function* getOutreachConfig(action) {
  try {
    const { headers, params = {} } = action.payload;
    const url = `${baseURL}${endPoints.outreachSettings}`;
    const data = yield call(getRequest, url, params, headers);
    if (!data.error) {
      yield put(getOutreachConfigSuccess(data));
    } else {
      yield put(getOutreachConfigFailure(data.message));
    }
  } catch (err) {
    yield put(getOutreachConfigFailure(err.message));
  }
}
function* getPlanPermission(action) {
  const { headers } = action.payload;
  const url = `${baseURL}${endPoints.plan}`;
  const result = yield call(getRequest, url, {}, headers);
  yield put(getFetchPermissionSuccess(result.data));
}

function* getUserInfoRedis(action) {
  try {
    const { headers } = action.payload;
    const url = `${baseURL}${endPoints.userInfoRedis}`;
    const data = yield call(getRequest, url, {}, headers);
    if (!data.error) {
      yield put(getUserInfoRedisSuccess(data));
    } else {
      yield put(getUserInfoRedisFailure(data.message));
    }
  } catch (err) {
    yield put(getUserInfoRedisFailure(err.message));
  }
}

function* getFilterListData(action) {
  try {
    const { headers } = action.payload;
    const url = `${baseURL}${endPoints.FILTER_STATIC_LIST}`;
    let data = yield call(getRequest, url, {}, headers);
    data = modifyStaticFilters(data);
    yield put(filterListSuccess(data));
  } catch (error) {
    yield put(filterListError());
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* commonSaga() {
  yield takeLatest(CREDIT_INIT, getCreditinfo);
  yield takeLatest(FILTERS_INIT, getSavedFilters);
  yield takeLatest(DELETE_FILTERS_INIT, deleteSavedFilter);
  yield takeLatest(DOMAIN_USERS_INIT, getDomainUsersList);
  yield takeLatest(HUBSPOT_CONFIG, getHubspotConfig);
  yield takeLatest(SALESFORCE_CONFIG, getSalesForceConfig);
  yield takeLatest(OUTREACH_CONFIG, getOutreachConfig);
  yield takeLatest(PIPEDRIVE_CONFIG, getPipedriveConfig);
  yield takeLatest(FETCH_PERMISSION, getPlanPermission);
  yield takeLatest(USER_INFO_REDIS, getUserInfoRedis);
  yield takeLatest(FILTER_LIST_DATA, getFilterListData);
  yield takeLatest(UPDATE_FILTERS, updateSavedFilters);
  yield takeLatest(DUPLICATE_FILTER_INIT, duplicateSavedFilters);
  yield takeLatest(SHARE_SAVED_FILTER, shareSavedFilters);
  yield takeLatest(SAVE_FILTERS, saveFilters);
}

export function* triggerNotification(action) {
  yield put(showToastNotification(action.payload));
}

/**
 * Add any Notification candidates you will get notification based on Its type for ex.
 * SUCCESS : { type: SUCCESS, message: "Taks completed" }
 * ERROR : { type: ERROR, message: "Task Failed" }
 * this nofification payload passed to any action will show notification from here.
 */
export function* notificationsSaga() {
  yield takeLatest(
    [SEARCH_LEADS_ERROR, SEARCH_COMPANIES_ERROR, ADDTO_LIST_END],
    triggerNotification
  );
}

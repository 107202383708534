import { useState, useRef, useEffect } from 'react';

/* eslint-disable import/prefer-default-export */
export const useNestedState = (initialState) => {
  const [state, setState] = useState(initialState);
  const setNestedState = (field, value) => {
    setState({ ...state, [field]: value });
  };
  return [state, setNestedState];
};

export const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef(value);
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

// export const usePrevious = (value) => {
//   // The ref object is a generic container whose current property is mutable ...
//   // ... and can hold any value, similar to an instance property on a class
//   const ref = useRef(value);
//   const old = useRef(ref.current);
//   // Store current value in ref
//   useEffect(() => {
//     old.current = ref.current;
//     ref.current = value;
//   }, [value]); // Only re-run if value changes
//   // Return previous value (happens before update in useEffect above)
//   return { new: ref.current, old: old.current };
// };

export const useSPrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef(value);
  const old = useRef(ref.current);
  // Store current value in ref
  useEffect(() => {
    old.current = ref.current;
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return old.current;
};

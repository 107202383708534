import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { getPlanNamesByRole } from 'utils/common';
import './PlanIndicatiorPill.scss';

const PlanIndicatiorPill = ({ plan }) => {
  const getPlanName = () => {
    return getPlanNamesByRole(plan, true);
  };

  const getPlanStyles = () => {
    return getPlanNamesByRole(plan, false);
  };

  return (
    <div className="plan-indicatior-pill">
      <Tag size="mini" className={getPlanStyles()}>
        {getPlanName(plan)}
      </Tag>
    </div>
  );
};

PlanIndicatiorPill.propTypes = {
  plan: PropTypes.number,
};

PlanIndicatiorPill.defaultProps = {
  plan: 10,
};

export default PlanIndicatiorPill;

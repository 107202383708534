import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { showError, showSuccess } from 'components/common/errorMessage';

const ToastNotification = () => {
  const { notification } = useSelector((state) => state.common);

  useEffect(() => {
    if (notification && Object.values(notification).every((val) => val)) {
      handleToastNotifiation(notification);
    }
  }, [JSON.stringify(notification)]);

  const handleToastNotifiation = ({ type, message }) => {
    switch (type) {
      case 'SUCCESS': {
        showSuccess(message);
        break;
      }
      case 'ERROR': {
        showError(message);
        break;
      }
      default:
        break;
    }
  };

  return <div className="slintel-toast-notification" />;
};

export default ToastNotification;

import {
  CLOSE_QUICK_VIEW,
  OPEN_QUICK_VIEW,
  UPDATE_LEAD,
  SEARCH_LEADS,
  SEARCH_COMPANIES,
  SEARCH_LEADS_SUCCESS,
  SEARCH_COMPANIES_SUCCESS,
  SEARCH_COMPANIES_ERROR,
  SEARCH_LEADS_ERROR,
  SET_CHECKED_ITEMS,
  CLEAR_CHECKED_ITEMS,
  TAB_CHANGE,
  SET_TAB,
  RESET_FILTER,
  SELECT_FILTER,
  APPLY_FILTERS,
  SHOW_SHIMMERS,
  TOGGLE_FILTER_VIEW,
  CREATE_LIST,
  CLOSE_ADDTO_LIST,
  OPEN_ADDTO_LIST,
  TOGGLE_LEAD_FILTERS,
  TOGGLE_COMPANY_FILTERS,
  RESET_COMPANY_FILTERS,
  RESET_LEAD_FILTERS,
  STORE_SORT,
  NET_NEW_COMPANIES_SUCCESS,
  NET_NEW_LEADS_SUCCESS,
  SCROLL_AREA_EXTRA_SIZE,
  NET_NEW_COMPANIES_ERROR,
  NET_NEW_LEADS_ERROR,
  SEARCH_NET_NEW_LEADS,
  SEARCH_NET_NEW_COMPANIES,
  OPEN_SAVE_FILTER,
  CLOSE_SAVE_FILTER,
  DEFAULT_INNER_ACTIVE_KEY,
  SET_ACTIVE_PANEL,
  UPDATE_CUSTOM_SELECTIONS,
  SET_USER_SELECTION,
  UPDATE_USER_INFO_REDIS,
  SET_DROPDOWN_ID,
  SET_EXPORT_DROPDOWN_VISIBLE,
} from 'store/actionTypes';

export const tabChange = (payload) => {
  return {
    type: TAB_CHANGE,
    payload,
  };
};
export const setTab = (payload) => {
  return {
    type: SET_TAB,
    payload,
  };
};

export const openQuickView = (payload) => {
  return {
    type: OPEN_QUICK_VIEW,
    payload,
  };
};

export const closeQuickView = (payload) => {
  return {
    type: CLOSE_QUICK_VIEW,
    payload,
  };
};

export const showShimmers = (payload) => {
  return {
    type: SHOW_SHIMMERS,
    payload,
  };
};

export const searchLeads = (payload) => {
  return {
    type: SEARCH_LEADS,
    payload,
  };
};

export const searchNetNewLeads = (payload) => {
  return {
    type: SEARCH_NET_NEW_LEADS,
    payload,
  };
};

export const updateLead = (payload) => {
  return {
    type: UPDATE_LEAD,
    payload,
  };
};

export const searchCompanies = (payload) => {
  return {
    type: SEARCH_COMPANIES,
    payload,
  };
};

export const searchNetNewCompanies = (payload) => {
  return {
    type: SEARCH_NET_NEW_COMPANIES,
    payload,
  };
};

export const searchLeadSuccess = (payload) => {
  return {
    type: SEARCH_LEADS_SUCCESS,
    payload,
  };
};

export const searchLeadsError = (payload) => {
  return {
    type: SEARCH_LEADS_ERROR,
    payload,
  };
};

export const searchCompaniesSuccess = (payload) => {
  return {
    type: SEARCH_COMPANIES_SUCCESS,
    payload,
  };
};

export const searchCompaniesError = (payload) => {
  return {
    type: SEARCH_COMPANIES_ERROR,
    payload,
  };
};

export const netNewSearchLeadSuccess = (payload) => {
  return {
    type: NET_NEW_LEADS_SUCCESS,
    payload,
  };
};

export const netNewSearchLeadError = (payload) => {
  return {
    type: NET_NEW_LEADS_ERROR,
    payload,
  };
};

export const netNewSearchCompaniesSuccess = (payload) => {
  return {
    type: NET_NEW_COMPANIES_SUCCESS,
    payload,
  };
};

export const netNewSearchCompaniesError = (payload) => {
  return {
    type: NET_NEW_COMPANIES_ERROR,
    payload,
  };
};

export const setCheckedItems = (payload) => {
  return {
    type: SET_CHECKED_ITEMS,
    payload,
  };
};

export const clearCheckedItems = () => {
  return {
    type: CLEAR_CHECKED_ITEMS,
  };
};

export const selectFilter = (payload) => {
  return {
    type: SELECT_FILTER,
    payload,
  };
};

export const createList = (payload) => {
  return {
    type: CREATE_LIST,
    payload,
  };
};

export const openAddtoList = (payload) => {
  return {
    type: OPEN_ADDTO_LIST,
    payload,
  };
};

export const closeAddtoList = (payload) => {
  return {
    type: CLOSE_ADDTO_LIST,
    payload,
  };
};

export const resetFilter = (payload) => {
  return {
    type: RESET_FILTER,
    payload,
  };
};

export const resetLeadFilters = () => {
  return {
    type: RESET_LEAD_FILTERS,
  };
};

export const resetCompanyFilters = () => {
  return {
    type: RESET_COMPANY_FILTERS,
  };
};

export const applyFilter = (payload) => {
  return {
    type: APPLY_FILTERS,
    payload,
  };
};

export const toggleLeadFilters = (payload) => {
  return {
    type: TOGGLE_LEAD_FILTERS,
    payload,
  };
};

export const toggleFilterView = (payload) => {
  return {
    type: TOGGLE_FILTER_VIEW,
    payload,
  };
};

export const toggleCompanyFilters = (payload) => {
  return {
    type: TOGGLE_COMPANY_FILTERS,
    payload,
  };
};

export const storeSort = (payload) => {
  return {
    type: STORE_SORT,
    payload,
  };
};

export const newNewLeadsSuccess = (payload) => {
  return {
    type: NET_NEW_LEADS_SUCCESS,
    payload,
  };
};

export const newNewCompaniesSuccess = (payload) => {
  return {
    type: NET_NEW_COMPANIES_SUCCESS,
    payload,
  };
};

export const tableExtraSize = (payload) => {
  return {
    type: SCROLL_AREA_EXTRA_SIZE,
    payload,
  };
};

export const openSaveFilter = (payload) => {
  return {
    type: OPEN_SAVE_FILTER,
    payload,
  };
};

export const closeSaveFilter = (payload) => {
  return {
    type: CLOSE_SAVE_FILTER,
    payload,
  };
};

export const setDefaultInnerActiveKey = (payload) => {
  return {
    type: DEFAULT_INNER_ACTIVE_KEY,
    payload,
  };
};

export const setActivePanelKey = (payload) => {
  return {
    type: SET_ACTIVE_PANEL,
    payload,
  };
};

export const setUpdateCustomSelections = (payload) => {
  return {
    type: UPDATE_CUSTOM_SELECTIONS,
    payload,
  };
};

export const setUserSelection = (payload) => {
  return {
    type: SET_USER_SELECTION,
    payload,
  };
};
export const updateUserInfoRedis = (payload) => {
  return {
    type: UPDATE_USER_INFO_REDIS,
    payload,
  };
};

export const setExportDropdownVisible = (payload) => {
  return {
    type: SET_EXPORT_DROPDOWN_VISIBLE,
    payload,
  };
};

export const setDropDownId = (payload) => {
  return {
    type: SET_DROPDOWN_ID,
    payload,
  };
};

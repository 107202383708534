import { NEWS_INIT, NEWS_SUCCESS, NEWS_FAILURE, NEWS_FEED_RESET } from 'store/actionTypes';

export const newsSuccess = (data) => {
  return {
    type: NEWS_SUCCESS,
    data,
  };
};

export const newsFailure = (error) => {
  return {
    type: NEWS_FAILURE,
    error,
  };
};

export const getNewsList = (params, headers) => {
  const payload = {
    params,
    headers,
  };
  return {
    type: NEWS_INIT,
    payload,
  };
};

export const newsFeedReset = () => {
  return {
    type: NEWS_FEED_RESET,
  };
};

import React from 'react';
import PropTypes from 'prop-types';

import { CircleFillIcon } from 'UI/CustomIcon/CustomIcon';

const DecisionMakingPower = ({ label, length, index }) => {
  const green = Array.from({ length: length - index }, (v, i) => (
    <CircleFillIcon fill="#36D068" key={`green + ${i}`} />
  ));
  const gray = Array.from({ length: index }, (v, i) => (
    <CircleFillIcon fill="#EAF2FA" key={`gray + ${i}`} />
  ));
  return (
    <>
      <span style={{ marginRight: '10px' }}>{[...green, ...gray]}</span>
      {label}
    </>
  );
};

DecisionMakingPower.propTypes = {
  label: PropTypes.string,
  length: PropTypes.number,
  index: PropTypes.number,
};

export default DecisionMakingPower;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Space, Divider, Col, Card, Avatar, Row } from 'antd';
import { APP_ROUTE_SRC } from 'constants/multimedia';
import { TechnologyIcon, PsychographicIcon, TimeIcon, SourceIcon } from 'UI/CustomIcon/CustomIcon';
import './TechnographicsCard.scss';

const { Meta } = Card;
const { Paragraph, Text } = Typography;
const mSecs = 1000;
const getDescription = (description, confidenceScore) => {
  return (
    <div className="technographic-desc">
      <Paragraph
        ellipsis={{
          rows: 2,
          tooltip: description,
        }}
        className="technographic-p"
      >
        {description}
      </Paragraph>
      <Divider type="vertical" className="score-divider" />
      <div className="technographic-score">
        <Text className="score-title">Score</Text>
        <Text className="score-desc">{confidenceScore}%</Text>
      </div>
    </div>
  );
};

const getUrl = (type, id) => {
  return type === 'TECHNOLOGY STACK'
    ? `${APP_ROUTE_SRC.TECHNOLOGY_DETAILS}/${id}`
    : `${APP_ROUTE_SRC.PSYCHOGRAPHICS_DETAILS}/${id}`;
};

const getImage = (type) => {
  return type === 'TECHNOLOGY STACK' ? (
    <TechnologyIcon fill="#697390" width="32px" height="48px" />
  ) : (
    <PsychographicIcon fill="#697390" width="32px" height="48px" />
  );
};

const getLastDetectedMonth = (startDate, runDate) => {
  startDate = new Date(startDate * mSecs);
  runDate = runDate ? new Date(runDate * mSecs) : new Date();
  const months =
    (runDate.getFullYear() - startDate.getFullYear()) * 12 +
    (runDate.getMonth() - startDate.getMonth());
  return months > 0 ? `${months} months ago` : 'This Month';
};
const TechnographicsCard = ({ title, data, type }) => {
  const {
    desc: description,
    img: image,
    confidence_score: confidenceScore,
    sources = [],
    id,
    last_detected: lastDetected,
  } = data;
  return (
    <div>
      <Card className="technographic-card">
        <Meta
          avatar={<Avatar size={48} src={image || getImage(type)} />}
          title={
            <a className="title" target="_blank" href={getUrl(type, id)} rel="noreferrer">
              {title}
            </a>
          }
          description={getDescription(description, confidenceScore)}
          className="meta-class"
        />
        <div className="technographic-content">
          <Row className="technographic-row">
            <Col span={8} className="technographic-item">
              <Space>
                <TimeIcon height="16px" width="16px" fill="#697390" />
                Last detected
              </Space>
            </Col>
            <Col span={16}> {getLastDetectedMonth(lastDetected)}</Col>
          </Row>
          <Row className="technographic-row">
            <Col span={8} className="technographic-item">
              <Space>
                <SourceIcon height="16px" width="16px" fill="#697390" />
                Sources
              </Space>
            </Col>
            <Col className="technographic-source" span={16}>
              {sources.join(', ')}
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

TechnographicsCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  type: PropTypes.string,
};

TechnographicsCard.defaultProps = {
  title: '',
  data: {},
  type: '',
};

export default React.memo(TechnographicsCard);

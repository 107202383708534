import React from 'react';
import { Button, Progress, Typography, Col, Row, notification } from 'antd';
import { StopOutlined } from '@ant-design/icons';
import { SmallCloseIcon, ExportIcon } from 'UI/CustomIcon/CustomIcon';
import './CustomMessages.scss';
import PresignedButton from 'components/common/presignedButton';

export const ExportProgress = ({ msg, progress = 0, duration = 100, key }) => {
  const content = (
    <Row style={{ width: '100%' }}>
      <Col span={22} className="export-col">
        <Progress size="small" percent={progress} className="export-progress-bar" />
      </Col>
    </Row>
  );
  notification.success({
    description: content,
    icon: <ExportIcon fill="#4A6EE5" className="action-icon" width={30} height={30} />,
    duration,
    key,
    message: <Typography.Text>{msg || 'Export in progress'}</Typography.Text>,
    className: 'custom-export-progress',
  });
};

export const ExportSuccess = ({
  msg,
  duration = 0,
  url,
  key,
  successRoute,
  showDownload = false,
}) => {
  const description = (
    <Row style={{ width: '100%', marginTop: '12px' }}>
      <Col span={22} className="export-success-col">
        {showDownload ? (
          <PresignedButton
            element={
              <Button
                type="primary"
                style={{ borderRadius: '5px', height: '30px', fontSize: '12px' }}
              >
                Download CSV
              </Button>
            }
            url={url}
          />
        ) : // <Button
        //   type="primary"
        //   style={{ borderRadius: '5px', height: '30px', fontSize: '12px' }}
        //   onClick={() => window.open(url)}
        // >
        //   Download CSV
        // </Button>
        null}
        <Button
          style={{
            border: '1px solid #5e81f4',
            borderRadius: '5px',
            color: '#5e81f4',
            height: '30px',
            marginLeft: '8px',
            fontSize: '12px',
          }}
          onClick={() => window.open(successRoute)}
          target="_blank"
        >
          View History
        </Button>
      </Col>
    </Row>
  );
  notification.success({
    description,
    duration,
    key,
    message: (
      <Typography.Text style={{ fontSize: '14px', color: '#29275F' }}>
        {msg || 'Export success'}
      </Typography.Text>
    ),
    className: 'custom-export-success',
    closeIcon: <SmallCloseIcon width="10px" fill="#697390" />,
  });
};

export const FailedNotification = ({ msg, duration = 0, key, description, path }) => {
  const desc = (
    <Row style={{ width: '100%' }}>
      <Col span={22} className="export-success-col">
        {description}
        <Button className="export-failed-btn" onClick={() => window.open(path)} target="_blank">
          View History
        </Button>
      </Col>
    </Row>
  );
  notification.error({
    description: desc,
    duration,
    icon: (
      <StopOutlined style={{ color: '#F64B53', width: '26px', height: '16px', fontSize: '26px' }} />
    ),
    key,
    message: (
      <Typography.Text style={{ fontSize: '14px', color: '#29275F' }}>
        {msg || 'Export failed'}
      </Typography.Text>
    ),
    className: 'custom-export-failed',
    closeIcon: <SmallCloseIcon width="10px" fill="#697390" />,
  });
};

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Card } from 'antd';
import './EmptyView.scss';
import { SearchNotFoundIcon } from 'UI/CustomIcon/CustomIcon';

const { Title } = Typography;
const { Meta } = Card;

const EmptyView = ({ description }) => {
  return (
    <div className="empty-view">
      <Card
        bordered={false}
        cover={<SearchNotFoundIcon className="empty-icon" width="145" height="145" />}
      >
        <Meta
          title={
            <Title level={4} className="title-text">
              Sorry
            </Title>
          }
          description={description}
        />
      </Card>
    </div>
  );
};

EmptyView.propTypes = {
  description: PropTypes.string,
};

EmptyView.defaultProps = {
  description: '',
};

export default EmptyView;

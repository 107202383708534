import React, { useEffect, useState } from 'react';
import { Form, Input, Typography, Select } from 'antd';
import Drawer from 'UI/CustomDrawer/CustomDrawer';
import { REPORT_FLAG_COMPANIES, REPORT_FLAG_LEADS } from 'constants/constants';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { closeReportDrawer } from 'containers/common/commonActions';
import { CommonService } from 'api/services';
import { headerGenerator } from 'utils/common';
import { showError, showSuccess } from 'components/common/errorMessage';
import DrawerTitle from 'UI/CustomDrawer/DrawerTitle';

const { Option } = Select;
const Title = Typography;
const { TextArea } = Input;
const { useForm } = Form;

const ReportDrawer = () => {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const headers = headerGenerator(user.token, user.session_id);
  const dispatch = useDispatch();
  const { reportDrawerInfo } = useSelector((state) => state.common);
  const [form] = useForm();
  const [optionsList, setOptionsList] = useState([]);
  const [bugField, setBugField] = useState('');
  const [feedback, setFeedback] = useState('');

  useEffect(() => {
    const listing =
      reportDrawerInfo.payload.objectType === 'lead' ? REPORT_FLAG_LEADS : REPORT_FLAG_COMPANIES;
    const opt = Object.keys(listing).map((item) => {
      return { label: listing[item], value: listing[item] };
    });
    setOptionsList(opt);
  }, [reportDrawerInfo.payload && reportDrawerInfo.payload.objectType]);

  const reportBug = async () => {
    const {
      flaggedFieldOriginalValue,
      slintelObjectIdentifier,
      objectLinkedinIdentifier,
      objectType,
    } = reportDrawerInfo.payload;
    if (!(feedback && bugField)) {
      showError('Please select something');
      return;
    }
    const reqPayload = {
      user_id: user.userId,
      user_email: user.userEmail,
      object_type: objectType,
      flagged_issue_field: bugField,
      flagged_field_original_value: flaggedFieldOriginalValue,
      flagged_field_feedback: feedback,
      slintel_object_identifier: slintelObjectIdentifier,
      object_linkedin_identifier: objectLinkedinIdentifier,
      flagged_source: 'Application',
    };
    const reportResponse = await CommonService.reportBug(reqPayload, headers);
    form.resetFields();
    if (reportResponse.error) {
      showError(reportResponse.message);
    } else {
      showSuccess('Bug reported successfully!');
    }
    dispatch(closeReportDrawer());
  };

  const handleFormSubmit = async () => {
    form
      .validateFields()
      .then(() => {
        reportBug();
      })
      .catch((errorInfo) => {});
  };

  const onChange = (value) => {
    setBugField(value);
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleClose = () => {
    dispatch(closeReportDrawer());
  };

  const getTitle = () => {
    const actions = [
      {
        title: 'Cancel',
        theme: 'secondary',
        onClick: handleClose,
        type: 'default',
      },
      {
        title: 'Report',
        theme: 'primary',
        onClick: handleFormSubmit,
        type: 'primary',
      },
    ];
    return <DrawerTitle title="REPORT BUG!" actions={actions} />;
  };

  const getContent = () => {
    return (
      <Form form={form} layout="vertical">
        <Form.Item
          label={<Title level={5}>Type</Title>}
          name="field"
          rules={[{ required: true, message: 'Field is required!' }]}
        >
          <Select
            showSearch
            placeholder="Select"
            optionFilterProp="children"
            onChange={onChange}
            options={optionsList}
            filterOption={(input, option) =>
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </Form.Item>
        <Form.Item
          label="Tell us more?"
          name="info"
          rules={[{ required: true, message: 'Info is required!' }]}
        >
          <TextArea rows={4} placeholder="Type" onChange={handleFeedbackChange} />
        </Form.Item>
      </Form>
    );
  };

  return (
    <Drawer
      title={getTitle()}
      content={getContent()}
      visible={reportDrawerInfo.isOpen}
      width={600}
      mask
      closable={false}
      headerStyle={{ background: '#F8FAFF' }}
      style={{ position: 'absolute' }}
    />
  );
};

export default ReportDrawer;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Table } from 'antd';
import ShowTotal from 'UI/ShowTotal';
import { getPlanConfig } from 'utils/common';
import { Spinner } from 'components/common/loader';

// TODO: add loader and no data
const ListView = ({ data = [], isLoading = false, followList, columns, type }) => {
  // TODO: check this part for responsiveness
  const getHeight = () => {
    const containerBottomMargin = 16;
    const otherContentArea = 200;
    const changedHeight = otherContentArea + 98 + 100 + containerBottomMargin;

    const height = `calc(100vh - ${changedHeight}px)`;
    return height;
  };

  const planConfig = getPlanConfig();
  const pagination = {
    position: ['bottomLeft'],
    total: data.length >= planConfig.total ? planConfig.total : data.length,
    showSizeChanger: false,
    responsive: true,
    pageSize: planConfig.pageSize,
    showTotal: (tableTotal, range) => (
      <ShowTotal
        total={tableTotal}
        type={type === 'technology' ? 'Technologies' : 'Psychographics'}
        range={range}
      />
    ),
    defaultCurrent: 1,
  };

  return (
    <div className="table-view-container">
      {isLoading ? (
        <Spinner />
      ) : (
        <Table
          loading={isLoading}
          size="small"
          pagination={followList ? false : pagination}
          scroll={{
            y: getHeight(),
          }}
          columns={columns}
          dataSource={data}
          rowKey={(record) => record._id}
        />
      )}
    </div>
  );
};

export default ListView;

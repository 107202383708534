import { put, takeLatest, call } from 'redux-saga/effects';
import { getRequest } from 'api/apiRequests';
import { baseURL, endPoints } from 'api/apiEndpoints';
import { PROFILE_REMOVED_LISTS_INIT } from 'store/actionTypes';
import { profileListFailure, profileListSuccess } from './profileRemovedListActions';

function* fetchProfileLists(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.profileRemovedList}`;
    const lists = yield call(getRequest, url, params, headers);
    if (lists.error) {
      yield put(profileListFailure(lists.message));
    } else {
      yield put(profileListSuccess(lists));
    }
  } catch (err) {
    yield put(profileListFailure(err.message));
  }
}

export default function* getListsProfileRemoved() {
  yield takeLatest(PROFILE_REMOVED_LISTS_INIT, fetchProfileLists);
}

import {
  CREDIT_INIT,
  CREDIT_SUCCESS,
  CREDIT_INFO_FAILURE,
  CREDIT_LIMIT_MODAL,
  FILTERS_INIT,
  FILTERS_SUCCESS,
  FILTERS_FAILURE,
  DOMAIN_USERS_INIT,
  DOMAIN_USERS_SUCCESS,
  DOMAIN_USERS_FAILURE,
  DELETE_FILTERS_INIT,
  DELETE_FILTERS_SUCCESS,
  DELETE_FILTERS_FAILURE,
  HUBSPOT_CONFIG_SUCCESS,
  HUBSPOT_CONFIG_FAILURE,
  HUBSPOT_CONFIG,
  SET_LOADING,
  SET_NOTIFICATION_BELL_STATUS,
  SALESFORCE_CONFIG_SUCCESS,
  SALESFORCE_CONFIG_FAILURE,
  SALESFORCE_CONFIG,
  OUTREACH_CONFIG,
  OUTREACH_CONFIG_FAILURE,
  OUTREACH_CONFIG_SUCCESS,
  PIPEDRIVE_CONFIG,
  PIPEDRIVE_CONFIG_SUCCESS,
  PIPEDRIVE_CONFIG_FAILURE,
  FETCH_PERMISSION,
  FETCH_PERMISSION_SUCCESS,
  TOGGLE_UPGRADE_MODAL,
  TOGGLE_SESSION_TIMEOUT_MODAL,
  USER_INFO_REDIS,
  USER_INFO_REDIS_SUCCESS,
  USER_INFO_REDIS_FAILURE,
  FILTER_LIST_SUCCESS,
  FILTER_LIST_ERROR,
  FILTER_LIST_DATA,
  SHOW_TOAST_NOTIFICATION,
  UPDATE_FILTERS,
  UPDATEFILTER_SUCCESS,
  DUPLICATE_FILTER_INIT,
  FILTER_DUPLICATE_SUCCESS,
  SHARE_SAVED_FILTER,
  SHARE_FILTER_SUCCESS,
  SHARE_FILTER_FAILURE,
  SAVE_FILTER_SUCCESS,
  SAVE_FILTERS,
  OPEN_REPORT_DRAWER,
  CLOSE_REPORT_DRAWER,
  SET_SAVED_FILTER_LOADING,
} from 'store/actionTypes';

export const setLoading = (visible) => {
  return {
    type: SET_LOADING,
    data: visible,
  };
};

export const setSavedFilterLoading = (visible) => {
  return {
    type: SET_SAVED_FILTER_LOADING,
    data: visible,
  };
};

export const setNotificationBellStatus = (anyUnread) => {
  return {
    type: SET_NOTIFICATION_BELL_STATUS,
    data: anyUnread,
  };
};

export const creditSuccess = (data) => {
  return {
    type: CREDIT_SUCCESS,
    data,
  };
};

export const creditInfoFailure = (error) => {
  return {
    type: CREDIT_INFO_FAILURE,
    error,
  };
};

export const getCreditInfo = (headers) => {
  const payload = {
    headers,
  };
  return {
    type: CREDIT_INIT,
    payload,
  };
};

export const toggleCreditLimitModalVisibility = (visibility) => {
  return {
    type: CREDIT_LIMIT_MODAL,
    payload: visibility,
  };
};

export const updateSavedFilters = (headers, params, data) => {
  const payload = {
    headers,
    params,
    data,
  };
  return {
    type: UPDATE_FILTERS,
    payload,
  };
};

export const saveFilters = (headers, params, data) => {
  const payload = {
    headers,
    params,
    data,
  };
  return {
    type: SAVE_FILTERS,
    payload,
  };
};

export const duplicateSavedFilters = (headers, data) => {
  const payload = {
    headers,
    data,
  };
  return {
    type: DUPLICATE_FILTER_INIT,
    payload,
  };
};

export const duplicateFilterSuccess = (data) => {
  return {
    type: FILTER_DUPLICATE_SUCCESS,
    data,
  };
};

export const updateSuccess = (data) => {
  return {
    type: UPDATEFILTER_SUCCESS,
    data,
  };
};

export const saveFilterSuccess = (data) => {
  return {
    type: SAVE_FILTER_SUCCESS,
    data,
  };
};

export const getSavedFilters = (headers) => {
  const payload = {
    headers,
  };
  return {
    type: FILTERS_INIT,
    payload,
  };
};

export const filtersSuccess = (data) => {
  return {
    type: FILTERS_SUCCESS,
    data,
  };
};

export const filtersFailure = (error) => {
  return {
    type: FILTERS_FAILURE,
    error,
  };
};

export const deleteSavedFilters = (headers, params) => {
  const payload = {
    headers,
    params,
  };
  return {
    type: DELETE_FILTERS_INIT,
    payload,
  };
};

export const deleteFiltersSuccess = (data) => {
  return {
    type: DELETE_FILTERS_SUCCESS,
    data,
  };
};

export const deleteFiltersFailure = (error) => {
  return {
    type: DELETE_FILTERS_FAILURE,
    error,
  };
};

export const shareSavedFilter = (headers, data) => {
  const payload = {
    headers,
    data,
  };
  return {
    type: SHARE_SAVED_FILTER,
    payload,
  };
};

export const shareFilterSuccess = (data) => {
  return {
    type: SHARE_FILTER_SUCCESS,
    data,
  };
};

export const shareFilterFailure = (error) => {
  return {
    type: SHARE_FILTER_FAILURE,
    error,
  };
};

export const getDominUsersList = (headers) => {
  const payload = {
    headers,
  };
  return {
    type: DOMAIN_USERS_INIT,
    payload,
  };
};

export const domainUsersListSuccess = (data) => {
  return {
    type: DOMAIN_USERS_SUCCESS,
    data,
  };
};

export const domainUsersListFailure = (error) => {
  return {
    type: DOMAIN_USERS_FAILURE,
    error,
  };
};

export const getHubspotConfig = (headers) => {
  const payload = {
    headers,
  };
  return {
    type: HUBSPOT_CONFIG,
    payload,
  };
};

export const getHubspotConfigSuccess = (data) => {
  return {
    type: HUBSPOT_CONFIG_SUCCESS,
    data,
  };
};

export const getHubspotConfigFailure = (error) => {
  return {
    type: HUBSPOT_CONFIG_FAILURE,
    error,
  };
};
export const getPipedriveConfig = (headers) => {
  const payload = {
    headers,
  };
  return {
    type: PIPEDRIVE_CONFIG,
    payload,
  };
};

export const getPipedriveConfigSuccess = (data) => {
  return {
    type: PIPEDRIVE_CONFIG_SUCCESS,
    data,
  };
};

export const getPipedriveConfigFailure = (error) => {
  return {
    type: PIPEDRIVE_CONFIG_FAILURE,
    error,
  };
};

export const getSalesForceConfig = (headers) => {
  const payload = {
    headers,
  };
  return {
    type: SALESFORCE_CONFIG,
    payload,
  };
};

export const getSalesForceConfigSuccess = (data) => {
  return {
    type: SALESFORCE_CONFIG_SUCCESS,
    data,
  };
};

export const getSalesForceConfigFailure = (error) => {
  return {
    type: SALESFORCE_CONFIG_FAILURE,
    error,
  };
};

export const getOutreachConfig = (headers, params) => {
  const payload = {
    headers,
    params,
  };
  return {
    type: OUTREACH_CONFIG,
    payload,
  };
};

export const getOutreachConfigSuccess = (data) => {
  return {
    type: OUTREACH_CONFIG_SUCCESS,
    data,
  };
};

export const getOutreachConfigFailure = (error) => {
  return {
    type: OUTREACH_CONFIG_FAILURE,
    error,
  };
};

export const getUserInfoRedis = (headers) => {
  const payload = {
    headers,
  };
  return {
    type: USER_INFO_REDIS,
    payload,
  };
};

export const getPlanPermission = (headers) => {
  const payload = {
    headers,
  };
  return {
    type: FETCH_PERMISSION,
    payload,
  };
};

export const getFetchPermissionSuccess = (data) => {
  return {
    type: FETCH_PERMISSION_SUCCESS,
    data,
  };
};

export const getUserInfoRedisSuccess = (data) => {
  return {
    type: USER_INFO_REDIS_SUCCESS,
    data,
  };
};

export const toggleUpgradeModal = (data) => {
  return {
    type: TOGGLE_UPGRADE_MODAL,
    data,
  };
};

export const toggleSessionTimeoutModal = (data) => {
  return {
    type: TOGGLE_SESSION_TIMEOUT_MODAL,
    data,
  };
};

export const getUserInfoRedisFailure = (error) => {
  return {
    type: USER_INFO_REDIS_FAILURE,
    error,
  };
};

export const fetchFilterList = (payload) => {
  return {
    type: FILTER_LIST_DATA,
    payload,
  };
};

export const filterListSuccess = (data) => {
  return {
    type: FILTER_LIST_SUCCESS,
    data,
  };
};

export const filterListError = (data) => {
  return {
    type: FILTER_LIST_ERROR,
    data,
  };
};
export const showToastNotification = (data) => {
  return {
    type: SHOW_TOAST_NOTIFICATION,
    data,
  };
};

export const openReportDrawer = (data) => {
  return {
    type: OPEN_REPORT_DRAWER,
    data,
  };
};

export const closeReportDrawer = (data) => {
  return {
    type: CLOSE_REPORT_DRAWER,
    data,
  };
};

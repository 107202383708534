import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getCookie = (name) => {
  return cookies.get(name);
};

export const setCookie = (key, val, expires) => {
  const options = { domain: `.${process.env.DOMAIN_NAME}`, path: '/', expires };
  cookies.set(key, val, options);
};

export const removeCookie = (
  key,
  options = { domain: `.${process.env.DOMAIN_NAME}`, path: '/' }
) => {
  cookies.remove(key, options);
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import './loader.scss';

// global loader
// accessible via redux
const Loader = () => {
  const loaderVisible = useSelector((state) => state.common.loading);
  return loaderVisible && <Spinner />;
};

export const Spinner = () => {
  return (
    <div className="loader-container">
      <Spin size="large" />
    </div>
  );
};

export default React.memo(Loader);

/* eslint-disable import/prefer-default-export */

import { COMPANY_SIZE_DATA } from 'src/constants/constants';

const COMPANY_SECTORS = [
  'Energy and Utilities',
  'Financial Services',
  'Healthcare and Lifesciences',
  'Industrials & Chemicals',
  'Media and Telecom',
  'Professional Services',
  'Retail and CPG',
  'Technology',
];

const COMPANY_TARGET_PERSONA = [
  'Hr',
  'Sales',
  'Marketing',
  'Technology',
  'Finance Risk And Compliance',
  'Customer Success And Support',
  'Operations',
  'Research And Strategy',
  'Administrative',
  'Ceo/founder/co-founder',
  'General Management',
  'Other',
];

const PROPENSITY_SCORE_COMPANIES_TO_TARGET = {
  DEFAULT_DAYS_FROM: 90,
  DAYS_FROM_OPTIONS: [7, 14, 30, 90],
};

const COMPANY_LIST_API_REQUEST_LIMIT = 50;

const MAX_BUYING_SIGNAL = {
  renewal: 200,
  technologyCategory: 200,
  technology: 200,
  funding: 100,
  size: 100,
  jobs: 100,
};

export {
  COMPANY_SIZE_DATA,
  PROPENSITY_SCORE_COMPANIES_TO_TARGET,
  COMPANY_SECTORS,
  COMPANY_TARGET_PERSONA,
  COMPANY_LIST_API_REQUEST_LIMIT,
  MAX_BUYING_SIGNAL,
};

/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable import/no-unresolved */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RightHandSideDrawer from 'UI/CustomDrawer/CustomDrawer';
import DrawerTitle from 'UI/CustomDrawer/DrawerTitle';
// import history from '@slintel/s-history';
import { MAX_ITEM_PER_LIST } from 'constants/constants';
import { showError } from 'components/common/errorMessage';
import AddToListForm from './addToListForm';

const AddToListDrawer = ({
  visible,
  onClose,
  getLists,
  addItemsToList,
  toggleCreateListDrawerVisibility,
  isFetchListItems,
  setReloadListItems,
}) => {
  const [listObj, setListObj] = useState({});
  const [selectedList, setSelectedList] = useState(null);
  const [pageStart, setPageStart] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

  const getTheList = async (
    searchValue,
    page = 1,
    sorting = { key: 'updatedAt', order: 'descend' }
  ) => {
    const params = {
      type: 'lead',
      page,
    };
    if (sorting && sorting.order) {
      params.sortKey = sorting.key;
      params.sortOrder = sorting.order === 'descend' ? 'DESC' : 'ASC';
    }
    if (searchValue) {
      params.searchTerm = searchValue;
    }
    setLoading(true);
    const response = await getLists(params);
    setLoading(false);
    if (isFetchListItems) {
      setReloadListItems(false);
    }
    setListObj(response);
  };

  useEffect(() => {
    if (visible || isFetchListItems) {
      setSearchTerm('');
      setPageStart(1);
      getTheList();
    }
  }, [visible, isFetchListItems]);

  const onCancel = () => {
    setSelectedList(null);
    onClose(false);
  };

  const onAdd = async () => {
    if (!(selectedList && selectedList.id)) {
      showError('List not found');
      return;
    }
    if (selectedList.itemCount >= MAX_ITEM_PER_LIST) {
      showError('List limit is exhausted. Please select another list');
      return;
    }
    onCancel();
    await addItemsToList(selectedList);
    // history.push(`/list/${response.listId}`);
    // window.location.reload();
  };

  const openCreateList = () => {
    toggleCreateListDrawerVisibility(true);
  };

  return (
    <RightHandSideDrawer
      visible={visible}
      onClose={onCancel}
      content={
        <AddToListForm
          listObj={listObj}
          setSelectedList={setSelectedList}
          openCreateList={openCreateList}
          pageStart={pageStart}
          setPageStart={setPageStart}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          getTheList={getTheList}
          loading={loading}
          selectedList={selectedList}
        />
      }
      title={
        <DrawerTitle
          title="SELECT LIST"
          actions={[
            {
              title: 'Cancel',
              type: 'default',
              size: 'large',
              onClick: onCancel,
            },
            {
              title: 'Select',
              type: 'primary',
              size: 'large',
              onClick: onAdd,
              disabled: !(selectedList && Object.keys(selectedList).length),
            },
          ]}
        />
      }
    />
  );
};

AddToListDrawer.propTypes = {
  getLists: PropTypes.func.isRequired,
  addItemsToList: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  toggleCreateListDrawerVisibility: PropTypes.func.isRequired,
  isFetchListItems: PropTypes.bool,
  setReloadListItems: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
};

export default AddToListDrawer;

export const reduceToBar = (previous, item) => {
  previous[item.key] = item.doc_count;
  return previous;
};
export const refactorData = (
  heatMapData,
  competitors,
  adds,
  drops,
  companySize,
  customerCategory
) => {
  const mapData = heatMapData.map((item) => {
    return {
      name: item.key,
      code: item.code,
      value: item.doc_count < 1 ? 1 : item.doc_count,
    };
  });
  const competitorsBarData = (competitors?.data?.agg || []).reduce(reduceToBar, {}) || {};
  const addsData = (adds?.data?.agg || []).reduce(reduceToBar, {}) || {};
  const dropsData = (drops?.data?.agg || []).reduce(reduceToBar, {}) || {};
  const companySizeData = (companySize?.data?.agg || []).map((key) => {
    return {
      name: key.key,
      y: key.doc_count,
    };
  });
  const competitorsPieData = (competitors?.data?.agg || []).map((key) => {
    return {
      name: key.key,
      y: key.doc_count,
    };
  });
  let count = 1;
  const customerCategoryData = (customerCategory?.data || []).reduce((total, value) => {
    if (value.key !== '' && value.key !== 'unknown' && count <= 10) {
      count++;
      total.push({ name: value.key, value: value.doc_count, colorValue: count });
    }
    return total;
  }, []);
  return {
    mapData,
    competitorsBarData,
    addsData,
    dropsData,
    companySizeData,
    competitorsPieData,
    customerCategoryData,
  };
};
export const timeConverter = (timestamp) => {
  const a = new Date(timestamp * 1000);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  return `${month} ${year}`;
};

import React from 'react';
import PropTypes from 'prop-types';
import Button from 'UI/Button';
import { AddToListIcon } from 'UI/CustomIcon/CustomIcon';
import './AddToListButton.scss';
import { Tooltip } from 'antd';

const AddToListButton = ({ onAddToList, title, tooltip, ...props }) => {
  let toolTipTitle = props.disabled ? tooltip || 'Select records from below to take an action' : '';
  if (props.disabled && tooltip) {
    toolTipTitle = tooltip;
  }
  return (
    <div className="addtolist-button-container">
      <Tooltip placement="bottom" title={toolTipTitle}>
        <span>
          <Button
            {...props}
            theme="secondary"
            style={props.disabled ? { pointerEvents: 'none' } : {}}
            onClick={onAddToList}
            icon={<AddToListIcon fill="#4a6ee5" width={15} height={15} />}
          >
            {title || 'Add to Smart List'}
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};

AddToListButton.propTypes = {
  onAddToList: PropTypes.func,
};

AddToListButton.defaultProps = {
  onAddToList: () => {},
};

export default AddToListButton;

import React from 'react';
import Thumbnail from 'UI/Thumbnail';
import { CompanyIcon } from 'UI/CustomIcon/CustomIcon';
import scssVariables from 'src/_vars.scss';
import { Typography, Popover, Divider, Tooltip } from 'antd';
import { getClickableLink } from 'utils/common';

const { Text, Title } = Typography;

export const content = (labels) => {
  return (
    <div>
      <Title level={5}>Categories</Title>
      {labels.map((item) => {
        return (
          <>
            <Divider className="divider" />
            {item}
          </>
        );
      })}
    </div>
  );
};
export const showExtraLabels = (labels) => {
  if (!labels) {
    return '';
  }
  if (labels?.length === 1) {
    return (
      <Tooltip mouseEnterDelay={0.3} placement="topLeft" title={labels.toString()}>
        <Text
          style={{
            maxWidth: '150px',
          }}
          ellipsis
        >
          {labels.toString()}
        </Text>
      </Tooltip>
    );
  }
  return (
    <div className="tech-status-popover">
      <Popover
        getPopupContainer={(trigger) => (trigger ? trigger.parentElement : trigger)}
        style={{ padding: '0' }}
        content={() => content(labels)}
        trigger="click"
      >
        <Text
          style={{
            maxWidth: '150px',
          }}
          ellipsis
        >
          {labels[0]}
        </Text>

        <div style={{ color: scssVariables.slintelBlue1, cursor: 'pointer' }}>
          +{labels.length - 1}
        </div>
      </Popover>
    </div>
  );
};

export const getThumbnail = (record) => {
  return record?.company_profile_image_url ? (
    <Thumbnail.Image size="small" imageUrl={getClickableLink(record?.company_profile_image_url)} />
  ) : (
    <Thumbnail.Icon size="small" Icon={CompanyIcon} />
  );
};

import {
  LISTS_INIT,
  LISTS_SUCCESS,
  LISTS_FAILURE,
  LISTS_RESET,
  LISTS_DEBOUNCE_INIT,
  LISTS_CREATE_SUCCESS,
  LISTS_CREATE,
  LIST_DELETE,
  LIST_DELETE_SUCCESS,
  LISTS_SEARCH_SUCCESS,
  LIST_ACTIVE_FILTER,
  SET_LIST_ITEMS_FILTER,
  RESET_LIST_ITEMS_FILTER,
  LIST_ITEMS_SUCCESS,
  LIST_ITEMS_FAILURE,
  FETCH_LIST_ITEMS,
  LIST_ITEMS_INIT,
  RESET_CURRENT_FILTER,
  ADDTO_LIST,
  ADD_TO_LIST_JOBSPAGE,
  ADDTO_LIST_START,
  ADDTO_LIST_END,
  OPEN_QUICK_VIEW_SMART_LIST,
  CLOSE_QUICK_VIEW_SMART_LIST,
  UPDATE_LEAD_SMART_LIST,
  OPEN_ADDTO_LIST_SMART_LIST,
  CLOSE_ADDTO_LIST_SMART_LIST,
  SET_ACTION,
  CLEAR_ADD_TO_LIST_SOURCE,
} from 'store/actionTypes';

export const smartListSuccess = (data) => {
  return {
    type: LISTS_SUCCESS,
    data,
  };
};

export const smartListSearchSuccess = (data) => {
  return {
    type: LISTS_SEARCH_SUCCESS,
    data,
  };
};

export const smartListCreateSuccess = (data) => {
  return {
    type: LISTS_CREATE_SUCCESS,
    data,
  };
};

export const smartListFailure = (error) => {
  return {
    type: LISTS_FAILURE,
    error,
  };
};

export const getLists = (params, headers) => {
  const payload = {
    params,
    headers,
  };
  return {
    type: LISTS_INIT,
    payload,
  };
};

export const createList = (params, headers) => {
  const payload = {
    params,
    headers,
  };
  return {
    type: LISTS_CREATE,
    payload,
  };
};

export const searchLists = (params, headers) => {
  const payload = {
    params,
    headers,
  };
  return {
    type: LISTS_DEBOUNCE_INIT,
    payload,
  };
};

export const smartlistReset = () => {
  return {
    type: LISTS_RESET,
  };
};

export const deleteListById = (params, headers) => {
  const payload = {
    params,
    headers,
  };
  return {
    type: LIST_DELETE,
    payload,
  };
};

export const deleteListByIdSuccess = (data) => {
  return {
    type: LIST_DELETE_SUCCESS,
    data,
  };
};

export const setActiveFilter = (data) => {
  return {
    type: LIST_ACTIVE_FILTER,
    data,
  };
};

export const getListItems = (listId, params, headers) => {
  const payload = {
    listId,
    params,
    headers,
  };

  return {
    type: FETCH_LIST_ITEMS,
    payload,
  };
};

export const listItemsSuccess = (data) => {
  return {
    type: LIST_ITEMS_SUCCESS,
    data,
  };
};

export const listItemsFailure = (error) => {
  return {
    type: LIST_ITEMS_FAILURE,
    error,
  };
};

export const setListItemFilters = (type, name, values) => {
  return {
    type: SET_LIST_ITEMS_FILTER,
    payload: {
      type,
      name,
      values,
    },
  };
};

export const resetListItemFilters = () => {
  return {
    type: RESET_LIST_ITEMS_FILTER,
  };
};

export const initListItemFetch = () => {
  return {
    type: LIST_ITEMS_INIT,
  };
};

export const resetCurrentFilter = (type) => {
  return {
    type: RESET_CURRENT_FILTER,
    payload: {
      type,
    },
  };
};

export const addtoList = (payload) => {
  return {
    type: ADDTO_LIST,
    payload,
  };
};

export const addtoListFromJobsPage = (payload) => {
  return {
    type: ADD_TO_LIST_JOBSPAGE,
    payload,
  };
};

export const addtoListStart = (payload) => {
  return {
    type: ADDTO_LIST_START,
    payload,
  };
};

export const addtoListComplete = (payload) => {
  return {
    type: ADDTO_LIST_END,
    payload,
  };
};

export const openQuickView = (payload) => {
  return {
    type: OPEN_QUICK_VIEW_SMART_LIST,
    payload,
  };
};

export const closeQuickView = (payload) => {
  return {
    type: CLOSE_QUICK_VIEW_SMART_LIST,
    payload,
  };
};

export const updateLead = (payload) => {
  return {
    type: UPDATE_LEAD_SMART_LIST,
    payload,
  };
};

export const openAddToList = (payload) => {
  return {
    type: OPEN_ADDTO_LIST_SMART_LIST,
    payload,
  };
};

export const closeAddToList = (payload) => {
  return {
    type: CLOSE_ADDTO_LIST_SMART_LIST,
    payload,
  };
};

export const setCompanySmartListAction = (payload) => {
  return {
    type: SET_ACTION,
    payload,
  };
};

export const clearAddToListSource = (payload) => {
  return {
    type: CLEAR_ADD_TO_LIST_SOURCE,
    payload,
  };
};

import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Button, Input, Form, Switch, Select } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { LIST_TAB_SELECTION, LIST_TABS } from 'constants/constants';
import { useTranslation } from 'react-i18next';

const { OptGroup, Option } = Select;
const useResetFormOnCloseModal = ({ form, visible }) => {
  const prevVisibleRef = useRef();
  useEffect(() => {
    prevVisibleRef.current = visible;
  }, [visible]);
  const prevVisible = prevVisibleRef.current;

  useEffect(() => {
    if (!visible && prevVisible) {
      form.resetFields();
    }
  }, [visible]);
};

const CreateList = ({ isCreateList, toggle, drawerTitle, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [isExclusionSelected, setExclusionSelected] = useState(false);
  const { t } = useTranslation(['list']);

  useResetFormOnCloseModal({
    form,
    visible: isCreateList,
  });

  const title = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ alignSelf: 'center' }}>
          <Text
            style={{
              color: '#29275F',
              fontSize: '16px',
              fontWeight: 'bold',
              textAlign: 'left',
              letterSpacing: '0.8px',
              textTransform: 'uppercase',
            }}
          >
            {drawerTitle}
          </Text>
        </div>
        <div>
          <Button type="default" onClick={toggle}>
            Cancel
          </Button>
          <Button
            type="primary"
            loading={loading && isCreateList}
            onClick={submitForm}
            style={{ marginLeft: '15px' }}
          >
            {' '}
            Create{' '}
          </Button>
        </div>
      </div>
    );
  };

  const onFinish = async (values) => {
    setLoading(true);
    const val = await onSubmit(values);
    setLoading(val);
  };

  const handelListSelection = (selectedValue) => {
    setExclusionSelected(selectedValue.endsWith('exclusion'));
  };

  const submitForm = () => {
    form.submit();
  };

  return (
    <Drawer
      onClose={toggle}
      visible={isCreateList}
      style={{
        marginLeft: '60px',
      }}
      closable={false}
      maskClosable
      width="600px"
      title={title()}
      placement="right"
    >
      <div>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={onFinish}
          initialValues={{ autoUpdate: true }}
        >
          <Form.Item
            name="title"
            label={<Title level={5}> List Name </Title>}
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: 'Please enter a valid list name',
              },
            ]}
          >
            <Input
              maxLength="200"
              style={{ width: '420px' }}
              placeholder="Enter a new name for your List"
            />
          </Form.Item>
          <Form.Item
            name="listType"
            rules={[
              {
                required: true,
                message: 'Please select a list type',
              },
            ]}
            label={
              <>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Title level={5}> List Type </Title>
                  <Text style={{ fontSize: '12px', color: '#929CB7' }}>
                    {' '}
                    {t('create-list.description.list_type')}
                  </Text>
                </div>
              </>
            }
          >
            <Select
              placeholder="Select"
              style={{
                width: '420px',
              }}
              onChange={handelListSelection}
            >
              {LIST_TABS.map((tab) => (
                <OptGroup
                  label={
                    <div className="list-label-wrapper">
                      <span className="list-type-label">{tab.label}</span>
                    </div>
                  }
                  key={tab.value}
                >
                  {LIST_TAB_SELECTION[tab.value]
                    .filter((list) => list.value !== 'all')
                    .map((list) => (
                      <Option value={list.value} key={list.value}>
                        {' '}
                        {list.label}{' '}
                      </Option>
                    ))}
                </OptGroup>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            hidden={isExclusionSelected}
            name="autoUpdate"
            valuePropName="checked"
            label={
              <>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Title level={5}> Auto Update </Title>
                  <Text style={{ fontSize: '12px', color: '#929CB7' }}>
                    {t('create-list.description.auto_update')}
                  </Text>
                </div>
              </>
            }
          >
            <Switch style={{ opacity: '0' }} checked disabled />
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
};

CreateList.propTypes = {
  isCreateList: PropTypes.bool.isRequired,
  drawerTitle: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  copyText: PropTypes.string,
};

export default CreateList;

import React from 'react';
import { Menu } from 'antd';
import Icon from '@ant-design/icons';
import {
  DashboardIcon,
  TechnologyIcon,
  JobPostingIcon,
  PsychographicIcon,
  SmartListIcon,
  DiscoverIcon,
} from '../CustomIcon/CustomIcon';

import SideNavMenuIcon from './SideNavMenuIcon';
// import Dashboard from 'containers/dashboard/dashboardIndex';
const technologyPath = '/technologies/HR/Applicant%20Tracking';
const keywordPath = '/keywords/HR/Recruitment';

const menuItems = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: DashboardIcon,
    key: 'dashboard',
    type: 'navlink',
  },
  {
    title: 'Discover',
    path: '/discover/lead',
    icon: DiscoverIcon,
    key: 'discover',
    type: 'navlink',
  },
  {
    title: 'Technologies',
    path: technologyPath,
    icon: TechnologyIcon,
    key: 'technologies',
    type: 'navlink',
  },
  {
    title: 'Psychographics',
    path: keywordPath,
    icon: PsychographicIcon,
    key: 'keywords',
    type: 'navlink',
  },
  {
    title: 'Lists',
    path: '/list',
    icon: SmartListIcon,
    key: 'list',
    type: 'navlink',
  },
  {
    title: 'Jobs',
    // path: process.env.OLD_DASHBOARD_URL_JOB_POSTING,
    path: '/job-posting',
    icon: JobPostingIcon,
    key: 'job-posting',
    type: 'navlink',
  },
];

// eslint-disable-next-line react/prop-types
const SideNavbarMenu = ({ theme, lockedPages, ...props }) => {
  return (
    <Menu {...props}>
      {menuItems.map((item) => {
        // eslint-disable-next-line react/prop-types
        const pages = lockedPages.map((page) => page.name);
        // eslint-disable-next-line react/prop-types
        const getLockTitle = (key) => lockedPages.find((page) => page.name === key);
        const isLocked = pages.includes(item.key);
        const lockedTitle = getLockTitle(item.key);
        return (
          <Menu.Item
            key={item.key}
            icon={
              <Icon
                component={() => (
                  <SideNavMenuIcon
                    component={item.icon}
                    locked={isLocked}
                    type={item.type}
                    path={item.path}
                    theme={theme}
                  />
                )}
              />
            }
          >
            {`${isLocked ? lockedTitle.tooltip : item.title}`}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default SideNavbarMenu;

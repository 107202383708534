import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LeadIcon } from 'UI/CustomIcon/CustomIcon';
import './Thumbnail.scss';

const ThumbnailIcon = ({ size, Icon, backgroundColor, iconColor }) => {
  const iconSize = {
    big: { width: '26', height: '26' },
    medium: { width: '24', height: '24' },
    small: { width: '22', height: '22' },
    mini: { width: '20', height: '20' },
  };

  const iconHeight = () => iconSize[size].height;

  const iconWidth = () => iconSize[size].width;

  const renderIcon = () => {
    return (
      <Icon
        fill={iconColor}
        className="slintel-thumbnail-icon"
        height={iconHeight()}
        width={iconWidth()}
      />
    );
  };

  return (
    <div className={`slintel-thumbnail slintel-thumbnail-${size}`} style={{ backgroundColor }}>
      {renderIcon()}
    </div>
  );
};

ThumbnailIcon.propTypes = {
  size: PropTypes.string,
  Icon: PropTypes.element,
  iconColor: PropTypes.string,
  backgroundColor: PropTypes.string,
};

ThumbnailIcon.defaultProps = {
  size: 'big',
  Icon: LeadIcon,
  iconColor: '#ffff',
  backgroundColor: '#38c5af',
};

const iconSize = {
  big: { width: '26', height: '26' },
  medium: { width: '24', height: '24' },
  small: { width: '22', height: '22' },
  mini: { width: '20', height: '20' },
};

const ThumbnailImage = ({ size, imageUrl, backgroundColor, fallbackSrc, fallbackIcon }) => {
  const [state, setState] = useState({ errored: false });

  const onError = () => {
    setState({
      errored: true,
    });
  };

  const imageHeight = () => iconSize[size].height;

  const imageWidth = () => iconSize[size].width;

  const renderIcon = () => {
    return (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        src={`${imageUrl}`}
        className={`slintel-thumbnail slintel-thumbnail-${size}`}
        height={imageHeight()}
        width={imageWidth()}
        loading="lazy"
        onError={onError}
      />
    );
  };
  if (state.errored && fallbackIcon) {
    return <ThumbnailIcon size="small" Icon={fallbackIcon} />;
  }
  return (
    <div className={`slintel-thumbnail slintel-thumbnail-${size}`} style={{ backgroundColor }}>
      {renderIcon()}
    </div>
  );
};

ThumbnailImage.propTypes = {
  size: PropTypes.string,
  imageUrl: PropTypes.string,
  backgroundColor: PropTypes.string,
};

ThumbnailImage.defaultProps = {
  size: 'big',
  imageUrl: '',
  backgroundColor: '',
};

export default {
  Icon: ThumbnailIcon,
  Image: ThumbnailImage,
};

/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/prop-types */
import { Select, Spin, Tooltip, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import FilterTagPill from 'UI/FilterTagPill/FilterTagPill';
import { isEqual, separateString } from 'utils/common';
import { useSPrevious } from 'utils/hooks/customHooks';
import { sanitizeSearchString } from 'utils/discovery/common';
// import * as PropTypes from 'prop-types';
const { Text } = Typography;
// suggestions = [], defaultOptions = [],

const { Option, OptGroup } = Select;

const MultipleSelect = ({
  onChange,
  options,
  type,
  hasNegation,
  handleSearch,
  mode = 'multiple',
  value = [],
  placeholder,
  filters,
  loading = false,
  staticOptions = false,
  style = { width: '250px' },
  customFilterProp = 'value',
  resetDropdownOptions,
  textSeparator,
  maxLength,
  filterOption,
  listHeight,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [notSelectedValues, setNotSelectedValues] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const previousFilter = useSPrevious(value);
  useEffect(() => {
    if (selectedValues.length) {
      const notValues = [];
      selectedValues.forEach((selectedValue) => {
        if (selectedValue && selectedValue.charAt(0) === '!') {
          const notSelectedValue = selectedValue.replace('!', '');
          if (!notSelectedValues.includes(notSelectedValue)) {
            notValues.push(notSelectedValue);
          }
        }
      });
      if (notValues.length) {
        setNotSelectedValues(notValues);
      }
    }
  }, []);

  useEffect(() => {
    if (value && (!isEqual(previousFilter, value) || !isEqual(value, selectedValues))) {
      setSelectedValues(value);
    }
  }, [value]);

  useEffect(() => {
    if (value && !isEqual(previousFilter, value)) {
      setSelectedValues(value);
    }
  }, [previousFilter]);
  useEffect(() => {
    if (value) {
      const notValues = [];
      selectedValues.forEach((selectedValue) => {
        if (selectedValue && selectedValue.charAt(0) === '!') {
          const notSelectedValue = selectedValue.replace('!', '');
          notValues.push(notSelectedValue);
        }
      });
      setNotSelectedValues(notValues);
    }
  }, [selectedValues]);

  const getComapnyImage = (dataType, url) => {
    const imageUrl =
      dataType === 'keyword'
        ? 'https://d3ml3b6vywsj0z.cloudfront.net/website/v2/icons/sl_keyword_icon.svg'
        : url;
    return !imageUrl ? (
      <div role="img">
        <svg width="30" height="30">
          <circle cx="15" cy="15" r="15" fill="#DFE7F0" />
        </svg>
      </div>
    ) : (
      <div role="img">
        <img
          width="30"
          height="30"
          alt=""
          style={{ borderRadius: '25%', objectFit: 'contain' }}
          src={imageUrl}
        />
      </div>
    );
  };

  const getCompanyOptions = (option) => {
    const optionValue = option.company_id
      ? `${option.company_id}|${option.company_industry}|${option.company_website}|${option.company_name}`
      : `${option.company_industry}|${option.company_website}|${option.company_name}`;

    return (
      <Option key={optionValue} value={optionValue} label={option.text || option.company_name}>
        <div
          className="option-label-item"
          data-type={option.type}
          data-industry={option.company_industry}
          data-website={option.company_website}
          data-label={option.company_name}
        >
          {getComapnyImage(option.type, option.company_profile_image_url)}
          <div className="option-sub-label">
            <span>{option.company_name}</span>
            <Text
              title={option.company_industry}
              style={{ maxWidth: 200 }}
              ellipsis={{ tooltip: true }}
            >
              {option.company_industry}
            </Text>
            <span>{option.company_website}</span>
          </div>
        </div>
      </Option>
    );
  };

  const getTechnologyAndKeywordOptions = (option) => {
    const optionValue = `${option.category}|${option.subcategory}|${option.company_name}`;
    return (
      <Option key={optionValue} value={optionValue} label={option.company_name}>
        <div
          className="option-label-item"
          data-type={option.type}
          data-category={option.category}
          data-subcategory={option.subcategory}
          data-label={option.company_name}
        >
          {getComapnyImage(option.type, option.company_profile_image_url)}
          <div className="option-sub-label">
            <span>{option.company_name}</span>
            <Text
              title={`${option.category} > ${option.subcategory}`}
              style={{ maxWidth: 200 }}
              ellipsis={{ tooltip: true }}
            >
              {`${option.category} > ${option.subcategory}`}
            </Text>
          </div>
        </div>
      </Option>
    );
  };

  const dropdownOptions = useMemo(() => {
    let dropdownRenderOptions = [];
    if (type === 'group') {
      dropdownRenderOptions = Object.keys(options).map((option) => (
        <OptGroup key={option} label={option}>
          {options[option].map((key) => (
            <Option key={key} value={key}>
              {key}
            </Option>
          ))}
        </OptGroup>
      ));
    } else if (type === 'key-value') {
      dropdownRenderOptions = options.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ));
    } else if (type === 'image-text') {
      dropdownRenderOptions = options.map((option) => {
        return option.type === 'technology' || option.type === 'keyword'
          ? getTechnologyAndKeywordOptions(option)
          : getCompanyOptions(option);
      });
    } else {
      const uniqueOptions = [...new Set(options)];
      dropdownRenderOptions = uniqueOptions.map((option) => (
        <Option key={option} value={option}>
          {option}
        </Option>
      ));
    }
    return dropdownRenderOptions;
  }, [options, filters]);

  const toggleNot = (label) => {
    const newValue = [...value];
    let itemIndex = newValue.indexOf(label.value);
    if (itemIndex === -1) {
      itemIndex = newValue.indexOf(`!${label.value}`);
    }
    if (itemIndex !== -1) {
      if (newValue[itemIndex] === `!${label.value}`) {
        newValue[itemIndex] = label.value;
        setNotSelectedValues(notSelectedValues.filter((key) => key !== label.value));
      } else {
        newValue[itemIndex] = `!${label.value}`;
        setNotSelectedValues([...notSelectedValues, label.value]);
      }
    }
    setSelectedValues(newValue);
    onChange(newValue);
  };

  const triggerChange = (key) => {
    let newValues = [];
    if (textSeparator) {
      const keys = separateString(key, textSeparator)
        .filter((subText) => !!subText)
        .map((subText) => (subText.length > 200 ? subText.slice(0, 200) : subText));
      newValues = [...value, ...keys];
    } else {
      newValues = [...value, key.length > 200 ? key.slice(0, 200) : key];
    }
    const uniqueValues = [...new Set(newValues)];
    setSelectedValues(uniqueValues);
    onChange(uniqueValues);
    setSearchValue('');
  };

  const getTagName = (label) => {
    if (typeof label === 'string') {
      let customLabel = label;
      if (label.includes('|')) {
        const valuesInLabel = label.split('|');
        customLabel = valuesInLabel.length > 3 ? valuesInLabel[3] : valuesInLabel[2];
      }
      return customLabel;
    }
    return label.props['data-label'] || label.props.children;
  };

  const tagRender = (props) => {
    const { label, closable, onClose, value: selectedValue } = props;
    const optionType = typeof label === 'object' && label?.props['data-type'];
    const tag = (
      <FilterTagPill
        type={hasNegation === undefined || hasNegation === true ? 'negation' : 'normal'}
        isTooltipRequired={
          typeof label === 'object' && !['company', 'keyword', 'technology'].includes(optionType)
        }
        longText={30} // adding this to give custom longtext range
        onDelete={onClose}
        onToggleNegate={(action) => {
          toggleNot(props);
        }}
        label={notSelectedValues.includes(selectedValue) ? `!${selectedValue}` : selectedValue}
      />
    );

    if (!optionType && label?.includes('|')) {
      const exampleLabel = label.split('|');
      return exampleLabel.length > 3 ? (
        <Tooltip
          placement="right"
          title={
            <div className="tooltip-label-text">
              <span>{exampleLabel[3] !== 'undefined' && exampleLabel[3]}</span>
              <span>{exampleLabel[2] !== 'undefined' && exampleLabel[2]}</span>
              <span>{exampleLabel[1] !== 'undefined' && exampleLabel[1]}</span>
            </div>
          }
        >
          {tag}
        </Tooltip>
      ) : (
        <Tooltip
          placement="right"
          title={
            <div className="tooltip-label-text">
              <span>{exampleLabel.pop()}</span>
            </div>
          }
        >
          {tag}
        </Tooltip>
      );
    }

    const getTitle = () => {
      return optionType === 'company' ? (
        <>
          <div className="tooltip-label-text">
            <span>{label.props['data-label']}</span>
            <span>{label.props['data-industry']}</span>
            <span>{label.props['data-website']}</span>
          </div>
        </>
      ) : (
        <>
          <div className="tooltip-label-text">
            <span>{label.props['data-label']}</span>
            <span>{`${label.props['data-category']} > ${label.props['data-subcategory']}`}</span>
          </div>
        </>
      );
    };

    return ['company', 'keyword', 'technology'].includes(optionType) ? (
      <Tooltip placement="right" title={getTitle()}>
        {tag}
      </Tooltip>
    ) : (
      tag
    );
  };

  const onRemove = (key) => {
    let itemIndex = selectedValues.indexOf(key);
    if (itemIndex === -1) {
      itemIndex = selectedValues.indexOf(`!${key}`);
    }
    if (itemIndex !== -1) {
      const currentSelectedOptions = [...selectedValues];
      currentSelectedOptions.splice(itemIndex, 1);
      setSelectedValues(currentSelectedOptions);
      onChange(currentSelectedOptions);
    }
    const notSelectedIndex = notSelectedValues.indexOf(key);
    if (notSelectedIndex !== -1) {
      const currentNotSelectedOptions = [...notSelectedValues];
      currentNotSelectedOptions.splice(notSelectedIndex, 1);
      setNotSelectedValues(currentNotSelectedOptions);
    }
  };

  const handleRequest = async (searchText) => {
    if (!searchText.trim() && resetDropdownOptions) {
      resetDropdownOptions(false);
    }
    if (searchText.trim() === searchValue.trim()) {
      setSearchValue(searchText);
      return;
    }
    if (maxLength && searchText.length >= maxLength) {
      const newValue = searchText.substring(0, maxLength);
      setSearchValue(newValue);
      handleSearch(sanitizeSearchString(newValue));
      return;
    }
    if (!staticOptions && resetDropdownOptions) {
      resetDropdownOptions(false, true);
    }
    setSearchValue(searchText);
    handleSearch(sanitizeSearchString(searchText));
  };
  const onDropdownVisibleChange = async (open) => {
    if (!open) {
      setSearchValue('');
    }
    if (!staticOptions && resetDropdownOptions) {
      resetDropdownOptions(open);
    }
  };

  const renderNotFoundContent = () => {
    if (loading) {
      return <Spin size="small" />;
    }
    if (searchValue !== '') {
      return 'No matching records were found';
    }
    return null;
  };

  return (
    <Select
      getPopupContainer={(trigger) => (trigger ? trigger.parentElement : trigger)}
      mode={mode}
      dropdownStyle={{ backgroundColor: '#ffffff' }}
      style={style}
      className="custom-select-dropdown mt-1"
      placeholder={placeholder}
      labelInValue={false}
      value={selectedValues.map((v) => (v && v.charAt(0) === '!' ? v.replace('!', '') : v))}
      onSelect={triggerChange}
      onDeselect={onRemove}
      tagRender={tagRender}
      onSearch={handleRequest}
      notFoundContent={renderNotFoundContent()}
      optionFilterProp={customFilterProp}
      onDropdownVisibleChange={onDropdownVisibleChange}
      open={staticOptions ? undefined : searchValue}
      searchValue={searchValue}
      filterOption={filterOption}
      listHeight={listHeight}
    >
      {dropdownOptions}
    </Select>
  );
};

export default MultipleSelect;

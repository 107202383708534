/* eslint-disable jsx-a11y/accessible-emoji */
import React, { lazy, Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Input, ConfigProvider, Select, Row, Col } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { SearchIcon } from 'UI/CustomIcon/CustomIcon';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { smartlistReset } from 'containers/smartList/smartListActions';
import Text from 'antd/lib/typography/Text';
import NoItem from 'components/common/noItem';
import Button from 'UI/Button/Button';
import { LIST_NAME_CHAR_LIMIT, LIST_TABS } from 'constants/constants';
import smartListEmptyAnimation from 'assets/animations/empty_smart_list.json';
import CustomLoader from 'UI/CustomLoader';
import { tableOptions, getTableColumns, getPlaceHolder, getStyles } from './ListTableConfig';
import './ListTable.scss';

const Player = lazy(() => import('utils/player'));

const { pageNumber, sortOrder } = tableOptions;

const SMART_LIST = LIST_TABS[0].value;

const EXCLUSION_LIST = LIST_TABS[1].value;

const ListTable = ({
  listType,
  deleteList,
  total,
  loading,
  lists,
  fetchLists,
  onTypeFilter,
  typeSelection,
  defaultFilter,
  onUplodClick,
  handleSetIsCreateList,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [pageStart, setPageStart] = useState(1);
  const dispatch = useDispatch();
  const hasMore = total > lists.length;
  const { activeFilter } = useSelector((state) => state.smartList, shallowEqual);
  useEffect(() => {
    return () => {
      dispatch(smartlistReset());
      setSearchTerm('');
    };
  }, []);

  const getListWithSortAndSearch = (searchValue, page = pageNumber, sorting = sortOrder) => {
    const params = {
      page,
    };
    if (sorting && sorting.order) {
      params.sortKey = sorting.key;
      params.sortOrder = sorting.order === 'descend' ? 'DESC' : 'ASC';
    }
    if (searchValue) {
      params.searchTerm = searchValue;
    }
    fetchLists(params);
  };

  const onChange = (pagination, filter, sorter, extra) => {
    const payload = sorter.order
      ? {
          key: sorter.field,
          order: sorter.order,
        }
      : tableOptions.sortConfig;

    if (hasMore || extra.action === 'paginate') {
      if (extra.action === 'paginate') {
        setPageStart(pagination.current);
      } else {
        setPageStart(1);
      }
      getListWithSortAndSearch(searchTerm, pagination.current, payload);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setPageStart(1);
    getListWithSortAndSearch(e.target.value);
  };

  const columns = () => {
    const type = activeFilter[listType];
    return getTableColumns(type, listType, hasMore, deleteList, onUplodClick);
  };

  const resetSearch = () => {
    setSearchTerm('');
    getListWithSortAndSearch('');
  };

  const handleTypeChange = (type) => {
    onTypeFilter(listType, type);
  };

  const getNoItemText = () => {
    switch (listType) {
      case SMART_LIST:
        return (
          <div className="no-item-title">
            <br />
            {searchTerm
              ? `You don't have a list by that name. Try again${'?'}`
              : 'Create your first Smart List here'}
          </div>
        );

      case EXCLUSION_LIST:
        return searchTerm ? (
          `You don't have a list by that name. Try again${'?'}`
        ) : (
          <div className="no-item-info">
            <br />
            <div className="no-item-title">
              <strong>
                No Exclusion Lists to show <span> 🙇‍♂️ </span>
              </strong>
            </div>
            <div className="no-item-list">
              <div>
                Use Exclusion Lists to exclude people and companies that you don’t want to see in
                your Smart Lists. Now, you don’t have to manually remove records for different
                situations and combinations.
              </div>
              <br />
              <div>
                {`You can use People / Company Exclusion Lists while
                   adding people to a Smart List from the People Insights page`}
              </div>
              <br />
              <div>
                {`You can ONLY use Company Exclusion Lists while adding companies
                  to a Smart List from the Company Insights page.`}
              </div>
            </div>
          </div>
        );
      default:
        return 'No Items';
    }
  };

  const getNoItemIcon = () => {
    return (
      <Suspense fallback={<CustomLoader />}>
        <Player
          autoplay
          loop
          controls={false}
          renderer="canvas"
          extraProp={{}}
          src={smartListEmptyAnimation}
          style={{ height: '130px', width: '133px' }}
        />
      </Suspense>
    );
  };

  return (
    <ConfigProvider
      renderEmpty={() => !loading && <NoItem icon={getNoItemIcon()} text={getNoItemText()} />}
    >
      <div className="table-view-container">
        <Table
          size="small"
          columns={columns()}
          loading={loading}
          dataSource={lists}
          onChange={onChange}
          className="list-table"
          scroll={{
            y: '100%',
          }}
          pagination={{
            defaultCurrent: 1,
            position: ['bottomLeft'],
            total,
            showSizeChanger: false,
            responsive: true,
            current: pageStart,
            pageSize: 25,
            showTotal: (count, range) => (
              <Text type="secondary">{`Showing ${range[0]}-${range[1]} of ${count}`}</Text>
            ),
            // size: 'small',
            // itemRender: (page, type, originalElement) => {
            //   if (type === 'prev' || type === 'next') return originalElement;
            //   return null;
            // },
          }}
          rowKey="id"
          showSorterTooltip={false}
          title={() => (
            <div>
              <Row gutter={[24]}>
                <Col span={16}>
                  <Row gutter={[16]}>
                    <Col style={{ paddingLeft: 0 }}>
                      <Input
                        style={{ width: '240px', height: '36px' }}
                        value={searchTerm}
                        placeholder="Search"
                        onChange={handleSearch}
                        maxLength={LIST_NAME_CHAR_LIMIT}
                        suffix={
                          searchTerm ? (
                            <CloseOutlined
                              onClick={resetSearch}
                              style={{ color: 'rgba(0,0,0,.45)' }}
                            />
                          ) : (
                            <SearchIcon
                              className="search-icon"
                              width={15}
                              fill={searchTerm ? '#29275F' : '#929CB7'}
                            />
                          )
                        }
                      />
                    </Col>
                    <Col className="reset-padding">
                      <Select
                        style={getStyles(listType)}
                        placeholder={getPlaceHolder(listType)}
                        onChange={handleTypeChange}
                        options={typeSelection}
                        defaultValue={defaultFilter}
                        value={activeFilter[listType] || defaultFilter}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  <Button
                    onClick={() => handleSetIsCreateList(true)}
                    theme="primary"
                    icon={<PlusOutlined />}
                  >
                    Create a List
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        />
      </div>
    </ConfigProvider>
  );
};

ListTable.propTypes = {
  listType: PropTypes.string,
  tab: PropTypes.string,
  deleteList: PropTypes.func,
  loading: PropTypes.bool,
  fetchLists: PropTypes.func,
  lists: PropTypes.array,
  total: PropTypes.number,
  onTypeFilter: PropTypes.func,
  typeSelection: PropTypes.array,
  defaultFilter: PropTypes.string,
  onUplodClick: PropTypes.func,
};

export default ListTable;

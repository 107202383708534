import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { formatNumberWithComma } from 'utils/common';
import { useTranslation } from 'react-i18next';

const ShowTotal = ({ range, total, type }) => {
  const { t } = useTranslation(['discovery']);

  return (
    <Typography.Text type="secondary" style={{ marginRight: '10px' }}>
      {t('info_text.pagination_records', {
        range: `${range[0]}-${range[1]}`,
        count: formatNumberWithComma(total),
        type,
      })}
    </Typography.Text>
  );
};

ShowTotal.propTypes = {
  total: PropTypes.number,
  range: PropTypes.array,
};

ShowTotal.defaultProps = {
  range: [0, 0],
  total: 0,
};

export default ShowTotal;

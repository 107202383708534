import { put, takeLatest, call } from 'redux-saga/effects';
import { baseURL, endPoints } from 'api/apiEndpoints';
import { NEWS_INIT } from 'store/actionTypes';
import { getRequest } from 'api/apiRequests';
import { newsSuccess, newsFailure } from './newsActions';

function* getNewsList(action) {
  try {
    const {
      params: { isFilterChange, ...queryParams },
      headers,
    } = action.payload;
    const url = `${baseURL}${endPoints.newsFeed}`;
    const newsFeeds = yield call(getRequest, url, queryParams, headers);
    if (!newsFeeds.error) {
      yield put(
        newsSuccess({
          newsFeeds,
          isFilterChange,
        })
      );
    } else {
      yield put(newsFailure(newsFeeds.message));
    }
  } catch (err) {
    yield put(newsFailure(err.message));
  }
}
// eslint-disable-next-line import/prefer-default-export
export function* getNewsListSaga() {
  yield takeLatest(NEWS_INIT, getNewsList);
}

import React from 'react';
import PropTypes from 'prop-types';
import './Avatar.scss';
import { Typography } from 'antd';
import { getInitials } from '../../utils/common';

const { Text } = Typography;

const Avatar = (props) => {
  const { title, size } = props;

  const renderAvatar = () => (
    <div className={`avatar-wrapper ${size}`} {...props}>
      {title && <Text className="main-title">{getInitials(title)}</Text>}
    </div>
  );

  return renderAvatar();
};

Avatar.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
};

Avatar.defaultProps = {
  size: 'big',
};

export default Avatar;

import { Button, Col, Dropdown, Menu, Row, Space, Tooltip, Typography } from 'antd';
import IntegrationService from 'api/services/integrationService';
import ExportFieldSelection from 'components/common/exportFieldSelection';
import ExportHubspot from 'components/discovery/export/ExportHubspot';
import ExportPipeDrive from 'components/discovery/export/ExportPipeDrive';
import ExportSalesForce from 'components/discovery/export/ExportSalesForce';
import {
  COMPANY_SORTING_OPTIONS,
  DEFAULT_USER_ROLE_CONSTANT,
  LEAD_SORTING_OPTIONS,
  MAX_EXPORT_LIMIT,
  MAX_PIPEDRIVE_EXPORT_LIMIT,
  MAX_TOTAL_LIMIT,
  SORT_ORDERS,
  EXPORT_POPOVER_SOURCE,
} from 'constants/constants';
import {
  clearCheckedItems,
  setDropDownId,
  setExportDropdownVisible,
} from 'containers/discovery/discoveryActions';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CsvIcon,
  ExportIcon,
  HubspotIcon,
  PipedriveIcon,
  SalesforceIcon,
} from 'UI/CustomIcon/CustomIcon';
import {
  ExportProgress,
  ExportSuccess,
  FailedNotification,
} from 'UI/CustomMessages/CustomMessages';
import { getLoggedInUser, updateSortingForSearch } from 'utils/common';
import { getSearchQueryParams } from 'utils/discovery/common';
import { eventActions, eventList, eventManager } from 'utils/eventManager';
import store from 'src/store/store';
import scssVariables from '../../../../_vars.scss';
import ExportButton from '../ExportButton/ExportButton';
import './ExportPopover.scss';

const { Text } = Typography;
const getSearchParams = (tab) => {
  const {
    leadFilters,
    companyFilters,
    page: PAGE,
    defaultInnerActiveKey,
    selectionParam,
  } = store.getState().discovery;
  const filters = tab === 'lead' ? leadFilters : companyFilters;

  const defaultLeadSorting = [{ key: LEAD_SORTING_OPTIONS[0].key, order: SORT_ORDERS[1].key }];
  const defaultCompanySorting = [
    { key: COMPANY_SORTING_OPTIONS[0].key, order: SORT_ORDERS[1].key },
  ];
  const defaultSorting = tab === 'lead' ? defaultLeadSorting : defaultCompanySorting;
  const {
    SORT: { values: selectedSort },
    ORDER: { values: selectedOrder },
  } = filters;
  const data = getSearchQueryParams(
    filters,
    {
      sort: selectedSort[0],
      order: selectedOrder[0],
      page: PAGE,
      fields: [],
      innerTab: defaultInnerActiveKey,
    },
    selectionParam
  );
  if (data && !data.sort) {
    data.sort = defaultSorting;
  }
  return updateSortingForSearch(data, tab);
};
const ExportPopover = ({ tab, id, smallIcon = false, source, onClick = () => {} }) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState('');
  const hubspotConfig = useSelector((state) => state.common.hubspotConfig);
  const salesForceConfig = useSelector((state) => state.common.salesforceConfig);
  const pipedriveConfig = useSelector((state) => state.common.pipedriveConfig);
  const pusherChannel = useSelector((state) => state.auth.pusherChannel);
  const checkedItems = useSelector((state) => state.discovery.checkedItems);
  const dropdownId = useSelector((state) => state.discovery.dropdownId);
  const exportDropdownVisible = useSelector((state) => state.discovery.exportDropdownVisible);
  let totalSelected = useSelector((state) => state.discovery.totalSelected);
  const { planName } =
    useSelector((state) => state.common.planPermission) || DEFAULT_USER_ROLE_CONSTANT;
  const [isLoading, setIsLoading] = useState(false);

  const userInfo = getLoggedInUser();
  const { userId, userEmail } = userInfo;

  if (id) {
    totalSelected = 1;
  }
  const totalCount = totalSelected;
  const visible = exportDropdownVisible && dropdownId === id;
  const isProfessional = planName === 'PROFESSIONAL';

  const openDropDown = () => {
    dispatch(setDropDownId(id));
    dispatch(setExportDropdownVisible(true));
  };

  const { DATAVIEW, QUICKVIEW } = EXPORT_POPOVER_SOURCE;
  let conditionalVisibilityProps = { trigger: ['click'] };
  let onClickProps = {};

  if (source === DATAVIEW) {
    conditionalVisibilityProps = { visible };
    onClickProps = { onClick: openDropDown };
  }
  if (source === QUICKVIEW) {
    conditionalVisibilityProps = {
      ...conditionalVisibilityProps,
      getPopupContainer: (trigger) => (trigger ? trigger.parentElement : trigger),
    };
  }

  const onExport = async (data) => {
    const {
      status,
      payload,
      exportType: eType,
      export_type: csvCompanyExportType,
      msg,
      url: csvUrl,
    } = data;
    let exportType = eType || csvCompanyExportType || '';
    if (exportType === 'SF') {
      exportType = 'Salesforce';
      dispatch(clearCheckedItems());
    }
    if (exportType === 'CSV') {
      if (msg === 'In Progress') {
        ExportProgress({
          duration: 0,
          progress: 50,
          msg: `${exportType} export in progress!`,
          key: `${exportType.toLowerCase()}-${userId}`,
        });
      }
      if (msg === 'done') {
        ExportSuccess({
          showDownload: exportType.toLowerCase() === 'csv',
          url: csvUrl,
          msg: `${exportType} export complete!`,
          successRoute: `/my-account?view=${exportType.toLowerCase()}`,
          key: `${exportType.toLowerCase()}-${userId}`,
          duration: 10,
        });
        dispatch(clearCheckedItems());
      }
    }
    if (msg === 'failed' || status === 'failed') {
      FailedNotification({
        msg: `${exportType} export failed!`,
        key: `${exportType.toLowerCase()}-${userId}`,
        path: `/my-account?view=${exportType.toLowerCase()}`,
        duration: 10,
      });
    }
    if (['failed', 'done'].includes(msg) || status === 'failed') {
      eventManager.track(eventList.EXPORT_STATUS, {
        payload: {
          status: msg === 'done' ? 'success' : status || msg,
          export_type: exportType,
          version: eventActions.NDF,
        },
      });
    }
  };

  const exportCSV = async (rec) => {
    const { projection } = rec;
    const params = {
      userId: userId.toString(),
      userEmail,
      scheduleAt: new Date().getTime(),
      projection,
      filter: {},
      leadId: id ? [id] : checkedItems,
      export_count: totalCount,
    };
    const reqData = {
      data: params,
      type: 'EXPORT_CSV',
      metadata: getSearchParams(tab),
      export_count: totalCount,
      entity: tab,
    };
    setIsLoading(true);
    const res = await IntegrationService.exportAddtoListScheduler(reqData);
    setIsLoading(false);
    if (tab === 'lead' && res.jobId) {
      pusherChannel.bind(`csv-export-status-lead-${res.jobId}`, onExport);
    } else if (res.jobId) {
      pusherChannel.bind(`export-status-${res.jobId}`, onExport);
    }
    if (res.jobId) {
      ExportProgress({
        msg: 'CSV export in progress!',
        duration: 0,
        key: `${selectedOption}-${userId}`,
      });
      setSelectedOption('');
    }
    eventManager.track(eventList.EXPORT_TRIGGERED, {
      payload: {
        tab,
        export_type: eventActions.CSV,
        navigation: store.getState().discovery.defaultInnerActiveKey,
        payload: getSearchParams(tab),
        version: eventActions.NDF,
        export_source: source,
      },
    });
  };

  // Need to disable the Company Export fileds for Plans except Professional
  const getDisabledFields = () => {
    return isProfessional ? [] : ['fiscal_year', 'revenue_range', 'company_funding_range'];
  };

  const dropdownOverlay = id ? (
    singleExportOptions(
      '',
      setSelectedOption,
      salesForceConfig,
      hubspotConfig,
      pipedriveConfig,
      isProfessional,
      onClick
    )
  ) : (
    <Menu>
      <Menu.Item key="123">
        {exportButtons({
          numberOfItems: totalCount,
          type: 'selected',
          setSelectedOption,
          salesForceConfig,
          hubspotConfig,
          pipedriveConfig,
          hasViewAccess: isProfessional,
          onClick,
        })}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="export-popover">
      <Dropdown
        arrow
        overlay={dropdownOverlay}
        placement="bottomLeft"
        disabled={!totalCount}
        overlayClassName="export-popover-overlay"
        {...conditionalVisibilityProps}
      >
        {smallIcon ? (
          <Tooltip placement="top" title="Export">
            <Button
              {...onClickProps}
              key={id}
              theme="secondary"
              size="small"
              style={{ border: '0px' }}
              icon={<ExportIcon fill="#4A6EE5" className="action-icon" width={15} height={15} />}
            />
          </Tooltip>
        ) : (
          <ExportButton id={id} tab={tab} />
        )}
      </Dropdown>
      {selectedOption === 'csv' && (
        <ExportFieldSelection
          visible={selectedOption === 'csv'}
          selectedValues={{}}
          onClose={() => {
            setSelectedOption('');
          }}
          onExport={(el) => {
            exportCSV(el);
          }}
          listType={tab}
          exportCount={0}
          csvCount={totalSelected}
          className="custom-csv-export"
          disabledValues={getDisabledFields()}
          disableTechnology={!isProfessional}
          isLoading={isLoading}
        />
      )}
      {selectedOption === 'hubspot' && (
        <ExportHubspot
          tab={tab}
          crm={selectedOption}
          setSelectedOption={setSelectedOption}
          selectedIds={checkedItems}
          getSearchParams={getSearchParams}
          totalSelected={totalSelected}
          source={source}
          id={id}
        />
      )}
      {selectedOption === 'pipedrive' && (
        <ExportPipeDrive
          tab={tab}
          crm={selectedOption}
          setSelectedOption={setSelectedOption}
          selectedIds={checkedItems}
          getSearchParams={getSearchParams}
          totalSelected={totalSelected}
          source={source}
          id={id}
        />
      )}
      {selectedOption === 'salesforce' && (
        <ExportSalesForce
          tab={tab}
          crm={selectedOption}
          setSelectedOption={setSelectedOption}
          selectedIds={checkedItems}
          getSearchParams={getSearchParams}
          totalSelected={totalSelected}
          source={source}
          id={id}
        />
      )}
    </div>
  );
};

const addCRM = (
  type,
  numberOfItems,
  setSelectedOption,
  hasViewAccess,
  exportLimit = MAX_EXPORT_LIMIT,
  onClick
) => {
  let meta = type === 'hubspot' ? { Icon: HubspotIcon, Title: 'HubSpot' } : null;
  meta = !meta && type === 'salesforce' ? { Icon: SalesforceIcon, Title: 'Salesforce' } : meta;
  meta = !meta && type === 'pipedrive' ? { Icon: PipedriveIcon, Title: 'Pipedrive' } : meta;

  return (
    meta && (
      <Col className="export-dropdown-col">
        <Tooltip placement="bottom" title={`Export limit: ${exportLimit}`}>
          <Button
            className="export-btn"
            disabled={!numberOfItems || !hasViewAccess || numberOfItems > exportLimit}
            onClick={() => {
              setSelectedOption(type);
              onClick(type);
            }}
          >
            <meta.Icon height="16" width="16" />
            <Text
              style={{
                color: scssVariables.toggleActiveColor,
                marginLeft: '5px',
                fontSize: '12px',
              }}
            >
              {meta.Title}
            </Text>
          </Button>
        </Tooltip>
      </Col>
    )
  );
};
const exportButtons = ({
  numberOfItems,
  setSelectedOption,
  salesForceConfig,
  hubspotConfig,
  pipedriveConfig,
  hasViewAccess,
  onClick,
}) => {
  return (
    <div className="export-popover-content">
      <Space direction="vertical" size={16}>
        <Row gutter={8} className="export-popover-row" align="middle">
          <Col className="export-dropdown-col">
            <Text className="export-text">Export to</Text>
          </Col>
          <Col className="export-dropdown-col">
            <Tooltip placement="bottom" title="Export limit: 50000">
              <Button
                className="export-btn"
                disabled={
                  hubspotConfig?.disable_csv_export ||
                  !numberOfItems ||
                  numberOfItems > MAX_TOTAL_LIMIT
                }
                onClick={() => {
                  setSelectedOption('csv');
                  onClick('csv');
                }}
              >
                <CsvIcon height="16" width="16" />
                <Text style={{ color: '#5E81F4', marginLeft: '5px', fontSize: '12px' }}>CSV</Text>
              </Button>
            </Tooltip>
          </Col>
          {hubspotConfig && hubspotConfig.status
            ? addCRM(
                'hubspot',
                numberOfItems,
                setSelectedOption,
                hasViewAccess,
                MAX_EXPORT_LIMIT,
                onClick
              )
            : null}
          {salesForceConfig?.status && salesForceConfig?.isPackageInstalled
            ? addCRM(
                'salesforce',
                numberOfItems,
                setSelectedOption,
                hasViewAccess,
                MAX_EXPORT_LIMIT,
                onClick
              )
            : null}
          {pipedriveConfig && pipedriveConfig.status
            ? addCRM(
                'pipedrive',
                numberOfItems,
                setSelectedOption,
                hasViewAccess,
                MAX_PIPEDRIVE_EXPORT_LIMIT,
                onClick
              )
            : null}
        </Row>
        <Row className="export-popover-row" align="bottom">
          <Col flex="auto" className="export-dropdown-col">
            <Typography.Text style={{ fontSize: '12px', color: '#697390' }}>
              Export limits for CSV: 50000, HubSpot: 10000, Salesforce: 10000, Pipedrive:{' '}
              {MAX_PIPEDRIVE_EXPORT_LIMIT}
            </Typography.Text>
          </Col>
        </Row>
      </Space>
    </div>
  );
};

const singleExportOptions = (
  id,
  setSelectedOption,
  salesForceConfig,
  hubspotConfig,
  pipedriveConfig,
  hasViewAccess,
  onClick
) => (
  <div className="export-popover-content single-export">
    <Menu className="export-dropdown-row">
      <Menu.Item
        disabled={hubspotConfig?.disable_csv_export}
        onClick={() => {
          setSelectedOption('csv');
          onClick('csv');
        }}
        key="csv"
        className="custom-single-menu-item"
      >
        <CsvIcon height="16" width="16" />
        <Text style={{ color: '#5E81F4', marginLeft: '5px' }}>CSV</Text>
      </Menu.Item>
      {salesForceConfig && salesForceConfig.isConnected && salesForceConfig.isPackageInstalled ? (
        <Menu.Item
          disabled={!hasViewAccess}
          onClick={() => {
            setSelectedOption('salesforce');
            onClick('salesforce');
          }}
          key="salesforce"
          className="custom-single-menu-item"
        >
          <SalesforceIcon height="16" width="16" />
          <Text style={{ color: '#5E81F4', marginLeft: '5px' }}>Salesforce</Text>
        </Menu.Item>
      ) : (
        <></>
      )}
      {hubspotConfig && hubspotConfig.status === '1' && (
        <Menu.Item
          disabled={!hasViewAccess}
          onClick={() => {
            setSelectedOption('hubspot');
            onClick('hubspot');
          }}
          key="hubspot"
          className="custom-single-menu-item"
        >
          <HubspotIcon height="16" width="16" />
          <Text style={{ color: '#5E81F4', marginLeft: '5px' }}>Hubspot</Text>
        </Menu.Item>
      )}
      {pipedriveConfig && pipedriveConfig.status && (
        <Menu.Item
          disabled={!hasViewAccess}
          onClick={() => {
            setSelectedOption('pipedrive');
            onClick('pipedrive');
          }}
          key="pipedrive"
          className="custom-single-menu-item"
        >
          <PipedriveIcon height="16" width="16" />
          <Text style={{ color: '#5E81F4', marginLeft: '5px' }}>Pipedrive</Text>
        </Menu.Item>
      )}
    </Menu>
  </div>
);

export default React.memo(ExportPopover);

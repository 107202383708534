/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import Sider from 'antd/lib/layout/Sider';
import {
  EmployeeIcon,
  LocationIcon,
  TechnologyIcon,
  PsychographicIcon,
} from 'UI/CustomIcon/CustomIcon';
import { Checkbox, Collapse, Space, Tooltip, Typography } from 'antd';
import 'components/discovery/filters/FilterList/FilterList.scss';
import Combobox from 'UI/Combobox';
import DynamicCombobox from 'UI/DynamicCombobox';
import { FilterSelectionButton } from 'components/list/ListFilters/FilterTags';
import { FREE_TEXT_FILTER_CHAR_LIMIT, COMPANY_SIZE_DATA } from 'constants/constants';
import { debounce } from 'utils/common';
import { showError } from 'components/common/errorMessage';
import { TechnologyService } from 'api/services';

const { Panel } = Collapse;
const { Text } = Typography;
const { getTechnologyLocation } = TechnologyService;

const filtersDefaultValues = {
  LOCATION: ['United States', 'Canada'],
  COMPANY_SIZE: COMPANY_SIZE_DATA,
  CATEGORIES: [],
};
const CompanyFilterContainer = ({ tabHeading, updateFilter, categoryList, filterData, type }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const resetDropdownOptions = (open, isLoading = false) => {
    if (isLoading) {
      setLoading(true);
    }
    return !open && setOptions([]);
  };

  const handleRequest = async (value) => {
    if (!value) {
      return;
    }
    setLoading(true);
    const query = {
      q: value,
    };
    const response = await getTechnologyLocation(query);
    setLoading(false);
    if (response.error) {
      showError(response.message);
      return;
    }
    if (response?.data.length) {
      // const suggestions = getOptionsFormat(response);
      setOptions(response.data);
    } else {
      setOptions([]);
    }
  };

  // TODO: add translation / i18n for tab?
  return (
    <Sider trigger={null} width={300} theme="light" className="technology-filter-container">
      <div className="slintel-filter-list">
        {/* TODO: add news css class/ add new css file and params classname */}
        <div className="technology-filter-top-border" />
        <div className="discovery-filters technology-filters">
          <Collapse
            accordion
            bordered={false}
            className="filter-group"
            collapsible="disabled"
            defaultActiveKey={tabHeading}
          >
            {/* TODO: change icon , make header component, var css */}
            <Panel
              header={
                <div className="filter-header">
                  <div className="filter-header-text">
                    <Space size={5}>
                      <Text>{tabHeading}</Text>
                    </Space>
                  </div>
                </div>
              }
              key={tabHeading}
              showArrow={false}
            >
              <Collapse
                bordered={false}
                className="filter-group-item"
                expandIconPosition="right"
                key="Location"
              >
                <Panel
                  header={
                    <div className="filter-header">
                      <Tooltip
                        placement="right"
                        title="Search for companies based on the geographical location"
                      >
                        <Space size={5}>
                          <LocationIcon fill="#697390" width="15px" />
                          <Text>Location</Text>
                        </Space>
                      </Tooltip>
                    </div>
                  }
                  key="Company Location"
                >
                  <div style={{ padding: '5px' }}>
                    <DynamicCombobox
                      hasNegation={false}
                      textSeparator=","
                      mode="multiple"
                      type="COMPANY_LOCATION"
                      onChange={(v) => updateFilter('company_location', v)}
                      maxLength={FREE_TEXT_FILTER_CHAR_LIMIT}
                      value={filterData.company_location}
                      handleRequest={debounce(handleRequest, 500)}
                      optionList={options}
                      loading={loading}
                      onBlur={() => setOptions([])}
                      resetDropdownOptions={resetDropdownOptions}
                    />
                    <FilterSelectionButton
                      selectFilter={(v) =>
                        !filterData.company_location.includes(v) &&
                        updateFilter('company_location', [...filterData.company_location, v])
                      }
                      values={filtersDefaultValues.LOCATION}
                    />
                  </div>
                </Panel>
                <Panel
                  header={
                    <div className="filter-header">
                      <Tooltip
                        placement="right"
                        title="Look for companies using their employee count as a filter. We use preset ranges for this filter"
                      >
                        <Space size={5}>
                          <EmployeeIcon fill="#697390" width="15px" />
                          <Text>Size</Text>
                        </Space>
                      </Tooltip>
                    </div>
                  }
                  key="Size"
                >
                  <div style={{ padding: '5px' }}>
                    <Checkbox.Group
                      className="filter-checkbox__list"
                      style={{ display: 'flex', flexDirection: 'column' }}
                      options={filtersDefaultValues.COMPANY_SIZE}
                      value={filterData.company_size}
                      onChange={(v) => updateFilter('company_size', v)}
                    />
                  </div>
                </Panel>
                {categoryList.length > 0 && (
                  <Panel
                    header={
                      <div className="filter-header">
                        <Tooltip
                          placement="right"
                          title={
                            type === 'keywords'
                              ? 'Search psychographics you want to target from the list'
                              : 'Search technologies you want to target from the list'
                          }
                        >
                          <Space size={5}>
                            {type === 'keywords' ? (
                              <PsychographicIcon fill="#697390" width="15px" />
                            ) : (
                              <TechnologyIcon fill="#697390" width="15px" />
                            )}
                            <Text>Category</Text>
                          </Space>
                        </Tooltip>
                      </div>
                    }
                    key="Category"
                  >
                    <div style={{ padding: '5px' }}>
                      <Combobox
                        listHeight={200}
                        hasNegation={false}
                        textSeparator=","
                        mode="multiple"
                        type="CATEGORIES"
                        staticOptions
                        value={filterData.categories}
                        options={categoryList}
                        onChange={(v) => updateFilter('categories', v)}
                        maxLength={FREE_TEXT_FILTER_CHAR_LIMIT}
                      />
                      <FilterSelectionButton
                        selectFilter={(v) =>
                          !filterData.categories.includes(v) &&
                          updateFilter('categories', [...filterData.categories, v])
                        }
                        values={categoryList.slice(0, 2)}
                      />
                    </div>
                  </Panel>
                )}
              </Collapse>
            </Panel>
          </Collapse>
        </div>
      </div>
    </Sider>
  );
};

export default CompanyFilterContainer;

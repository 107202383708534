import {
  OUTREACH_MAPPING_SUCCESS,
  OUTREACH_CONFIG_SUCCESS,
  OUTREACH_CONFIG_FAILURE,
  OUTREACH_FIELD_CHANGES,
  OUTREACH_INIT,
} from 'store/actionTypes';

export const outreachFieldMappingSuccess = (data) => {
  return {
    type: OUTREACH_MAPPING_SUCCESS,
    data,
  };
};

export const outreachFieldChanges = (data) => {
  return {
    type: OUTREACH_FIELD_CHANGES,
    data,
  };
};

export const fieldMappingFailure = (error) => {
  return {
    type: 'fieldMappingFailure',
    error,
  };
};
export const deleteFieldMappingByIdSuccess = (data) => {
  return {
    type: 'deleteFieldMappingByIdSuccess',
    data,
  };
};

export const getOutreachSettings = (params, headers) => {
  const payload = {
    params,
    headers,
  };
  return {
    type: OUTREACH_INIT,
    payload,
  };
};
export const getOutreachConfigSuccess = (data) => {
  return {
    type: OUTREACH_CONFIG_SUCCESS,
    data,
  };
};

export const getOutreachConfigFailure = (error) => {
  return {
    type: OUTREACH_CONFIG_FAILURE,
    error,
  };
};

import { notification } from 'antd';
import React from 'react';
import { SuccessIcon, SmallCloseIcon, InfoNewIcon, AlertIcon } from 'UI/CustomIcon/CustomIcon';
import { NOTIFICATION_DURATION } from 'constants/constants';
import Text from 'antd/lib/typography/Text';
import { getParagraphWithSafeHTML } from 'utils/common';
import './errorMessage.scss';
import scssVariables from 'src/_vars.scss';

const iconDimensions = {
  height: 25,
  width: 25,
};

export const showError = (errorMessage) => {
  return notification.open({
    duration: NOTIFICATION_DURATION,
    message: <Text style={{ color: '#29275F' }}>{errorMessage}</Text>,
    style: {
      border: '1px solid #F64B53',
      boxShadow: '0px 10px 10px #5E81F41A',
      color: '#F64B53',
      borderRadius: 8,
    },
    top: '10px',
    // TODO: Replace with negation icon
    icon: <AlertIcon {...iconDimensions} fill="#F64B53" />,
    closeIcon: <SmallCloseIcon height="10" width="10" fill="#697390" />,
  });
};

export const showSuccess = (successMessage, duration = NOTIFICATION_DURATION) => {
  return notification.open({
    message: <Text style={{ color: '#29275F' }}>{getParagraphWithSafeHTML(successMessage)}</Text>,
    style: {
      border: '1px solid #36D068',
      color: '#6EC194',
      boxShadow: '0px 10px 10px #5E81F41A',
      borderRadius: 8,
    },
    duration,
    // description: 'this is a desfdsdsfdsfdsn  dsfndsfkndskf dfsdfkdsnfdsfmnfd',
    top: '10px',
    icon: <SuccessIcon {...iconDimensions} fill="#36D068" />,
    closeIcon: <SmallCloseIcon height="10" width="10" fill="#697390" />,
  });
};

export const showWarning = (errorMessage) => {
  return notification.open({
    duration: NOTIFICATION_DURATION,
    message: <Text style={{ color: '#29275F' }}>{errorMessage}</Text>,
    style: {
      borderRadius: '5px',
      color: '#F65F4B',
    },
    top: '10px',
    icon: <AlertIcon {...iconDimensions} fill="#FF7C0B" />,
    closeIcon: <SmallCloseIcon height="7" width="7" fill="#697390" />,
  });
};

export const showInfo = (errorMessage, option = {}) => {
  return notification.open({
    placement: option.placement,
    duration: option.duration ?? NOTIFICATION_DURATION,
    message: <Text style={{ color: '#29275F' }}>{errorMessage}</Text>,
    description: option.description && (
      <Text style={{ color: scssVariables.grey }}>{option.description}</Text>
    ),
    className: 'info-notification-container',
    style: {
      borderRadius: '5px',
      color: '#4A6EE5',
    },
    top: '10px',
    icon: <InfoNewIcon {...iconDimensions} fill="#4A6EE5" />,
    closeIcon: <SmallCloseIcon height="7" width="7" fill="#697390" />,
  });
};

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Table, ConfigProvider, Space, Radio, Tooltip } from 'antd';
import NoItem from 'components/common/noItem';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Link from 'antd/lib/typography/Link';
import UILink from 'UI/Link';
import Confirm from 'UI/ConfirmModal/Confirm';
import Text from 'antd/lib/typography/Text';
import { useDispatch, useSelector } from 'react-redux';
import { getSalesForceConfig } from 'containers/common/commonActions';
import { openQuickView } from 'containers/smartList/smartListActions';
import {
  headerGenerator,
  getEmailBucketIcon,
  isEqual,
  getPlanConfig,
  isCopyEnabled,
} from 'utils/common';
import { usePrevious } from 'utils/hooks/customHooks';
import CSVImg from 'assets/svg/csv.png';
import HubspotImg from 'assets/svg/hubspot.png';
import SalesforceImg from 'assets/svg/salesforce.png';
import AlertIcon from 'assets/svg/alert-01.png';
import { EXPORT_LEADS_OPTIONS, SF_LEAD_EXPORT_TYPE, LEAD, COMPANY } from 'constants/constants';
import scssVariables from 'src/_vars.scss';
import Copy from 'UI/Copy/Copy';
import ExportFieldSelection from '../common/exportFieldSelection';

const planConfig = getPlanConfig();

const LeadListItem = ({
  listItems,
  fetchListItems,
  deleteListItems,
  exportListItems,
  params = { page: 1 },
  loading = false,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const hubspotConfig = useSelector((state) => state.common.hubspotConfig);
  const salesForceConfig = useSelector((state) => state.common.salesforceConfig);
  const userInfoRedis = useSelector((state) => state.common.userInfoRedis);
  const [leadExportType, setLeadExportType] = useState(null);
  const [action, setAction] = useState({
    confirm: false,
    targetItem: {},
    type: '', // single, selected, bulk
  });
  const prevParams = usePrevious(params);
  const quickView = useSelector((state) => state.smartList.quickView);

  useEffect(() => {
    if (prevParams && params) {
      const { page: prevPage, ...prevListParams } = prevParams;
      const { page: currentPage, ...listParams } = params;
      if (!isEqual(prevListParams, listParams)) {
        setSelectedRowKeys([]);
      }
    }
  }, [params]);

  const { token, session_id: sessionId } = user;
  const headers = headerGenerator(token, sessionId);

  useEffect(() => {
    if (salesForceConfig && salesForceConfig.lead_export_type) {
      if (salesForceConfig.lead_export_type === SF_LEAD_EXPORT_TYPE.both) {
        if (!(userInfoRedis && userInfoRedis.salesforce_preference)) {
          setLeadExportType(1);
        } else {
          setLeadExportType(userInfoRedis.salesforce_preference.exortType);
        }
      } else {
        setLeadExportType(salesForceConfig.lead_export_type);
      }
    }
  }, [salesForceConfig && salesForceConfig.lead_export_type]);

  const onSelectChange = (newSelectedKeys) => {
    setSelectedRowKeys(newSelectedKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
    fixed: true,
  };

  const updateSetAction = (event, type = 'single', targetItem = {}, confirm = true) => {
    if (event === 'export-sf') {
      dispatch(getSalesForceConfig(headers));
    }

    setAction({
      confirm,
      targetItem,
      type,
      event,
    });
  };

  const singleExportOptions = (id) => (
    <Menu>
      <Menu.Item
        disabled={hubspotConfig?.disable_csv_export}
        onClick={() => updateSetAction('export-csv', undefined, { id })}
      >
        <img alt="csv" src={CSVImg} />
        <Text style={{ color: '#5E81F4', marginLeft: '5px' }}>CSV</Text>
      </Menu.Item>
      {salesForceConfig && salesForceConfig.isConnected && salesForceConfig.isPackageInstalled ? (
        <Menu.Item onClick={() => updateSetAction('export-sf', undefined, { id })}>
          <img alt="salesforce" src={SalesforceImg} />
          <Text style={{ color: '#5E81F4', marginLeft: '5px' }}>Salesforce</Text>
        </Menu.Item>
      ) : (
        <></>
      )}
      {hubspotConfig && hubspotConfig.status === '1' && (
        <Menu.Item onClick={() => updateSetAction('export-hb', undefined, { id })}>
          <img alt="hubspot" src={HubspotImg} />
          <Text style={{ color: '#5E81F4', marginLeft: '5px' }}>Hubspot</Text>
        </Menu.Item>
      )}
    </Menu>
  );

  const deleteOptions = (
    <Menu>
      <Menu.Item
        disabled={!selectedRowKeys.length}
        onClick={() => updateSetAction('delete', 'selected')}
      >
        <Text>{`Delete selected ${selectedRowKeys.length} item`}</Text>
      </Menu.Item>
      <Menu.Item disabled={!listItems.total} onClick={() => updateSetAction('delete', 'bulk')}>
        <Text>{`Delete all ${listItems.total} items`}</Text>
      </Menu.Item>
    </Menu>
  );

  const exportButtons = (numberOfItems, type) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            marginRight: '20px',
            width: '150px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Text style={{ color: '#29275F', fontSize: '14px' }}>
            {`Export ${type === 'selected' ? 'selected' : 'all'}`}
          </Text>
          <Text style={{ color: '#929CB7', fontSize: '12px' }}>
            {`${numberOfItems} ${numberOfItems > 1 ? 'items' : 'item'}`}
          </Text>
        </div>
        <Space>
          <Button
            style={{ background: '#E6F4FF', borderRadius: '3px', border: 'none' }}
            disabled={hubspotConfig?.disable_csv_export || !numberOfItems}
            onClick={() => updateSetAction('export-csv', type)}
          >
            <img alt="csv" src={CSVImg} />
            <Text style={{ color: '#5E81F4', marginLeft: '5px' }}>CSV</Text>
          </Button>
          {salesForceConfig &&
          salesForceConfig.isConnected &&
          salesForceConfig.isPackageInstalled ? (
            <Button
              style={{ background: '#E6F4FF', borderRadius: '3px', border: 'none' }}
              disabled={!numberOfItems}
              onClick={() => updateSetAction('export-sf', type)}
            >
              <img alt="salesforce" src={SalesforceImg} />
              <Text style={{ color: '#5E81F4', marginLeft: '5px' }}>Salesforce</Text>
            </Button>
          ) : (
            <></>
          )}
          {hubspotConfig && hubspotConfig.status === '1' && (
            <Button
              style={{ background: '#E6F4FF', borderRadius: '3px', border: 'none' }}
              disabled={!numberOfItems}
              onClick={() => updateSetAction('export-hb', type)}
            >
              <img alt="hubspot" src={HubspotImg} />
              <Text style={{ color: '#5E81F4', marginLeft: '5px' }}>Hubspot</Text>
            </Button>
          )}
        </Space>
      </div>
    );
  };

  const exportOptions = (
    <Menu>
      <Menu.Item>{exportButtons(selectedRowKeys.length, 'selected')}</Menu.Item>
      <Menu.Item>{exportButtons(listItems.total, 'bulk')}</Menu.Item>
    </Menu>
  );

  const hasMore = params.page * 25 < listItems.total;
  const onChange = (pagination, filter, sorter, extra) => {
    const payload = JSON.parse(JSON.stringify(params));
    if (sorter.order) {
      payload.key = sorter.field;
      payload.order = sorter.order === 'descend' ? 'desc' : 'asc';
    } else {
      payload.key = 'created_at';
      payload.order = 'desc';
    }
    if (hasMore || extra.action === 'paginate') {
      if (extra.action === 'paginate') {
        payload.page = pagination.current;
      } else {
        payload.page = 1;
        setSelectedRowKeys([]);
      }
      fetchListItems(payload);
    }
  };

  const handleOk = async (exportKeys) => {
    let selectedItems = [];
    let isBulk = false;
    if (action.type === 'bulk') {
      isBulk = true;
    } else if (action.type === 'single') {
      selectedItems.push(action.targetItem.id);
    } else if (action.type === 'selected') {
      selectedItems = selectedRowKeys;
    } else {
      return;
    }
    handleCancel();
    if (action.event === 'delete') {
      await deleteListItems(isBulk, selectedItems);
      const filteredSelectedRowKeys = selectedRowKeys.filter(
        (item) => !selectedItems.includes(item)
      );
      setSelectedRowKeys(filteredSelectedRowKeys);
    } else if (['export-hb', 'export-sf', 'export-csv'].includes(action.event)) {
      let expType = null;
      if (action.event === 'export-sf') {
        expType = leadExportType;
      }
      exportListItems(action.event, isBulk, selectedItems, expType, exportKeys);
    }
  };

  const handleCancel = () => {
    setAction({
      confirm: false,
      targetItem: {},
      type: '',
    });
  };

  const getIdWithoutListId = (idWithListId = '') => idWithListId.split('_')[1];

  const getColor = (record) => {
    return quickView.isQuickView && quickView.data?.id?.value === getIdWithoutListId(record.id)
      ? scssVariables.selectedQuickViewItem
      : scssVariables.slintelDarkBlue;
  };

  const openQuickViewHandler = (record, rowIndex, view = LEAD) => {
    let data;
    if (view === LEAD) {
      data = {
        id: { value: getIdWithoutListId(record.id) },
        index: rowIndex,
      };
    } else {
      data = {
        id: { value: record.id },
        company_id: { value: record.company_id },
      };
    }
    dispatch(openQuickView({ data, view }));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '200px',
      render: (text, record, rowIndex) => (
        <UILink
          type="text"
          text={
            <Tooltip placement="topLeft" title={text}>
              <Text style={{ maxWidth: '150px', color: getColor(record) }} ellipsis>
                {text}
              </Text>
            </Tooltip>
          }
          onClick={() => openQuickViewHandler(record, rowIndex)}
        />
      ),
      fixed: 'left',
    },
    {
      title: 'Job Title',
      dataIndex: 'lead_titles',
      key: 'lead_titles',
      width: '200px',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '300px',
      render: (text, record) => (
        <div>
          <div style={{ display: 'flex', alignItem: 'center', justifyContent: 'left' }}>
            {isCopyEnabled() ? (
              <Copy text={`${text}`} element={<Text>{`${text}`}</Text>} />
            ) : (
              <Text title={text} ellipsis>
                {text}
              </Text>
            )}
            <span style={{ marginLeft: '8px' }}>
              <img src={getEmailBucketIcon(record.email_score)} alt="" />
            </span>
          </div>
        </div>
      ),
    },
    {
      title: 'Phones',
      dataIndex: 'phone',
      key: 'phone',
      width: '200px',
      render: (text, record) => {
        const phone = record?.phone || record?.work_phone || '';
        if (phone) {
          return isCopyEnabled() ? (
            <Copy text={`${phone}`} element={<Text>{`${phone}`}</Text>} />
          ) : (
            <span className="remove-mouse-selection">{phone}</span>
          );
        }
        return null;
      },
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
      width: '200px',
      sorter: hasMore
        ? true
        : (a, b) => {
            if (a.company_name < b.company_name) {
              return -1;
            }
            if (a.company_name > b.company_name) {
              return 1;
            }
            return 0;
          },
      render: (text, record, rowIndex) => (
        <UILink
          type="text"
          text={
            <Tooltip placement="topLeft" title={text}>
              <Text style={{ maxWidth: '150px' }} ellipsis>
                {text}
              </Text>
            </Tooltip>
          }
          onClick={() => openQuickViewHandler(record, rowIndex, COMPANY)}
        />
      ),
    },
    {
      title: 'Company Website',
      dataIndex: 'company_website',
      key: 'company_website',
      width: '200px',
      render: (text, record) => (
        <Link
          style={{ color: '#5753B5' }}
          href={`//${record.company_website}`}
          key={record.company_website}
          target="_blank"
        >
          {record.company_website}
        </Link>
      ),
    },
    {
      title: 'Company Phones',
      dataIndex: 'company_phone_numbers',
      key: 'company_phone_numbers',
      width: '200px',
      render: (text) => <span className="remove-mouse-selection">{text}</span>,
    },
    {
      title: 'Division',
      dataIndex: 'lead_divison',
      key: 'lead_divison',
      width: '200px',
    },
    // {
    //   title: 'Decision Making Power',
    //   dataIndex: 'decision_making_power',
    //   key: 'decision_making_power',
    //   width: '200px',
    // },
    {
      title: 'Company Location',
      dataIndex: 'company_location',
      key: 'company_location',
      width: '200px',
      render: (text) => (text && Array.isArray(text) ? text.filter((t) => t).join(', ') : ''),
    },
    {
      title: 'People Location',
      dataIndex: 'lead_location',
      key: 'lead_location',
      width: '250px',
      render: (text) => (text && Array.isArray(text) ? text.filter((t) => t).join(', ') : ''),
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: '140px',
      className: 'action-btn-holder',
      render: (text, record, index) => (
        <div className="actions-btn">
          <Dropdown
            getPopupContainer={() => document.getElementsByClassName('list-item-block')[index]}
            overlay={() => {
              return singleExportOptions(record.id);
            }}
            placement="bottomLeft"
            arrow
          >
            <Button className="export-btn">Export</Button>
          </Dropdown>
          <Button
            onClick={() => updateSetAction('delete', 'single', { id: record.id })}
            className="delete-btn"
            icon={<DeleteOutlined style={{ fill: '#5E81F4' }} />}
            type="link"
          />
        </div>
      ),
    },
  ];

  const onExportTypeChange = (e) => {
    setLeadExportType(e.target.value);
  };

  const getSalesforceConfirmsection = () => {
    return (
      <div className="export-option-container">
        <Text className="head">
          {action.type === 'bulk' ? listItems.total : selectedRowKeys.length} Selected Record(s)
        </Text>
        <Text className="sub-head" type="secondary">
          Push to Salesforce As
        </Text>
        <Radio.Group
          className="radio-group-sl"
          onChange={onExportTypeChange}
          value={leadExportType}
        >
          {EXPORT_LEADS_OPTIONS.map((item) => {
            return (
              <Radio
                className="radio-btn-sl"
                key={item.value}
                value={item.value}
                disabled={![item.value, 3].includes(salesForceConfig.lead_export_type)}
              >
                {item.label}
                {![item.value, 3].includes(salesForceConfig.lead_export_type) && (
                  <div className="help-text">
                    <img alt="alert" src={AlertIcon} />
                    <Text className="small-text" type="secondary">
                      {user.user_access_role === 1
                        ? 'Update Salesforce people export configuration for Slintel to export as'
                        : 'Reach out to your company admin for Slintel to export as'}{' '}
                      {item.label}
                    </Text>
                  </div>
                )}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
    );
  };

  const confirmProps = {
    title: 'Delete Item(s)',
    desc: 'Are you sure you want to delete these people?',
  };

  const hasItems = () => {
    return listItems && Array.isArray(listItems.items) && listItems.items.length > 0;
  };

  if (action.confirm) {
    if (action.event === 'export-hb') {
      confirmProps.title = 'Export to Hubspot';
      confirmProps.desc = 'Are you sure you want to export the item(s)?';
    } else if (action.event === 'export-sf') {
      confirmProps.title = 'Export to Salesforce';
      // confirmProps.desc = 'Are you sure you want to export the item(s)?';
      confirmProps.desc = getSalesforceConfirmsection();
    } else if (action.event === 'export-csv') {
      confirmProps.title = 'Export to CSV';
      confirmProps.desc = 'Are you sure you want to export these people?';
    }
  }

  return (
    <>
      <ConfigProvider
        renderEmpty={() =>
          !loading && (
            <div className="list-item-table_empty">
              <NoItem text="No results found" />
            </div>
          )
        }
      >
        <Table
          style={{
            position: 'relative',
            height: '100%',
          }}
          className="list-item-table"
          rowSelection={rowSelection}
          rowClassName="list-item-block"
          columns={columns}
          loading={loading}
          dataSource={listItems.items}
          rowKey="id"
          total={listItems.total}
          onChange={onChange}
          pagination={{
            position: ['topRight'],
            style: { marginRight: '15px', marginBottom: '0' },
            current: params.page,
            total: listItems.total >= planConfig.total ? planConfig.total : listItems.total,
            showSizeChanger: false,
            pageSize: 25,
            showTotal: (total, range) => (
              <Text type="secondary">{`${range[0]}-${range[1]} of ${total}`}</Text>
            ),
            size: 'small',
          }}
          scroll={{
            y: '100%',
            x: 1300,
          }}
          showSorterTooltip
          title={() =>
            hasItems() && (
              <div className="action-btn-group">
                <Space>
                  <Dropdown overlay={exportOptions} placement="bottomLeft" arrow>
                    <Button
                      style={{
                        border: '1px solid #5e81f4',
                        borderRadius: '5px',
                        color: '#5e81f4',
                      }}
                    >
                      {' '}
                      Export
                    </Button>
                  </Dropdown>
                  <Dropdown overlay={deleteOptions} placement="bottomLeft" arrow>
                    <Button
                      style={{ background: '#E6F4FF' }}
                      className="delete-btn"
                      icon={<DeleteOutlined style={{ fill: '#5E81F4' }} />}
                    />
                  </Dropdown>
                </Space>
                <Confirm
                  visible={action.confirm && action.event !== 'export-csv'}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  okText={action.event === 'delete' ? 'Delete' : 'Export'}
                  titleText={confirmProps.title}
                  descText={confirmProps.desc}
                  width="400px"
                />
              </div>
            )
          }
        />
      </ConfigProvider>
      {action.confirm && action.event === 'export-csv' && (
        <ExportFieldSelection
          visible={action.confirm && action.event === 'export-csv'}
          onClose={handleCancel}
          onExport={handleOk}
          listType="lead"
          exportCount={listItems.total}
          isBulk={action.type === 'bulk'}
          selectedValues={{}}
          renderFrom="list"
        />
      )}
    </>
  );
};

LeadListItem.propTypes = {
  listItems: PropTypes.object.isRequired,
  fetchListItems: PropTypes.func,
  defaultPage: PropTypes.number,
  loading: PropTypes.bool,
  params: PropTypes.object,
  deleteListItems: PropTypes.func,
  exportListItems: PropTypes.func,
};

export default LeadListItem;

import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Select } from 'antd';
import './Dropdown.scss';

const Dropdown = (props) => {
  const [selectedItem, setSelectedItem] = useState();

  const {
    handleOnChange,
    dropdownType,
    optionList,
    placeholder,
    defaultValue,
    customStyle,
    disabled = false,
    optionLabelProp = '',
    mode = 'single',
  } = props;

  useEffect(() => {
    if (defaultValue) {
      let options = optionList;
      if (!Array.isArray(optionList)) {
        options = Object.keys(optionList).map((key) => {
          return { label: optionList[key].label, value: key };
        });
      }
      const item = options.find((a) => a.value === defaultValue.value);
      setSelectedItem(item);
    }
    if (Array.isArray(defaultValue)) {
      const items = optionList?.filter((o1) => defaultValue?.some((o2) => o1.value === o2.value));
      setSelectedItem(items);
    }
  }, [defaultValue]);

  const onChange = (value) => {
    setSelectedItem(value);
    handleOnChange(dropdownType, value);
  };

  // const onBlur = () => {
  //   // console.log('blur');
  // };

  // const onFocus = () => {
  //   // console.log('focus');
  // };

  // const onSearch = (val) => {
  //   // console.log('search:', val);
  // };

  let filteredOptions = optionList;
  if (!Array.isArray(optionList)) {
    filteredOptions = Object.keys(optionList).map((key) => {
      return {
        label: optionList[key].label,
        value: key,
        disabled: optionList[key].disabled || false,
      };
    });
  }

  if (selectedItem) {
    filteredOptions = filteredOptions.filter((a) => a.value !== selectedItem.value);
  }

  return (
    <Select
      // required to prevent 'floating' on scroll
      getPopupContainer={(trigger) => trigger.parentNode}
      dropdownStyle={{ backgroundColor: '#ffffff' }}
      showSearch
      style={{ height: '36px', width: '200px', ...customStyle }}
      className="custom-select-dropdown"
      placeholder={placeholder || 'Select from dropdown'}
      onChange={onChange}
      // onFocus={onFocus}
      // onBlur={onBlur}
      // onSearch={onSearch}
      optionLabelProp={optionLabelProp}
      labelInValue
      value={selectedItem}
      options={filteredOptions}
      disabled={disabled}
      mode={mode}
    />
  );
};

Dropdown.defaultProps = {
  disabled: false,
};

Dropdown.propTypes = {
  dropdownType: PropTypes.string,
  defaultValue: PropTypes.object,
  optionList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  handleOnChange: PropTypes.func,
  placeholder: PropTypes.string,
  customStyle: PropTypes.object,
  disabled: PropTypes.bool,
};

export default Dropdown;

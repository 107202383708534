import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CustomAlert, CustomWarningAlert } from 'UI/CustomAlert/CustomAlert';
import Drawer from 'UI/CustomDrawer/CustomDrawer';
import DrawerTitle from 'UI/CustomDrawer/DrawerTitle';
import IntegrationService from 'api/services/integrationService';
import { useSelector, useDispatch } from 'react-redux';
import { ExportProgress } from 'UI/CustomMessages/CustomMessages';
import { getLoggedInUser } from 'utils/common';
import { eventManager, eventList, eventActions } from 'utils/eventManager';
import { clearCheckedItems } from 'containers/discovery/discoveryActions';

const ExportHubspot = ({
  crm,
  setSelectedOption,
  getSearchParams,
  tab,
  selectedIds = [],
  totalSelected,
  source = '',
  id,
}) => {
  const { credits } = useSelector((state) => state.common);
  const userInfo = getLoggedInUser();
  const { userId, userEmail } = userInfo;
  const hubspotConfig = useSelector((state) => state.common.hubspotConfig) || {};
  const { defaultInnerActiveKey } = useSelector((state) => state.discovery);
  const { api_url: hubspotApiUrl } = hubspotConfig;
  const totalCount = totalSelected;
  const hasCredits = credits && credits.credits_left >= totalCount;
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const exportToHubspot = async () => {
    const params = {
      userId,
      hubspotApiUrl,
      userEmail,
      scheduleAt: new Date().getTime(),
      export_count: totalCount,
      filter: {},
      leadId: id ? [id] : selectedIds,
    };

    try {
      const reqData = {
        data: params,
        type: 'EXPORT_HB',
        metadata: getSearchParams(tab),
        export_count: totalCount,
        entity: tab,
      };
      setIsLoading(true);
      await IntegrationService.exportAddtoListScheduler(reqData);
      setIsLoading(false);
      ExportProgress({
        key: `${crm}-${userId}`,
        duration: 0,
        msg: 'Your HubSpot export is in progress. This can take up to a few minutes, and we will notify you once the export is done.',
      });
      dispatch(clearCheckedItems());
      setSelectedOption('');
      eventManager.track(eventList.EXPORT_TRIGGERED, {
        payload: {
          tab,
          export_type: eventActions.HUBSPOT,
          navigation: defaultInnerActiveKey,
          payload: getSearchParams(tab),
          version: eventActions.NDF,
          export_source: source,
        },
      });
    } catch (ex) {
      // console.log('🚀 ~ file: ExportHubspot.jsx ~ line 43 ~ exportToHubspot ~ ex', ex);
    }
  };

  return (
    <div className="ExportHubspotWrapper">
      <Drawer
        content={
          <DrawerContent hasCredits={hasCredits} message={`${totalCount} records selected`} />
        }
        title={
          <DrawerTitle
            title="PUSH TO HUBSPOT"
            actions={[
              {
                title: 'Cancel',
                theme: 'secondary',
                disabled: isLoading,
                onClick: () => {
                  setSelectedOption('');
                },
              },
              {
                title: 'Confirm',
                loading: isLoading,
                theme: 'primary',
                size: 'medium',
                disabled: !hasCredits || !totalCount,
                onClick: () => {
                  exportToHubspot();
                },
              },
            ]}
          />
        }
        visible={crm === 'hubspot'}
        width={600}
      />
    </div>
  );
};
const DrawerContent = ({ hasCredits, ...props }) => {
  return (
    <>
      <CustomAlert {...props} />
      {!hasCredits ? (
        <CustomWarningAlert message="Insufficient credits for export. Talk to your Customer Success Manager to get credits" />
      ) : null}
    </>
  );
};

ExportHubspot.propTypes = {
  crm: PropTypes.string,
  setSelectedOption: PropTypes.func,
  tab: PropTypes.string.isRequired,
  selectedIds: PropTypes.array,
  getSearchParams: PropTypes.func,
  totalSelected: PropTypes.number,
  source: PropTypes.string,
};

export default React.memo(ExportHubspot);

import React from 'react';
import { Progress } from 'antd';
import scssVariables from '../../_vars.scss';

const useProgress = (
  credits,
  size = 40,
  trailColor = scssVariables.trialDefault,
  strokeColor = scssVariables.strokeDefault
) => {
  let percent = 0;
  if (credits && credits.credits_used !== undefined && credits.credits_used !== null) {
    const { credits_used: creditsUsed, credits_left: creditsLeft } = credits;
    percent = parseInt(
      (parseInt(creditsLeft, 10) * 100) /
        (parseInt(creditsLeft, 10) + parseInt(creditsUsed, 10)).toFixed(3),
      10
    );
  }
  let fillColor = strokeColor;
  if (percent < 10) {
    fillColor = scssVariables.red;
  } else if (percent >= 10 && percent <= 40) {
    fillColor = scssVariables.orange;
  } else {
    fillColor = scssVariables.green;
  }
  return (
    <Progress
      width={size}
      strokeWidth={27}
      strokeColor={fillColor}
      strokeLinecap="circle"
      format={() => ''}
      percent={percent}
      status="active"
      className="credit-card__content-icon"
      type="circle"
      trailColor={trailColor}
    />
  );
};

export default useProgress;

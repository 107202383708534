import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Multiselect from 'components/filters/multipleSelect';
import './DynamicCombobox.scss';
import { debounce } from 'utils/common';
import { DEBOUNCE_WAIT } from 'constants/constants';

const DynamicCombobox = ({
  type,
  value,
  onChange,
  optionList = [],
  handleRequest,
  loading,
  resetDropdownOptions,
  placeHolder,
  ...props
}) => {
  const [selectedValues, setSelected] = useState([]);
  const [isPlaceholder, setIsPlaceholder] = useState(!placeHolder);
  const [newOptions, setNewOptions] = useState([]);

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  useEffect(() => {
    setNewOptions(optionList);
  }, [optionList]);

  const handleChange = (values) => {
    setSelected(values);
    setIsPlaceholder(true);
    onChange(values);
  };

  // eslint-disable-next-line no-shadow
  const handleSearch = async (value) => {
    if (!value) {
      return;
    }
    handleRequest(value);
  };
  const debounceHandleSearch = useMemo(() => debounce(handleSearch, DEBOUNCE_WAIT), []);

  const handlePlaceholder = (v) => {
    if (v) {
      setIsPlaceholder(false);
    } else {
      setIsPlaceholder(true);
    }
  };

  return (
    <div className="slintel-dynamic-combobox">
      {isPlaceholder && <span className="custom-placeholder">Search</span>}
      <Multiselect
        {...props}
        autoClearSearchValue
        allowClear
        placeholder={placeHolder ?? ''}
        value={selectedValues}
        type={type}
        onChange={handleChange}
        style={{ width: '100%' }}
        options={newOptions}
        handleSearch={(v) => {
          handlePlaceholder(v);
          debounceHandleSearch(v);
        }}
        loading={loading}
        resetDropdownOptions={resetDropdownOptions}
        filterOption={false}
      />
    </div>
  );
};

DynamicCombobox.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  placeHolder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  optionList: PropTypes.array,
  handleRequest: PropTypes.func,
  resetDropdownOptions: PropTypes.func,
  loading: PropTypes.bool,
  value: PropTypes.array,
};

DynamicCombobox.defaultProps = {
  options: [
    { label: 'Option1', value: 'Option1' },
    { label: 'Option2', value: 'Option2' },
  ],
  placeHolder: '',
  type: 'key-value',
  onChange: () => {},
  optionList: [],
  handleRequest: () => {},
  loading: false,
  value: [],
};

export default DynamicCombobox;

import React from 'react';

const RowBlockContainer = ({ blocks, centerBlockNumber, equalRows = 3 }) => {
  return (
    <div className="row block-container gap-2">
      {blocks
        .filter((x) => !!x?.props?.validator)
        .map((block, index) => {
          const isCenterBlock = centerBlockNumber && (index + 1) % centerBlockNumber === 0;
          return (
            <>
              <div
                key={block.key}
                className={`col ${block.props.blockClassName || ''} ${
                  isCenterBlock ? 'center-block' : ''
                }`}
              >
                <div className="p-4">{block}</div>
              </div>
              {(index + 1) % equalRows === 0 ? <div className="w-100" /> : null}
            </>
          );
        })}
    </div>
  );
};
export default RowBlockContainer;

/* eslint-disable import/no-unresolved */

import React, { Component } from 'react';
import { ConfigProvider, Button, Popover, Table, Space, Menu, Dropdown } from 'antd';
import {
  PlusOutlined,
  CaretUpOutlined,
  MinusOutlined,
  CaretDownOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import ShowTotal from 'UI/ShowTotal';
import BuyingSignal from '../../buying-signals/src/buying-signals';
import CompanyAvatarInfo from '../../company-avatar-info/src/company-avatar-info';
import EmptyCompaniesList from './empty-companies-list';
import './companies-list.scss';

const BUYING_SIGNALS_ORDER = [
  'funding',
  'jobs',
  'size',
  'renewal',
  'technology',
  'technologyCategory',
];

export default class CompaniesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
    };
  }

  handleViewDetailsClick = (record, index) => {
    const { openQuickView } = this.props;
    const { company } = record;
    const data = {
      id: { value: company?.id },
      name: company?.name,
      index,
    };
    openQuickView({ data, view: 'company' });
  };

  allColumns = () => {
    // TODO: evolve this into interface to select columns and their order.
    return [
      this.companyColumn(),
      this.scoreColumn(),
      this.buyingSignalsColumn(),
      this.actionsColumn(),
    ];
  };

  // the columns render keyed companies data, from companies-to-target-feed
  companyColumn = () => {
    const { oldDashboardURL, openQuickView, activeCompanyId } = this.props;
    return {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      className: 'companies-list__company-details',
      width: 450,
      render: (company, _, rowIndex) => (
        <CompanyAvatarInfo
          company={company}
          oldDashboardURL={oldDashboardURL}
          openQuickView={openQuickView}
          rowIndex={rowIndex}
          activeCompanyId={activeCompanyId}
        />
      ),
    };
  };

  scoreColumn = () => {
    return {
      title: 'Score',
      dataIndex: 'propensity',
      key: 'propensity',
      width: 200,
      render: (propensity) => {
        return (
          <div className="companies-list__score">
            <Popover
              overlayClassName="propensity-poover"
              placement="bottom"
              content={propensity.brief}
            >
              {propensity.score}
              {propensity.change > 0 && (
                <div className="companies-list__score-change companies-list__score-change--positive">
                  <PlusOutlined style={{ fontSize: '10px' }} />
                  {propensity.change}
                  <CaretUpOutlined />
                </div>
              )}
              {propensity.change < 0 && (
                <div className="companies-list__score-change companies-list__score-change--negative">
                  <MinusOutlined style={{ fontSize: '10px' }} />
                  {propensity.change * -1}
                  <CaretDownOutlined />
                </div>
              )}
              {propensity.change === 0 && (
                <div className="companies-list__score-change companies-list__score-change--same">
                  <MinusOutlined />
                </div>
              )}
            </Popover>
          </div>
        );
      },
      sorter: (a, b) => {
        return a.propensity.score - b.propensity.score;
      },
    };
  };

  buyingSignalsColumn = () => {
    const { preferencesDrawerToggle } = this.props;
    return {
      title: (
        <div>
          Buying Signal Strength&nbsp;
          <Popover
            overlayClassName="propensity-poover"
            placement="bottom"
            content={() => {
              return (
                <div>
                  Six buying signals contribute to a company’s intent score. They are: Funding, Open
                  Job Postings, Company Size, Contract Renewal Timeline, E-commerce spend, and Usage
                  of Other Ecosystem Products
                </div>
              );
            }}
          >
            <InfoCircleOutlined />
          </Popover>
        </div>
      ),
      key: 'Strength of buying signals',
      dataIndex: 'buyingSignals',
      width: 350,
      render: (buyingSignals) => {
        // FIXME This code is crying DRY!!!
        return (
          <>
            {BUYING_SIGNALS_ORDER.map((val) => {
              return (
                <BuyingSignal
                  signalStrength={buyingSignals[val]}
                  signalType={val}
                  subcategory={buyingSignals.subcategory}
                  subcategoryLabel={buyingSignals.subcategoryLabel}
                  category={buyingSignals.category}
                  key={val}
                  threshold={buyingSignals.minimumScoresThreshold[val]}
                  addPreferencesDrawer={preferencesDrawerToggle}
                />
              );
            })}
          </>
        );
      },
    };
  };

  actionsColumn = () => {
    return {
      key: 'action',
      dataIndex: 'company',
      render: (_, company, index) => {
        return (
          <div className="companies-list__action">
            <Button
              type="primary"
              ghost
              className="companies-list__action--view-leads"
              onClick={() => this.handleViewDetailsClick(company, index)}
            >
              View Details
            </Button>
            {/* <MoreOutlined className="companies-list__action--view-more" /> */}
          </div>
        );
      },
      align: 'right',
    };
  };

  onCompaniestTableChange = (pagination, _, sorter, extra) => {
    const { closeQuickView, fetchCompanies } = this.props;
    // sorter.order = ascend | descend
    const sortOrder = sorter?.order === 'ascend' ? 'asc' : 'desc';
    const action = extra?.action;
    // extra.action: paginate | sort | filter
    if (action !== 'paginate') {
      closeQuickView();
    }
    fetchCompanies(pagination.current, pagination.pageSize, sortOrder);
  };

  onSelectChange = (newSelectedKeys, selectedRows) => {
    const { setCheckedItems } = this.props;
    this.setState({
      selectedRowKeys: newSelectedKeys,
    });
    setCheckedItems(selectedRows);
  };

  addToListOptions = () => {
    const { selectedRowKeys } = this.state;
    const { totalDataUnits, addToListDrawerToggle } = this.props;
    return (
      <Menu>
        <Menu.Item disabled={!selectedRowKeys.length} onClick={() => addToListDrawerToggle(false)}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                marginRight: '20px',
                width: '150px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Text style={{ color: '#29275F', fontSize: '14px' }}>Add selected</Text>
              <Text
                style={{
                  color: '#929CB7',
                  fontSize: '12px',
                }}
              >{`${selectedRowKeys.length} item(s)`}</Text>
            </div>
          </div>
        </Menu.Item>
        <Menu.Item disabled={!totalDataUnits} onClick={() => addToListDrawerToggle(true)}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                marginRight: '20px',
                width: '150px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Text style={{ color: '#29275F', fontSize: '14px' }}>Add all</Text>
              <Text
                style={{ color: '#929CB7', fontSize: '12px' }}
              >{`${totalDataUnits} item(s)`}</Text>
            </div>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  render() {
    const { selectedRowKeys } = this.state;
    const {
      companies,
      totalDataUnits,
      preferencesDrawerToggle,
      identifier,
      // mixpanel,
      type,
      pageSize,
      page = 1,
    } = this.props;
    const columns = this.allColumns();
    const rowSelection = {
      selectedRowKeys,
      preserveSelectedRowKeys: true,
      onChange: this.onSelectChange,
    };

    return (
      <ConfigProvider
        renderEmpty={() => (
          <EmptyCompaniesList
            preferencesOrFilter={type}
            togglePrefsDrawer={preferencesDrawerToggle}
          />
        )}
      >
        <div className="companies-list">
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={companies}
            pagination={{
              responsive: true,
              total: totalDataUnits,
              current: page,
              position: ['topRight'],
              style: { marginRight: '15px' },
              showSizeChanger: false,
              showTotal: (total, range) => <ShowTotal total={total} range={range} />,
              pageSize,
              size: 'small',
            }}
            className={`${identifier} companies-list`}
            scroll={{ y: 300 }}
            onChange={this.onCompaniestTableChange}
            title={() => (
              <div
                style={{
                  top: '-48px',
                  position: 'absolute',
                }}
              >
                {totalDataUnits > 0 && (
                  <Space>
                    <Dropdown
                      getPopupContainer={(trigger) => trigger.parentNode}
                      overlay={this.addToListOptions}
                      placement="bottomLeft"
                      arrow
                    >
                      <Button type="primary" ghost>
                        Add to Smart List
                      </Button>
                    </Dropdown>
                  </Space>
                )}
              </div>
            )}
          />
        </div>
      </ConfigProvider>
    );
  }
}

CompaniesList.defaultProps = {
  mixpanel: null,
};

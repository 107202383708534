import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toggleUpgradeModal } from 'containers/common/commonActions';
import { LockedIcon } from '../CustomIcon/CustomIcon';
import './UpgradePlanLock.scss';

const UpgradePlanLock = ({ isVisible }) => {
  const dispatch = useDispatch();

  const openUpgradeModal = () => {
    dispatch(toggleUpgradeModal(true));
  };

  return (
    isVisible && (
      <LockedIcon
        onClick={openUpgradeModal}
        width="16"
        height="16"
        className="upgrade-lock__icon"
      />
    )
  );
};

UpgradePlanLock.propTypes = {
  isVisible: PropTypes.bool,
};

UpgradePlanLock.defaultProps = {
  isVisible: false,
};

export default UpgradePlanLock;

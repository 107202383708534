/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */

import { formatFilters } from '../common';

export const getSearchQueryParams = (appliedFilters, query, selectionParam, props = {}) => {
  const { page = 1, pageSize = 25, sort = '', order = 'asc', fields, innerTab = 'total' } = query;

  const filters = formatFilters(appliedFilters);

  const queryParams = {
    fields,
    filters,
    pagination: {
      page,
      size: pageSize,
    },
  };
  if (sort) {
    queryParams.sort = [
      {
        key: sort,
        order,
      },
    ];
  }
  if (selectionParam.searchType && selectionParam.initialSize) {
    queryParams.search_type = selectionParam.searchType;
    queryParams.initial_size = selectionParam.initialSize;
    if (selectionParam.leadsPerCompany) {
      queryParams.leads_per_company = selectionParam.leadsPerCompany;
    }

    const finalUserSelection = [];
    for (const [key, value] of Object.entries(selectionParam.userSelection)) {
      if (key === 'add') {
        finalUserSelection.push(...value);
      } else {
        finalUserSelection.push(...value.map((v) => `!${v}`));
      }
    }
    if (finalUserSelection?.length) {
      queryParams.user_selection = finalUserSelection;
    }
  }
  if (innerTab === 'net_new') {
    queryParams.is_net_new = true;
  }
  return queryParams;
};

export const getIdsToBeSearched = (navigatedPage, data, pageSize) => {
  const startIndex = pageSize * (navigatedPage - 1);
  const endIndex = pageSize * navigatedPage;
  return data?.ids ? data.ids.slice(startIndex, endIndex) : null;
};

export const getNetNewQueryParams = (
  queryParams,
  userId,
  data,
  isJustPageChange,
  PAGE,
  pagination
) => {
  let queryParam = {
    ...queryParams,
    user_id: userId,
    ids: null,
    totalIds: data.ids,
  };
  if (isJustPageChange) {
    queryParam = {
      ...queryParam,
      ids: !queryParams.initial_size
        ? getIdsToBeSearched(PAGE, data, pagination.pageSize)
        : data.ids,
      size: 25,
    };
  }
  return queryParam;
};

export const checkSortOrderChange = (oldobj, selectedSort, selectedOrder) => {
  if (selectedSort && selectedSort.length > 0 && selectedOrder && selectedOrder.length > 0) {
    const isOrderEqual =
      (oldobj.order === 'ascend' && selectedOrder[0] === 'asc') ||
      (oldobj.order === 'descend' && selectedOrder[0] === 'desc');
    if (!isOrderEqual) {
      return true;
    }
    if (oldobj.column.key === selectedSort[0]) {
      return false;
    }
    if (oldobj.column.key === 'name' && selectedSort[0] === 'name_keyword') {
      return false;
    }
    if (oldobj.column.key === 'company_name' && selectedSort[0] === 'company_name_keyword') {
      return false;
    }
    if (oldobj.column.key === 'company_size' && selectedSort[0] === 'company_size_key') {
      return false;
    }
  }
  return true;
};

export const sanitizeSearchString = (url) => {
  try {
    const domain = new URL(url).host;
    return domain.replace('www.', '');
  } catch (_) {
    return url.replace('www.', '');
  }
};

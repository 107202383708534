import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button } from 'antd';
import './TechnographicsContent.scss';
import TechnographicsCard from '../TechnographicsCard/TechnographicsCard';

const TechnographicsContent = ({ data, title, type, onlyCard = false }) => {
  if (onlyCard) {
    return <TechnographicsCard data={data} title={title} type={type} />;
  }
  return (
    <Dropdown
      key={title}
      overlay={<TechnographicsCard data={data} title={title} type={type} />}
      placement="topLeft"
    >
      <Button key={title} type="secondary" size="small" className="technographic-pills">
        {title}
      </Button>
    </Dropdown>
  );
};

TechnographicsContent.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string,
};

TechnographicsContent.defaultProps = {
  data: {},
  title: '',
  type: '',
};

export default TechnographicsContent;

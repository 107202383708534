import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import './upgradeButton.scss';
import { useDispatch } from 'react-redux';
import { toggleUpgradeModal } from 'containers/common/commonActions';

const UpgradeButton = ({ permission = { showUpgrade: false } }) => {
  const dispatch = useDispatch();
  const { showUpgrade, theme } = permission;

  const getClassName = () => {
    switch (theme) {
      case 'blue':
        return 'upgrade-blue';
      case 'red':
        return 'upgrade-red';
      case 'yellow':
        return 'upgrade-yellow';
      default:
        return '';
    }
  };

  return showUpgrade ? (
    <div className="upgrade-button">
      {' '}
      <Button
        type="primary"
        className={getClassName()}
        onClick={() => dispatch(toggleUpgradeModal(true))}
      >
        Upgrade
      </Button>
    </div>
  ) : (
    <></>
  );
};

UpgradeButton.propTypes = {
  permission: PropTypes.object,
  onClick: PropTypes.func,
};

export default UpgradeButton;

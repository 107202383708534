import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown, Space } from 'antd';
import Button from 'UI/Button';
import { MenuIcon, ReportIcon, JobPostingIcon, EmployeeIcon } from 'UI/CustomIcon/CustomIcon';
import './ExtraActionPopover.scss';

const ExtraActionPopover = ({ menuItems, handleClick, viewName, socialLinks }) => {
  const onclick = (item) => {
    handleClick(item.title);
  };

  const menu = (
    <Menu>
      {socialLinks && <Menu.Item> {socialLinks} </Menu.Item>}
      {menuItems.map((item) => {
        if (viewName === 'lead' && !['View People', 'View Jobs'].includes(item.title)) {
          return (
            <Menu.Item key={item.title} onClick={() => onclick(item)}>
              <Space size={10}>
                <item.icon fill="#697390" width="15" height="15" />
                <span className="menu-item-text">{item.title}</span>
              </Space>
            </Menu.Item>
          );
        }
        if (viewName === 'company' && !item.isDisabled) {
          return (
            <Menu.Item key={item.title} onClick={() => onclick(item)}>
              <Space size={10}>
                <item.icon fill="#697390" width="15" height="15" />
                <span className="menu-item-text">{item.title}</span>
              </Space>
            </Menu.Item>
          );
        }
        return null;
      })}
    </Menu>
  );

  return (
    <div className="slintel-extra-action-popover">
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        getPopupContainer={(trigger) => (trigger ? trigger.parentElement : trigger)}
      >
        <Button
          theme="secondary"
          size="middle"
          style={{ backgroundColor: '#E9F6FE', border: 'none' }}
          icon={<MenuIcon fill="#4A6EE5" width={15} height={15} />}
        />
      </Dropdown>
    </div>
  );
};

ExtraActionPopover.propTypes = {
  menuItems: PropTypes.array,
  // handleClick: PropTypes.func,
  // viewName: PropTypes.string,
};

ExtraActionPopover.defaultProps = {
  menuItems: [
    // { icon: UnfollowIcon, title: 'Unfollow' },
    // { icon: ShareIcon, title: 'Share' },
    { icon: EmployeeIcon, title: 'View People' },
    { icon: JobPostingIcon, title: 'View Jobs' },
    { icon: ReportIcon, title: 'Report' },
  ],
};

export default ExtraActionPopover;

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCreditInfo } from './commonActions';
import Common from './commonIndex';

const mapStateToProps = (state) => {
  return {
    credits: state.common.credits,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCreditInfo: (params, headers) => dispatch(getCreditInfo(params, headers)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Common));

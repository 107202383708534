import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';
import { ReactComponent as SLogo } from 'assets/svg/slintel-icon-dark.svg';
import { ReactComponent as Lead } from 'assets/svg/Group 5137.svg';
import { ReactComponent as Enrichment } from 'assets/svg/Group 5138.svg';
import { ReactComponent as BranchInsight } from 'assets/svg/Group 5234.svg';
import { ReactComponent as Search } from 'assets/svg/slintel-icon-search.svg';
import { ReactComponent as SourceLink } from 'assets/svg/Group 5207.svg';
import { ReactComponent as ShareLink } from 'assets/svg/Group 5184.svg';
import { ReactComponent as Info } from 'assets/svg/Group 5179.svg';
import { ReactComponent as Ellipsis } from 'assets/svg/Group 5175.svg';
import { ReactComponent as OkSign } from 'assets/svg/oksign.svg';
import { ReactComponent as Person } from 'assets/svg/Group 5246.svg';
import { ReactComponent as NoItems } from 'assets/svg/Group 5300.svg';
import { ReactComponent as RightArrow } from 'assets/svg/Path 805.svg';
import { ReactComponent as EventsTheme } from 'assets/svg/Group 5315.svg';
import { ReactComponent as GrowthTheme } from 'assets/svg/Group 5348.svg';
import { ReactComponent as AcquisitionTheme } from 'assets/svg/Group 5318.svg';
import { ReactComponent as TechnologiesTheme } from 'assets/svg/Group 5320.svg';
import { ReactComponent as ExpansionTheme } from 'assets/svg/Group 5322.svg';
import { ReactComponent as LeadershipChangesTheme } from 'assets/svg/Group 5324.svg';
import { ReactComponent as FundingTheme } from 'assets/svg/Group 5326.svg';
import { ReactComponent as IPOTheme } from 'assets/svg/Group 5328.svg';
import { ReactComponent as PartnershipsTheme } from 'assets/svg/Group 5330.svg';
import { ReactComponent as InstaSuccess } from 'assets/svg/Group 13140.svg';
import { ReactComponent as InstaProcess } from 'assets/svg/Group 13205.svg';
import { ReactComponent as InstaError } from 'assets/svg/Group 13184.svg';
import {
  ReactComponent as ProductLaunchesTheme,
  ReactComponent as Plus,
} from 'assets/svg/Path 4212.svg';
import { ReactComponent as LeadUpload } from 'assets/svg/lead_upload.svg';
import { ReactComponent as LeadEnrich } from 'assets/svg/lead_enrich.svg';
import { ReactComponent as LeadDiscover } from 'assets/svg/lead_discover.svg';
import { ReactComponent as LeadNon } from 'assets/svg/lead_non.svg';
import { ReactComponent as Edit } from 'assets/svg/Group 5197.svg';
import { ReactComponent as Download } from 'assets/svg/Group 5198 copy.svg';
import { ReactComponent as LeadEnrichment } from 'assets/svg/Group 5362.svg';
import { ReactComponent as AddCompaniesFromSlintel } from 'assets/svg/addCompaniesFromSlintel.svg';
import { ReactComponent as CompanyEnrichment } from 'assets/svg/companyEnrichment.svg';
import { ReactComponent as Csv } from 'assets/svg/Group 6653.svg';
import { ReactComponent as Overview } from 'assets/svg/Group 6638.svg';
import { ReactComponent as Salesforce } from 'assets/svg/salesforce_icon.svg';
import { ReactComponent as Hubspot } from 'assets/svg/hubspot_icon.svg';
import { ReactComponent as Pipedrive } from 'assets/svg/Group 6650.svg';
import { ReactComponent as OtherIcon } from 'assets/svg/otherFilterIcon.svg';
import { ReactComponent as CircleIcon } from 'assets/svg/circle.svg';
import { ReactComponent as Locked } from 'assets/svg/lock-01.svg';
import { ReactComponent as UpAarrow } from 'assets/svg/up-arrow-01.svg';
import { ReactComponent as Note } from 'assets/svg/alert-01.svg';
import { ReactComponent as BBucket } from 'assets/svg/Group 8239.svg';
import { ReactComponent as ABucket } from 'assets/svg/Group 8238.svg';
import { ReactComponent as APlusBucket } from 'assets/svg/Group 8237.svg';
import { ReactComponent as Discover } from 'assets/svg/slintel-icon-discover.svg';
import { ReactComponent as SmartList } from 'assets/svg/slintel-icon-lists.svg';
import { ReactComponent as Company } from 'assets/svg/slintel-icon-company.svg';
import { ReactComponent as Psychographic } from 'assets/svg/slintel-icon-Psychographic.svg';
import { ReactComponent as Technology } from 'assets/svg/slintel-icon-technology.svg';
import { ReactComponent as Dashboard } from 'assets/svg/slintel-icon-dashboard.svg';
import { ReactComponent as CompanyTab } from 'assets/svg/slintel-icon-company-dark.svg';
import { ReactComponent as NewsFeed } from 'assets/svg/slintel-icon-newsfeeds.svg';
import { ReactComponent as LeadTab } from 'assets/svg/slintel-icon-lead-dark.svg';
import { ReactComponent as DefaultLead } from 'assets/svg/Group 12943.svg';
import { ReactComponent as DefaultCompany } from 'assets/svg/Group 13044.svg';
import { ReactComponent as AddFilterNotif } from 'assets/svg/Group 13040.svg';
import { ReactComponent as JobPosting } from 'assets/svg/slintel-icon-job.svg';
import { ReactComponent as Funding } from 'assets/svg/slintel-icon-funding.svg';
import { ReactComponent as NextArrow } from 'assets/svg/slintel-icon-arrow-next-dark.svg';
import { ReactComponent as PreviousArrow } from 'assets/svg/slintel-icon-arrow-previous-dark.svg';
import { ReactComponent as UpArrow } from 'assets/svg/slintel-icon-arrow-up-dark.svg';
import { ReactComponent as DownArrow } from 'assets/svg/slintel-icon-arrow-down-big.svg';
import { ReactComponent as DownArrowSmall } from 'assets/svg/slintel-icon-arrow-down-small.svg';
import { ReactComponent as UpArrowSmall } from 'assets/svg/slintel-icon-arrow-up-small.svg';
import { ReactComponent as LocationIconDark } from 'assets/svg/slintel-icon-location.svg';
import { ReactComponent as AddToList } from 'assets/svg/slintel-icon-add-to-list.svg';
import { ReactComponent as Filter } from 'assets/svg/slintel-icon-filter-dark.svg';
import { ReactComponent as Export } from 'assets/svg/export.svg';
import { ReactComponent as SortDescending } from 'assets/svg/slintel-icon-descending-order.svg';
import { ReactComponent as Phone } from 'assets/svg/slintel-icon-phone.svg';
import { ReactComponent as Email } from 'assets/svg/slintel-icon-email.svg';
import { ReactComponent as Facebook } from 'assets/svg/slintel-icon-facebook.svg';
import { ReactComponent as Linkedin } from 'assets/svg/slintel-icon-linkedin.svg';
import { ReactComponent as Twitter } from 'assets/svg/slintel-icon-twitter.svg';
import { ReactComponent as DecisionMakingPower } from 'assets/svg/slintel-icon-decision-making-power.svg';
import { ReactComponent as Division } from 'assets/svg/slintel-icon-division.svg';
import { ReactComponent as Function } from 'assets/svg/slintel-icon-function.svg';
import { ReactComponent as Industry } from 'assets/svg/slintel-icon-industry.svg';
import { ReactComponent as Sector } from 'assets/svg/slintel-icon-sector.svg';
import { ReactComponent as SocialLinks } from 'assets/svg/slintel-icon-social-links.svg';
import { ReactComponent as Year } from 'assets/svg/slintel-icon-year.svg';
import { ReactComponent as Revenue } from 'assets/svg/slintel-icon-revenue.svg';
import { ReactComponent as Employee } from 'assets/svg/slintel-icon-lead.svg';
import { ReactComponent as NotificationBell } from 'assets/svg/slintel-icon-notification.svg';
import { ReactComponent as Duplicate } from 'assets/svg/slintel-icon-duplicate.svg';
import { ReactComponent as Rename } from 'assets/svg/slintel-icon-edit.svg';
import { ReactComponent as Delete } from 'assets/svg/slintel-icon-delete.svg';
import { ReactComponent as Alert } from 'assets/svg/slintel-icon-alert.svg';
import { ReactComponent as Success } from 'assets/svg/slintel-icon-success.svg';
import { ReactComponent as Menu } from 'assets/svg/slintel-icon-menu.svg';

import { ReactComponent as Share } from 'assets/svg/slintel-icon-share.svg';
import { ReactComponent as Report } from 'assets/svg/slintel-icon-report.svg';
import { ReactComponent as Unfollow } from 'assets/svg/slintel-icon-unfollow.svg';
import { ReactComponent as Investor } from 'assets/svg/slintel-icon-investor.svg';
import { ReactComponent as Amount } from 'assets/svg/slintel-icon-amount.svg';
import { ReactComponent as SmallClose } from 'assets/svg/slintel-icon-close-small.svg';
import { ReactComponent as Expand } from 'assets/svg/slintel-icon-expand.svg';
import { ReactComponent as Collapse } from 'assets/svg/slintel-icon-collapse.svg';
import { ReactComponent as Help } from 'assets/svg/slintel-icon-help.svg';
import { ReactComponent as Education } from 'assets/svg/slintel-icon-education.svg';
import { ReactComponent as Copy } from 'assets/svg/slintel-icon-copy.svg';
import { ReactComponent as Link } from 'assets/svg/slintel-icon-link.svg';
import { ReactComponent as Auth } from 'assets/svg/Group 6651.svg';
import { ReactComponent as Mappings } from 'assets/svg/Group 8083.svg';
import { ReactComponent as Config } from 'assets/svg/Group 8084.svg';
import { ReactComponent as FingerPrint } from 'assets/svg/Group 8172.svg';
import { ReactComponent as SortAscending } from 'assets/svg/slintel-icon-ascending-order.svg';
import { ReactComponent as InfoNew } from 'assets/svg/slintel-icon-info-01.svg';
import { ReactComponent as Negation } from 'assets/svg/slintel-icon-negation.svg';
import { ReactComponent as DefaultFilterLead } from 'assets/svg/Group 12104.svg';
import { ReactComponent as DefaultFilterCompany } from 'assets/svg/Group 12105.svg';
import { ReactComponent as ThumbsUp } from 'assets/svg/Group 11989.svg';
import { ReactComponent as Crunchbase } from 'assets/svg/Group 13109.svg';
import { ReactComponent as Owler } from 'assets/svg/Group 13110.svg';
import { ReactComponent as Calender } from 'assets/svg/slintel-icon-calender.svg';
import { ReactComponent as SearchNotFound } from 'assets/svg/empty_icon.svg';
import { ReactComponent as TableLoading } from 'assets/gif/loader2.gif';
import { ReactComponent as InvalidFilter } from 'assets/svg/Group 11731.svg';
import { ReactComponent as Source } from 'assets/svg/slintel-icon-source.svg';
import { ReactComponent as Time } from 'assets/svg/slintel-icon-time.svg';
import { ReactComponent as Upgrade } from 'assets/svg/slintel-icon-upgrade.svg';
import { ReactComponent as SessionTimeout } from 'assets/svg/sessionTimeout.svg';
// import { ReactComponent as Calender } from 'assets/svg/Group 11168.svg';
import { ReactComponent as Refresh } from 'assets/svg/Group 11355.svg';
import { ReactComponent as ExportNew } from 'assets/svg/Group 11677.svg';
import { ReactComponent as History } from 'assets/svg/Group 8807-1.svg';
import { ReactComponent as Bell } from 'assets/svg/Group 8807-2.svg';
import { ReactComponent as Integration } from 'assets/svg/Group 8807.svg';
import { ReactComponent as Account } from 'assets/svg/Group 12179.svg';
import { ReactComponent as CSVNew } from 'assets/svg/Group 13402_csv.svg';
import { ReactComponent as NewDownArrowSmall } from 'assets/svg/Path 10376_down.svg';
import { ReactComponent as Sandbox } from 'assets/svg/Group 10840.svg';
import { ReactComponent as Production } from 'assets/svg/Group 10841.svg';
import { ReactComponent as Enrich } from 'assets/svg/Group 8162.svg';
import { ReactComponent as Validate } from 'assets/svg/Group 6652.svg';
import { ReactComponent as Package } from 'assets/svg/Group 8182.svg';
import { ReactComponent as NAICS } from 'assets/svg/slintel-icon-naics-code.svg';
import { ReactComponent as SIC } from 'assets/svg/slintel-icon-sic-code.svg';
import { ReactComponent as EBITDA } from 'assets/svg/slintel-icon-ebitda.svg';
import { ReactComponent as Stock } from 'assets/svg/slintel-icon-stock.svg';

export const SessionTimeoutIcon = (props) => (
  <Icon component={() => <SessionTimeout {...props} />} />
);
export const LogoIcon = (props) => <Icon component={() => <SLogo {...props} />} />;
export const DashboardIcon = (props) => <Icon component={() => <Dashboard {...props} />} />;
export const TechnologyIcon = (props) => <Icon component={() => <Technology {...props} />} />;
export const PsychographicIcon = (props) => <Icon component={() => <Psychographic {...props} />} />;
export const CompanyIcon = (props) => <Icon component={() => <Company {...props} />} />;
export const LeadIcon = (props) => <Icon component={() => <Lead {...props} />} />;
export const EnrichmentIcon = (props) => <Icon component={() => <Enrichment {...props} />} />;
export const EllipsisIcon = (props) => <Icon component={() => <Ellipsis {...props} />} />;
export const OkSignIcon = (props) => <Icon component={() => <OkSign {...props} />} />;
export const JobPostingIcon = (props) => <Icon component={() => <JobPosting {...props} />} />;
export const FundingIcon = (props) => <Icon component={() => <Funding {...props} />} />;
export const CsvIcon = (props) => <Icon component={() => <Csv {...props} />} />;
export const OverviewIcon = (props) => <Icon component={() => <Overview {...props} />} />;
export const SalesforceIcon = (props) => <Icon component={() => <Salesforce {...props} />} />;
export const HubspotIcon = (props) => <Icon component={() => <Hubspot {...props} />} />;
export const PipedriveIcon = (props) => <Icon component={() => <Pipedrive {...props} />} />;
export const SmartListIcon = (props) => <Icon component={() => <SmartList {...props} />} />;
export const NoteIcon = (props) => <Icon component={() => <Note {...props} />} />;
export const BBucketIcon = (props) => <Icon component={() => <BBucket {...props} />} />;
export const ABucketIcon = (props) => <Icon component={() => <ABucket {...props} />} />;
export const APlusBucketIcon = (props) => <Icon component={() => <APlusBucket {...props} />} />;
export const DefaultLeadIcon = (props) => <Icon component={() => <DefaultLead {...props} />} />;

export const DefaultCompanyIcon = (props) => (
  <Icon component={() => <DefaultCompany {...props} />} />
);

export const AddFilterNotifIcon = (props) => (
  <Icon component={() => <AddFilterNotif {...props} />} />
);
export const LocationIcon = (props) => <Icon component={() => <LocationIconDark {...props} />} />;

export const CompanyTabIcon = (props) => <Icon component={() => <CompanyTab {...props} />} />;
export const NewsFeedTabIcon = (props) => <Icon component={() => <NewsFeed {...props} />} />;
export const LeadTabIcon = (props) => <Icon component={() => <LeadTab {...props} />} />;

export const NextArrowIcon = (props) => <Icon component={() => <NextArrow {...props} />} />;
export const PreviousArrowIcon = (props) => <Icon component={() => <PreviousArrow {...props} />} />;

export const AddCompaniesFromSlintelIcon = (props) => (
  <Icon component={() => <AddCompaniesFromSlintel {...props} />} />
);
export const CompanyEnrichmentIcon = (props) => (
  <Icon component={() => <CompanyEnrichment {...props} />} />
);
export const BranchInsightIcon = (props) => <Icon component={() => <BranchInsight {...props} />} />;
export const SearchIcon = (props) => <Icon component={() => <Search {...props} />} />;
export const SourceLinkIcon = (props) => <Icon component={() => <SourceLink {...props} />} />;
export const ShareLinkIcon = (props) => <Icon component={() => <ShareLink {...props} />} />;
export const InfoIcon = (props) => <Icon component={() => <Info {...props} />} />;
export const NoItemsIcon = (props) => <Icon component={() => <NoItems {...props} />} />;
export const RightArrowIcon = (props) => <Icon component={() => <RightArrow {...props} />} />;
export const PersonIcon = (props) => <Icon component={() => <Person {...props} />} />;
export const EventsThemeIcon = (props) => <Icon component={() => <EventsTheme {...props} />} />;
export const GrowthThemeIcon = (props) => <Icon component={() => <GrowthTheme {...props} />} />;
export const FundingThemeIcon = (props) => <Icon component={() => <FundingTheme {...props} />} />;
export const IpoThemeIcon = (props) => <Icon component={() => <IPOTheme {...props} />} />;
export const PlusIcon = (props) => <Icon component={() => <Plus {...props} />} />;
export const LeadUploadIcon = (props) => <Icon component={() => <LeadUpload {...props} />} />;
export const LeadEnrichIcon = (props) => <Icon component={() => <LeadEnrich {...props} />} />;
export const LeadDiscoverIcon = (props) => <Icon component={() => <LeadDiscover {...props} />} />;
export const EditIcon = (props) => <Icon component={() => <Edit {...props} />} />;
export const DownloadIcon = (props) => <Icon component={() => <Download {...props} />} />;
export const AuthIcon = (props) => <Icon component={() => <Auth {...props} />} />;
export const ConfigIcon = (props) => <Icon component={() => <Mappings {...props} />} />;
export const FieldMappingsIcon = (props) => <Icon component={() => <Config {...props} />} />;
export const FingerPrintIcon = (props) => <Icon component={() => <FingerPrint {...props} />} />;
export const HistoryIcon = (props) => <Icon component={() => <History {...props} />} />;
export const IntegrationIcon = (props) => <Icon component={() => <Integration {...props} />} />;
export const AccountIcon = (props) => <Icon component={() => <Account {...props} />} />;
export const BellIcon = (props) => <Icon component={() => <Bell {...props} />} />;
export const CSVNewIcon = (props) => <Icon component={() => <CSVNew {...props} />} />;
export const SandboxIcon = (props) => <Icon component={() => <Sandbox {...props} />} />;
export const ProductionIcon = (props) => <Icon component={() => <Production {...props} />} />;
export const EnrichIcon = (props) => <Icon component={() => <Enrich {...props} />} />;
export const ValidateIcon = (props) => <Icon component={() => <Validate {...props} />} />;
export const PackageIcon = (props) => <Icon component={() => <Package {...props} />} />;
export const NewDownArrowSmallIcon = (props) => (
  <Icon component={() => <NewDownArrowSmall {...props} />} />
);

export const LeadNonIcon = (props) => <Icon component={() => <LeadNon {...props} />} />;
export const LeadEnrichmentIcon = (props) => (
  <Icon component={() => <LeadEnrichment {...props} />} />
);
export const ExpansionThemeIcon = (props) => (
  <Icon component={() => <ExpansionTheme {...props} />} />
);
export const TechnologiesThemeIcon = (props) => (
  <Icon component={() => <TechnologiesTheme {...props} />} />
);
export const AcquisitionThemeIcon = (props) => (
  <Icon component={() => <AcquisitionTheme {...props} />} />
);
export const LeadershipChangesThemeIcon = (props) => (
  <Icon component={() => <LeadershipChangesTheme {...props} />} />
);
export const PartnershipsThemeIcon = (props) => (
  <Icon component={() => <PartnershipsTheme {...props} />} />
);
export const ProductLaunchesThemeIcon = (props) => (
  <Icon component={() => <ProductLaunchesTheme {...props} />} />
);

export const DiscoverIcon = (props) => <Icon component={() => <Discover {...props} />} />;
export const OtherFilterIcon = (props) => <Icon component={() => <OtherIcon {...props} />} />;
export const CircleFillIcon = (props) => <Icon component={() => <CircleIcon {...props} />} />;
export const LockedIcon = (props) => <Icon component={() => <Locked {...props} />} />;
export const UpArrowIcon = (props) => <Icon component={() => <UpAarrow {...props} />} />;
export const AddToListIcon = (props) => <Icon component={() => <AddToList {...props} />} />;
export const FilterIcon = (props) => <Icon component={() => <Filter {...props} />} />;
export const ExportIcon = (props) => <Icon component={() => <Export {...props} />} />;
export const PhoneIcon = (props) => <Icon component={() => <Phone {...props} />} />;
export const PhoneSuccessIcon = (props) => (
  <Icon
    component={() => {
      return (
        <div>
          <Phone {...props} />
          <InstaSuccess
            fill="#FFFFFF"
            style={{
              position: 'absolute',
              top: '50%',
              left: '60%',
            }}
          />
        </div>
      );
    }}
  />
);
export const PhoneProcessIcon = (props) => (
  <Icon
    component={() => {
      return (
        <div>
          <Phone {...props} />
          <InstaProcess
            width={10}
            height={10}
            fill="#FFFFFF"
            style={{
              position: 'absolute',
              top: '50%',
              left: '60%',
            }}
          />
        </div>
      );
    }}
  />
);
export const PhoneErrorIcon = (props) => (
  <Icon
    component={() => {
      return (
        <div>
          <Phone {...props} />
          <InstaError
            width={10}
            height={10}
            fill="#FFFFFF"
            style={{
              position: 'absolute',
              top: '50%',
              left: '60%',
            }}
          />
        </div>
      );
    }}
  />
);
export const EmailSuccessIcon = (props) => (
  <Icon
    component={() => {
      return (
        <div>
          <Email {...props} />
          <InstaSuccess
            fill="#FFFFFF"
            style={{
              position: 'absolute',
              top: '50%',
              left: '60%',
            }}
          />
        </div>
      );
    }}
  />
);
export const EmailProcessIcon = (props) => (
  <Icon
    component={() => {
      return (
        <div>
          <Email {...props} />
          <InstaProcess
            width={10}
            height={10}
            fill="#FFFFFF"
            style={{
              position: 'absolute',
              top: '50%',
              left: '60%',
            }}
          />
        </div>
      );
    }}
  />
);
export const EmailErrorIcon = (props) => (
  <Icon
    component={() => {
      return (
        <div>
          <Email {...props} />
          <InstaError
            width={10}
            height={10}
            fill="#FFFFFF"
            style={{
              position: 'absolute',
              top: '50%',
              left: '60%',
            }}
          />
        </div>
      );
    }}
  />
);
export const EmailIcon = (props) => <Icon component={() => <Email {...props} />} />;
export const FacebookIcon = (props) => <Icon component={() => <Facebook {...props} />} />;
export const LinkedinIcon = (props) => <Icon component={() => <Linkedin {...props} />} />;
export const TwitterIcon = (props) => <Icon component={() => <Twitter {...props} />} />;
export const DecisionMakingPowerIcon = (props) => (
  <Icon component={() => <DecisionMakingPower {...props} />} />
);
export const DivisionIcon = (props) => <Icon component={() => <Division {...props} />} />;
export const FunctionIcon = (props) => <Icon component={() => <Function {...props} />} />;
export const IndustryIcon = (props) => <Icon component={() => <Industry {...props} />} />;
export const SectorIcon = (props) => <Icon component={() => <Sector {...props} />} />;
export const SocialLinksIcon = (props) => <Icon component={() => <SocialLinks {...props} />} />;
export const YearIcon = (props) => <Icon component={() => <Year {...props} />} />;
export const RevenueIcon = (props) => <Icon component={() => <Revenue {...props} />} />;
export const EBITDAIcon = (props) => <Icon component={() => <EBITDA {...props} />} />;
export const StockIcon = (props) => <Icon component={() => <Stock {...props} />} />;
export const NAICSIcon = (props) => <Icon component={() => <NAICS {...props} />} />;
export const SICIcon = (props) => <Icon component={() => <SIC {...props} />} />;

export const EmployeeIcon = (props) => <Icon component={() => <Employee {...props} />} />;
export const InvestorIcon = (props) => <Icon component={() => <Investor {...props} />} />;
export const AmountIcon = (props) => <Icon component={() => <Amount {...props} />} />;
export const SmallCloseIcon = (props) => <Icon component={() => <SmallClose {...props} />} />;
export const EducationIcon = (props) => <Icon component={() => <Education {...props} />} />;
export const CopyIcon = (props) => <Icon component={() => <Copy {...props} />} />;
export const LinkIcon = (props) => <Icon component={() => <Link {...props} />} />;
export const DeleteIcon = (props) => <Icon component={() => <Delete {...props} />} />;
export const DuplicateIcon = (props) => <Icon component={() => <Duplicate {...props} />} />;
export const RenameIcon = (props) => <Icon component={() => <Rename {...props} />} />;
export const ExportNewIcon = (props) => <Icon component={() => <ExportNew {...props} />} />;
export const RefreshIcon = (props) => <Icon component={() => <Refresh {...props} />} />;
export const SortDescendingIcon = (props) => (
  <Icon component={() => <SortDescending {...props} />} />
);
export const SortAscendingIcon = (props) => <Icon component={() => <SortAscending {...props} />} />;
export const NotificationBellIcon = (props) => (
  <Icon component={() => <NotificationBell {...props} />} />
);
export const AlertIcon = (props) => <Icon component={() => <Alert {...props} />} />;
export const SuccessIcon = (props) => <Icon component={() => <Success {...props} />} />;
export const MenuIcon = (props) => <Icon component={() => <Menu {...props} />} />;

export const ShareIcon = (props) => <Icon component={() => <Share {...props} />} />;
export const ReportIcon = (props) => <Icon component={() => <Report {...props} />} />;
export const UnfollowIcon = (props) => <Icon component={() => <Unfollow {...props} />} />;

export const ExpandIcon = (props) => <Icon component={() => <Expand {...props} />} />;
export const CollapseIcon = (props) => <Icon component={() => <Collapse {...props} />} />;
export const HelpIcon = (props) => <Icon component={() => <Help {...props} />} />;

export const InfoNewIcon = (props) => <Icon component={() => <InfoNew {...props} />} />;

export const NegationIcon = (props) => <Icon component={() => <Negation {...props} />} />;

export const DownArrowIcon = (props) => <Icon component={() => <DownArrow {...props} />} />;

export const DownArrowSmallIcon = (props) => (
  <Icon component={() => <DownArrowSmall {...props} />} />
);

export const UpArrowSmallIcon = (props) => <Icon component={() => <UpArrowSmall {...props} />} />;

export const UpArrowIconDark = (props) => <Icon component={() => <UpArrow {...props} />} />;

export const ThumbsUpIcon = (props) => <Icon component={() => <ThumbsUp {...props} />} />;

export const CrunchbaseIcon = (props) => <Icon component={() => <Crunchbase {...props} />} />;

export const OwlerIcon = (props) => <Icon component={() => <Owler {...props} />} />;

export const CalenderIcon = (props) => <Icon component={() => <Calender {...props} />} />;
export const SearchNotFoundIcon = (props) => (
  <Icon component={() => <SearchNotFound {...props} />} />
);
export const TableLoadingIcon = (props) => <Icon component={() => <TableLoading {...props} />} />;
export const SourceIcon = (props) => <Icon component={() => <Source {...props} />} />;
export const TimeIcon = (props) => <Icon component={() => <Time {...props} />} />;
export const UpgradeIcon = (props) => <Icon component={() => <Upgrade {...props} />} />;

export const DefaultFilterLeadIcon = (props) => (
  <Icon component={() => <DefaultFilterLead {...props} />} />
);
export const DefaultFilterCompanyIcon = (props) => (
  <Icon component={() => <DefaultFilterCompany {...props} />} />
);

export const InvalidFilterIcon = (props) => <Icon component={() => <InvalidFilter {...props} />} />;

export const ThemeIcon = ({ theme, ...props }) => {
  switch (theme) {
    case 'funding':
      return <Icon component={() => <FundingTheme {...props} />} />;
    case 'acquisition':
      return <Icon component={() => <AcquisitionTheme {...props} />} />;
    case 'events':
      return <Icon component={() => <EventsTheme {...props} />} />;
    case 'partnerships':
      return <Icon component={() => <PartnershipsTheme {...props} />} />;
    case 'technologies':
      return <Icon component={() => <TechnologiesTheme {...props} />} />;
    case 'expansion':
      return <Icon component={() => <ExpansionTheme {...props} />} />;
    case 'growth':
      return <Icon component={() => <GrowthTheme {...props} />} />;
    case 'leadership_changes':
      return <Icon component={() => <LeadershipChangesTheme {...props} />} />;
    case 'product_launches':
      return <Icon component={() => <ProductLaunchesTheme {...props} />} />;
    case 'executive_movements':
      return <Icon component={() => <LeadershipChangesTheme {...props} />} />;
    case 'ipos':
      return <Icon component={() => <IPOTheme {...props} />} />;
    default:
      return <Icon component={() => <TechnologiesTheme {...props} />} />;
  }
};

ThemeIcon.propTypes = {
  theme: PropTypes.string.isRequired,
};

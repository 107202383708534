import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import {
  Steps,
  Button,
  Typography,
  Upload,
  Progress,
  Space,
  Form,
  Row,
  Col,
  Select,
  Drawer,
  Radio,
  Alert,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import {
  CSVImportGetHeaders,
  calculatePercentage,
  headerGenerator,
  generateApiUrl,
} from 'utils/common';
import { showError, showSuccess } from 'components/common/errorMessage';
import { LeadEnrichmentIcon } from '../../UI/CustomIcon/CustomIcon';
import { SAMPLE_COMPANY_EXCLUSION_CSV, SAMPLE_LEAD_EXCLUSION_CSV } from '../../constants/constants';
import { uplodFile, postRequest } from '../../api/apiRequests';
import { baseURL, endPoints } from '../../api/apiEndpoints';
import '../../containers/smartList/smartListIndex.scss';

const { Text, Link, Title } = Typography;

const { Step } = Steps;

const ListFileUploadDrawer = ({ list, onClose, visible }) => {
  const [current, setCurrent] = useState(0);

  const [fileName, setFileName] = useState(null);

  const [fileList, setFileList] = useState([]);

  const [progress, setProgress] = useState(0);

  const [fileUrl, setFileUrl] = useState('');

  const [downloadUrl, setDownloadUrl] = useState('');

  const [isFileUpload, setIsFileUpload] = useState(false);

  const [csvHeaders, setCsvHeaders] = useState([]);

  const [s3FileName, setS3FileName] = useState('');

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.auth.user, shallowEqual);
  const httpHeaders = headerGenerator(user.token, user.session_id);

  const finalMapping = {
    lead_exclusion: [
      {
        slintelField: 'slintel_lead_id',
        label: 'People ID',
      },
      {
        slintelField: 'email',
        label: 'Email',
      },
    ],
    company_exclusion: [
      {
        slintelField: 'slintel_company_id',
        label: 'Company ID',
      },
      {
        slintelField: 'company_website',
        label: 'Company Domain',
      },
    ],
  };

  const getInitialValues = () => ({
    selectedMappping: list.listType === 'lead_exclusion' ? 'slintel_lead_id' : 'slintel_company_id',
    slintel_lead_id: { value: '--' },
    email: { value: '--' },
    slintel_company_id: { value: '--' },
    company_website: { value: '--' },
  });

  useEffect(() => {
    fileUplodComplete();
  }, [s3FileName]);

  const removeFile = () => {
    setFileList([]);
    setFileName(null);
    setIsFileUpload(false);
    setFileUrl('');
    setDownloadUrl('');
    setS3FileName('');
  };

  const reset = () => {
    setProgress(0);
    setCurrent(0);
    removeFile();
    onClose();
  };

  const analyseFile = async (file) => {
    return new Promise((resolve, reject) => {
      try {
        CSVImportGetHeaders(file, resolve);
      } catch (err) {
        reject(err);
      }
    });
  };

  const generatePreSignedJob = async (file) => {
    const response = await postRequest(
      `${baseURL}list/${endPoints.generateFileUrl}`,
      {
        fileName: file.name,
        fileSize: file.size,
      },
      httpHeaders
    );
    if (response.error) {
      throw new Error(response.message);
    }
    return response;
  };

  const getFileFormData = (file, fields) => {
    const formData = new FormData();
    // eslint-disable-next-line guard-for-in
    for (const field in fields) {
      formData.append(field, fields[field]);
    }
    formData.append('file', file);
    return formData;
  };

  const fileUplodComplete = () => {
    if (fileName && fileUrl && s3FileName) {
      setIsFileUpload(true);
    }
  };

  const generateDownloadUrl = (s3Url, path) => {
    const url = `${s3Url}/${path}`;
    setDownloadUrl(url);
  };

  const uploadSuccess = (name, headers, preSignedData) => {
    setFileName(name);
    setCsvHeaders(headers);
    setFileUrl(preSignedData.url);
    setS3FileName(preSignedData.s3FileName);
    generateDownloadUrl(preSignedData.url, preSignedData.fields.key);
  };

  const uploadFailure = (message) => {
    setProgress(0);
    showError(message || 'Unable to upload file. Please try again');
    removeFile();
  };

  const getUploadConfig = () => {
    return {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event) => {
        const percent = Math.floor(calculatePercentage(event.loaded, event.total));
        setProgress(Math.max(percent, 2));
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
      },
    };
  };

  const uplodWithPresignedUrl = async ({ file }) => {
    try {
      setProgress(1);
      const preSignedData = await generatePreSignedJob(file);
      const { url, fields } = preSignedData;
      if (!url) {
        throw new Error('Unable to Analyse File...');
      } else {
        const formData = getFileFormData(file, fields);
        const config = getUploadConfig();
        const response = await uplodFile(url, formData, config);
        if (response.error) {
          uploadFailure(response.message);
        } else {
          const headers = await analyseFile(file);
          uploadSuccess(file.name, headers, preSignedData);
        }
      }
    } catch (err) {
      showError(err.message);
      setProgress(0);
      removeFile();
    }
  };

  const renderProgress = () => {
    return (
      <div style={{ flex: '0.8' }}>
        <Progress
          trailColor="#DFE7F0"
          strokeColor="#5E81F4"
          percent={progress}
          size="small"
          status="active"
        />
      </div>
    );
  };

  const renderProgressTitle = () => {
    return progress ? (
      <Text
        className="title"
        style={{
          marginLeft: '20px',
          textAlign: 'center',
        }}
      >
        Uploading...
      </Text>
    ) : (
      <Text className="title" style={{ marginLeft: '20px' }}>
        <Link href={downloadUrl} target="_blank">
          {fileName}
        </Link>
      </Text>
    );
  };

  const getUploadTitle = () => {
    return list.listType === 'lead_exclusion' ? (
      <span>People Exclusion List </span>
    ) : (
      <span>Company Exclusion List </span>
    );
  };

  const getSampleFile = () => {
    return list.listType === 'lead_exclusion'
      ? SAMPLE_LEAD_EXCLUSION_CSV
      : SAMPLE_COMPANY_EXCLUSION_CSV;
  };

  const renderTitle = () => {
    return !isFileUpload && !progress ? (
      <Title
        style={{
          marginLeft: '20px',
        }}
        level={5}
      >
        {getUploadTitle()}
        <br />
        <Link
          style={{ fontSize: '14px', position: 'relative', zIndex: 1 }}
          type="link"
          href={getSampleFile()}
          target="_blank"
        >
          Sample CSV
        </Link>
      </Title>
    ) : (
      renderProgressTitle()
    );
  };

  const renderUploadBtn = () => {
    return isFileUpload ? (
      <div>
        <CloseOutlined fill="#929CB7" onClick={() => removeFile()} />
      </div>
    ) : (
      <div style={{ display: 'flex' }}>
        <Space>
          <>
            <Text type="secondary" className="ant-upload-hint">
              Drag & Drop
            </Text>
            <Text type="secondary">(OR)</Text>
          </>
          <div className="dummy-btn">Upload CSV</div>
        </Space>{' '}
        <Upload
          fileList={fileList}
          accept=".csv"
          title="Upload"
          className="upload-block"
          customRequest={uplodWithPresignedUrl}
        >
          <Button className="upload-btn">Upload</Button>
        </Upload>
      </div>
    );
  };

  const renderHeaderInfo = () => {
    const message =
      list.listType === 'lead_exclusion'
        ? 'People ID and Email fields missing'
        : 'Company ID and Company Domain fields missing';

    const hasRequiredField =
      list.listType === 'lead_exclusion'
        ? csvHeaders.some(
            (header) => header.toLowerCase().match('lead_id') || header.toLowerCase().match('email')
          )
        : csvHeaders.some(
            (header) =>
              header.toLowerCase().match('company_id') ||
              header.toLowerCase().match('company_domain')
          );

    return (
      isFileUpload &&
      !hasRequiredField && (
        <div className="header-info">
          <br />
          <Alert message={message} type="warning" showIcon closable={false} />
          <br />
        </div>
      )
    );
  };

  const renderUploadForm = () => {
    return (
      <>
        {renderHeaderInfo()}
        <div className="exclusion-file-upload">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LeadEnrichmentIcon
              style={{ background: '#E6F4FF', borderRadius: '50%' }}
              fill="#29275F"
            />
            {renderTitle()}
          </div>
          {progress ? renderProgress() : renderUploadBtn()}
        </div>
        <br />
        <FileUploadInfo />
      </>
    );
  };

  const FileUploadInfo = () => {
    return (
      <>
        <div className="file-upload-info">
          <Text className="info-text"> Things to keep in mind before uploading a list </Text>
          <div className="info-items">
            <ul className="info-text">
              <li>Only CSV file format is supported</li>
              <li>Max. 200 MB CSV file is supported</li>
              <li>First row in the file should be the Header</li>
              <li>Action will be taken only on the first 5 Million rows</li>
              {list.listType === 'lead_exclusion' ? (
                <li>Please upload only verified emails to get accurate results</li>
              ) : (
                <li>Please upload only verified company domains to get accurate results</li>
              )}
            </ul>
          </div>
        </div>
      </>
    );
  };

  const getCsvHeaderMapping = (values) => {
    const { selectedMappping } = values;
    const finalCSVMapping = {};
    if (selectedMappping) {
      const selectedValue = values[selectedMappping].value;
      if (selectedValue !== '--') {
        finalCSVMapping[selectedMappping] = selectedValue;
      }
    }
    return finalCSVMapping;
  };

  const validateForm = (values) => {
    const { selectedMappping } = values;
    const isLead = list.listType === 'lead_exclusion';
    if (!selectedMappping) {
      const errorMessage = 'Please Choose and Select a field you want to map';
      showError(errorMessage);
      return false;
    }
    if (values[selectedMappping].value === '--') {
      const errorMessage = isLead
        ? 'Please map People ID and/or Email to create Exclusion List'
        : 'Please map Company Name and/or Company Domain to create Exclusion List';
      showError(errorMessage);
      return false;
    }
    return true;
  };

  const onFinish = (values) => {
    if (validateForm(values)) {
      const { id: listId } = list;
      const params = {
        listId,
        fileName,
        fileUrl,
        s3FileName,
        mapping: getCsvHeaderMapping(values),
      };
      createJobWithMappings(params);
    }
  };

  const createJobWithMappings = async (params) => {
    setLoading(true);
    const apiURL = baseURL + generateApiUrl(endPoints.lists, endPoints.createExclusionListJob);
    const response = await postRequest(apiURL, params, httpHeaders);
    if (response.error) {
      showError(response.message);
    } else {
      showSuccess('Working on this 👩‍💻');
      reset();
    }
    setLoading(false);
    form.resetFields();
  };

  const getSelectionForField = (label, value) => {
    return (
      <Form.Item style={{ marginBottom: '0px' }} name="selectedMappping">
        <Radio.Group>
          <Radio value={value}>{label}</Radio>
        </Radio.Group>
      </Form.Item>
    );
  };

  const renderFieldMappingForm = () => {
    const { listType } = list;
    return (
      <div className="list-field-mapping-block">
        <Form
          labelAlign="left"
          labelCol={{
            span: 8,
          }}
          colon={false}
          hideRequiredMark
          form={form}
          initialValues={getInitialValues()}
          onFinish={onFinish}
        >
          <div>
            <>
              <Space direction="vertical" size="large">
                <div className="note-section">
                  Once you map the CSV columns to Slintel fields, a new exclusion list with enriched
                  insights will be created.
                </div>
                <Row className="fields-mapping">
                  <Col span={8}>
                    <Text className="head">Slintel Field</Text>
                  </Col>
                  <Col span={16}>
                    <Text className="head">CSV Field</Text>
                  </Col>
                </Row>
              </Space>
            </>
            <div>
              {finalMapping[listType].map((field) => {
                return (
                  <Form.Item
                    label={getSelectionForField(field.label, field.slintelField)}
                    key={field.slintelField}
                    name={field.slintelField}
                    className="fields-mapping"
                    style={{ paddingTop: '15px' }}
                  >
                    <Select
                      dropdownStyle={{ backgroundColor: '#ffffff' }}
                      style={{ width: '200px' }}
                      className="custom-select-dropdown"
                      placeholder="Please select CSV fields"
                      labelInValue
                      options={csvHeaders
                        .map((e) => ({ value: e, label: e }))
                        .concat({ value: '--', label: '--' })}
                    />
                  </Form.Item>
                );
              })}
            </div>
          </div>
        </Form>
      </div>
    );
  };

  const renderStep = () => {
    switch (current) {
      case 0:
        return renderUploadForm();
      case 1:
        return renderFieldMappingForm();
      default:
        return <></>;
    }
  };

  const submit = () => {
    form.submit();
  };

  const next = () => {
    return current === 1 ? submit() : setCurrent(current + 1);
  };

  const back = () => {
    return current === 0 ? reset() : setCurrent(current - 1);
  };

  const getByTitle = () => {
    return (
      <div>
        <div style={{ alignSelf: 'center' }}>
          <Text
            style={{
              color: '#29275F',
              fontSize: '16px',
              fontWeight: 'bold',
              textAlign: 'left',
              letterSpacing: '0.8px',
              textTransform: 'uppercase',
            }}
          >
            Upload {getUploadTitle()}
          </Text>
        </div>
        <div style={{ display: 'flex', marginTop: '20px' }}>
          <div style={{ flex: 1 }}>
            <Steps className="list-field-mapping" disabled current={current}>
              <Step />
              <Step />
            </Steps>
          </div>
          <div
            style={{
              width: '60%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              type="default"
              disabled={progress}
              onClick={() => {
                back();
              }}
            >
              {current === 1 ? 'Back' : 'Cancel'}
            </Button>
            <Button
              type="primary"
              loading={loading}
              style={{ marginLeft: '15px' }}
              disabled={!isFileUpload}
              onClick={() => {
                next();
              }}
            >
              {current === 1 ? 'Done' : 'Next'}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Drawer
        title={getByTitle()}
        visible={visible}
        onClose={onClose}
        style={{
          marginLeft: '60px',
        }}
        destroyOnClose
        closable={false}
        maskClosable={false}
        width="600px"
        className="list-upload-field-mapping"
      >
        <>{renderStep()}</>
      </Drawer>
    </>
  );
};

ListFileUploadDrawer.propTypes = {
  list: PropTypes.shape({
    id: PropTypes.number.isRequired,
    listType: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

export default ListFileUploadDrawer;

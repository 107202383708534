/* eslint no-use-before-define: 2 */
import React from 'react';
import { Tooltip, Modal, Space, Button as AntButton } from 'antd';

import Button from 'UI/Button';

import { InfoIcon, EmployeeIcon, CompanyIcon, DeleteIcon } from 'UI/CustomIcon/CustomIcon';

import { NavLink } from 'react-router-dom';
import { formatDateFromString } from 'utils/common';

import Thumbnail from 'UI/Thumbnail/Thumbnail';
import Text from 'antd/lib/typography/Text';

import { LIST_TABS } from 'constants/constants';
import PresignedButton from 'components/common/presignedButton';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const [SMART_LIST] = LIST_TABS.map((op) => op.value);

export const tableOptions = {
  sortOrder: {
    key: 'updatedAt',
    order: 'descend',
  },
  pageNumber: 1,
};

export const getListName = (type) => {
  return type
    ? type
        .split('_')
        .map((text) => text.toUpperCase())
        .join(' ')
    : 'List';
};

export const getPlaceHolder = (listType) => {
  return `Select ${getListName(listType)} Type`;
};

export const getStyles = () => {
  return { width: '240px', height: '36px' };
};

const StatusComponent = ({ record }) => {
  const { userListJobs } = record;
  const status =
    Array.isArray(userListJobs) && userListJobs[0]
      ? userListJobs[0].userListJobItems[0].status
      : '';
  let label = 'No Records Added';
  // TODO: Get message from server
  const errorMsg = '';
  if (status === 'created') {
    label = (
      <div>
        <Text type="warning">Created</Text>
      </div>
    );
  } else if (status === 'success') {
    label = (
      <div>
        <Text type="success">Ready to use </Text>
      </div>
    );
  } else if (status === 'processing') {
    label = (
      <div>
        <Text type="warning">In-Progress</Text>
      </div>
    );
  } else if (status === 'failed') {
    label = (
      <>
        <Text type="danger">Failed </Text>
        <Tooltip placement="right" title={errorMsg || 'List processing failed'}>
          <InfoIcon style={{ fill: '#929CB7', marginLeft: '10px' }} />
        </Tooltip>
      </>
    );
  }

  return label;
};

// const getDownLoadUrl = (record) => {
//   return record.userListJobs[0].userListJobItems[0].url;
// };

const renderSmartListNameColumn = (text, record) => {
  return record.listType && record.listType.startsWith('lead') ? (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Tooltip title={text.length > 40 ? text : ''}>
        <NavLink
          style={{
            color: '#5753B5',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '300px',
          }}
          to={{
            pathname: `/list/${record.id}`,
            item: record,
          }}
        >
          {text}
        </NavLink>
      </Tooltip>
      <Text type="secondary" style={{ fontSize: '12px', letterSpacing: 0 }}>
        {`${record.itemCount || 0} People`}
      </Text>
    </div>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <NavLink
        style={{ color: '#5753B5' }}
        to={{
          pathname: `/list/${record.id}`,
          item: record,
        }}
      >
        {text}
      </NavLink>
      <Text type="secondary" style={{ fontSize: '12px', letterSpacing: 0 }}>
        {`${record.itemCount || 0} Companies`}
      </Text>
    </div>
  );
};

const renderExclusionListNameColumn = (text, record) => {
  return record.listType && record.listType.startsWith('lead') ? (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Tooltip title={text.length > 40 ? text : ''}>
        <Text
          style={{
            color: '#5753B5',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '300px',
          }}
        >
          {text}
        </Text>
      </Tooltip>
      <Text type="secondary" style={{ fontSize: '12px', letterSpacing: 0 }}>
        {`${record.itemCount || 0} People`}
      </Text>
    </div>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Text
        style={{ color: '#5753B5' }}
        to={{
          pathname: `/list/${record.id}`,
          item: record,
        }}
      >
        {text}
      </Text>
      <Text type="secondary" style={{ fontSize: '12px', letterSpacing: 0 }}>
        {`${record.itemCount || 0} Companies`}
      </Text>
    </div>
  );
};

const renderIcons = (record) => {
  return record.listType && record.listType.startsWith('lead') ? (
    <Thumbnail.Icon Icon={EmployeeIcon} size="small" />
  ) : (
    <Thumbnail.Icon Icon={CompanyIcon} size="small" backgroundColor="#E38168" />
  );
};

const renderSmarListName = (text, record) => {
  return (
    <Space>
      {renderIcons(record)}
      {renderSmartListNameColumn(text, record)}
    </Space>
  );
};

const renderExclusionListName = (text, record) => {
  return (
    <Space>
      {renderIcons(record)}
      {renderExclusionListNameColumn(text, record)}
    </Space>
  );
};

// const downloadCsvFile = (record) => {
//   const anchor = document.createElement('a');
//   anchor.href = getDownLoadUrl(record);
//   anchor.target = '_blank';
//   anchor.rel = 'noreferrer';
//   document.body.appendChild(anchor);
//   anchor.click();
//   document.body.removeChild(anchor);
// };

export const getTableColumns = (type, listType, hasMore, deleteList, onUplodClick) => {
  const getSmartListColumns = () => [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
      sorter: hasMore
        ? true
        : (a, b) => {
            if (a.title < b.title) {
              return -1;
            }
            if (a.title > b.title) {
              return 1;
            }
            return 0;
          },
      width: '30%',
      render: renderSmarListName,
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: hasMore
        ? true
        : (a, b) => {
            if (a.createdAt < b.createdAt) {
              return -1;
            }
            if (a.createdAt > b.createdAt) {
              return 1;
            }
            return 0;
          },
      render: (text) => formatDateFromString(text),
    },
    {
      title: 'Updated On',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: hasMore
        ? true
        : (a, b) => {
            if (a.updatedAt < b.updatedAt) {
              return -1;
            }
            if (a.updatedAt > b.updatedAt) {
              return 1;
            }
            return 0;
          },
      defaultSortOrder: 'descend',
      render: (text) => formatDateFromString(text),
    },
    {
      title: '',
      key: 'action',
      width: '20%',
      render: (text, record) => (
        <div className="table-action-column">
          <Button
            icon={<DeleteIcon fill="#F55656" width="13px" height="13px" />}
            theme="secondary-destructive"
            onClick={() => {
              confirm({
                icon: null,
                title: 'DELETE LIST',
                centered: true,
                content: 'Are you sure you want to delete this list?',
                onOk() {
                  deleteList(record.id);
                },
                onCancel() {},
              });
            }}
          />
        </div>
      ),
    },
  ];

  const getSupressionListColumns = () => [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
      sorter: hasMore
        ? true
        : (a, b) => {
            if (a.title < b.title) {
              return -1;
            }
            if (a.title > b.title) {
              return 1;
            }
            return 0;
          },
      width: '30%',
      render: renderExclusionListName,
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: hasMore
        ? true
        : (a, b) => {
            if (a.createdAt < b.createdAt) {
              return -1;
            }
            if (a.createdAt > b.createdAt) {
              return 1;
            }
            return 0;
          },
      render: (text) => formatDateFromString(text),
    },
    {
      title: 'Status',
      hidden: type !== SMART_LIST,
      dataIndex: 'status',
      key: 'status',
      defaultSortOrder: 'descend',
      render: (status, record) => <StatusComponent record={record} />,
    },
    {
      title: '',
      key: 'action',
      width: '20%',
      render: (text, record) => (
        <div className="table-action-column">
          <div>
            <Button
              icon={<DeleteIcon fill="#F55656" width="13px" height="13px" />}
              theme="secondary-destructive"
              onClick={() => {
                confirm({
                  okText: 'Yes, Delete',
                  icon: null,
                  title: `DELETE ${getListName(type)}`,
                  centered: true,
                  okButtonProps: {
                    danger: true,
                  },
                  content: 'Deleting a exclusion list will permanently remove it from your list.',
                  onOk() {
                    deleteList(record.id);
                  },
                  onCancel() {},
                });
              }}
            />
          </div>
          <div>
            {record.userListJobs && record.userListJobs[0] ? (
              // record.userListJobs[0].userListJobItems[0].url
              <PresignedButton
                element={
                  <AntButton
                    // onClick={() => downloadCsvFile(record)}
                    className="table-action-items"
                    style={{ background: '#E6F4FF', color: '#7291f5', border: 'none' }}
                    type="primary"
                  >
                    Download
                    <DownloadOutlined width="13px" height="13px" />
                  </AntButton>
                }
                url={record.userListJobs[0].userListJobItems[0].url}
              />
            ) : (
              // <Button
              //   onClick={() => downloadCsvFile(record)}
              //   className="table-action-items"
              //   style={{ background: '#E6F4FF', color: '#7291f5', border: 'none' }}
              //   type="primary"
              // >
              //   Download
              //   <DownloadOutlined width="13px" height="13px" />
              // </Button>
              <Button
                className="table-action-items"
                onClick={() => onUplodClick(record.id, record.listType)}
                type="primary"
                title="Upload"
              >
                <UploadOutlined width="13px" height="13px" />
              </Button>
            )}
          </div>
        </div>
      ),
    },
  ];
  return listType === SMART_LIST ? getSmartListColumns() : getSupressionListColumns();
};

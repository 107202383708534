import React from 'react';
import { Typography, Space } from 'antd';

const { Text } = Typography;

const KeyValueWithIcon = (props) => {
  const { item, actionBtn, width = 150 } = props;
  return (
    <div className="table-info-row">
      <div className="table-info-key">
        <Space size={10} style={{ alignItems: 'baseline', display: 'flex' }}>
          {item.icon}
          {item.title && (
            <div style={{ width }}>
              <Text type="secondary">{item.title}</Text>
            </div>
          )}
        </Space>
      </div>
      <div className="table-info-value">
        {item.value} {actionBtn}
      </div>
    </div>
  );
};

export default KeyValueWithIcon;

/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
import React from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import Button from 'UI/Button';

import { Spin, Tooltip, Popover, Button as AntButton, Typography, ConfigProvider } from 'antd';
import './ActionGroup.scss';
import ExportPopover from 'components/discovery/actions/ExportPopover/ExportPopover';
import Copy from 'UI/Copy/Copy';
import { isCopyEnabled } from 'utils/common';

const ActionGroup = ({ actions, data, tab, source }) => {
  const content = (item) => {
    if (!item.record) {
      return <></>;
    }
    if (item?.record?.loading) {
      return (
        <div className="custom-popver-container">
          <Spin size="small" />
        </div>
      );
    }
    if (item.actions === 'REQUEST_PHONE') {
      return item.record?.company_phone_number && item.record?.company_phone_number?.value ? (
        <div className="custom-popver-container">
          <div className="custom-popver">
            {isCopyEnabled() ? (
              <Copy
                text={`${item?.record?.company_phone_number.value}`}
                element={
                  <Typography.Text>{`${item?.record?.company_phone_number.value}`}</Typography.Text>
                }
              />
            ) : (
              <Typography.Text>{`${item?.record?.company_phone_number.value}`}</Typography.Text>
            )}
          </div>
        </div>
      ) : (
        <div className="custom-popver">
          <Typography.Text>Not Available</Typography.Text>
        </div>
      );
    }
    return <></>;
  };
  const renderButton = (item) => {
    const Icon = item.icons[0];
    if (item.actions === 'OPEN_EXPORT' && item.data && item.data.id) {
      return (
        <ExportPopover
          id={item.data.id.value}
          tab={tab}
          smallIcon
          source={source}
          onClick={() => {}}
        />
      );
    }
    return (
      <Button
        key={nanoid()}
        onClick={item.onClick}
        theme="secondary"
        size="small"
        style={{ border: '0px' }}
        icon={<Icon fill="#4A6EE5" className="action-icon" width={15} height={15} />}
      />
    );
  };
  const renderIcon = (item) => {
    const Icon = item.icons[0];
    return (
      <div style={{ minWidth: '20' }} className="slintel-button slintel-button__secondary">
        <AntButton
          key={nanoid()}
          theme="secondary"
          size="small"
          style={{ border: '0px' }}
          icon={<Icon fill="#4A6EE5" className="action-icon" width={15} height={15} />}
        />
      </div>
    );
  };

  return (
    <ConfigProvider getPopupContainer={(trigger) => (trigger ? trigger.parentElement : trigger)}>
      {actions && actions.length ? (
        <div className="slintel-action-group">
          {actions.map((item, index) => {
            return ['REQUEST_PHONE'].includes(item.actions) ? (
              <React.Fragment key={item.actions}>
                <Popover
                  getPopupContainer={(trigger) => (trigger ? trigger.parentElement : trigger)}
                  overlayClassName="insta-reveal-popover"
                  style={{ padding: '0' }}
                  content={() => content(item)}
                  trigger="click"
                >
                  {renderIcon(item)}
                </Popover>
                {index < actions.length - 1 && <div className="seprator" />}{' '}
              </React.Fragment>
            ) : (
              <React.Fragment key={item.actions}>
                {item.tooltip ? (
                  <Tooltip mouseEnterDelay={0.3} placement="top" title={item.title}>
                    {renderButton(item)}
                  </Tooltip>
                ) : (
                  renderButton(item)
                )}
                {index < actions.length - 1 && <div className="seprator" />}{' '}
              </React.Fragment>
            );
          })}
        </div>
      ) : null}
    </ConfigProvider>
  );
};

ActionGroup.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icons: PropTypes.arrayOf(React.Component),
      toggle: PropTypes.bool,
      actions: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

ActionGroup.defaultProps = {
  actions: [],
};

export default ActionGroup;

import React, { useEffect, useMemo, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Space } from 'antd';
import Button from 'UI/Button';
import Drawer from 'UI/CustomDrawer/CustomDrawer';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeAddtoList } from 'containers/discovery/discoveryActions';
import { addtoList, addtoListFromJobsPage } from 'containers/smartList/smartListActions';
import { eventManager, eventList, eventActions } from 'utils/eventManager';
import store from 'src/store/store';
import AddtoListForm from '../AddtoListForm';
import './AddtoList.scss';

const Title = Typography;

const AddtoList = ({ tab }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['addtolist']);
  const isAddtoListVisible = useSelector((state) => state.discovery.isAddtoListVisible);
  const isListLoading = useSelector((state) => state.smartList.isListLoading);
  const addtoListForm = useRef(null);
  const [btnDisabled, setBtnDisabled] = useState(true);

  useEffect(() => {
    onAddtoListClose();
  }, [tab]);

  useEffect(() => {
    if (!isAddtoListVisible) {
      onAddtoListClose();
    }
  }, [isAddtoListVisible]);

  const onSubmit = useCallback((params) => {
    const { defaultInnerActiveKey } = store.getState().discovery;
    const { source, jobFilters } = params;
    delete params.source;
    if (jobFilters) {
      handleAddToListFromJobsPage(params);
    } else {
      dispatch(addtoList({ params }));
    }
    eventManager.track(eventList.ADD_TO_SMART_LIST_TRIGGERED, {
      payload: {
        tab,
        navigation: defaultInnerActiveKey,
        payload: params,
        version: eventActions.NDF,
        smartlist_source: source,
        exclusion: !!params.exclusion_list_ids,
      },
    });
  });

  const handleAddToListFromJobsPage = (params) => {
    dispatch(addtoListFromJobsPage({ params }));
  };

  const onAddtoListClose = () => {
    dispatch(closeAddtoList());
    setBtnDisabled(true);
  };

  const sumbmitAddtoListForm = useCallback(() => {
    addtoListForm.current.onSubmit();
  });

  const onValuesChange = (changedValues, allValues) => {
    if (Array.isArray(allValues?.listId) && allValues?.listId.length === 0) {
      setBtnDisabled(true);
    } else if (
      ['object', 'string'].includes(typeof allValues?.listId) &&
      !Array.isArray(allValues?.listId) &&
      allValues?.listId
    ) {
      setBtnDisabled(false);
    }
  };

  const title = useMemo(() => {
    return (
      <div className="add-to-list__title">
        <Title style={{ color: '#29275F' }}>{t('heading1.ADD_TO_SMART_LIST')}</Title>
        <Space size={8}>
          <Button
            disabled={isListLoading}
            theme="secondary"
            onClick={onAddtoListClose}
            title={t('button_cancel_text.CANCEL')}
          />
          <Button
            disabled={btnDisabled}
            theme="primary"
            loading={isListLoading}
            onClick={sumbmitAddtoListForm}
            title={t('button_confirm_text.ADD')}
          />
        </Space>
      </div>
    );
  }, [isListLoading, btnDisabled]);

  return (
    isAddtoListVisible && (
      <Drawer
        title={title}
        content={
          <AddtoListForm
            ref={addtoListForm}
            isAnySmartListSelected={btnDisabled}
            tab={tab}
            onSubmit={onSubmit}
            onValuesChange={onValuesChange}
          />
        }
        visible={isAddtoListVisible}
        width={600}
        getContainer={false}
        mask
        closable={false}
        headerStyle={{ background: '#F8FAFF' }}
        style={{ position: 'absolute' }}
        className="add-tolist-view-drawer"
      />
    )
  );
};

AddtoList.propTypes = {
  tab: PropTypes.string,
};

AddtoList.defaultProps = {
  tab: '',
};

export default React.memo(AddtoList);

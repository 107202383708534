/* eslint-disable react/no-this-in-sfc */
import React, { useContext, useEffect, useState } from 'react';
import { Divider, Typography } from 'antd';
import { TechnologyService } from 'api/services';
import Highcharts from 'components/charts/highCharts';
import MapChart from 'components/charts/heatMap';
import BarChart from 'components/charts/barChart';
import PieChart from 'components/charts/pieChart';
import TreeMap from 'components/charts/treeMap';
import { Spinner } from 'components/common/loader';
import Pills from 'UI/Pills';
import { useHistory, useParams } from 'react-router-dom';
import TechPsychContext from '../TechPsychContext';
import RowBlockContainer from '../layouts/RowBlockContainer';
import { refactorData } from './helper';

const { Title } = Typography;

const Overview = ({ type }) => {
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: {
      mapData,
      closestCompetitorsData,
      competitorsBarData,
      addsData,
      dropsData,
      companySizeData,
      competitorsPieData,
      customerCategoryData,
      worldMapData,
      ...data
    },
    updateContext,
  } = useContext(TechPsychContext);

  const fetchData = async () => {
    setIsLoading(true);
    const technology = { techId: id };
    const [
      heatMapData,
      closestCompetitors,
      competitors,
      adds,
      drops,
      companySize,
      customerCategory,
      worldMap,
    ] = await Promise.all([
      TechnologyService.getCountryHeatMap(technology),
      TechnologyService.getClosestCompetitors(technology),
      TechnologyService.getCompetitors(technology),
      TechnologyService.getAdds(technology),
      TechnologyService.getDrops(technology),
      TechnologyService.getCompanySize(technology),
      TechnologyService.getProductCategories(technology),
      TechnologyService.getCustomWorld(),
    ]);
    const graphData = refactorData(
      heatMapData?.error ? [] : heatMapData,
      competitors?.error ? [] : competitors,
      adds?.error ? [] : adds,
      drops?.error ? [] : drops,
      companySize?.error ? [] : companySize,
      customerCategory?.error ? [] : customerCategory
    );
    updateContext({
      data: {
        ...data,
        ...graphData,
        closestCompetitorsData: closestCompetitors?.error ? [] : closestCompetitors?.data,
        worldMapData: worldMap ?? [],
      },
    });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);
  const redirect = async (value) => {
    const params = {
      subCategory: data.subcategory,
      techName: value,
    };
    const tech = await TechnologyService.getTechnology(params);
    if (tech?.data) {
      window.open(`/technologies-details/${tech.data}`, '_blank');
    }
  };
  const BarChartRow = ({ title, rowdata }) => {
    return Object.keys(rowdata).length ? (
      <div className="overview-row">
        <div className="left col-2">
          <Title level={3}>{title}</Title>
          <Divider className="divider" />
          <div>
            {Object.keys(rowdata).map((value) => {
              return (
                <Pills className="sl-btn" IconComponent={value} onClick={() => redirect(value)} />
              );
            })}
          </div>
        </div>
        <div className="col-7">
          <BarChart
            options={{
              xtitle: 'Technologies',
              ytitle: 'Company Count',
              data: rowdata,
            }}
            highcharts={Highcharts}
          />
        </div>
      </div>
    ) : null;
  };
  const blocks =
    type === 'technology'
      ? [
          <BarChartRow
            validator={Object.keys(closestCompetitorsData).length}
            rowdata={closestCompetitorsData}
            title={`${data.title} vs Top competitors by company count`}
          />,
          <BarChartRow
            validator={Object.keys(competitorsBarData).length}
            rowdata={competitorsBarData}
            title={`Compare company count: ${data.title} vs. top 4 in category`}
          />,
          <BarChartRow
            validator={Object.keys(addsData).length}
            rowdata={addsData}
            title={`Compare companies added this month: ${data.title} vs. top 4 in category`}
          />,
          <BarChartRow
            validator={Object.keys(dropsData).length}
            rowdata={dropsData}
            title={`Compare companies dropped this month: ${data.title} vs. top 4 in category`}
          />,
          <PieChart
            title="Category Market Share"
            validator={competitorsPieData.length}
            options={{ data: competitorsPieData }}
            highcharts={Highcharts}
          />,
        ]
      : [];
  if (isLoading) {
    return <Spinner />;
  }
  const chartCompanySizeData = companySizeData.slice(0, 5);
  return (
    <div className="overview">
      <RowBlockContainer
        centerBlockNumber={2}
        equalRows={1}
        blocks={[
          ...blocks,
          <MapChart
            validator={mapData.length}
            options={{
              map: worldMapData,
              mapData,
              title: data.title,
              type: data.type,
            }}
            highcharts={Highcharts}
          />,
          <PieChart
            title={`${data.title} Customers by Size`}
            validator={chartCompanySizeData.length}
            options={{ data: chartCompanySizeData, half: true }}
            highcharts={Highcharts}
          />,
          <TreeMap
            validator={customerCategoryData.length}
            options={{
              title: `${data.title} Customers by Category`,
              data: customerCategoryData,
            }}
            highcharts={Highcharts}
          />,
        ]}
      />
    </div>
  );
};

export default Overview;

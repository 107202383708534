import React from 'react';
import { DatePicker } from 'antd';
import * as moment from 'moment';

const { RangePicker } = DatePicker;

const AddedDateRange = (props) => {
  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };
  return (
    <RangePicker
      disabledDate={disabledDate}
      style={{ width: '240px', height: '38px' }}
      {...props}
    />
  );
};

export default AddedDateRange;

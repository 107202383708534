/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Space, Tooltip, Typography } from 'antd';
import Link from 'UI/Link';
import Thumbnail from 'UI/Thumbnail';
import { PsychographicIcon } from 'UI/CustomIcon/CustomIcon';
import { TechnologyService } from 'api/services';
import { TYPES_MAPPER } from 'constants/constants';
import { Spinner } from 'components/common/loader';
import TechPsychContext from '../TechPsychContext';
import Listing from '../common/Listing';

const { Text } = Typography;

const { fetchKeywordCategories } = TechnologyService;

// TODO: check console error for text expecting string; getting object
const getThumbnail = (record) => {
  return record?.company_profile_image_url ? (
    <Thumbnail.Image size="small" imageUrl={record?.company_profile_image_url} />
  ) : (
    <Thumbnail.Icon size="small" Icon={PsychographicIcon} />
  );
};
const getColumns = (history) => {
  const handleClick = (record) => {
    const url = `/${TYPES_MAPPER.keyword}/${record._id}`;
    history.push(url, { from: history.location.pathname });
  };
  return [
    {
      title: 'PSYCHOGRAPHICS',
      dataIndex: 'company_name_label',
      key: 'company_name_label',
      // width: 300,
      render: (item, record) => {
        return (
          <div className="list-view-title">
            <Link
              style={{ padding: 0 }}
              text={getThumbnail(record)}
              onClick={() => handleClick(record)}
            />
            <Link
              onClick={() => handleClick(record)}
              type="text"
              style={{ padding: 0 }}
              text={
                <Tooltip mouseEnterDelay={0.3} placement="topLeft" title={item}>
                  <Space direction="vertical" size={0} align="start">
                    <Text
                      style={{
                        maxWidth: '200px',
                        color: '#29275f',
                      }}
                      ellipsis
                    >
                      {item}
                    </Text>
                    <Text
                      style={{
                        fontSize: '12px',
                        color: '#697390',
                        fontWeight: '10',
                        position: 'relative',
                        top: '-6px',
                      }}
                      ellipsis
                    >
                      {/* TODO: check data with Arijeet subcategory_label and subcategory */}
                      {record.subcategory_label}
                    </Text>
                  </Space>
                </Tooltip>
              }
            />
          </div>
        );
      },
    },
    {
      title: 'COMPANIES',
      dataIndex: 'domains',
      key: 'domains',
      // width: 300,
      align: 'center',
      render: (item, record) =>
        record.domains > 0 ? record.domains.toLocaleString('en-US') : record.domains,
    },
  ];
};
const Keyword = () => {
  const [isFilterDataLoading, setIsFilterDataLoading] = useState(true);
  const [filterData, setFilterData] = useState({});
  const history = useHistory();

  const getKeywordCategories = async () => {
    let filters = {};
    try {
      const response = await fetchKeywordCategories();
      filters = response?.data?.error ? {} : response.data.data || {};
      setFilterData(filters);
      setIsFilterDataLoading(false);
    } catch (e) {
      // TODO: handle error
    }
  };

  useEffect(() => {
    getKeywordCategories();
  }, []);

  const columns = getColumns(history);

  return (
    <>
      <TechPsychContext.Provider value="Keyword">
        {isFilterDataLoading ? (
          <Spinner />
        ) : (
          <Listing filterOptions={filterData} columns={columns} type="keyword" />
        )}
        ;
      </TechPsychContext.Provider>
    </>
  );
};

export default Keyword;

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import './exportFieldSelection.scss';
import { Collapse, Checkbox, Button, Typography, Form, Tag, Skeleton } from 'antd';
import { showError } from 'components/common/errorMessage';
import CustomDrawer from 'UI/CustomDrawer/CustomDrawer';
import DrawerTitle from 'UI/CustomDrawer/DrawerTitle';
import { CustomWarningAlert, CustomInfoAlert, CustomAlert } from 'UI/CustomAlert/CustomAlert';
import { CompanyIcon, TechnologyIcon, LeadIcon } from 'UI/CustomIcon/CustomIcon';
import { shallowEqual, useSelector } from 'react-redux';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import UpgradePlanLock from 'UI/UpgradePlanLock/UpgradePlanLock';
import {
  EXPORT_COMPANY_FIELD_MAPPING,
  EXPORT_EXTRA_MAPPING,
  EXPORT_LEAD_FIELD_MAPPINGS,
  LEAD_COMPANY_FIELD_MAPPINGS_EXTRA,
} from 'constants/constants';
import { endPoints, baseURL } from 'api/apiEndpoints';
import { getRequest } from 'api/apiRequests';
import { headerGenerator } from 'utils/common';
import TechnologyExportSelection from './technologyExportSelection';
import LimitLeadForm from './limitLeadsForm';
import scssVariables from '../../_vars.scss';

const { Panel } = Collapse;
const { Text } = Typography;

const initialValues = {
  lead: [],
  company: [],
  technology: [],
};
const CUSTOM_ALERT_TOTAL_COUNT = 10000;
const CUSTOM_CSV_ALERT_COUNT = 100;
const defaultUnselected = {
  lead: ['skills', 'interests'],
  company: [
    'company_funding_range',
    'revenue_range',
    'ebitda_range',
    'company_address',
    'zip_code',
  ],
};

const ExportSelection = ({
  onClose,
  onExport,
  visible,
  listType,
  isBulk,
  exportCount,
  csvCount,
  className = 'export-selection-container',
  disabledValues = [],
  disableTechnology = false,
  renderFrom,
  isLoading = false,
}) => {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const headers = headerGenerator(user.token, user.session_id);
  const [totalCount, setTotalCount] = useState();
  const [totalAvaliableCount, setTotalAvaliableCount] = useState();
  const [form] = Form.useForm();
  const [selectedCount, setSelectedCount] = useState({ lead: 0, company: 0, technology: 0 });
  const [loading, setLoading] = useState(false);
  const [checkAllMapping, setAllMapping] = useState(initialValues);
  const [checkBoxListMapping, setCheckBoxList] = useState(initialValues);
  const [limitLead, setLimitLead] = useState(null);
  const { credits } = useSelector((state) => state.common);
  const hasCredits = credits && credits.credits_left >= csvCount;

  useEffect(() => {
    getCatSubCategoriesList();
    return () => {
      resetAllSelected();
    };
  }, []);

  useEffect(() => {
    setTotalCount(getTotal());
  }, [selectedCount]);

  const saveUserPreferences = (values) => {
    localStorage.setItem(`${user.userId}_${listType}`, JSON.stringify(values));
  };

  const getSelectedCount = (object) => {
    return Object.keys(object).reduce(
      (acc, key) => ({ ...acc, ...{ [key]: object[key].length } }),
      { ...selectedCount }
    );
  };

  const getSavedPreferences = (mapping) => {
    const preferences = localStorage.getItem(`${user.userId}_${listType}`);
    let selectedPreference = {};
    try {
      if (typeof preferences === 'string') {
        selectedPreference = JSON.parse(preferences);
      } else {
        for (const key in initialValues) {
          if (key !== 'technology' && mapping[key]) {
            selectedPreference[key] = mapping[key].filter(
              (val) => !defaultUnselected[key].includes(val)
            );
          }
        }
      }
      if (disabledValues?.length) {
        selectedPreference.company = selectedPreference.company.filter(
          (value) => !disabledValues.includes(value)
        );
      }
      setSelectedCount(getSelectedCount(selectedPreference));
      return selectedPreference;
    } catch (e) {
      return initialValues;
    }
  };

  const getCompanyPageKeyMappings = () => {
    const companyPageMappings = EXPORT_COMPANY_FIELD_MAPPING.map((item) => {
      // Override the key value as its different for company export
      if (item.value === 'company_phone_numbers') {
        const newItem = { ...item };
        newItem.value = 'company_phone_number';
        return newItem;
      }
      return item;
    });
    return [...companyPageMappings, ...EXPORT_EXTRA_MAPPING];
  };

  const getFieldsAllowedInPlan = (mapping) => {
    if (disabledValues?.length) {
      return mapping.filter((map) => !disabledValues.includes(map.value));
    }
    return mapping;
  };

  const getCsvInfoType = (num) => {
    return num > CUSTOM_CSV_ALERT_COUNT;
  };

  const handelSelectionForListType = (technologies) => {
    const listMapping =
      listType === 'company'
        ? {
            company: getCompanyPageKeyMappings(),
            technology: technologies,
          }
        : {
            lead: EXPORT_LEAD_FIELD_MAPPINGS,
            company: [
              ...EXPORT_COMPANY_FIELD_MAPPING,
              ...EXPORT_EXTRA_MAPPING,
              ...LEAD_COMPANY_FIELD_MAPPINGS_EXTRA,
            ],
            technology: technologies,
          };

    const allMapping = Object.keys(listMapping).reduce((acc, key) => {
      acc[key] = listMapping[key].map((item) => item.value);
      return acc;
    }, {});

    allMapping.technology = allMapping.technology.flat();

    const { company, lead, technology } = allMapping;
    if (listType === 'company') {
      setTotalAvaliableCount(company.length + technology.length);
    } else {
      setTotalAvaliableCount(lead.length + company.length + technology.length);
    }
    setAllMapping(allMapping);
    setCheckBoxList(listMapping);
    form.setFieldsValue(getSavedPreferences(allMapping));
  };

  const getTotal = () => selectedCount.lead + selectedCount.company + selectedCount.technology;

  const getCatSubCategoriesList = async () => {
    setLoading(true);
    const data = await getRequest(`${baseURL}${endPoints.techCatSubcatList}`, {}, headers);
    if (!data.error) {
      const techData = Object.entries(data).map(([key, value]) => ({
        label: key,
        value: [...new Set(value.map((val) => val.subCat))],
      }));
      handelSelectionForListType(techData);
    }
    setLoading(false);
  };

  const setAllSelected = () => {
    form.setFieldsValue({ select_all: true });
    form.setFieldsValue(checkAllMapping);
    const leads = form.getFieldValue('lead')?.length || 0;
    const companies = form.getFieldValue('company')?.length || 0;
    const technologis = form.getFieldValue('technology')?.length || 0;
    setSelectedCount({ lead: leads, company: companies, technology: technologis });
  };

  const resetAllSelected = () => {
    form.resetFields();
    setSelectedCount({ lead: 0, company: 0, technology: 0 });
  };

  const onValuesChange = (selction) => {
    const [key] = Object.keys(selction);
    const values = selction[key];
    if (key in initialValues) {
      setSelectedCount({ ...selectedCount, [key]: values.length });
    }
  };

  const checkUnChekFields = (type, isCheked) => {
    form.setFieldsValue({ [type]: isCheked ? checkAllMapping[type] : [] });
    const count = form.getFieldValue(type)?.length || 0;
    setSelectedCount({ ...selectedCount, [type]: count });
  };

  const hasSelection = useCallback(
    (type) => {
      if (!type) return false;
      return type !== 'all'
        ? !!selectedCount[type] && selectedCount[type] < checkAllMapping[type].length
        : !!totalCount && totalCount < totalAvaliableCount;
    },
    [totalAvaliableCount, totalCount]
  );

  const hasCheckedAll = useCallback(
    (type) => {
      if (!type) return false;
      return type !== 'all'
        ? !!selectedCount[type] && selectedCount[type] === checkAllMapping[type].length
        : !!totalCount && totalCount === totalAvaliableCount;
    },
    [totalCount, totalAvaliableCount]
  );

  const handleExport = () => {
    const formItems = form.getFieldsValue(true);
    const exportKeys = {
      projection: {
        import_fields: [...formItems.lead, ...formItems.company],
        tech_subcategories: hasCheckedAll('technology') ? [] : formItems.technology,
        lead: [...formItems.lead],
        company: [...formItems.company],
      },
    };
    if (formItems.technology.length) {
      exportKeys.projection.import_fields.push('technologies_mapper');
      exportKeys.projection[listType].push('technologies_mapper');
    }
    if (limitLead && limitLead.export_type) {
      exportKeys.export_type = 'limit_leads_per_company';
      exportKeys.export_count = limitLead.export_count;
      exportKeys.user_max_records_per_company = limitLead.user_max_records_per_company;
      if (!exportKeys.export_count || !exportKeys.user_max_records_per_company) {
        showError('We can’t limit people per company until you tell us how many');
        return;
      }
      if (exportKeys.export_count > exportCount) {
        showError(`Total records should be between 1 and ${exportCount}`);
        return;
      }
      if (exportKeys.export_count < exportKeys.user_max_records_per_company) {
        showError(
          'Uh-oh! The total number of people needs to be more than the number of records per company'
        );
        return;
      }
    }
    saveUserPreferences(formItems);
    onExport(exportKeys);
  };

  const handleCancel = () => {
    resetAllSelected();
    onClose();
  };

  // const title = useMemo(() => {
  //   return (
  //     <div className="export-drawer__title">
  //       <Title className="export-drawer__title_heading" >PUSH TO PIPEDRIVE</Title>
  //       <Space size={8}>
  //         <Button
  //           disabled={isLoading}
  //           theme="secondary"
  //           onClick={() => {
  //             setSelectedOption('');
  //           }}
  //           title="Cancel"
  //         />
  //         <Button
  //           theme="primary"
  //           loading={isLoading}
  //           onClick={() => {
  //             exportToPipedrive();
  //           }}
  //           disabled={!hasCredits || !totalCount}
  //           title="Confirm"
  //         />
  //       </Space>
  //     </div>
  //   );
  // }, [isLoading]);

  const getDisabledValues = () => {
    const options = EXPORT_COMPANY_FIELD_MAPPING.filter((item) =>
      disabledValues.includes(item.value)
    );
    return options;
  };

  const expandIcon = ({ isActive }) =>
    isActive ? (
      <MinusOutlined style={{ color: scssVariables.white }} />
    ) : (
      <PlusOutlined style={{ color: scssVariables.white }} />
    );

  const content = (
    <div className={className}>
      {isBulk && listType === 'lead' && (
        <LimitLeadForm exportCount={exportCount} onSubmit={setLimitLead} />
      )}
      {csvCount &&
        (getCsvInfoType(csvCount) ? (
          <CustomInfoAlert
            description={
              csvCount > CUSTOM_ALERT_TOTAL_COUNT
                ? 'Exporting more than 10,000 records might take up to an hour.'
                : 'Exporting more than 100 records might take up to 15 minutes.'
            }
            message={`${csvCount} records selected`}
          />
        ) : (
          <CustomAlert message={`${csvCount} records selected`} />
        ))}
      {!hasCredits && csvCount ? (
        <CustomWarningAlert
          className="custom-warning-alert"
          message="Insufficient credits for export. Talk to your Customer Success Manager to get credits"
        />
      ) : null}

      <Form
        form={form}
        initialValues={initialValues}
        name="export-selection"
        onValuesChange={onValuesChange}
        colon={false}
        autoComplete="off"
      >
        <div className="export-selection-header">
          <Form.Item
            name="select_all"
            // rules={[{ required: true, message: 'Please Select atleast one csv column key' }]}
          >
            <Checkbox
              style={{ fontWeight: '600' }}
              indeterminate={hasSelection('all')}
              checked={hasCheckedAll('all')}
              onChange={({ target }) => (target.checked ? setAllSelected() : resetAllSelected())}
            >
              Select all
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button style={{ fontWeight: '600' }} type="link" onClick={resetAllSelected}>
              Clear all
              {totalCount > 0 ? ` (${totalCount})` : ''}
            </Button>
          </Form.Item>
        </div>
        <div className="export-selection-content">
          <Collapse
            accordion
            defaultActiveKey={[listType]}
            bordered={false}
            expandIcon={expandIcon}
            expandIconPosition="right"
          >
            {listType === 'lead' && (
              <Panel
                key="lead"
                header={
                  <div className="expand-filter-header">
                    <div role="presentation" onClick={(event) => event.stopPropagation()}>
                      <Form.Item>
                        <Checkbox
                          indeterminate={hasSelection('lead')}
                          checked={hasCheckedAll('lead')}
                          onChange={({ target }) => checkUnChekFields('lead', target.checked)}
                        />
                      </Form.Item>
                    </div>
                    <div className="filter-header-text">
                      <span className="filter-header-icon">
                        <LeadIcon
                          style={{
                            // borderRadius: '35%',
                            height: '26px',
                          }}
                          width="26"
                          fill="#697390"
                        />
                      </span>
                      <span>
                        <Text>People Fields</Text>
                      </span>
                      <span>
                        {selectedCount.lead > 0 && (
                          <Tag closable onClose={() => checkUnChekFields('lead', false)}>
                            {' '}
                            {selectedCount.lead}{' '}
                          </Tag>
                        )}
                      </span>
                    </div>
                  </div>
                }
              >
                <Skeleton loading={loading}>
                  <Form.Item name="lead">
                    <Checkbox.Group
                      className="export-selection-lead"
                      options={checkBoxListMapping.lead}
                    />
                  </Form.Item>
                </Skeleton>
              </Panel>
            )}
            <Panel
              key="company"
              header={
                <div className="expand-filter-header">
                  <div role="presentation" onClick={(event) => event.stopPropagation()}>
                    <Form.Item>
                      <Checkbox
                        indeterminate={hasSelection('company')}
                        checked={hasCheckedAll('company')}
                        onChange={({ target }) => checkUnChekFields('company', target.checked)}
                      />
                    </Form.Item>
                  </div>
                  <div className="filter-header-text">
                    <span className="filter-header-icon">
                      <CompanyIcon fill="#697390" width="20" style={{ height: '16px' }} />
                    </span>
                    <span>
                      <Text>Company Fields</Text>
                    </span>
                    <span>
                      {selectedCount.company > 0 && (
                        <Tag closable onClose={() => checkUnChekFields('company', false)}>
                          {selectedCount.company}
                        </Tag>
                      )}
                    </span>
                  </div>
                </div>
              }
            >
              <Skeleton loading={loading}>
                <Form.Item name="company">
                  <Checkbox.Group
                    options={checkBoxListMapping.company}
                    className="export-selection-company"
                  />
                </Form.Item>
                {disabledValues?.length > 0 && (
                  <Checkbox.Group
                    options={getDisabledValues()}
                    className="export-selection-company"
                    disabled
                  />
                )}
              </Skeleton>
            </Panel>
            <Panel
              key="technology"
              disabled={disableTechnology}
              header={
                <div className="expand-filter-header">
                  <div role="presentation" onClick={(event) => event.stopPropagation()}>
                    <Form.Item>
                      <Checkbox
                        disabled={disableTechnology}
                        indeterminate={hasSelection('technology')}
                        checked={hasCheckedAll('technology')}
                        onChange={({ target }) => checkUnChekFields('technology', target.checked)}
                      />
                    </Form.Item>
                  </div>
                  <div className="filter-header-text">
                    <span className="filter-header-icon">
                      <TechnologyIcon fill="#697390" width="20" style={{ height: '16px' }} />
                    </span>
                    <span>
                      <Text>Technology Fields</Text>
                    </span>
                    <span>
                      {selectedCount.technology > 0 && (
                        <Tag closable onClose={() => checkUnChekFields('technology', false)}>
                          {selectedCount.technology}
                        </Tag>
                      )}
                    </span>
                    <span>
                      <UpgradePlanLock isVisible={disableTechnology} />
                    </span>
                  </div>
                </div>
              }
            >
              <Form.Item name="technology">
                <TechnologyExportSelection
                  renderFrom={renderFrom}
                  onChange={(values) => form.setFieldsValue({ technology: values })}
                  technologies={checkBoxListMapping.technology}
                  totalAvailable={checkAllMapping.technology.length}
                  selectedTech={form.getFieldValue('technology')}
                />
              </Form.Item>
            </Panel>
          </Collapse>
        </div>
      </Form>
    </div>
  );

  return (
    <CustomDrawer
      destroyOnClose
      content={content}
      onClose={handleCancel}
      maskClosable={false}
      title={
        <DrawerTitle
          title="EXPORT CSV"
          actions={[
            {
              title: 'Cancel',
              theme: 'secondary',
              onClick: handleCancel,
              disabled: isLoading,
            },
            {
              title: 'Export',
              theme: 'primary',
              loading: isLoading,
              onClick: handleExport,
              disabled: !totalCount || (!exportCount && (!csvCount || !hasCredits)),
            },
          ]}
        />
      }
      // visible
      visible={visible}
    />
  );
};

ExportSelection.propTypes = {
  onClose: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  listType: PropTypes.string,
  isBulk: PropTypes.bool,
  exportCount: PropTypes.number,
  selectedValues: PropTypes.object.isRequired,
  disabledValues: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    })
  ),
  disableTechnology: PropTypes.bool,
};

export default ExportSelection;

/* eslint-disable react/prop-types */
import React from 'react';
import LeadFilter from './LeadFilter';
import CompanyFilter from './CompanyFilter';

const ListFilter = ({ listInfo, onFilterChanged }) => {
  return (
    <>
      {listInfo && listInfo.listType === 'lead' ? <LeadFilter onFilterChanged={onFilterChanged} /> : <CompanyFilter onFilterChanged={onFilterChanged} />}
    </>
  );
};

export default ListFilter;

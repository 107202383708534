import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { Select } from 'antd';
// eslint-disable-next-line no-restricted-imports
import Multiselect from 'components/filters/multipleSelect';
import './Combobox.scss';

const Combobox = ({ options, type, value, onChange, placeholder, textSeparator, ...props }) => {
  const [selectedValues, setSelected] = useState([]);
  const [isPlaceholder, setIsPlaceholder] = useState(true);

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  const handleChange = (values) => {
    setSelected(values);
    setIsPlaceholder(true);
    onChange(values);
  };

  const handleSearch = (val) => {
    if (val) {
      setIsPlaceholder(false);
    } else {
      setIsPlaceholder(true);
    }
  };

  return (
    <div className="slintel-combobox">
      {isPlaceholder && <span className="custom-placeholder">{placeholder}</span>}
      <Multiselect
        {...props}
        textSeparator={textSeparator}
        autoClearSearchValue
        allowClear
        placeholder=""
        value={selectedValues}
        tokenSeparators={[';']}
        type={type}
        onChange={handleChange}
        style={{ width: '100%' }}
        options={options}
        handleSearch={handleSearch}
      />
    </div>
  );
};

Combobox.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  type: PropTypes.string,
  selectedValues: PropTypes.array,
  handleSearch: PropTypes.func,
  value: PropTypes.array,
  placeholder: PropTypes.string,
};

Combobox.defaultProps = {
  options: [
    { label: 'Option1', value: 'Option1' },
    { label: 'Option2', value: 'Option2' },
  ],
  type: 'key-value',
  selectedValues: [],
  handleSearch: () => {},
  value: [],
  placeholder: 'Search',
};

export default Combobox;

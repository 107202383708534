import React, { useState } from 'react';
import { Tabs, Card } from 'antd';
// import { Sticky, StickyContainer } from 'react-sticky';

const { TabPane } = Tabs;
const renderTabBar = (props, DefaultTabBar) => (
  <DefaultTabBar {...props} className="lc-detail-custom-tabbar" />
);

const getTabContent = (name, isBeta) => {
  return (
    <div className="d-flex">
      {name}
      {isBeta ? <div className="beta-tag">Beta</div> : null}
    </div>
  );
};

const TabList = ({ tabsData = [] }) => {
  const [selectedTab, setSelectedTab] = useState(tabsData?.[0]?.key);

  return (
    <Card className="inner-tab-container m-0">
      <div>
        <Tabs
          size="large"
          activeKey={selectedTab}
          tabPosition="top"
          className="list-tab ms-0"
          onChange={setSelectedTab}
          renderTabBar={renderTabBar}
          style={{ overflow: 'unset' }}
        >
          {tabsData.map(({ name, key, component, isBeta = false }) => {
            return (
              <TabPane tab={getTabContent(name, isBeta)} key={key}>
                {component}
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </Card>
  );
};
export default TabList;

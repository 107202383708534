/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';

import { EnvironmentOutlined } from '@ant-design/icons';
import Thumbnail from 'UI/Thumbnail';
import { CompanyIcon } from 'UI/CustomIcon/CustomIcon';
import './company-avatar-info.scss';
import { getClickableLink } from 'utils/common';

function CompanyInfoDetails(props) {
  const { location, website } = props;

  return (
    <div className="company-info-details">
      <div className="company-info-details__website">{website}</div>
      <div className="company-info-details__location">
        <EnvironmentOutlined />
        &nbsp;
        {location}
      </div>
    </div>
  );
}

export default function CompanyAvatarInfo(props) {
  const { company, openQuickView, rowIndex, activeCompanyId } = props;

  const handleClick = () => {
    const data = {
      id: { value: company?.id },
      name: company.name,
      index: rowIndex,
    };
    openQuickView({ data, view: 'company' });
  };

  const getThumbnail = useCallback((record) => {
    return (
      <div className="thumbnail-container" onClick={handleClick}>
        {record?.logo ? (
          <Thumbnail.Image size="small" imageUrl={getClickableLink(record?.logo)} />
        ) : (
          <Thumbnail.Icon size="small" Icon={CompanyIcon} />
        )}
      </div>
    );
  }, []);

  const isActive = () => activeCompanyId === company?.id;

  return (
    <Card className="company-avatar-info" bordered={false}>
      <Card.Meta
        avatar={getThumbnail(company)}
        title={
          <div className="linked-title linked-title--active">
            <span onClick={handleClick} className={isActive() ? 'active-company-quickview' : ''}>
              {company.name}
            </span>
          </div>
        }
        description={
          <CompanyInfoDetails
            website={company.website}
            location={`${company.location[0]}, ${company.location[2]}`}
          />
        }
      />
    </Card>
  );
}

CompanyAvatarInfo.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    website: PropTypes.string.isRequired,
    location: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  openQuickView: PropTypes.func.isRequired,
  rowIndex: PropTypes.number.isRequired,
  activeCompanyId: PropTypes.string.isRequired,
};

CompanyInfoDetails.propTypes = {
  website: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
};

/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Spin, Layout, Card } from 'antd';
import NewsFeed from './news-feed';

const { Content } = Layout;
export default class FeedTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const { activeTab, loading } = this.state;
    // eslint-disable-next-line react/prop-types
    const { tabs, togglePreferencesDrawerVisibility, mixpanel, userId } = this.props;
    // const companiesInsightsRelativeUrl = '/#/companies';

    return (
      <Layout className="outer-tab-container">
        <Content className="outer-tab-content">
          <Card className="inner-tab-container">
            <Spin spinning={loading}>
              <div className="news-feed-filter-tabs">
                <Tabs
                  size="large"
                  activeKey={activeTab}
                  tabPosition="top"
                  style={{ height: '100vh' - '300px' }}
                  className="filter-tabs"
                  onChange={this.onFilterTabChange}
                >
                  <Tabs.TabPane tab="Recommended" key="preferences">
                    <div className="filter-tabs--feed">
                      <NewsFeed
                        togglePreferencesDrawerVisibility={togglePreferencesDrawerVisibility}
                        companiesFilter={tabs.preferences}
                        mixpanel={mixpanel}
                        type="preferences"
                        userId={userId}
                      />
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Global" key="global">
                    <div className="filter-tabs--feed">
                      <NewsFeed
                        togglePreferencesDrawerVisibility={togglePreferencesDrawerVisibility}
                        mixpanel={mixpanel}
                        type="global"
                        userId={userId}
                      />
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </Spin>
          </Card>
        </Content>
      </Layout>
    );
  }
}

FeedTabs.propTypes = {
  tabs: PropTypes.shape({
    filters: PropTypes.arrayOf(PropTypes.shape({})),
    preferences: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  togglePreferencesDrawerVisibility: PropTypes.func.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func,
  }),
  userId: PropTypes.number.isRequired,
};

FeedTabs.defaultProps = {
  mixpanel: null,
};

/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { setCookie } from 'utils/cookie';
import { USER_ROLES_CONSTANT } from 'constants/constants';
import {
  VERIFICATION_SUCCESS,
  VERIFICATION_FAILURE,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAILURE,
  CSRF_SUCCESS,
  CSRF_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  PROMOCODE_APPLY_RESET,
  PROMOCODE_APPLY_SUCCESS,
  PROMOCODE_APPLY_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from 'store/actionTypes';

const initialState = {
  userData: {},
  csrfToken: undefined,
  errorMessage: undefined,
  successMessage: '',
  csrfError: '',
  verificationData: undefined,
  verificationError: undefined,
  loginData: undefined,
  loginError: '',
  forgotSuccess: undefined,
  forgotError: undefined,
  resetSuccess: undefined,
  resetError: undefined,
  promoSuccess: undefined,
  promoError: undefined,
};

const UsersRegister = (state = initialState, action) => {
  switch (action.type) {
    case USER_CREATE_SUCCESS: {
      const { data } = action;
      let { userData } = state;
      userData = data.data;
      return {
        ...state,
        userData,
        errorMessage: undefined,
        verificationData: undefined,
        verificationError: undefined,
        successMessage: 'User has been created',
      };
    }
    case USER_CREATE_FAILURE: {
      const { error } = action;
      return {
        ...state,
        errorMessage: error,
        verificationData: undefined,
        verificationError: undefined,
        successMessage: '',
      };
    }
    case CSRF_SUCCESS: {
      const { data } = action;
      let { csrfToken } = state;
      csrfToken = data.csrfToken;
      return {
        ...state,
        csrfToken,
      };
    }
    case CSRF_FAILURE: {
      const { error } = action;
      return {
        ...state,
        csrfError: error,
      };
    }
    case VERIFICATION_SUCCESS: {
      const { data } = action;
      let { verificationData } = state;
      verificationData = data;
      return {
        ...state,
        verificationData,
        verificationError: undefined,
      };
    }
    case VERIFICATION_FAILURE: {
      const { error } = action;
      return {
        ...state,
        verificationData: undefined,
        verificationError: error,
      };
    }
    case LOGIN_SUCCESS: {
      const { data } = action;
      let { loginData } = state;
      loginData = data.data;

      const diff = loginData.data.exp - loginData.data.iat;
      const date = new Date();
      const currentTimeStamp = date.getTime();
      const expires = new Date(currentTimeStamp + diff * 1000);

      const dataToStore = {
        token: loginData.token,
        userName: loginData.data.name,
        userEmail: loginData.data.email,
        userRole: loginData.data.role,
        userId: loginData.data.userId,
        has_onboarded: loginData.data.has_onboarded,
        designation: loginData.data.designation,
        company: loginData.data.company,
        phone: loginData.data.phone,
        company_size: loginData.data.company_size,
        lead_source: loginData.data.lead_source,
        industry: loginData.data.industry,
        session_id: loginData.session_id,
        expires,
        createdAt: loginData.data.created_at,
        user_access_role: loginData.data.user_access_role,
        accountId: loginData.data.accountId,
        last_login_at: loginData.data.last_login_at,
      };
      setCookie('userToken', dataToStore, expires);

      // IsFreeTrialExpired
      const keyRole = Object.keys(USER_ROLES_CONSTANT).find(
        (key) => USER_ROLES_CONSTANT[key] === 'FREETRIAL Pack'
      );
      if (loginData.data.role === keyRole) {
        if (
          typeof loginData.data.account_expires_in !== 'undefined' &&
          loginData.data.account_expires_in > 0
        ) {
          const freeTrialExpiredTime = new Date(
            currentTimeStamp + loginData.data.account_expires_in * 1000
          );
          setCookie('isFreeTrialExpired', false, freeTrialExpiredTime);
        } else {
          const freeTrialExpiredTime = new Date(
            currentTimeStamp + loginData.data.account_expires_in * 1000
          );
          setCookie('isFreeTrialExpired', true, freeTrialExpiredTime);
        }
      }
      return {
        ...state,
        loginData,
        loginError: '',
      };
    }
    case LOGIN_FAILURE: {
      const { error } = action;
      return {
        ...state,
        loginError: error,
      };
    }
    case LOGOUT_SUCCESS: {
      return {
        ...state,
      };
    }
    case LOGOUT_FAILURE: {
      const { error } = action;
      return {
        ...state,
        logoutError: error,
      };
    }
    case FORGOT_PASSWORD_SUCCESS: {
      const { data } = action;
      let { forgotSuccess } = state;
      forgotSuccess = data;
      return {
        ...state,
        forgotSuccess,
        forgotError: undefined,
      };
    }
    case FORGOT_PASSWORD_FAILURE: {
      const { error } = action;
      return {
        ...state,
        forgotError: error,
        forgotSuccess: undefined,
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      const { data } = action;
      let { resetSuccess } = state;
      resetSuccess = data;
      return {
        ...state,
        resetSuccess,
        resetError: undefined,
      };
    }
    case RESET_PASSWORD_FAILURE: {
      const { error } = action;
      return {
        ...state,
        resetError: error,
        resetSuccess: undefined,
      };
    }
    case PROMOCODE_APPLY_SUCCESS: {
      const { data } = action;
      let { promoSuccess } = state;
      promoSuccess = data;
      return {
        ...state,
        promoSuccess,
        promoError: undefined,
      };
    }
    case PROMOCODE_APPLY_FAILURE: {
      const { error } = action;
      return {
        ...state,
        promoError: error,
        promoSuccess: undefined,
      };
    }
    case PROMOCODE_APPLY_RESET: {
      return {
        ...state,
        promoError: undefined,
        promoSuccess: undefined,
      };
    }
    default:
      return state;
  }
};

export default UsersRegister;

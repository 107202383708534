import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import * as PropTypes from 'prop-types';
import { Layout } from 'antd';
import Header from 'components/common/header';
import Loader from 'components/common/loader';
import CreditLimitModal from 'components/credit/creditLimitModal';
import SideNavbar from 'UI/SideNavbar/SideNavbar';
import UpgradeModal from 'components/common/upgradeModal';
import ReportDrawer from 'UI/ReportDrawer';
import { CommonService } from 'api/services';
import { headerGenerator } from 'utils/common';

const { Content } = Layout;

const DashboardLayout = ({ children, authDetails }) => {
  return (
    <Layout>
      <Loader />
      <SideNavbar credits={authDetails.credits} planPermission={authDetails.planPermission} />
      <CreditLimitModal />
      <UpgradeModal />
      <ReportDrawer />
      <Layout className="site-layout">
        <Header />
        <Content className="site-layout-background">{children}</Content>
      </Layout>
    </Layout>
  );
};
DashboardLayout.propTypes = {
  children: PropTypes.func,
  authDetails: PropTypes.object,
};

const PrivateRoute = ({ component: Component, authDetails, path, ...rest }) => {
  const planPermission = useSelector((state) => state.common.planPermission);
  const user = useSelector((state) => state.auth.user, shallowEqual);

  const getLockedPages = () => {
    return planPermission?.notAllowed?.page || [];
  };

  const checkIsLocked = (pages) => {
    const [, currentPath] = path.split('/');
    return currentPath && pages.includes(currentPath);
  };

  const checkIfPageLocked = () => {
    const lockedPages = getLockedPages();
    const pages = lockedPages.map((page) => page.name);
    return checkIsLocked(pages);
  };

  // will check for valid token before loading component
  // changes made for https://6sense.atlassian.net/browse/APP-5833
  const checkIfUserTokenValid = async () => {
    try {
      const headers = headerGenerator(user.token, user.session_id);
      const response = (await CommonService.validateUserToken(headers)) || {};
      const isValidUserToken = response.isValidUserToken || false;
      if (!isValidUserToken) {
        sessionStorage.setItem('showTimeoutModal', true);
      }
      return isValidUserToken;
    } catch (ex) {
      // keeping default to invalidToken
      return false;
    }
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authDetails.isLogin || !checkIfUserTokenValid()) {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }

        const isLocked = checkIfPageLocked();
        if (isLocked) {
          return <Redirect to={{ pathname: '/discover/lead', state: { from: props.location } }} />;
        }

        // authorized so return component
        return (
          <DashboardLayout authDetails={authDetails}>
            <Component {...props} />
          </DashboardLayout>
        );
      }}
    />
  );
};
PrivateRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.object,
  authDetails: PropTypes.object,
  path: PropTypes.string,
};
export default PrivateRoute;

import { put, takeLatest, call } from 'redux-saga/effects';
import { baseURL, endPoints } from 'api/apiEndpoints';
import { FIELDMAPPINGS_DELETE, OUTREACH_INIT } from 'store/actionTypes';
import { getRequest, deleteRequest } from 'api/apiRequests';
import {
  getOutreachConfigSuccess,
  getOutreachConfigFailure,
  deleteFieldMappingByIdSuccess,
  fieldMappingFailure,
} from './outreachActions';

function* deleteFieldMappingsById(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.outreachSettings}/${params.id}`;
    const response = yield call(deleteRequest, url, {}, headers);
    if (!response.error) {
      yield put(deleteFieldMappingByIdSuccess(response));
    } else {
      yield put(fieldMappingFailure(response.message));
    }
  } catch (err) {
    yield put(fieldMappingFailure(err.message));
  }
}

function* getOutreachConfig(action) {
  try {
    const { headers, params } = action.payload;
    const url = `${baseURL}${endPoints.outreachSettings}`;
    const data = yield call(getRequest, url, params, headers);
    if (!data.error) {
      yield put(getOutreachConfigSuccess(data));
    } else {
      yield put(getOutreachConfigFailure(data.message));
    }
  } catch (err) {
    yield put(getOutreachConfigFailure(err.message));
  }
}

export function* getOutreachSettings() {
  yield takeLatest(OUTREACH_INIT, getOutreachConfig);
}
export function* deleteFieldMappings() {
  yield takeLatest(FIELDMAPPINGS_DELETE, deleteFieldMappingsById);
}

/* eslint-disable no-nested-ternary */
import React from 'react';
import { Typography, Space, Divider, Button } from 'antd';
import {
  JobPostingIcon,
  CompanyIcon,
  PhoneIcon,
  EmailIcon,
  LocationIcon,
  SocialLinksIcon,
  FunctionIcon,
  DivisionIcon,
  SectorIcon,
  IndustryIcon,
  DecisionMakingPowerIcon,
  EducationIcon,
  CalenderIcon,
} from 'UI/CustomIcon/CustomIcon';
import QuickViewCard from 'UI/QuickViewCard/QuickViewCard';
import KeyValueWithIcon from 'UI/KeyValueWithIcon/KeyValueWithIcon';
import { getClickableLink, arrayToCommaSeperatedString, isCopyEnabled } from 'utils/common';
import InstaRevealPhone from 'components/common/instaRevealPhone';
import DecisionMakingCircle from 'components/common/DecisionMakingCircle';
import InstaRevealEmail from 'components/common/instaRevealEmail';
import PillsGroup from 'UI/PillsGroup/PillsGroup';
import SocialLinks from 'UI/SocialLinks/SocialLinks';
import TechnographicsView from 'components/discovery/quickView/TechnographicsView/TechnographicsView';
import scssVariables from 'src/_vars.scss';
import { eventActions } from 'utils/eventManager';
import { APP_ROUTE_SRC } from 'constants/multimedia';
import Copy from 'UI/Copy/Copy';

const { Text } = Typography;

// const aboutDataList = [
//   { title: 'Function', value: 'Design', icon: <FunctionIcon width="15px" fill={scssVariables.grey} /> },
//   { title: 'Division', value: 'Ecommerce', icon: <DivisionIcon width="15px" fill={scssVariables.grey} /> },
//   {
//     title: 'Sector',
//     value: 'Healthcare and Lifesciences',
//     icon: <SectorIcon width="15px" fill={scssVariables.grey} />,
//   },
//   { title: 'Industry', value: 'Ecommerce', icon: <IndustryIcon width="15px" fill={scssVariables.grey} /> },
//   {
//     title: 'Decision making power',
//     value: 'Medium',
//     icon: <DecisionMakingPowerIcon width="15px" fill={scssVariables.grey} />,
//   },
// ];

// const expData = [
//   {
//     titleIcon: {
//       size: 'small',
//       Icon: FundingIcon,
//       backgroundColor: scssVariables.mildYellow,
//       iconColor: 'white',
//     },
//     title: 'Group Marketing Manager',
//     subTitle: 'Mailchimp',
//     isActionBtn: true,
//     content: [
//       {
//         title: 'Year',
//         value: <Text>Jun 2019 Nov 2019</Text>,
//         icon: <YearIcon width="15px" fill={scssVariables.grey} />,
//       },
//       {
//         title: 'Location',
//         value: <Text>Bangalore</Text>,
//         icon: <LocationIcon width="15px" fill={scssVariables.grey} />,
//       },
//       {
//         title: 'Web / Social Media',
//         value: <Text>mailchimp.com</Text>,
//         icon: <SocialLinksIcon width="15px" fill={scssVariables.grey} />,
//       },
//     ],
//   },
// ];
// const educationData = [
//   {
//     titleIcon: {
//       size: 'small',
//       Icon: EducationIcon,
//       backgroundColor: scssVariables.mildYellow,
//       iconColor: 'white',
//     },
//     title: 'Group Marketing Manager',
//     subTitle: 'Mailchimp',
//     isActionBtn: false,
//     content: [
//       {
//         title: 'Funding amount',
//         value: <Text>10M</Text>,
//         icon: <YearIcon width="15px" fill={scssVariables.grey} />,
//       },
//       {
//         title: 'Investors',
//         value: <Text>Manhattan Venture Partners Soft Bank Vision Fund</Text>,
//         icon: <LocationIcon width="15px" fill={scssVariables.grey} />,
//       },
//     ],
//   },
//   {
//     titleIcon: {
//       size: 'small',
//       Icon: EducationIcon,
//       backgroundColor: scssVariables.mildYellow,
//       iconColor: 'white',
//     },
//     title: 'Group Marketing Manager',
//     subTitle: 'Mailchimp',
//     isActionBtn: false,
//     content: [
//       {
//         title: 'Funding amount',
//         value: <Text>10M</Text>,
//         icon: <YearIcon width="15px" fill={scssVariables.grey} />,
//       },
//       {
//         title: 'Investors',
//         value: <Text>Manhattan Venture Partners Soft Bank Vision Fund</Text>,
//         icon: <LocationIcon width="15px" fill={scssVariables.grey} />,
//       },
//     ],
//   },
// ];

const decisionMakingPowerMapping = {
  High: 0,
  Medium: 1,
  Low: 2,
};

const getExperienceContent = (item) => {
  const list = [];
  if (item.start_date?.length && item.end_date?.length) {
    list.push({
      title: 'Year',
      value: (
        <Text>
          {item.start_date[0]} - {item.end_date[0]}
        </Text>
      ),
      icon: <CalenderIcon width="15px" fill={scssVariables.grey} />,
    });
  }
  if (item.location) {
    list.push({
      title: 'Location',
      value: <Text>{Object.values(item.location).join(', ')}</Text>,
      icon: <LocationIcon width="15px" fill={scssVariables.grey} />,
    });
  }
  if (item.company_website) {
    list.push({
      title: 'Web / Social Media',
      value: <Text>{item.company_website}</Text>,
      icon: <SocialLinksIcon width="15px" fill={scssVariables.grey} />,
    });
  }
  return list;
};

const getEducationContent = (item) => {
  const list = [];
  if (item.start_date && item.end_date) {
    list.push({
      title: 'Year',
      value: (
        <Text>
          {item.start_date} - {item.end_date}
        </Text>
      ),
      icon: <CalenderIcon width="15px" fill={scssVariables.grey} />,
    });
  }
  if (item.school_location) {
    list.push({
      title: 'Location',
      value: <Text>{item.school_location}</Text>,
      icon: <LocationIcon width="15px" fill={scssVariables.grey} />,
    });
  }
  return list;
};

const LeadQuickView = ({
  quickViewData,
  leadsExtraDetails = {},
  requestEmail,
  requestPhoneNumber,
  technographicsData = {},
  viewName,
  sendEvent,
}) => {
  const getInstaEmailRes = (res, item) => {
    requestEmail(item.id.value, res);
  };

  const getInstaPhoneRes = (res, item) => {
    requestPhoneNumber(item.id.value, res);
  };

  const getContent = () => {
    return (
      <>
        <Space className="space-full-width" direction="vertical" size={20}>
          {/* <div className="quick-action-bar">
            <Space size={16}>
              <AddToListButton
                onAddToList={() => dispatch(openAddtoList({ ids: [quickViewData?.id?.value] }))}
              />
              <ExportPopover tab="lead" id={quickViewData.id && quickViewData.id.value} />
            </Space>
            <ExtraActionPopover viewName={viewName} />
          </div> */}
          <div className="quick-view-content">
            <Space className="space-full-width" direction="vertical" size={32}>
              <div className="quick-view-info">
                <Space direction="vertical" size={6}>
                  {quickViewData.lead_titles && quickViewData.lead_titles.value ? (
                    <div className="info-block">
                      <div>
                        <JobPostingIcon width="15px" fill={scssVariables.grey} />
                      </div>
                      <div className="info-content-block">
                        <div className="content-title">
                          <Text>{quickViewData.lead_titles.value}</Text>
                        </div>
                        {/* <div className="content-small">
                      <Space size={6}>
                        <div className="content-key">Earlier</div>
                        <div className="content-value">Product Designer</div>
                      </Space>
                    </div> */}
                      </div>
                    </div>
                  ) : null}

                  {quickViewData.company_name && quickViewData.company_name.value ? (
                    <div className="info-block">
                      <div>
                        <CompanyIcon width="15px" fill={scssVariables.grey} />
                      </div>
                      <div className="info-content-block">
                        <div className="content-title">
                          <Text>{quickViewData.company_name.value}</Text>
                        </div>
                        {/* <div className="content-small">
                      <Space size={6}>
                        <div className="content-key">Earlier</div>
                        <div className="content-value">Solganick & Co</div>
                      </Space>
                    </div> */}
                      </div>
                    </div>
                  ) : null}

                  <div className="info-block">
                    <div>
                      <EmailIcon width="15px" fill={scssVariables.grey} />
                    </div>
                    <div className="info-content-block">
                      <div className="content-title">
                        <div className="content-key">
                          {quickViewData.email && quickViewData.email.value ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {isCopyEnabled() ? (
                                <Copy
                                  text={quickViewData.email.value}
                                  element={
                                    <Text className="info-text">{quickViewData.email.value}</Text>
                                  }
                                />
                              ) : (
                                <Text className="info-text">{quickViewData.email.value}</Text>
                              )}

                              <div className="custom-popver-circle">
                                {quickViewData.email_score?.value === 99
                                  ? 'A+'
                                  : quickViewData.email_score?.value >= 85
                                  ? 'A'
                                  : 'B'}
                              </div>
                            </div>
                          ) : !quickViewData.emailNotAvailable &&
                            quickViewData.id &&
                            quickViewData.id.value &&
                            quickViewData.company_name &&
                            quickViewData.company_name.value &&
                            quickViewData.company_website &&
                            quickViewData.company_website.value &&
                            quickViewData.name &&
                            quickViewData.name.value ? (
                            <InstaRevealEmail
                              payload={{
                                leadId: quickViewData.id.value,
                                companyName: quickViewData.company_name.value,
                                website: quickViewData.company_website.value,
                                name: quickViewData.name.value,
                              }}
                              getInstaRes={(instaRes) => getInstaEmailRes(instaRes, quickViewData)}
                            />
                          ) : (
                            'Not Available'
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="info-block">
                      <div>
                        <PhoneIcon width="15px" fill={scssVariables.grey} />
                      </div>
                      <div className="info-content-block">
                        <div className="content-title">
                          <Space size={25} style={{ alignItems: 'baseline' }}>
                            <div className="content-key">
                              <Text>Personal</Text>
                            </div>
                            <div className="content-value">
                              <div>
                                {(quickViewData.phone && quickViewData.phone.value) ||
                                (quickViewData.work_phone && quickViewData.work_phone.value) ? (
                                  <>
                                    {quickViewData.phone && quickViewData.phone.value ? (
                                      <>
                                        {isCopyEnabled() ? (
                                          <Copy
                                            text={quickViewData.phone.value}
                                            element={
                                              <Text className="info-text">
                                                {quickViewData.phone.value} (Phone)
                                              </Text>
                                            }
                                          />
                                        ) : (
                                          <Text className="info-text">
                                            {quickViewData.phone.value} (Phone)
                                          </Text>
                                        )}
                                      </>
                                    ) : null}
                                    {quickViewData.work_phone && quickViewData.work_phone.value ? (
                                      <>
                                        {' '}
                                        {isCopyEnabled() ? (
                                          <Copy
                                            text={quickViewData.work_phone.value}
                                            element={
                                              <Text className="info-text">
                                                {quickViewData.work_phone.value} (Work)
                                              </Text>
                                            }
                                          />
                                        ) : (
                                          <Text className="info-text">
                                            {quickViewData.work_phone.value} (Work)
                                          </Text>
                                        )}
                                      </>
                                    ) : null}
                                  </>
                                ) : !quickViewData.phoneNotAvailable &&
                                  quickViewData.id &&
                                  quickViewData.id.value &&
                                  quickViewData.linkedin_url &&
                                  quickViewData.linkedin_url.value ? (
                                  <InstaRevealPhone
                                    payload={{
                                      leadId: quickViewData.id.value,
                                      linkedinUrl: quickViewData.linkedin_url.value,
                                    }}
                                    getInstaRes={(instaRes) =>
                                      getInstaPhoneRes(instaRes, quickViewData)
                                    }
                                  />
                                ) : (
                                  'Not Available'
                                )}
                              </div>
                            </div>
                          </Space>
                        </div>
                      </div>
                    </div>

                    {quickViewData.company_phone_numbers &&
                    quickViewData.company_phone_numbers.value ? (
                      <div className="info-block">
                        <div>
                          <PhoneIcon
                            width="15px"
                            fill={scssVariables.grey}
                            style={{ visibility: 'hidden' }}
                          />
                        </div>
                        <div className="info-content-block">
                          <div className="content-title">
                            <Space size={25}>
                              <div className="content-key">
                                <Text>Company</Text>
                              </div>
                              <div className="content-value">
                                {isCopyEnabled() ? (
                                  <Copy
                                    text={quickViewData.company_phone_numbers.value}
                                    element={
                                      <Text
                                        style={{ maxWidth: '150px' }}
                                        ellipsis
                                        className="info-text"
                                      >
                                        {quickViewData.company_phone_numbers.value}
                                      </Text>
                                    }
                                  />
                                ) : (
                                  <Text
                                    style={{ maxWidth: '150px' }}
                                    ellipsis
                                    className="info-text"
                                  >
                                    {quickViewData.company_phone_numbers.value}
                                  </Text>
                                )}
                              </div>
                            </Space>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="info-block">
                    <div>
                      <SocialLinksIcon width="15px" fill={scssVariables.grey} />
                    </div>
                    <div className="info-content-block">
                      <div className="content-title">
                        <Space size={25}>
                          <div className="content-key">
                            {/* <Text>Lead</Text> */}
                            <Text>Company</Text>
                          </div>
                          <div className="content-value">
                            {/* <div>
                              <Space size={8}>
                                {quickViewData.linkedin_url && quickViewData.linkedin_url.value ? (
                                  <a
                                    target="_blank"
                                    href={getClickableLink(quickViewData.linkedin_url.value)}
                                    rel="noreferrer"
                                  >
                                    <LinkedinIcon
                                      width="15px"
                                      fill={scssVariables.grey}
                                      className="socialmedia-icon"
                                    />
                                  </a>
                                ) : null}
                                {quickViewData.twitter_url && quickViewData.twitter_url.value ? (
                                  <a
                                    target="_blank"
                                    href={getClickableLink(quickViewData.twitter_url.value)}
                                    rel="noreferrer"
                                  >
                                    <TwitterIcon
                                      width="15px"
                                      fill={scssVariables.grey}
                                      className="socialmedia-icon"
                                    />
                                  </a>
                                ) : null}
                                {quickViewData.facebook_url && quickViewData.facebook_url.value ? (
                                  <a
                                    target="_blank"
                                    href={getClickableLink(quickViewData.facebook_url.value)}
                                    rel="noreferrer"
                                  >
                                    <FacebookIcon
                                      width="15px"
                                      fill={scssVariables.grey}
                                      className="socialmedia-icon"
                                    />
                                  </a>
                                ) : null}
                              </Space>
                            </div> */}
                            <div>
                              <Space split={<Divider type="vertical" />} size={2}>
                                {quickViewData.company_website &&
                                quickViewData.company_website.value ? (
                                  <a
                                    className="info-text"
                                    target="_blank"
                                    href={getClickableLink(quickViewData.company_website.value)}
                                    rel="noreferrer"
                                  >
                                    {quickViewData.company_website.value}
                                  </a>
                                ) : null}
                                <Space size={8}>
                                  <SocialLinks
                                    showText={false}
                                    data={quickViewData}
                                    viewName="company"
                                  />
                                </Space>
                              </Space>
                            </div>
                          </div>
                        </Space>
                      </div>
                    </div>
                  </div>

                  {quickViewData.lead_location &&
                  quickViewData.lead_location.value &&
                  quickViewData.lead_location.value.length ? (
                    <div className="info-block">
                      <div>
                        <LocationIcon width="15px" fill={scssVariables.grey} />
                      </div>
                      <div className="info-content-block">
                        <div className="content-title">
                          <Text>
                            {arrayToCommaSeperatedString(quickViewData.lead_location.value)}
                          </Text>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </Space>
              </div>

              <div className="about-view">
                <Space direction="vertical" size={16}>
                  <div>
                    <Text className="quick-view-content-header">ABOUT</Text>
                  </div>
                  <div className="table-info-block">
                    <Space direction="vertical" size={16}>
                      {/* {aboutDataList.map((item) => (
                        <KeyValueWithIcon item={item} />
                      ))} */}

                      {quickViewData.company_function && quickViewData.company_function.value ? (
                        <KeyValueWithIcon
                          item={{
                            title: 'Function',
                            value: quickViewData.company_function.value,
                            icon: <FunctionIcon width="15px" fill={scssVariables.grey} />,
                          }}
                        />
                      ) : null}
                      {quickViewData.lead_divison && quickViewData.lead_divison.value ? (
                        <KeyValueWithIcon
                          item={{
                            title: 'Division',
                            value: quickViewData.lead_divison.value,
                            icon: <DivisionIcon width="15px" fill={scssVariables.grey} />,
                          }}
                        />
                      ) : null}
                      {quickViewData.company_sector && quickViewData.company_sector.value ? (
                        <KeyValueWithIcon
                          item={{
                            title: 'Sector',
                            value: quickViewData.company_sector.value,
                            icon: <SectorIcon width="15px" fill={scssVariables.grey} />,
                          }}
                        />
                      ) : null}
                      {quickViewData.company_industry && quickViewData.company_industry.value ? (
                        <KeyValueWithIcon
                          item={{
                            title: 'Industry',
                            value: quickViewData.company_industry.value,
                            icon: <IndustryIcon width="15px" fill={scssVariables.grey} />,
                          }}
                        />
                      ) : null}
                      {quickViewData.decision_making_power &&
                      quickViewData.decision_making_power.value ? (
                        <KeyValueWithIcon
                          item={{
                            title: 'Decision-Making Power',
                            value: (
                              <DecisionMakingCircle
                                label={quickViewData.decision_making_power.value}
                                index={
                                  decisionMakingPowerMapping[
                                    quickViewData.decision_making_power.value
                                  ] ?? 0
                                }
                                length={3}
                              />
                            ),
                            icon: (
                              <DecisionMakingPowerIcon width="15px" fill={scssVariables.grey} />
                            ),
                          }}
                        />
                      ) : null}
                    </Space>
                  </div>
                </Space>
              </div>

              {quickViewData.skills?.value?.length ? (
                <div className="pill-section">
                  <Space direction="vertical" size={16}>
                    <div>
                      <Text className="quick-view-content-header">
                        SKILLS{' '}
                        <Text className="quick-view-content-header__count">
                          {' '}
                          ({quickViewData.skills.value.length})
                        </Text>
                      </Text>
                    </div>
                    <PillsGroup list={quickViewData.skills.value} />
                  </Space>
                </div>
              ) : null}

              {quickViewData.interests?.value?.length ? (
                <div className="pill-section">
                  <Space direction="vertical" size={16}>
                    <div>
                      <Text className="quick-view-content-header">
                        INTERESTS{' '}
                        <Text className="quick-view-content-header__count">
                          {' '}
                          ({quickViewData.interests.value.length})
                        </Text>
                      </Text>
                    </div>
                    <PillsGroup list={quickViewData.interests.value} />
                  </Space>
                </div>
              ) : null}

              {leadsExtraDetails?.experience?.length ? (
                <>
                  <div className="experience-section">
                    <Space align="baseline" direction="vertical" size={16} className="space-width">
                      <div>
                        <Text className="quick-view-content-header">
                          EXPERIENCE{' '}
                          <Text className="quick-view-content-header__count">
                            {' '}
                            ({leadsExtraDetails.experience.length})
                          </Text>
                        </Text>
                      </div>
                      {leadsExtraDetails.experience.slice(0, 3).map((list) => (
                        <QuickViewCard
                          title={list.title_name}
                          subTitle={list.company_name}
                          titleIcon={{
                            size: 'small',
                            icon: CompanyIcon,
                            backgroundColor: scssVariables.mildYellow,
                            iconColor: 'white',
                          }}
                          content={getExperienceContent(list)}
                        />
                      ))}
                    </Space>
                  </div>
                  {leadsExtraDetails.experience.length > 3 && (
                    <Button
                      type="primary"
                      block
                      style={{ borderColor: '#E9F6FE', background: '#E9F6FE', color: '#4A6EE5' }}
                      href={`${APP_ROUTE_SRC.LEAD_DETAILS}/${quickViewData.id.value}`}
                      target="_blank"
                    >
                      See More
                    </Button>
                  )}
                </>
              ) : null}

              {leadsExtraDetails.education && leadsExtraDetails.education.length ? (
                <div className="education-cert-section">
                  <Space align="baseline" direction="vertical" size={16} className="space-width">
                    <div>
                      <Text className="quick-view-content-header">
                        EDUCATION & CERTIFICATION{' '}
                        <Text className="quick-view-content-header__count">
                          {' '}
                          ({leadsExtraDetails.education.length})
                        </Text>
                      </Text>
                    </div>
                    {leadsExtraDetails.education.slice(0, 2).map((list) => {
                      return list.school_name ? (
                        <QuickViewCard
                          title={list.school_name}
                          subTitle={list.school_website || ''}
                          titleIcon={{
                            size: 'small',
                            Icon: EducationIcon,
                            backgroundColor: '#E3CA68',
                            iconColor: 'white',
                          }}
                          content={getEducationContent(list)}
                          isActionBtn={false}
                        />
                      ) : null;
                    })}
                  </Space>
                </div>
              ) : null}
              {technographicsData.technologies_mapper_view ? (
                <TechnographicsView
                  title="TECHNOLOGY STACK"
                  data={technographicsData.technologies_mapper_view}
                />
              ) : null}
              {technographicsData.keywords_mapper_view ? (
                <TechnographicsView
                  title="PSYCHOGRAPHICS"
                  data={technographicsData.keywords_mapper_view}
                />
              ) : null}
            </Space>
          </div>
          {quickViewData.id && (
            <div className="see-detail-view">
              <Button
                type="link"
                block
                href={`${APP_ROUTE_SRC.LEAD_DETAILS}/${quickViewData.id.value}`}
                target="_blank"
                onClick={() => sendEvent(eventActions.SEE_DETAILED_VIEW)}
              >
                See detailed view
              </Button>
            </div>
          )}
        </Space>
      </>
    );
  };

  return getContent();
};

export default LeadQuickView;

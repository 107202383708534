import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { toggleUpgradeModal } from 'containers/common/commonActions';
import { UpArrowIcon } from 'UI/CustomIcon/CustomIcon';
import './upgradeModal.scss';
import { baseURL, endPoints } from 'api/apiEndpoints';
import { headerGenerator } from 'utils/common';
import { postRequest } from 'api/apiRequests';
import { showError } from './errorMessage';

const UpgradeModal = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);
  const visibility = useSelector((state) => state.common.upgradeModal.isVisible);
  const planPermission = useSelector((state) => state.common.planPermission);
  const [isModalVisible, setIsModalVisible] = useState(visibility);
  const [isLoading, setIsLoading] = useState(false);
  const [requestRaised, setRequestRaised] = useState(false);
  const connectUrl = `${baseURL}${endPoints.userUpgrade}`;

  useEffect(() => {
    setIsModalVisible(visibility);
    setRequestRaised(false);
    setIsLoading(false);
  }, [visibility]);

  const getClassName = () => {
    switch (planPermission && planPermission.theme) {
      case 'blue':
        return 'upgrade-blue';
      case 'red':
        return 'upgrade-red';
      default:
        return '';
    }
  };

  const getIconColor = () => {
    let color = '';
    if (planPermission) {
      switch (planPermission.theme) {
        case 'blue':
          color = 'icon-holder icon-blue';
          break;
        case 'red':
          color = 'icon-holder icon-red';
          break;
        default:
          color = '';
      }
    }
    return color;
  };

  const handleCancel = () => {
    dispatch(toggleUpgradeModal(false));
  };

  const raiseRequest = async () => {
    setIsLoading(true);

    const { token, session_id: sessionId, userId, userEmail, userName } = userData;
    const headers = headerGenerator(token, sessionId);

    const payload = {
      subject: 'Upgrade Request Alert From Seed User!',
      userName,
      userEmail,
      userId,
      url: window.location.href,
      requestedDate: new Date().toString(),
      reason: 'Upgrade Request from Seed User',
    };

    const response = await postRequest(connectUrl, payload, headers);
    setIsLoading(false);
    if (response.error) {
      showError(response.message);
      dispatch(toggleUpgradeModal(false));
    } else if (response.msg) {
      setRequestRaised(true);
    }
  };

  const getContent = () => {
    if (requestRaised) {
      return (
        <>
          <h2>Your request has been submitted!</h2>
          <p>Our Product Specialist will get in touch with you soon</p>
          <div className="upgrade-button">
            <Button type="primary" className={getClassName()} onClick={handleCancel}>
              Back to the Dashboard
            </Button>
          </div>
        </>
      );
    }
    return (
      <>
        <h2>Upgrade now to unlock premium features</h2>
        <p>
          Get unlimited access to technographic, firmographics, people insights, integrations, and
          enrichment
        </p>
        <div className="upgrade-button">
          <Button
            type="primary"
            className={getClassName()}
            onClick={raiseRequest}
            loading={isLoading}
          >
            Talk to Us
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal width={650} footer={null} title="" visible={isModalVisible} onCancel={handleCancel}>
        <div
          className="upgrade-modal-block"
          style={{ padding: '50px 0 20px 0', textAlign: 'center' }}
        >
          <div className={getIconColor()}>
            <UpArrowIcon className="icon" />
          </div>
          {getContent()}
        </div>
      </Modal>
    </>
  );
};

export default UpgradeModal;

import { getRequest } from 'api/apiRequests';
import { baseURL, endPoints } from 'api/apiEndpoints';

const NewsService = {
  async fetchCompanyNewsById(params, headers) {
    const url = `${baseURL}${endPoints.companyNewsFeed}`;
    const response = await getRequest(url, params, headers);
    return { data: response };
  },
};

export default NewsService;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from 'UI/IconButton';
import { Button } from 'antd';
import { baseURL, endPoints } from 'api/apiEndpoints';
import { headerGenerator, isEqual, checkNotificationIsFromCE } from 'utils/common';
import { getRequest, postRequest } from 'api/apiRequests';
import { showError, showSuccess } from 'components/common/errorMessage';
import { setNotificationBellStatus } from 'containers/common/commonActions';
import { eventManager, eventList, eventActions } from 'utils/eventManager';
import {
  ExportProgress,
  ExportSuccess,
  FailedNotification,
} from 'UI/CustomMessages/CustomMessages';
import { clearCheckedItems } from 'containers/discovery/discoveryActions';
import NotificationCard from './notificationCard';
import './notifications.scss';

// main notification window
const NotificationWindow = ({ visible, toggleVisibility }) => {
  const [notifications, setNotifications] = useState([]);
  const [anyError, setAnyError] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((s) => s.auth.user, isEqual);
  const { token, session_id: sessionId, userId } = user;
  const anyUnreadNotifications = useSelector((state) => state.common.unreadNotifications);
  const headers = headerGenerator(token, sessionId);
  const pusherChannel = useSelector((state) => state.auth.pusherChannel);

  useEffect(() => {
    getAllNotifications();
    subscribeToFileProcessing();
    subscribeToAddingListItem();
    subscribeToExport();

    return () => {
      pusherChannel.unbind('application_notification');
      pusherChannel.unbind('adding-list-item');
      pusherChannel.unbind(`export-status-${userId}`);
    };
  }, []);

  const subscribeToExport = () => {
    pusherChannel.bind(`export-status-${userId}`, onExport);
  };

  const onExport = async (data) => {
    const {
      status,
      payload = {},
      exportType: eType,
      export_type: csvCompanyExportType,
      msg,
      url: csvUrl,
    } = data;
    let exportType = eType || csvCompanyExportType || '';
    if (checkNotificationIsFromCE(data) || !exportType) return;
    if (exportType === 'SF' || exportType === 'SALESFORCE') {
      exportType = 'Salesforce';
    }
    if (exportType === 'CSV') {
      if (msg === 'In Progress' || status === 'inprogress') {
        ExportProgress({
          duration: 0,
          msg: `${exportType} export in progress!`,
          progress: 50,
          key: `${exportType.toLowerCase()}-${userId}`,
        });
        return;
      }
      if (msg === 'done' || status === 'success') {
        ExportSuccess({
          duration: 10,
          showDownload: exportType.toLowerCase() === 'csv',
          url: csvUrl || payload.url,
          msg: `${exportType} export complete!`,
          successRoute: `/my-account?view=${exportType.toLowerCase()}`,
          key: `${exportType.toLowerCase()}-${userId}`,
        });
        getAllNotifications();
        eventManager.track(eventList.EXPORT_STATUS, {
          status: 'success',
          export_type: exportType,
          version: eventActions.NDF,
        });
        return;
      }
    }

    if (msg === 'failed' || status === 'failed') {
      FailedNotification({
        duration: 10,
        msg: `${exportType} export failed!`,
        key: `${exportType.toLowerCase()}-${userId}`,
        path: `/my-account?view=${exportType.toLowerCase()}`,
      });
      getAllNotifications();
      eventManager.track(eventList.EXPORT_STATUS, {
        payload: {
          status: 'failed',
          export_type: exportType,
          version: eventActions.NDF,
        },
      });
      return;
    }

    if (['inprogress'].includes(status?.replace(/ /g, '').toLowerCase()) || msg === 'In Progress') {
      ExportProgress({
        progress: 50,
        duration: 0,
        msg: `${exportType} export in progress!`,
        key: `${exportType.toLowerCase()}-${userId}`,
      });
    }
    if (['success', ' Success'].indexOf(status) !== -1) {
      const { url } = payload;
      ExportSuccess({
        duration: 10,
        showDownload: exportType.toLowerCase() === 'csv',
        url,
        msg: `${exportType} export complete!`,
        key: `${exportType.toLowerCase()}-${userId}`,
        successRoute: `/my-account?view=${exportType.toLowerCase()}`,
      });
      getAllNotifications();
      eventManager.track(eventList.EXPORT_STATUS, {
        payload: {
          status: 'success',
          export_type: exportType,
          version: eventActions.NDF,
        },
      });
    }
  };

  const subscribeToFileProcessing = () => {
    pusherChannel.bind('application_notification', async (data) => {
      await getAllNotifications();
      showSuccess(data.msg);
    });
  };

  const subscribeToAddingListItem = () => {
    pusherChannel.bind('adding-list-item', async (data) => {
      await getAllNotifications();
      if (['failed'].includes(data.status)) {
        showError(data.message);
      } else {
        showSuccess(data.message);
      }
      if (['processed', 'failed'].includes(data.status)) {
        dispatch(clearCheckedItems());
        const { listStats = {} } = data;
        eventManager.track(eventList.ADD_TO_SMART_LIST_STATUS, {
          payload: {
            status: data.status,
            version: eventActions.NDF,
            reason: data.message,
            smartlist_results: listStats.total,
          },
        });
      }
    });
  };

  // get all recent notifications
  const getAllNotifications = async () => {
    const connectUrl = `${baseURL}${endPoints.notifications}`;
    const response = await getRequest(connectUrl, { page: 1, limit: 15 }, headers);
    if (response.error) {
      setAnyError(true);
      // showError(response.message);
    }
    /*
    response.data = [
      {
        id: 1,
        change_type: 'lead',
        notification_type: 'technology',
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        created_at: 1620806412,
        read: false,
        view_link: 'www.google.com',
      },
      {
        id: 2,
        change_type: 'lead',
        notification_type: 'technology',
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Nullam varius suscipit ex at cursus.
          Aenean sed nisl sapien. Nullam et varius massa.
          Aenean fringilla ut urna sit amet porta. Integer sed dolor ultricies,
          scelerisque mauris vitae, ultrices erat. Pellentesque tempus,
          neque id ornare consectetur, elit arcu lacinia ante,
          vehicula tristique nulla quam in justo.
          Mauris scelerisque magna id eros posuere dapibus.
          Vivamus sit amet augue eu metus iaculis iaculis.
          Integer eu lacus neque. Donec pulvinar pellentesque mi eget blandit.',
        created_at: 1620806412,
        read: false,
        view_link: 'www.google.com',
      },
    ];
    */
    if (response.data) {
      let anyUnread = response.has_unread === 1;
      const notificationData = response.data.map((data) => {
        if (!anyUnread && !data.is_seen) {
          anyUnread = true;
        }
        return {
          id: data.id,
          changeType: data.change_type.toLowerCase(),
          type: data.notification_type.toLowerCase(),
          message: data.title,
          timestamp: data.created_at,
          read: data.is_seen,
          imageLink: data.image_url || '',
          viewLink: data.view_link || '',
        };
      });
      setNotifications(notificationData);
      dispatch(setNotificationBellStatus(anyUnread));
    }
  };

  const markAsRead = async (id = 0) => {
    const connectUrl = `${baseURL}${endPoints.notifications_read}`;
    const body = {};
    if (id) {
      body.type = 'single';
      body.notificationId = id;
    } else {
      body.type = 'all';
    }

    const response = await postRequest(connectUrl, body, headers);
    if (response.error) {
      // showError(response.message);
    }
    if (response.msg) {
      let anyUnread = false;
      const updatedNotifications = notifications.map((notification) => {
        const newData = notification;
        if (body.type === 'all') {
          newData.read = true;
        } else if (newData.id === id) {
          newData.read = true;
        }
        if (newData.is_seen) {
          anyUnread = true;
        }
        return newData;
      });
      setNotifications(updatedNotifications);
      dispatch(setNotificationBellStatus(anyUnread));
    }
  };

  if (visible) {
    return (
      <>
        <div className="notifications-window-container">
          <div className="window-container-caret-top" />
          <div className="notifications-top-bar">
            <h2>Notifications</h2>
            <div className="notifications-top-bar-actions">
              {notifications.length > 0 ? (
                <IconButton
                  IconComponent={<div className="notification-double-tick-icon" />}
                  tooltip="Mark all as read"
                  onClick={() => {
                    if (anyUnreadNotifications) {
                      markAsRead();
                    }
                  }}
                  customStyle={{
                    marginRight: 10,
                  }}
                />
              ) : (
                <></>
              )}

              <Link to="/my-account/notifications" onClick={() => toggleVisibility()}>
                <IconButton
                  IconComponent={<div className="notification-settings-icon" />}
                  tooltip="Notification Settings"
                  tooltipPlacement="bottomRight"
                  onClick={() => {}}
                />
              </Link>
            </div>
          </div>
          <div className="notifications-window-body">
            {!anyError && (
              <div className="notifications-window-footer">
                <Link to="/notifications" onClick={() => toggleVisibility()}>
                  <Button type="primary" className="white-primary-button notification-center">
                    See all Notifications
                  </Button>
                </Link>
              </div>
            )}
            {notifications.length > 0 ? (
              notifications.map((data) => {
                return (
                  <NotificationCard
                    dataId={data.id}
                    key={`${data.id}`}
                    notificationType={data.type}
                    changeType={data.changeType}
                    imageLink={data.imageLink}
                    viewLink={data.viewLink}
                    message={data.message.replace(/_/gm, '')}
                    timestamp={data.timestamp * 1000}
                    read={data.read}
                    viewType="compact"
                    onClick={(id) => markAsRead(id)}
                  />
                );
              })
            ) : (
              <div className="notification-page no-data-available minimal">
                {anyError ? (
                  <>
                    <div className="no-data-error-icon" />
                    <p className="no-data-title">
                      Oops! We are unable to fetch your alerts right now.
                      <br />
                      Why don’t you try back again later?
                    </p>
                  </>
                ) : (
                  <>
                    <div className="no-data-radio" />
                    <p className="no-data-title">
                      Nothing to see here, please set your preferences in
                      <br />
                      <a className="no-data-title-nav" href="/my-account/notifications">
                        Notifications Settings
                      </a>
                      to start seeing notifications
                    </p>
                  </>
                )}
              </div>
            )}
            {/* <Divider className="common-divider left-side" orientation="left" plain>
              Earlier
            </Divider> */}
          </div>
        </div>
        <div
          className="notifications-window-mask"
          onClick={() => toggleVisibility()}
          role="button"
          tabIndex="0"
        >
          &nbsp;
        </div>
      </>
    );
  }
  return <></>;
};

export default NotificationWindow;

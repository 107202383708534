import React, { useState } from 'react';
import { CopyIcon } from 'UI/CustomIcon/CustomIcon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Space, Popover, Tooltip } from 'antd';
import './Copy.scss';

const Copy = ({ text, element }) => {
  const [isCopied, setIsCopied] = useState(false);

  const setCopied = () => {
    if (!isCopied) {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };

  return (
    <Popover
      overlayClassName="copy-container"
      content="Copied"
      trigger="click"
      visible={isCopied}
      placement="rightTop"
    >
      <CopyToClipboard text={text} onCopy={setCopied}>
        <Space size={6}>
          {element}
          <Tooltip title="Copy">
            <CopyIcon width="12px" fill="#69739080" style={{ cursor: 'pointer' }} />
          </Tooltip>
        </Space>
      </CopyToClipboard>
    </Popover>
  );
};

export default Copy;

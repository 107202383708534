import {
  CREDIT_INIT,
  CREDIT_SUCCESS,
  CREDIT_INFO_FAILURE,
  FILTERS_SUCCESS,
  FILTERS_FAILURE,
  DOMAIN_USERS_SUCCESS,
  DOMAIN_USERS_INIT,
  DOMAIN_USERS_FAILURE,
  DELETE_FILTERS_SUCCESS,
  DELETE_FILTERS_FAILURE,
  HUBSPOT_CONFIG,
  HUBSPOT_CONFIG_SUCCESS,
  HUBSPOT_CONFIG_FAILURE,
  SET_LOADING,
  SET_NOTIFICATION_BELL_STATUS,
  CREDIT_LIMIT_MODAL,
  SALESFORCE_CONFIG_SUCCESS,
  SALESFORCE_CONFIG_FAILURE,
  SALESFORCE_CONFIG,
  OUTREACH_CONFIG,
  OUTREACH_CONFIG_FAILURE,
  OUTREACH_CONFIG_SUCCESS,
  FETCH_PERMISSION_SUCCESS,
  TOGGLE_UPGRADE_MODAL,
  TOGGLE_SESSION_TIMEOUT_MODAL,
  USER_INFO_REDIS_SUCCESS,
  USER_INFO_REDIS_FAILURE,
  FILTER_LIST_SUCCESS,
  FILTER_LIST_ERROR,
  SHOW_TOAST_NOTIFICATION,
  UPDATEFILTER_SUCCESS,
  FILTER_DUPLICATE_SUCCESS,
  SHARE_FILTER_SUCCESS,
  SHARE_FILTER_FAILURE,
  PIPEDRIVE_CONFIG_FAILURE,
  PIPEDRIVE_CONFIG_SUCCESS,
  OPEN_REPORT_DRAWER,
  CLOSE_REPORT_DRAWER,
  SET_SAVED_FILTER_LOADING,
  UPDATE_USER_INFO_REDIS,
} from 'store/actionTypes';

const initialState = {
  loading: false,
  unreadNotifications: false,
  credits: {},
  creditLimitModalVisibility: false,
  filters: [],
  domainUsersList: [],
  domainUsersErrorMessage: '',
  errorMessage: '',
  savedFilterErrorMessage: '',
  filtersDeleteSuccessMessage: '',
  hubspotConfig: null,
  salesforceConfig: null,
  outreachConfig: null,
  planPermission: {},
  upgradeModal: {
    isVisible: false,
  },
  sessionTimeoutModal: false,
  filterList: null,
  notification: {
    type: '',
    message: '',
  },
  shareFilterSuccessMessage: '',
  shareFilterErrorMessage: '',
  reportDrawerInfo: {
    isOpen: false,
    payload: {},
  },
  isFilterLoading: false,
};

const common = (state = initialState, action) => {
  switch (action.type) {
    case CREDIT_INIT:
    case HUBSPOT_CONFIG:
    case SALESFORCE_CONFIG:
    case OUTREACH_CONFIG:
      return {
        ...state,
      };
    case CREDIT_SUCCESS: {
      const {
        data: {
          credits: { data },
        },
      } = action;
      return {
        ...state,
        credits: data,
        errorMessage: '',
      };
    }
    case CREDIT_INFO_FAILURE: {
      return {
        ...state,
        credits: initialState.credits,
        errorMessage: action.error,
      };
    }
    case CREDIT_LIMIT_MODAL: {
      const { payload: visibility } = action;
      return {
        ...state,
        creditLimitModalVisibility: visibility,
      };
    }
    case FILTERS_SUCCESS: {
      const {
        data: {
          filters: { data },
        },
      } = action;
      return {
        ...state,
        errorMessage: '',
        filters: data,
        isFilterLoading: false,
      };
    }
    case FILTERS_FAILURE: {
      return {
        ...state,
        // filters: [],
        savedFilterErrorMessage: action.error,
        isFilterLoading: false,
      };
    }
    case DELETE_FILTERS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        // filtersDeleteSuccessMessage: 'Filter is deleted successfully',
        filters: state.filters.filter((filter) => filter.id !== data.id),
      };
    }
    case DELETE_FILTERS_FAILURE: {
      return {
        ...state,
        // filters: [],
        savedFilterErrorMessage: action.error,
        loading: false,
      };
    }
    case DOMAIN_USERS_INIT: {
      return {
        ...state,
      };
    }
    case DOMAIN_USERS_SUCCESS: {
      const {
        data: { data },
      } = action;
      return {
        ...state,
        domainUsersList: data.data,
        domainUsersErrorMessage: '',
      };
    }
    case DOMAIN_USERS_FAILURE: {
      return {
        ...state,
        // domainUsersList: [],
        domainUsersErrorMessage: action.error,
      };
    }
    case HUBSPOT_CONFIG_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        hubspotConfig: data.data,
        errorMessage: '',
      };
    }
    case HUBSPOT_CONFIG_FAILURE: {
      return {
        ...state,
        hubspotConfig: null,
        errorMessage: action.error,
      };
    }
    case SALESFORCE_CONFIG_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        salesforceConfig: {
          ...data.data,
          isConnected: data.isConnected,
          isSandboxEnabled: data.isSandboxEnabled,
        },
        errorMessage: '',
      };
    }
    case SALESFORCE_CONFIG_FAILURE: {
      return {
        ...state,
        salesforceConfig: null,
        errorMessage: action.error,
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.data,
      };
    }

    case SET_SAVED_FILTER_LOADING: {
      return {
        ...state,
        isFilterLoading: action.data,
      };
    }

    case SET_NOTIFICATION_BELL_STATUS: {
      return {
        ...state,
        unreadNotifications: action.data,
      };
    }
    case OUTREACH_CONFIG_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        outreachConfig: data,
        errorMessage: '',
      };
    }
    case OUTREACH_CONFIG_FAILURE: {
      return {
        ...state,
        outreachConfig: null,
        errorMessage: action.error,
      };
    }
    case PIPEDRIVE_CONFIG_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        pipedriveConfig: data.data,
        errorMessage: '',
      };
    }
    case PIPEDRIVE_CONFIG_FAILURE: {
      return {
        ...state,
        pipedriveConfig: null,
        errorMessage: action.error,
      };
    }
    case FETCH_PERMISSION_SUCCESS: {
      return {
        ...state,
        planPermission: action.data,
      };
    }
    case TOGGLE_UPGRADE_MODAL: {
      return {
        ...state,
        upgradeModal: { ...state.upgradeModal, isVisible: action.data },
      };
    }
    case TOGGLE_SESSION_TIMEOUT_MODAL: {
      return {
        ...state,
        sessionTimeoutModal: action.data,
      };
    }
    case USER_INFO_REDIS_SUCCESS: {
      const { data } = action;
      if (data && data.data && data.data.salesforce_preference) {
        data.data.salesforce_preference = JSON.parse(data.data.salesforce_preference);
      }
      return {
        ...state,
        userInfoRedis: { ...data.data },
        errorMessage: '',
      };
    }
    case UPDATE_USER_INFO_REDIS: {
      const { payload } = action;
      return {
        ...state,
        userInfoRedis: { ...state.userInfoRedis, ...payload },
      };
    }

    case USER_INFO_REDIS_FAILURE: {
      return {
        ...state,
        userInfoRedis: null,
        errorMessage: action.error,
      };
    }

    case FILTER_LIST_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        filterList: data,
      };
    }

    case FILTER_LIST_ERROR: {
      return {
        ...state,
        filterList: null,
      };
    }

    case SHOW_TOAST_NOTIFICATION: {
      return {
        ...state,
        notification: { ...action.data, id: Math.random() },
      };
    }
    case UPDATEFILTER_SUCCESS: {
      const newArray = [...state.filters];
      if (action && action.data) {
        const data = action.data;
        const index = state.filters.findIndex((todo) => todo.id === data.params.id);
        newArray[index].isSubscribed = Object.prototype.hasOwnProperty.call(
          data.data,
          'isSubscribed'
        )
          ? data.data.isSubscribed
          : newArray[index].isSubscribed;
        newArray[index].name = Object.prototype.hasOwnProperty.call(data.data, 'name')
          ? data.data.name
          : newArray[index].name;
      }
      return {
        ...state,
        errorMessage: '',
        filters: newArray,
      };
    }
    case FILTER_DUPLICATE_SUCCESS: {
      const newArray = [...state.filters];
      return {
        ...state,
        errorMessage: '',
        filters: [action.data.filters.data].concat(newArray),
      };
    }
    case SHARE_FILTER_SUCCESS: {
      return {
        ...state,
        shareFilterSuccessMessage: { msg: 'Filter shared successfully', id: Math.random() },
      };
    }
    case SHARE_FILTER_FAILURE: {
      return {
        ...state,
        shareFilterErrorMessage: action.error,
      };
    }
    case OPEN_REPORT_DRAWER: {
      return {
        ...state,
        reportDrawerInfo: { isOpen: true, payload: action.data },
      };
    }
    case CLOSE_REPORT_DRAWER: {
      return {
        ...state,
        reportDrawerInfo: { isOpen: false, payload: {} },
      };
    }
    default:
      return state;
  }
};

export default common;

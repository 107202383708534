import React, { useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';
import { Button, Form, Tooltip, Collapse, Checkbox, Divider, Affix, Space } from 'antd';
import {
  InfoIcon,
  TechnologyIcon,
  CompanyIcon,
  LeadIcon,
  PsychographicIcon,
  OtherFilterIcon,
} from 'UI/CustomIcon/CustomIcon';
import AddedDateRange from 'components/filters/addedDateRange';
import { EmailAvailability, PhoneAvailability } from 'components/filters/emailAvailability';
import DynamicAutoSuggest from 'components/filters/dynamicAutoSuggest';
import { endPoints } from 'api/apiEndpoints';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import {
  LEAD_TITLE_DATA,
  LEAD_DIVISON_DATA,
  DECISION_MAKING,
  COMPANY_INDUSTRY,
  COMPANY_SIZE_DATA,
  COMPANY_SIZE_PICK,
  LIST_SOURCE_DATA,
  DEFAULT_TECHNOLOGIES_OPTIONS,
  DEFAULT_KEYWORDS_OPTIONS,
  DEFAULT_COMPANY_OPTIONS,
  COMPANY_REVENUE_DATA,
  COMPANY_REVENUE_PICK,
  LEAD_CONTINENT_DATA,
  LIST_PAGE_DEFAULT_LEADS_LOCATION_OPTIONS,
  LIST_PAGE_DEFAULT_COMPANY_LOCATION_OPTIONS,
  FREE_TEXT_FILTER_CHAR_LIMIT,
} from 'constants/constants';
import {
  setListItemFilters,
  resetListItemFilters,
  resetCurrentFilter,
} from 'containers/smartList/smartListActions';
import DecisionMakingCircle from 'components/common/DecisionMakingCircle';
import { usePrevious } from 'utils/hooks/customHooks';
import { isEqual } from 'utils/common';
import CheckboxTree from 'UI/CheckboxTree/CheckboxTree';
import { FilterTag, FilterCount, FilterSelectionButton } from './FilterTags';

const { Panel } = Collapse;

const LeadQuickFilter = ({ onFilterChanged }) => {
  const { t } = useTranslation(['discovery']);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [activeFilter, setActivePanel] = useState();
  const { listFilters: filters } = useSelector((state) => state.smartList);
  const [totalFilters, setTotalCount] = useState(0);
  const previousFilters = usePrevious(JSON.parse(JSON.stringify(filters)));

  useEffect(() => {
    return () => {
      clearAllFilters();
    };
  }, []);

  useEffect(() => {
    const count = getTotalFilterCount();
    setTotalCount(count);
    if (
      (totalFilters >= 0 && totalFilters !== count) ||
      (previousFilters && !isEqual(previousFilters, filters))
    ) {
      if (Array.isArray(filters.other.created_at) && filters.other.created_at.length === 0) {
        form.resetFields(['created_at']);
      }
      if (
        Array.isArray(filters.lead.decision_making_power) &&
        filters.lead.decision_making_power.length === 0
      ) {
        form.resetFields(['decision_making_power']);
      }
      if (Array.isArray(filters.lead.company_sector) && filters.lead.company_sector.length === 0) {
        form.resetFields(['company_sector_industry']);
      }
      onFilterChanged();
    }
  }, [filters]);

  const expandIcon = ({ isActive }) =>
    isActive ? (
      <MinusOutlined style={{ color: '#697390' }} />
    ) : (
      <PlusOutlined style={{ color: '#697390' }} />
    );

  const applyFilter = (type, name, values) => {
    dispatch(setListItemFilters(type, name, values));
  };

  const resetFilter = (type) => {
    const resetFields = Object.keys(filters[type]);
    form.resetFields(resetFields);
    dispatch(resetCurrentFilter(type));
  };

  const clearAllFilters = () => {
    form.resetFields();
    dispatch(resetListItemFilters());
  };

  const onFilterApply = (type, name, values) => {
    switch (name) {
      case 'company_sector_industry':
        handleFilterApplyforSectorAndIndustry(type, name, values);
        break;
      case 'company_function_division':
        handleFilterApplyforFunctionAndDivision(type, name, values);
        break;
      default:
        form.setFieldsValue({ [name]: values });
        applyFilter(type, name, values);
    }
  };

  const selectFilter = (type, name, value) => {
    const values = filters[type][name];
    if (values && !values.includes(value)) {
      values.push(value);
    }
    form.setFieldsValue({ [name]: values });
    applyFilter(type, name, values);
  };

  const getCount = (acc, filterValues) => (filterValues ? acc + filterValues.length : acc);

  const getTotalFilterCount = () => {
    const { lead, company, ...remainingFilters } = filters;
    const { company_function_division, ...leadFilters } = lead;
    const { company_sector_industry, ...companyFilters } = company;
    const selectedFilters = {
      lead: { ...leadFilters },
      company: { ...companyFilters },
      ...remainingFilters,
    };
    return Object.values(selectedFilters)
      .map((filter) => Object.values(filter).reduce(getCount, 0))
      .reduce((a, b) => a + b, 0);
  };

  const getFilterCount = (type) => {
    const { company_function_division, company_sector_industry, ...selectedFilters } =
      filters[type];
    return Object.values(selectedFilters).reduce(getCount, 0);
  };

  const formatDateAndApplyFilter = (dates) => {
    if (dates) {
      const value = dates.map((date) => date.toISOString()).join('_');
      applyFilter('other', 'created_at', [value]);
    } else {
      applyFilter('other', 'created_at', []);
    }
  };

  const getChildFromParentList = (parentList = [], list = []) => {
    return parentList.length
      ? list.filter((name) => {
          return !parentList.some((text) => name.startsWith(text));
        })
      : list;
  };

  const selectIndustryAndSector = (industries) => {
    const sector = industries.filter((values) => !values.includes('|'));
    const industry = getChildFromParentList(sector, industries);
    applyFilter('company', 'company_sector', sector);
    applyFilter('company', 'company_industry', industry);
    applyFilter('company', 'company_sector_industry', industries);
  };

  const selectFunctionAndDivision = (division) => {
    const functions = division.filter((values) => !values.includes('|'));
    const divisions = getChildFromParentList(functions, division);
    applyFilter('lead', 'company_function', functions);
    applyFilter('lead', 'lead_divison', divisions);
    applyFilter('lead', 'company_function_division', division);
  };

  const handleFilterApplyforSectorAndIndustry = (type, name, values) => {
    const filterValues = filters[type][name].filter((val) => !values.includes(val));
    const formValues = form
      .getFieldValue('company_sector_industry')
      .filter((val) => filterValues.some((text) => !val.startsWith(text)));
    const industries = formValues.filter((value) => value.includes('|'));
    const sectors = formValues.filter((value) => !value.includes('|'));
    form.setFieldsValue({
      company_sector_industry: formValues,
    });
    applyFilter(type, name, formValues);
    applyFilter('company', 'company_sector', sectors);
    applyFilter('company', 'company_industry', industries);
  };

  const handleFilterApplyforFunctionAndDivision = (type, name, values) => {
    const filterValues = filters[type][name].filter((val) => !values.includes(val));
    const formValues = form
      .getFieldValue('company_function_division')
      .filter((val) => filterValues.some((text) => !val.startsWith(text)));
    const divisions = formValues.filter((value) => value.includes('|'));
    const functions = formValues.filter((value) => !value.includes('|'));
    form.setFieldsValue({
      company_function_division: formValues,
    });
    applyFilter(type, name, formValues);
    applyFilter('lead', 'company_function', functions);
    applyFilter('lead', 'lead_divison', divisions);
  };

  const [container, setContainer] = useState(null);

  return (
    <div className="filter-section" ref={setContainer}>
      <Form
        labelCol={{
          span: 24,
        }}
        colon={false}
        form={form}
        hideRequiredMark
      >
        <div className="filter-section__head">
          <Affix target={() => container}>
            <div className="action-btn">
              <Text className="title">Filters</Text>
              <Button disabled={totalFilters === 0} type="link" onClick={clearAllFilters}>
                {' '}
                Clear all
                {totalFilters > 0 ? ` (${totalFilters})` : ''}
              </Button>
            </div>
          </Affix>
        </div>
        <div className="filter-section__filter">
          <Collapse
            accordion
            expandIconPosition="right"
            expandIcon={expandIcon}
            bordered={false}
            onChange={setActivePanel}
          >
            <Panel
              header={
                <div className="expand-filter-header">
                  <div className="filter-header-text">
                    <span style={{ marginLeft: -2 }}>
                      <LeadIcon fill="#697390" width="25" height="25" />
                    </span>
                    <span>
                      <Text>People</Text>
                    </span>
                    <span>
                      <FilterCount
                        filters={filters}
                        type="lead"
                        resetFilter={resetFilter}
                        getCount={getFilterCount}
                      />
                    </span>
                  </div>
                  {activeFilter !== 'lead' && (
                    <div className="selected-filters">
                      <FilterTag filters={filters} onFilterApply={onFilterApply} type="lead" />
                    </div>
                  )}
                </div>
              }
              key="lead"
            >
              <div className="filter-items_inner-panel">
                <Collapse bordered={false} expandIconPosition="right">
                  <Panel
                    header={
                      <>
                        <Text>Job Title</Text>
                        <Tooltip
                          placement="right"
                          title="Select the job title for your target people persona"
                        >
                          <InfoIcon className="filter-info-icon" />
                        </Tooltip>
                      </>
                    }
                    key="lead_titles"
                  >
                    <Form.Item name="lead_titles">
                      <DynamicAutoSuggest
                        onChange={(values) => applyFilter('lead', 'lead_titles', values)}
                        mode="tags"
                        filters={useMemo(() => filters.lead.lead_tittles, [filters])}
                        placeholder="Select job title"
                        style={{ width: '95%' }}
                        defaultOptionList={LEAD_TITLE_DATA}
                        maxLength={FREE_TEXT_FILTER_CHAR_LIMIT}
                      />
                    </Form.Item>
                    <FilterSelectionButton
                      values={LEAD_TITLE_DATA}
                      selectFilter={(value) => selectFilter('lead', 'lead_titles', value)}
                    />
                  </Panel>
                  <Divider />
                  <Panel
                    header={
                      <>
                        <Text>Function & Division</Text>
                      </>
                    }
                    key="company_function"
                  >
                    <Form.Item name="company_function_division">
                      <CheckboxTree
                        onChange={(values) => selectFunctionAndDivision(values)}
                        treeData={LEAD_DIVISON_DATA}
                      />
                    </Form.Item>
                  </Panel>
                  <Divider />
                  <Panel
                    header={
                      <>
                        <Text>Decision-Making power</Text>
                        <Tooltip
                          placement="right"
                          title="Select decision-making power of prospects you would like to target"
                        >
                          <InfoIcon className="filter-info-icon" />
                        </Tooltip>
                      </>
                    }
                    key="decision_making_power"
                  >
                    <Form.Item name="decision_making_power">
                      <Checkbox.Group
                        onChange={(values) => applyFilter('lead', 'decision_making_power', values)}
                      >
                        <Space direction="vertical">
                          {DECISION_MAKING.map((value, index) => (
                            <Checkbox value={value} key={value}>
                              <DecisionMakingCircle
                                label={value}
                                index={index}
                                length={DECISION_MAKING.length}
                              />
                            </Checkbox>
                          ))}
                        </Space>
                      </Checkbox.Group>
                    </Form.Item>
                  </Panel>
                  <Divider />
                  <Panel
                    header={
                      <>
                        <Text>Emails</Text>
                      </>
                    }
                    key="email_availablity"
                  >
                    <Form.Item name="email_availablity">
                      <EmailAvailability
                        onChange={(values) => {
                          const mapping = ['Off', 'A+', 'A', 'B'];
                          applyFilter(
                            'lead',
                            'email_availablity',
                            values.map((value) => mapping[value])
                          );
                        }}
                        filters={filters.lead.email_availablity}
                        radioStyle={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Panel>
                  <Divider />
                  <Panel
                    header={
                      <>
                        <Text>Direct Phones</Text>
                      </>
                    }
                    key="has_phone_number"
                  >
                    <Form.Item name="has_phone_number">
                      <PhoneAvailability
                        onChange={(value) => {
                          const mapping = ['Off', 'All'];
                          applyFilter('lead', 'has_phone_number', value ? [mapping[value]] : []);
                        }}
                        filters={filters.other.has_phone_number}
                      />
                    </Form.Item>
                  </Panel>
                  <Divider />
                  <Panel
                    header={
                      <>
                        <Text>Location</Text>
                        <Tooltip
                          placement="right"
                          title="Select the city, state, and/or country you'd like to target"
                        >
                          <InfoIcon className="filter-info-icon" />
                        </Tooltip>
                      </>
                    }
                    key="lead_location"
                  >
                    <Form.Item name="lead_location">
                      <DynamicAutoSuggest
                        onChange={(values) => applyFilter('lead', 'lead_location', values)}
                        filters={filters.lead.lead_location}
                        placeholder="Search people location"
                        apiEndPoint={endPoints.locationSuggester}
                        style={{ width: '95%' }}
                        defaultOptionList={LIST_PAGE_DEFAULT_LEADS_LOCATION_OPTIONS}
                      />
                    </Form.Item>
                    <FilterSelectionButton
                      values={['United States', 'India']}
                      selectFilter={(value) => selectFilter('lead', 'lead_location', value)}
                    />
                  </Panel>
                  <Divider />
                  <Panel
                    header={
                      <>
                        <Text>Continent</Text>
                        <Tooltip
                          placement="right"
                          title="Select the continent(s) you would like to target"
                        >
                          <InfoIcon className="filter-info-icon" />
                        </Tooltip>
                      </>
                    }
                    key="continent"
                  >
                    <Form.Item name="continent">
                      <DynamicAutoSuggest
                        onChange={(values) => applyFilter('lead', 'continent', values)}
                        filters={filters.lead.continent}
                        placeholder="Select people continent"
                        optionList={LEAD_CONTINENT_DATA}
                        style={{ width: '95%' }}
                      />
                    </Form.Item>
                    <FilterSelectionButton
                      values={['North America', 'Asia']}
                      selectFilter={(value) => selectFilter('lead', 'continent', value)}
                    />
                  </Panel>
                </Collapse>
              </div>
            </Panel>
            <Panel
              header={
                <div className="expand-filter-header">
                  <div className="filter-header-text">
                    <span>
                      <CompanyIcon fill="#697390" width="16" height="16" />
                    </span>
                    <span>
                      <Text>Company</Text>
                    </span>
                    <span>
                      <FilterCount
                        filters={filters}
                        type="company"
                        resetFilter={resetFilter}
                        getCount={getFilterCount}
                      />
                    </span>
                  </div>
                  {activeFilter !== 'company' && (
                    <div className="selected-filters">
                      <FilterTag filters={filters} onFilterApply={onFilterApply} type="company" />
                    </div>
                  )}
                </div>
              }
              key="company"
            >
              <div className="filter-items_inner-panel">
                <Collapse expandIconPosition="right" bordered={false}>
                  <Panel
                    header={
                      <>
                        <Text>Name</Text>
                        <Tooltip
                          placement="right"
                          title="Select company name that you would like to see people from"
                        >
                          <InfoIcon className="filter-info-icon" />
                        </Tooltip>
                      </>
                    }
                    key="company_name"
                  >
                    <Form.Item name="company_name">
                      <DynamicAutoSuggest
                        onChange={(values) => applyFilter('company', 'company_name', values)}
                        type="image-text"
                        placeholder="Search Company"
                        defaultOptionList={DEFAULT_COMPANY_OPTIONS}
                        apiEndPoint={endPoints.companyAutosuggestion}
                        style={{ width: '95%' }}
                        filters={filters.company.company_name.map(
                          (name) =>
                            DEFAULT_COMPANY_OPTIONS.find((option) => option.value === name)
                              ?.value || name
                        )}
                      />
                    </Form.Item>
                    <FilterSelectionButton
                      values={DEFAULT_COMPANY_OPTIONS}
                      selectFilter={(value) => selectFilter('company', 'company_name', value)}
                    />
                  </Panel>
                  <Divider />
                  <Panel
                    header={
                      <>
                        <Text>Size</Text>
                        <Tooltip
                          placement="right"
                          title="Select employee size of the companies you want to target"
                        >
                          <InfoIcon className="filter-info-icon" />
                        </Tooltip>
                      </>
                    }
                    key="company_size"
                  >
                    <Form.Item name="company_size">
                      <DynamicAutoSuggest
                        onChange={(values) => applyFilter('company', 'company_size', values)}
                        filters={filters.company.company_size}
                        placeholder="Select company size"
                        optionList={COMPANY_SIZE_DATA}
                        style={{ width: '95%' }}
                      />
                    </Form.Item>
                    <FilterSelectionButton
                      values={COMPANY_SIZE_PICK}
                      selectFilter={(value) => selectFilter('company', 'company_size', value)}
                    />
                  </Panel>
                  <Divider />
                  <Panel
                    header={
                      <>
                        <Text>Location</Text>
                        <Tooltip
                          placement="right"
                          title="Select the city, state, and/or country you would like to target"
                        >
                          <InfoIcon className="filter-info-icon" />
                        </Tooltip>
                      </>
                    }
                    key="company_location"
                  >
                    <Form.Item name="company_location">
                      <DynamicAutoSuggest
                        onChange={(values) => applyFilter('company', 'company_location', values)}
                        filters={filters.company.company_location}
                        placeholder="Search company location"
                        apiEndPoint={endPoints.leadsCompanyLocationSuggester}
                        style={{ width: '95%' }}
                        defaultOptionList={LIST_PAGE_DEFAULT_COMPANY_LOCATION_OPTIONS}
                      />
                    </Form.Item>
                    <FilterSelectionButton
                      values={['United States', 'California']}
                      selectFilter={(value) => selectFilter('company', 'company_location', value)}
                    />
                  </Panel>
                  <Divider />
                  <Panel
                    header={
                      <>
                        <Text>Sector & Industry</Text>
                      </>
                    }
                    key="company_sector"
                  >
                    <Text style={{ color: '#929CB7', fontSize: '12px' }}>
                      {' '}
                      Select industry for a narrower search or sector for a broader match{' '}
                    </Text>
                    <Form.Item name="company_sector_industry">
                      <CheckboxTree
                        onChange={selectIndustryAndSector}
                        treeData={COMPANY_INDUSTRY}
                      />
                    </Form.Item>
                  </Panel>
                  <Divider />
                  <Panel
                    header={
                      <>
                        <Text>SIC Code</Text>
                        <Tooltip placement="right" title="Filter companies using SIC codes">
                          <InfoIcon className="filter-info-icon" />
                        </Tooltip>
                      </>
                    }
                    key="company_sic_code"
                  >
                    <Form.Item name="company_sic_code">
                      <DynamicAutoSuggest
                        onChange={(values) => applyFilter('company', 'company_sic_code', values)}
                        mode="tags"
                        filters={useMemo(() => filters.company.company_sic_code, [filters])}
                        placeholder="Search for SIC codes"
                        // optionList={COMPANY_SIC_DATA}
                        style={{ width: '95%' }}
                        // defaultOptionList={COMPANY_SIC_DATA}
                        maxLength={FREE_TEXT_FILTER_CHAR_LIMIT}
                      />
                    </Form.Item>
                    <FilterSelectionButton
                      values={['8011', '7389']}
                      selectFilter={(value) => selectFilter('company', 'company_sic_code', value)}
                    />
                  </Panel>
                  <Divider />
                  <Panel
                    header={
                      <>
                        <Text>NAICS Code</Text>
                        <Tooltip placement="right" title="Filter companies using NAICS codes">
                          <InfoIcon className="filter-info-icon" />
                        </Tooltip>
                      </>
                    }
                    key="company_naics_code"
                  >
                    <Form.Item name="company_naics_code">
                      <DynamicAutoSuggest
                        onChange={(values) => applyFilter('company', 'company_naics_code', values)}
                        mode="tags"
                        filters={useMemo(() => filters.company.company_naics_code, [filters])}
                        placeholder="Search for NAICS codes"
                        // optionList={COMPANY_NAICS_DATA}
                        style={{ width: '95%' }}
                        // defaultOptionList={COMPANY_NAICS_DATA}
                        maxLength={FREE_TEXT_FILTER_CHAR_LIMIT}
                      />
                    </Form.Item>
                    <FilterSelectionButton
                      values={['561990', '621111']}
                      selectFilter={(value) => selectFilter('company', 'company_naics_code', value)}
                    />
                  </Panel>
                  <Divider />
                  <Panel
                    header={
                      <>
                        <Text>Revenue</Text>
                        <Tooltip
                          placement="right"
                          title="Select estimated revenue of target public companies"
                        >
                          <InfoIcon className="filter-info-icon" />
                        </Tooltip>
                      </>
                    }
                    key="revenue_range"
                  >
                    <Form.Item name="revenue_range">
                      <DynamicAutoSuggest
                        onChange={(values) => applyFilter('company', 'revenue_range', values)}
                        filters={filters.company.revenue_range}
                        placeholder="Select revenue"
                        optionList={COMPANY_REVENUE_DATA}
                        style={{ width: '95%' }}
                      />
                    </Form.Item>
                    <FilterSelectionButton
                      values={COMPANY_REVENUE_PICK}
                      selectFilter={(value) => selectFilter('company', 'revenue_range', value)}
                    />
                  </Panel>
                </Collapse>
              </div>
            </Panel>
            <Panel
              header={
                <div className="expand-filter-header">
                  <div className="filter-header-text">
                    <span>
                      <TechnologyIcon fill="#697390" width="16" height="16" />
                    </span>
                    <span>
                      <Text>Technologies</Text>
                    </span>
                    <span>
                      <FilterCount
                        filters={filters}
                        type="technologies"
                        resetFilter={resetFilter}
                        getCount={getFilterCount}
                      />
                    </span>
                  </div>
                  {activeFilter !== 'technologies' && (
                    <div className="selected-filters">
                      <FilterTag
                        filters={filters}
                        onFilterApply={onFilterApply}
                        type="technologies"
                      />
                    </div>
                  )}
                </div>
              }
              key="technologies"
            >
              <div className="filter-items_inner-panel">
                <Collapse expandIconPosition="right" bordered={false}>
                  <Panel
                    header={
                      <>
                        <Text>Technologies Used</Text>
                        <Tooltip
                          placement="right"
                          title="Select technologies that your target companies are currently using to see people from them"
                        >
                          <InfoIcon className="filter-info-icon" />
                        </Tooltip>
                      </>
                    }
                  >
                    <Form.Item name="technologies">
                      <DynamicAutoSuggest
                        onChange={(values) => applyFilter('technologies', 'technologies', values)}
                        filters={filters.technologies.technologies.map(
                          (tech) =>
                            DEFAULT_TECHNOLOGIES_OPTIONS.find((d) => d.value === tech)?.value ||
                            tech
                        )}
                        defaultOptionList={DEFAULT_TECHNOLOGIES_OPTIONS}
                        placeholder="Search Technology"
                        apiEndPoint={endPoints.technologyAutoSuggestions}
                        type="image-text"
                        style={{ width: '95%' }}
                      />
                    </Form.Item>
                    <FilterSelectionButton
                      values={DEFAULT_TECHNOLOGIES_OPTIONS}
                      selectFilter={(value) => selectFilter('technologies', 'technologies', value)}
                    />
                  </Panel>
                </Collapse>
              </div>
            </Panel>
            <Panel
              header={
                <div className="expand-filter-header">
                  <div className="filter-header-text">
                    <span>
                      <PsychographicIcon fill="#697390" width="16" height="16" />
                    </span>
                    <span>
                      <Text>Psychographics</Text>
                    </span>
                    <span>
                      <FilterCount
                        filters={filters}
                        type="keywords"
                        resetFilter={resetFilter}
                        getCount={getFilterCount}
                      />
                    </span>
                  </div>
                  {activeFilter !== 'keywords' && (
                    <div className="selected-filters">
                      <FilterTag filters={filters} onFilterApply={onFilterApply} type="keywords" />
                    </div>
                  )}
                </div>
              }
              key="keywords"
            >
              <div className="filter-items_inner-panel">
                <Collapse expandIconPosition="right" bordered={false}>
                  <Panel
                    header={
                      <>
                        <Text>Psychographics</Text>
                        <Tooltip placement="right" title={t('filters.tooltip.KEYWORDS')}>
                          <InfoIcon className="filter-info-icon" />
                        </Tooltip>
                      </>
                    }
                  >
                    <Form.Item name="keywords">
                      <DynamicAutoSuggest
                        onChange={(values) => applyFilter('keywords', 'keywords', values)}
                        filters={filters.keywords.keywords.map(
                          (keyword) =>
                            DEFAULT_KEYWORDS_OPTIONS.find((d) => d.value === keyword)?.value ||
                            keyword
                        )}
                        defaultOptionList={DEFAULT_KEYWORDS_OPTIONS}
                        placeholder="Search Psychographics"
                        apiEndPoint={endPoints.keywordsAutoSuggestions}
                        type="image-text"
                        style={{ width: '95%' }}
                      />
                    </Form.Item>
                    <FilterSelectionButton
                      values={DEFAULT_KEYWORDS_OPTIONS}
                      selectFilter={(value) => selectFilter('keywords', 'keywords', value)}
                    />
                  </Panel>
                </Collapse>
              </div>
            </Panel>
            <Panel
              header={
                <div className="expand-filter-header">
                  <div className="filter-header-text">
                    <span>
                      <OtherFilterIcon fill="#697390" width="16" height="16" />
                    </span>
                    <span>
                      <Text>Additional</Text>
                    </span>
                    <span>
                      <FilterCount
                        filters={filters}
                        type="other"
                        resetFilter={resetFilter}
                        getCount={getFilterCount}
                      />
                    </span>
                  </div>
                  {activeFilter !== 'other' && (
                    <div className="selected-filters">
                      <FilterTag filters={filters} onFilterApply={onFilterApply} type="other" />
                    </div>
                  )}
                </div>
              }
              key="other"
            >
              <div className="filter-items_inner-panel">
                <Collapse expandIconPosition="right" bordered={false}>
                  <Panel
                    header={
                      <>
                        <Text>Source</Text>
                        <Tooltip
                          placement="topLeft"
                          title="Filter people based on the source from which they were created"
                        >
                          <InfoIcon className="filter-info-icon" />
                        </Tooltip>
                      </>
                    }
                  >
                    <Form.Item name="source">
                      <DynamicAutoSuggest
                        onChange={(values) => applyFilter('other', 'source', values)}
                        style={{ width: '95%' }}
                        filters={filters.other.source}
                        type="key-value"
                        name="Source"
                        optionList={LIST_SOURCE_DATA}
                        placeholder="Select source"
                      />
                    </Form.Item>
                    <FilterSelectionButton
                      values={LIST_SOURCE_DATA}
                      selectFilter={(value) => selectFilter('other', 'source', value)}
                    />
                  </Panel>
                  <Divider />
                  <Panel
                    header={
                      <>
                        <Text>Date Added</Text>
                        <Tooltip
                          placement="topLeft"
                          title="Specify a date range to view people that were created between those dates"
                        >
                          <InfoIcon className="filter-info-icon" />
                        </Tooltip>
                      </>
                    }
                  >
                    <Form.Item name="created_at">
                      <AddedDateRange
                        onChange={(value) => formatDateAndApplyFilter(value)}
                        style={{ width: '95%' }}
                      />
                    </Form.Item>
                  </Panel>
                </Collapse>
              </div>
            </Panel>
          </Collapse>
        </div>
      </Form>
    </div>
  );
};

LeadQuickFilter.propTypes = {
  onFilterChanged: PropTypes.func.isRequired,
};

export default LeadQuickFilter;

import { UPDATE_USER_INFO } from 'store/actionTypes';

const initialState = {
  user: {},
  pusherChannel: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_INFO:
      return {
        ...state,
        user: action.data.user,
        pusherChannel: action.data.pusherChannel,
      };
    default:
      return state;
  }
};

export default auth;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ExportSalesForce.scss';
import { CustomAlert, CustomWarningAlert, CustomInfoAlert } from 'UI/CustomAlert/CustomAlert';
import Drawer from 'UI/CustomDrawer/CustomDrawer';
import DrawerTitle from 'UI/CustomDrawer/DrawerTitle';
import IntegrationService from 'api/services/integrationService';
import { useSelector, useDispatch } from 'react-redux';
import { Radio, Row, Space, Typography, Col } from 'antd';
import { getLoggedInUser } from 'utils/common';
import { WarningOutlined } from '@ant-design/icons';
import { clearCheckedItems, updateUserInfoRedis } from 'containers/discovery/discoveryActions';
import { SF_LEAD_EXPORT_TYPE } from 'constants/constants';
import { eventManager, eventList, eventActions } from 'utils/eventManager';
import { ExportProgress } from 'UI/CustomMessages/CustomMessages';
import { useTranslation } from 'react-i18next';

const CUSTOM_ALERT_TOTAL_COUNT = 25;

const getSavedPreference = (salesForceConfig, userInfoRedis) => {
  if (
    salesForceConfig.lead_export_type === SF_LEAD_EXPORT_TYPE.both ||
    !salesForceConfig.lead_export_type
  ) {
    // when export type both(3) option is selected
    return userInfoRedis.salesforce_preference?.exortType ?? salesForceConfig.lead_export_type;
  }
  return salesForceConfig.lead_export_type;
};

const ExportSalesForce = ({
  crm,
  setSelectedOption,
  getSearchParams,
  tab,
  selectedIds = [],
  totalSelected,
  source = '',
  id,
}) => {
  const salesForceConfig = useSelector((state) => state.common.salesforceConfig) || {};
  const { defaultInnerActiveKey } = useSelector((state) => state.discovery);
  const userInfo = getLoggedInUser();
  const { userId, userEmail, user_access_role: userRole } = userInfo;
  const { credits, userInfoRedis } = useSelector((state) => state.common);

  const totalCount = totalSelected;
  const hasCredits = credits && credits.credits_left >= totalCount;
  const { api_url: sfApiUrl, is_migrated: isMigrated = false } = salesForceConfig;
  const [exportOption, setExportOption] = useState(
    getSavedPreference(salesForceConfig, userInfoRedis)
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation(['export']);

  const exportSalesforce = async () => {
    const params = {
      filter: {},
      leadId: id ? [id] : selectedIds,
      userId: userId.toString(),
      sfApiUrl,
      userEmail,
      scheduleAt: new Date().getTime(),
      export_count: totalCount,
      lead_export_type: exportOption,
    };
    const reqData = {
      data: params,
      type: 'EXPORT_SF',
      metadata: getSearchParams(tab),
      export_count: totalCount,
      entity: tab,
    };
    setIsLoading(true);
    await IntegrationService.exportAddtoListScheduler(reqData);
    setIsLoading(false);
    ExportProgress({
      key: `${crm}-${userId}`,
      duration: 0,
      msg: 'Your Salesforce export is in progress. This can take up to a few minutes, and we will notify you once the export is done.',
    });
    dispatch(updateUserInfoRedis({ salesforce_preference: { exortType: exportOption } }));
    setSelectedOption('');
    dispatch(clearCheckedItems());
    eventManager.track(eventList.EXPORT_TRIGGERED, {
      payload: {
        tab,
        export_type: eventActions.SALESFORCE,
        navigation: defaultInnerActiveKey,
        payload: getSearchParams(tab),
        version: eventActions.NDF,
        export_source: source,
      },
    });
  };

  return (
    <div className="ExportSalesForceWrapper">
      <Drawer
        content={
          <DrawerContent
            exportOption={exportOption}
            setExportOption={setExportOption}
            salesForceConfig={salesForceConfig}
            hasCredits={hasCredits}
            userRole={userRole}
            tab={tab}
            t={t}
            isMigrated={isMigrated}
            totalCount={totalCount}
          />
        }
        title={
          <DrawerTitle
            title="PUSH TO SALESFORCE"
            actions={[
              {
                title: 'Cancel',
                type: 'default',
                theme: 'secondary',
                disabled: isLoading,
                size: 'medium',
                onClick: () => {
                  setSelectedOption('');
                },
              },
              {
                title: 'Confirm',
                type: 'primary',
                theme: 'primary',
                size: 'medium',
                loading: isLoading,
                disabled:
                  !hasCredits ||
                  !totalCount ||
                  (tab === 'lead' && exportOption === SF_LEAD_EXPORT_TYPE.both),
                onClick: exportSalesforce,
              },
            ]}
          />
        }
        visible={crm === 'salesforce'}
        width={600}
      />
    </div>
  );
};

const salesforceExportWarning = (userRole, type) => {
  const exportType = type === 'lead' ? 'People' : 'Contacts';
  return (
    <div className="export-type-warning">
      <WarningOutlined style={{ color: '#FF7C0B', fontSize: '14px', marginRight: '4px' }} />
      {userRole === 1
        ? `Update Salesforce lead export configuration for Slintel to export as ${exportType}`
        : `Reach out to your company admin for Slintel to export as ${exportType}`}
    </div>
  );
};

const DrawerContent = (props) => {
  const {
    setExportOption,
    exportOption,
    salesForceConfig,
    hasCredits,
    userRole,
    tab,
    t,
    isMigrated,
    totalCount,
  } = props;
  const infoMsg = `${totalCount} records selected`;
  return (
    <div>
      <Row gutter={[16, 32]} style={{ marginBottom: '16px' }}>
        <Col span={24}>
          {isMigrated ? (
            <CustomInfoAlert
              description={
                totalCount > CUSTOM_ALERT_TOTAL_COUNT
                  ? t('warning_messages.static.salesforce_bulk_export_warning')
                  : t('warning_messages.static.salesforce_export_warning')
              }
              message={infoMsg}
            />
          ) : (
            <CustomAlert message={infoMsg} {...props} />
          )}
          {!hasCredits ? (
            <CustomWarningAlert message="Insufficient credits for export. Talk to your Customer Success Manager to get credits" />
          ) : null}
        </Col>
      </Row>

      {tab === 'lead' ? (
        <>
          <Row gutter={[16, 32]} style={{ marginBottom: '16px' }}>
            <Col span={18}>
              <Typography.Text strong>Push to Salesforce as</Typography.Text>
            </Col>
          </Row>
          <Row gutter={[16, 32]}>
            <Col span={22}>
              <Radio.Group
                onChange={(v) => {
                  setExportOption(v.target.value);
                }}
                value={exportOption}
              >
                <Space direction="vertical">
                  <Radio
                    value={SF_LEAD_EXPORT_TYPE.leads}
                    disabled={
                      ![SF_LEAD_EXPORT_TYPE.leads, SF_LEAD_EXPORT_TYPE.both].includes(
                        salesForceConfig.lead_export_type
                      )
                    }
                  >
                    <span> Lead </span>
                    {![SF_LEAD_EXPORT_TYPE.leads, SF_LEAD_EXPORT_TYPE.both].includes(
                      salesForceConfig.lead_export_type
                    )
                      ? salesforceExportWarning(userRole, 'lead')
                      : null}
                  </Radio>
                  <Radio
                    value={SF_LEAD_EXPORT_TYPE.contacts}
                    disabled={
                      ![SF_LEAD_EXPORT_TYPE.contacts, SF_LEAD_EXPORT_TYPE.both].includes(
                        salesForceConfig.lead_export_type
                      )
                    }
                  >
                    Contacts
                    {![SF_LEAD_EXPORT_TYPE.contacts, SF_LEAD_EXPORT_TYPE.both].includes(
                      salesForceConfig.lead_export_type
                    )
                      ? salesforceExportWarning(userRole, 'contact')
                      : null}
                  </Radio>
                </Space>
              </Radio.Group>
            </Col>
          </Row>
        </>
      ) : null}
    </div>
  );
};
ExportSalesForce.propTypes = {
  tab: PropTypes.string.isRequired,
  crm: PropTypes.string,
  selectedIds: PropTypes.array,
  setSelectedOption: PropTypes.func,
  getSearchParams: PropTypes.func,
  totalSelected: PropTypes.number,
  source: PropTypes.string,
};

export default React.memo(ExportSalesForce);

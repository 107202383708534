export const DISCOVER_MESSAGES = {
  insta_reveal_disclaimer:
    'The Insta Reveal feature lets you perform a live check on the web to find the contact info of People, which Slintel doesn’t have details for on its database. However, it is still possible that we don’t find any info for some people.',
  advanced_search:
    'You can search for strings along with boolean operators to filter out more detailed information regarding People or Company. You can use AND (+) , OR (|), and NOT (-) operators. Eg : “Taleo+Greenhouse”, learn more about how to use this search',
};

export const DISCOVER_URL = {
  boolean_search_doc:
    'https://slintel.freshdesk.com/support/solutions/articles/44002330163-advanced-and-boolean-search-on-slintel',
};

import { getRequest, postRequest } from 'api/apiRequests';
import { baseURL, endPoints } from 'api/apiEndpoints';
import isObject from 'lodash/isObject';

const headers = {};
/**
 * List service for list management api
 */
const ListService = {
  async fetchSmartList(params) {
    const url = `${baseURL}${endPoints.lists}`;
    const response = await getRequest(url, params, headers);
    return response;
  },
  async fetchExclusionList(params) {
    const url = `${baseURL}${endPoints.lists}`;
    const response = await getRequest(url, params, headers);
    return response;
  },
  async addItemsToList(params) {
    const { listId, listType, ...queryParams } = params;
    let id = listId.value;
    if (listType === 'lead') {
      queryParams.total_leads = queryParams?.ids?.length;
    }
    if (listType === 'company') {
      queryParams.total_companies = queryParams?.ids?.length;
    }
    if (!isObject(listId)) {
      const response = await ListService.createList({ autoUpdate: true, listType, title: listId });
      id = response.id;
    }
    const url = `${baseURL}${endPoints.lists}/${id}/${endPoints.ADD_TO_LIST}`;
    const response = await postRequest(url, queryParams, headers);
    return response;
  },
  async addToSmartListJobsPage(params) {
    const { listId, listType, ...filterParams } = params;
    let queryParams = {};
    let response;
    let id = listId.value;
    if (filterParams?.exclusion_list_ids) {
      queryParams.exclusion_list_ids = filterParams?.exclusion_list_ids;
    }
    if (!isObject(listId)) {
      response = await ListService.createList({ autoUpdate: true, listType, title: listId });
      id = response.id;
    }
    if (listType === 'lead') {
      queryParams = {
        total_leads: filterParams?.jobFilters.totalCount,
        filter: filterParams?.jobFilters.filtersToSmartList,
        ...queryParams,
      };
      const url = `${baseURL}${endPoints.lists}/${id}/create-job`;
      response = await postRequest(url, queryParams, headers);
      const queryData = `?jobId=${response.jobId}&from=job-posting&jobItemId=${response.jobItemId}`;
      window.location.href = `/list/${response.listId}${queryData}`;
      return response;
    }
    if (listType === 'company') {
      queryParams = {
        total_companies: filterParams?.jobFilters.totalCount,
        filter: filterParams?.jobFilters.filtersToSmartList,
        ...queryParams,
      };
      const url = `${baseURL}${endPoints.lists}/${id}/${endPoints.ADD_TO_LIST_JOBPAGE}`;
      response = await postRequest(url, queryParams, headers);
    }
    return response;
  },
  async createList(params) {
    const url = `${baseURL}${endPoints.lists}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
};

export default ListService;

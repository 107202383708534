import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import {
  NextArrowIcon,
  PreviousArrowIcon,
  DownArrowIcon,
  UpArrowIconDark,
} from 'UI/CustomIcon/CustomIcon';
import './OpenerButton.scss';

// TODO: ADD ICONS for UP and DOWN in when passed
const OpenerButton = ({ direction, iconProps, onOpenClick, ...props }) => {
  const renderIcon = () => {
    switch (direction) {
      case 'right':
        return <NextArrowIcon {...iconProps} />;
      case 'left':
        return <PreviousArrowIcon {...iconProps} />;
      case 'up':
        return <UpArrowIconDark {...iconProps} />;
      case 'down':
        return <DownArrowIcon {...iconProps} />;
      default:
        return <></>;
    }
  };

  return (
    <Button
      size="small"
      shape="circle"
      className="filter-arrow-btn"
      disabled={props.disabled || false}
      onClick={onOpenClick}
      icon={renderIcon()}
      {...props}
    />
  );
};

OpenerButton.propTypes = {
  direction: PropTypes.string,
  iconProps: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
  onOpenClick: PropTypes.func,
};

OpenerButton.defaultProps = {
  direction: 'right',
  iconProps: { height: '10', width: '6' },
};

export default OpenerButton;

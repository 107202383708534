import React, { lazy, Suspense, useState } from 'react';
import { Button, Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { toggleCreditLimitModalVisibility } from 'containers/common/commonActions';
import { postRequest } from 'api/apiRequests';
import { headerGenerator } from 'utils/common';
import { baseURL, endPoints } from 'api/apiEndpoints';
import { showError } from 'components/common/errorMessage';
import './creditLimit.scss';
import exhaustedCreditAnimation from 'assets/animations/exhausted_credit.json';
import creditSuccessAnimation from 'assets/animations/credit_success.json';
import CustomLoader from 'UI/CustomLoader';

const Player = lazy(() => import('utils/player'));

const CreditLimitModal = () => {
  const [requestRaised, setRequestRaised] = useState(false);
  const userData = useSelector((state) => state.auth.user);
  const visibility = useSelector((state) => state.common.creditLimitModalVisibility);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const raiseRequest = async () => {
    setIsLoading(true);

    const { token, session_id: sessionId, userId, userEmail, userName } = userData;
    const headers = headerGenerator(token, sessionId);

    const connectUrl = `${baseURL}${endPoints.userUpgrade}`;
    const payload = {
      subject: 'Upgrade Request Alert!',
      userName,
      userEmail,
      userId,
      url: window.location.href,
      requestedDate: new Date().toString(),
      reason: 'Credits are exhausted & user requires more credits for exports',
    };

    const response = await postRequest(connectUrl, payload, headers);
    setIsLoading(false);
    if (response.error) {
      showError(response.message);
      dispatch(toggleCreditLimitModalVisibility(false));
    } else if (response.msg) {
      setRequestRaised(true);
    }
  };

  const commonPlayerProps = {
    autoplay: true,
    loop: true,
    controls: false,
    renderer: 'canvas',
    extraProp: {},
  };

  const renderView = () => {
    if (requestRaised === true) {
      return (
        <span className="credit-limit-container">
          <Suspense fallback={<CustomLoader />}>
            <Player
              {...commonPlayerProps}
              src={creditSuccessAnimation}
              style={{ height: '116px', width: '130px' }}
            />
          </Suspense>
          <p className="credit-limit-message">
            Your Customer Success Manager will reach out to you shortly
          </p>
        </span>
      );
    }
    return (
      <div className="credit-limit-container">
        <Suspense fallback={<CustomLoader />}>
          <Player
            {...commonPlayerProps}
            src={exhaustedCreditAnimation}
            style={{ height: '130px', width: '133px' }}
          />
        </Suspense>
        <p className="credit-limit-message">You’ve hit your credit limit!</p>
        <p className="credit-limit-description">
          Talk to your Customer Success Manager to know more
        </p>
        <Button
          loading={isLoading}
          className="request-button"
          type="primary"
          onClick={() => raiseRequest()}
        >
          Raise a request
        </Button>
      </div>
    );
  };

  return (
    <Modal
      centered
      visible={visibility}
      className="credit-limit-modal"
      closable={!isLoading}
      footer={null}
      width={500}
      onCancel={() => {
        setRequestRaised(false);
        dispatch(toggleCreditLimitModalVisibility(false));
      }}
    >
      {renderView()}
    </Modal>
  );
};

export default React.memo(CreditLimitModal);

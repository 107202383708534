export const PINCODE_INIT = 'PINCODE_INIT';
export const PINCODE_SUCCESS = 'PINCODE_SUCCESS';
export const PINCODE_FAILURE = 'PINCODE_FAILURE';

export const NEWS_INIT = 'NEWS_INIT';
export const NEWS_SUCCESS = 'NEWS_SUCCESS';
export const NEWS_FAILURE = 'NEWS_FAILURE';
export const NEWS_FEED_RESET = 'NEWS_FEED_RESET';

export const CREDIT_INIT = 'CREDIT_INIT';
export const CREDIT_SUCCESS = 'CREDIT_SUCCESS';
export const CREDIT_INFO_FAILURE = 'CREDIT_INFO_FAILURE';

// credit limit modal visibility
export const CREDIT_LIMIT_MODAL = 'CREDIT_LIMIT_MODAL';

export const SYNC_STORE = 'SYNC_STORE';

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';

export const FILTERS_INIT = 'FILTERS_INIT';
export const FILTERS_SUCCESS = 'FILTERS_SUCCESS';
export const FILTERS_FAILURE = 'FILTERS_FAILURE';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const UPDATEFILTER_SUCCESS = 'UPDATEFILTER_SUCCESS';
export const DUPLICATE_FILTER_INIT = 'DUPLICATE_FILTER_INIT';
export const FILTER_DUPLICATE_SUCCESS = 'FILTER_DUPLICATE_SUCCESS';
export const SHARE_SAVED_FILTER = 'SHARE_SAVED_FILTER';
export const SHARE_FILTER_SUCCESS = 'SHARE_FILTER_SUCCESS';
export const SHARE_FILTER_FAILURE = 'SHARE_FILTER_FAILURE';

export const DELETE_FILTERS_INIT = 'DELETE_FILTERS_INIT';
export const DELETE_FILTERS_SUCCESS = 'DELETE_FILTERS_SUCCESS';
export const DELETE_FILTERS_FAILURE = 'DELETE_FILTERS_FAILURE';

export const DOMAIN_USERS_INIT = 'DOMAIN_USERS_INIT';
export const DOMAIN_USERS_SUCCESS = 'DOMAIN_USERS_SUCCESS';
export const DOMAIN_USERS_FAILURE = 'DOMAIN_USERS_FAILURE';

export const LISTS_INIT = 'LISTS_INIT';
export const LISTS_DEBOUNCE_INIT = 'LISTS_DEBOUNCE_INIT';
export const LISTS_SUCCESS = 'LISTS_SUCCESS';
export const LISTS_SEARCH_SUCCESS = 'LISTS_SEARCH_SUCCESS';
export const LISTS_FAILURE = 'LISTS_FAILURE';
export const LISTS_RESET = 'LISTS_RESET';
export const LISTS_CREATE = 'LISTS_CREATE';
export const LISTS_CREATE_SUCCESS = 'LISTS_CREATE_SUCCESS';
export const LIST_DELETE = 'LIST_DELETE';
export const LIST_DELETE_SUCCESS = 'LIST_DELETE_SUCCESS';
export const LIST_ACTIVE_FILTER = 'LIST_ACTIVE_FILTER';

export const HUBSPOT_CONFIG = 'HUBSPOT_CONFIG';
export const HUBSPOT_CONFIG_SUCCESS = 'HUBSPOT_CONFIG_SUCCESS';
export const HUBSPOT_CONFIG_FAILURE = 'HUBSPOT_CONFIG_FAILURE';

export const SALESFORCE_CONFIG = 'SALESFORCE_CONFIG';
export const SALESFORCE_CONFIG_SUCCESS = 'SALESFORCE_CONFIG_SUCCESS';
export const SALESFORCE_CONFIG_FAILURE = 'SALESFORCE_CONFIG_FAILURE';

export const OUTREACH_CONFIG = 'OUTREACH_CONFIG';
export const OUTREACH_CONFIG_SUCCESS = 'OUTREACH_CONFIG_SUCCESS';
export const OUTREACH_CONFIG_FAILURE = 'OUTREACH_CONFIG_FAILURE';
export const PIPEDRIVE_CONFIG = 'PIPEDRIVE_CONFIG';
export const PIPEDRIVE_CONFIG_SUCCESS = 'PIPEDRIVE_CONFIG_SUCCESS';
export const PIPEDRIVE_CONFIG_FAILURE = 'PIPEDRIVE_CONFIG_FAILURE';
export const OUTREACH_FIELD_CHANGES = 'FIELD_CHANGES';
export const OUTREACH_MAPPING_SUCCESS = 'MAPPING_SUCCESS';
export const OUTREACH_INIT = 'OUTREACH_INIT';
export const FIELDMAPPINGS_DELETE = 'FIELDMAPPINGS_DELETE';
export const USER_INFO_REDIS = 'USER_INFO_REDIS';
export const USER_INFO_REDIS_SUCCESS = 'USER_INFO_REDIS_SUCCESS';
export const USER_INFO_REDIS_FAILURE = 'USER_INFO_REDIS_FAILURE';

// global level loading indicator
export const SET_LOADING = 'SET_LOADING';

// notification bell icon
export const SET_NOTIFICATION_BELL_STATUS = 'SET_NOTIFICATION_BELL_STATUS';

// saved Filter Loading
export const SET_SAVED_FILTER_LOADING = 'SET_SAVED_FILTER_LOADING';

// List Items and Filter
export const FETCH_LIST_ITEMS = 'FETCH_LIST_ITEMS';
export const LIST_ITEMS_SUCCESS = 'LIST_ITEMS_SUCCESS';
export const LIST_ITEMS_FAILURE = 'LIST_ITEMS_FAILURE';
export const SET_LIST_ITEMS_FILTER = 'SET_LIST_ITEMS_FILTER';
export const RESET_LIST_ITEMS_FILTER = 'RESET_LIST_ITEMS_FILTER';
export const LIST_ITEMS_INIT = 'LIST_ITEMS_INIT';
export const FETCH_PERMISSION = 'FETCH_PERMISSION';
export const FETCH_PERMISSION_SUCCESS = 'FETCH_PERMISSION_SUCCESS';
export const RESET_CURRENT_FILTER = 'RESET_CURRENT_FILTER';

export const TOGGLE_UPGRADE_MODAL = 'TOGGLE_UPGRADE_MODAL';
export const TOGGLE_SESSION_TIMEOUT_MODAL = 'TOGGLE_SESSION_TIMEOUT_MODAL';
export const UPGRADE_ASK_INIT = 'UPGRADE_ASK_INIT';
export const UPGRADE_ASK_SUCCESS = 'UPGRADE_ASK_SUCCESS';
export const UPGRADE_ASK_FAILURE = 'UPGRADE_ASK_FAILURE';
export const PROFILE_REMOVED_LISTS_INIT = 'PROFILE_REMOVED_LISTS_INIT';
export const PROFILE_REMOVED_LISTS_SUCCESS = 'PROFILE_REMOVED_LISTS_SUCCESS';
export const PROFILE_REMOVED_LISTS_FAILURE = 'PROFILE_REMOVED_LISTS_FAILURE';

export const SET_ACTIVE_PANEL = 'SET_ACTIVE_PANEL';
export const DEFAULT_INNER_ACTIVE_KEY = 'DEFAULT_INNER_ACTIVE_KEY';
export const UPDATE_CUSTOM_SELECTIONS = 'UPDATE_CUSTOM_SELECTIONS';
export const TAB_CHANGE = 'TAB_CHANGE';
export const SET_TAB = 'SET_TAB';
export const OPEN_QUICK_VIEW = 'OPEN_QUICK_VIEW';
export const CLOSE_QUICK_VIEW = 'CLOSE_QUICK_VIEW';
export const FILTER_LIST_DATA = 'FILTER_LIST_DATA';
export const FILTER_LIST_SUCCESS = 'FILTER_LIST_SUCCESS';
export const FILTER_LIST_ERROR = 'FILTER_LIST_ERROR';
export const SHOW_SHIMMERS = 'SHOW_SHIMMERS';
export const UPDATE_LEAD = 'UPDATE_LEAD';
export const SEARCH_LEADS = 'SEARCH_LEADS';
export const SEARCH_COMPANIES = 'SEARCH_COMPANIES';
export const SEARCH_LEADS_SUCCESS = 'SEARCH_LEADS_SUCCESS';
export const SEARCH_COMPANIES_SUCCESS = 'SEARCH_COMPANIES_SUCCESS';
export const SEARCH_LEADS_ERROR = 'SEARCH_LEADS_ERROR';
export const SEARCH_COMPANIES_ERROR = 'SEARCH_COMPANIES_ERROR';
export const SET_CHECKED_ITEMS = 'SET_CHECKED_ITEMS';
export const CLEAR_CHECKED_ITEMS = 'CLEAR_CHECKED_ITEMS';
export const SELECT_FILTER = 'SELECT_FILTER';
export const RESET_FILTER = 'RESET_FILTER';
export const RESET_LEAD_FILTERS = 'RESET_LEAD_FILTERS';
export const RESET_COMPANY_FILTERS = 'RESET_COMPANY_FILTERS';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const SHOW_TOAST_NOTIFICATION = 'SHOW_TOAST_NOTIFICATION';
export const TOGGLE_FILTER_VIEW = 'TOGGLE_FILTER_VIEW';

export const OPEN_ADDTO_LIST = 'OPEN_ADDTO_LIST';
export const CLOSE_ADDTO_LIST = 'CLOSE_ADDTO_LIST';
export const CREATE_LIST = 'CREATE_LIST';
export const ADDTO_LIST = 'ADDTO_LIST';
export const ADD_TO_LIST_JOBSPAGE = 'ADD_TO_LIST_JOBSPAGE';
export const ADDTO_LIST_START = 'ADDTO_LIST_START';
export const ADDTO_LIST_END = 'ADDTO_LIST_END';
export const TOGGLE_LEAD_FILTERS = 'TOGGLE_LEAD_FILTERS';
export const TOGGLE_COMPANY_FILTERS = 'TOGGLE_COMPANY_FILTERS';
export const STORE_SORT = 'STORE_SORT';
export const NET_NEW_LEADS_SUCCESS = 'NET_NEW_LEADS_SUCCESS';
export const NET_NEW_COMPANIES_SUCCESS = 'NET_NEW_COMPANIES_SUCCESS';
export const NET_NEW_LEADS_ERROR = 'NET_NEW_LEADS_ERROR';
export const NET_NEW_COMPANIES_ERROR = 'NET_NEW_COMPANIES_ERROR';
export const SEARCH_NET_NEW_LEADS = 'SEARCH_NET_NEW_LEADS';
export const SEARCH_NET_NEW_COMPANIES = 'SEARCH_NET_NEW_COMPANIES';
export const SCROLL_AREA_EXTRA_SIZE = 'SCROLL_AREA_EXTRA_SIZE';
export const OPEN_SAVE_FILTER = 'OPEN_SAVE_FILTER';
export const CLOSE_SAVE_FILTER = 'CLOSE_SAVE_FILTER';
export const SAVE_FILTER_SUCCESS = 'SAVE_FILTER_SUCCESS';
export const SAVE_FILTERS = 'SAVE_FILTERS';
export const OPEN_REPORT_DRAWER = 'OPEN_REPORT_DRAWER';
export const CLOSE_REPORT_DRAWER = 'CLOSE_REPORT_DRAWER';
// USER REGISTER
export const USER_CREATE = 'USER_CREATE';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_FAILURE = 'USER_CREATE_FAILURE';
export const GET_CSRF = 'GET_CSRF';
export const CSRF_FAILURE = 'CSRF_FAILURE';
export const CSRF_SUCCESS = 'CSRF_SUCCESS';
export const SEND_VERIFICATION = 'SEND_VERIFICATION';
export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS';
export const VERIFICATION_FAILURE = 'VERIFICATION_FAILURE';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const PROMOCODE_APPLY = 'PROMOCODE_APPLY';
export const PROMOCODE_APPLY_SUCCESS = 'PROMOCODE_APPLY_SUCCESS';
export const PROMOCODE_APPLY_FAILURE = 'PROMOCODE_APPLY_FAILURE';
export const PROMOCODE_APPLY_RESET = 'PROMOCODE_APPLY_RESET';
export const SET_USER_SELECTION = 'SET_USER_SELECTION';
export const UPDATE_USER_INFO_REDIS = 'UPDATE_USER_INFO_REDIS';

// Used in ExportPopover Component for Dropdown
export const SET_DROPDOWN_ID = 'SET_DROPDOWN_ID';
export const SET_EXPORT_DROPDOWN_VISIBLE = 'SET_EXPORT_DROPDOWN_VISIBLE';

// Refactor - move to common - will have to pass module name from existing callers
export const OPEN_QUICK_VIEW_SMART_LIST = 'OPEN_QUICK_VIEW_SMART_LIST';
export const CLOSE_QUICK_VIEW_SMART_LIST = 'CLOSE_QUICK_VIEW_SMART_LIST';
export const UPDATE_LEAD_SMART_LIST = 'UPDATE_LEAD_SMART_LIST';
export const OPEN_ADDTO_LIST_SMART_LIST = 'OPEN_ADDTO_LIST_SMART_LIST';
export const CLOSE_ADDTO_LIST_SMART_LIST = 'CLOSE_ADDTO_LIST_SMART_LIST';
export const SET_ACTION = 'SET_ACTION';
export const CLEAR_ADD_TO_LIST_SOURCE = 'CLEAR_ADD_TO_LIST_SOURCE';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { getDiffInDays } from 'utils/common';
import CollapseCard from 'UI/CollapseCard/CollapseCard';
import TechnographicsContent from '../TechnographicsContent/TechnographicsContent';
import './TechnographicsView.scss';
import EmptyView from '../../EmptyView/EmptyView';

const { Text } = Typography;
// const defaultActiveKey = [];

const searchCondition = (el, category, filterValue, level) => {
  if (level === 0 || level === 1) return el.toLowerCase().includes(filterValue);
  return false;
};
const sourceCondition = (el, category, filterValue, level) => {
  if (level === 1) {
    return !!category?.sources.find((source) => {
      return filterValue.includes(source);
    });
  }
  return false;
};

const sliderCondition = (el, category, filterValue, level) => {
  if (level === 1) {
    const [min, max] = filterValue;
    return min <= category?.confidence_score && category?.confidence_score <= max;
  }
  return false;
};

const lastDetectedInCondition = (el, category, filterValue, level) => {
  if (level === 1) {
    if (!category.last_detected) {
      return true;
    }

    return getDiffInDays(category.last_detected) <= filterValue;
  }
  return false;
};

const getFilterResult = (categories, filterValue, filterCondition) => {
  const result = {};
  Object.entries(categories).forEach(([el, category]) => {
    if (filterCondition(el, category, filterValue, 0)) {
      result[el] = category;
      // defaultActiveKey.push(el);
    } else {
      Object.entries(category).forEach(([item, items]) => {
        if (filterCondition(item, items, filterValue, 1)) {
          if (!result[el]) {
            result[el] = {};
          }
          if (!result[el][item]) {
            result[el][item] = {};
          }
          // defaultActiveKey.push(el);
          result[el][item] = items;
        }
      });
    }
  });
  return result;
};

const getCollpaseData = (categories = {}, title, filters, isDetailView) => {
  let result = categories;
  const { search, source, lastDetectedValue, sliderValue } = filters;
  if (search) {
    result = getFilterResult(result, search, searchCondition);
  }
  if (isDetailView) {
    result = getFilterResult(result, sliderValue, sliderCondition);

    result = getFilterResult(result, source, sourceCondition);

    if (lastDetectedValue) {
      result = getFilterResult(result, lastDetectedValue, lastDetectedInCondition);
    }
  }

  return Object.keys(result).map((el, i) => {
    return {
      header: el,
      content: Object.keys(result[el]).map((t) => (
        <TechnographicsContent
          key={t}
          title={t}
          data={result[el][t]}
          type={title}
          onlyCard={isDetailView}
        />
      )),
      key: el,
    };
  });
};

const TechnographicsView = ({
  data,
  title,
  isDetailView,
  lastDetectedOptions,
  statusOptions,
  fetchTechStatus,
  emptyViewDescription,
  sourceOptions = [],
  ...rest
}) => {
  const [searchValue, setValue] = useState('');
  const [sliderValue, setSliderValue] = useState(
    title === 'TECHNOLOGY STACK' ? [0, 100] : [80, 100]
  );
  const [sourceValues, setSourceValues] = useState(sourceOptions.map((source) => source.value));
  const [lastDetectedValue, setLastDetectedValue] = useState(lastDetectedOptions?.[2]?.value);

  const [currentStatus, setCurrentStatusValue] = useState(statusOptions?.[0]?.value);

  const onSearch = (e) => {
    // todo: add debounce effect
    setValue(e.target.value);
  };
  const onSliderChange = (range) => {
    // todo: add debounce effect
    setSliderValue(range);
  };

  const handleSourceChange = (values) => {
    setSourceValues(values);
  };
  const handleLastDetectedChange = (value) => {
    setLastDetectedValue(value);
  };
  const handleStatus = (value) => {
    fetchTechStatus(value);
    setCurrentStatusValue(value);
  };

  return data.total || isDetailView ? (
    <div className="technographics-view">
      {!isDetailView && (
        <Text strong>
          {title} <span className="technographics-count">({data.total}) </span>
        </Text>
      )}
      <div className="technographics-collapse">
        <CollapseCard
          {...rest}
          isDetailView={isDetailView}
          handleLastDetectedChange={handleLastDetectedChange}
          lastDetectedOptions={lastDetectedOptions}
          statusOptions={statusOptions}
          currentStatus={currentStatus}
          handleStatus={handleStatus}
          lastDetectedValue={lastDetectedValue}
          handleSourceChange={handleSourceChange}
          data={getCollpaseData(
            data.categories,
            title,
            {
              search: searchValue.toLowerCase(),
              source: sourceValues,
              lastDetectedValue,
              sliderValue,
            },
            isDetailView
          )}
          total={data.total}
          title={title}
          sourceValues={sourceValues}
          sourceOptions={sourceOptions}
          onSearch={onSearch}
          onSliderChange={onSliderChange}
          searchValue={searchValue}
          sliderValue={sliderValue}
          emptyContent={
            <EmptyView
              description={
                emptyViewDescription ??
                `We did not find any ${
                  title === 'TECHNOLOGY STACK' ? 'technology' : 'psychographics'
                } for this search term. Please try a different search term.`
              }
            />
          }
        />
      </div>
    </div>
  ) : null;
};

TechnographicsView.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  sourceOptions: PropTypes.arrayOf(PropTypes.object),
};

TechnographicsView.defaultProps = {
  data: {},
  title: '',
  sourceOptions: [],
};

export default TechnographicsView;

import React from 'react';
import * as PropTypes from 'prop-types';
import { Modal } from 'antd';
import './Confirm.scss';

const Confirm = ({
  titleText,
  onOk,
  onCancel,
  visible,
  descText,
  okText = 'Delete',
  closable = false,
  cancelButtonProps = {
    type: 'default',
  },
  width,
}) => (
  <>
    <Modal
      title={titleText}
      visible={visible}
      className="confirm-modal"
      wrapClassName="confirm-modal-wrap"
      closable={closable}
      onOk={onOk}
      onCancel={onCancel}
      centered
      okText={okText}
      cancelText="Cancel"
      cancelButtonProps={cancelButtonProps}
      okButtonProps={{
        type: 'primary',
        style: {
          marginLeft: '15px',
        },
      }}
      width={width || '460px'}
      style={{
        height: '222px',
      }}
    >
      {descText}
    </Modal>
  </>
);

Confirm.propTypes = {
  titleText: PropTypes.any,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
  okText: PropTypes.string,
  closable: PropTypes.bool,
  cancelButtonProps: PropTypes.object,
  descText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  width: PropTypes.string,
};

export default Confirm;

import { call, put, takeLatest } from 'redux-saga/effects';
import { logout } from 'utils/common';
import { showError } from 'components/common/errorMessage';
import { baseURL, endPoints } from 'api/apiEndpoints';
import { postRequest, getRequestWithConfig } from 'api/apiRequests';
import {
  USER_CREATE,
  GET_CSRF,
  SEND_VERIFICATION,
  USER_LOGIN,
  USER_LOGOUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  PROMOCODE_APPLY,
} from 'store/actionTypes';
import {
  userCreateSuccess,
  userCreateFailure,
  csrfSuccess,
  csrfFailure,
  verificationSuccess,
  verificationFailure,
  loginSuccess,
  logoutSuccess,
  loginFailure,
  logoutFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  promoCodeApplySuccess,
  promoCodeApplyFailure,
} from './registerActions';

function* userCreate(action) {
  try {
    const { params, headers, csrfToken } = action.payload;
    const { history, ...requestParams } = params;
    const url = `${baseURL}${endPoints.userCreate}`;
    const config = {
      credentials: 'include',
      mode: 'cors',
    };
    const response = yield call(postRequest, url, requestParams, headers, '', config, csrfToken);
    if (!response.error) {
      yield put(userCreateSuccess(response));
    } else {
      yield put(userCreateFailure(response));
    }
  } catch (err) {
    yield put(userCreateFailure(err));
  }
}

function* fetchCsrfToken(action) {
  try {
    let { headers } = action.payload;
    const url = `${baseURL}${endPoints.csrftoken}`;
    headers = { ...headers, Accept: 'application/json' };
    const config = {
      credentials: 'include',
      mode: 'cors',
    };
    const method = 'GET';
    const defaultAxios = {
      withCredentials: true,
    };
    const response = yield call(getRequestWithConfig, {
      url,
      headers,
      method,
      config,
      defaultAxios,
    });
    if (!response.error) {
      yield put(csrfSuccess(response));
    } else {
      yield put(csrfFailure(response));
    }
  } catch (err) {
    yield put(csrfFailure(err));
  }
}

function* sendVerification(action) {
  try {
    const { params, headers, csrfToken } = action.payload;
    const { history, ...requestParams } = params;
    const url = `${baseURL}${endPoints.sendVerificationLink}`;
    const config = {
      credentials: 'include',
      mode: 'cors',
    };
    const response = yield call(postRequest, url, requestParams, headers, '', config, csrfToken);
    if (!response.error) {
      yield put(verificationSuccess(response));
    } else {
      yield put(verificationFailure(response));
    }
  } catch (err) {
    yield put(verificationFailure(err));
  }
}
function* userLogin(action) {
  try {
    const { params, headers, csrfToken } = action.payload;
    const { history, ...requestParams } = params;
    const url = `${baseURL}${endPoints.userLogin}`;
    const config = {
      credentials: 'include',
      mode: 'cors',
    };
    const response = yield call(postRequest, url, requestParams, headers, '', config, csrfToken);
    if (!response.error) {
      yield put(loginSuccess(response));
    } else {
      yield put(loginFailure(response));
    }
  } catch (err) {
    yield put(loginFailure(err));
  }
}

function* userLogout(action) {
  try {
    const { headers } = action.payload;
    const url = `${baseURL}${endPoints.userLogout}`;
    const response = yield call(postRequest, url, {}, headers);
    if (!response.error) {
      logout();
      yield put(logoutSuccess(response));
    }
  } catch (err) {
    showError(err);
    yield put(logoutFailure(err));
  }
}

function* forgotPassword(action) {
  try {
    const { params, headers, csrfToken } = action.payload;
    const { history, ...requestParams } = params;
    const url = `${baseURL}${endPoints.forgotPasswordAccount}`;
    const config = {
      credentials: 'include',
      mode: 'cors',
    };
    const response = yield call(postRequest, url, requestParams, headers, '', config, csrfToken);
    if (!response.error) {
      yield put(forgotPasswordSuccess(response));
    } else {
      yield put(forgotPasswordFailure(response));
    }
  } catch (err) {
    yield put(forgotPasswordFailure(err));
  }
}
function* resetPassword(action) {
  try {
    const { params, headers, csrfToken } = action.payload;
    const { history, ...requestParams } = params;
    const url = `${baseURL}${endPoints.resetPasswordAccount}`;
    const config = {
      credentials: 'include',
      mode: 'cors',
    };
    const response = yield call(postRequest, url, requestParams, headers, '', config, csrfToken);
    if (!response.error) {
      yield put(resetPasswordSuccess(response));
    } else {
      yield put(resetPasswordFailure(response));
    }
  } catch (err) {
    yield put(loginFailure(err));
  }
}
function* promocodeApply(action) {
  try {
    const { params, headers, csrfToken } = action.payload;
    const { history, ...requestParams } = params;
    const url = `${baseURL}${endPoints.promoCodeAccount}`;
    const config = {
      credentials: 'include',
      mode: 'cors',
    };
    const response = yield call(postRequest, url, requestParams, headers, '', config, csrfToken);
    if (!response.error) {
      yield put(promoCodeApplySuccess(response));
    } else {
      yield put(promoCodeApplyFailure(response));
    }
  } catch (err) {
    yield put(promoCodeApplyFailure(err));
  }
}

export default function* userCreateSaga() {
  yield takeLatest(USER_CREATE, userCreate);
  yield takeLatest(GET_CSRF, fetchCsrfToken);
  yield takeLatest(SEND_VERIFICATION, sendVerification);
  yield takeLatest(USER_LOGIN, userLogin);
  yield takeLatest(USER_LOGOUT, userLogout);
  yield takeLatest(FORGOT_PASSWORD, forgotPassword);
  yield takeLatest(RESET_PASSWORD, resetPassword);
  yield takeLatest(PROMOCODE_APPLY, promocodeApply);
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Space, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'components/common/loader';
import { TechnologyService } from 'api/services';
import Link from 'UI/Link';
import Thumbnail from 'UI/Thumbnail';
import { TYPES_MAPPER } from 'constants/constants';
import { TechnologyIcon } from 'UI/CustomIcon/CustomIcon';
import Listing from '../common/Listing';
import TechPsychContext from '../TechPsychContext';

const { fetchTechnologyCategories } = TechnologyService;
const { Text } = Typography;
const getThumbnail = (record) => {
  return record?.company_profile_image_url ? (
    <Thumbnail.Image
      size="small"
      imageUrl={record?.company_profile_image_url}
      fallbackIcon={TechnologyIcon}
    />
  ) : (
    <Thumbnail.Icon size="small" Icon={TechnologyIcon} />
  );
};

const getColumns = (history) => {
  const handleClick = (record) => {
    const url = `/${TYPES_MAPPER.technology}/${record._id}`;
    history.push(url, { from: history.location.pathname });
  };
  return [
    {
      title: 'TECHNOLOGY',
      dataIndex: 'company_name',
      key: 'company_name',
      // width: 300,
      render: (item, record) => {
        return (
          <div className="list-view-title">
            <Link
              style={{ padding: 0 }}
              text={getThumbnail(record)}
              onClick={() => handleClick(record)}
            />
            <div
              onClick={() => handleClick(record)}
              type="text"
              role="button"
              tabIndex={-1}
              style={{ padding: 0, cursor: 'pointer' }}
            >
              <Tooltip mouseEnterDelay={0.3} placement="topLeft" title={item}>
                <Space direction="vertical" size={0} align="start">
                  <Text
                    style={{
                      maxWidth: '200px',
                      color: '#29275f',
                      paddingBottom: '5px',
                    }}
                    ellipsis
                  >
                    {item}
                  </Text>
                </Space>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
    {
      title: 'COMPANIES',
      dataIndex: 'domains',
      key: 'domains',
      width: 200,
      align: 'center',
      render: (item, record) =>
        record.domains > 0 ? record.domains.toLocaleString('en-US') : record.domains,
    },
    {
      title: 'ADDED',
      dataIndex: 'adds_this_month',
      key: 'adds_this_month',
      width: 150,
      align: 'center',
    },
    {
      title: 'DELETED',
      dataIndex: 'deleted_this_month',
      key: 'deleted_this_month',
      width: 150,
      align: 'center',
    },
  ];
};

const Technology = () => {
  const [isFilterDataLoading, setIsFilterDataLoading] = useState(false);
  const [filterData, setFilterData] = useState({});
  const history = useHistory();
  const getTechnologyCategories = async () => {
    let filters = {};
    setIsFilterDataLoading(true);
    try {
      const response = await fetchTechnologyCategories();
      filters = response?.data?.error ? {} : response.data || {};
      setFilterData(filters);
    } catch (e) {
      // TODO: handle error
    }
    setIsFilterDataLoading(false);
  };

  useEffect(() => {
    getTechnologyCategories();
  }, []);
  const columns = getColumns(history);
  return (
    <>
      <TechPsychContext.Provider value="Technology">
        {isFilterDataLoading ? (
          <Spinner />
        ) : (
          <Listing filterOptions={filterData} columns={columns} type="technology" />
        )}
      </TechPsychContext.Provider>
    </>
  );
};

export default Technology;

import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Progress, Row, Space, Steps, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import { baseURL, endPoints } from 'api/apiEndpoints';
import { deleteRequest, getRequest, postRequest } from 'api/apiRequests';
import { showError, showSuccess } from 'components/common/errorMessage';
import PresignedButton from 'components/common/presignedButton';
import {
  HUBSPOT_EXPORT_LIMIT,
  LIST_EXTENDED_TIMEOUT,
  LIST_JOB_TYPES,
  MAX_ITEM_PER_LIST,
  SAMPLE_COMPANY_ENRICHMENT_CSV,
  SAMPLE_LEAD_DISCOVERY_CSV,
  SAMPLE_LEAD_ENRICHMENT_CSV,
  SF_EXPORT_COMPANY,
  SF_EXPORT_LEAD,
} from 'constants/constants';
import { toggleCreditLimitModalVisibility } from 'containers/common/commonActions';
import {
  clearAddToListSource,
  closeAddToList,
  closeQuickView,
  getListItems,
  openAddToList,
  openQuickView,
  updateLead,
} from 'containers/smartList/smartListActions';
import 'containers/smartList/smartListIndex.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useLocation, useParams } from 'react-router-dom';
import ButtonUI from 'UI/Button';
import {
  AddCompaniesFromSlintelIcon,
  CompanyEnrichmentIcon,
  DownloadIcon,
  InfoIcon,
  LeadDiscoverIcon,
  LeadEnrichmentIcon,
  LeadUploadIcon,
} from 'UI/CustomIcon/CustomIcon';
import QuickViewContent from 'containers/discovery/layouts/QuickViewContent';
import { calculatePercentage, formatFiltersForFetchingList, headerGenerator } from 'utils/common';
import { eventList, eventManager } from 'utils/eventManager';
import AddLeads from './addLeads';
import CompanyListItem from './companyListItem';
import LeadListItem from './leadListItem';
import ListFilters from './ListFilters/ListFilters';
import ListInfo from './listInfo';

const SmartListDrawer = React.lazy(() =>
  import(/* webpackChunkName: "SmartListDrawer" */ './smartListDrawer')
);

const { Step } = Steps;

let steps = [];

let fileName = '';
let fileURL = '';

let finalMapping = {
  finalMapping: [],
};

const SMARTLIST = 'Smart List';

const SmartListItem = () => {
  const { id: listId } = useParams();
  const location = useLocation();
  const history = useHistory();
  let isFromJobPosting = false;
  const queryParams = Object.fromEntries(new URLSearchParams(location.search));
  if (queryParams && Object.keys(queryParams).length) {
    if (
      queryParams.jobId &&
      queryParams.jobItemId &&
      (queryParams.from === 'job-posting' || queryParams.from === 'company-list')
    ) {
      steps = [{ id: 1 }, { id: 2 }, { id: 3 }];
      finalMapping.userListJobId = queryParams.jobId;
      finalMapping.userListJobItemId = queryParams.jobItemId;
      isFromJobPosting = true;
    }
  }
  const [listInfo, setListInfo] = useState(location.item || {});
  const pusherChannel = useSelector((state) => state.auth.pusherChannel);
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const creditsRemaining = useSelector((state) => state.common.credits.credits_left, shallowEqual);
  const headers = headerGenerator(user.token, user.session_id);
  const [uploadModal, setUploadModal] = useState(isFromJobPosting);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [addMoreLeads, setAddMoreLeads] = useState(false);
  const { listItems, listItemsLoading, listFilters, filterState } = useSelector(
    (state) => state.smartList
  );
  const [jobsInProgress, setJobsInProgress] = useState([]);
  const [currentStep, setCurrentStep] = useState(isFromJobPosting ? 2 : 0);
  const [uploadProgress, setUploadProgress] = useState(isFromJobPosting ? 100 : 0);
  const [jobProgress, setJobProgress] = useState({
    percent: 0,
    url: '',
    jobType: '',
  });
  const [disable, setDisable] = useState(false);
  const [itemParams, setItemParams] = useState({ page: 1, key: 'created_at', order: 'desc' });
  const [form] = Form.useForm();
  const listInfoRef = useRef();
  const jobProgressRef = useRef();
  const progressRef = useRef();
  // const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();

  const quickView = useSelector((state) => state.smartList.quickView);
  const addToListSource = useSelector((state) => state.smartList.addToListSource);
  const addToListSourceRef = useRef();

  const getFormattedData = useCallback((items = []) => {
    return items.map((item) => {
      return {
        ...item,
        id: { value: item.id?.split('_')[1] },
      };
    });
  }, []);

  const storeData = {
    quickView,
    defaultInnerActiveKey: 'total',
    viewName: listInfo?.listType,
    [listInfo.listType === 'lead' ? 'leads' : 'companies']: {
      data: getFormattedData(listItems.items),
      total_results: listItems.total,
    },
    page: itemParams?.page || 1,
  };

  const updateLeadHandler = useCallback((payload) => {
    dispatch(updateLead(payload));
  }, []);

  const closeQuickViewHandler = useCallback((payload) => {
    dispatch(closeQuickView(payload));
  }, []);

  const openQuickViewHandler = useCallback((payload) => {
    dispatch(openQuickView(payload));
  }, []);

  const openAddToListHandler = useCallback((payload) => {
    dispatch(openAddToList(payload));
  }, []);

  const closeAddToListHandler = useCallback(() => {
    dispatch(closeAddToList());
  }, []);

  const handlePageChange = useCallback(
    (goToPage) => {
      const params = {
        ...itemParams,
        page: goToPage,
      };
      fetchListItems(params);
    },
    [itemParams]
  );

  listInfoRef.current = listInfo;
  jobProgressRef.current = jobsInProgress;
  progressRef.current = jobProgress;
  addToListSourceRef.current = addToListSource;

  useEffect(() => {
    setItemsLoading(listItemsLoading);
  }, [listItemsLoading]);

  useEffect(() => {
    pusherChannel.bind('list-file-upload', updateFileUploadProgress);
    pusherChannel.bind('adding-list-item', updateProgressAndGetAddListItem);
    // pusherChannel.bind('csv-export-status-lead-', (data) => updateJobProgress(data));
    // pusherChannel.bind('export-status-', (data) => updateJobProgress(data));
    pusherChannel.bind(`export-status-${user.userId}`, updateJobProgress);
    return () => {
      pusherChannel.unbind('list-file-upload');
      pusherChannel.unbind('adding-list-item');
      pusherChannel.unbind(`export-status-${user.userId}`);
      dispatch(closeQuickView());
    };
  }, []);

  const getParamsFromFilter = () => {
    return Object.values(listFilters).reduce((listFilter, filter) => {
      const filters = Object.keys(filter).reduce(
        (acc, key) => {
          if (acc[key].length === 0) {
            delete acc[key];
          }
          return { ...acc };
        },
        { ...filter }
      );
      return { ...listFilter, ...filters };
    }, {});
  };

  const prepareFetchParams = () => {
    const params = getParamsFromFilter();
    const { company_sector_industry, company_function_division, source, created_at, ...filters } =
      params;
    const {
      has_phone_number: phone,
      email_availablity: email,
      has_leads: leads,
      technologies,
      keywords,
      company_name,
      lead_divison,
      company_industry,
      company_function,
    } = filters;

    if (phone) {
      const mapping = ['Off', 'All'];
      const [val] = phone.map((value) => mapping.indexOf(value));
      filters.has_phone_number = val;
    }

    if (email) {
      const mapping = ['Off', 'A+', 'A', 'B'];
      filters.email_availablity = email.map((value) => mapping.indexOf(value));
    }

    if (leads) {
      filters.has_leads = leads.every((value) => value === true);
    }

    if (technologies) {
      filters.technologies =
        Array.isArray(technologies) &&
        technologies.map((tech) => {
          const technology = tech.split('|').map((key) => key.split(' ').join('_'));
          technology.shift(); // as first index is categoty and we want to remove that
          const techKey = technology.join('|').toLowerCase();
          return tech.indexOf('!') === 0 ? `!${techKey}` : techKey;
        });
    }

    if (keywords) {
      filters.keywords =
        Array.isArray(keywords) &&
        keywords.map((tech) => {
          const keyword = tech.split('|').map((key) => key.split(' ').join('_'));
          keyword.shift(); // as first index is categoty and we want to remove that
          const techKey = keyword.join('|').toLowerCase();
          return tech.indexOf('!') === 0 ? `!${techKey}` : techKey;
        });
    }

    if (Array.isArray(company_name) && company_name.length) {
      filters.company_name = company_name.map((name) => {
        const companyName = name.split('|').pop();
        return name.indexOf('!') === 0 ? `!${companyName}` : companyName;
      });
    }

    if (Array.isArray(lead_divison) && lead_divison.length) {
      filters.lead_divison = lead_divison.map((name) => {
        const division = name.split('|').pop();
        return division;
      });
    }

    if (Array.isArray(company_industry) && company_industry.length) {
      filters.company_industry = company_industry.map((name) => {
        const industry = name.split('|').pop();
        return industry;
      });
    }

    if (Array.isArray(company_function) && company_function.length) {
      filters.company_function = company_function.map((name) => {
        let companyFunction = name.split('|').pop();
        if (companyFunction === 'HR') {
          companyFunction = 'Hr';
        }
        return companyFunction;
      });
    }

    const otherFilters = {};
    if (source && source.length) {
      otherFilters.source = source;
    }
    // eslint-disable-next-line camelcase
    if (created_at && Array.isArray(created_at) && created_at.length) {
      otherFilters.created_at = created_at[0].split('_');
    }
    return {
      filters,
      otherFilters,
    };
  };

  const onFilterChanged = () => {
    const { filters, otherFilters } = prepareFetchParams();
    const fetchParams =
      filterState === 'reset'
        ? {
            key: 'created_at',
            order: 'desc',
            page: 1,
          }
        : {
            ...itemParams,
            page: 1,
            filters,
            listFilters: otherFilters,
          };
    fetchListItems(fetchParams);
  };

  const updateFileUploadProgress = (data) => {
    if (data.error) {
      showError('Error while uploading file to the server, Please try again.');
    } else {
      const percent = calculatePercentage(data.processed, data.total);
      if (percent === 100) {
        fileURL = data.url;
      }
      if (uploadProgress !== percent || uploadProgress !== 100) {
        setUploadProgress(percent);
      }
    }
  };

  const getLists = async (fetchListParams) => {
    const apiURL = `${baseURL}${endPoints.lists}`;
    const response = await getRequest(apiURL, fetchListParams, headers);
    if (response.error) {
      showError(response.message);
    } else {
      return {
        count: response.count,
        rows: response.rows,
        type: fetchListParams,
      };
    }
    return {};
  };

  const createNewList = async (createListParams) => {
    const apiURL = `${baseURL}${endPoints.lists}`;
    const response = await postRequest(apiURL, createListParams, headers);
    if (response.error) {
      showError(response.message);
    }
  };

  const updateProgressAndGetAddListItem = (data) => {
    const { progress, total } = data;
    const percent = calculatePercentage(progress, total);
    if (progressRef.current.percent !== percent || progressRef.current.percent !== 100) {
      setJobProgress({ percent });
    }
    if (data.status === 'processed') {
      if (addToListSourceRef.current?.listType === 'lead') {
        dispatch(clearAddToListSource());
        return;
      }
      setTimeout(() => {
        fetchListItems({ ...itemParams, page: 1 });
        fetchListInfo(true);
      }, 1000);
    }
  };

  const updateJobProgress = (data) => {
    const { total, status, url, payload, processed } = data;
    if (total && processed) {
      const percent = calculatePercentage(processed, total);
      if (progressRef.current.percent !== percent || progressRef.current.percent !== 100) {
        setJobProgress({ percent, jobType: 'export', url: url || (payload ? payload.url : '') });
      }
    } else if (['inprogress', 'in progress'].includes(status)) {
      setJobProgress({ percent: 50, jobType: 'export' });
    } else if (status === 'success') {
      setJobProgress({ percent: 100, url: url || (payload ? payload.url : ''), jobType: 'export' });
    }
  };

  useEffect(() => {
    fetchListInfo();
    fetchListItems(itemParams);
    fetchJobsInProgress();
  }, [listId]);

  useEffect(() => {
    const formInitialValues = {};
    if (currentStep === 1) {
      if (!formInitialValues.csvMapping) {
        formInitialValues.csvMapping = [{}];
        finalMapping.finalMapping.forEach((field) => {
          if (field.matchedCsvField) {
            formInitialValues.csvMapping[0][field.slintelField] = { value: field.matchedCsvField };
          } else {
            formInitialValues.csvMapping[0][field.slintelField] = { value: undefined };
          }
        });
      }
    }
    if (currentStep === 2) {
      if (!formInitialValues.csvFilters || !formInitialValues.csvFilters.length) {
        formInitialValues.csvFilters = [{}];
      }
    }
    form.setFieldsValue(formInitialValues);
  }, [currentStep]);

  const addLeads = () => {
    setAddMoreLeads(true);
    setUploadModal(true);
  };

  const resetJobsInProgress = (jobs = [], progress = {}) => {
    setJobProgress(progress);
    setJobsInProgress(jobs);
  };

  const onFileUpload = (info) => {
    const { status, name, response } = info.file;
    fileName = name;
    if (status === 'uploading') {
      if (!uploadModal) {
        setUploadModal(true);
      }
      if (addMoreLeads) {
        setAddMoreLeads(false);
      }
    }
    if (status === 'done') {
      finalMapping = response;
      if (finalMapping.jobType === 'enrichment') {
        steps = [
          {
            id: 1,
          },
          {
            id: 2,
          },
          {
            id: 3,
          },
        ];
      } else {
        steps = [
          {
            id: 1,
          },
          {
            id: 2,
          },
          {
            id: 3,
          },
        ];
      }
      // message.success(`${info.file.name} file uploaded successfully.`);
      eventManager.track(eventList.LIST_CSV_UPLOAD, {
        payload: {
          jobType: response.jobType,
          listId,
        },
      });
    } else if (status === 'error') {
      let msg = 'Some error occured while uploading a file';
      if (response.msg) {
        msg = `${name} file upload failed. Reason: ${response.msg} Please try again.`;
      }
      showError(msg);
      resetUploadModal();
    }
  };

  const fetchListInfo = async (force = false) => {
    if (!listInfo || Object.keys(listInfo).length === 0 || force) {
      const apiURL = `${baseURL}${endPoints.lists}/${listId}`;
      const response = await getRequest(apiURL, {}, headers);
      if (response.error) {
        showError(response.message);
        return;
      }
      setListInfo(response);
    }
  };

  const fetchListItems = async (params) => {
    eventManager.track(eventList.LIST_FILTER_APPLIED, {
      object: listInfo.listType,
      payload: {
        ...params,
        listId,
      },
    });
    setItemParams(params);
    const formatedFilters = formatFiltersForFetchingList(getParamsFromFilter(), params.filters);
    dispatch(getListItems(listId, { ...params, filters: formatedFilters }, headers));
  };

  const deleteListItems = async (isBulk, selectedIds = []) => {
    dispatch(closeQuickView());
    const params = {};
    if (isBulk) {
      params.filters = itemParams ? itemParams.filters : {};
    } else {
      params.ids = selectedIds;
    }
    eventManager.track(eventList.LIST_ITEM_DELETE, {
      object: listInfo.listType,
      payload: {
        ...params,
        listId,
      },
    });
    setItemsLoading(true);
    const apiURL = `${baseURL}${endPoints.lists}/${listId}/delete-list-items`;
    const response = await postRequest(apiURL, params, headers);
    setItemParams(params);
    setItemsLoading(false);
    if (response.error) {
      showError(response.message);
      return;
    }
    fetchListInfo(true);
    await fetchListItems({ ...itemParams, page: 1 });
  };

  const createDiscoverLeadsJob = async (isBulk, selectedIds = [], selectedList) => {
    const { filters, otherFilters } = prepareFetchParams();
    const params = {
      jobType: 'companyLeadDiscovery',
      companyListId: listId,
      filters,
      listFilters: otherFilters,
    };
    if (isBulk) {
      params.filter = {};
      if (itemParams.filters) {
        // changes made for JIRA: https://slinteldoc.atlassian.net/browse/APP-5841
        // keeping filter for keeping exisitng code. not sure if it would impact anything else
        params.filter = itemParams.filters;
        if (itemParams.filters.company_sector_industry) {
          delete params.filters.company_industry;
          delete params.filters.company_sector;
          params.filters = {
            ...params.filters,
            company_sector_industry: itemParams.filters.company_sector_industry,
          };
        }
      }
    } else {
      params.ids = selectedIds;
    }
    setItemsLoading(true);
    const apiURL = `${baseURL}${endPoints.lists}/${selectedList.id}/create-job`;
    const response = await postRequest(apiURL, params, headers);
    setItemParams(params);
    setItemsLoading(false);
    if (response.error) {
      showError(response.message);
      return;
    }
    const { jobId, jobItemId } = response;
    history.push(`/list/${selectedList.id}
    ?jobId=${jobId}
    &jobItemId=${jobItemId}
    &from=company-list`);
    window.location.reload();
  };

  const exportListItems = async (
    event,
    isBulk,
    selectedIds = [],
    leadExportType = null,
    exportKeys = {}
  ) => {
    const params = {};
    if (isBulk) {
      params.filters = itemParams ? itemParams.filters : {};
      params.export_count = listItems.total;
    } else {
      params.ids = selectedIds;
      params.export_count = selectedIds.length;
    }
    // Modify parameters on export
    const config = {};
    if (event === 'export-csv') {
      config.timeout = LIST_EXTENDED_TIMEOUT;
      const projection = {
        import_fields: [],
        tech_subcategories: [],
      };
      params.projection = 'projection' in exportKeys ? exportKeys.projection : projection;
      if ('export_type' in exportKeys) {
        params.export_type = exportKeys.export_type;
        params.export_count = exportKeys.export_count;
        params.user_max_records_per_company = exportKeys.user_max_records_per_company;
      }
    }
    // if user doesn't have the required export credits
    if (creditsRemaining < params.export_count) {
      dispatch(toggleCreditLimitModalVisibility(true));
      return;
    }

    if (params.export_count > MAX_ITEM_PER_LIST) {
      showError(`Maxmimum ${MAX_ITEM_PER_LIST} export is allowed.`);
      return;
    }
    if (event === 'export-hb' && params.export_count > HUBSPOT_EXPORT_LIMIT) {
      showError(`Maximum ${HUBSPOT_EXPORT_LIMIT} export is allowed for HubSpot.`);
      return;
    }

    if (event === 'export-sf') {
      params.lead_export_type = leadExportType;
      if (listInfo.listType === 'lead' && params.export_count > SF_EXPORT_LEAD) {
        showError(`Maximum ${SF_EXPORT_LEAD} export is allowed for Salesforce.`);
        return;
      }
      if (listInfo.listType === 'company' && params.export_count > SF_EXPORT_COMPANY) {
        showError(`Maximum ${SF_EXPORT_COMPANY} export is allowed for Salesforce.`);
        return;
      }
      params.salesforce_api_url = user.salesforce_api_url;
    }

    eventManager.track(eventList.LIST_ITEM_EXPORT, {
      object: listInfo.listType,
      payload: {
        ...params,
        event,
        listId,
      },
    });

    setItemsLoading(true);
    const apiURL = `${baseURL}${endPoints.lists}/${listId}/${event}`;
    const response = await postRequest(apiURL, params, headers, null, config);
    setItemsLoading(false);
    if (response.error) {
      showError(response.message);
    } else {
      resetJobsInProgress([response], {});
      if (event === 'export-csv') {
        if (listInfo.listType === 'lead') {
          pusherChannel.bind(`csv-export-status-lead-${response.jobItemId}`, updateJobProgress);
        } else {
          pusherChannel.bind(`export-status-${response.jobItemId}`, updateJobProgress);
        }
      }
    }
  };

  const fetchJobsInProgress = async () => {
    const apiURL = `${baseURL}${endPoints.lists}/${listId}/jobs`;
    const response = await postRequest(
      apiURL,
      {
        status: 'processing',
      },
      headers
    );
    if (response.error) {
      showError(response.message);
    }
    resetJobsInProgress(response, {});
  };

  const renderTitle = () => {
    if (addMoreLeads) {
      return listInfo.listType === 'lead' ? 'Add People' : 'Add Companies';
    }

    return 'MAP CSV COLUMNS TO SLINTEL FIELDS';
  };

  const getByTitle = () => {
    return (
      <div>
        <div style={{ alignSelf: 'center' }}>
          <Text
            style={{
              color: '#29275F',
              fontSize: '16px',
              fontWeight: 'bold',
              textAlign: 'left',
              letterSpacing: '0.8px',
              textTransform: 'uppercase',
            }}
          >
            {renderTitle()}
          </Text>
          {!addMoreLeads && (
            <Tooltip
              placement="topRight"
              title="Map each column header on your CSV file to a field on Slintel using the drop-down menu"
            >
              <InfoIcon style={{ fill: '#929CB7', marginLeft: '10px' }} />
            </Tooltip>
          )}
        </div>
        <div style={{ display: 'flex', marginTop: '20px' }}>
          <div style={{ flex: 1 }}>
            <Steps className="list-field-mapping" current={currentStep}>
              {steps.map((item) => (
                <Step key={item.id} />
              ))}
            </Steps>
          </div>
          <div
            style={{
              width: '60%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              type="default"
              onClick={() => {
                deleteFile(true);
                resetUploadModal();
              }}
            >
              Cancel
            </Button>
            <ButtonUI
              title={currentStep === steps.length - 1 ? 'Done' : 'Next'}
              theme="primary"
              style={{ marginLeft: '15px' }}
              disabled={uploadProgress !== 100}
              loading={disable && currentStep === steps.length - 1}
              onClick={() => {
                if (currentStep === 1) {
                  const error = validateCSVHeaders();
                  if (error) {
                    showError(error);
                    return null;
                  }
                }
                return currentStep === steps.length - 1
                  ? submitForm()
                  : setCurrentStep(currentStep + 1);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const validateCSVHeaders = () => {
    let errorMessage = '';
    const csvMappingList = form.getFieldValue('csvMapping');
    if (csvMappingList) {
      const csvMapping = csvMappingList[0];
      const finalCSVMapping = {};
      for (const key of Object.keys(csvMapping)) {
        if (csvMapping[key].value !== '--') {
          finalCSVMapping[key] = csvMapping[key].value;
        }
      }
      if (finalMapping.jobType === 'enrichment' && listInfo.listType === 'lead') {
        if (
          !finalCSVMapping.linkedin_url &&
          !finalCSVMapping.email &&
          (!finalCSVMapping.first_name ||
            !finalCSVMapping.last_name ||
            (!finalCSVMapping.company_name && !finalCSVMapping.company_domain)) &&
          !finalCSVMapping._id
        ) {
          errorMessage =
            'Please fill in with either the Slintel People ID/LinkedIn URL/email ID/first and last name along with company domain/company name for enrichment';
        }
      }

      if (finalCSVMapping.jobType === 'discovery' && listInfo.listType === 'lead') {
        if (!finalCSVMapping.company_domain && !finalCSVMapping.company_name) {
          errorMessage = 'Please select company name or company domain for people discovery';
        }
      }
      if (finalCSVMapping === 'enrichment' && listInfo.listType === 'company') {
        if (!finalCSVMapping.company_domain && !finalCSVMapping.company_name) {
          errorMessage = 'Please select company name or company domain for company enrichment';
        }
      }
    }
    return errorMessage;
  };

  const onFinish = async (values) => {
    const params = {
      jobId: finalMapping.userListJobId,
      jobItemId: finalMapping.userListJobItemId,
    };
    if (values.csvMapping) {
      params.csvMapping = values.csvMapping[0];
      const finalCSVMapping = {};
      for (const key of Object.keys(params.csvMapping)) {
        if (params.csvMapping[key].value !== '--') {
          finalCSVMapping[key] = params.csvMapping[key].value;
        }
      }
      params.mapping = finalCSVMapping;
    }
    if (values.csvFilters) {
      params.filters = values.csvFilters[0];
    }
    setDisable(true);
    await onSubmit(params);
    setDisable(false);
  };

  const onSubmit = async (params) => {
    const apiURL = `${baseURL}${endPoints.lists}/${listId}/job`;
    const response = await postRequest(apiURL, params, headers);
    if (response.error) {
      showError(response.message);
    } else {
      showSuccess('Looking this up in our database 🔍');
      resetJobsInProgress([response], {});
      resetUploadModal();
    }
  };

  const submitForm = () => {
    form.submit();
  };

  const updateListTitle = async (newTitle) => {
    if (listInfo.title.trim() !== newTitle.trim()) {
      const apiURL = `${baseURL}${endPoints.lists}/${listId}`;
      const response = await postRequest(apiURL, { title: newTitle }, headers);
      if (response.error) {
        showError(response.message);
        return;
      }
      setListInfo(response);
    }
  };

  const resetUploadModal = (drawerClose = true) => {
    if (drawerClose) setUploadModal(false);
    setUploadProgress(0);
    setCurrentStep(0);
    if (drawerClose && addMoreLeads) setAddMoreLeads(false);
    else setAddMoreLeads(true);
    finalMapping = {
      finalMapping: [],
    };
    form.resetFields();
  };

  const deleteFile = async (isCancel = false) => {
    if (finalMapping.userListJobId && finalMapping.userListJobItemId) {
      const apiURL = `${baseURL}${endPoints.listJob}/
      ${finalMapping.userListJobId}
      /item/${finalMapping.userListJobItemId}`;
      const response = await deleteRequest(apiURL, {}, headers);
      if (response.error) {
        showError(response.message);
      } else if (!isCancel) {
        resetUploadModal(false);
      }
    }
  };

  const getViewHistoryURL = (type) => {
    switch (type) {
      case 'csv_export':
        return (
          <RouterLink to="/my-account?view=csv" target="_blank">
            {' '}
            View History{' '}
          </RouterLink>
        );
      case 'salesforce_push':
        return (
          <RouterLink to="/my-account?view=salesforce" target="_blank">
            {' '}
            View History{' '}
          </RouterLink>
        );
      case 'hubspot_push':
        return (
          <RouterLink to="/my-account?view=hubspot" target="_blank">
            {' '}
            View History{' '}
          </RouterLink>
        );
      default:
        return (
          <RouterLink href="/my-account" target="_blank">
            {' '}
            View History{' '}
          </RouterLink>
        );
    }
  };

  const renderAddItems = () => {
    return (
      <Row justify="center" style={{ marginTop: '40px' }}>
        <Space size="middle">
          <Col span="4">
            <AddLeads
              data={{
                listId: listInfo.id,
                jobType: 'enrichment',
              }}
              onFileUpload={onFileUpload}
              style={{
                height: '248px',
              }}
              link={`/discover/${listInfo.listType === 'lead' ? 'lead' : 'company'}`}
              icon={
                listInfo.listType === 'lead' ? (
                  <LeadUploadIcon
                    style={{ background: '#E6F4FF', borderRadius: '50%' }}
                    fill="#29275F"
                  />
                ) : (
                  <AddCompaniesFromSlintelIcon
                    style={{ background: '#E6F4FF', borderRadius: '50%' }}
                    fill="#29275F"
                  />
                )
              }
              title={`Add ${
                listInfo.listType === 'lead' ? 'People' : 'Companies'
              } from Slintel’s database`}
              uploadText={listInfo.listType === 'lead' ? 'Add People' : 'Add Companies'}
              direction="vertical"
            />
          </Col>
          <Col span="4">
            <AddLeads
              onFileUpload={onFileUpload}
              data={{
                listId: listInfo.id,
                jobType: 'enrichment',
              }}
              style={{
                height: '248px',
              }}
              link={
                listInfo.listType === 'lead'
                  ? SAMPLE_LEAD_ENRICHMENT_CSV
                  : SAMPLE_COMPANY_ENRICHMENT_CSV
              }
              icon={
                listInfo.listType === 'lead' ? (
                  <LeadEnrichmentIcon
                    style={{ background: '#E6F4FF', borderRadius: '50%' }}
                    fill="#29275F"
                  />
                ) : (
                  <CompanyEnrichmentIcon
                    style={{ background: '#E6F4FF', borderRadius: '50%' }}
                    fill="#29275F"
                  />
                )
              }
              title={`Enrich ${
                listInfo.listType === 'lead' ? 'People' : 'Company data'
              } by uploading a CSV`}
              direction="vertical"
              type="file"
            />
          </Col>
          {listInfo.listType !== 'company' && (
            <Col span="4">
              <AddLeads
                onFileUpload={onFileUpload}
                data={{
                  listId: listInfo.id,
                  jobType: 'discovery',
                }}
                link={SAMPLE_LEAD_DISCOVERY_CSV}
                style={{
                  height: '248px',
                }}
                title="Discover people by uploading a CSV"
                icon={
                  <LeadDiscoverIcon
                    style={{ background: '#E6F4FF', borderRadius: '50%' }}
                    fill="#29275F"
                  />
                }
                direction="vertical"
                type="file"
              />
            </Col>
          )}
        </Space>
      </Row>
    );
  };

  const renderListItems = () => {
    return listInfo.listType === 'lead' ? (
      <LeadListItem
        listItems={listItems}
        fetchListItems={fetchListItems}
        deleteListItems={deleteListItems}
        exportListItems={exportListItems}
        loading={itemsLoading}
        params={itemParams}
      />
    ) : (
      <CompanyListItem
        listItems={listItems}
        fetchListItems={fetchListItems}
        deleteListItems={deleteListItems}
        exportListItems={exportListItems}
        getLists={getLists}
        createNewList={createNewList}
        createDiscoverLeadsJob={createDiscoverLeadsJob}
        loading={itemsLoading}
        params={itemParams}
        openAddToList={openAddToListHandler}
        closeAddToList={closeAddToListHandler}
      />
    );
  };

  return (
    <>
      <Row className="fill-offset">
        {(listInfo && listInfo.itemCount) || (listItems && listItems.total) ? (
          <Col className="filter-column" span="5">
            <Card className="filter-content">
              <ListFilters onFilterChanged={onFilterChanged} listInfo={listInfo} />
            </Card>
          </Col>
        ) : null}
        <Col
          span={(listInfo && listInfo.itemCount) || (listItems && listItems.total) ? '19' : '24'}
        >
          <div className="smart-list-item">
            {listInfo && listInfo.listType && (
              <ListInfo listInfo={listInfo} updateListTitle={updateListTitle} addLeads={addLeads} />
            )}
            <div className="card-container list-container list-item-container">
              <Card style={listInfo && !listInfo.itemCount ? { padding: '1rem' } : {}}>
                {itemsLoading || (listInfo && listInfo.itemCount)
                  ? renderListItems()
                  : renderAddItems()}
              </Card>
            </div>
            {uploadModal && (
              <SmartListDrawer
                fileURL={fileURL}
                fileName={fileName}
                getByTitle={getByTitle}
                addMoreLeads={addMoreLeads}
                onFileUpload={onFileUpload}
                listInfo={listInfo}
                resetUploadModal={resetUploadModal}
                currentStep={currentStep}
                uploadProgress={uploadProgress}
                deleteFile={deleteFile}
                form={form}
                onFinish={onFinish}
                finalMapping={finalMapping}
              />
            )}
            {jobsInProgress && jobsInProgress.length ? (
              <div className="progress-card">
                <div className="progress-card__item">
                  <div className="icon">
                    <DownloadIcon style={{ fill: '#929CB7' }} />
                  </div>
                  <div className="head">
                    {`${LIST_JOB_TYPES[jobsInProgress[0].type]} ${
                      jobProgress.percent !== 100 ? 'in progress!' : 'complete!'
                    }`}
                    {jobProgress.percent === 100 && jobProgress.jobType === 'export' ? (
                      // <Button className="sl-small-btn"> Download CSV </Button>
                      <div className="action-btn">
                        {jobsInProgress[0].type === 'csv_export' && jobProgress.url ? (
                          <PresignedButton
                            element={<Button className="sl-small-btn"> Download CSV </Button>}
                            url={jobProgress.url}
                          />
                        ) : null}
                        {getViewHistoryURL(jobsInProgress[0].type)}
                      </div>
                    ) : null}
                  </div>
                  <Progress percent={jobProgress.percent} />
                  <div className="close-btn">
                    <CloseOutlined
                      onClick={() => resetJobsInProgress([], {})}
                      style={{ color: 'rgb(0,0,0)', fontSize: '10px' }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
      <QuickViewContent
        isNextPrevVisible
        module={SMARTLIST}
        showAddToList={false}
        storeData={storeData}
        goToNextPage={handlePageChange}
        goToPrevPage={handlePageChange}
        updateLead={updateLeadHandler}
        closeQuickView={closeQuickViewHandler}
        openQuickView={openQuickViewHandler}
        openAddToList={openAddToListHandler}
      />
    </>
  );
};

export default SmartListItem;

import React from 'react';
import { Typography, Image, Button } from 'antd';
import notFoundSVG from '../../assets/svg/notfound.svg';
import './notFound.scss';

const { Title, Text } = Typography;

const NotFound = () => {
  return (
    <div className="not-found">
      <div className="media">
        <Image className="not-foundImg" alt="Slintel" src={notFoundSVG} />
        <div className="media-body">
          <Title level={2}>404 Error</Title>
          <Text className="sub-media">This is not the web page you are looking for.</Text>
          <Button className="link-btn" href="/dashboard">
            Go to home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Select, Spin, Typography } from 'antd';
import Link from 'UI/Link';
import { useTranslation } from 'react-i18next';
import './AddtoListSelection.scss';
import { LeadTabIcon, CompanyIcon } from 'UI/CustomIcon/CustomIcon';
import FilterTagPill from 'UI/FilterTagPill/FilterTagPill';
import { usePrevious } from 'utils/hooks/customHooks';
import { isEqual } from 'utils/common';
import variables from 'src/_vars.scss';

const { Option } = Select;

const AddtoListSelection = ({
  listType,
  placeholder,
  options,
  type,
  value,
  loading,
  onChange,
  onSearch,
  ...props
}) => {
  const { t } = useTranslation(['addtolist']);

  const [selectedValues, setSelected] = useState([]);
  const [isPlaceholder, setIsPlaceholder] = useState(true);
  const [searchText, setSearchText] = useState('');
  const prev = usePrevious(selectedValues);
  const smarListRef = useRef(null);

  useEffect(() => {
    if (!isEqual(prev, selectedValues)) onChange(selectedValues);
  }, [selectedValues]);

  const isSmartList = useMemo(() => {
    return listType === 'smart_list';
  }, [listType]);

  const handleChange = (values) => {
    const val = isSmartList && values.length ? values.pop() : values;
    const selectedVals = Array.isArray(val) ? val : val;
    setSelected(selectedVals);
    setIsPlaceholder(true);
    if (isSmartList) {
      smarListRef.current.blur();
    }
  };

  const handleSearch = (val) => {
    let text = val.trim();
    if (text.length < 201) {
      setSearchText(val);
    } else {
      text = searchText;
    }
    setIsPlaceholder(!text);
    onSearch(text);
  };
  const onDropdownVisibleChange = (open) => {
    if (!open) {
      setSearchText('');
      onSearch('');
    }
  };
  const tagRender = useCallback(
    (tags) => {
      const { label, onClose } = tags;
      return <FilterTagPill showFullText label={label || searchText} onDelete={onClose} />;
    },
    [value]
  );

  const dropdownRender = useMemo(() => {
    return (
      <>
        {options.map((option) => (
          <Option key={option.value} value={option.value} label={option.label}>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <div style={{ padding: '8px 8px 0 0' }}>
                {option.listType?.startsWith('lead') ? (
                  <LeadTabIcon width="16" height="16" />
                ) : (
                  <CompanyIcon width="16" height="16" />
                )}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography.Text style={{ color: '#697390', fontSize: 16 }}>
                  {option.label}
                </Typography.Text>
                <small style={{ textTransform: 'capitalize' }}>{option.listType}</small>
              </div>
            </div>
          </Option>
        ))}
        {searchText && isSmartList && (
          <Option key={searchText} value={searchText} label={searchText}>
            <Link
              onClick={() => {
                setSelected(searchText);
                smarListRef.current.blur();
              }}
              text={`Create a new List '${searchText}'`}
            />
          </Option>
        )}
      </>
    );
  }, [options]);

  const getNotFoundContent = () => {
    if (isSmartList) {
      return loading ? <Spin size="small" /> : null;
    }
    return loading ? (
      <Spin size="small" />
    ) : (
      <Link
        style={{ color: variables.slintelDarkBlue, cursor: 'default' }}
        text={t('validation.static.EXCLUSION_LIST_EMPTY')}
      />
    );
  };

  return (
    <div className={isSmartList ? 'slintel-smart-list-box' : 'slintel-combobox'}>
      {!isSmartList && isPlaceholder && <span className="custom-placeholder">{placeholder}</span>}
      <Select
        ref={smarListRef}
        {...props}
        autoClearSearchValue
        placeholder={isSmartList ? placeholder : ''}
        value={selectedValues}
        mode="multiple"
        labelInValue // allows to use the content as label value pair
        onChange={handleChange}
        style={{ width: '100%' }}
        optionLabelProp="label" // used to bypass the tagrender formatting
        onSearch={handleSearch}
        tagRender={tagRender}
        notFoundContent={getNotFoundContent()}
        optionFilterProp="label"
        searchValue={searchText}
        onDropdownVisibleChange={onDropdownVisibleChange}
      >
        {dropdownRender}
      </Select>
    </div>
  );
};

export default AddtoListSelection;

export const endPoints = {
  /* common */
  creditInfo: 'credits',
  verifyToken: 'verifyToken',
  /* news */
  newsFeed: 'news-feed/global',
  shareNews: 'news-feed/share',
  globalSearch: 'search/autosuggestions',
  recentPages: 'search/history',
  savedFilters: 'custom-filters',
  updateFilters: 'custom-filter',
  shareFilter: 'share-filters-on-email',
  lists: 'list',
  emailSuggest: 'get-slintel-user-email-suggester',
  companyNewsFeed: 'news-feed/company',
  /* integrations */
  integrationsHistory: 'history',
  pipedriveConnect: 'pipedrive-config',
  pipedriveDisconnect: 'delete-pipedrive-settings',
  pipedriveSettings: 'get-user-pipedrive-auth-creds',
  slackConnectionStatus: 'slack-integration-status',
  technologySubCategories: 'technology/subcategories-list',
  technologyAutoSuggestions: 'technology/autosuggestions',
  keywordsAutoSuggestions: 'keyword/autosuggestions',
  technologiesAutoSuggestion: 'technology/autosuggestions',
  keywordsAutoSuggestion: 'keyword/autosuggestions',
  techCatSubcatList: 'technology/catSubcatList',
  pipedriveUpdate: 'pipedrive-fields-update',
  deletePipedriveField: 'pipedrive-custon-field/',
  pipedriveFields: 'get-pipedrive-fields',
  // hubspotSettings: 'get-user-integration-auth-creds',
  salesforceConnect: 'salesforce-config',
  salesforceProperties: 'salesforce-mapping-fields',
  salesforceMappings: 'get-mapper-fields',
  salesforceSettingsInfo: 'get-user-salesforce-auth-creds',
  salesforceConfig: 'salesforce-creds-v2',
  salesforceDisconnect: 'salesforce-creds',
  salesforceMappingsUpdate: 'sf-fields-update',
  salesforceCustomField: 'salesforce-custom-field',
  salesforceEnrich: 'enrichSalesforce',
  salesforceRecordsCount: 'record-credits',
  salesforceSettings: 'salesforce-creds-v2?userId=',
  hubspotHistory: 'get-user-hb-export-history',
  salesforceHistory: 'get-user-sf-export-history',
  csvHistory: 'get-user-csv',
  resetPassword: 'password/reset',
  listFileUpload: 'list/file-upload',
  listJob: 'list-job',
  // List filter suggessions
  locationSuggester: 'leads/location-suggester',
  companySuggester: 'leads/company-suggester',
  companyAutosuggestion: 'companies/autosuggestions',
  companyLocationSuggester: 'companies/location-suggester',
  leadsCompanyLocationSuggester: 'leads/company-location-suggester',
  listLocationSuggester: 'list/location-suggester',
  listCompanySuggester: 'list/company-suggester',
  hubspotConfig: 'get-user-integration-auth-creds',
  getHubspotMappings: 'get-hubspot-fields-mapper',
  hubspotEnrichConnect: 'hubspot-enrichment',
  hubspotEnrichDisconnect: 'delete-hubspot-enrich-settings',
  getHubspotEnrichmentCreds: 'get-hubspot-enrichment-creds',
  updateHubspotConfig: 'update-hubspot-config',
  getHubspotProperties: 'get-hubspot-fields',
  disconnectHubspot: 'delete-hubspot-settings',
  connectHubspot: 'hubspot-config',
  updateHubspotMappings: 'hubspot-fields-update',
  deleteHubspotField: 'hubspot-field/',
  enrichHubspotProperties: 'get-hubspot-enrich-fields',
  notifications: 'notifications',
  notifications_read: 'notification_read',
  notificationSettings: 'notification-settings',
  slackURLIntegration: 'slack-url-integration',
  profileRemovedList: 'profileRemovedList',
  /* credit upgrade */
  userUpgrade: 'user-upgrade-request',
  userInfoRedis: 'get-user-info-from-redis',
  generateFileUrl: 'generate-file-url',
  createExclusionListJob: 'create-exclusion-job',
  /* Outreach */
  outreachSettings: 'outreach',
  outreachConfig: 'outreach/config',
  outreachProperties: 'outreach/properties',
  outreachMappings: 'outreach/mappings',
  outreachHistory: 'outreach/history',
  plan: 'plan/permission',
  /* singup */
  userCreate: 'account/register-forever-free',
  csrftoken: 'form-token',
  sendVerificationLink: 'account/send-verification-link',
  userLogin: 'account/login',
  userLogout: 'account/logout',
  userIpAddress: 'get/ip/address',
  /* Discovery */
  INSTA_REVEAL: 'request-phone-number',
  INSTA_REVEAL_EMAIL: 'request-email',
  FILTER_STATIC_LIST: 'static-filters',
  SEARCH_LEADS: 'v1/leads/search',
  SEARCH_COMPANIES: 'v1/companies/search',
  LEADS_SUGGESTER: 'v1/leads/suggest',
  COMPANIES_SUGGESTER: 'v1/companies/suggest',
  LEAD_IDS: 'v1/leads/ids',
  COMPANY_IDS: 'v1/companies/ids',
  UNIQUE_COMPANY_COUNT: 'v1/leads/companies/count',
  COMPANY_COUNT: 'v1/companies/count',
  LEAD_COUNT: 'v1/leads/count',
  COMPANY_RECOMMENDATION: 'recommendation',
  COMPANY_TWEETS: 'company-tweets',
  TECH_MOVEMENTS: 'techmovements',

  /* integrations export */
  EXPORT_ADDTOLIST_SCHEDULER: 'export-job-scheduler',
  SALESFORCE_LEAD_EXPORT: 'v2/leads/sf-bulk-export',
  PIPEDRIVE_LEAD_EXPORT: 'v2/leads/pd-bulk-export',
  HUBSPOT_LEAD_EXPORT: 'leads/hubspot-bulk-export',
  CSV_LEAD_EXPORT: 'v2/leads/csv-bulk-export',
  SALESFORCE_COMPANY_EXPORT: 'v2/companies/sf-bulk-export',
  PIPEDRIVE_COMPANY_EXPORT: 'companies/pipedrive-export',
  HUBSPOT_COMPANY_EXPORT: 'companies/hubspot-export',
  CSV_COMPANY_EXPORT: 'companies/csv-export-v2',
  LEAD_SUGGESTER: 'v1/leads/suggest',
  COMPANY_SUGGESTER: 'v1/company/suggest',
  ADD_TO_LIST: 'addtolist-job-scheduler',
  GET_NOT_AVAILABLE_INSTA_INFO: 'get-not-available-phone-ids',
  GET_COMPANY_FOLLOW: 'company/follow',
  FOLLOW_COMPANY: 'company/follow',
  UNFOLLOW_COMPANY: 'company/follow',
  REPORT_BUG: 'sns/user_data_error_alert',
  resetPasswordAccount: 'account/password',
  forgotPasswordAccount: 'account/password/reset',
  generatePrivateUrl: 'generate-private-url',
  promoCodeAccount: 'account/promo-code',

  /* technology categories */
  technologyAddition: 'technology/:id/adds',
  technologyDeletion: 'technology/:id/drops',
  technologyRenewal: 'technology/contract-renewals',
  technologyCategories: 'technology/categories',
  technologyCategoryList: 'technology/category-list',
  technology: 'technology',
  TECHNOLOGY_OVERVIEW: 'technology-overview-by-id',
  TECHNOLOGY_GRAPH: 'technology/graph',
  TECHNOLOGY_ID_MAPPER: 'technology/technology-id-mapper',
  CUSTOM_WORLD_GEO_JSON: 'https://code.highcharts.com/mapdata/custom/world.geo.json',
  TECHNOLOGY_LOCATION_SUGGESTER: 'technology/location-suggester',
  follow: 'follow/tech-keyword-custom-filters',
  deleteFollow: 'follow/delete-custom-filters',
  technologyCompanyDetails: 'technology/domains',
  keywords: 'keywords',
  keywordCategories: 'keywords/subcategories',
  ADD_TO_LIST_JOBPAGE: 'add-jobpage-items',
  JOB_POSTING: 'job/posting',
};
// export const baseURL = process.enhubspot-custon-fieldv.REACT_APP_ENV === 'production'
//   ? 'http://localhost:8080/api/' : 'http://localhost:8080/api/';
export const baseURL = process.env.BASE_API_URL;

export const searchClientID = 'slintel.dashboard';

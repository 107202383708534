import React from 'react';
import HighchartsReact from 'highcharts-react-official';

const TreeMap = ({ options, highcharts }) => {
  const { data, title } = options;
  const treeMapOptions = {
    credits: {
      enabled: false,
    },
    colorAxis: {
      minColor: '#f2f8fd',
      maxColor: highcharts.getOptions().colors[0],
    },
    series: [
      {
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        data,
        tooltip: {
          valueSuffix: ' Companies',
        },
      },
    ],
    title: {
      text: title,
      style: {
        fontSize: '30px',
      },
    },
    legend: {
      enabled: false,
    },
  };
  return data.length ? <HighchartsReact highcharts={highcharts} options={treeMapOptions} /> : null;
};
export default TreeMap;

import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { objectFilter } from 'utils/common';
import { tagsForCompanyFilter, tagsForPreferences } from './filter-types';

import '../sass/filter-pills.scss';

const IGNORE_PILLS = ['Company ID'];

function getPillsDataBasedOnFilterType(filter, filterCategory) {
  if (filterCategory === 'preferences') {
    return tagsForPreferences(filter);
  }
  if (filterCategory === 'filter' && filter.type?.toLowerCase() === 'company') {
    return tagsForCompanyFilter(filter);
  }
  return [];
}

const FilterPills = (props) => {
  const { filter, controlClassNames, filterCategory, isFilterExpand } = props;

  const pillsData = getPillsDataBasedOnFilterType(filter, filterCategory);

  const ignorePills = (key, pills) => {
    return !IGNORE_PILLS.includes(key) && pills[key].length > 0;
  };

  const renderPills = () => {
    const filteredPills = objectFilter(
      pillsData,
      ([key, val]) => !IGNORE_PILLS.includes(key) && val.length > 0
    );

    const size = Object.keys(filteredPills).length;
    const viewPills = Object.keys(filteredPills).map((key, index) => {
      return (
        <>
          <div
            className={`filter-pill__container ${ignorePills(key, filteredPills) ? '' : 'hide'}`}
          >
            <span className="filter-pill__label">{`${key} `}</span>
            {filteredPills[key].map((v, i) => {
              return (
                <Tag key={v} className="filter-pill__tag">
                  <span key={v}>{v}</span>
                </Tag>
              );
            })}
          </div>
          {size !== index + 1 && (
            <span className={`sl-divider ${ignorePills(key, filteredPills) ? '' : 'hide'}`} />
          )}
        </>
      );
    });
    return viewPills;
  };

  return (
    <div className="filter-pills-container">
      <div className={`filter-pills ${!isFilterExpand && 'collapsed-block'} ${controlClassNames}`}>
        {renderPills()}
      </div>
    </div>
  );
};

FilterPills.propTypes = {
  filter: PropTypes.shape({
    id: PropTypes.number,
    subCategories: PropTypes.arrayOf(PropTypes.shape({})),
    sectors: PropTypes.arrayOf(PropTypes.string),
    locations: PropTypes.arrayOf(PropTypes.string),
    sizes: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    technologies: PropTypes.arrayOf(PropTypes.shape({})),
    type: PropTypes.string,
  }),
  controlClassNames: PropTypes.string,
  filterCategory: PropTypes.string.isRequired,
  // filterFieldsToShow: PropTypes.shape({}),
};

FilterPills.defaultProps = {
  filter: {},
  controlClassNames: '',
  // filterFieldsToShow: null,
};

export default FilterPills;

import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import smartListReducers from 'containers/smartList/smartListReducers';
import ProfileRemovedListReducers from 'containers/profileRemovedList/profileRemovedListReducers';
import createSagaMiddleware from 'redux-saga';
import outreachReducers from 'containers/integrations/outreach/outreachReducers';
import { SYNC_STORE } from 'store/actionTypes';
import rootSaga from './rootSaga';
import newsReducers from '../containers/news/newsReducers';
import authRecuders from '../containers/auth/authRecuders';
import commonReducers from '../containers/common/commonReducer';
import discoveryReducer from '../containers/discovery/discoveryReducers';
import registerReducers from '../containers/register/registerReducers';
// import signupReducers from './containers/register/signup/signupReducers';

const reducer = combineReducers({
  news: newsReducers,
  auth: authRecuders,
  common: commonReducers,
  smartList: smartListReducers,
  outreach: outreachReducers,
  profileRemovedList: ProfileRemovedListReducers,
  discovery: discoveryReducer,
  // userSignup: signupReducers,
  userRegister: registerReducers,
});

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const sagaMiddleware = createSagaMiddleware();

const affectingActions = {};

const triggerStoreUpdate = (store) => (next) => (action) => {
  next(action);
  if (affectingActions[action.type]) {
    localStorage.setItem('updatedStore', JSON.stringify(store.getState()));
  }
};

const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware, triggerStoreUpdate));

const store = createStore(reducer, enhancer);

window.addEventListener('focus', () => {
  if (window.isDirty) {
    try {
      const strStore = localStorage.getItem('updatedStore');
      const newStore = JSON.parse(strStore);
      store.dispatch({ type: SYNC_STORE, data: newStore });
    } catch (err) {
      window.location.reload();
    }
  }
});

sagaMiddleware.run(rootSaga);

export default store;

import Mixpanel from 'third-party/MixpanelConfig';
import {
  CAPTURE_EVENT_NAME_CONSTANT,
  MIXPANEL_EVENTS,
  EVENT_ACTIONS_CONSTANTS,
} from 'constants/constants';

const MIXPANEL_EVENTS_LIST = Object.values(MIXPANEL_EVENTS);

const eventManager = {
  track: (eventName, payload) => {
    if (MIXPANEL_EVENTS_LIST.includes(eventName)) {
      Mixpanel.track(eventName, payload);
    }
  },
  customEvent: () => {},
};

const eventList = {
  ...CAPTURE_EVENT_NAME_CONSTANT,
  ...MIXPANEL_EVENTS,
};

const eventActions = EVENT_ACTIONS_CONSTANTS || {};

export { eventManager, eventList, eventActions };

/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Text from 'antd/lib/typography/Text';
import { Tag, Button, Tooltip } from 'antd';
import { LIST_SOURCE_DATA } from 'constants/constants';
import { ExpandIcon } from 'UI/CustomIcon/CustomIcon';

const FilterCount = ({ type, getCount, resetFilter, filters }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(getCount(type));
  }, [filters]);

  return count === 0 ? (
    <></>
  ) : (
    <Tag closable onClose={() => resetFilter(type)}>
      {count}
    </Tag>
  );
};

const FilterSelectionButton = React.memo(({ filters = [], values: options, selectFilter }) => {
  return (
    <div className="filter-selection-btn">
      {options
        // .filter((option) => {
        //   const value = typeof option === 'string' ? option : option.value;
        //   return !filters.includes(value);
        // })
        .map((option) => {
          const label = typeof option === 'string' ? option : option.label;
          const value = typeof option === 'string' ? option : option.value;
          return (
            <div key={value}>
              <Button
                type="link"
                onClick={() => selectFilter(value)}
                icon={<ExpandIcon fill="#697390" width="10" />}
              >
                {label}
              </Button>
            </div>
          );
        })}
    </div>
  );
});

const TOOLTIP_VISIBILITY_LIST = [
  'company_name',
  'technologies',
  'keywords',
  'company_industry',
  'lead_divison',
];

const HIDDEN_FILTER_KEYS = [
  'company_sector',
  'company_industry',
  'lead_divison',
  'company_function',
];

const FilterTag = ({ type, onFilterApply: applyFilter, filters }) => {
  const currentFilter = filters[type] || {};
  const filterList = Object.keys(currentFilter);

  const onFilterApply = (filterType, filterName, values) => {
    applyFilter(filterType, filterName, values);
  };

  const getFilterTagText = (key) => {
    const filterKeyValueMapping = {
      company: {
        company_name: 'Name',
        company_size: 'Size',
        company_location: 'Location',
        company_sector: 'Sector',
        company_industry: 'Industry',
        has_leads: 'Companies with people',
        company_sector_industry: 'Sector and Industry',
        company_sic_code: 'SIC',
        company_naics_code: 'NAICS',
        revenue_range: 'Revenue',
      },
      keywords: {
        keywords: 'Psychographics',
      },
      technologies: {
        technologies: 'Technologies Used',
      },
      lead: {
        lead_titles: 'Job title',
        company_function: 'Function',
        decision_making_power: 'Decision-making power',
        lead_divison: 'Division',
        email_availablity: 'Emails',
        has_phone_number: 'Direct phones',
        company_function_division: 'Function and Division',
        lead_location: 'People Location',
        continent: 'People Continent',
      },
      other: {
        source: 'Source',
        created_at: 'Date Added',
      },
    };

    return filterKeyValueMapping[type][key];
  };

  const formatDisplayText = (key, filter) => {
    let filterText;
    switch (key) {
      case 'company_function_division':
      case 'company_sector_industry':
      case 'company_name':
      case 'keywords':
      case 'technologies':
        filterText = filter.split('|').pop();
        break;
      case 'source':
        filterText = LIST_SOURCE_DATA.find((data) => data.value === supressNot(filter)).label;
        break;
      case 'has_leads':
        filterText = filter ? 'Yes' : 'No';
        break;
      default:
        filterText = filter;
    }

    return supressNot(filterText);
  };

  const supressNot = (filter) => (filter.startsWith('!') ? filter.slice(1) : filter);

  const formatFilterTooltip = (key, filter) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case 'technologies':
      case 'keywords': {
        const [category, subcategory, name] = filter.split('|');
        return (
          <>
            <span className="tooltip-label-text">
              <span>{name}</span>
              <span>{`${supressNot(category)} > ${subcategory}`}</span>
            </span>
          </>
        );
      }
      case 'company': {
        if (key === 'company_industry') {
          const [parent, child] = filter.split('|');
          return (
            <>
              <span className="tooltip-label-text">
                <span>{child}</span>
                <span>{supressNot(parent)}</span>
              </span>
            </>
          );
        }
        if (key === 'company_name') {
          const [industry, website, company] = filter.split('|');
          return (
            <>
              <span className="tooltip-label-text">
                <span>{company}</span>
                <span>{supressNot(industry)}</span>
                <span>{website}</span>
              </span>
            </>
          );
        }
        break;
      }
      case 'lead': {
        const [parent, child] = filter.split('|');
        return (
          <>
            <span className="tooltip-label-text">
              <span>{child}</span>
              <span>{supressNot(parent)}</span>
            </span>
          </>
        );
      }
    }
    return <></>;
  };

  const isTagChildOfList = (list, tag) => list.some((item) => tag.startsWith(item));

  const getVisibility = (key, filter) => {
    let isVisible;
    switch (key) {
      case 'company_function_division':
      case 'company_sector_industry': {
        const list = currentFilter[key].filter((values) => !values.includes('|'));
        isVisible = filter.includes('|') && isTagChildOfList(list, filter);
        break;
      }
      default:
        isVisible = false;
    }
    return isVisible ? 'hide-tag' : '';
  };

  return (
    <>
      {filterList.map((key) =>
        currentFilter[key] && currentFilter[key].length > 0
          ? !HIDDEN_FILTER_KEYS.includes(key) && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  rowGap: '5px',
                }}
              >
                <Text style={{ fontSize: '13px', marginRight: '5px' }}>
                  {getFilterTagText(key)}
                </Text>
                {key === 'created_at' && currentFilter[key] ? (
                  <Tag color="geekblue" onClose={() => onFilterApply(type, key, [])} closable>
                    {currentFilter[key][0]
                      .split('_')
                      .map((val) => new Date(val).toLocaleDateString())
                      .join('-')}
                  </Tag>
                ) : (
                  currentFilter[key] &&
                  currentFilter[key].map((filter) =>
                    TOOLTIP_VISIBILITY_LIST.includes(key) ? (
                      <Tooltip title={formatFilterTooltip(key, filter)} placement="right">
                        <Tag
                          color={filter.startsWith('!') ? 'red' : 'geekblue'}
                          onClose={() => {
                            const values = currentFilter[key].filter((value) => value !== filter);
                            onFilterApply(type, key, values);
                          }}
                          closable
                        >
                          {formatDisplayText(key, filter)}
                        </Tag>
                      </Tooltip>
                    ) : (
                      <Tag
                        className={getVisibility(key, filter)}
                        color={
                          typeof filter === 'string' && filter.startsWith('!') ? 'red' : 'geekblue'
                        }
                        onClose={() => {
                          const values = currentFilter[key].filter((value) => value !== filter);
                          onFilterApply(type, key, values);
                        }}
                        closable
                      >
                        {formatDisplayText(key, filter)}
                      </Tag>
                    )
                  )
                )}
              </div>
            )
          : null
      )}
    </>
  );
};

export { FilterTag, FilterCount, FilterSelectionButton };

import React from 'react';
import { Card } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';

const PageLayout = ({ children }) => {
  return (
    <Layout className="outer-tab-container">
      <Content className="outer-tab-content" style={{ backgroundColor: 'white' }}>
        <Card style={{ margin: '10px', padding: 0 }} bodyStyle={{ padding: '16px' }}>
          {children}{' '}
        </Card>
      </Content>
    </Layout>
  );
};

export default PageLayout;

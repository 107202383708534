import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CustomAlert, CustomWarningAlert } from 'UI/CustomAlert/CustomAlert';
import Drawer from 'UI/CustomDrawer/CustomDrawer';
import DrawerTitle from 'UI/CustomDrawer/DrawerTitle';
import { getLoggedInUser } from 'utils/common';
import { useSelector, useDispatch } from 'react-redux';
import IntegrationService from 'api/services/integrationService';
import { ExportProgress } from 'UI/CustomMessages/CustomMessages';
import { clearCheckedItems } from 'containers/discovery/discoveryActions';
import { eventManager, eventList, eventActions } from 'utils/eventManager';

const ExportPipeDrive = ({
  crm,
  setSelectedOption,
  getSearchParams,
  tab,
  selectedIds = [],
  totalSelected,
  source,
  id,
}) => {
  const { defaultInnerActiveKey } = useSelector((state) => state.discovery);
  const userInfo = getLoggedInUser();
  const { userId, userEmail } = userInfo;
  const { credits } = useSelector((state) => state.common);
  const totalCount = totalSelected;
  const hasCredits = credits && credits.credits_left >= totalCount;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const exportToPipedrive = async () => {
    const params = {
      userId,
      userEmail,
      scheduleAt: new Date().getTime(),
      export_count: totalCount,
      filter: {},
      leadId: id ? [id] : selectedIds,
    };

    const reqData = {
      data: params,
      type: 'EXPORT_PD',
      metadata: getSearchParams(tab),
      export_count: totalCount,
      entity: tab,
    };
    setIsLoading(true);
    await IntegrationService.exportAddtoListScheduler(reqData);
    setIsLoading(false);
    ExportProgress({
      duration: 0,
      msg: 'Pipedrive export in progress!',
      key: `${crm}-${userId}`,
    });
    setSelectedOption('');
    dispatch(clearCheckedItems());
    eventManager.track(eventList.EXPORT_TRIGGERED, {
      payload: {
        tab,
        export_type: eventActions.PIPEDRIVE,
        navigation: defaultInnerActiveKey,
        payload: getSearchParams(tab),
        version: eventActions.NDF,
        export_source: source,
      },
    });
  };

  return (
    <div className="ExportPipeDriveWrapper">
      <Drawer
        content={
          <DrawerContent message={`${totalCount} records selected`} hasCredits={hasCredits} />
        }
        title={
          <DrawerTitle
            title="PUSH TO PIPEDRIVE"
            actions={[
              {
                title: 'Cancel',
                type: 'default',
                theme: 'secondary',
                disabled: isLoading,
                size: 'medium',
                onClick: () => {
                  setSelectedOption('');
                },
              },
              {
                title: 'Confirm',
                type: 'primary',
                theme: 'primary',
                size: 'medium',
                loading: isLoading,
                disabled: !hasCredits || !totalCount,
                onClick: () => {
                  exportToPipedrive();
                },
              },
            ]}
          />
        }
        visible={crm === 'pipedrive'}
        width={600}
      />
    </div>
  );
};
const DrawerContent = (props) => {
  const { hasCredits } = props;
  return (
    <>
      <CustomAlert {...props} />
      {!hasCredits ? (
        <CustomWarningAlert message="Insufficient credits for export. Talk to your Customer Success Manager to get credits" />
      ) : null}
    </>
  );
};

ExportPipeDrive.propTypes = {
  crm: PropTypes.string,
  setSelectedOption: PropTypes.func,
  tab: PropTypes.string.isRequired,
  selectedIds: PropTypes.array,
  getSearchParams: PropTypes.func,
  totalSelected: PropTypes.number,
  source: PropTypes.string,
};

export default React.memo(ExportPipeDrive);

/* eslint-disable import/no-unresolved */
import { baseURL, endPoints } from 'api/apiEndpoints';
import { getRequest } from 'api/apiRequests';
import mixpanel from 'mixpanel-browser';
import { getLoggedInUser, headerGenerator } from 'utils/common';

const userInfo = getLoggedInUser();

let defaultObj = {};

if (userInfo) {
  defaultObj = {
    user_name: userInfo.userName,
    user_email: userInfo.userEmail,
    domain: userInfo.userEmail.split('@')[1],
    user_role: userInfo.userRole,
    user_id: userInfo.userId,
    session_id: userInfo.session_id,
    expires_at: userInfo.expires,
    has_onboarded: userInfo.has_onboarded,
    event_time: new Date().getTime(),
  };
}
mixpanel.init(process.env.MIXPANEL_ACCESS_KEY);
const Mixpanel = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (eventName, payload) => {
    mixpanel.track(eventName, { ...defaultObj, ...payload });
  },
  people: {
    set: async (props) => {
      try {
        const { token, session_id } = userInfo;
        const headers = headerGenerator(token, session_id);
        const ip = await getUserIpAddress(headers);
        mixpanel.people.set({ ...props, ...{ $ip: ip } });
      } catch (ex) {
        console.error(ex);
      }
    },
  },
};
const getUserIpAddress = async (headers) => {
  try {
    const url = `${baseURL}${endPoints.userIpAddress}`;
    const ip = await getRequest(url, {}, headers);
    return ip.remoteAddress ? ip.remoteAddress : '';
  } catch (ex) {
    return '';
  }
};

export default Mixpanel;

import { baseURL, endPoints } from 'api/apiEndpoints';
import { postRequest, getRequest, deleteRequest } from '../apiRequests';

const headers = {};

const IntegrationService = {
  async exportAddtoListScheduler(params) {
    const url = `${baseURL}${endPoints.EXPORT_ADDTOLIST_SCHEDULER}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async salesLeadForceExport(params) {
    const url = `${baseURL}${endPoints.SALESFORCE_LEAD_EXPORT}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async salesForceCompanyExport(params) {
    const url = `${baseURL}${endPoints.SALESFORCE_COMPANY_EXPORT}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async pipedriveLeadExport(params) {
    const url = `${baseURL}${endPoints.PIPEDRIVE_LEAD_EXPORT}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async pipedriveCompanyExport(params) {
    const url = `${baseURL}${endPoints.PIPEDRIVE_COMPANY_EXPORT}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async hubspotLeadExport(params) {
    const url = `${baseURL}${endPoints.HUBSPOT_LEAD_EXPORT}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async hubspotCompanyExport(params) {
    const url = `${baseURL}${endPoints.HUBSPOT_COMPANY_EXPORT}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async csvLeadExport(params) {
    const url = `${baseURL}${endPoints.CSV_LEAD_EXPORT}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async csvCompanyExport(params) {
    const url = `${baseURL}${endPoints.CSV_COMPANY_EXPORT}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async getPipedriveSettings(params) {
    const url = `${baseURL}${endPoints.pipedriveSettings}`;
    const response = await getRequest(url, params, headers);
    return response;
  },
  async getIntegrationsHistory(url, params) {
    const response = await getRequest(url, params, headers);
    return response;
  },
  async getSalesforceEnrichDetails(params) {
    const url = `${baseURL}${endPoints.salesforceEnrich}`;
    const response = await getRequest(url, params, headers);
    return response;
  },
  async getSfEnrichmentRecords(params) {
    const url = `${baseURL}${endPoints.salesforceRecordsCount}`;
    const response = await getRequest(url, params, headers);
    return response;
  },
  async getSalesforceProperties(params) {
    const url = `${baseURL}${endPoints.salesforceProperties}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async getSfMappings(params) {
    const url = `${baseURL}${endPoints.salesforceMappings}`;
    const response = await getRequest(url, params, headers);
    return response;
  },
  async connectSalesforce(params) {
    const url = `${baseURL}${endPoints.salesforceConnect}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async disconnectSalesforce(params) {
    const url = `${baseURL}${endPoints.salesforceDisconnect}`;
    const response = await deleteRequest(url, params, headers);
    return response;
  },
  async updateSfMappings(params) {
    const url = `${baseURL}${endPoints.salesforceMappingsUpdate}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async updateSfConfig(params) {
    const url = `${baseURL}${endPoints.salesforceConfig}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async getHubspotProperties(params) {
    const url = `${baseURL}${endPoints.getHubspotProperties}`;
    const response = await getRequest(url, params, headers);
    return response;
  },
  async updateHubspotConfig(params) {
    const url = `${baseURL}${endPoints.updateHubspotConfig}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async updateHubspotMappings(params) {
    const url = `${baseURL}${endPoints.updateHubspotMappings}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async getHubspotMappings(params) {
    const url = `${baseURL}${endPoints.getHubspotMappings}`;
    const response = await getRequest(url, params, headers);
    return response;
  },
  async connectHubspot(params) {
    const url = `${baseURL}${endPoints.connectHubspot}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async disconnectHubspot(params) {
    const url = `${baseURL}${endPoints.disconnectHubspot}`;
    const response = await deleteRequest(url, params, headers);
    return response;
  },
  async deleteHubspotField(params) {
    const url = `${baseURL}${endPoints.deleteHubspotField}${params.id}`;
    const response = await deleteRequest(url, {}, headers);
    return response;
  },
  async deleteSfField(params) {
    const url = `${baseURL}${endPoints.salesforceCustomField}/${params.id}`;
    const response = await deleteRequest(url, {}, headers);
    return response;
  },
};

export default IntegrationService;

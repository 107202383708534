import React from 'react';
import { Modal } from 'antd';
import { VISUAL_WALKTHROUGH_SRC } from 'constants/multimedia';

const VisualWalkthrough = ({ open, handleCancel }) => {
  return (
    open && (
      <Modal
        visible
        width="60%"
        title=""
        onCancel={handleCancel}
        footer={null}
        closable={false}
        bodyStyle={{ padding: '15px', lineHeight: '0px' }}
      >
        <iframe
          title="VisualWalkthrough"
          width="100%"
          height="420px"
          src={VISUAL_WALKTHROUGH_SRC}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="true"
        />
      </Modal>
    )
  );
};
export default VisualWalkthrough;

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Form, Alert, InputNumber, Checkbox } from 'antd';

const LimitLeadForm = ({ exportCount, onSubmit }) => {
  const [form] = Form.useForm();

  const [formValues, setFormValues] = useState({
    limit_leads_per_company: false,
  });

  const validateForm = () => {
    const values = form.getFieldsValue();
    const payload = {
      ...values,
      export_type: formValues.limit_leads_per_company,
    };
    onSubmit(payload);
  };

  return (
    <Form
      colon={false}
      form={form}
      onFinish={validateForm}
      onValuesChange={() => form.submit()}
      initialValues={{ export_type: false }}
    >
      <div className="bulk-selections-container">
        <Alert
          message={
            <>
              <Form.Item name="export_type">
                <Checkbox
                  checked={formValues.limit_leads_per_company}
                  value="limit_leads_per_company"
                  onChange={({ target }) => {
                    setFormValues({
                      limit_leads_per_company: target.checked,
                    });
                    if (target.checked) {
                      form.setFieldsValue({ export_count: exportCount });
                    }
                  }}
                />
              </Form.Item>
              <Form.Item name="export_count" label="Export a total of">
                <InputNumber
                  disabled={!formValues.limit_leads_per_company}
                  // max={50000}
                  min={1}
                  // rules={[{
                  //   required: true,
                  //   max: exportCount,
                  //   message: `Total records should be between 1 and ${exportCount}`,
                  // }]}
                />
              </Form.Item>
              <Form.Item name="user_max_records_per_company" label="people* and not more than">
                <InputNumber
                  disabled={!formValues.limit_leads_per_company}
                  min={1}
                  // max={50000}
                />
              </Form.Item>
              <span> {' records*'} </span>
              <Form.Item label="per company." />
              <div>
                People are sorted in decreasing order of decision-making power within a company.
              </div>
            </>
          }
          type="info"
        />
      </div>
    </Form>
  );
};

export default LimitLeadForm;

import React, { useEffect, useState } from 'react';
import { Button } from 'antd';

const PillsGroup = ({ list = [], size = 8 }) => {
  const [limit, setLimit] = useState(0);
  const [isExpand, setIsExpand] = useState(false);

  useEffect(() => {
    setLimit(size);
    if (list.length <= size) {
      setIsExpand(true);
    }
  }, [list]);

  const showAll = () => {
    setLimit(list.length);
    setIsExpand(true);
  };
  return (
    <div className="pill-block">
      {list.slice(0, limit).map((item) => (
        <div key={item} className="pill-cover">
          {item}
        </div>
      ))}
      {!isExpand ? (
        <Button type="link" onClick={showAll}>
          {list.length - size} others
        </Button>
      ) : null}
    </div>
  );
};

export default PillsGroup;

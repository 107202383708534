/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import InfoBlock from 'components/common/InfoBlock';
import { eventManager, eventList, eventActions } from 'utils/eventManager';
import { PsychographicIcon, TechnologyIcon } from 'UI/CustomIcon/CustomIcon';
import { Divider, Space, Typography } from 'antd';
import { TechnologyService } from 'api/services';
import { showSuccess, showError } from 'components/common/errorMessage';
import { DEFAULT_TECHNOLOGY_PATH, DEFAULT_KEYWORD_PATH } from 'constants/constants';
import ProfileActionsContainer from './ProfileActionsContainer';

const { getCustomFiltersTechnology, followTechnologyAndKeyword, deleteFollowFiltersById } =
  TechnologyService;
const subPath = window.location.pathname.split('/')[1];

const getFormattedFollowedData = (item) => {
  if (!item.meta) item.meta = {};
  const followedItem = { ...item.meta };

  followedItem.techId = item.id;

  followedItem.isChecked = true;
  followedItem.company_name_label = item.name;
  followedItem.company_name = item.name;
  followedItem.company_profile_image_url = item.company_profile_image_url;
  return followedItem;
};

const { Text, Link } = Typography;

const getHref = (category = '', subcategory = '', type) => {
  const isTech = type === 'technology';
  const path = isTech ? 'technologies' : 'keywords';
  if (category && subcategory) return `/${path}/${category}/${subcategory}`;
  return isTech ? DEFAULT_TECHNOLOGY_PATH : DEFAULT_KEYWORD_PATH;
};

const Profile = ({ data, type }) => {
  const [isFollowedLoading, setIsFollowedLoading] = useState(false);
  const [followedData, setFollowedData] = useState({});

  useEffect(() => {
    getFollowedTechnologies();
  }, []);

  const getFollowedTechnologies = async () => {
    const params = { filterType: 'technology' };
    setIsFollowedLoading(true);
    try {
      const { data: followData = [] } = await getCustomFiltersTechnology(params);
      const followDetails = followData.find(
        (item) => item.preferenceType === type && item.meta._id === data._id
      );
      if (followDetails) {
        const formattedFollowData = getFormattedFollowedData(followDetails);
        setFollowedData(formattedFollowData);
      }
    } catch (e) {
      // TODO: handle error
    }
    setIsFollowedLoading(false);
  };

  const handleFollow = useCallback(
    (recordData) => async () => {
      try {
        const { _id, title, subcategory, company_name_label: companyNameLabel } = recordData;
        const payload = { _id, title, type, company_name_label: companyNameLabel };
        if (type === 'technology') {
          payload.subcategory = subcategory;
        } else {
          payload.company_name_label = companyNameLabel;
        }
        const res = await followTechnologyAndKeyword(payload);
        if (res.data?.id) {
          setFollowedData((prevFollowedData) => ({
            ...prevFollowedData,
            isChecked: true,
            techId: res.data.id,
          }));
          showSuccess(`You are now following ${recordData.title}`);
        } else {
          showError(`Error in following ${recordData.title}`);
        }
      } catch (e) {
        showError(`Error in following ${recordData.title}`);
      }
    },
    [followedData]
  );

  const handleUnFollow = useCallback(
    (recordData) => async () => {
      try {
        // we need to pass id coming from db when followed
        const payload = { id: followedData.techId, type };
        await deleteFollowFiltersById(payload);
        recordData.isChecked = false;
        setFollowedData((prevFollowedData) => ({
          ...prevFollowedData,
          isChecked: false,
          techId: null,
        }));

        showSuccess(`You have unfollowed ${recordData.title}`);
      } catch (e) {
        showError(`Error in unfollowing ${recordData.title}`);
      }
    },
    [followedData]
  );
  const renderContent = (title, value) => {
    return (
      <InfoBlock
        align="center"
        color="dark-blue"
        size="medium"
        children={
          <Space direction="vertical" align="center">
            <Text className="content-key-medium">{title}</Text>
            <Text className="content-value-red">{value}</Text>
          </Space>
        }
      />
    );
  };
  return (
    <div className="gray-box p-3 border-0">
      <div className="display-flex gap-5 position-relative">
        <Space size={8} align="start" className=" ">
          <InfoBlock
            thumbnail={
              data?.image_url
                ? undefined
                : type === 'technology'
                ? TechnologyIcon
                : PsychographicIcon
            }
            fallbackIcon={type === 'technology' ? TechnologyIcon : PsychographicIcon}
            imageUrl={data?.image_url}
            size="big"
            color="dark-blue"
            imagesize="big"
          />
          <Space size={4} direction="vertical" className="space-full-width">
            <div className="d-flex justify-content-between align-items-center">
              <InfoBlock value={<div>{data?.title} </div>} size="big" color="dark-blue" />
              <div className="action-container">
                <ProfileActionsContainer
                  isLoading={isFollowedLoading}
                  record={followedData}
                  handleFollow={handleFollow(data)}
                  handleUnfollow={handleUnFollow(data)}
                />
              </div>
            </div>
            <InfoBlock
              value={
                <div className="gap-3 content-key">
                  <div style={{ width: '600px' }}>{data?.description} </div>
                </div>
              }
              size="small"
              color="dark-blue"
            />
            <Space size={8} split={<Divider type="vertical" />}>
              <InfoBlock
                align="center"
                color="dark-blue"
                size="medium"
                keyName="Category"
                children={<Text strong>{data?.category}</Text>}
              />
              <InfoBlock
                align="center"
                color="dark-blue"
                size="medium"
                keyName="Sub Category"
                children={
                  <RouterLink to={getHref(data?.category, data?.subcategory, type)}>
                    <Text strong>{data?.subcategory}</Text>
                  </RouterLink>
                }
              />
            </Space>
          </Space>
        </Space>
        <Space
          className="mx-auto"
          size={8}
          split={<Divider type="vertical" className="lc-detail-divider " />}
        >
          {renderContent('Total Companies', data?.domains?.toLocaleString('en-US'))}
          {type === 'technology' && renderContent('Additions', data?.adds_this_month)}
          {type === 'technology' && renderContent('Deletions', data?.deleted_this_month)}
        </Space>
      </div>
    </div>
  );
};
export default Profile;

const sendEvent = (action, companyId) => {
  eventManager.track(eventList.DETAIL_VIEW_ACTION, {
    payload: {
      // tab: subPath,
      type: subPath,
      action,
      version: eventActions.NDF,
      company_id: companyId,
    },
  });
};

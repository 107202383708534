import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import './Link.scss';

const Link = ({ text, type, onClick, ...props }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    onClick(e);
  };

  return (
    <div className={`slintel-link-${type}`}>
      <Button onClick={handleClick} type="link" {...props}>
        {text}
      </Button>
    </div>
  );
};

Link.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  text: '',
  type: '',
  onClick: () => {},
};

export default Link;

import Highcharts from 'highcharts/highstock';

require('highcharts/indicators/indicators')(Highcharts);
require('highcharts/indicators/pivot-points')(Highcharts);
require('highcharts/indicators/macd')(Highcharts);
require('highcharts/modules/map')(Highcharts);
require('highcharts/modules/treemap')(Highcharts);
require('highcharts/modules/coloraxis')(Highcharts);

export default Highcharts;

/* eslint-disable no-async-promise-executor */
import { baseURL, endPoints } from 'api/apiEndpoints';
import { uplodFile, postRequest, deleteRequest, getRequest } from '../apiRequests';

// TODO: ADD any common functionality used across the app.
const CommonService = {
  async uplodFile(params) {
    const response = await uplodFile(params);
    return response;
  },
  async followCompany(params, headers) {
    const url = `${baseURL}${endPoints.FOLLOW_COMPANY}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async unFollowCompany(id, headers) {
    const url = `${baseURL}${endPoints.UNFOLLOW_COMPANY}/${id}`;
    const response = await deleteRequest(url, {}, headers);
    return response;
  },

  async requestPhoneNumber(params, headers) {
    const url = baseURL + endPoints.INSTA_REVEAL;
    const response = await postRequest(url, params, headers);
    return response;
  },
  // requestPhoneNumber(params, headers) {
  //   // const response = await postRequest(url, params);
  //   return new Promise(async (resolve, reject) => {
  //     console.log(baseURL, endPoints.INSTA_REVEAL);
  //     const url = baseURL + endPoints.INSTA_REVEAL;

  //     const response1 = await postRequest(url, params, headers);
  //     console.log(response1);
  //     setTimeout(() => {
  //       const response = [
  //         { phone: '+1913-749-6755', type: 'mobile' },
  //         { phone: '+1806-881-9005', type: 'mobile' },
  //         { phone: '+1316-617-0015', type: 'mobile' },
  //         { phone: '+1816-674-8827', type: 'mobile' },
  //         { phone: '+1816-442-7255' },
  //         { phone: '+1316-260-9657' },
  //         { phone: '+1620-277-2615', type: 'home_phone' },
  //         { phone: '+1913-384-1008' },
  //         { phone: '+1620-231-9686' },
  //       ];
  //       resolve(response);
  //     }, 1000);
  //   });
  // },
  async requestEmail(params, headers) {
    const url = baseURL + endPoints.INSTA_REVEAL_EMAIL;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async getNotifications() {
    // Add Notifiaction API call here
  },
  async getExportStatus() {
    // Add Export status  API call here
  },
  async getNotAvailableInstaInfo(ids, headers) {
    const url = baseURL + endPoints.GET_NOT_AVAILABLE_INSTA_INFO;
    const response = await postRequest(url, ids, headers);
    return response;
  },
  async getCompanyFollow(id, headers) {
    const url = `${baseURL}${endPoints.GET_COMPANY_FOLLOW}/${id}`;
    const response = await getRequest(url, {}, headers);
    return response;
  },
  async reportBug(params, headers) {
    const url = `${baseURL}${endPoints.REPORT_BUG}`;
    const response = await postRequest(url, params, headers);
    return response;
  },
  async validateUserToken(headers) {
    const url = `${baseURL}${endPoints.verifyToken}`;
    const response = await postRequest(url, {}, headers);
    return response;
  },
};

export default CommonService;

import React from 'react';
import PropTypes from 'prop-types';
import { Button as AntButton } from 'antd';
import './Button.scss';

const Button = ({ title, icon, theme, onClick, ...props }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    onClick(e);
  };

  return (
    <div
      style={icon ? { minWidth: '20' } : {}}
      className={`slintel-button slintel-button__${theme}`}
    >
      {icon ? (
        <AntButton icon={icon} {...props} onClick={handleClick} />
      ) : (
        <AntButton onClick={handleClick} {...props}>
          {title}
        </AntButton>
      )}
    </div>
  );
};

Button.propTypes = {
  theme: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.element,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  theme: 'primary',
  icon: null,
  title: 'Button',
  onClick: () => {},
};

export default Button;

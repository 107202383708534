import React, { useState } from 'react';
import { Button, Card, Input } from 'antd';
import * as PropTypes from 'prop-types';
import Title from 'antd/lib/typography/Title';
import { EditIcon, CompanyIcon, EmployeeIcon } from 'UI/CustomIcon/CustomIcon';
// import Text from 'antd/lib/typography/Text';
// import { formatDateFromString } from 'utils/common';
import { PlusOutlined } from '@ant-design/icons';
import { MAX_ITEM_PER_LIST } from 'constants/constants';
import Thumbnail from 'UI/Thumbnail/Thumbnail';

const ListInfo = ({ listInfo, updateListTitle, addLeads }) => {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const { title = '', listType } = listInfo;

  const [editText, setEditText] = useState('');

  const handleEditToggle = () => {
    setEdit(true);
    setEditText(title);
  };

  const updateTitle = async () => {
    setLoading(true);
    await updateListTitle(editText);
    setLoading(false);
    setEdit(false);
  };

  return (
    <Card
      className="listInfo"
      bodyStyle={{
        padding: '15px 20px 15px 20px',
      }}
      style={{
        backgroundColor: '#F8FAFF',
      }}
    >
      <div className="item-title-container">
        {!edit ? (
          <div className="list-item-title-container">
            {listType === 'lead' ? (
              <Thumbnail.Icon size="big" backgroundColor="#38C5AF" Icon={EmployeeIcon} />
            ) : (
              <Thumbnail.Icon size="big" backgroundColor="#C15286" Icon={CompanyIcon} />
            )}
            <div className="list-item-title-row">
              <Title level={4} className="list-item-title">
                {title}
                <EditIcon fill="#929CB7" onClick={handleEditToggle} style={{ cursor: 'pointer' }} />
              </Title>
              <div style={{ color: '#929CB7', fontSize: '14px' }}>{`${listInfo.itemCount} ${
                listType === 'lead' ? 'People' : 'Total Companies'
              }`}</div>
            </div>
          </div>
        ) : (
          <Input
            value={editText}
            onChange={(e) => setEditText(e.target.value)}
            style={{
              width: '420px',
              height: '40px',
              color: '#29275F',
              fontSize: '14px',
              marginBottom: '0.5em',
            }}
            maxLength={50}
            suffix={
              <>
                <Button
                  style={{
                    background: '#E6F4FF',
                    color: '#5E81F4',
                    boxShadow: 'none',
                    border: 'none',
                  }}
                  loading={loading}
                  type="primary"
                  onClick={updateTitle}
                >
                  Save
                </Button>
                <Button
                  style={{
                    marginLeft: 10,
                  }}
                  loading={loading}
                  type="default"
                  onClick={() => setEdit(false)}
                >
                  Cancel
                </Button>
              </>
            }
            disabled={loading}
          />
        )}
        <Button
          disabled={MAX_ITEM_PER_LIST - listInfo.itemCount <= 0}
          type="primary"
          onClick={addLeads}
        >
          <PlusOutlined />
          {listType === 'lead' ? 'People' : 'Companies'}
        </Button>
      </div>
      {/* <Row gutter={[24, 24]} style={{ marginBottom: '0' }}>
        <Col>
          <Space size="middle">
            <Text className="capitalize" type="secondary">Created By</Text>
            <Text className="capitalize medium-strong" type="default">{createdBy}</Text>
          </Space>
        </Col>
        <Col>
          <Space size="middle">
            <Text className="capitalize" type="secondary">Created On</Text>
            <Text className="capitalize medium-strong" type="default">
              {formatDateFromString(createdAt)}
            </Text>
          </Space>
        </Col>
        <Col>
          <Space size="middle">
            <Text className="capitalize" type="secondary">Updated on</Text>
            <Text className="capitalize medium-strong" type="default">
              {formatDateFromString(updatedAt)}
            </Text>
          </Space>
        </Col>
      </Row> */}
    </Card>
  );
};

ListInfo.propTypes = {
  listInfo: PropTypes.object.isRequired,
  updateListTitle: PropTypes.func,
  addLeads: PropTypes.func,
};

export default ListInfo;

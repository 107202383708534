/* eslint-disable no-console */
import React, { useState } from 'react';
import { Button, Spin, Tooltip } from 'antd';
import { NotificationBellIcon, UnfollowIcon } from 'UI/CustomIcon/CustomIcon';

// TODO: add translation for follow/unfollow
const FollowButton = ({ data, handleFollow, handleUnfollow }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleUnfollowClick = async () => {
    setIsLoading(true);
    await handleUnfollow(data);
    setIsLoading(false);
  };

  const handleFollowClick = async () => {
    setIsLoading(true);
    await handleFollow(data);
    setIsLoading(false);
  };

  const getIcon = (recordData) => {
    if (isLoading) {
      return <Spin size="small" />;
    }
    // TODO: disable in main table?
    // click issue on button vs icon
    if (recordData.isChecked) {
      return <UnfollowIcon onClick={handleUnfollowClick} fill="#4a6ee5" width={18} />;
    }
    return <NotificationBellIcon onClick={handleFollowClick} fill="#4a6ee5" width={18} />;
  };

  return (
    <Tooltip placement="top" title={data.isChecked ? 'UNFOLLOW' : 'FOLLOW'}>
      <Button style={{ background: '#E9F6FE' }} theme="secondary" icon={getIcon(data)} />
    </Tooltip>
  );
};

export default FollowButton;

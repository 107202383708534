import React, { useEffect, useState } from 'react';
import { Card, Tabs } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { headerGenerator, getQueryStringParams } from 'utils/common';
import { postRequest } from 'api/apiRequests';
import { usePrevious } from 'utils/hooks/customHooks';
import { showError, showSuccess } from 'components/common/errorMessage';
import { baseURL, endPoints } from 'api/apiEndpoints';
import CreateList from 'components/list/createList';
import ListFileUploadDrawer from 'components/list/ListFileUpload';
import ListTable from 'components/list/listTable/ListTable';
import { LIST_TABS, LIST_TAB_SELECTION } from 'constants/constants';
import Layout, { Content } from 'antd/lib/layout/layout';
import { eventManager, eventList } from 'utils/eventManager';
import store from 'store/store';
import { deleteListById, getLists, searchLists, setActiveFilter } from './smartListActions';
import './smartListIndex.scss';

const SMART_LIST = LIST_TABS[0].value;

const activeView = new Map();

const SmartList = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const { lists, total, errorMessage, successMessage, activeFilter } = useSelector(
    (state) => state.smartList,
    shallowEqual
  );
  const headers = headerGenerator(user.token, user.session_id);
  const [isCreateList, setIsCreateList] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleCreateList = () => {
    setIsCreateList(false);
  };

  const [activeTab, setActiveTab] = useState(SMART_LIST);
  const [isFileUpload, setFileUploadVisible] = useState(false);
  const [supressionList, setExclusionList] = useState({ id: -1, listType: '' });
  const pusherChannel = useSelector((state) => state.auth.pusherChannel);
  const previousActiveFilter = usePrevious({ ...activeFilter });

  useEffect(() => {
    fetchDataWithFilters();
    subscribeToFileProcessing();

    return () => {
      pusherChannel.unbind('application_notification');
    };
  }, []);

  useEffect(() => {
    // if (!isEqual(previousActiveFilter, activeFilter)) {
    fetchListByType();
    // }
  }, [activeFilter]);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [lists]);

  useEffect(() => {
    setFileUploadVisible(!!supressionList.listType);
  }, [supressionList]);

  useEffect(() => {
    activeView.set('tab', activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (errorMessage && loading) {
      if (loading) {
        setLoading(false);
      }
      showError(errorMessage);
    }
    if (successMessage && loading) {
      if (loading) {
        setLoading(false);
      }
      showSuccess(successMessage);
    }
  }, [errorMessage, successMessage]);

  const subscribeToFileProcessing = () => {
    pusherChannel.bind('application_notification', loadDataOnFileProcess);
  };

  const loadDataOnFileProcess = (data) => {
    const selectedTab = activeView.get('tab');
    if (data.change_type && data.change_type.toLowerCase() === selectedTab) {
      fetchLatestLists(selectedTab);
    }
  };

  const getListTypeforCurrentTab = (tab) => {
    // bug: DS-5727 closure issue
    const { activeFilter: storeActiveFilter } = store.getState().smartList;
    const selectedTypeForCurrentTab = storeActiveFilter[tab] || 'all';
    if (selectedTypeForCurrentTab === 'all') {
      return LIST_TAB_SELECTION[tab]
        .filter((option) => option.value !== 'all')
        .map((op) => op.value);
    }
    return selectedTypeForCurrentTab;
  };

  const getDefaultSettings = (query = '') => {
    const listTypes = query.includes(',') ? query.split(',') : [query];
    const tabValues = Object.keys(LIST_TAB_SELECTION).filter((key) =>
      LIST_TAB_SELECTION[key].map((op) => op.value).includes(listTypes[0])
    );
    const mapping = {
      tab: tabValues.length ? tabValues[0] : LIST_TABS[0].value,
      type: tabValues.length && listTypes.length === 1 ? query : 'all',
    };
    return mapping;
  };

  const fetchSmartLists = (params) => {
    setLoading(true);
    if (params.searchTerm) {
      dispatch(searchLists(params, headers));
    } else {
      dispatch(getLists(params, headers));
    }
    history.push(`?type=${params.type}`);
  };

  const fetchListByType = (params = {}) => {
    const type = getListTypeforCurrentTab(activeTab);
    const queryParams = {
      type,
      page: 1,
      ...params,
    };
    fetchSmartLists(queryParams);
  };

  const fetchLatestLists = (tab) => {
    const type = getListTypeforCurrentTab(tab);
    const params = {
      type,
      page: 1,
      sortKey: 'updatedAt',
      sortOrder: 'DESC',
    };
    fetchSmartLists(params);
  };

  const fetchDataWithFilters = async () => {
    const query = getQueryStringParams(location.search).type;
    const { tab, type } = getDefaultSettings(query);
    setActiveTab(tab);
    setFilterForTab(tab, type);
  };

  const onTabChange = (tab) => {
    setActiveTab(tab);
    fetchLatestLists(tab);
  };

  const onDrawerClose = () => {
    if (activeTab.startsWith('exclusion')) {
      fetchLatestLists(activeTab);
    }
    setExclusionList({ id: -1, listType: '' });
  };

  const setFilterForTab = (tab, type) => {
    const previousState = activeFilter;
    previousState[tab] = type;
    const nextState = { ...previousState };
    dispatch(setActiveFilter(nextState));
  };

  const handleListCreationAction = (response) => {
    const { id, listType } = response;
    if (listType.endsWith('exclusion')) {
      openFileUploadDrawer(id, listType);
    } else {
      navigateToListItem(response);
    }
  };

  const openFileUploadDrawer = (id, listType) => {
    setExclusionList({ id, listType });
  };

  const navigateToListItem = (response) => {
    history.push({
      pathname: `/list/${response.id}`,
      item: response,
    });
  };

  const createSmartList = async (values) => {
    eventManager.track(eventList.LIST_CREATE, {
      object: values.listType,
      payload: values,
    });
    const url = `${baseURL}${endPoints.lists}`;
    const response = await postRequest(url, values, headers);
    if (response.error) {
      showError(response.message);
      return;
    }
    setIsCreateList(false);
    handleListCreationAction(response);
    // eslint-disable-next-line consistent-return
    return false;
  };

  const deleteList = (id) => {
    eventManager.track(eventList.LIST_DELETE, {
      object: activeTab,
      payload: {
        listId: id,
      },
    });
    setLoading(true);
    dispatch(deleteListById({ id }, headers));
  };

  // const renderCreateListButton = () => {
  //   return (
  //     <Button onClick={() => setIsCreateList(true)} type="primary" className="create-list-btn">
  //       <PlusOutlined />
  //       Create a list
  //     </Button>
  //   );
  // };

  const handleSetIsCreateList = () => {
    setIsCreateList(true);
  };

  const renderTabs = () => {
    return (
      <>
        {LIST_TABS.map((tab) => {
          return (
            <Tabs.TabPane tab={tab.label} key={tab.value}>
              <React.Fragment key={tab.value}>
                <Layout className="outer-tab-container">
                  <Content className="outer-tab-content" style={{ minHeight: '84.5vh' }}>
                    <Card className="inner-tab-container">
                      <ListTable
                        defaultFilter="all"
                        loading={loading}
                        listType={tab.value}
                        lists={lists}
                        total={total}
                        typeSelection={LIST_TAB_SELECTION[activeTab]}
                        fetchLists={fetchListByType}
                        onTypeFilter={setFilterForTab}
                        deleteList={deleteList}
                        onUplodClick={openFileUploadDrawer}
                        handleSetIsCreateList={handleSetIsCreateList}
                      />
                    </Card>
                  </Content>
                </Layout>
              </React.Fragment>
            </Tabs.TabPane>
          );
        })}
      </>
    );
  };

  return (
    <>
      <CreateList
        isCreateList={isCreateList}
        toggle={toggleCreateList}
        drawerTitle="CREATE A LIST"
        onSubmit={createSmartList}
      />
      <ListFileUploadDrawer onClose={onDrawerClose} visible={isFileUpload} list={supressionList} />
      {/* <Row gutter={[16, 16]} className="list-holder-no-margin">
        <Col span={24} className="list-holder-no-padding"> */}
      <div className="card-container list-container">
        <Tabs
          type="card"
          activeKey={activeTab}
          onChange={onTabChange}
          // tabBarExtraContent={renderCreateListButton()}
          className="app-tabs"
        >
          {renderTabs()}
        </Tabs>
      </div>
      {/* </Col>
      </Row> */}
    </>
  );
};

export default SmartList;

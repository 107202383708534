import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import scssVariables from 'src/_vars.scss';

const MapChart = ({ options, highcharts }) => {
  const { map, mapData, title, type } = options;
  const mapOptions = {
    credits: {
      enabled: false,
    },
    chart: {
      map,
      width: 1000,
      height: 500,
    },
    title: {
      text: `${title} Customers by Country`,
      style: {
        fontSize: '30px',
      },
    },
    subtitle: {
      text: '',
      style: {
        fontSize: '18px',
      },
    },
    legend: {
      enabled: false,
    },
    mapNavigation: {
      enabled: false,
      buttonOptions: {
        verticalAlign: 'bottom',
      },
    },
    exporting: { enabled: false },
    colorAxis: {
      min: 1,
      max: 1000,
      type: 'logarithmic',
    },
    colors: [scssVariables.slintelBlue1],
    series: [
      {
        data: mapData,
        joinBy: ['iso-a2', 'code'],
        name: type === 'keyword' ? 'Psychographics' : 'Technologies',
        tooltip: {
          valueSuffix: ' companies',
        },
        states: {
          hover: {
            color: scssVariables.selectedQuickViewItem,
            borderColor: 'gray',
          },
        },
      },
    ],
  };
  return mapData.length ? (
    <HighchartsReact highcharts={highcharts} constructorType="mapChart" options={mapOptions} />
  ) : null;
};
export default MapChart;

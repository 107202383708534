import {
  OUTREACH_FIELD_CHANGES,
  OUTREACH_MAPPING_SUCCESS,
  OUTREACH_INIT,
  OUTREACH_CONFIG_SUCCESS,
  OUTREACH_CONFIG_FAILURE,
} from 'store/actionTypes';

const initialState = {
  settings: {},
  errorMessage: '',
  successMessage: '',
};

const Outreach = (state = initialState, action) => {
  switch (action.type) {
    case OUTREACH_INIT: {
      const { data } = action;
      return {
        ...state,
        outreach: data,
        errorMessage: '',
      };
    }
    case OUTREACH_FIELD_CHANGES: {
      const {
        data: { rows, count },
      } = action;
      let { mappings } = state;
      mappings = [...mappings, ...rows];
      return {
        ...state,
        mappings,
        total: count,
        errorMessage: '',
        successMessage: '',
      };
    }
    case OUTREACH_MAPPING_SUCCESS: {
      const { data } = action;
      const { mappings } = state;
      return {
        ...state,
        mappings: [data, ...mappings],
        total: state.count + 1,
        successMessage: 'Mapping updated successfully',
        errorMessage: '',
      };
    }
    case OUTREACH_CONFIG_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        outreach: data,
        errorMessage: '',
      };
    }
    case OUTREACH_CONFIG_FAILURE: {
      return {
        ...state,
        outreach: null,
        errorMessage: action.error,
      };
    }
    default:
      return state;
  }
};

export default Outreach;

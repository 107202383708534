/* eslint-disable consistent-return */
import { Button, Spin, Tooltip } from 'antd';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import CommonService from 'api/services/commonService';
import { headerGenerator } from 'utils/common';
import { TOOL_TIP } from 'containers/dashboard/configs-constants/constants/src/constants';
import { InfoNewIcon } from 'UI/CustomIcon/CustomIcon';

const InstaRevealPhone = ({ payload, getInstaRes }) => {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const headers = headerGenerator(user.token, user.session_id);
  const [loader, setLoader] = useState(false);

  const handleRequest = async () => {
    setLoader(true);
    const response = await CommonService.requestPhoneNumber(payload, headers);
    setLoader(false);
    if (!response || response.error || !response.length) {
      getInstaRes('');
      return;
    }
    if (response[0] && response[0].phone) {
      getInstaRes(response[0].phone);
    }
  };

  return (
    <>
      {loader ? (
        <Spin size="small" />
      ) : (
        <div>
          <Button
            className="no-padding"
            style={{ height: 22, marginRight: 10 }}
            type="link"
            onClick={handleRequest}
          >
            Insta Reveal Phone Number
          </Button>
          <Tooltip title={TOOL_TIP.DISCOVER_MESSAGES.insta_reveal_disclaimer}>
            <InfoNewIcon className="empty-icon" width="14" height="14" />
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default InstaRevealPhone;

import {
  USER_CREATE,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAILURE,
  GET_CSRF,
  CSRF_SUCCESS,
  CSRF_FAILURE,
  VERIFICATION_SUCCESS,
  VERIFICATION_FAILURE,
  SEND_VERIFICATION,
  USER_LOGIN,
  USER_LOGOUT,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_FAILURE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  PROMOCODE_APPLY_RESET,
  PROMOCODE_APPLY,
  PROMOCODE_APPLY_SUCCESS,
  PROMOCODE_APPLY_FAILURE,
} from 'store/actionTypes';

export const userCreateSuccess = (data) => {
  return {
    type: USER_CREATE_SUCCESS,
    data,
  };
};

export const userCreateFailure = (error) => {
  return {
    type: USER_CREATE_FAILURE,
    error,
  };
};

export const createUser = (params, headers, csrfToken) => {
  const payload = {
    params,
    headers,
    csrfToken,
  };
  return {
    type: USER_CREATE,
    payload,
  };
};

export const fetchCsrfToken = (headers) => {
  const payload = {
    headers,
  };
  return {
    type: GET_CSRF,
    payload,
  };
};

export const csrfSuccess = (data) => {
  return {
    type: CSRF_SUCCESS,
    data,
  };
};

export const csrfFailure = (error) => {
  return {
    type: CSRF_FAILURE,
    error,
  };
};

export const sendVerification = (params, headers, csrfToken) => {
  const payload = {
    params,
    headers,
    csrfToken,
  };
  return {
    type: SEND_VERIFICATION,
    payload,
  };
};

export const verificationSuccess = (data) => {
  return {
    type: VERIFICATION_SUCCESS,
    data,
  };
};

export const verificationFailure = (error) => {
  return {
    type: VERIFICATION_FAILURE,
    error,
  };
};

export const userLogin = (params, headers, csrfToken) => {
  const payload = {
    params,
    headers,
    csrfToken,
  };
  return {
    type: USER_LOGIN,
    payload,
  };
};

export const userLogout = (headers) => {
  const payload = {
    headers,
  };
  return {
    type: USER_LOGOUT,
    payload,
  };
};

export const loginSuccess = (data) => {
  return {
    type: LOGIN_SUCCESS,
    data,
  };
};

export const logoutSuccess = (data) => {
  return {
    type: LOGOUT_SUCCESS,
    data,
  };
};

export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    error,
  };
};

export const logoutFailure = (error) => {
  return {
    type: LOGOUT_FAILURE,
    error,
  };
};

export const forgotPassword = (params, headers, csrfToken) => {
  const payload = {
    params,
    headers,
    csrfToken,
  };
  return {
    type: FORGOT_PASSWORD,
    payload,
  };
};

export const forgotPasswordSuccess = (data) => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    data,
  };
};

export const forgotPasswordFailure = (error) => {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    error,
  };
};

export const resetPassword = (params, headers, csrfToken) => {
  const payload = {
    params,
    headers,
    csrfToken,
  };
  return {
    type: RESET_PASSWORD,
    payload,
  };
};

export const resetPasswordSuccess = (data) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    data,
  };
};

export const resetPasswordFailure = (error) => {
  return {
    type: RESET_PASSWORD_FAILURE,
    error,
  };
};

export const promoCodeApply = (params, headers, csrfToken) => {
  const payload = {
    params,
    headers,
    csrfToken,
  };
  return {
    type: PROMOCODE_APPLY,
    payload,
  };
};

export const promoCodeApplySuccess = (data) => {
  return {
    type: PROMOCODE_APPLY_SUCCESS,
    data,
  };
};

export const promoCodeApplyFailure = (error) => {
  return {
    type: PROMOCODE_APPLY_FAILURE,
    error,
  };
};
export const promoCodeApplyReset = () => {
  return {
    type: PROMOCODE_APPLY_RESET,
  };
};

import React from 'react';
import HighchartsReact from 'highcharts-react-official';

const PieChart = ({ options, highcharts, title = '' }) => {
  const { data, half = false } = options;
  const pieOptions = {
    credits: {
      enabled: false,
    },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    title: {
      text: `${title}`,
      style: {
        fontSize: '30px',
      },
    },
    tooltip: {
      pointFormat: '<b>{point.percentage:.1f}%</b>',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          style: {
            color: (highcharts.theme && highcharts.theme.contrastTextColor) || 'black',
          },
        },
      },
    },
    series: [
      {
        colorByPoint: true,
        data,
      },
    ],
  };
  if (half) {
    pieOptions.plotOptions.pie = {
      dataLabels: {
        enabled: true,
        distance: -50,
        style: {
          fontWeight: 'bold',
          color: 'white',
        },
      },
      startAngle: -90,
      endAngle: 90,
      center: ['50%', '70%'],
      size: '140%',
    };
    pieOptions.series[0] = {
      ...pieOptions.series[0],
      type: 'pie',
      name: '',
      innerSize: '50%',
    };
  }
  return data.length ? <HighchartsReact highcharts={highcharts} options={pieOptions} /> : null;
};
export default PieChart;

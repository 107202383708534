import React, { useEffect } from 'react';
import { HelpIcon } from 'UI/CustomIcon/CustomIcon';

const HeadwayWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.defer = true;
    script.src = 'https://cdn.headwayapp.co/widget.js';
    document.head.appendChild(script);
    const config = {
      selector: '.headway-badge',
      account: 'xDX54x',
      enabled: true,
    };
    script.onload = () => {
      window.Headway.init(config);
    };
  }, []);

  return (
    <div
      className="headway-badge"
      id="headway-badge"
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <HelpIcon width="22" height="22" fill="#929cb7" />
    </div>
  );
};

export default HeadwayWidget;

export const CLOUDFRONT_BASE_URL = 'https://d3ml3b6vywsj0z.cloudfront.net';
export const DEBOUNCE_WAIT = 1000;
export const TIMEOUT = 30000;
export const LIST_EXTENDED_TIMEOUT = 60000;
export const NOTIFICATION_DURATION = 5;
export const MINIMUM_REQUIRED_SCREEN_WIDTH = 962;
export const MAX_NETNEW_LIMIT = 10000;
export const MAX_TOTAL_LIMIT = 50000;
export const MAX_TOTAL_CUSTOM_SELECTION_LIMIT = 10000000;
export const SMALL_SCREEN_INFO_TOASTER_MESSAGE =
  'Slintel works best with a screen size greater than 962 px. If you’re already on a laptop or computer, please try expanding or maximizing your browser size.';

export const NEWS_THEMES_MAPPING = {
  funding: 'Funding',
  acquisition: 'Acquisition',
  events: 'Events',
  partnerships: 'Partnerships',
  technologies: 'Technologies',
  expansion: 'Expansion',
  growth: 'Growth',
  leadership_changes: 'Leadership Changes',
  product_launches: 'Product Launches',
  executive_movements: 'Executive Movements',
  ipos: 'IPOS',
};

export const DECISION_MAKING_POWER_MAPPING = {
  High: 0,
  Medium: 1,
  Low: 2,
};

export const DEFAULT_FISCAL_YEAR_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const EMAIL_SCORE_ALLOWED_DOMAINS = ['slintel.com'];
export const DEFAULT_FUNDING_TIMELINES = [
  { label: 'Last 15 Days', value: 'Last 15 Days' },
  { label: 'Within 1 Month', value: 'Within 1 Month' },
  { label: 'Within 3 Months', value: 'Within 3 Months' },
  { label: 'Within 6 Months', value: 'Within 6 Months' },
  { label: 'Within 1 Year', value: 'Within 1 Year' },
];
export const DEFAULT_TECHNOLOGIES_OPTIONS = [
  {
    company_name: 'Greenhouse',
    subcategory: 'Applicant Tracking',
    company_profile_image_url:
      'https://d3ml3b6vywsj0z.cloudfront.net/website/product-images/Greenhouse.jpg',
    category: 'HR',
    type: 'technology',
    label: 'Greenhouse',
    value: 'HR|Applicant Tracking|Greenhouse',
  },
  {
    company_name: 'Taleo',
    subcategory: 'Applicant Tracking',
    company_profile_image_url:
      'https://d3ml3b6vywsj0z.cloudfront.net/website/product-images/Taleo.jpg',
    category: 'HR',
    type: 'technology',
    label: 'Taleo',
    value: 'HR|Applicant Tracking|Taleo',
  },
];

export const DEFAULT_KEYWORDS_OPTIONS = [
  {
    company_name: 'Objectives And Key Results',
    subcategory: 'Keywords',
    company_profile_image_url: '',
    category: 'Others',
    type: 'keyword',
    label: 'Objectives And Key Results',
    value: 'HR|Performance Management|Objectives And Key Results',
  },
  {
    company_name: 'Work From Home',
    subcategory: 'Keywords',
    company_profile_image_url: '',
    category: 'Others',
    type: 'keyword',
    label: 'Work from home',
    value: 'Others|Keywords|Work From Home',
  },
];

export const LIST_PAGE_DEFAULT_LEADS_LOCATION_OPTIONS = ['United States', 'California'];
export const LIST_PAGE_DEFAULT_COMPANY_LOCATION_OPTIONS = ['United States', 'California'];

export const DEFAULT_LEADS_LOCATION_OPTIONS = [
  { label: 'India', value: 'India' },
  { label: 'San Francisco', value: 'San Francisco' },
];
export const DEFAULT_COMPANY_LOCATION_OPTIONS = [
  { label: 'United States', value: 'United States' },
  { label: 'California', value: 'California' },
];

export const DEFAULT_COMPANY_OPTIONS = [
  {
    company_industry: 'Information Technology and Services',
    company_website: 'oracle.com',
    company_name: 'Oracle',
    company_profile_image_url:
      'https://crunchbase-production-res.cloudinary.com/image/upload/c_lpad,h_120,w_120,f_jpg/v1428845915/yr6khaxmmdbxjik1ys5l.png',
    type: 'company',
    label: 'Oracle',
    company_id: '5c3afffed55ae49f1b75e231',
    value: '5c3afffed55ae49f1b75e231|Information Technology and Services|oracle.com|Oracle',
  },
  {
    company_industry: 'Internet',
    company_name: 'Amazon',
    company_website: 'amazon.com',
    company_profile_image_url:
      'https://crunchbase-production-res.cloudinary.com/image/upload/c_lpad,h_120,w_120,f_jpg/asi1glj5xbbn0cbnwg05',
    company_homepage_url: 'http://amazon.com',
    type: 'company',
    label: 'Amazon',
    value: '5c3b000ad55ae49f1b75f1a3|Internet|amazon.com|Amazon',
    company_id: '5c3b000ad55ae49f1b75f1a3',
  },
];
export const DEFAULT_EMPTY_SEC_INDU_OPTIONS = [
  'Technology',
  'Financial Services',
  'Professional Services',
];
export const DEFAULT_EMPTY_LOCATION_OPTIONS = [
  'United States',
  'Canada',
  'India',
  'United Kingdom',
];
export const DEFAULT_EMPTY_LEAD_TITLE_OPTIONS = ['Founder', 'CEO', 'VP', 'CTO', 'Sales VP'];
export const DEFAULT_EMPTY_COMPANY_SIZE_OPTIONS = [
  '500 - 999',
  '250 - 499',
  '5,000 - 9,999',
  '1,000 - 4,999',
];
export const DEFAULT_EMPTY_COMPANY_NAME_OPTIONS = [
  {
    company_industry: 'Information Technology and Services',
    company_website: 'oracle.com',
    company_name: 'Oracle',
    company_profile_image_url:
      'https://crunchbase-production-res.cloudinary.com/image/upload/c_lpad,h_120,w_120,f_jpg/v1428845915/yr6khaxmmdbxjik1ys5l.png',
    type: 'company',
    label: 'Oracle',
    company_id: '5c3afffed55ae49f1b75e231',
    value: '5c3afffed55ae49f1b75e231|Information Technology and Services|oracle.com|Oracle',
  },
  {
    company_industry: 'Internet',
    company_name: 'Amazon',
    company_website: 'amazon.com',
    company_profile_image_url:
      'https://crunchbase-production-res.cloudinary.com/image/upload/c_lpad,h_120,w_120,f_jpg/asi1glj5xbbn0cbnwg05',
    company_homepage_url: 'http://amazon.com',
    type: 'company',
    label: 'Amazon',
    value: '5c3b000ad55ae49f1b75f1a3|Internet|amazon.com|Amazon',
    company_id: '5c3b000ad55ae49f1b75f1a3',
  },
  {
    company_industry: 'Computer Software',
    company_website: 'microsoft.com',
    company_name: 'Microsoft',
    company_profile_image_url: `${CLOUDFRONT_BASE_URL}/company_images/5b8915717c866675e50fdcb1_images.png`,
    type: 'company',
    label: 'Microsoft',
    company_id: '5b8915717c866675e50fdcb1',
    value: '5b8915717c866675e50fdcb1|Computer Software|microsoft.com|Microsoft',
  },
  {
    company_industry: 'Consumer Electronics',
    company_website: 'apple.com',
    company_name: 'Apple',
    company_profile_image_url: `${CLOUDFRONT_BASE_URL}/company_images/5b8915717c866675e50fdccc_images.png`,
    type: 'company',
    label: 'Apple',
    company_id: '5b8915717c866675e50fdccc',
    value: '5b8915717c866675e50fdccc|Consumer Electronics|apple.com|Apple',
  },
];

export const DEFAULT_PAST_COMPANY_OPTIONS = [
  {
    company_industry: 'Computer Software',
    company_website: 'microsoft.com',
    company_name: 'Microsoft',
    company_profile_image_url: `${CLOUDFRONT_BASE_URL}/company_images/5b8915717c866675e50fdcb1_images.png`,
    type: 'company',
    label: 'Microsoft',
    company_id: '5b8915717c866675e50fdcb1',
    value: '5b8915717c866675e50fdcb1|Computer Software|microsoft.com|Microsoft',
  },
  {
    company_industry: 'Information Technology and Services',
    company_website: 'accenture.com',
    company_profile_image_url: `${CLOUDFRONT_BASE_URL}/company_images/5c3b0161d55ae49f1b77c2ac_images.png`,
    company_name: 'Accenture',
    type: 'company',
    label: 'Accenture',
    company_id: '5c3b0161d55ae49f1b77c2ac',
    value: '5c3b0161d55ae49f1b77c2ac|Information Technology and Services|accenture.com|Accenture',
  },
];

export const NEWS_THEMES = {
  all: {
    label: 'All',
    bgColor: '#71FCF0',
    color: '#26C6B8',
  },
  funding: {
    label: 'Funding',
    bgColor: '#AEFCD2',
    color: '#62BC8B',
  },
  acquisition: {
    label: 'Acquisition',
    bgColor: '#FFE5E8',
    color: '#EB949E',
  },
  events: {
    label: 'Events',
    bgColor: '#FFF1E5',
    color: '#E3A46D',
  },
  partnerships: {
    label: 'Partnerships',
    bgColor: '#FFE5E5',
    color: '#FC8888',
  },
  technologies: {
    label: 'Technologies',
    bgColor: '#BEF0FD',
    color: '#14A8D3',
  },
  expansion: {
    label: 'Expansion',
    bgColor: '#FFF7BE',
    color: '#BEAD2C',
  },
  growth: {
    label: 'Growth',
    bgColor: '#71FCF0',
    color: '#26C6B8',
  },
  leadership_changes: {
    label: 'Leadership Changes',
    bgColor: '#FBE0FC',
    color: '#E38BE6',
  },
  product_launches: {
    label: 'Product Launches',
    bgColor: '#E4FFD0',
    color: '#8FB572',
  },
  executive_movements: {
    label: 'Executive Movements',
    bgColor: '#FBE0FC',
    color: '#E38BE6',
  },
  ipos: {
    label: 'IPOs',
    bgColor: '#E8E0FF',
    color: '#9883D3',
  },
};

export const NEWS_DATE_RANGE = [
  {
    label: 'Last 1 Day',
    value: 1,
  },
  {
    label: 'Last 7 Days',
    value: 7,
  },
  {
    label: 'Last 14 Days',
    value: 14,
  },
  {
    label: 'Last 30 Days',
    value: 30,
  },
  {
    label: 'Last 90 Days',
    value: 90,
  },
];

export const LAST_DETECTED_DATE_RANGE = [
  {
    label: 'Last 30 days',
    value: 30,
  },
  {
    label: 'Last 60 days',
    value: 60,
  },
  {
    label: 'Last 90 days',
    value: 90,
  },
  {
    label: 'Last 120 days',
    value: 120,
  },
  {
    label: 'Last 150 days',
    value: 150,
  },
  {
    label: 'Last 180 days',
    value: 180,
  },
];

export const TECHNOLOGY_SOURCES = [
  { label: 'Jobs', value: 'Jobs' },
  { label: 'Digital Footprint', value: 'Digital Footprint' },
  { label: 'Human Curated', value: 'Human Curated' },
  { label: 'People Work Summary', value: 'People Work Summary' },
];

export const TECH_STATUS = [
  { label: 'All Technologies', value: '' },
  { label: 'Additions', value: 'adds' },
  { label: 'Deletions', value: 'deletes' },
  { label: 'Contract Renewals', value: 'renewals' },
];

export const TECH_SOURCES = [
  {
    label: 'Jobs',
    value: 'jobs',
  },
  {
    label: 'Digital Footprint',
    value: 'digital_footprint',
  },
  {
    label: 'Human Curated',
    value: 'human_curated',
  },
  {
    label: 'People Work Summary',
    value: 'people_work_summary',
  },
];

export const MAPPINGS_PROJECTIONS = [
  {
    label: 'Technology',
    value: 'technology',
  },
  {
    label: 'Psychographics',
    value: 'keyword',
  },
  {
    label: 'Confidence score',
    value: 'confidence_score',
  },
  {
    label: 'Last detected',
    value: 'last_detected',
  },
  {
    label: 'Tech source',
    value: 'tech_source',
  },
  {
    label: 'Psychographics source',
    value: 'tech_source',
  },
  // {
  //   label: 'Sub-category',
  //   value: 'sub_category',
  // },
];

export const LIST_TABS = [
  {
    label: 'Smart Lists',
    value: 'smart_list',
  },
  {
    label: 'Exclusion Lists',
    value: 'exclusion_list',
  },
];

export const LIST_TAB_SELECTION = {
  smart_list: [
    {
      label: 'All Smart Lists',
      value: 'all',
    },
    {
      label: 'People',
      value: 'lead',
    },
    {
      label: 'Company',
      value: 'company',
    },
  ],
  exclusion_list: [
    {
      label: 'All Exclusion List',
      value: 'all',
    },
    {
      label: 'People',
      value: 'lead_exclusion',
    },
    {
      label: 'Company',
      value: 'company_exclusion',
    },
  ],
};

export const SAVED_FILTER_TYPE = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Lead',
    value: 'lead',
  },
  {
    label: 'Company',
    value: 'company',
  },
];

export const FILTER_TYPE = {
  lead: {
    bgColor: '#FFFBDD',
    color: '#DDC932',
  },
  company: {
    bgColor: '#FFF1E5',
    color: '#E3A46D',
  },
  technology: {
    bgColor: '#D0F5FF',
    color: '#14A8D3',
  },
  keyword: {
    bgColor: '#26C6B8',
    color: '#E3A46D',
  },
};

export const FILTER_PAGE_MAPPER = {
  lead: 'leads',
  company: 'companies',
  technology: 'technologies',
  keyword: 'keywords',
};

export const USER_ROLES_CONSTANT = {
  5: 'FREETRIAL Pack',
  7: 'STARTER Pack',
  8: 'GROWTH Pack',
  9: 'PROFESSIONAL Pack',
  10: 'FOREVER FREE Pack',
  11: 'SEED PLAN',
};
export const DEFAULT_USER_ROLE_CONSTANT = 11;

export const USER_ROLES_MAP = {
  PROFESSIONAL: 9,
  FOREVER_FREE: 10,
  SEED: 11,
};

export const LEAD_DIVISON_DATA = {
  Hr: [
    'Learning And Development',
    'Compensation And Benefits',
    'Talent Management',
    'Talent Operations',
    'Talent Acquisition',
    'General',
  ],
  Technology: [
    'It Security',
    'Tech Support',
    'Server And Storage',
    'Networking',
    'Data Science/ Data Engineering',
    'Data Management',
    'Bi And Analytics',
    'Machine Learning/deep Learning',
    'Software Engineering',
    'Project Management/business Analysis',
    'Product Management',
    'Testing And Qa',
    'Design And Experience',
    'Dev Ops',
    'Information Technology/systems',
    'General',
  ],
  'Customer Success And Support': ['Customer Support', 'Customer Success'],
  Marketing: [
    'Content Marketing',
    'Email And Social Media Marketing',
    'Seo And Sem',
    'Affiliate Marketing',
    'Product Marketing',
    'Brand Management',
    'Media And Pr',
    'Advertising And Promotions',
    'Marketing And Growth',
  ],
  Sales: [
    'Business Development',
    'Sales Development',
    'Sales Strategy',
    'Sales Operations',
    'Pre Sales',
    'Partnerships',
    'Lead Gen',
    'Inside Sales/field Sales',
  ],
  'Ceo/founder/co-founder': ['General'],
  Operations: [
    'Bids And Procurement',
    'Inventory And Merchandise',
    'Logistics And Distribution',
    'Quality Control And Maintenance',
    'Process Improvement',
    'Operations Management',
  ],
  'Finance Risk And Compliance': [
    'Accounting',
    'Audit',
    'Risk',
    'Regulatory And Compliance',
    'Finance',
  ],
  'General Management': ['All'],
  'Research And Strategy': [
    'Research',
    'Strategy',
    'Innovation',
    'Corporate Development',
    'Consulting',
  ],
  Other: ['General'],
  Administrative: ['Admin'],
};

export const FUNCTION_AGGREGATOR = [
  {
    label: 'Management',
    value: 'Management',
  },
  {
    label: 'Engineering',
    value: 'Engineering',
  },
  {
    label: 'Healthcare',
    value: 'Healthcare',
  },
  {
    label: 'Others',
    value: 'Others',
  },
  {
    label: 'Manual Work',
    value: 'Manual Work',
  },
  {
    label: 'Data Analysis',
    value: 'Data Analysis',
  },
  {
    label: 'Finance',
    value: 'Finance',
  },
  {
    label: 'Education',
    value: 'Education',
  },
  {
    label: 'Customer Service',
    value: 'Customer Service',
  },
  {
    label: 'Design',
    value: 'Design',
  },
  {
    label: 'Human Resources',
    value: 'Human Resources',
  },
  {
    label: 'Sales',
    value: 'Sales',
  },
  {
    label: 'Administrative',
    value: 'Administrative',
  },
  {
    label: 'Consulting',
    value: 'Consulting',
  },
  {
    label: 'Food',
    value: 'Food',
  },
  {
    label: 'Information Technology',
    value: 'Information Technology',
  },
  {
    label: 'Marketing',
    value: 'Marketing',
  },
  {
    label: 'Operations',
    value: 'Operations',
  },
  {
    label: 'Research',
    value: 'Research',
  },
  {
    label: 'Legal',
    value: 'Legal',
  },
  {
    label: 'Real Estate',
    value: 'Real Estate',
  },
  {
    label: 'Purchasing',
    value: 'Purchasing',
  },
  {
    label: 'Quality Assurance',
    value: 'Quality Assurance',
  },
  {
    label: 'Product Management',
    value: 'Product Management',
  },
];

export const LIST_SOURCE_DATA = [
  {
    label: 'Slintel Database',
    value: 'slintel',
  },
  {
    label: 'CSV',
    value: 'csv',
  },
];

export const LEAD_TITLE_DATA = [
  'CXO',
  'VP',
  'Director',
  'Manager',
  // 'Partner',
];

export const FUNCTION = [
  'Hr',
  'Sales',
  'Marketing',
  'Technology',
  'Finance Risk And Compliance',
  'Customer Success And Support',
  'Operations',
  'Research And Strategy',
  'Administrative',
  'Ceo/founder/co-founder',
  'General Management',
  'Other',
];

export const DECISION_MAKING = ['High', 'Medium', 'Low'];

export const COMPANY_REVENUE_DATA = [
  '$1 - $1M',
  '$1M - $5M',
  '$5M - $10M',
  '$10M - $25M',
  '$25M - $50M',
  '$50M - $100M',
  '$100M - $250M',
  '$250M - $500M',
  '$500M - $1B',
  '$1B - $2.5B',
  '$2.5B - $5B',
  '$5B+',
];

export const COMPANY_REVENUE_PICK = ['$5B+', '$2.5B - $5B'];

export const COMPANY_SIZE_PICK = ['10,000+', '5,000 - 9,999'];

export const LEAD_CONTINENT_DATA = [
  'North America',
  'Europe',
  'Asia',
  'Oceania',
  'Africa',
  'South America',
  'Antarctica',
];

export const EVENT_ACTIONS_CONSTANTS = {
  NDF: 'ndf',
  LEAD: 'lead',
  COMPANY: 'company',
  HUBSPOT: 'hubspot',
  PIPEDRIVE: 'pipedrive',
  SALESFORCE: 'salesforce',
  CSV: 'csv',
  COMPANY_FOLLOW: 'company_follow',
  COMPANY_UNFOLLOW: 'company_unfollow',
  SELECT_PAGE: 'select_page',
  SELECT_MAX: 'select_max',
  CUSTOM_SELECTION: 'custom_selection',
  APPLY: 'apply',
  SHARE: 'share',
  DELETE: 'delete',
  RENAME: 'rename',
  DUPLICATE: 'duplicate',
  CANCEL: 'cancel',
  PROCEED: 'proceed',
  FIND_LEADS: 'find_leads',
  ADD_TO_SMART_LIST: 'add_to_smart_list',
  EXPORT: 'export',
  SEE_DETAILED_VIEW: 'see_detailed_view',
  VIEW_JOBS: 'view_jobs',
  VIEW_LEADS: 'view_leads',
  INSTA_REVEAL_EMAIL: 'insta_reveal_email',
  INSTA_REVEAL_PHONE: 'insta_reveal_phone',
  SOCIAL_LINK_CLICKED: 'social_link_clicked',
  EXISTING_FILTER: 'existing_filter',
  NEW_FILTER: 'new_filter',
  LEAD_QUICK_VIEW: 'lead_quick_view',
  COMPANY_QUICK_VIEW: 'company_quick_view',
  COMPANY_QUICK_VIEW_OPENED: 'company_quickview_opened',
  QUICK_VIEW_OPENED: 'quickview_opened',
  FOLLOW: 'follow',
  UNFOLLOW: 'unfollow',
  CONTINUE: 'continue',
  LEAD_DETAIL: 'lead_detail',
  COMPANY_DETAIL: 'company_detail',
  FAILED: 'failed',
  SUCCESS: 'success',
};

// MIXPANEL EVENTS
export const MIXPANEL_EVENTS = {
  RESULT_ITEM_ACTION: 'result_item_action',
  APPLIED_FILTER: 'applied_filter',
  SAVE_FILTER: 'save_filter',
  TAB_SWITCH_POPUP: 'tab_switch_popup',
  SELECTOR_APPLIED: 'selector_applied',
  ADD_TO_SMART_LIST_STATUS: 'add_to_smart_list_status',
  ADD_TO_SMART_LIST_TRIGGERED: 'add_to_smartlist_triggered',
  SAVED_FILTER_ACTION: 'saved_filter_action',
  VIEW_JOBS_CLICKED: 'view_jobs_clicked',
  QUICK_VIEW_ACTIONS: 'quickview_action',
  EXPORT_STATUS: 'export_status',
  EXPORT_TRIGGERED: 'export_triggered',
  SESSION_STARTED: 'session_started',
  SIDENAVBAR_ITEM_CLICK: 'sidenavbar_item_click',
  REQUEST_DATA: 'request_data',
  SORTING_APPLIED: 'sorting_applied',
  SEARCH: 'search',
  GLOBAL_SEARCH_EVENT: 'global_search_event',
  INTENT_TAB: 'intent_tab',
  NEWS_FEEDS_TAB: 'news_feeds_tab',
  LIST_CREATE: 'list_create',
  LIST_CSV_UPLOAD: 'list_csv_upload',
  LIST_DELETE: 'list_delete',
  LIST_FILTER_APPLIED: 'list_filter_applied',
  LIST_ITEM_DELETE: 'list_item_delete',
  LIST_ITEM_EXPORT: 'list_item_export',
  INTEGRATION: 'integration',
  OUTREACH_INTEGRATION: 'outreach_integration',
  OUTREACH_CONNECT: 'outreach_connect',
  OUTREACH_POST_CONNECT: 'outreach_post_connect',
  OUTREACH_DISCONNECT: 'outreach_disconnect',
  OUTREACH_DISCONNECT_CONFIRM: 'outreach_disconnect_confirmation',
  CLICK_ON_GET_STARTED: 'click_on_get_started',
  COUPON_CODE_APPLIED: 'coupon_code_applied',
  VERIFICATION_PAGE_OPEN: 'verification_page_open',
  DETAIL_VIEW_ACTION: 'detail_view_action',
  SALESFORCE_DISCONNECT: 'salesforce_disconnect',
  HUBSPOT_DISCONNECT: 'hubspot_disconnect',
  SALESFORCE_POST_CONNECT: 'salesforce_post_connect',
  SALESFORCE_CUSTOM_PACKAGE_CLICK: 'salesforce_custom_package_click',
  SALESFORCE_SETTINGS_UPDATE: 'salesforce_settings_update',
  SALESFORCE_FIELD_MAPPING: 'salesforce_field_mapping',
  SALESFORCE_ENRICHMENT: 'salesforce_enrichment',
  SALESFORCE_CONFIG_CHANGELOG: 'salesforce_config_changelog',
  SALESFORCE_RESYNC_FIELDS: 'salesforce_resync_fields',
  SALESFORCE_CONNECT: 'salesforce_connect',
  HUBSPOT_CONNECT: 'hubspot_connect',
  HUBSPOT_POST_CONNECT: 'hubspot_post_connect',
  HUBSPOT_FIELD_MAPPING: 'hubspot_field_mapping',
  HUBSPOT_PUSH: 'hubspot_push',
  HUBSPOT_FIELD_RESYNC: 'hubspot_field_resync',
  HUBSPOT_SETTINGS: 'hubspot_settings',
};

export const CAPTURE_EVENT_NAME_CONSTANT = {
  SIDENAVBAR_ITEM_CLICK: 'sidenavbar_item_click',
};

export const TYPES_MAPPER = {
  lead: 'lead-details',
  company: 'company-details',
  keyword: 'keywords-details',
  technology: 'technologies-details',
};

export const INTEGRATIONS = [
  {
    label: 'CSV',
    value: 'csv',
    update: false,
    export: true,
  },
  {
    label: 'Pipedrive',
    value: 'pipedrive',
    update: false,
    export: true,
  },
  {
    label: 'Hubspot',
    value: 'hubspot',
    update: true,
    export: true,
  },
  {
    label: 'Salesforce',
    value: 'salesforce',
    update: true,
    export: true,
  },
  {
    label: 'Outreach',
    value: 'outreach',
    update: false,
    export: true,
  },
];

export const INTEGRATIONS_OBJECT_TYPES = {
  salesforce: [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Lead',
      value: 'lead',
    },
    {
      label: 'Contact',
      value: 'contact',
    },
    {
      label: 'Account',
      value: 'account',
    },
  ],
  hubspot: [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Lead',
      value: 'lead',
    },
    {
      label: 'Company',
      value: 'account',
    },
  ],
  csv: [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'People',
      value: 'lead',
    },
    {
      label: 'Company',
      value: 'account',
    },
  ],
};

export const INTEGRATIONS_EXPORT_SOURCES = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Dashboard',
    value: 'dashboard',
  },
  {
    label: 'Chrome Extension',
    value: 'ce',
  },
];

export const INTEGRATIONS_STATUS = [
  {
    label: 'All',
    value: 'all',
  },
  // {
  //   label: 'JobComplete',
  //   value: 'jobCompleted',
  // },
  {
    label: 'InProgress',
    value: 'inProgress',
  },
  {
    label: 'Failed',
    value: 'jobFailed',
  },
  {
    label: 'Success',
    value: 'jobSuccess',
  },
];
const CLOUDFRONT_URL = `${CLOUDFRONT_BASE_URL}/website/v2/icons`;
export const INTEGRATIONS_ICONS = {
  pipedrive: `${CLOUDFRONT_URL}/pd@2x.png`,
  hubspot: `${CLOUDFRONT_URL}/hubspot@2x.png`,
  salesforce: `${CLOUDFRONT_URL}/salesforce@2x.png`,
  outreach: `${CLOUDFRONT_URL}/outreach_@2x.png`,
  csv: `${CLOUDFRONT_URL}/dash-file-csv.png`,
  slack: `${CLOUDFRONT_URL}/slack@2x.png`,
  hubspot_enrich: `${CLOUDFRONT_URL}/hb-enrich@2x.png`,
};

const PHONE_PRIORITY_META = '{"priority":{"1":"phone","2":"work_phone"}}';
const TECHNOLOGY_META =
  '{"mapAll": true,"subcategory":[],"technology":[],"lastDetected":180,"techSource":["jobs","digital_footprint","human_curated","people_work_summary"],"techConfidence":[0,100],"projections":["technology"],"itemsPerSubCategory":0}';
const PYSCHOGRAPHICS_META =
  '{"mapAll": true,"subcategory":[],"keyword":[],"lastDetected":180,"techSource":["jobs","digital_footprint","human_curated","people_work_summary"],"techConfidence":[0,100],"projections":["keyword"],"itemsPerSubCategory":0}';

export const HISTORY_DATE_RANGE = [
  {
    label: 'Last 1 day',
    value: 1,
  },
  {
    label: 'Last 7 days',
    value: 7,
  },
  {
    label: 'Last 14 days',
    value: 14,
  },
  {
    label: 'Last 30 days',
    value: 30,
  },
  {
    label: 'Last 90 days',
    value: 90,
  },
  {
    label: 'All',
    value: 0,
  },
];

export const HUBSPOT_FIELDS = {
  contact_fields: [
    {
      label: 'First Name',
      field_value: 'first_name',
      field_example: 'Johnathan',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Last Name',
      field_value: 'last_name',
      field_example: 'Doe',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Email',
      field_value: 'email',
      field_example: 'jdoe@slintel.com',
      // hubspot_contact: 'Email',
      isMandatory: true,
    },
    {
      label: 'Phone Number',
      field_value: 'phone',
      field_example: '+(415) 123-4567',
      // hubspot_contact: '',
      slintel_field_meta: PHONE_PRIORITY_META,
      isMandatory: false,
    },
    {
      label: 'Email Confidence Score',
      field_value: 'email_score',
      field_example: 'High',
      isMandatory: false,
    },
    {
      label: 'City',
      field_value: 'city',
      field_example: 'San Francisco',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'State',
      field_value: 'state',
      field_example: 'California',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Country',
      field_value: 'country',
      field_example: 'United States',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Job Title',
      field_value: 'lead_titles',
      field_example: 'Sales Development Representative',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Division',
      field_value: 'lead_divison',
      field_example: 'Sales Development Representative ',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Decision Making Power',
      field_value: 'decision_making_power',
      field_example: 'High ',
      // hubspot_contact: '',
      isMandatory: false,
    },
    // {
    //   label: 'Seniority Level',
    //   field_value: 'seniority_level',
    //   field_example: 'Director',
    //   isMandatory: false,
    // },
    {
      label: 'LinkedIn URL',
      field_value: 'linkedin_url',
      field_example: 'http://www.linkedin.com/in/1234 ',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Company Name',
      field_value: 'company_name',
      field_example: 'Slintel',
      // hubspot_contact: '',
      isMandatory: false,
    },
    // {
    //   label: 'Company Description',
    //   field_value: 'company_description',
    //   field_example:
    //     'Slintel, the leader in capturing technographics-powered buying intent, helps companies uncover the 3% of active buyers in their target market...',
    //   isMandatory: false,
    // },
    {
      label: 'Company Website',
      field_value: 'company_website',
      field_example: 'www.slintel.com',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Company Phone Number',
      field_value: 'company_phone_numbers',
      field_example: '+(415) 123-4567',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Company City',
      field_value: 'company_city',
      field_example: 'San Francisco',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Company State',
      field_value: 'company_state',
      field_example: 'California',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Company Country',
      field_value: 'company_country',
      field_example: 'United States',
      // hubspot_contact: '',
      isMandatory: false,
    },
    // {
    //   label: 'Company Street Address',
    //   field_value: 'company_street_address',
    //   field_example: '465 N Whisman Rd',
    //   isMandatory: false,
    // },
    {
      label: 'Company Type',
      field_value: 'company_type',
      field_example: 'Private',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Company Function',
      field_value: 'company_function',
      field_example: 'Sales Intelligence',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Company Sector',
      field_value: 'company_sector',
      field_example: 'Retail and CPG',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Company Industry',
      field_value: 'company_industry',
      field_example: 'Apparel',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Company Size',
      field_value: 'company_size',
      field_example: '201-500',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Company Fiscal Year',
      field_value: 'fiscal_year',
      field_example: 'Jan-Dec',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Company Funding Range',
      field_value: 'company_funding_range',
      field_example: '10M-50M',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Company Revenue Range',
      field_value: 'revenue_range',
      field_example: '100M-500M',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Company EBITDA Range',
      field_value: 'ebitda_range',
      field_example: '100M-500M ',
      // hubspot_contact: '',
      isMandatory: false,
    },

    {
      label: 'Company Facebook URL',
      field_value: 'company_facebook_page',
      field_example: 'https://www.facebook.com/ExampleInc/',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Company Twitter URL',
      field_value: 'company_twitter_page',
      field_example: 'https://twitter.com/ExampleInc/',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Company LinkedIn URL',
      field_value: 'company_linkedin_page',
      field_example: 'https://www.linkedin.com/company/ExampleInc/',
      // hubspot_contact: '',
      isMandatory: false,
    },
    // {
    //   label: 'Slintel Company Url',
    //   field_value: 'slintel_company_url',
    //   field_example: 'https://dashboard.slintel.com/#/company-details/5c3b016dd55ae49f1b77d266',
    //   isMandatory: false,
    // },
    {
      label: 'Company Product And Services',
      field_value: 'company_products_services',
      field_example: 'Photo Sharing, Publishing, Social Media',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Company Job Count',
      field_value: 'job_count',
      field_example: '45',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Company SIC Code',
      field_value: 'company_sic_code',
      field_example: '2600,2621,2631',
      isMandatory: false,
    },
    {
      label: 'Company NAICS Code',
      field_value: 'company_naics_code',
      field_example: '522320,541850',
      isMandatory: false,
    },
    {
      label: 'Skills',
      field_value: 'skills',
      field_example: 'Apache, Scala',
      isMandatory: false,
    },
    {
      label: 'Interests',
      field_value: 'interests',
      field_example: 'ERP, Java',
      isMandatory: false,
    },
    {
      label: 'Slintel Updated Type',
      field_value: 'slintel_updated_type',
      field_example: 'Slintel Enriched',
      isMandatory: false,
    },
    // {
    //   label: 'Slintel People Url',
    //   field_value: 'slintel_lead_url',
    //   field_example: 'https://dashboard.slintel.com/#/lead-details/5dc164616d4235090c39736b',
    //   isMandatory: false,
    // },
    {
      label: 'Last Slintel Updated At',
      field_value: 'last_slintel_updated',
      field_example: '07/15/2021',
      isMandatory: false,
    },
    {
      label: 'Technologies',
      field_value: 'technologies_mapper',
      field_example: ' Qualtrics (Job Page, Digital Footprints) ',
      slintel_field_meta: TECHNOLOGY_META,
      isMandatory: false,
    },
    {
      label: 'Psychographics',
      field_value: 'keywords_mapper',
      field_example: 'WorkFromHome (Job Page, Digital Footprints)',
      slintel_field_meta: PYSCHOGRAPHICS_META,
      isMandatory: false,
    },
    {
      label: 'People Id',
      field_value: 'lead_id',
      field_example: '5b8916597c866675e510c2dc',
      // hubspot_contact: '',
      isMandatory: false,
    },
  ],
  account_fields: [
    {
      label: 'Company Name',
      field_value: 'company_name',
      field_example: 'Microsoft',
      // hubspot_account: '',
      isMandatory: false,
    },
    // {
    //   label: 'Company Description',
    //   field_value: 'company_description',
    //   field_example:
    //     'Slintel, the leader in capturing technographics-powered buying intent, helps companies uncover the 3% of active buyers in their target market...',
    //   isMandatory: false,
    // },
    {
      label: 'Company Website',
      field_value: 'company_website',
      field_example: 'https://www.microsoft.com/',
      // hubspot_account: 'Website',
      isMandatory: true,
    },
    {
      label: 'Company Phone Number',
      field_value: 'company_phone_number',
      field_example: '+14158435757',
      // hubspot_account: '',
      isMandatory: false,
    },
    {
      label: 'Company City',
      field_value: 'company_city',
      field_example: 'New York City',
      // hubspot_account: '',
      isMandatory: false,
    },
    {
      label: 'Company State',
      field_value: 'company_state',
      field_example: 'New York',
      // hubspot_account: '',
      isMandatory: false,
    },
    {
      label: 'Company Country',
      field_value: 'company_country',
      field_example: 'United States',
      // hubspot_account: '',
      isMandatory: false,
    },
    // {
    //   label: 'Company Street Address',
    //   field_value: 'company_street_address',
    //   field_example: '465 N Whisman Rd',
    //   isMandatory: false,
    // },
    {
      label: 'Company Type',
      field_value: 'company_type',
      field_example: 'Private',
      // hubspot_account: '',
      isMandatory: false,
    },
    {
      label: 'Company Sector',
      field_value: 'company_sector',
      field_example: 'Retail and CPG',
      isMandatory: false,
    },
    {
      label: 'Company Industry',
      field_value: 'company_industry',
      field_example: 'Apparel',
      isMandatory: false,
    },
    {
      label: 'Company Size',
      field_value: 'company_size',
      field_example: '201-500',
      isMandatory: false,
    },
    {
      label: 'Company Job Count',
      field_value: 'job_count',
      field_example: '45',
      isMandatory: false,
    },
    {
      label: 'Company SIC Code',
      field_value: 'company_sic_code',
      field_example: '2600,2621,2631',
      isMandatory: false,
    },
    {
      label: 'Company NAICS Code',
      field_value: 'company_naics_code',
      field_example: '522320,541850',
      isMandatory: false,
    },
    {
      label: 'Company Fiscal Year',
      field_value: 'fiscal_year',
      field_example: 'Jan-Dec',
      isMandatory: false,
    },
    {
      label: 'Company Funding Range',
      field_value: 'company_funding_range',
      field_example: '10M-50M',
      isMandatory: false,
    },
    {
      label: 'Company Revenue Range',
      field_value: 'revenue_range',
      field_example: '50M-100M',
      isMandatory: false,
    },
    {
      label: 'Company EBITDA Range',
      field_value: 'ebitda_range',
      field_example: '100M-500M ',
      isMandatory: false,
    },
    {
      label: 'Company Facebook URL',
      field_value: 'company_facebook_page',
      field_example: 'https://www.facebook.com/ExampleInc/',
      isMandatory: false,
    },
    {
      label: 'Company Twitter URL',
      field_value: 'company_twitter_page',
      field_example: 'https://twitter.com/ExampleInc/',
      isMandatory: false,
    },
    // {
    //   label: 'Slintel Company Url',
    //   field_value: 'slintel_company_url',
    //   field_example: 'https://dashboard.slintel.com/#/company-details/5c3b016dd55ae49f1b77d266',
    //   isMandatory: false,
    // },
    {
      label: 'Company Function',
      field_value: 'company_function',
      field_example: 'Sales Intelligence',
      // hubspot_contact: '',
      isMandatory: false,
    },
    {
      label: 'Company LinkedIn URL',
      field_value: 'company_linkedin_page',
      field_example: 'https://www.linkedin.com/company/ExampleInc/',
      isMandatory: false,
    },
    {
      label: 'Company Product And Services',
      field_value: 'company_products_services',
      field_example: 'Photo Sharing, Publishing, Social Media',
      isMandatory: false,
    },
    {
      label: 'Company Address',
      field_value: 'company_address',
      field_example: '839 St. Charles Ave. Suite 305',
      isMandatory: false,
    },
    {
      label: 'Postal Code',
      field_value: 'postal_code',
      field_example: '70130',
      isMandatory: false,
    },
    {
      label: 'Created By',
      field_value: 'created_by',
      field_example: 'email@example.com',
      isMandatory: false,
    },
    {
      label: 'Slintel Updated Type',
      field_value: 'slintel_updated_type',
      field_example: 'Slintel Enriched',
      isMandatory: false,
    },

    {
      label: 'Last Slintel Updated At',
      field_value: 'last_slintel_updated',
      field_example: '07/15/2021',
      isMandatory: false,
    },
    {
      label: 'Technologies',
      field_value: 'technologies_mapper',
      field_example: 'Qualtrics (Job Page, Digital Footprints)',
      isMandatory: false,
      slintel_field_meta: TECHNOLOGY_META,
    },
    {
      label: 'Psychographics',
      field_value: 'keywords_mapper',
      slintel_field_meta: PYSCHOGRAPHICS_META,
      field_example: 'WorkFromHome (Job Page, Digital Footprints) ',
      isMandatory: false,
    },
    {
      label: 'Company Id',
      field_value: 'company_id',
      field_example: '5b8916597c866675e510k2dc',
      // hubspot_contact: '',
      isMandatory: false,
    },
  ],
};

export const PIPEDRIVE_FIELDS = {
  contact_fields: [
    {
      label: 'Name',
      field_value: 'name',
      field_example: 'John Doe',
      isMandatory: true,
    },
    {
      label: 'Email',
      field_value: 'email',
      field_example: 'youremail@example.com',
      isMandatory: true,
    },
    {
      label: 'Phone Number  ',
      field_value: 'phone',
      field_example: '+14158435757',
      isMandatory: false,
      slintel_field_meta: PHONE_PRIORITY_META,
    },
    {
      label: 'City',
      field_value: 'city',
      field_example: 'San Francisco',
      isMandatory: false,
    },
    {
      label: 'State',
      field_value: 'state',
      field_example: 'California',
      isMandatory: false,
    },
    {
      label: 'Country',
      field_value: 'country',
      field_example: 'United States',
      isMandatory: false,
    },
    {
      label: 'Job Title',
      field_value: 'lead_titles',
      field_example: 'Marketing Manager',
      isMandatory: false,
    },
    {
      label: 'Division',
      field_value: 'lead_divison',
      field_example: 'HR',
      isMandatory: false,
    },
    {
      label: 'Decision Making Power',
      field_value: 'decision_making_power',
      field_example: 'High',
      isMandatory: false,
    },
    // {
    //   label: 'Seniority Level',
    //   field_value: 'seniority_level',
    //   field_example: 'Director',
    //   isMandatory: false,
    // },
    {
      label: 'LinkedIn URL',
      field_value: 'linkedin_url',
      field_example: 'https://www.linkedin.com/in/johndoe08/',
      isMandatory: false,
    },
    // {
    //   label: 'Slintel People Url',
    //   field_value: 'slintel_lead_url',
    //   field_example: 'https://dashboard.slintel.com/#/lead-details/5dc164616d4235090c39736b',
    //   isMandatory: false,
    // },
    {
      label: 'Company Name',
      field_value: 'company_name',
      field_example: 'Microsoft',
      isMandatory: false,
    },
    // {
    //   label: 'Company Description',
    //   field_value: 'company_description',
    //   field_example:
    //     'Slintel, the leader in capturing technographics-powered buying intent, helps companies uncover the 3% of active buyers in their target market...',
    //   isMandatory: false,
    // },
    {
      label: 'Company Website',
      field_value: 'company_website',
      field_example: 'https://www.microsoft.com/',
      isMandatory: false,
    },
    {
      label: 'Company Phone Number',
      field_value: 'company_phone_numbers',
      field_example: '+14158435757',
      isMandatory: false,
    },
    {
      label: 'Company City',
      field_value: 'company_city',
      field_example: 'New York City',
      isMandatory: false,
    },
    {
      label: 'Company State',
      field_value: 'company_state',
      field_example: 'New York',
      isMandatory: false,
    },
    {
      label: 'Company Country',
      field_value: 'company_country',
      field_example: 'United States',
      isMandatory: false,
    },
    // {
    //   label: 'Company Street Address',
    //   field_value: 'company_street_address',
    //   field_example: '465 N Whisman Rd',
    //   isMandatory: false,
    // },
    {
      label: 'Company Type',
      field_value: 'company_type',
      field_example: 'Private',
      isMandatory: false,
    },
    {
      label: 'Company Function',
      field_value: 'company_function',
      field_example: 'Operations ',
      isMandatory: false,
    },
    {
      label: 'Company Sector',
      field_value: 'company_sector',
      field_example: 'Retail and CPG',
      isMandatory: false,
    },
    {
      label: 'Company Industry',
      field_value: 'company_industry',
      field_example: 'Apparel',
      isMandatory: false,
    },
    {
      label: 'Company Size',
      field_value: 'company_size',
      field_example: '201-500',
      isMandatory: false,
    },
    {
      label: 'Company Fiscal Year',
      field_value: 'fiscal_year',
      field_example: 'Jan-Dec',
      isMandatory: false,
    },
    {
      label: 'Company Funding Range',
      field_value: 'company_funding_range',
      field_example: '10M-50M',
      isMandatory: false,
    },
    {
      label: 'Company Revenue Range',
      field_value: 'revenue_range',
      field_example: '50M-100M',
      isMandatory: false,
    },
    {
      label: 'Company EBITDA Range',
      field_value: 'ebitda_range',
      field_example: '100M-500M',
      isMandatory: false,
    },
    {
      label: 'Company Facebook URL',
      field_value: 'company_facebook_page',
      field_example: 'https://www.facebook.com/ExampleInc/',
      isMandatory: false,
    },
    {
      label: 'Company Twitter URL',
      field_value: 'company_twitter_page',
      field_example: 'https://twitter.com/ExampleInc/',
      isMandatory: false,
    },
    {
      label: 'Company LinkedIn URL',
      field_value: 'company_linkedin_page',
      field_example: 'https://www.linkedin.com/company/ExampleInc/',
      isMandatory: false,
    },
    // {
    //   label: 'Slintel Company Url',
    //   field_value: 'slintel_company_url',
    //   field_example: 'https://dashboard.slintel.com/#/company-details/5c3b016dd55ae49f1b77d266',
    //   isMandatory: false,
    // },
    {
      label: 'Company Product And Services',
      field_value: 'company_products_services',
      field_example: 'Photo Sharing, Publishing, Social Media',
      isMandatory: false,
    },
    {
      label: 'Company Job Count',
      field_value: 'job_count',
      field_example: '45',
      isMandatory: false,
    },
    {
      label: 'Company SIC Code',
      field_value: 'company_sic_code',
      field_example: '2600,2621,2631',
      isMandatory: false,
    },
    {
      label: 'Company NAICS Code',
      field_value: 'company_naics_code',
      field_example: '522320,541850',
      isMandatory: false,
    },
    {
      label: 'Email Confidence Score',
      field_value: 'email_score',
      field_example: 'High',
      isMandatory: false,
    },
    {
      label: 'Skills',
      field_value: 'skills',
      field_example: 'Apache, Scala',
      isMandatory: false,
    },
    {
      label: 'Interests',
      field_value: 'interests',
      field_example: 'ERP, Java',
      isMandatory: false,
    },
    {
      label: 'Created By',
      field_value: 'created_by',
      field_example: 'email@example.com',
      isMandatory: false,
    },
    {
      label: 'Psychographics',
      field_value: 'keywords_mapper',
      field_example: 'WorkFromHome (Job Page, Digital Footprints) ',
      isMandatory: false,
      slintel_field_meta: PYSCHOGRAPHICS_META,
    },
    {
      label: 'Technologies',
      field_value: 'technologies_mapper',
      field_example: 'Qualtrics (Job Page, Digital Footprints)',
      isMandatory: false,
      slintel_field_meta: TECHNOLOGY_META,
    },
  ],
  account_fields: [
    {
      label: 'Company Name',
      field_value: 'company_name',
      field_example: 'Microsoft',
      isMandatory: true,
    },
    // {
    //   label: 'Company Description',
    //   field_value: 'company_description',
    //   field_example:
    //     'Slintel, the leader in capturing technographics-powered buying intent, helps companies uncover the 3% of active buyers in their target market...',
    //   isMandatory: false,
    // },
    {
      label: 'Company Website',
      field_value: 'company_website',
      field_example: 'https://www.microsoft.com/',
      isMandatory: false,
    },
    {
      label: 'Company Phone Number',
      field_value: 'company_phone_number',
      field_example: '+14158435757',
      isMandatory: false,
    },
    {
      label: 'Company City',
      field_value: 'company_city',
      field_example: 'New York City',
      isMandatory: false,
    },
    {
      label: 'Company State',
      field_value: 'company_state',
      field_example: 'New York',
      isMandatory: false,
    },
    {
      label: 'Company Country',
      field_value: 'company_country',
      field_example: 'United States',
      isMandatory: false,
    },
    // {
    //   label: 'Company Street Address',
    //   field_value: 'company_street_address',
    //   field_example: '465 N Whisman Rd',
    //   isMandatory: false,
    // },
    {
      label: 'Company Type',
      field_value: 'company_type',
      field_example: 'Private',
      isMandatory: false,
    },
    {
      label: 'Company Sector',
      field_value: 'company_sector',
      field_example: 'Retail and CPG',
      isMandatory: false,
    },
    {
      label: 'Company Industry',
      field_value: 'company_industry',
      field_example: 'Apparel',
      isMandatory: false,
    },
    {
      label: 'Company Size',
      field_value: 'company_size',
      field_example: '201-500',
      isMandatory: false,
    },
    {
      label: 'Company Job Count',
      field_value: 'job_count',
      field_example: '45',
      isMandatory: false,
    },
    {
      label: 'Company SIC Code',
      field_value: 'company_sic_code',
      field_example: '2600,2621,2631',
      isMandatory: false,
    },
    {
      label: 'Company NAICS Code',
      field_value: 'company_naics_code',
      field_example: '522320,541850',
      isMandatory: false,
    },
    {
      label: 'Company Fiscal Year',
      field_value: 'fiscal_year',
      field_example: 'Jan-Dec',
      isMandatory: false,
    },
    {
      label: 'Company Funding Range',
      field_value: 'company_funding_range',
      field_example: '10M-50M',
      isMandatory: false,
    },
    {
      label: 'Company Revenue Range',
      field_value: 'revenue_range',
      field_example: '50M-100M',
      isMandatory: false,
    },
    {
      label: 'Company EBITDA Range',
      field_value: 'ebitda_range',
      field_example: '100M-500M ',
      isMandatory: false,
    },
    {
      label: 'Company Facebook URL',
      field_value: 'company_facebook_page',
      field_example: 'https://www.facebook.com/ExampleInc/',
      isMandatory: false,
    },
    {
      label: 'Company Twitter URL',
      field_value: 'company_twitter_page',
      field_example: 'https://twitter.com/ExampleInc/',
      isMandatory: false,
    },
    {
      label: 'Company LinkedIn URL',
      field_value: 'company_linkedin_page',
      field_example: 'https://www.linkedin.com/company/ExampleInc/',
      isMandatory: false,
    },
    // {
    //   label: 'Slintel Company Url',
    //   field_value: 'slintel_company_url',
    //   field_example: 'https://dashboard.slintel.com/#/company-details/5c3b016dd55ae49f1b77d266',
    //   isMandatory: false,
    // },
    {
      label: 'Company Product And Services',
      field_value: 'company_products_services',
      field_example: 'Photo Sharing, Publishing, Social Media',
      isMandatory: false,
    },
    {
      label: 'Company Address',
      field_value: 'company_address',
      field_example: '839 St. Charles Ave. Suite 305',
      isMandatory: false,
    },
    {
      label: 'Postal Code',
      field_value: 'postal_code',
      field_example: '70130',
      isMandatory: false,
    },
    {
      label: 'Created By',
      field_value: 'created_by',
      field_example: 'email@example.com',
      isMandatory: false,
    },
    {
      label: 'Technologies',
      field_value: 'technologies_mapper',
      field_example: 'Qualtrics (Job Page, Digital Footprints)',
      isMandatory: false,
      slintel_field_meta: TECHNOLOGY_META,
    },
    {
      label: 'Psychographics',
      field_value: 'keywords_mapper',
      field_example: 'WorkFromHome (Job Page, Digital Footprints) ',
      isMandatory: false,
      slintel_field_meta: PYSCHOGRAPHICS_META,
    },
  ],
};

export const OUTREACH_FIELDS = {
  prospect_fields: [
    {
      label: 'First Name',
      field_value: 'first_name',
      field_example: 'Johnathan',
      isMandatory: true,
    },
    {
      label: 'Last Name',
      field_value: 'last_name',
      field_example: 'Doe',
      isMandatory: true,
    },
    {
      label: 'Email',
      field_value: 'email',
      field_example: 'youremail@example.com',
      isMandatory: true,
    },
    {
      label: 'Export Tags',
      field_value: 'tags',
      field_example: 'ORM',
      isMandatory: true,
    },
    {
      label: 'Phone Number',
      field_value: 'phone',
      field_example: '+14158435757',
      isMandatory: false,
      slintel_field_meta: PHONE_PRIORITY_META,
    },
    {
      label: 'City',
      field_value: 'city',
      field_example: 'San Francisco',
      isMandatory: false,
    },
    {
      label: 'State',
      field_value: 'state',
      field_example: 'California',
      isMandatory: false,
    },
    {
      label: 'Country',
      field_value: 'country',
      field_example: 'United States',
      isMandatory: false,
    },
    {
      label: 'Job Title',
      field_value: 'lead_titles',
      field_example: 'Marketing Manager',
      isMandatory: false,
    },
    {
      label: 'Division',
      field_value: 'lead_divison',
      field_example: 'HR',
      isMandatory: false,
    },
    {
      label: 'Decision Making Power',
      field_value: 'decision_making_power',
      field_example: 'High',
      isMandatory: false,
    },
    // {
    //   label: 'Seniority Level',
    //   field_value: 'seniority_level',
    //   field_example: 'Director',
    //   isMandatory: false,
    // },
    {
      label: 'LinkedIn URL',
      field_value: 'linkedin_url',
      field_example: 'https://www.linkedin.com/in/johndoe08/',
      isMandatory: false,
    },
    // {
    //   label: 'Slintel People Url',
    //   field_value: 'slintel_lead_url',
    //   field_example: 'https://dashboard.slintel.com/#/lead-details/5dc164616d4235090c39736b',
    //   isMandatory: false,
    // },
    {
      label: 'Company Name',
      field_value: 'company_name',
      field_example: 'Microsoft',
      isMandatory: false,
    },
    // {
    //   label: 'Company Description',
    //   field_value: 'company_description',
    //   field_example:
    //     'Slintel, the leader in capturing technographics-powered buying intent, helps companies uncover the 3% of active buyers in their target market...',
    //   isMandatory: false,
    // },
    {
      label: 'Company Website',
      field_value: 'company_website',
      field_example: 'https://www.microsoft.com/',
      isMandatory: false,
    },
    {
      label: 'Company Phone Number',
      field_value: 'company_phone_number',
      field_example: '+14158435757',
      isMandatory: false,
    },
    {
      label: 'Company City',
      field_value: 'company_city',
      field_example: 'New York City',
      isMandatory: false,
    },
    {
      label: 'Company State',
      field_value: 'company_state',
      field_example: 'New York',
      isMandatory: false,
    },
    {
      label: 'Company Country',
      field_value: 'company_country',
      field_example: 'United States',
      isMandatory: false,
    },
    // {
    //   label: 'Company Street Address',
    //   field_value: 'company_street_address',
    //   field_example: '465 N Whisman Rd',
    //   isMandatory: false,
    // },
    {
      label: 'Company Type',
      field_value: 'company_type',
      field_example: 'Private',
      isMandatory: false,
    },
    {
      label: 'Company Function',
      field_value: 'company_function',
      field_example: 'Operations ',
      isMandatory: false,
    },
    {
      label: 'Company Sector',
      field_value: 'company_sector',
      field_example: 'Retail and CPG',
      isMandatory: false,
    },
    {
      label: 'Company Industry',
      field_value: 'company_industry',
      field_example: 'Apparel',
      isMandatory: false,
    },
    {
      label: 'Company Size',
      field_value: 'company_size',
      field_example: '201-500',
      isMandatory: false,
    },
    {
      label: 'Company Fiscal Year',
      field_value: 'fiscal_year',
      field_example: 'Jan-Dec',
      isMandatory: false,
    },
    {
      label: 'Company Funding Range',
      field_value: 'company_funding_range',
      field_example: '10M-50M',
      isMandatory: false,
    },
    {
      label: 'Company Revenue Range',
      field_value: 'revenue_range',
      field_example: '50M-100M',
      isMandatory: false,
    },
    {
      label: 'Company EBITDA Range',
      field_value: 'ebitda_range',
      field_example: '100M-500M',
      isMandatory: false,
    },
    {
      label: 'Company Facebook URL',
      field_value: 'company_facebook_page',
      field_example: 'https://www.facebook.com/ExampleInc/',
      isMandatory: false,
    },
    {
      label: 'Company Twitter URL',
      field_value: 'company_twitter_page',
      field_example: 'https://twitter.com/ExampleInc/',
      isMandatory: false,
    },
    {
      label: 'Company LinkedIn URL',
      field_value: 'company_linkedin_page',
      field_example: 'https://www.linkedin.com/company/ExampleInc/',
      isMandatory: false,
    },
    // {
    //   label: 'Slintel Company Url',
    //   field_value: 'slintel_company_url',
    //   field_example: 'https://dashboard.slintel.com/#/company-details/5c3b016dd55ae49f1b77d266',
    //   isMandatory: false,
    // },
    {
      label: 'Company Product And Services',
      field_value: 'company_products_services',
      field_example: 'Photo Sharing, Publishing, Social Media',
      isMandatory: false,
    },
    {
      label: 'Company Job Count',
      field_value: 'job_count',
      field_example: '45',
      isMandatory: false,
    },
    {
      label: 'Company SIC Code',
      field_value: 'company_sic_code',
      field_example: '2600,2621,2631',
      isMandatory: false,
    },
    {
      label: 'Company NAICS Code',
      field_value: 'company_naics_code',
      field_example: '522320,541850',
      isMandatory: false,
    },
    {
      label: 'Email Confidence Score',
      field_value: 'email_score',
      field_example: 'High',
      isMandatory: false,
    },
    {
      label: 'Skills',
      field_value: 'skills',
      field_example: 'Apache, Scala',
      isMandatory: false,
    },
    {
      label: 'Interests',
      field_value: 'interests',
      field_example: 'ERP, Java',
      isMandatory: false,
    },
    {
      label: 'Created By',
      field_value: 'created_by',
      field_example: 'email@example.com',
      isMandatory: false,
    },
    {
      label: 'Psychographics',
      field_value: 'keywords_mapper',
      field_example: 'WorkFromHome (Job Page, Digital Footprints) ',
      isMandatory: false,
      slintel_field_meta: PYSCHOGRAPHICS_META,
    },
    {
      label: 'Technologies',
      field_value: 'technologies_mapper',
      field_example: 'Qualtrics (Job Page, Digital Footprints)',
      isMandatory: false,
      slintel_field_meta: TECHNOLOGY_META,
    },
  ],
  account_fields: [
    {
      label: 'Company Name',
      field_value: 'company_name',
      field_example: 'Microsoft',
      isMandatory: true,
    },
    // {
    //   label: 'Company Description',
    //   field_value: 'company_description',
    //   field_example:
    //     'Slintel, the leader in capturing technographics-powered buying intent, helps companies uncover the 3% of active buyers in their target market...',
    //   isMandatory: false,
    // },
    {
      label: 'Company Website',
      field_value: 'company_website',
      field_example: 'https://www.microsoft.com/',
      isMandatory: true,
    },
    {
      label: 'Company Phone Number',
      field_value: 'company_phone_number',
      field_example: '+14158435757',
      isMandatory: false,
    },
    {
      label: 'Company City',
      field_value: 'company_city',
      field_example: 'New York City',
      isMandatory: false,
    },
    {
      label: 'Company State',
      field_value: 'company_state',
      field_example: 'New York',
      isMandatory: false,
    },
    {
      label: 'Company Country',
      field_value: 'company_country',
      field_example: 'United States',
      isMandatory: false,
    },
    // {
    //   label: 'Company Street Address',
    //   field_value: 'company_street_address',
    //   field_example: '465 N Whisman Rd',
    //   isMandatory: false,
    // },
    {
      label: 'Company Type',
      field_value: 'company_type',
      field_example: 'Private',
      isMandatory: false,
    },
    {
      label: 'Company Sector',
      field_value: 'company_sector',
      field_example: 'Retail and CPG',
      isMandatory: false,
    },
    {
      label: 'Company Industry',
      field_value: 'company_industry',
      field_example: 'Apparel',
      isMandatory: false,
    },
    {
      label: 'Company Size',
      field_value: 'company_size',
      field_example: '201-500',
      isMandatory: false,
    },
    {
      label: 'Company Job Count',
      field_value: 'job_count',
      field_example: '45',
      isMandatory: false,
    },
    {
      label: 'Company SIC Code',
      field_value: 'company_sic_code',
      field_example: '2600,2621,2631',
      isMandatory: false,
    },
    {
      label: 'Company NAICS Code',
      field_value: 'company_naics_code',
      field_example: '522320,541850',
      isMandatory: false,
    },
    {
      label: 'Company Fiscal Year',
      field_value: 'fiscal_year',
      field_example: 'Jan-Dec',
      isMandatory: false,
    },
    {
      label: 'Company Funding Range',
      field_value: 'company_funding_range',
      field_example: '10M-50M',
      isMandatory: false,
    },
    {
      label: 'Company Revenue Range',
      field_value: 'revenue_range',
      field_example: '50M-100M',
      isMandatory: false,
    },
    {
      label: 'Company EBITDA Range',
      field_value: 'ebitda_range',
      field_example: '100M-500M ',
      isMandatory: false,
    },
    {
      label: 'Company Facebook URL',
      field_value: 'company_facebook_page',
      field_example: 'https://www.facebook.com/ExampleInc/',
      isMandatory: false,
    },
    {
      label: 'Company Twitter URL',
      field_value: 'company_twitter_page',
      field_example: 'https://twitter.com/ExampleInc/',
      isMandatory: false,
    },
    {
      label: 'Company LinkedIn URL',
      field_value: 'company_linkedin_page',
      field_example: 'https://www.linkedin.com/company/ExampleInc/',
      isMandatory: false,
    },
    // {
    //   label: 'Slintel Company Url',
    //   field_value: 'slintel_company_url',
    //   field_example: 'https://dashboard.slintel.com/#/company-details/5c3b016dd55ae49f1b77d266',
    //   isMandatory: false,
    // },
    {
      label: 'Company Product And Services',
      field_value: 'company_products_services',
      field_example: 'Photo Sharing, Publishing, Social Media',
      isMandatory: false,
    },
    {
      label: 'Company Address',
      field_value: 'company_address',
      field_example: '839 St. Charles Ave. Suite 305',
      isMandatory: false,
    },
    {
      label: 'Postal Code',
      field_value: 'postal_code',
      field_example: '70130',
      isMandatory: false,
    },
    {
      label: 'Created By',
      field_value: 'created_by',
      field_example: 'email@example.com',
      isMandatory: false,
    },
    {
      label: 'Technologies',
      field_value: 'technologies_mapper',
      field_example: 'Qualtrics (Job Page, Digital Footprints)',
      isMandatory: false,
      slintel_field_meta: TECHNOLOGY_META,
    },
    {
      label: 'Psychographics',
      field_value: 'keywords_mapper',
      field_example: 'WorkFromHome (Job Page, Digital Footprints) ',
      isMandatory: false,
      slintel_field_meta: PYSCHOGRAPHICS_META,
    },
  ],
};

export const SAMPLE_LEAD_ENRICHMENT_CSV =
  'https://d3ml3b6vywsj0z.cloudfront.net/website/lead_enrichement_sample.csv';
export const SAMPLE_LEAD_DISCOVERY_CSV =
  'https://d3ml3b6vywsj0z.cloudfront.net/website/sample_lead_discovery.csv';
export const SAMPLE_COMPANY_ENRICHMENT_CSV =
  'https://d3ml3b6vywsj0z.cloudfront.net/website/company_enrichment.csv';
export const SAMPLE_COMPANY_EXCLUSION_CSV =
  'https://d3ml3b6vywsj0z.cloudfront.net/website/slintel-company-exclusion-list-example.csv';
export const SAMPLE_LEAD_EXCLUSION_CSV =
  'https://d3ml3b6vywsj0z.cloudfront.net/website/slintel-lead-exclusion-example.csv';

export const LIST_JOB_TYPES = {
  lead_enrichment: 'People enrichment',
  lead_discovery: 'People discovery',
  company_enrichment: 'Company enrichment',
  slintel_lead_add: 'Adding people from Slintel',
  // TODO: this doesn't look correct. What is the context here?
  slintel_job_lead_add: 'Adding job lead(s) from Slintel',
  slintel_company_add: 'Adding company(s) from Slintel',
  csv_export: 'CSV export',
  salesforce_push: 'Salesforce export',
  hubspot_push: 'HubSpot export',
  slintel_intent_bulk_add: 'Adding company(s) from Slintel',
  slintel_company_list_lead_discovery: 'People discovery from company(s)',
};

export const BETA_DOMAINS = ['slintel.com', 'mailinator.com', 'yopmail.com'];

export const MAX_ITEM_PER_LIST = 50000;
export const HUBSPOT_EXPORT_LIMIT = 10000;
export const SF_EXPORT_COMPANY = 10000;
export const SF_EXPORT_LEAD = 50000;
export const SF_EXPORT_CONTACT_ACCOUNT = 50000;
export const MAX_EXPORT_LIMIT = 10000;
export const MAX_PIPEDRIVE_EXPORT_LIMIT = 1000;
export const GLOBAL_SEARCH_CHAR_LIMIT = 200;
export const FILTER_BOOLEAN_SEARCH_CHAR_LIMIT = 200;
export const FREE_TEXT_FILTER_CHAR_LIMIT = 200;
export const LIST_NAME_CHAR_LIMIT = 50;
export const SUGGESTER_CHAR_LIMIT = 100;

export const SLACK_CLIENT_ID = '133360771634.1874984748996';

export const COMPANY_SIZE_DATA = [
  '0 - 9',
  '10 - 19',
  '20 - 49',
  '50 - 99',
  '100 - 249',
  '250 - 499',
  '500 - 999',
  '1,000 - 4,999',
  '5,000 - 9,999',
  '10,000+',
];

export const COMPANY_SECTOR = [
  'Energy and Utilities',
  'Financial Services',
  'Healthcare and Lifesciences',
  'Industrials & Chemicals',
  'Media and Telecom',
  'Professional Services',
  'Retail and CPG',
  'Technology',
];

export const COMPANY_INDUSTRY = {
  'Energy and Utilities': ['Oil & Energy', 'Renewables & Environment', 'Utilities'],
  'Financial Services': [
    'Accounting',
    'Banking',
    'Capital Markets',
    'Financial Services',
    'Insurance',
    'Investment Banking',
    'Investment Management',
    'Venture Capital & Private Equity',
    'Fund-Raising',
  ],
  'Healthcare and Lifesciences': [
    'Alternative Medicine',
    'Biotechnology',
    'Health Wellness and Fitness',
    'Hospital & Health Care',
    'Medical Devices',
    'Medical Practice',
    'Mental Health Care',
    'Pharmaceuticals',
    'Veterinary',
  ],
  'Industrials & Chemicals': [
    'Airlines/Aviation',
    'Automotive',
    'Aviation & Aerospace',
    'Building Materials',
    'Business Supplies and Equipment',
    'Chemicals',
    'Defense & Space',
    'Electrical/Electronic Manufacturing',
    'Food Production',
    'Glass Ceramics & Concrete',
    'Industrial Automation',
    'Machinery',
    'Mining & Metals',
    'Railroad Manufacture',
    'Semiconductors',
    'Shipbuilding',
    'Transportation/Trucking/Railroad',
  ],
  'Media and Telecom': [
    'Broadcast Media',
    'Entertainment',
    'Media Production',
    'Motion Pictures and Film',
    'Newspapers',
    'Online Media',
    'Publishing',
    'Telecommunications',
    'Wireless',
  ],
  'Professional Services': [
    'Alternative Dispute Resolution',
    'Architecture & Planning',
    'Arts and Crafts',
    'Civic & Social Organization',
    'Civil Engineering',
    'Commercial Real Estate',
    'Construction',
    'Design',
    'Education Management',
    'Environmental Services',
    'Events Services',
    'Executive Office',
    'Facilities Services',
    'Farming',
    'Fine Art',
    'Fishery',
    'Gambling & Casinos',
    'Government Administration',
    'Government Relations',
    'Graphic Design',
    'Higher Education',
    'Human Resources',
    'Import and Export',
    'Individual & Family Services',
    'International Affairs',
    'International Trade and Development',
    'Judiciary',
    'Law Enforcement',
    'Law Practice',
    'Legal Services',
    'Legislative Office',
    'Leisure Travel & Tourism',
    'Libraries',
    'Logistics and Supply Chain',
    'Management Consulting',
    'Maritime',
    'Marketing and Advertising',
    'Market Research',
    'Mechanical or Industrial Engineering',
    'Military',
    'Museums and Institutions',
    'Music',
    'Nonprofit Organization Management',
    'Outsourcing/Offshoring',
    'Performing Arts',
    'Philanthropy',
    'Photography',
    'Political Organization',
    'Primary/Secondary Education',
    'Printing',
    'Professional Training & Coaching',
    'Program Development',
    'Public Policy',
    'Public Relations and Communications',
    'Public Safety',
    'Ranching',
    'Real Estate',
    'Recreational Facilities and Services',
    'Religious Institutions',
    'Research',
    'Security and Investigations',
    'Staffing and Recruiting',
    'Think Tanks',
    'Translation and Localization',
    'Writing and Editing',
  ],
  'Retail and CPG': [
    'Apparel & Fashion',
    'Consumer Electronics',
    'Consumer Goods',
    'Consumer Services',
    'Cosmetics',
    'Dairy',
    'Food & Beverages',
    'Furniture',
    'Hospitality',
    'Luxury Goods & Jewelry',
    'Package/Freight Delivery',
    'Packaging and Containers',
    'Paper & Forest Products',
    'Plastics',
    'Restaurants',
    'Retail',
    'Sporting Goods',
    'Sports',
    'Supermarkets',
    'Textiles',
    'Tobacco',
    'Warehousing',
    'Wholesale',
    'Wine and Spirits',
  ],
  Technology: [
    'Animation',
    'Computer Games',
    'Computer Hardware',
    'Computer Networking',
    'Computer & Network Security',
    'Computer Software',
    'E-Learning',
    'Information Services',
    'Information Technology and Services',
    'Internet',
    'Nanotechnology',
  ],
};
export const EXPORT_LEADS_OPTIONS = [
  { label: 'Leads', value: 1 },
  { label: 'Contacts', value: 2 },
];
export const PIPEDRIVE_EVENTS = {
  PIPEDRIVE_CONNECT: 'pipedrive_connect',
  PIPEDRIVE_POST_CONNECT: 'pipedrive_post_connect',
  PIPEDRIVE_SETTINGS_UPDATE: 'pipedrive_settings_update',
  PIPEDRIVE_FIELD_MAPPING: 'pipedrive_field_mapping',
};

export const EXPORT_EXTRA_MAPPING = [
  {
    label: 'Stock Exchange',
    value: 'stock_exchange',
  },
  {
    label: 'Description',
    value: 'company_description',
  },
  {
    label: 'Stock Symbol',
    value: 'stock_symbol',
  },
  {
    label: 'Stock Price',
    value: 'stock_price',
  },
  {
    label: 'Facebook Page',
    value: 'company_facebook_page',
  },
  {
    label: 'LinkedIn Page',
    value: 'company_linkedin_page',
  },
  {
    label: 'Twitter Page',
    value: 'company_twitter_page',
  },
];

export const EXPORT_COMPANY_FIELD_MAPPING = [
  { label: 'Name', value: 'company_name' },
  { label: 'Website', value: 'company_website' },
  { label: 'Phone Number', value: 'company_phone_numbers' },
  { label: 'City', value: 'company_city' },
  { label: 'State', value: 'company_state' },
  { label: 'Country', value: 'company_country' },
  { label: 'Street Address', value: 'company_street_address' },
  { label: 'Sector', value: 'company_sector' },
  { label: 'Industry', value: 'company_industry' },
  { label: 'Size', value: 'company_size' },
  { label: 'Fiscal Year', value: 'fiscal_year' },
  { label: 'Job Count', value: 'job_count' },
  { label: 'Products & Services', value: 'company_products_services' },
  {
    label: 'Funding Range',
    value: 'company_funding_range',
  },
  { label: 'Revenue Range', value: 'revenue_range' },
  { label: 'EBITDA Range', value: 'ebitda_range' },
  { label: 'Address', value: 'company_address' },
  { label: 'Zip Code', value: 'zip_code' },
];

export const EXPORT_LEAD_FIELD_MAPPINGS = [
  { label: 'Name', value: 'name' },
  { label: 'Email', value: 'email' },
  { label: 'Phone Number', value: 'phone' },
  { label: 'Work Phone', value: 'work_phone' },
  { label: 'City', value: 'city' },
  { label: 'State', value: 'state' },
  { label: 'Country', value: 'country' },
  { label: 'Division', value: 'lead_divison' },
  { label: 'Function', value: 'company_function' },
  { label: 'People Title', value: 'lead_titles' },
  { label: 'Seniority Level', value: 'seniority_level' },
  {
    label: 'Decision-Making power',
    value: 'decision_making_power',
  },
  { label: 'LinkedIn URL', value: 'linkedin_url' },
  { label: 'Skills', value: 'skills' },
  { label: 'Interests', value: 'interests' },
  { label: 'Email Confidence', value: 'email_score' },
];

export const LEAD_COMPANY_FIELD_MAPPINGS_EXTRA = [{ label: 'Company Type', value: 'company_type' }];

export const MAPPINGS_DRAWER_FIELDS = ['keywords_mapper', 'technologies_mapper'];
export const PHONE_PRIORITY_MAPPINGS = [
  {
    key: '1',
    type: 'Phone',
    value: 'phone',
  },
  {
    key: '2',
    type: 'Work Phone',
    value: 'work_phone',
  },
];
export const HUBSPOT_EXCLUDE_FIELDS = ['slintel_created_by', 'slintel_last_updated_by'];
export const KB_ARTICLES = {
  outreach: 'https://slintel.freshdesk.com/support/solutions/folders/44001226666',
};
export const PUSHER_CONSTANT = {
  CSV_EXPORT_STATUS_TECHNOLOGY: 'csv-export-status-technology',
  CSV_EXPORT_STATUS_LEAD: 'csv-export-status-lead',
  CSV_EXPORT_STATUS_COMPANY: 'csv-export-status-company',
  CSV_EXPORT_STATUS_COMPANY_V2: 'export-status',
  SF_EXPORT_STATUS_LEAD: 'sf-export-status-lead',
  CSV_EXPORT_STATUS_ENRICH_COMPANY: 'csv-export-status-enrich-company',
  CSV_EXPORT_STATUS_ENRICH_LEAD: 'csv-export-status-enrich-lead',
  ADD_LIST_ITEM: 'adding-list-item',
};

export const JOB_PAGE_FILTERS = {
  company_funding_range: 'company_funding_range',
  // company_latest_funding_date: 'company_latest_funding_date', // Removed as per feedback from product
  company_location: 'company_location',
  company_name: 'company_name_keyword',
  company_products_services: 'company_products_services',
  company_size: 'company_size',
  fiscal_year: 'fiscal_year',
  keywords: 'keywords',
  max_job_count: 'max_job_count',
  min_job_count: 'min_job_count',
  revenue_range: 'revenue_range',
  technologies: 'technologies',
  company_sic_code: 'company_sic_code',
  company_naics_code: 'company_naics_code',
  // company_sector: '', // special case handled in - (handleViewJob) function
  // company_industry: '', // special case handled in - (handleViewJob) function
};

export const LEAD_SORTING_KEYS = [
  'Most Relevant',
  'name_keyword',
  'lead_divison',
  'company_name',
  'company_industry',
  'company_sector',
  'company_function',
];

export const LEAD_SORTING_OPTIONS = [
  { label: 'Relevance', key: 'Most Relevant' },
  { label: 'Name', key: 'name_keyword' },
  { label: 'Lead Division', key: 'lead_divison' },
  { label: 'Company Name', key: 'company_name' },
  { label: 'Industry', key: 'company_industry' },
  { label: 'Sector', key: 'company_sector' },
  { label: 'Lead Function', key: 'company_function' },
];

export const COMPANY_SORTING_KEYS = [
  'Most Relevant',
  'company_name_keyword',
  'company_size_key',
  'company_industry',
  'company_sector',
];

export const COMPANY_SORTING_OPTIONS = [
  { label: 'Relevance', key: 'Most Relevant' },
  { label: 'Company Name', key: 'company_name_keyword' },
  { label: 'Employee Size', key: 'company_size_key' },
  { label: 'Industry', key: 'company_industry' },
  { label: 'Sector', key: 'company_sector' },
];

export const SORT_ORDERS = [
  { label: 'Ascending', key: 'asc' },
  { label: 'Descending', key: 'desc' },
];

export const DUMMY_LEAD_DATA = {
  lead_location: {
    value: ['Milwaukee', 'Wisconsin', 'United States'],
  },
  company_industry: {
    value: 'Computer Software',
  },
  phone: {
    value: '+14148075866',
  },
  lead_titles: {
    value: 'Technical Architect',
  },
  company_name: {
    value: 'Adobe',
  },
  company_website: {
    value: 'adobe.com',
  },
  work_phone: {
    value: '',
  },
  name: {
    value: 'Blake Bauman',
  },
  id: {
    value: '5f7d8152bd31992d203717e4',
  },
  email: {
    value: 'bbauman@adobe.com',
  },
  company_size: {
    value: '10k+',
  },
};

export const DUMMY_COMPANY_DATA = {
  company_website: {
    value: 'frontierequitiesvc.com',
  },
  company_name: {
    value: 'Frontier Equities Vc',
  },
  company_profile_image_url: {
    value:
      'https://d3ml3b6vywsj0z.cloudfront.net/company_images/5c3b0363d55ae49f1b7ac693_images.png',
  },
  company_location: {
    value: ['New York', 'New York', 'United States'],
  },
  id: {
    value: '5c3b0363d55ae49f1b7ac693',
  },
  company_size: {
    value: 'Unknown',
  },
};

export const REPORT_FLAG_COMPANIES = {
  company_name: 'Company Name',
  company_description: 'Company Description',
  company_phone_number: 'Company Phone Number',
  company_city: 'Company City',
  company_state: 'Company State',
  company_country: 'Company Country',
  company_website: 'Company Website',
  technologies: 'Company Technology Used',
  keywords: 'Company Keywords Used',
  company_products_services: 'Company Product and Services',
  company_profile_image_url: 'Company Image',
  company_size: 'Company Size',
  company_type: 'Company Type',
  company_industry: 'Company Industry',
  company_sector: 'Company Sector',
  fiscal_year: 'Company Fiscal Year',
  revenue_range: 'Company Revenue',
  other: 'Other',
};

export const REPORT_FLAG_LEADS = {
  name: 'Name',
  lead_titles: 'Designation',
  email: 'Email',
  city: 'City',
  state: 'State',
  country: 'Country',
  phone: 'Phone',
  linkedin_url: 'LinkedIn URL',
  lead_divison: 'Lead Division',
  company_function: 'Lead Function',
  decision_making_power: 'Decision Making Power',
  company_name: 'Company Name',
  company_description: 'Company Description',
  company_phone_number: 'Company Phone Number',
  company_city: 'Company City',
  company_state: 'Company State',
  company_country: 'Company Country',
  company_website: 'Company Website',
  technologies: 'Company Technology Used',
  keywords: 'Company Keywords Used',
  company_products_services: 'Company Product and Services',
  company_profile_image_url: 'Company Image',
  company_size: 'Company Size',
  company_type: 'Company Type',
  company_industry: 'Company Industry',
  company_sector: 'Company Sector',
  fiscal_year: 'Company Fiscal Year',
  revenue_range: 'Company Revenue',
  other: 'Other',
};

export const LOCATION_INTERNATIONAL_MAPPER = {
  'North America': [
    'Barbados',
    'Canada',
    'Cayman Islands',
    'El Salvador',
    'United States',
    'Dominican Republic',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Panama',
    'Saint Vincent and the Grenadines',
    'Antigua and Barbuda',
    'Bahamas',
    'Costa Rica',
    'Cuba',
    'Mexico',
    'Nicaragua',
    'Guatemala',
    'Puerto Rico',
    'Honduras',
    'Saint Pierre and Miquelon',
    'Martinique',
    'Trinidad and Tobago',
    'U.S. Virgin Islands',
    'Bermuda',
    'Dominica',
    'Grenada',
    'Greenland',
    'Guadeloupe',
    'Haiti',
    'Jamaica',
    'Montserrat',
    'Anguilla',
    'Belize',
    'Saint Barthelemy',
    'Sint Maarten',
    'Saint Martin',
    'Turks and Caicos Islands',
    'British Virgin Islands',
    'United States Minor Outlying Islands',
    'Netherlands Antilles',
  ],
  'South America': [
    'Bonaire, Saint Eustatius and Saba',
    'Brazil',
    'Guyana',
    'Peru',
    'Uruguay',
    'Chile',
    'French Guiana',
    'Suriname',
    'Ecuador',
    'Venezuela',
    'Paraguay',
    'Colombia',
    'Argentina',
    'Bolivia',
    'Curacao',
    'Falkland Islands',
    'Aruba',
  ],
  Asia: [
    'Bangladesh',
    'Bahrain',
    'Iran',
    'East Timor',
    'Azerbaijan',
    'Jordan',
    'Mongolia',
    'Maldives',
    'Philippines',
    'Turkmenistan',
    'Turkey',
    'Uzbekistan',
    'Yemen',
    'Armenia',
    'Bhutan',
    "People's Republic of China",
    'Iraq',
    'Israel',
    'Kazakhstan',
    'Laos',
    'Lebanon',
    'Malaysia',
    'Singapore',
    'Syria',
    'Thailand',
    'Tajikistan',
    'Taiwan',
    'Brunei',
    'Cyprus',
    'Korea',
    'Sri Lanka',
    'Nepal',
    'Kuwait',
    'Pakistan',
    'Saudi Arabia',
    'United Arab Emirates',
    'Georgia',
    'Hong Kong',
    'Japan',
    'Kyrgyzstan',
    'Cambodia',
    'Qatar',
    'Afghanistan',
    'Indonesia',
    'India',
    'Myanmar',
    'Oman',
    'Palestinian Territory',
    'Vietnam',
    'Christmas Island',
    'British Indian Ocean Territory',
    'Cocos Islands',
  ],
  Europe: [
    'Belarus',
    'Estonia',
    'Spain',
    'Guernsey',
    'Latvia',
    'Portugal',
    'Ukraine',
    'Faroe Islands',
    'Isle of Man',
    'Iceland',
    'Netherlands',
    'Russia',
    'Switzerland',
    'Macedonia',
    'Poland',
    'Romania',
    'Svalbard and Jan Mayen',
    'Albania',
    'Bosnia and Herzegovina',
    'Belgium',
    'United Kingdom',
    'Ireland',
    'San Marino',
    'Aland Islands',
    'Bulgaria',
    'Liechtenstein',
    'Malta',
    'Norway',
    'Serbia',
    'Austria',
    'Czech Republic',
    'Greece',
    'Croatia',
    'Lithuania',
    'Slovenia',
    'Slovakia',
    'Denmark',
    'Luxembourg',
    'Moldova',
    'Sweden',
    'Andorra',
    'Germany',
    'Finland',
    'France',
    'Hungary',
    'Italy',
    'Jersey',
    'Monaco',
    'Montenegro',
    'Kosovo',
    'Vatican',
    'Serbia and Montenegro',
    'Gibraltar',
  ],
  Oceania: [
    'Fiji',
    'Northern Mariana Islands',
    'French Polynesia',
    'Kiribati',
    'New Zealand',
    'Marshall Islands',
    'American Samoa',
    'Wallis and Futuna',
    'Samoa',
    'Guam',
    'New Caledonia',
    'Palau',
    'Solomon Islands',
    'Tuvalu',
    'Papua New Guinea',
    'Tonga',
    'Vanuatu',
    'Australia',
    'Cook Islands',
    'Micronesia',
    'Nauru',
    'Pitcairn',
    'Norfolk Island',
    'Tokelau',
    'Niue',
  ],
  Africa: [
    'Equatorial Guinea',
    'Guinea-Bissau',
    'Tanzania',
    'Angola',
    'Cape Verde',
    'Egypt',
    'Ethiopia',
    'Ghana',
    'Libya',
    'Mauritania',
    'Niger',
    'Mayotte',
    'Zimbabwe',
    'Burkina Faso',
    'Djibouti',
    'Algeria',
    'Gabon',
    'Kenya',
    'Mali',
    'Malawi',
    'Sao Tome and Principe',
    'Chad',
    'Togo',
    'Uganda',
    'Benin',
    'Ivory Coast',
    'Liberia',
    'Mozambique',
    'Namibia',
    'Seychelles',
    'Tunisia',
    'Burundi',
    'Republic of the Congo',
    'Eritrea',
    'Gambia',
    'Madagascar',
    'Rwanda',
    'Sudan',
    'Swaziland',
    'Zambia',
    'Cameroon',
    'Guinea',
    'Nigeria',
    'South Africa',
    'Botswana',
    'Democratic Republic of the Congo',
    'Comoros',
    'Lesotho',
    'Mauritius',
    'Senegal',
    'South Sudan',
    'Central African Republic',
    'Morocco',
    'Reunion',
    'Sierra Leone',
    'Somalia',
    'Saint Helena',
    'Western Sahara',
  ],
  Antarctica: [
    'South Georgia and the South Sandwich Islands',
    'Bouvet Island',
    'French Southern Territories',
    'Antarctica',
    'Heard Island and McDonald Islands',
  ],
};

export const LOCATION_REGIONS_MAPPER = {
  APAC: [
    'Afghanistan',
    'Australia',
    'Bangladesh',
    'Bhutan',
    'Brunei',
    'Cambodia',
    "People's Republic of China",
    'East Timor',
    'Micronesia',
    'Fiji',
    'Hong Kong',
    'India',
    'Indonesia',
    'Japan',
    'Kiribati',
    'Laos',
    'Malaysia',
    'Maldives',
    'Marshall Islands',
    'Mongolia',
    'Myanmar',
    'Nauru',
    'Nepal',
    'New Zealand',
    'Pakistan',
    'Palau',
    'Papua New Guinea',
    'Philippines',
    'Samoa',
    'Singapore',
    'Solomon Islands',
    'Korea',
    'Sri Lanka',
    'Taiwan',
    'Thailand',
    'Tonga',
    'Tuvalu',
    'Vanuatu',
    'Vietnam',
  ],
  'Middle East': [
    'Bahrain',
    'Cyprus',
    'Egypt',
    'Iran',
    'Iraq',
    'Israel',
    'Jordan',
    'Kuwait',
    'Lebanon',
    'Oman',
    'Palestinian Territory',
    'Qatar',
    'Saudi Arabia',
    'Syria',
    'Turkey',
    'United Arab Emirates',
    'Yemen',
  ],
  Carribean: [
    'Anguilla',
    'Antigua And Barbuda',
    'Aruba',
    'Bahamas',
    'Barbados',
    'Belize',
    'Bonaire, Saint Eustatius and Saba',
    'British Virgin Islands',
    'Cayman Islands',
    'Dominica',
    'Cuba',
    'Curacao',
    'Dominican Republic',
    'Venezuela',
    'Grenada',
    'Guadeloupe',
    'Haiti',
    'Jamaica',
    'Martinique',
    'Montserrat',
    'Puerto Rico',
    'Saint Barthelemy',
    'Saint Kitts And Nevis',
    'Saint Lucia',
    'Saint Martin',
    'Sint Maarten',
    'Trinidad And Tobago',
    'Turks And Caicos Islands',
    'U.S. Virgin Islands',
  ],
  'Latin America': [
    'Barbados',
    'Brazil',
    'El Salvador',
    'Dominican Republic',
    'Guyana',
    'Saint Lucia',
    'Panama',
    'Peru',
    'Uruguay',
    'Antigua and Barbuda',
    'Bahamas',
    'Chile',
    'Costa Rica',
    'Cuba',
    'Mexico',
    'Nicaragua',
    'Suriname',
    'Guatemala',
    'Ecuador',
    'Honduras',
    'Venezuela',
    'Paraguay',
    'Trinidad and Tobago',
    'Colombia',
    'Dominica',
    'Grenada',
    'Haiti',
    'Jamaica',
    'Argentina',
    'Bolivia',
    'Belize',
    'Saint Vincent and the Grenadines',
    'Saint Kitts and Nevis',
  ],
  EMEA: [
    'Bahrain',
    'Belarus',
    'Estonia',
    'Spain',
    'Guernsey',
    'Equatorial Guinea',
    'Guinea-Bissau',
    'Iran',
    'Latvia',
    'Portugal',
    'Tanzania',
    'Ukraine',
    'Angola',
    'Cape Verde',
    'Egypt',
    'Ethiopia',
    'Faroe Islands',
    'Ghana',
    'Isle of Man',
    'Iceland',
    'Jordan',
    'Libya',
    'Mauritania',
    'Niger',
    'Netherlands',
    'Turkey',
    'Yemen',
    'Zimbabwe',
    'Burkina Faso',
    'Switzerland',
    'Djibouti',
    'Algeria',
    'Gabon',
    'Iraq',
    'Kenya',
    'Macedonia',
    'Mali',
    'Malawi',
    'Poland',
    'Romania',
    'Chad',
    'Togo',
    'Uganda',
    'Albania',
    'Bosnia and Herzegovina',
    'Belgium',
    'Benin',
    'Ivory Coast',
    'United Kingdom',
    'Ireland',
    'Israel',
    'Lebanon',
    'Liberia',
    'Mozambique',
    'Namibia',
    'San Marino',
    'Syria',
    'Tunisia',
    'Bulgaria',
    'Burundi',
    'Cyprus',
    'Eritrea',
    'Gambia',
    'Liechtenstein',
    'Madagascar',
    'Malta',
    'Norway',
    'Serbia',
    'Rwanda',
    'Sudan',
    'Swaziland',
    'Zambia',
    'Austria',
    'Cameroon',
    'Czech Republic',
    'Guinea',
    'Greece',
    'Croatia',
    'Kuwait',
    'Lithuania',
    'Nigeria',
    'Saudi Arabia',
    'Slovenia',
    'Slovakia',
    'South Africa',
    'United Arab Emirates',
    'Botswana',
    'Democratic Republic of the Congo',
    'Denmark',
    'Georgia',
    'Comoros',
    'Lesotho',
    'Luxembourg',
    'Moldova',
    'Mauritius',
    'Qatar',
    'Sweden',
    'Senegal',
    'Andorra',
    'Central African Republic',
    'Germany',
    'Finland',
    'France',
    'Hungary',
    'Italy',
    'Jersey',
    'Morocco',
    'Monaco',
    'Montenegro',
    'Oman',
    'Somalia',
    'Western Sahara',
    'Gibraltar',
    'Palestinian Territory',
    'Sao Tome and Principe',
    'Vatican',
  ],
};

export const SF_LEAD_EXPORT_TYPE = {
  leads: 1,
  contacts: 2,
  both: 3,
};

export const DELAY_MS = 100;
export const DEFAULT_ERROR_MSG =
  'Internal Server Error - Please contact your customer success manager';
export const TEXT_LABELS = {
  keyword: 'psychographics',
  Keyword: 'Psychographics',
  lead: 'people',
};

export const SALESFORCE_FIELDS = {
  lead_fields: [
    {
      label: 'First Name',
      field_value: 'first_name',
      field_example: 'John',
      isMandatory: false,
    },
    {
      label: 'Last Name',
      field_value: 'last_name',
      field_example: 'Doe',
      isMandatory: true,
    },
    {
      label: 'Email',
      field_value: 'email',
      field_example: 'youremail@example.com',
      isMandatory: true,
    },
    {
      label: 'Phone Number',
      field_value: 'phone',
      field_example: '+14158435757',
      isMandatory: false,
      slintel_field_meta: PHONE_PRIORITY_META,
    },
    {
      label: 'City',
      field_value: 'city',
      field_example: 'San Francisco',
      isMandatory: false,
    },
    {
      label: 'State',
      field_value: 'state',
      field_example: 'California',
      isMandatory: false,
    },
    {
      label: 'Country',
      field_value: 'country',
      field_example: 'United States',
      isMandatory: false,
    },
    {
      label: 'Job Title',
      field_value: 'lead_titles',
      field_example: 'Marketing Manager',
      isMandatory: false,
    },
    {
      label: 'Divison',
      field_value: 'lead_divison',
      field_example: 'HR',
      isMandatory: false,
    },
    {
      label: 'Decision Making Power',
      field_value: 'decision_making_power',
      field_example: 'High',
      isMandatory: false,
    },
    // {
    //   label: 'Seniority Level',
    //   field_value: 'seniority_level',
    //   field_example: 'Director',
    //   isMandatory: false,
    // },
    {
      label: 'Linkedin URL',
      field_value: 'linkedin_url',
      field_example: 'https://www.linkedin.com/in/johndoe08/',
      isMandatory: false,
    },
    {
      label: 'Skills',
      field_value: 'skills',
      field_example: 'Apache, Scala',
      isMandatory: false,
    },
    {
      label: 'Interests',
      field_value: 'interests',
      field_example: 'ERP, Java',
      isMandatory: false,
    },
    {
      label: 'Company Name',
      field_value: 'company_name',
      field_example: 'Microsoft',
      isMandatory: true,
    },
    // {
    //   label: "Company Description",
    //   field_value: "company_description",
    //   field_example: "Slintel, the leader in capturing technographics-powered buying intent, helps companies uncover the 3% of active buyers in their target market...",
    //   isMandatory: false,
    // },
    {
      label: 'Company Website',
      field_value: 'company_website',
      field_example: 'https://www.microsoft.com/',
      isMandatory: false,
    },
    {
      label: 'Company Phone Number',
      field_value: 'company_phone_numbers',
      field_example: '+14158435757',
      isMandatory: false,
    },
    {
      label: 'Company City',
      field_value: 'company_city',
      field_example: 'New York City',
      isMandatory: false,
    },
    {
      label: 'Company State',
      field_value: 'company_state',
      field_example: 'New York',
      isMandatory: false,
    },
    {
      label: 'Company Country',
      field_value: 'company_country',
      field_example: 'United States',
      isMandatory: false,
    },
    // {
    //   label: "Company Street Address",
    //   field_value: "company_street_address",
    //   field_example: "465 N Whisman Rd",
    //   isMandatory: false,
    // },
    {
      label: 'Company Type',
      field_value: 'company_type',
      field_example: 'Private',
      isMandatory: false,
    },
    {
      label: 'Company Function',
      field_value: 'company_function',
      field_example: 'Operations ',
      isMandatory: false,
    },
    {
      label: 'Company Sector',
      field_value: 'company_sector',
      field_example: 'Retail and CPG',
      isMandatory: false,
    },
    {
      label: 'Company Industry',
      field_value: 'company_industry',
      field_example: 'Apparel',
      isMandatory: false,
    },
    {
      label: 'Company Size',
      field_value: 'company_size',
      field_example: '201-500',
      isMandatory: false,
    },
    {
      label: 'Company Fiscal Year',
      field_value: 'fiscal_year',
      field_example: 'Jan-Dec',
      isMandatory: false,
    },
    {
      label: 'Company Funding Range',
      field_value: 'company_funding_range',
      field_example: '10M-50M',
      isMandatory: false,
    },
    {
      label: 'Company Revenue Range',
      field_value: 'revenue_range',
      field_example: '50M-100M',
      isMandatory: false,
    },
    {
      label: 'Company EBITDA Range',
      field_value: 'ebitda_range',
      field_example: '100M-500M',
      isMandatory: false,
    },
    // {
    //   label: "Slintel Lead Url",
    //   field_value: "slintel_lead_url",
    //   field_example: "https://dashboard.slintel.com/#/lead-details/5dc164616d4235090c39736b",
    //   isMandatory: false,
    // },
    {
      label: 'Company Facebook URL',
      field_value: 'company_facebook_page',
      field_example: 'https://www.facebook.com/ExampleInc/',
      isMandatory: false,
    },
    {
      label: 'Company Twitter URL',
      field_value: 'company_twitter_page',
      field_example: 'https://twitter.com/ExampleInc/',
      isMandatory: false,
    },
    {
      label: 'Company LinkedIn URL',
      field_value: 'company_linkedin_page',
      field_example: 'https://www.linkedin.com/company/ExampleInc/',
      isMandatory: false,
    },
    // {
    //   label: "Slintel Company Url",
    //   field_value: "slintel_company_url",
    //   field_example: "https://dashboard.slintel.com/#/company-details/5c3b016dd55ae49f1b77d266",
    //   isMandatory: false,
    // },
    {
      label: 'Company Product And Services',
      field_value: 'company_products_services',
      field_example: 'Photo Sharing, Publishing, Social Media',
      isMandatory: false,
    },
    {
      label: 'Company Job Count',
      field_value: 'job_count',
      field_example: '45',
      isMandatory: false,
    },
    {
      label: 'Company SIC Code',
      field_value: 'company_sic_code',
      field_example: '2600,2621,2631',
      isMandatory: false,
    },
    {
      label: 'Company NAICS Code',
      field_value: 'company_naics_code',
      field_example: '522320,541850',
      isMandatory: false,
    },
    {
      label: 'Email Confidence Score',
      field_value: 'email_score',
      field_example: 'High',
      isMandatory: false,
    },
    {
      label: 'Created By',
      field_value: 'created_by',
      field_example: 'email@example.com',
      isMandatory: false,
    },
    {
      label: 'Technologies',
      field_value: 'technologies_mapper',
      field_example: 'Salesforce CRM(Contact Management)',
      isMandatory: false,
      slintel_field_meta: TECHNOLOGY_META,
    },
    {
      label: 'Psychographics',
      field_value: 'keywords_mapper',
      field_example: 'Salesforce CRM(Contact Management)',
      isMandatory: false,
      slintel_field_meta: PYSCHOGRAPHICS_META,
    },
    {
      label: 'People Id',
      field_value: '_id',
      field_example: '6280d86db523ac08d69b960e',
      isMandatory: false,
    },
    {
      label: 'Company Id',
      field_value: 'company_id',
      field_example: '5c3b00b3d55ae49f1b76d475',
      isMandatory: false,
    },
    {
      label: 'Slintel Updated Type',
      field_value: 'slintel_updated_type',
      field_example: 'Slintel Pushed',
      isMandatory: false,
    },
    {
      label: 'Last Slintel Updated At',
      field_value: 'last_slintel_updated',
      field_example: '07/15/2021',
      isMandatory: false,
    },
  ],
  contact_fields: [
    {
      label: 'First Name',
      field_value: 'first_name',
      field_example: 'John',
      isMandatory: false,
    },
    {
      label: 'Last Name',
      field_value: 'last_name',
      field_example: 'Doe',
      isMandatory: true,
    },
    {
      label: 'Email',
      field_value: 'email',
      field_example: 'youremail@example.com',
      isMandatory: true,
    },
    {
      label: 'Email Confidence Score',
      field_value: 'email_score',
      field_example: 'High',
      isMandatory: false,
    },
    {
      label: 'Phone Number',
      field_value: 'phone',
      field_example: '+14158435757',
      isMandatory: false,
      slintel_field_meta: PHONE_PRIORITY_META,
    },
    {
      label: 'City',
      field_value: 'city',
      field_example: 'San Francisco',
      isMandatory: false,
    },
    {
      label: 'State',
      field_value: 'state',
      field_example: 'California',
      isMandatory: false,
    },
    {
      label: 'Country',
      field_value: 'country',
      field_example: 'United States',
      isMandatory: false,
    },
    {
      label: 'Job Title',
      field_value: 'lead_titles',
      field_example: 'Marketing Manager',
      isMandatory: false,
    },
    {
      label: 'Divison',
      field_value: 'lead_divison',
      field_example: 'HR',
      isMandatory: false,
    },
    {
      label: 'Decision Making Power',
      field_value: 'decision_making_power',
      field_example: 'High',
      isMandatory: false,
    },
    // {
    //   label: 'Seniority Level',
    //   field_value: 'seniority_level',
    //   field_example: 'Director',
    //   isMandatory: false,
    // },
    {
      label: 'Linkedin URL',
      field_value: 'linkedin_url',
      field_example: 'https://www.linkedin.com/in/johndoe08/',
      isMandatory: false,
    },
    {
      label: 'Skills',
      field_value: 'skills',
      field_example: 'Apache, Scala',
      isMandatory: false,
    },
    {
      label: 'Interests',
      field_value: 'interests',
      field_example: 'ERP, Java',
      isMandatory: false,
    },
    {
      label: 'Company Name',
      field_value: 'company_name',
      field_example: 'Microsoft',
      isMandatory: false,
    },
    // {
    //   label: "Company Description",
    //   field_value: "company_description",
    //   field_example: "Slintel, the leader in capturing technographics-powered buying intent, helps companies uncover the 3% of active buyers in their target market...",
    //   isMandatory: false,
    // },
    {
      label: 'Company Website',
      field_value: 'company_website',
      field_example: 'https://www.microsoft.com/',
      isMandatory: false,
    },
    {
      label: 'Company Phone Number',
      field_value: 'company_phone_numbers',
      field_example: '+14158435757',
      isMandatory: false,
    },
    {
      label: 'Company City',
      field_value: 'company_city',
      field_example: 'New York City',
      isMandatory: false,
    },
    {
      label: 'Company State',
      field_value: 'company_state',
      field_example: 'New York',
      isMandatory: false,
    },
    {
      label: 'Company Country',
      field_value: 'company_country',
      field_example: 'United States',
      isMandatory: false,
    },
    // {
    //   label: "Company Street Address",
    //   field_value: "company_street_address",
    //   field_example: "465 N Whisman Rd",
    //   isMandatory: false,
    // },
    {
      label: 'Company Type',
      field_value: 'company_type',
      field_example: 'Private',
      isMandatory: false,
    },
    {
      label: 'Company Function',
      field_value: 'company_function',
      field_example: 'Operations ',
      isMandatory: false,
    },
    {
      label: 'Company Sector',
      field_value: 'company_sector',
      field_example: 'Retail and CPG',
      isMandatory: false,
    },
    {
      label: 'Company Industry',
      field_value: 'company_industry',
      field_example: 'Apparel',
      isMandatory: false,
    },
    {
      label: 'Company Size',
      field_value: 'company_size',
      field_example: '201-500',
      isMandatory: false,
    },
    {
      label: 'Company Fiscal Year',
      field_value: 'fiscal_year',
      field_example: 'Jan-Dec',
      isMandatory: false,
    },
    {
      label: 'Company Funding Range',
      field_value: 'company_funding_range',
      field_example: '10M-50M',
      isMandatory: false,
    },
    {
      label: 'Company Revenue Range',
      field_value: 'revenue_range',
      field_example: '50M-100M',
      isMandatory: false,
    },
    {
      label: 'Company EBITDA Range',
      field_value: 'ebitda_range',
      field_example: '100M-500M',
      isMandatory: false,
    },
    // {
    //   label: "Slintel Lead Url",
    //   field_value: "slintel_lead_url",
    //   field_example: "https://dashboard.slintel.com/#/lead-details/5dc164616d4235090c39736b",
    //   isMandatory: false,
    // },
    {
      label: 'Company Facebook URL',
      field_value: 'company_facebook_page',
      field_example: 'https://www.facebook.com/ExampleInc/',
      isMandatory: false,
    },
    {
      label: 'Company Twitter URL',
      field_value: 'company_twitter_page',
      field_example: 'https://twitter.com/ExampleInc/',
      isMandatory: false,
    },
    {
      label: 'Company LinkedIn URL',
      field_value: 'company_linkedin_page',
      field_example: 'https://www.linkedin.com/company/ExampleInc/',
      isMandatory: false,
    },
    // {
    //   label: "Slintel Company Url",
    //   field_value: "slintel_company_url",
    //   field_example: "https://dashboard.slintel.com/#/company-details/5c3b016dd55ae49f1b77d266",
    //   isMandatory: false,
    // },
    {
      label: 'Company Product And Services',
      field_value: 'company_products_services',
      field_example: 'Photo Sharing, Publishing, Social Media',
      isMandatory: false,
    },
    {
      label: 'Company Job Count',
      field_value: 'job_count',
      field_example: '45',
      isMandatory: false,
    },
    {
      label: 'Company SIC Code',
      field_value: 'company_sic_code',
      field_example: '2600,2621,2631',
      isMandatory: false,
    },
    {
      label: 'Company NAICS Code',
      field_value: 'company_naics_code',
      field_example: '522320,541850',
      isMandatory: false,
    },
    {
      label: 'Email Confidence Score',
      field_value: 'email_score',
      field_example: 'High',
      isMandatory: false,
    },
    {
      label: 'Created By',
      field_value: 'created_by',
      field_example: 'email@example.com',
      isMandatory: false,
    },
    {
      label: 'Technologies',
      field_value: 'technologies_mapper',
      field_example: 'Salesforce CRM(Contact Management)',
      isMandatory: false,
      slintel_field_meta: TECHNOLOGY_META,
    },
    {
      label: 'Psychographics',
      field_value: 'keywords_mapper',
      field_example: 'Salesforce CRM(Contact Management)',
      isMandatory: false,
      slintel_field_meta: PYSCHOGRAPHICS_META,
    },
    {
      label: 'People Id',
      field_value: '_id',
      field_example: '6280d86db523ac08d69b960e',
      isMandatory: false,
    },
    {
      label: 'Company Id',
      field_value: 'company_id',
      field_example: '5c3b00b3d55ae49f1b76d475',
      isMandatory: false,
    },
    {
      label: 'Slintel Updated Type',
      field_value: 'slintel_updated_type',
      field_example: 'Slintel Pushed',
      isMandatory: false,
    },
    {
      label: 'Last Slintel Updated At',
      field_value: 'last_slintel_updated',
      field_example: '07/15/2021',
      isMandatory: false,
    },
  ],
  account_fields: [
    {
      label: 'Company Name',
      field_value: 'company_name',
      field_example: 'Microsoft',
      isMandatory: true,
    },
    // {
    //   label: "Company Description",
    //   field_value: "company_description",
    //   field_example: "Slintel, the leader in capturing technographics-powered buying intent, helps companies uncover the 3% of active buyers in their target market...",
    //   isMandatory: false,
    // },
    {
      label: 'Company Website',
      field_value: 'company_website',
      field_example: 'https://www.microsoft.com/',
      isMandatory: true,
    },
    {
      label: 'Company Phone Number',
      field_value: 'company_phone_number',
      field_example: '+14158435757',
      isMandatory: false,
    },
    // {
    //   label: "Company Street Address",
    //   field_value: "company_street_address",
    //   field_example: "465 N Whisman Rd",
    //   isMandatory: false,
    // },
    {
      label: 'Company City',
      field_value: 'company_city',
      field_example: 'New York City',
      isMandatory: false,
    },
    {
      label: 'Company State',
      field_value: 'company_state',
      field_example: 'New York',
      isMandatory: false,
    },
    {
      label: 'Company Country',
      field_value: 'company_country',
      field_example: 'United States',
      isMandatory: false,
    },
    {
      label: 'Company Type',
      field_value: 'company_type',
      field_example: 'Private',
      isMandatory: false,
    },
    {
      label: 'Company Sector',
      field_value: 'company_sector',
      field_example: 'Retail and CPG',
      isMandatory: false,
    },
    {
      label: 'Company Industry',
      field_value: 'company_industry',
      field_example: 'Apparel',
      isMandatory: false,
    },
    {
      label: 'Company Size',
      field_value: 'company_size',
      field_example: '201-500',
      isMandatory: false,
    },
    {
      label: 'Company Job Count',
      field_value: 'job_count',
      field_example: '45',
      isMandatory: false,
    },
    {
      label: 'Company SIC Code',
      field_value: 'company_sic_code',
      field_example: '2600,2621,2631',
      isMandatory: false,
    },
    {
      label: 'Company NAICS Code',
      field_value: 'company_naics_code',
      field_example: '522320,541850',
      isMandatory: false,
    },
    {
      label: 'Company Fiscal Year',
      field_value: 'fiscal_year',
      field_example: 'Jan-Dec',
      isMandatory: false,
    },
    {
      label: 'Company Founded Year',
      field_value: 'company_founded_at',
      field_example: '1962',
      isMandatory: false,
    },
    {
      label: 'Company Funding Range',
      field_value: 'company_funding_range',
      field_example: '10M-50M',
      isMandatory: false,
    },
    {
      label: 'Company Revenue Range',
      field_value: 'revenue_range',
      field_example: '50M-100M',
      isMandatory: false,
    },
    {
      label: 'Company EBITDA Range',
      field_value: 'ebitda_range',
      field_example: '100M-500M',
      isMandatory: false,
    },
    // {
    //   label: "Slintel Company Url",
    //   field_value: "slintel_company_url",
    //   field_example: "https://dashboard.slintel.com/#/company-details/5c3b016dd55ae49f1b77d266",
    //   isMandatory: false,
    // },
    {
      label: 'Company Facebook URL',
      field_value: 'company_facebook_page',
      field_example: 'https://www.facebook.com/ExampleInc/',
      isMandatory: false,
    },
    {
      label: 'Company Twitter URL',
      field_value: 'company_twitter_page',
      field_example: 'https://twitter.com/ExampleInc/',
      isMandatory: false,
    },
    {
      label: 'Company LinkedIn URL',
      field_value: 'company_linkedin_page',
      field_example: 'https://www.linkedin.com/company/ExampleInc/',
      isMandatory: false,
    },
    {
      label: 'Company Product And Services',
      field_value: 'company_products_services',
      field_example: 'Photo Sharing, Publishing, Social Media',
      isMandatory: false,
    },
    {
      label: 'Company Address',
      field_value: 'company_address',
      field_example: '839 St. Charles Ave. Suite 305',
      isMandatory: false,
    },
    {
      label: 'Postal Code',
      field_value: 'postal_code',
      field_example: '70130',
      isMandatory: false,
    },
    {
      label: 'Created By',
      field_value: 'created_by',
      field_example: 'email@example.com',
      isMandatory: false,
    },
    {
      label: 'Technologies',
      field_value: 'technologies_mapper',
      field_example: 'Salesforce CRM(Contact Management)',
      isMandatory: false,
      slintel_field_meta: TECHNOLOGY_META,
    },
    {
      label: 'Psychographics',
      field_value: 'keywords_mapper',
      field_example: 'Salesforce CRM(Contact Management)',
      isMandatory: false,
      slintel_field_meta: PYSCHOGRAPHICS_META,
    },
    {
      label: 'Company Id',
      field_value: 'company_id',
      field_example: '5c3b00b3d55ae49f1b76d475',
      isMandatory: false,
    },
    {
      label: 'Slintel Updated Type',
      field_value: 'slintel_updated_type',
      field_example: 'Slintel Pushed',
      isMandatory: false,
    },
    {
      label: 'Last Slintel Updated At',
      field_value: 'last_slintel_updated',
      field_example: '',
      isMandatory: false,
    },
  ],
  update_options: [
    {
      label: 'Overwrite',
      value: 0,
    },
    {
      label: 'Fill If Blank',
      value: 1,
    },
    {
      label: 'A+ Email Confidence',
      value: 2,
    },
    {
      label: 'A and A+ Email Confidence',
      value: 3,
    },
  ],
};

export const ENRICH_OPTIONS = [
  { label: 'Lead', value: 1 },
  { label: 'Contact', value: 2 },
  { label: 'Account', value: 3 },
];

export const HUBSPOT_INTEGRATION = {
  CRM_NAME: 'Hubspot',
  CRM_NAME_KEY: 'hubspot',
  LEARN_MORE_LINK: 'https://slintel.freshdesk.com/support/solutions/folders/44001222797',
  CONFIG_SETTINGS_OPTIONS: [{ label: 'Contact', value: 2 }],
  AUTH_SUCCESS_MSG: 'Your Hubspot integration has been authenticated.',
  AUTH_TITLE: 'Hubspot Authentication',
  ACCOUNT_SUCCESS_MSG: 'Account has been disconnected successfully',
  MAPPING_SUCCESS_MSG: 'Mapping Updated Successfully',
  UPDATE_SETTINGS_MSG:
    'This will update settings for all users in your domain. Are you sure you want to proceed?',
};

export const SALESFORCE_INTEGRATION = {
  CRM_NAME: 'Salesforce',
  CRM_NAME_KEY: 'salesforce',
  ACCOUNT_SUCCESS_MSG: 'Account has been disconnected successfully',
  MAPPING_SUCCESS_MSG: 'Mapping Updated Successfully',
  AUTH_SUCCESS_MSG: 'Your Salesforce integration has been authenticated.',
  UPDATE_SETTINGS_MSG:
    'This will update settings for all users in your domain. Are you sure you want to proceed?',
  UPDATE_OPTION_VALIDATION_ERROR_MSG:
    'Update option is mandatory wherever salesforce fields are mapped',
  SLINTEL_LABEL_TOOLTIP:
    'These data points from Slintel need to be mapped to a corresponding Salesforce field to push data seamlessly from Slintel to Salesforce',
  CRM_LABEL_TOOLTIP:
    'Please map the Salesforce fields below to allow data to be pushed from Slintel',
  CONFIGURATIONS: {},
  LEARN_MORE_LINK: 'https://slintel.freshdesk.com/support/solutions/folders/44001203871',
  CONFIG_SETTINGS_OPTIONS: [
    { label: 'Lead', value: 1 },
    { label: 'Contact', value: 2 },
    { label: 'Both', value: 3 },
  ],

  AUTH_TITLE: 'Salesforce Authentication',

  AUTH_DESCRIPTION:
    'Click on the Connect button to authenticate your Salesforce instance. This authentication is Oauth-based and hence highly secure. Don’t worry, your data is in safe hands.',
  VALIDATE_PACKAGE_URL_1:
    'https://login.salesforce.com/packaging/installPackage.apexp?p0=04t2u000000XzFE',
  VALIDATE_PACKAGE_URL_2:
    'https://login.salesforce.com/packaging/installPackage.apexp?p0=04t2u000000XzF4',
  VALIDATION_SUCCESS_MSG: 'Successfully Validated',
  VALIDATE_TITLE: 'Install Custom Field Package (Ignore if already installed)',
  VALIDATE_DESCRIPTION:
    'Slintel adds only three fields to track and  update your Salesforce instance. These fields are used so that we can check records when you update & enrich your data.',
};
export const QUICKVIEW_MODULES = {
  dashboard: 'Dashboard',
  discover: 'Discover',
  smartList: 'Smart List',
  jobPosting: 'JobPosting',
};

export const EXPORT_POPOVER_SOURCE = {
  QUICKVIEW: 'quickview',
  DATAVIEW: 'dataview',
};

export const LEAD = 'lead';
export const COMPANY = 'company';
export const ADD_TO_SMART_LIST = 'Add to Smart List';
export const ADD_TO_LIST = 'ADD_TO_LIST';
export const ACTION_BUTTON = {
  ADD_TO_LIST: 'ADD_TO_LIST',
  EXPORT: 'OPEN_EXPORT',
};
export const EXPORT = 'export';
export const EMPTY_DATA_MSG_FILTERS =
  'Oops! Looks like we don’t have any data that match your filters.Try a different combination of filters to find the data you are looking for.';
export const EMPTY_TECHNOGRAPHIC_DATA = 'We did not find any technographic data for this company.';
export const EMPTY_PSYCHOGRAPHIC_DATA = 'We did not find any psychographic data for this company.';
export const DEFAULT_TECHNOLOGY_PATH = '/technologies/HR/Applicant%20Tracking';
export const DEFAULT_KEYWORD_PATH = '/keywords/HR/Recruitment';

import React from 'react';
import PropTypes from 'prop-types';
import { Space, Tooltip } from 'antd';
import { getClickableLink } from 'utils/common';
import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  CrunchbaseIcon,
  OwlerIcon,
  SocialLinksIcon,
} from 'UI/CustomIcon/CustomIcon';
import scssVariables from 'src/_vars.scss';
import { eventActions } from 'utils/eventManager';

const getSocialLinks = (data, viewName) => {
  switch (viewName) {
    case 'lead':
      return {
        linkedinUrl: data?.linkedin_url?.value,
        twitterUrl: data?.twitter_url?.value,
        facebookUrl: data?.facebook_url?.value,
      };
    case 'lead-detail':
      return {
        companyWebsite: data?.company_website?.value,
        linkedinUrl: data?.linkedin_url?.value,
        twitterUrl: data?.twitter_url?.value,
        facebookUrl: data?.facebook_url?.value,
      };
    case 'company':
    case 'company-detail':
      return {
        linkedinUrl: data?.company_linkedin_page?.value,
        twitterUrl: data?.company_twitter_page?.value,
        facebookUrl: data?.company_facebook_page?.value,
        companyWebsite: data?.company_website?.value,
        crunchBase: data?.company_crunchbase_page?.value,
        owler: data?.owler_url?.value,
      };

    default:
      return {};
  }
};
const MOUSE_ENTER_DELAY_FOR_SOCIAL_LINK_TOOLTIP = 0.3;
const renderIcons = (Icon, link, sendEvent) => {
  if (!link || !Icon) {
    return null;
  }
  return (
    <Tooltip
      mouseEnterDelay={MOUSE_ENTER_DELAY_FOR_SOCIAL_LINK_TOOLTIP}
      placement="bottom"
      title={link}
      key={Icon || link}
    >
      <a
        target="_blank"
        rel="noreferrer"
        href={getClickableLink(link)}
        onClick={() => sendEvent(eventActions.SOCIAL_LINK_CLICKED)}
      >
        <Icon width="15px" fill={scssVariables.grey} className="socialmedia-icon" />
      </a>
    </Tooltip>
  );
};

const SocialLinks = ({ showText, data, viewName, sendEvent, emptyText = null }) => {
  const linksObj = getSocialLinks(data, viewName);
  const anyLinkFound = Object.keys(linksObj).some((linkKey) => !!linksObj[linkKey]);
  if (!anyLinkFound) {
    return emptyText;
  }

  const { companyWebsite, linkedinUrl, twitterUrl, facebookUrl, crunchBase, owler } = linksObj;

  const linkIconsPairs = [
    { Icon: SocialLinksIcon, link: companyWebsite },
    { Icon: LinkedinIcon, link: linkedinUrl },
    { Icon: TwitterIcon, link: twitterUrl },
    { Icon: FacebookIcon, link: facebookUrl },
    { Icon: CrunchbaseIcon, link: crunchBase },
    { Icon: OwlerIcon, link: owler },
  ];

  return (
    <div className="social-links">
      <Space direction="vertical">
        {showText && <span> Social links </span>}
        <Space>{linkIconsPairs.map(({ Icon, link }) => renderIcons(Icon, link, sendEvent))}</Space>
      </Space>
    </div>
  );
};

SocialLinks.propTypes = {
  showText: PropTypes.bool,
  link: PropTypes.object,
  sendEvent: PropTypes.func,
};

SocialLinks.defaultProps = {
  showText: true,
  link: {},
  sendEvent: () => {},
};

export default SocialLinks;

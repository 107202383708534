import React from 'react';
import Avatar from 'UI/Avatar';
import Thumbnail from 'UI/Thumbnail';
import scssVariables from 'src/_vars.scss';
import './info-block.scss';

const InfoBlock = ({
  imageUrl,
  Icon,
  keyName,
  value,
  valueAddon,
  size,
  children,
  color,
  avatar,
  thumbnail,
  fallbackIcon,
  align = 'base-line',
  imagesize = 'medium',
}) => {
  return (
    <>
      <div className={`info-block info-block-${size} info-block-${color}`}>
        {Icon && (
          <>
            <Icon width="15px" fill={scssVariables.grey} />
            <span>&nbsp;&nbsp;</span>
          </>
        )}
        {avatar && (
          <div className="info-icon">
            <Avatar title={avatar} />
          </div>
        )}
        {imageUrl && (
          <>
            <Thumbnail.Image fallbackIcon={fallbackIcon} imageUrl={imageUrl} size={imagesize} />
            <span>&nbsp;&nbsp;</span>
          </>
        )}
        {thumbnail && (
          <>
            <Thumbnail.Icon Icon={thumbnail} size="medium" /> <span>&nbsp;&nbsp;</span>
          </>
        )}
        <div className={`content-title d-flex align-items-${align}`}>
          {keyName && (
            <div className="content-key">
              <span>{keyName}&nbsp;:&nbsp;</span>
            </div>
          )}
          <div>
            {value && <div className="content-value user-selection-none">{value}</div>}
            {children}
          </div>
        </div>
        {valueAddon && <div className="value-addon">{valueAddon}</div>}
      </div>
    </>
  );
};

export default InfoBlock;

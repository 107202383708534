import React from 'react';
import Sider from 'antd/lib/layout/Sider';
import FilterList from './FilterList';

const FilterContainer = ({
  tabHeading,
  updateFilter,
  filterData,
  // selectedHeading,
  // selectedOption,
}) => {
  // TODO: add translation / i18n for tab?
  return (
    <Sider trigger={null} width={300} theme="light" className="filter-container">
      <div className="filter-tab-container">
        <div className="filter-content">
          <FilterList
            tabHeading={tabHeading}
            updateFilter={updateFilter}
            filterData={filterData}
            // selectedHeading={selectedHeading}
            // selectedOption={selectedOption}
          />
        </div>
      </div>
    </Sider>
  );
};

export default FilterContainer;

import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Button } from 'antd';
import Text from 'antd/lib/typography/Text';
// import { LeadIcon } from 'UI/CustomIcon/CustomIcon';
import './profile.scss';
import { Link } from 'react-router-dom';
import useProfile from 'UI/CustomHooks/Profile';
import { getLoggedInUser, headerGenerator } from 'utils/common';
import { useDispatch } from 'react-redux';
import { userLogout } from 'containers/register/registerActions';
import CustomDrawer from 'UI/CustomDrawer/CustomDrawer';
import { SLINTEL_SUPPORT_SRC, CHROME_EXTENSION_SRC } from 'constants/multimedia';
import VisualWalkthrough from './VisualWalkthrough';

const userProfileStyle = {
  color: '#D1CFF5',
  fontSize: '30px',
};

const profileDrawerTitleStyle = {
  color: '#29275F',
  fontSize: '16px',
  fontWeight: 'bold',
  textAlign: 'left',
  letterSpacing: '0.8px',
  textTransform: 'uppercase',
  opacity: 1,
};

const Profile = ({ toggle, isProfile }) => {
  const dispatch = useDispatch();
  const [isVisualWalkthroughOpen, setIsVisualWalkthroughOpen] = useState(false);
  const user = getLoggedInUser();
  const onHandleLogout = () => {
    const headers = headerGenerator(user.token, user.session_id);
    dispatch(userLogout(headers));
  };
  const UserProfile = useProfile(userProfileStyle);
  const closeDrawer = () => toggle(false);
  const handleVisualWalkThrough = () => {
    closeDrawer();
    setIsVisualWalkthroughOpen(true);
  };

  return (
    <>
      <CustomDrawer
        onClose={closeDrawer}
        visible={isProfile}
        sidedrawerwidth="400px"
        title={<Text style={profileDrawerTitleStyle}>Profiles</Text>}
        placement="right"
        closable
        content={
          user?.token && (
            <div className="profile-info-body">
              <div className="profile-card">
                <div className="profile-card__image">{UserProfile}</div>
                <div className="profile-card__content">
                  <div className="profile-card__content-body">
                    <Text className="profile-card__content-text">{user.userName}</Text>
                    <Text className="profile-card__content-small-text">{user.userEmail}</Text>
                  </div>
                </div>
              </div>

              <div className="profile-card no-border">
                <div className="profile-nav">
                  <Link to="/my-account" className="profile-nav__button" onClick={closeDrawer}>
                    Settings
                  </Link>
                  <Link
                    to={{ pathname: CHROME_EXTENSION_SRC }}
                    target="_blank"
                    className="profile-nav__button"
                    type="link"
                  >
                    Get Chrome Extension
                  </Link>
                  <Link
                    to="/profile-deletion-request-compliance"
                    className="profile-nav__button"
                    onClick={closeDrawer}
                  >
                    Profile Deletion Request Compliance
                  </Link>
                  <div
                    role="button"
                    tabIndex="0"
                    className="profile-nav__button"
                    onClick={handleVisualWalkThrough}
                  >
                    Visual Walkthrough
                  </div>
                  <Link
                    to={{ pathname: SLINTEL_SUPPORT_SRC }}
                    target="_blank"
                    className="profile-nav__button"
                    type="link"
                  >
                    Help and Support
                  </Link>
                  <Button
                    onClick={onHandleLogout}
                    style={{ padding: 0, margin: '5px 0 0 0', textAlign: 'left' }}
                    type="link"
                    className="profile-nav__button"
                  >
                    Log Out
                  </Button>
                </div>
              </div>
            </div>
          )
        }
      />

      <VisualWalkthrough
        open={isVisualWalkthroughOpen}
        handleCancel={() => setIsVisualWalkthroughOpen(false)}
      />
    </>
  );
};

Profile.propTypes = {
  toggle: PropTypes.func.isRequired,
  isProfile: PropTypes.bool,
};

export default Profile;

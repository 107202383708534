/* eslint-disable import/no-unresolved */

import React from 'react';
import PropTypes from 'prop-types';
import { ConfigProvider } from 'antd';

import NoItems from '../../components-modules/ui-components/no-items/src/no-items';

import CompaniesToTargetFilterTabs from './companies-to-target-filter-tabs';

import '../sass/main.scss';

const EmptyStateMessageNode = () => {
  return <span>You don&apos;t have a list with that name</span>;
};
const CompaniesToTarget = (props) => {
  const { mixpanel, environmentVariables } = props;

  return (
    <ConfigProvider renderEmpty={() => <NoItems emptyStateMessage={<EmptyStateMessageNode />} />}>
      <CompaniesToTargetFilterTabs
        mixpanel={mixpanel}
        environmentVariables={environmentVariables}
      />
    </ConfigProvider>
  );
};

// create context to share configurations.

CompaniesToTarget.propTypes = {
  mixpanel: PropTypes.shape({
    track: PropTypes.func,
  }),
  // Environment variables of the environment microfrontend is running in.
  environmentVariables: PropTypes.shape({
    baseAPIURL: PropTypes.string.isRequired,
    oldDashboardURL: PropTypes.string.isRequired,
    domainName: PropTypes.string.isRequired,
  }).isRequired,
};

CompaniesToTarget.defaultProps = {
  mixpanel: null,
};

export default CompaniesToTarget;

import React from 'react';
import RoutedTabs from 'UI/RoutedTabs/RoutedTabs';
import { CompanyTabIcon, NewsFeedTabIcon } from 'UI/CustomIcon/CustomIcon';
import CompaniesToTarget from './companies-to-target/src/index';
import NewsFeedMain from './news/src/index';
import './dashboardIndex.scss';

const Dashboard = () => {
  const routeConfig = {
    companies: {
      label: 'Top Companies To Target',
      icon: CompanyTabIcon,
      component: () => {
        return (
          <div>
            <CompaniesToTarget
              environmentVariables={{
                baseAPIURL: process.env.BASE_API_URL,
                oldDashboardURL: process.env.OLD_DASHBOARD_URL,
                domainName: process.env.DOMAIN_NAME,
              }}
            />
          </div>
        );
      },
      getRoute: (url) => `${url}/companies-to-target`,
    },
    'news-feed': {
      label: 'Newsfeed',
      icon: NewsFeedTabIcon,
      component: () => {
        return (
          <div>
            <NewsFeedMain
              environmentVariables={{
                baseAPIURL: process.env.BASE_API_URL,
                oldDashboardURL: process.env.OLD_DASHBOARD_URL,
                domainName: process.env.DOMAIN_NAME,
                pusherConfig: {
                  pusherAppKey: process.env.PUSHER_APP_KEY,
                  pusherAppCluster: process.env.PUSHER_APP_CLUSTER,
                  nodeEnv: process.env.NODE_ENV,
                },
              }}
            />
          </div>
        );
      },
      getRoute: (url) => `${url}/news-feed`,
    },
  };

  return (
    <>
      {/* <Row gutter={[16, 16]}>
        <Col span={24}> */}
      <RoutedTabs
        tabsProps={{
          type: 'card',
          size: 'small',
          className: 'app-tabs',
        }}
        routeConfig={routeConfig}
        defaultTabRoute="companies-to-target"
      />
      {/* </Col>
      </Row> */}
    </>
  );
};

export default Dashboard;

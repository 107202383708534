/**
 * Provides selection and search Functionality with Ant Design Tree
 * Works with Antd Form and FormItem
 * Change state prgramatically with value passed from Form Item
 */

/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tree, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './_CheckboxTree.scss';

const { Search } = Input;

const CheckboxTree = ({ treeData, onChange, placeholder, hasRelation = false, value }) => {
  const [treeState, setTreeState] = useState({ searchValue: '', dataList: [], selectedKeys: [] });

  const { dataList, expandedKeys, autoExpandParent, searchValue, selectedKeys } = treeState;

  useEffect(() => {
    if (Object.keys(treeData)?.length) {
      setTreeState({
        expandedKeys: [],
        searchValue: '',
        autoExpandParent: true,
        dataList: getTreeData(treeData),
        selectedKeys: [],
      });
    }
  }, [treeData]);

  useEffect(() => {
    if (value) {
      setTreeState({ ...treeState, selectedKeys: value });
    }
  }, [value]);

  const onExpand = (keys) => {
    setTreeState({
      ...treeState,
      expandedKeys: keys,
      autoExpandParent: !!searchValue,
    });
  };

  const getParentKey = (key) => key?.split('|')[0];

  const getTreeData = (data) =>
    Object.entries(data).map((item) => ({
      key: item[0],
      title: item[0],
      children: item[1].map((value) => ({
        key: `${item[0]}|${value}`,
        title: value,
      })),
    }));

  const prepareTreeData = (data) =>
    data.map((item) => {
      const index = item.title.toLocaleLowerCase().indexOf(searchValue.toLocaleLowerCase());
      const beforeStr = item.title.substr(0, index);
      const sv = item.title.substr(index, searchValue.length);
      const afterStr = item.title.substr(index + searchValue.length);
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span className="site-tree-search-value">{sv}</span>
            {afterStr}
          </span>
        ) : (
          <span>{item.title}</span>
        );
      if (item.children) {
        return { title, key: item.key, children: prepareTreeData(item.children) };
      }

      return {
        title,
        key: item.key,
        checked: expandedKeys.includes(item.key),
      };
    });

  const onSearch = ({ target }) => {
    const { value } = target;
    const keys = dataList
      .map((item) => {
        const parentKey = getParentKey(item.key);
        if (item.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          return item.key;
        }
        if (item.children?.length) {
          return item.children.some(
            (child) => child.title.toLowerCase().indexOf(value.toLowerCase()) > -1
          )
            ? parentKey
            : null;
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);

    setTreeState({
      ...treeState,
      expandedKeys: value ? keys : [],
      searchValue: value,
      autoExpandParent: !!value,
    });
  };

  const onCheck = (checkedKeys) => {
    const keys = hasRelation ? checkedKeys.checked : checkedKeys;
    setTreeState({ ...treeState, selectedKeys: keys });
    onChange(keys);
  };

  const onSelect = (keys, event) => {
    const selectedKey = event.node.key;
    const expanded = event.selected
      ? [...expandedKeys, selectedKey]
      : expandedKeys.filter((key) => key !== selectedKey);
    let checkedKeys = treeState.selectedKeys;
    if (!event.node.children?.length) {
      if (event.node.checked) checkedKeys = checkedKeys.filter((k) => k !== selectedKey);
      else if (!checkedKeys.includes(selectedKey)) checkedKeys.push(selectedKey);
      onChange(checkedKeys);
    }
    setTreeState({ ...treeState, selectedKeys: checkedKeys, expandedKeys: expanded });
  };

  return (
    <div className="checkbox-tree-container">
      <Search
        style={{ marginBottom: 8 }}
        placeholder={placeholder || 'Search'}
        onChange={onSearch}
        allowClear
      />
      <Tree
        checkable
        blockNode
        checkStrictly={hasRelation}
        onCheck={onCheck}
        onSelect={onSelect}
        switcherIcon={<DownOutlined />}
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        treeData={prepareTreeData(dataList)}
        checkedKeys={selectedKeys}
        titleRender={(nodeData) => {
          return <span title={nodeData.title.props?.children[2] || ''}>{nodeData.title}</span>;
        }}
      />
    </div>
  );
};

CheckboxTree.propTypes = {
  value: PropTypes.array,
  treeData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  hasRelation: PropTypes.bool,
};

export default CheckboxTree;

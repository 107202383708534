import { Card, Collapse, Layout, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import DynamicCombobox from 'UI/DynamicCombobox';
import { showError, showSuccess } from 'components/common/errorMessage';
import OpenerButton from 'UI/OpenerButton';
import { FREE_TEXT_FILTER_CHAR_LIMIT } from 'constants/constants';
import { TechnologyService } from 'api/services';
import { debounce, headerGenerator } from 'utils/common';
import { getRequest } from 'api/apiRequests';
import { baseURL, endPoints } from 'api/apiEndpoints';
import FilterContainer from './listingFilter/FilterContainer';
import FollowButton from './FollowButton';
import ListView from './ListView';

const { Title, Text } = Typography;
const { Panel } = Collapse;
const {
  fetchTechnologyForSubCategory,
  getCustomFiltersTechnology,
  deleteFollowFiltersById,
  followTechnologyAndKeyword,
  fetchKeywordForSubCategory,
} = TechnologyService;

// TODO: any plan restrictions/auth for filter and page?
// TODO: add translation / i18n?
// check responsive css of table - hacks
const Listing = ({ filterOptions, columns: otherColumns, type }) => {
  const history = useHistory();
  // expects default values to be passed in routes
  const { category: paramCategory, subcategory: paramSubcategory } = useParams();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isFollowedLoading, setIsFollowedLoading] = useState(false);
  const [isFilterExpand, setIsFilterExpand] = useState(false);
  const [listData, setListData] = useState([]);
  const [count, setCount] = useState(0);
  const [category, setCategory] = useState(paramCategory);
  const [subcategory, setSubcategory] = useState(paramSubcategory);
  const [followedData, setFollowedData] = useState([]);
  const [listToFollowMap, setListToFollowMap] = useState({});
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState([]);
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const headers = headerGenerator(user.token, user.session_id);

  const SUB_DOMAIN = type === 'technology' ? 'technologies' : 'keywords';
  const handleClick = (record, callback) => {
    if (!record.length) {
      return;
    }
    const last = record.slice(-1);
    callback(last);
    const techKeyword = options.find(
      (item) => last[0] === `${item.category}|${item.subcategory}|${item.company_name}`
    );
    if (techKeyword) {
      const url = `/${type === 'technology' ? 'technologies-details' : 'keywords-details'}/${
        techKeyword._id
      }`;
      window.open(url, '_blank');
    }
  };

  const resetDropdownOptions = (open, isLoading = false) => {
    if (isLoading) {
      setOptionsLoading(true);
    }
    return !open && setOptions([]);
  };

  const getOptionsFormat = ({ data }) => {
    if (Array.isArray(data)) {
      return data.map((list) => ({ ...list._source, type: list._type }));
    }
    if (Array.isArray(data.data)) {
      if (typeof data.data[0] === 'string') {
        return data.data;
      }
      return data.data?.map((list) => ({ ...list._source, _id: list._id })) || [];
    }
    return data;
  };

  const handleRequest = async (value) => {
    if (!value) {
      return;
    }
    setOptionsLoading(true);

    const url = `${baseURL}${
      type === 'technology'
        ? endPoints.technologiesAutoSuggestion
        : endPoints.keywordsAutoSuggestion
    }`;
    const query = {
      q: value,
    };

    const response = await getRequest(url, query, headers);
    setOptionsLoading(false);
    if (response.error) {
      showError(response.message);
      return;
    }
    if (response?.data?.data.length) {
      const suggestions = getOptionsFormat(response);
      setOptions(suggestions);
    } else {
      setOptions([]);
    }
  };
  const columns = [
    ...otherColumns,

    {
      title: 'FOLLOW',
      key: 'actions',
      dataIndex: 'actions',
      // width: 100,
      align: 'center',
      render: (_, record) => {
        return (
          <FollowButton data={record} handleFollow={handleFollow} handleUnfollow={handleUnfollow} />
        );
      },
    },
  ];
  const handleFollow = useCallback(async (recordData) => {
    try {
      const res = await followTechnologyAndKeyword(recordData);
      if (res.data?.id) {
        recordData.isChecked = true;
        // for delete we need to pass id coming from db when followed
        recordData.techId = res.data.id;
        setFollowedData((followedTechList) => [
          { ...recordData, company_profile_image_url: '' },
          ...followedTechList,
        ]);
        showSuccess(
          `You are now following ${recordData.company_name_label ?? recordData.company_name}`
        );
      } else {
        showError(`Error in following ${recordData.company_name_label ?? recordData.company_name}`);
      }
    } catch (e) {
      showError(`Error in following ${recordData.company_name_label ?? recordData.company_name}`);
    }
  }, []);

  const handleUnfollow = useCallback(async (recordData) => {
    try {
      // we need to pass id coming from db when followed
      const payload = { id: recordData.techId, type };
      await deleteFollowFiltersById(payload);
      recordData.isChecked = false;
      setFollowedData((followedTechList) => {
        return followedTechList.filter((tech) => tech.techId !== recordData.techId);
      });
      setListData((prevList) =>
        prevList.map((listItem) => {
          if (listItem._id === recordData._id) {
            return { ...listItem, isChecked: false };
          }
          return listItem;
        })
      );
      showSuccess(`You have unfollowed ${recordData.company_name_label}`);
    } catch (e) {
      showError(`Error in unfollowing ${recordData.company_name_label}`);
    }
  }, []);

  const getTechnologyForSubcategory = async (selectedCategory, selectedSubcategory) => {
    let data = [];
    try {
      const params = {
        category: selectedCategory.replace(/\s/g, '+'),
        subcategory: selectedSubcategory.replace(/\s/g, '+'),
      };
      const response =
        type === 'technology'
          ? await fetchTechnologyForSubCategory(params)
          : await fetchKeywordForSubCategory(params);
      data = type === 'technology' ? response?.data?.data : response?.data || [];
    } catch (e) {
      // TODO: handle error
    }
    return data;
  };

  const getFormattedFollowedData = (followed) => {
    const metaIdToFollowedId = {};
    const formattedData = followed.map((item) => {
      if (!item.meta) item.meta = {};
      const followedItem = { ...item.meta };
      const id = item.meta._id;
      metaIdToFollowedId[id] = item.id;
      followedItem.techId = item.id;
      // followedItem.subcategory = item.meta.subcategory_label;
      followedItem.isChecked = true;
      followedItem.company_name_label = item.name;
      followedItem.company_name = item.name;
      followedItem.company_profile_image_url = item.company_profile_image_url;
      return followedItem;
    });
    setListToFollowMap(metaIdToFollowedId);
    return formattedData;
  };

  const getFollowedTechnologies = async () => {
    const params = { filterType: 'technology' };
    setIsFollowedLoading(true);
    try {
      const { data = [] } = await getCustomFiltersTechnology(params);
      const formatFollowedData = getFormattedFollowedData(
        data.filter((item) => item.preferenceType === type)
      );
      setFollowedData(formatFollowedData);
    } catch (e) {
      // TODO: handle error
    }
    setIsFollowedLoading(false);
  };

  const getSortedTechnologyKeys = (entries) => Object.keys(entries).sort();

  const getCatSubcat = (pCategory, pSubcategory) => {
    let selectedCategory = pCategory;
    let selectedSubcategory = pSubcategory;
    if (!pCategory) {
      const sortedTechnologyKeys = getSortedTechnologyKeys(filterOptions);
      selectedCategory = sortedTechnologyKeys[0];
    }
    if (!pSubcategory) {
      selectedSubcategory = filterOptions[selectedCategory][0];
    }
    return {
      selectedCategory,
      selectedSubcategory,
    };
  };

  const getData = async (pCategory, pSubcategory) => {
    const { selectedCategory, selectedSubcategory } = getCatSubcat(pCategory, pSubcategory);
    setIsDataLoading(true);
    try {
      const { data: listResponse, total } = await getTechnologyForSubcategory(
        selectedCategory,
        selectedSubcategory
      );
      setListData(listResponse);
      setCount(total);
      setCategory(decodeURIComponent(selectedCategory));
      setSubcategory(decodeURIComponent(selectedSubcategory));
    } catch (e) {
      // TODO: handle error
    }
    setIsDataLoading(false);
  };

  const updateListData = () => {
    if (Object.keys(listToFollowMap).length && listData.length) {
      // this goes to main table
      // TODO: should make new object ?
      const newListData = listData.map((item) => {
        if (listToFollowMap[item._id]) {
          item.isChecked = true;
          item.techId = listToFollowMap[item._id];
        }
        return item;
      });
      setListData(newListData);
    }
  };

  useEffect(() => {
    getFollowedTechnologies();
  }, []);

  useEffect(() => {
    getData(paramCategory, paramSubcategory);
  }, [paramCategory, paramSubcategory]);

  useEffect(() => {
    if (!isDataLoading && !isFollowedLoading) {
      updateListData();
    }
  }, [isDataLoading, isFollowedLoading]);

  // TODO: update URL
  const handleFilterChange = async (selectedFilter = {}) => {
    const selectedCategory = selectedFilter.heading;
    const selectedSubcategory = selectedFilter.option;
    const escapedCategory = encodeURIComponent(selectedCategory);
    const escapedSubcategory = encodeURIComponent(selectedSubcategory);
    history.push({
      pathname: `/${SUB_DOMAIN}/${escapedCategory}/${escapedSubcategory}`,
    });
  };

  return (
    <Layout className="technology-container">
      <FilterContainer
        updateFilter={handleFilterChange}
        tabHeading="Category"
        filterData={filterOptions}
        // selectedHeading={category}
        // selectedOption={subcategory}
      />
      {/* filter aside width is 300px */}
      {/* TODO: followed technologies opens and then closes */}
      <div style={{ width: 'calc(100% - 300px)', overflowY: 'scroll' }}>
        <Collapse
          className="follow-technology-container"
          expandIconPosition="right"
          accordion
          expandIcon={() => (
            <OpenerButton
              iconProps={{ height: '7', width: '7' }}
              direction={isFilterExpand ? 'up' : 'down'}
              className="filter-arrow-btn"
            />
          )}
          onChange={(key) => setIsFilterExpand(!!key)}
        >
          {/* Format followedData length */}
          <Panel
            header={`Followed ${type === 'technology' ? 'Technologies' : 'terms and phrases'} (${
              followedData.length
            })`}
            key="savedItem"
          >
            <div style={{ marginRight: '16px' }}>
              <ListView
                data={followedData}
                updateFollowedData={setFollowedData}
                followList
                columns={columns}
                type={type}
              />
              {/* TODO: change this */}
            </div>
          </Panel>
        </Collapse>

        <Card className="inner-tab-container">
          <div className="table-head-container">
            {/* TODO: can overflow text - clip / tooltip */}
            <Title level={4} className="head-text">
              {category} &gt; {subcategory} <span className="head-text-count"> {count}</span>
            </Title>

            <div style={{ width: '240px' }}>
              <DynamicCombobox
                mode="multiple"
                type="image-text"
                onChange={(v) => handleClick(v, setSearchValue)}
                maxLength={FREE_TEXT_FILTER_CHAR_LIMIT}
                value={searchValue}
                handleRequest={debounce(handleRequest, 500)}
                optionList={options}
                loading={optionsLoading}
                onBlur={() => setOptions([])}
                resetDropdownOptions={resetDropdownOptions}
                placeHolder={`Search ${type === 'technology' ? 'Technologies' : 'Psychographics'}`}
              />
            </div>
          </div>
          <div className="table-body-container">
            <ListView
              data={listData}
              isLoading={isDataLoading}
              updateFollowedData={setFollowedData}
              columns={columns}
              type={type}
            />
          </div>
        </Card>
      </div>
    </Layout>
  );
};

export default Listing;

/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import {
  LISTS_INIT,
  LISTS_SUCCESS,
  LISTS_RESET,
  LISTS_FAILURE,
  LISTS_CREATE_SUCCESS,
  LIST_DELETE_SUCCESS,
  LISTS_SEARCH_SUCCESS,
  LIST_ACTIVE_FILTER,
  LIST_ITEMS_SUCCESS,
  LIST_ITEMS_FAILURE,
  SET_LIST_ITEMS_FILTER,
  RESET_LIST_ITEMS_FILTER,
  LIST_ITEMS_INIT,
  RESET_CURRENT_FILTER,
  ADDTO_LIST_START,
  ADDTO_LIST_END,
  OPEN_QUICK_VIEW_SMART_LIST,
  CLOSE_QUICK_VIEW_SMART_LIST,
  UPDATE_LEAD_SMART_LIST,
  OPEN_ADDTO_LIST_SMART_LIST,
  CLOSE_ADDTO_LIST_SMART_LIST,
  SET_ACTION,
  CLEAR_ADD_TO_LIST_SOURCE,
} from 'store/actionTypes';

const initialQuickView = {
  isQuickView: false,
  viewName: '',
  data: {},
  isViewLoading: false,
  actionType: null, // PREV, NEXT
};

const initialAction = {
  confirm: false,
  targetItem: {},
  type: '', // single, selected, bulk
};

const initialFilters = {
  company: {
    company_name: [],
    company_size: [],
    company_location: [],
    company_sector: [],
    company_industry: [],
    company_sector_industry: [],
    company_sic_code: [],
    company_naics_code: [],
    revenue_range: [],
  },
  keywords: {
    keywords: [],
  },
  technologies: {
    technologies: [],
  },
  lead: {
    lead_titles: [],
    company_function: [],
    decision_making_power: [],
    lead_divison: [],
    email_availablity: [],
    has_phone_number: [],
    has_leads: [],
    company_function_division: [],
    lead_location: [],
    continent: [],
  },
  other: {
    source: [],
    created_at: [],
  },
};

const initialState = {
  lists: [],
  total: 0,
  errorMessage: '',
  successMessage: '',
  activeFilter: {
    smart_list: '',
    exclusion_list: '',
  },
  listItems: {},
  listFilters: { ...initialFilters },
  listItemsLoading: true,
  filterState: 'reset',
  isListLoading: false,
  quickView: initialQuickView,
  addToListDrawerVisibility: false,
  addToListSource: {},
  companySmartListAction: initialAction,
};

const SmartList = (state = initialState, action) => {
  switch (action.type) {
    case LISTS_INIT:
      return {
        ...state,
      };
    case LISTS_SUCCESS: {
      const {
        data: { rows, count },
      } = action;
      let { lists } = state;
      lists = [...lists, ...rows];
      return {
        ...state,
        lists,
        total: count,
        errorMessage: '',
        successMessage: '',
      };
    }
    case LISTS_SEARCH_SUCCESS: {
      const {
        data: { rows, count },
      } = action;
      return {
        ...state,
        lists: rows,
        total: count,
        errorMessage: '',
        successMessage: '',
      };
    }
    case LISTS_CREATE_SUCCESS: {
      const { data } = action;
      const { lists } = state;
      return {
        ...state,
        lists: [data, ...lists],
        total: state.count + 1,
        successMessage: 'List created successfully',
        errorMessage: '',
      };
    }
    case LISTS_FAILURE: {
      const { error } = action;
      return {
        ...state,
        errorMessage: error,
        successMessage: '',
      };
    }
    case LIST_DELETE_SUCCESS: {
      const { data } = action;
      let { lists, total } = state;
      lists = lists.filter((list) => list.id !== data);
      total -= 1;
      return {
        ...state,
        lists,
        total,
        errorMessage: '',
        successMessage: 'List deleted successfully',
      };
    }
    case LISTS_RESET: {
      return {
        ...initialState,
      };
    }
    case LIST_ACTIVE_FILTER: {
      const { data } = action;
      return {
        ...state,
        activeFilter: data,
      };
    }
    case LIST_ITEMS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        listItemsLoading: false,
        listItems: data,
      };
    }
    case LIST_ITEMS_FAILURE: {
      const { error } = action;
      return {
        ...state,
        listItemsLoading: false,
        errorMessage: error,
      };
    }
    case SET_LIST_ITEMS_FILTER: {
      const { payload } = action;
      const { type, name, values } = payload;
      const listFilters = state.listFilters;
      listFilters[type][name] = values;
      return {
        ...state,
        filterState: 'active',
        listFilters: { ...listFilters },
        quickView: initialQuickView,
      };
    }
    case RESET_LIST_ITEMS_FILTER: {
      const listFilters = { ...initialFilters };
      Object.values(listFilters).forEach((filter) => {
        Object.keys(filter).forEach((key) => {
          filter[key] = [];
        });
      });
      return {
        ...state,
        filterState: 'reset',
        listFilters: { ...listFilters },
        quickView: initialQuickView,
      };
    }
    case RESET_CURRENT_FILTER: {
      const { listFilters } = state;
      const { type } = action.payload;
      if (type) {
        for (const key in listFilters[type]) {
          listFilters[type][key] = [];
        }
      }
      return {
        ...state,
        filterState: 'active',
        listFilters: { ...listFilters },
        quickView: initialQuickView,
      };
    }
    case LIST_ITEMS_INIT: {
      return {
        ...state,
        listItemsLoading: true,
      };
    }

    case ADDTO_LIST_START: {
      return {
        ...state,
        isListLoading: true,
      };
    }

    case ADDTO_LIST_END: {
      return {
        ...state,
        isListLoading: false,
      };
    }

    case OPEN_QUICK_VIEW_SMART_LIST: {
      const { payload = {} } = action;
      return {
        ...state,
        quickView: {
          isQuickView: true,
          viewName: payload.view,
          data: payload.data,
          isViewLoading: !!payload.loading,
          actionType: payload.actionType ?? null,
        },
      };
    }

    case CLOSE_QUICK_VIEW_SMART_LIST: {
      return {
        ...state,
        quickView: initialQuickView,
      };
    }

    case UPDATE_LEAD_SMART_LIST: {
      const { payload } = action;
      const { email, email_score, emailScore, emailNotAvailable, phone, phoneNotAvailable } =
        payload;
      const selectedLeadId = action.payload?.id?.value;
      if (selectedLeadId) {
        const listItems = state.listItems.items.map((item) => {
          return item.id.split('_')[1] !== selectedLeadId
            ? item
            : {
                ...item,
                email: email?.value || item.email,
                email_score: email_score?.value || item.email_score,
                emailScore,
                emailNotAvailable,
                phone: phone?.value || item.phone,
                phoneNotAvailable,
              };
        });
        return {
          ...state,
          listItems: {
            ...state.listItems,
            items: listItems,
          },
        };
      }
      return state;
    }

    case OPEN_ADDTO_LIST_SMART_LIST: {
      const { payload } = action;
      return {
        ...state,
        addToListDrawerVisibility: true,
        addToListSource: payload,
      };
    }

    case CLOSE_ADDTO_LIST_SMART_LIST: {
      return {
        ...state,
        addToListDrawerVisibility: false,
      };
    }

    case CLEAR_ADD_TO_LIST_SOURCE: {
      return {
        ...state,
        addToListSource: {},
      };
    }

    case SET_ACTION: {
      const { payload } = action;
      return {
        ...state,
        companySmartListAction: payload,
      };
    }

    default:
      return state;
  }
};

export default SmartList;

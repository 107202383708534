import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Space, Avatar } from 'antd';
import { EXPORT_POPOVER_SOURCE } from 'constants/constants';
import ActionGroup from 'UI/ActionGroup/ActionGroup';
import Thumbnail from 'UI/Thumbnail/Thumbnail';
import scssVariables from 'src/_vars.scss';
import KeyValueWithIcon from '../KeyValueWithIcon/KeyValueWithIcon';
import './QuickViewCardSecond.scss';

const { Text } = Typography;

const getIcon = ({ icon, customIcon, size, backgroundColor, iconColor, src }) => {
  if (icon) {
    return <Avatar className="slintel-name-avatar">{icon}</Avatar>;
  }
  if (customIcon) {
    return (
      <Thumbnail.Icon
        size={size}
        Icon={customIcon}
        backgroundColor={backgroundColor}
        iconColor={iconColor}
      />
    );
  }
  return <Avatar className="slintel-name-avatar" src={src} />;
};

const QuickViewCardSecond = ({
  title,
  subTitle,
  titleIcon,
  isActionBtn,
  content,
  actions,
  width = '100%',
  tab,
  source = EXPORT_POPOVER_SOURCE.QUICKVIEW,
}) => {
  return (
    <div className="card-block" style={{ width }}>
      <div className="card-header">
        <div className="icon-holder">{getIcon(titleIcon)}</div>
        <div className="title-holder">
          <Space direction="vertical" size={4}>
            <Text strong size={5}>
              {title}
            </Text>
            <Text
              style={{ maxWidth: '200px', color: scssVariables.TEXT_GREY }}
              ellipsis={{
                tooltip: subTitle,
              }}
            >
              {subTitle}
            </Text>
          </Space>
        </div>
        {isActionBtn && (
          <div>
            <ActionGroup actions={actions} tab={tab} source={source} />
          </div>
        )}
      </div>
      <div className="card-content">
        <div className="table-info-block">
          <Space direction="vertical" size={10}>
            {content.map((item) => (
              <KeyValueWithIcon key={item.title} item={item} />
            ))}
          </Space>
        </div>
      </div>
    </div>
  );
};

QuickViewCardSecond.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isActionBtn: PropTypes.bool,
  content: PropTypes.array,
};

QuickViewCardSecond.defaultProps = {
  title: '',
  subTitle: '',
  isActionBtn: true,
  content: [],
};

export default QuickViewCardSecond;

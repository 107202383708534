import {
  APPLY_FILTERS,
  CLEAR_CHECKED_ITEMS,
  CLOSE_ADDTO_LIST,
  CLOSE_QUICK_VIEW,
  CLOSE_SAVE_FILTER,
  DEFAULT_INNER_ACTIVE_KEY,
  NET_NEW_COMPANIES_ERROR,
  NET_NEW_COMPANIES_SUCCESS,
  NET_NEW_LEADS_ERROR,
  NET_NEW_LEADS_SUCCESS,
  OPEN_ADDTO_LIST,
  OPEN_QUICK_VIEW,
  OPEN_SAVE_FILTER,
  RESET_COMPANY_FILTERS,
  RESET_FILTER,
  RESET_LEAD_FILTERS,
  SCROLL_AREA_EXTRA_SIZE,
  SEARCH_COMPANIES_ERROR,
  SEARCH_COMPANIES_SUCCESS,
  SEARCH_LEADS_ERROR,
  SEARCH_LEADS_SUCCESS,
  SET_ACTIVE_PANEL,
  SET_CHECKED_ITEMS,
  SET_USER_SELECTION,
  SHOW_SHIMMERS,
  STORE_SORT,
  TAB_CHANGE,
  TOGGLE_COMPANY_FILTERS,
  TOGGLE_FILTER_VIEW,
  TOGGLE_LEAD_FILTERS,
  UPDATE_CUSTOM_SELECTIONS,
  UPDATE_LEAD,
  SET_TAB,
  SET_DROPDOWN_ID,
  SET_EXPORT_DROPDOWN_VISIBLE,
} from 'store/actionTypes';
import { clearFilterByGroupId, clearFilters, mapSupportedFilters } from 'utils/common';

const initialQuickView = {
  isQuickView: false,
  viewName: '',
  data: {},
  isViewLoading: false,
  actionType: null, // PREV, NEXT
};

const commonLeadCompanyFilters = {
  MIN_FOUNDED_YEAR: {
    values: [],
    label: 'Min Founded Year',
    negation: false,
    plan: ['pro'],
    key: 'min_company_founded_at',
    groupId: ['COMPANY', 'FOUNDED_YEAR'],
  },
  MAX_FOUNDED_YEAR: {
    values: [],
    label: 'Max Founded Year',
    negation: false,
    plan: ['pro'],
    key: 'max_company_founded_at',
    groupId: ['COMPANY', 'FOUNDED_YEAR'],
  },
  FUNDING_ROUND_TYPE: {
    values: [],
    label: 'Last Funding Type',
    negation: false,
    plan: ['pro'],
    key: 'latest_funding_stage',
    groupId: ['COMPANY', 'FINANCIAL_INFORMATION'],
  },
  COMPANY_TYPE: {
    values: [],
    label: 'Company Type',
    negation: false,
    plan: ['seed', 'free'],
    key: 'company_type',
    groupId: ['COMPANY', 'COMPANY_TYPE'],
  },
};

const initialState = {
  viewName: '', // let it be empty by default for page refresh checking (for more details check TAB_CHANGE case)
  isFilterView: true,
  quickView: initialQuickView,
  tableExtraSize: {
    filterPreviewHeight: 0,
    infoStripHeight: 0,
  },
  leadFilters: {
    ...commonLeadCompanyFilters,
    JOB_TITLE: {
      values: [],
      label: 'Job Title',
      negation: true,
      plan: ['seed', 'free'],
      key: 'lead_titles',
      groupId: ['LEADS', 'JOB_TITLE'],
    },
    SENIORITY_LEVEL: {
      values: [],
      label: 'Seniority Level',
      negation: false,
      plan: ['seed', 'free'],
      key: 'seniority_level',
      groupId: ['LEADS', 'JOB_TITLE'],
    },
    DEPARTMENT_AND_JOB_FUNCTION: {
      values: [],
      label: 'Function & Division',
      negation: false,
      plan: ['seed', 'free'],
      key: 'department_function',
      groupId: ['LEADS', 'JOB_TITLE'],
    },
    DECISION_MAKING_POWER: {
      values: [],
      label: 'Decision-Making Power',
      negation: false,
      plan: ['seed', 'free'],
      key: 'decision_making_power',
      groupId: ['LEADS', 'JOB_TITLE'],
    },
    CONTACT_INFOMATION: {
      values: [],
      label: 'Contact Information',
      negation: false,
      plan: ['seed'],
      key: 'constact_information',
    },
    EMAIL: {
      values: [],
      label: 'Email Confidence Score',
      negation: false,
      plan: ['seed'],
      key: 'email_score',
      groupId: ['LEADS', 'CONTACT_INFOMATION'],
    },
    EXCLUDE_PROFILES_WITHOUT_DIRECT_PHONES: {
      values: [],
      label: 'Direct Phone',
      negation: false,
      plan: ['seed', 'free'],
      key: 'has_phone_number',
      groupId: ['LEADS', 'CONTACT_INFOMATION'],
    },
    EXCLUDE_PARTIAL_PROFILES: {
      values: [],
      label: 'Exclude Partial Profiles',
      negation: false,
      plan: ['seed'],
      key: 'exclude_partial_profiles',
    },
    SKILLS: {
      values: [],
      label: 'Skills',
      negation: false,
      plan: ['seed', 'free'],
      key: 'skills',
      groupId: ['LEADS', 'SKILLS'],
    },
    INTERESTS: {
      values: [],
      label: 'Interests',
      negation: false,
      plan: ['seed', 'free'],
      key: 'interests',
      groupId: ['LEADS', 'INTERESTS'],
    },
    LEADS_LOCATION: {
      values: [],
      label: 'People Location',
      negation: true,
      plan: ['seed', 'free'],
      key: 'lead_location',
      groupId: ['LOCATION'],
    },
    COMPANY_LOCATION: {
      values: [],
      label: 'Company Location',
      negation: true,
      plan: ['seed', 'free'],
      key: 'company_location',
      groupId: ['LOCATION'],
    },
    COMPANY_NAME_WEBSITE: {
      values: [],
      label: 'Current Company',
      negation: true,
      plan: ['seed', 'free'],
      key: 'company_id',
      groupId: ['COMPANY', 'COMPANY_NAME_WEBSITE'],
    },
    PAST_COMPANY: {
      values: [],
      label: 'Past Company',
      negation: false,
      plan: ['seed', 'free'],
      key: 'past_companies',
      groupId: ['COMPANY', 'COMPANY_NAME_WEBSITE'],
    },
    SECTOR_INDUSTRY: {
      values: [],
      label: 'Industry & Sector',
      negation: false,
      plan: ['seed', 'free'],
      key: 'sector_industry',
      groupId: ['COMPANY', 'SECTOR_INDUSTRY'],
    },
    NAICS: {
      values: [],
      label: 'NAICS',
      negation: true,
      plan: ['seed', 'free'],
      key: 'company_naics_code',
      groupId: ['COMPANY', 'SECTOR_INDUSTRY'],
    },
    SIC: {
      values: [],
      label: 'SIC',
      negation: true,
      plan: ['seed', 'free'],
      key: 'company_sic_code',
      groupId: ['COMPANY', 'SECTOR_INDUSTRY'],
    },
    PRODUCT_SERVICES: {
      values: [],
      label: 'Company Product And Services',
      negation: false,
      plan: ['seed', 'free'],
      key: 'company_products_services',
      groupId: ['COMPANY', 'PRODUCT_SERVICES'],
    },
    COMPANY_SIZE: {
      values: [],
      label: 'Company Size',
      negation: false,
      plan: ['seed', 'free'],
      key: 'company_size',
      groupId: ['COMPANY', 'COMPANY_SIZE'],
    },
    FUNDING_AMOUNT: {
      values: [],
      label: 'Total Funding Raised',
      negation: false,
      plan: [],
      key: 'company_funding_range',
      groupId: ['COMPANY', 'FINANCIAL_INFORMATION'],
    },
    FUNDING_DATE_RANGE: {
      values: [],
      label: 'Last Funded',
      negation: true,
      plan: ['seed'],
      key: 'company_latest_funding_date',
      groupId: ['COMPANY', 'FINANCIAL_INFORMATION'],
    },
    TECHNOLOGY_RS_DATE_RANGE: {
      values: [],
      label: 'Technology Date Range',
      negation: true,
      plan: ['seed'],
      key: 'technology_rs_date_range',
      groupId: ['TECHNOLOGY'],
    },
    REVENUE: {
      values: [],
      label: 'Revenue',
      negation: false,
      plan: [],
      key: 'revenue_range',
      groupId: ['COMPANY', 'FINANCIAL_INFORMATION'],
    },
    FISCAL_YEAR: {
      values: [],
      label: 'Fiscal Year',
      negation: false,
      plan: [],
      key: 'fiscal_year',
      groupId: ['COMPANY', 'FINANCIAL_INFORMATION'],
    },
    MIN_JOBS_COUNT_RANGE: {
      values: [],
      label: 'Min Job Count Range',
      negation: false,
      plan: ['seed'],
      key: 'min_job_count',
      groupId: ['COMPANY', 'JOBS_COUNT_RANGE'],
    },
    MAX_JOBS_COUNT_RANGE: {
      values: [],
      label: 'Max Job Count Range',
      negation: false,
      plan: ['seed'],
      key: 'max_job_count',
      groupId: ['COMPANY', 'JOBS_COUNT_RANGE'],
    },
    TAGS: {
      values: [],
      label: 'Tags',
      negation: true,
      plan: ['seed', 'free'],
      key: 'tags',
      groupId: ['COMPANY', 'TAGS'],
    },
    TECHNOLOGY: {
      values: [],
      label: 'Technology Currently Used',
      negation: true,
      plan: ['seed'],
      key: 'technologies',
      groupId: ['TECHNOLOGIES', 'TECHNOLOGY'],
    },
    TECHNOLOGYRECENTLYSTARTED: {
      values: [],
      label: 'Technology Added This Month',
      negation: true,
      plan: ['seed'],
      key: 'tech_adds',
      groupId: ['TECHNOLOGIES', 'TECHNOLOGYRECENTLYSTARTED'],
    },
    KEYWORDS: {
      values: [],
      label: 'Psychographics',
      negation: true,
      plan: [],
      key: 'keywords',
      groupId: ['KEYWORDS'],
    },
    SEARCH: {
      values: [],
      label: 'Search',
      negation: false,
      plan: ['seed'],
      key: 'boolean_search',
      skipParsing: true,
    },
    SORT: {
      values: [],
      label: 'Sort',
      negation: false,
      plan: [],
      key: 'sort',
    },
    ORDER: {
      values: [],
      label: 'Order',
      negation: false,
      plan: [],
      key: 'order',
    },
    LEAD_LOCATION_INTERNATIONAL: {
      values: [],
      label: 'People Continent',
      negation: false,
      plan: ['seed', 'free'],
      key: 'lead_location_international',
      groupId: ['LOCATION'],
    },
    LEAD_LOCATION_REGIONS: {
      values: [],
      label: 'People Location Region',
      negation: false,
      plan: ['seed', 'free'],
      key: 'lead_location_regions',
      groupId: ['LOCATION'],
    },
    COMPANY_LOCATION_INTERNATIONAL: {
      values: [],
      label: 'Company Continent',
      negation: false,
      plan: ['seed', 'free'],
      key: 'company_location_international',
      groupId: ['LOCATION'],
    },
    COMPANY_LOCATION_REGIONS: {
      values: [],
      label: 'Company Location Region',
      negation: false,
      plan: ['seed', 'free'],
      key: 'company_location_regions',
      groupId: ['LOCATION'],
    },
  },
  companyFilters: {
    ...commonLeadCompanyFilters,
    COMPANY_LOCATION: {
      values: [],
      label: 'Company Location',
      negation: true,
      plan: ['seed', 'free'],
      key: 'company_location',
      groupId: ['LOCATION'],
    },
    COMPANY_NAME_WEBSITE: {
      values: [],
      label: 'Company Name',
      negation: true,
      plan: ['seed', 'free'],
      key: 'company_id',
      groupId: ['COMPANY', 'COMPANY_NAME_WEBSITE'],
    },
    SECTOR_INDUSTRY: {
      values: [],
      label: 'Industry',
      negation: false,
      plan: ['seed', 'free'],
      key: 'sector_industry',
      groupId: ['COMPANY', 'SECTOR_INDUSTRY'],
    },
    NAICS: {
      values: [],
      label: 'NAICS',
      negation: true,
      plan: ['seed', 'free'],
      key: 'company_naics_code',
      groupId: ['COMPANY', 'SECTOR_INDUSTRY'],
    },
    SIC: {
      values: [],
      label: 'SIC',
      negation: true,
      plan: ['seed', 'free'],
      key: 'company_sic_code',
      groupId: ['COMPANY', 'SECTOR_INDUSTRY'],
    },
    PRODUCT_SERVICES: {
      values: [],
      label: 'Company Product And Services',
      negation: false,
      plan: ['seed', 'free'],
      key: 'company_products_services',
      groupId: ['COMPANY', 'PRODUCT_SERVICES'],
    },
    COMPANY_SIZE: {
      values: [],
      label: 'Company Size',
      negation: false,
      plan: ['seed', 'free'],
      key: 'company_size',
      groupId: ['COMPANY', 'COMPANY_SIZE'],
    },
    FUNDING_AMOUNT: {
      values: [],
      label: 'Total Funding Raised',
      negation: false,
      plan: [],
      key: 'company_funding_range',
      groupId: ['COMPANY', 'FINANCIAL_INFORMATION'],
    },
    FUNDING_DATE_RANGE: {
      values: [],
      label: 'Last Funded',
      negation: true,
      plan: ['seed'],
      key: 'company_latest_funding_date',
      groupId: ['COMPANY', 'FINANCIAL_INFORMATION'],
    },
    TECHNOLOGY_RS_DATE_RANGE: {
      values: [],
      label: 'Technology Date Range',
      negation: true,
      plan: ['seed'],
      key: 'technology_rs_date_range',
      groupId: ['TECHNOLOGY'],
    },
    REVENUE: {
      values: [],
      label: 'Revenue',
      negation: false,
      plan: [],
      key: 'revenue_range',
      groupId: ['COMPANY', 'FINANCIAL_INFORMATION'],
    },
    FISCAL_YEAR: {
      values: [],
      label: 'Fiscal Year',
      negation: false,
      plan: [],
      key: 'fiscal_year',
      groupId: ['COMPANY', 'FINANCIAL_INFORMATION'],
    },
    MIN_JOBS_COUNT_RANGE: {
      values: [],
      label: 'Min Job Count Range',
      negation: false,
      plan: ['seed'],
      key: 'min_job_count',
      groupId: ['COMPANY', 'JOBS_COUNT_RANGE'],
    },
    MAX_JOBS_COUNT_RANGE: {
      values: [],
      label: 'Max Job Count Range',
      negation: false,
      plan: ['seed'],
      key: 'max_job_count',
      groupId: ['COMPANY', 'JOBS_COUNT_RANGE'],
    },
    COMPANY_WITH_LEADS: {
      values: [],
      label: 'Company With People',
      negation: false,
      plan: ['seed', 'free'],
      key: 'has_leads',
      groupId: ['COMPANY', 'COMPANY_WITH_LEADS'],
    },
    TAGS: {
      values: [],
      label: 'Tags',
      negation: true,
      plan: ['seed', 'free'],
      key: 'tags',
      groupId: ['COMPANY', 'TAGS'],
    },
    TECHNOLOGY: {
      values: [],
      label: 'Technology Currently Used',
      negation: true,
      plan: ['seed'],
      key: 'technologies',
      groupId: ['TECHNOLOGIES', 'TECHNOLOGY'],
    },
    TECHNOLOGYRECENTLYSTARTED: {
      values: [],
      label: 'Technology Added This Month',
      negation: true,
      plan: ['seed'],
      key: 'tech_adds',
      groupId: ['TECHNOLOGIES', 'TECHNOLOGYRECENTLYSTARTED'],
    },
    KEYWORDS: {
      values: [],
      label: 'Psychographics',
      negation: true,
      plan: [],
      key: 'keywords',
      groupId: ['KEYWORDS'],
    },
    SEARCH: {
      values: [],
      label: 'Search',
      negation: false,
      plan: ['seed'],
      key: 'boolean_search',
      skipParsing: true,
    },
    SORT: {
      values: [],
      label: 'Sort',
      negation: false,
      plan: [],
      key: 'sort',
    },
    ORDER: {
      values: [],
      label: 'Order',
      negation: false,
      plan: [],
      key: 'order',
    },
    COMPANY_LOCATION_INTERNATIONAL: {
      values: [],
      label: 'Company Continent',
      negation: false,
      plan: ['seed', 'free'],
      key: 'company_location_international',
      groupId: ['LOCATION'],
    },
    COMPANY_LOCATION_REGIONS: {
      values: [],
      label: 'Company Location Region',
      negation: false,
      plan: ['seed', 'free'],
      key: 'company_location_regions',
      groupId: ['LOCATION'],
    },
  },
  leads: { data: [], total_results: 0 },
  companies: { data: [], total_results: 0 },
  checkedItems: [],
  totalSelected: null,
  isCheckedItems: false,
  selectionParam: {
    userSelection: {
      add: [],
      remove: [],
    },
    searchType: null,
    initialSize: null,
    leadsPerCompany: null,
  },
  shimmers: false,
  sort: '',
  page: 1,
  order: '',
  isAddtoListVisible: false,
  addToListItems: [],
  isLeadFilerActive: false,
  isCompanyFilterActive: false,
  isJustPageChange: false,
  isCustomSelection: false,
  totalLeads: 0,
  totalCompanies: 0,
  netNewLeads: { data: [], total_results: 0 },
  netNewCompanies: { data: [], total_results: 0 },
  uniqueCompanies: 0,
  isSaveFilterVisible: false,
  defaultInnerActiveKey: 'total',
  activePanel: '',
  innerActivePanel: '',
  dropdownId: '',
  exportDropdownVisible: false,
};

const Discover = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_TAB: {
      return {
        ...state,
        viewName: payload,
      };
    }
    case TAB_CHANGE: {
      const filters = state.viewName
        ? JSON.parse(JSON.stringify(mapSupportedFilters(state, payload)))
        : {};
      return {
        ...state,
        viewName: payload,
        ...filters,
        quickView: initialQuickView,
      };
    }

    case OPEN_QUICK_VIEW: {
      return {
        ...state,
        quickView: {
          isQuickView: true,
          viewName: payload.view,
          data: payload.data,
          isViewLoading: !!payload.loading,
          actionType: payload.actionType ?? null,
        },
      };
    }
    case CLOSE_QUICK_VIEW: {
      return {
        ...state,
        quickView: {
          isQuickView: false,
          viewName: payload.view,
          data: {},
        },
      };
    }
    case SEARCH_LEADS_SUCCESS: {
      return {
        ...state,
        leads: payload,
        shimmers: false,
        uniqueCompanies: payload?.unique_company_count ?? state.uniqueCompanies,
        totalSelected: state.totalSelected || (state.isCustomSelection ? payload?.count : 0) || 0,
        selectionParam: {
          ...state.selectionParam,
          initialSize:
            payload?.count < state.selectionParam.initialSize
              ? payload?.count
              : state.selectionParam.initialSize,
        },
        isCustomSelection: false,
      };
    }
    case UPDATE_LEAD: {
      const leads = state.leads.data.map((item, index) => {
        if (item.id.value !== action.payload.id.value) {
          return item;
        }

        return {
          ...item,
          ...action.payload,
        };
      });
      return {
        ...state,
        leads: {
          ...state.leads,
          data: leads,
        },
      };
    }
    case SEARCH_COMPANIES_SUCCESS: {
      return {
        ...state,
        companies: payload,
        shimmers: false,
      };
    }

    case NET_NEW_LEADS_SUCCESS: {
      return {
        ...state,
        netNewLeads: {
          ...payload,
          total_results: payload?.total_results ?? state.netNewLeads.total_results,
        },
        shimmers: false,
        totalSelected: state.totalSelected || (state.isCustomSelection ? payload?.count : 0) || 0,
        selectionParam: {
          ...state.selectionParam,
          initialSize:
            payload?.count < state.selectionParam.initialSize
              ? payload?.count
              : state.selectionParam.initialSize,
        },
        isCustomSelection: false,
      };
    }

    case NET_NEW_COMPANIES_SUCCESS: {
      return {
        ...state,
        netNewCompanies: {
          ...payload,
          total_results: payload?.total_results ?? state.netNewCompanies.total_results,
        },
        shimmers: false,
      };
    }

    case NET_NEW_LEADS_ERROR: {
      return {
        ...state,
        netNewLeads: { data: [], total_results: 0 },
        shimmers: false,
        isCustomSelection: false,
      };
    }

    case NET_NEW_COMPANIES_ERROR: {
      return {
        ...state,
        netNewCompanies: { data: [], total_results: 0 },
        shimmers: false,
      };
    }

    case SEARCH_LEADS_ERROR: {
      return {
        ...state,
        leads: { data: [], total_results: 0 },
        uniqueCompanies: 0,
        shimmers: false,
        isCustomSelection: false,
      };
    }
    case SEARCH_COMPANIES_ERROR: {
      return {
        ...state,
        companies: { data: [], total_results: 0 },
        shimmers: false,
      };
    }

    case SHOW_SHIMMERS: {
      return {
        ...state,
        shimmers: payload,
      };
    }
    case SET_CHECKED_ITEMS: {
      const updateState = {
        ...state,
        checkedItems: payload,
        isCheckedItems: !!payload.length,
      };

      if (!state.selectionParam.initialSize) {
        updateState.totalSelected = payload.length;
      }

      return {
        ...updateState,
      };
    }
    case CLEAR_CHECKED_ITEMS: {
      return {
        ...state,
        checkedItems: [],
        isJustPageChange: false,
        isCheckedItems: false,
        quickView: initialQuickView,
        leads: {
          ...state.leads,
          selected_ids: [],
        },
        companies: {
          ...state.companies,
          selected_ids: [],
        },
        netNewCompanies: {
          ...state.netNewCompanies,
          selected_ids: [],
        },
        netNewLeads: {
          ...state.netNewLeads,
          selected_ids: [],
        },
        totalSelected: null,
        selectionParam: {
          userSelection: {
            add: [],
            remove: [],
          },
          searchType: null,
          initialSize: null,
          leadsPerCompany: null,
        },
      };
    }
    case APPLY_FILTERS: {
      // code needs to be optimized
      const { tab, filters, isSortOrder } = payload;
      if (tab === 'lead') {
        const leadFiltersClone = { ...state.leadFilters };
        for (const key in filters) {
          if (leadFiltersClone[key] && leadFiltersClone[key].values) {
            leadFiltersClone[key].values = filters[key];
          }
        }
        return {
          ...state,
          checkedItems: [],
          isCheckedItems: false,
          totalSelected: null,
          selectionParam: {
            userSelection: {
              add: [],
              remove: [],
            },
            searchType: null,
            initialSize: null,
            leadsPerCompany: null,
          },
          leadFilters: leadFiltersClone,
          page: 1,
          isJustPageChange: isSortOrder ?? false,
          defaultInnerActiveKey: 'total',
          quickView: initialQuickView,
        };
      }

      const companyFiltersClone = { ...state.companyFilters };
      for (const key in filters) {
        if (companyFiltersClone[key] && companyFiltersClone[key].values) {
          companyFiltersClone[key].values = filters[key];
        }
      }
      return {
        ...state,
        checkedItems: [],
        isCheckedItems: false,
        totalSelected: null,
        selectionParam: {
          userSelection: {
            add: [],
            remove: [],
          },
          searchType: null,
          initialSize: null,
          leadsPerCompany: null,
        },
        companyFilters: companyFiltersClone,
        page: 1,
        isJustPageChange: isSortOrder ?? false,
        defaultInnerActiveKey: 'total',
        quickView: initialQuickView,
      };
    }

    case RESET_FILTER: {
      const { groupId, tab } = payload;
      const filters = tab === 'lead' ? state.leadFilters : state.companyFilters;
      const currentFilters = clearFilterByGroupId(groupId, filters);
      if (tab === 'lead') {
        return {
          ...state,
          leadFilters: { ...currentFilters },
          quickView: initialQuickView,
        };
      }
      return {
        ...state,
        companyFilters: { ...currentFilters },
        quickView: initialQuickView,
      };
    }

    case RESET_LEAD_FILTERS: {
      const { leadFilters } = state;
      const currentFilters = clearFilters(leadFilters);
      return {
        ...state,
        leadFilters: { ...currentFilters },
        page: 1,
        isJustPageChange: false,
        defaultInnerActiveKey: 'total',
        quickView: initialQuickView,
        selectionParam: {
          userSelection: {
            add: [],
            remove: [],
          },
          searchType: null,
          initialSize: null,
          leadsPerCompany: null,
        },
      };
    }

    case RESET_COMPANY_FILTERS: {
      const { companyFilters } = state;
      const currentFilters = clearFilters(companyFilters);
      return {
        ...state,
        companyFilters: { ...currentFilters },
        page: 1,
        isJustPageChange: false,
        defaultInnerActiveKey: 'total',
        quickView: initialQuickView,
        selectionParam: {
          userSelection: {
            add: [],
            remove: [],
          },
          searchType: null,
          initialSize: null,
          leadsPerCompany: null,
        },
      };
    }

    case TOGGLE_FILTER_VIEW: {
      return {
        ...state,
        isFilterView: payload,
      };
    }
    case OPEN_ADDTO_LIST: {
      return {
        ...state,
        isAddtoListVisible: true,
        addToListItems: { ...payload },
      };
    }

    case CLOSE_ADDTO_LIST: {
      return {
        ...state,
        isAddtoListVisible: false,
        addToListItems: { ids: [], listType: '' },
      };
    }

    case TOGGLE_LEAD_FILTERS: {
      return {
        ...state,
        isLeadFilerActive: !state.isLeadFilerActive,
      };
    }

    case TOGGLE_COMPANY_FILTERS: {
      return {
        ...state,
        isCompanyFilterActive: !state.isCompanyFilterActive,
      };
    }

    case STORE_SORT: {
      // return {
      //   ...state,
      //   sort: payload.sort,
      //   order: payload.order,
      //   checkedItems: [],
      // };
      if (state.viewName === 'lead') {
        const leadFiltersClone = { ...state.leadFilters };
        if (payload?.sort) {
          leadFiltersClone.SORT.values = [payload.sort];
        }
        if (payload?.order) {
          leadFiltersClone.ORDER.values = [payload.order];
        }
        return {
          ...state,
          leadFilters: leadFiltersClone,
          page: payload.page || 1,
          isJustPageChange: !!payload?.page,
          isCustomSelection: !!payload?.isCustomSelection,
          quickView: {
            ...state.quickView,
            isViewLoading: state.quickView.isQuickView,
          },
        };
      }
      if (state.viewName === 'company') {
        const companyFiltersClone = { ...state.companyFilters };
        if (payload?.sort) {
          companyFiltersClone.SORT.values = [payload.sort];
        }
        if (payload?.order) {
          companyFiltersClone.ORDER.values = [payload.order];
        }
        return {
          ...state,
          companyFilters: companyFiltersClone,
          page: payload.page || 1,
          isJustPageChange: !!payload?.page,
          isCustomSelection: !!payload?.isCustomSelection,
          quickView: {
            ...state.quickView,
            isViewLoading: state.quickView.isQuickView,
          },
        };
      }
      return state;
    }
    case SCROLL_AREA_EXTRA_SIZE: {
      let newHeight = {};
      if (payload.item === 'filterPreview') {
        newHeight = {
          filterPreviewHeight: payload.height,
        };
      }
      if (payload.item === 'infoStrip') {
        newHeight = {
          infoStripHeight: payload.height,
        };
      }
      return {
        ...state,
        tableExtraSize: { ...state.tableExtraSize, ...newHeight },
      };
    }

    case OPEN_SAVE_FILTER: {
      return {
        ...state,
        isSaveFilterVisible: true,
      };
    }

    case CLOSE_SAVE_FILTER: {
      return {
        ...state,
        isSaveFilterVisible: false,
      };
    }

    case DEFAULT_INNER_ACTIVE_KEY: {
      return {
        ...state,
        defaultInnerActiveKey: payload,
        page: 1,
      };
    }
    case SET_ACTIVE_PANEL: {
      const { activePanel, innerActivePanel } = payload;
      return {
        ...state,
        activePanel,
        innerActivePanel,
      };
    }
    case UPDATE_CUSTOM_SELECTIONS: {
      return {
        ...state,
        totalSelected: payload.leadsPerCompany ? 0 : payload.initialSize,
        selectionParam: {
          ...state.selectionParam,
          userSelection: {
            add: [],
            remove: [],
          },
          searchType: payload.searchType,
          initialSize: payload.initialSize,
          leadsPerCompany: payload.leadsPerCompany ? payload.leadsPerCompany : null,
        },
      };
    }

    case SET_USER_SELECTION: {
      const { ids, selected } = payload;
      let add = state?.selectionParam?.userSelection?.add || [];
      let remove = state?.selectionParam?.userSelection?.remove || [];
      const originalSelectedIds = state.checkedItems;
      ids.forEach((id) => {
        if (selected) {
          if (!originalSelectedIds.includes(id) && !add.includes(id) && !remove.includes(id)) {
            add.push(id);
          }
          remove = remove.filter((key) => key !== id);
        } else {
          if (originalSelectedIds.includes(id) && !remove.includes(id) && !add.includes(id)) {
            remove.push(id);
          }
          add = add.filter((key) => key !== id);
        }
      });

      // total count need to calculate (to be fixed)
      let totalSelectedCount = 0;
      if (state.selectionParam?.initialSize) {
        totalSelectedCount = state.selectionParam.initialSize + add.length - remove.length;
      }

      return {
        ...state,
        totalSelected: totalSelectedCount,
        selectionParam: {
          ...state.selectionParam,
          userSelection: {
            add,
            remove,
          },
        },
      };
    }

    case SET_DROPDOWN_ID: {
      return {
        ...state,
        dropdownId: payload,
      };
    }

    case SET_EXPORT_DROPDOWN_VISIBLE: {
      return {
        ...state,
        exportDropdownVisible: payload,
      };
    }

    default:
      return state;
  }
};

export default Discover;

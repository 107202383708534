/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useMemo, useEffect } from 'react';
import { Input, List, Avatar, Typography, ConfigProvider } from 'antd';
import { SearchIcon, PersonIcon, PsychographicIcon, LockedIcon } from 'UI/CustomIcon/CustomIcon';
import { getRequest } from 'api/apiRequests';
import { endPoints, baseURL } from 'api/apiEndpoints';
import { debounce, headerGenerator, getLoggedInUser, getClickableLink } from 'utils/common';
import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { eventManager, eventList } from 'utils/eventManager';
import { GLOBAL_SEARCH_CHAR_LIMIT, TYPES_MAPPER, TEXT_LABELS } from 'constants/constants';
import { APP_ROUTE_SRC } from 'constants/multimedia';
import { sanitizeSearchString } from 'utils/discovery/common';
import NoItem from './noItem';
import { showError } from './errorMessage';
import './globalSearch.scss';

const { Title, Link, Text } = Typography;

const GlobalSearch = () => {
  const planPermission = useSelector((state) => state.common.planPermission);
  const [searchTerm, setSearchTerm] = useState('');
  const [result, setResult] = useState(null);
  const [recentPages, setRecentPages] = useState(null);
  const [fieldInFocus, setFieldInFocus] = useState(false);
  const user = getLoggedInUser();
  const headers = headerGenerator(user.token, user.session_id);
  const DASHBOARD_URL = process.env.DASHBOARD_URL;

  const handleSearch = async (searchValue) => {
    if (!(searchValue && searchValue.trim().length > 0)) {
      setResult(null);
    } else {
      eventManager.track(eventList.SEARCH, {
        type: 'global',
        search_string: searchValue,
      });
      const searchString = sanitizeSearchString(searchValue.trim());
      const url = `${baseURL}${endPoints.globalSearch}`;
      const response = await getRequest(
        url,
        {
          searchTerm: encodeURIComponent(searchString),
        },
        headers
      );
      if (response.error) {
        showError(response.message);
      }
      setResult(response.data);
    }
  };

  const debounceHandleSearch = useMemo(() => debounce(handleSearch, 500), []);
  useEffect(() => {
    debounceHandleSearch(searchTerm);
  }, [searchTerm]);

  const handleRecentPages = async () => {
    const url = `${baseURL}${endPoints.recentPages}`;
    const response = await getRequest(url, { id: user.userId }, headers);
    if (response.error) {
      showError(response.message);
    }
    if (response) {
      setRecentPages(response);
    }
  };

  const trackEvent = (event, searchText, type) => {
    eventManager.track(eventList.GLOBAL_SEARCH_EVENT, {
      payload: {
        type,
        search_string: searchText,
        redirection_url: `${DASHBOARD_URL}/${TYPES_MAPPER[type]}/${event._id}`,
        id: event._id,
        event_time: new Date().getTime(),
      },
    });
  };

  const closeEntirePanel = (focus = false) => {
    setSearchTerm('');
    setResult(null);
    setRecentPages(null);
    setFieldInFocus(focus);
  };

  const closeButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    closeEntirePanel(false);
  };

  const getPlanTheme = () => {
    return planPermission && planPermission.theme ? planPermission.theme : '';
  };

  const isLocked = (key) => {
    const { globalSearch } = planPermission?.notAllowed;
    if (!globalSearch) {
      return false;
    }
    return globalSearch.includes(key);
  };

  const showLock = (key) => {
    const theme = getPlanTheme();
    const lockIconTheme = theme ? `lock-icon-${theme}` : '';
    return (
      isLocked(key) && (
        <span className="locked-icon" style={{ position: 'relative', top: '-5px' }}>
          <LockedIcon className={lockIconTheme} />
        </span>
      )
    );
  };

  const truncate = (input, limit = 20) =>
    input && input.length > limit ? `${input.substring(0, limit)}...` : input;

  return (
    <div className="global-search-container">
      <Input
        className={`global-search-input ${fieldInFocus ? 'expanded' : ''}`}
        onFocus={() => {
          handleRecentPages();
          setFieldInFocus(true);
        }}
        prefix={<SearchIcon className="search-icon" fill={fieldInFocus ? '#29275F' : '#929CB7'} />}
        value={searchTerm}
        placeholder={
          fieldInFocus ? '' : 'Search for technologies, companies, people, and psychographics'
        }
        onChange={(e) => setSearchTerm(e.target.value)}
        maxLength={GLOBAL_SEARCH_CHAR_LIMIT}
      />
      <div className="close-cross">
        <CloseOutlined
          onClick={(e) => closeButtonClick(e)}
          style={{ fontSize: 20, color: '#29275F', opacity: `${fieldInFocus ? 1 : 0}` }}
        />
      </div>

      {result && searchTerm ? (
        <div className="global-search">
          <ConfigProvider renderEmpty={() => <NoItem style={{ height: '300px' }} />}>
            <div
              className="custom-mask"
              onClick={() => {
                setSearchTerm('');
                setResult(null);
              }}
            />
            <div className="global-search__container">
              <div className="global-search__block">
                <div className="global-search__block-list">
                  <Title level={4} className="global-search__block-list-title">
                    Companies
                  </Title>
                  <List
                    itemLayout="horizontal"
                    dataSource={result.companyAutosuggestion}
                    renderItem={(item) => (
                      <List.Item style={{ border: 'none' }}>
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              size="large"
                              style={{ border: '1px solid #DFE7F0', padding: '6px' }}
                              shape="square"
                              src={getClickableLink(item._source.company_profile_image_url)}
                            />
                          }
                          title={
                            <Link
                              title={item._source.company_name}
                              onClick={() => trackEvent(item, item._source.company_name, 'company')}
                              className="global-search__block-list-link"
                              href={`${APP_ROUTE_SRC.COMPANY_DETAILS}/${item._id}`}
                            >
                              {truncate(item._source.company_name, 30)}
                            </Link>
                          }
                          description={
                            <div className="global-search__block-list-group">
                              {item._source.company_industry && (
                                <Text
                                  title={item._source.company_industry}
                                  className="global-search__block-list-desc search_result_block"
                                >
                                  <img
                                    className="search_result_icon"
                                    alt="Title"
                                    src="https://d3ml3b6vywsj0z.cloudfront.net/website/v2/icons/sl_industry_icon.svg"
                                  />
                                  {truncate(item._source.company_industry, 35)}
                                </Text>
                              )}
                              {item._source.company_website && (
                                <Text
                                  title={item._source.company_website}
                                  className="global-search__block-list-desc search_result_block"
                                >
                                  <img
                                    className="search_result_icon"
                                    alt="Title"
                                    src="https://d3ml3b6vywsj0z.cloudfront.net/website/v2/icons/sl_globe_icon.svg"
                                  />
                                  {truncate(item._source.company_website, 35)}
                                </Text>
                              )}
                            </div>
                          }
                        />
                      </List.Item>
                    )}
                  />
                  {result.companyAutosuggestion.length ? (
                    <Link
                      className="global-search__block-list-link"
                      href={`/discover/company?boolean_search=${searchTerm}`}
                    >
                      <Text strong style={{ fontSize: '14px', color: '#5e81f4' }}>
                        {`See all company results for ${searchTerm}`}
                        <RightOutlined fill="#5e81f4" style={{ marginLeft: '5px' }} />
                      </Text>
                    </Link>
                  ) : null}
                </div>
                <div className="global-search__block-list">
                  <Title level={4} className="global-search__block-list-title">
                    People
                  </Title>
                  <List
                    itemLayout="horizontal"
                    dataSource={result.leadAutosuggestion}
                    renderItem={(item) => (
                      <List.Item style={{ border: 'none' }}>
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              size="large"
                              style={{
                                border: '1px solid #DFE7F0',
                                background: '#FFFFFF',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '6px',
                              }}
                              shape="square"
                              icon={<PersonIcon fill="#929CB7" />}
                            />
                          }
                          title={
                            <Link
                              title={item._source.name_text}
                              onClick={() => trackEvent(item, item._source.name_text, 'lead')}
                              className="global-search__block-list-link"
                              href={`${APP_ROUTE_SRC.LEAD_DETAILS}/${item._id}`}
                            >
                              {truncate(item._source.name_text, 30)}
                            </Link>
                          }
                          description={
                            <div className="global-search__block-list-group">
                              {item._source.lead_titles && (
                                <Text
                                  title={item._source.lead_titles}
                                  className="global-search__block-list-desc search_result_block"
                                >
                                  <img
                                    className="search_result_icon"
                                    alt="Title"
                                    src="https://d3ml3b6vywsj0z.cloudfront.net/website/v2/icons/sl_title_icon.svg"
                                  />
                                  {truncate(item._source.lead_titles, 35)}
                                </Text>
                              )}
                              {item._source.company_website && (
                                <Text
                                  title={item._source.company_website}
                                  className="global-search__block-list-desc search_result_block"
                                >
                                  <img
                                    className="search_result_icon"
                                    alt="Website"
                                    src="https://d3ml3b6vywsj0z.cloudfront.net/website/v2/icons/sl_globe_icon.svg"
                                  />
                                  {truncate(item._source.company_website, 35)}
                                </Text>
                              )}
                            </div>
                          }
                        />
                      </List.Item>
                    )}
                  />
                  {result.leadAutosuggestion.length ? (
                    <Link
                      className="global-search__block-list-link"
                      href={`/discover/lead?boolean_search=${searchTerm}`}
                    >
                      <Text strong style={{ fontSize: '14px', color: '#5e81f4' }}>
                        {`See all people results for ${searchTerm}`}
                        <RightOutlined fill="#5e81f4" style={{ marginLeft: '5px' }} />
                      </Text>
                    </Link>
                  ) : null}
                </div>
                <div className="global-search__block-list">
                  <Title level={4} className="global-search__block-list-title">
                    Technologies {showLock('technology')}
                  </Title>
                  <List
                    itemLayout="horizontal"
                    dataSource={result.technologyAutosuggestion}
                    renderItem={(item) => (
                      <List.Item style={{ border: 'none', opacity: isLocked('keyword') ? 0.5 : 1 }}>
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              size="large"
                              style={{ border: '1px solid #DFE7F0', padding: '6px' }}
                              shape="square"
                              src={getClickableLink(item._source.company_profile_image_url)}
                            />
                          }
                          title={
                            <Link
                              type="link"
                              disabled={isLocked('technology')}
                              onClick={() =>
                                trackEvent(item, item._source.company_name, 'technology')
                              }
                              className="global-search__block-list-link"
                              href={`${APP_ROUTE_SRC.TECHNOLOGY_DETAILS}/${item._id}`}
                            >
                              {item._source.company_name}
                            </Link>
                          }
                          description={
                            <Text className="global-search__block-list-desc">
                              {`${item._source.category} > ${item._source.subcategory}`}
                            </Text>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </div>
                <div className="global-search__block-list">
                  <Title level={4} className="global-search__block-list-title">
                    Psychographics {showLock('keyword')}
                  </Title>
                  <List
                    itemLayout="horizontal"
                    dataSource={result.keywordAutosuggestion}
                    renderItem={(item) => (
                      <List.Item style={{ border: 'none', opacity: isLocked('keyword') ? 0.5 : 1 }}>
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              size="large"
                              style={{
                                border: '1px solid #DFE7F0',
                                background: '#FFFFFF',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '6px',
                              }}
                              shape="square"
                              icon={<PsychographicIcon fill="#929CB7" width={20} height={20} />}
                            />
                          }
                          title={
                            <Link
                              type="link"
                              disabled={isLocked('keyword')}
                              onClick={() =>
                                trackEvent(item, item._source.company_name, 'psychographics')
                              }
                              className="global-search__block-list-link"
                              href={`${APP_ROUTE_SRC.PSYCHOGRAPHICS_DETAILS}/${item._id}`}
                            >
                              {item._source.company_name}
                            </Link>
                          }
                          description={
                            <Text className="global-search__block-list-desc">
                              {`${item._source.category} > ${item._source.subcategory}`}
                            </Text>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </div>
            </div>
          </ConfigProvider>
        </div>
      ) : null}
      {recentPages && !searchTerm ? (
        <div className="global-search">
          <ConfigProvider
            renderEmpty={() => (
              <Title level={4} className="global-search_heading">
                Look up technology stack and people across 15M+ companies
              </Title>
            )}
          >
            <div
              className="custom-mask"
              onClick={() => {
                closeEntirePanel();
              }}
            />
            <div className="global-search__container">
              <div className="global-search__block" style={{ marginLeft: '20px' }}>
                <div className="global-search__block-list">
                  {recentPages.length !== 0 ? (
                    <Title level={4} className="global-search__block-list-title">
                      Recently visited pages
                    </Title>
                  ) : null}
                  <List
                    itemLayout="horizontal"
                    dataSource={recentPages}
                    renderItem={(item) => (
                      <List.Item style={{ border: 'none' }}>
                        <List.Item.Meta
                          title={
                            <Link
                              className="global-search__block-list-link"
                              href={item.redirection_url}
                            >
                              {item.search_string}
                            </Link>
                          }
                          description={
                            item.type !== '_doc' && (
                              <Text className="global-search__block-list-desc">
                                {TEXT_LABELS[item.type] || item.type}
                              </Text>
                            )
                          }
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </div>
            </div>
          </ConfigProvider>
        </div>
      ) : null}
    </div>
  );
};

export default GlobalSearch;

/* eslint-disable camelcase */
/* eslint-disable guard-for-in */
/* global chrome */
import React from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import {
  SORT_ORDERS,
  LEAD_SORTING_OPTIONS,
  COMPANY_SORTING_OPTIONS,
  DEFAULT_FISCAL_YEAR_MONTHS,
  DEFAULT_FUNDING_TIMELINES,
  LOCATION_INTERNATIONAL_MAPPER,
  LOCATION_REGIONS_MAPPER,
  USER_ROLES_MAP,
  LEAD,
  COMPANY,
  LEAD_SORTING_KEYS,
  COMPANY_SORTING_KEYS,
} from '../constants/constants';
import { getCookie, removeCookie } from './cookie';

const CHROME_EXTENSION_ID = process.env.CHROME_EXTENSION_ID;
export const getUniqueLocationOptions = (options = []) => {
  const PRIORITY_MAPPING = {
    COUNTRY: 0,
    STATE: 1,
    OTHERS: 2,
  };

  const groupedByText = {};
  const indexOfOption = {};
  let lastOption = 0;
  const resultantOptions = [];

  options.forEach((obj) => {
    groupedByText[obj.text] = groupedByText[obj.text] || [];

    if (indexOfOption[obj.text] === undefined) {
      // to maintain order
      indexOfOption[obj.text] = lastOption;
      lastOption += 1;
    }

    const optionObj = { ...obj, priority: PRIORITY_MAPPING.OTHERS }; // computing priority
    if (obj.country === obj.text) {
      optionObj.priority = PRIORITY_MAPPING.COUNTRY;
    } else if (obj.state === obj.text) {
      optionObj.priority = PRIORITY_MAPPING.STATE;
    }

    groupedByText[obj.text].push(optionObj);
  });

  Object.entries(groupedByText).forEach(([optionText, optionsList]) => {
    // picking the highest priority item
    const sortedOptions = optionsList.slice().sort((a, b) => (a.priority > b.priority ? 1 : -1));
    const { text, company_id: key } = sortedOptions[0];
    resultantOptions[indexOfOption[optionText]] = { label: text, value: text, key };
  });
  return resultantOptions;
};
const sortingOptions = [...LEAD_SORTING_OPTIONS, ...COMPANY_SORTING_OPTIONS];

export const isEqual = (first, second) => {
  if (first === second) {
    return true;
  }
  if (
    (first === undefined || second === undefined || first === null || second === null) &&
    (first || second)
  ) {
    return false;
  }
  const firstType = first.constructor.name;
  const secondType = second.constructor.name;
  if (firstType !== secondType) {
    return false;
  }
  if (firstType === 'Array') {
    if (first.length !== second.length) {
      return false;
    }
    let equal = true;
    for (let i = 0; i < first.length; i++) {
      if (!isEqual(first[i], second[i])) {
        equal = false;
        break;
      }
    }
    return equal;
  }
  if (firstType === 'Object') {
    let equal = true;
    const fKeys = Object.keys(first);
    const sKeys = Object.keys(second);
    if (fKeys.length !== sKeys.length) {
      return false;
    }
    for (let i = 0; i < fKeys.length; i++) {
      if (first[fKeys[i]] && second[fKeys[i]]) {
        if (first[fKeys[i]] === second[fKeys[i]]) {
          continue; // eslint-disable-line
        }
        if (
          first[fKeys[i]] &&
          (first[fKeys[i]].constructor.name === 'Array' ||
            first[fKeys[i]].constructor.name === 'Object')
        ) {
          equal = isEqual(first[fKeys[i]], second[fKeys[i]]);
          if (!equal) {
            break;
          }
        } else if (first[fKeys[i]] !== second[fKeys[i]]) {
          equal = false;
          break;
        }
      } else if ((first[fKeys[i]] && !second[fKeys[i]]) || (!first[fKeys[i]] && second[fKeys[i]])) {
        equal = false;
        break;
      }
    }
    return equal;
  }
  return first === second;
};

// get the query params
export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const formatDate = (input) => {
  const date1 = new Date(input);
  return moment(date1).format('DD MMM YYYY');
};

export const formatDateFromString = (input) => {
  return moment(input).format('DD MMM, YYYY');
};

export const getCurrentYear = () => {
  const year = moment().format('YYYY');
  return year;
};

export const formatDateWithTime = (input) => {
  return moment(input).format('h:mm A, DD MMM, YYYY');
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const redirectToOldDashboard = (url) => {
  window.location.href = url;
};

export const redirectToOldDashboardLogin = () => {
  window.location.href = `${process.env.DASHBOARD_URL}/login`;
};

export const headerGenerator = (token = '', sessionId = '') => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (token) {
    headers['x-auth-token'] = token;
  }

  if (sessionId) {
    headers.session_id = sessionId;
  }
  return headers;
};

export const generateApiUrl = (...args) => {
  return args.join('/');
};

export const logout = (isDisableLoginRedirect) => {
  const user = getCookie('userToken');
  if (user) {
    localStorage.removeItem('TechnologyCustomFiltersObj');
    localStorage.removeItem('CompanyCustomFiltersObj');
    localStorage.removeItem('LeadCustomFiltersObj');
    removeCookie('userToken');
    removeCookie('isFreeTrialExpired');
    removeCookie('isSLSuperUser');
  }
  if (!isDisableLoginRedirect) {
    redirectToOldDashboardLogin();
  }
};

export const encodeQueryData = (data) => {
  const encodedResult = [];
  for (const key in data) {
    const encodedValue = (data[key] || [])
      .map((value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
    encodedResult.push(encodedValue);
  }
  return encodedResult.join('&');
};

// Originally inspired by  David Walsh (https://davidwalsh.name/javascript-debounce-function)

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// `wait` milliseconds.
export const debounce = (func, wait) => {
  let timeout;

  // This is the function that is returned and will be executed many times
  // We spread (...args) to capture any number of parameters we want to pass
  return function executedFunction(...args) {
    // The callback function to be executed after
    // the debounce time has elapsed
    const later = () => {
      // null timeout to indicate the debounce ended
      timeout = null;

      // Execute the callback
      func(...args);
    };
    // This will reset the waiting every function execution.
    // This is the step that prevents the function from
    // being executed because it will never reach the
    // inside of the previous setTimeout
    clearTimeout(timeout);

    // Restart the debounce waiting period.
    // setTimeout returns a truthy value (it differs in web vs Node)
    timeout = setTimeout(later, wait);
  };
};

export const calculatePercentage = (num, total) => {
  return (parseInt(num, 10) / parseInt(total, 10)) * 100;
};

export const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
        const [key, value] = param.split('=');
        // eslint-disable-next-line no-param-reassign
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {})
    : {};
};

// validate if the url is valid or not
export const isValidURL = (url) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  return !!pattern.test(url);
};

export const getDomainFromEmail = (email) => {
  if (!email) {
    return null;
  }
  return email.split('@')[1];
};

export const getLoggedInUser = () => {
  const user = getCookie('userToken');
  return user && user.token ? user : null;
};

export const getEmailBucketIcon = (score) => {
  if (score === 99) {
    return 'https://d3ml3b6vywsj0z.cloudfront.net/website/v2/icons/priority_aplus.svg';
  }
  if (score >= 85 && score <= 88) {
    return 'https://d3ml3b6vywsj0z.cloudfront.net/website/v2/icons/priority_a.svg';
  }
  if (score >= 65 && score <= 75) {
    return 'https://d3ml3b6vywsj0z.cloudfront.net/website/v2/icons/priority_b.svg';
  }
  return null;
};

export const CSVImportGetHeaders = (file, cb) => {
  // Get our CSV file from upload

  // Instantiate a new FileReader
  const reader = new FileReader();

  // Read our file to an ArrayBuffer
  reader.readAsArrayBuffer(file);

  // Handler for onloadend event.
  reader.onloadend = (evt) => {
    // Get the Array Buffer
    const data = evt.target.result;

    // Grab our byte length
    const byteLength = data.byteLength;

    // Convert to conventional array, so we can iterate though it
    const ui8a = new Uint8Array(data, 0);

    // Used to store each character that makes up CSV header
    let headerString = '';

    // Iterate through each character in our Array
    for (let i = 0; i < byteLength; i++) {
      // Get the character for the current iteration
      const char = String.fromCharCode(ui8a[i]);

      // Check if the char is a new line
      if (char.match(/[^\r\n]+/g) !== null) {
        // Not a new line so lets append it to our header string and keep processing
        headerString += char;
      } else {
        // We found a new line character, stop processing
        break;
      }
    }

    const headers = headerString.split(',');
    cb(headers);
    // Split our header string into an array
  };
};

const getAllLocationMappedKeys = (key) => {
  let mapper = {};
  if (['LEAD_LOCATION_INTERNATIONAL', 'COMPANY_LOCATION_INTERNATIONAL'].includes(key)) {
    mapper = LOCATION_INTERNATIONAL_MAPPER;
  }
  if (['LEAD_LOCATION_REGIONS', 'COMPANY_LOCATION_REGIONS'].includes(key)) {
    mapper = LOCATION_REGIONS_MAPPER;
  }
  return Object.keys(mapper);
};

const getAllLocationMappedValues = (key, parentList, childList) => {
  let mapper = {};
  if (['LEAD_LOCATION_INTERNATIONAL', 'COMPANY_LOCATION_INTERNATIONAL'].includes(key)) {
    mapper = LOCATION_INTERNATIONAL_MAPPER;
  }
  if (['LEAD_LOCATION_REGIONS', 'COMPANY_LOCATION_REGIONS'].includes(key)) {
    mapper = LOCATION_REGIONS_MAPPER;
  }
  let list = childList;
  if (parentList.length > 0) {
    list = Object.keys(mapper);
  }
  const mapped = list.map((item) => mapper[item]);
  const mergedList = [].concat(...mapped);
  return mergedList;
};

const filtersMergeDuplicate = (filtersResult) => {
  const resultSet = [];
  filtersResult.forEach((item) => {
    const dupIndex = resultSet.findIndex(
      (el) => el.key === item.key && !['company_sector', 'company_function'].includes(item.key)
    );
    if (dupIndex === -1) {
      resultSet.push(item);
    } else {
      const oldQuery = resultSet[dupIndex].query;
      resultSet[dupIndex] = {
        ...resultSet[dupIndex],
        query: `${oldQuery},${item.query}`,
      };
    }
  });

  return resultSet;
};

const getSubcatAndTech = (key, item) => {
  let itemT = item.toLowerCase().split(' ').join('_').split('|').splice(1, 2).join('|');
  if (key === 'TECHNOLOGYRECENTLYSTARTED') {
    itemT += '|used';
  }
  return item.charAt(0) === '!' ? `!${itemT}` : itemT;
};

export const getSubcatAndTechSetExported = getSubcatAndTech;

export const formatFilters = (filter) => {
  const TECH_KEYWORD_CASE = ['TECHNOLOGY', 'KEYWORDS', 'TECHNOLOGYRECENTLYSTARTED'];
  const filtersResult = [];
  for (const key in filter) {
    if (filter[key] && filter[key].values && filter[key].values.length) {
      let subFilters = filter[key].values;
      if (TECH_KEYWORD_CASE.includes(key)) {
        subFilters = filter[key].values.map((item) => getSubcatAndTech(key, item));
        const finalString = subFilters
          .map((filterValue) => encodeURIComponent(filterValue))
          .join(',');
        if (finalString) {
          filtersResult.push({
            key: filter[key].key,
            query: finalString,
          });
        }
      } else if (['DEPARTMENT_AND_JOB_FUNCTION', 'SECTOR_INDUSTRY'].includes(key)) {
        const params =
          key === 'DEPARTMENT_AND_JOB_FUNCTION'
            ? { parent: 'company_function', child: 'lead_divison' }
            : { parent: 'company_sector', child: 'company_industry' };

        const finalSet = getCombinedParentAndChildSet(subFilters, params);
        filtersResult.push(...finalSet);
      } else if (
        [
          'COMPANY_LOCATION_INTERNATIONAL',
          'COMPANY_LOCATION_REGIONS',
          'LEAD_LOCATION_INTERNATIONAL',
          'LEAD_LOCATION_REGIONS',
        ].includes(key)
      ) {
        const { parentList, childList } = getParentAndChildSet(subFilters);
        let finalSet = {};
        if (['COMPANY_LOCATION_INTERNATIONAL', 'COMPANY_LOCATION_REGIONS'].includes(key)) {
          finalSet = { company_location: getAllLocationMappedValues(key, parentList, childList) };
        } else if (['LEAD_LOCATION_INTERNATIONAL', 'LEAD_LOCATION_REGIONS'].includes(key)) {
          finalSet = { lead_location: getAllLocationMappedValues(key, parentList, childList) };
        }
        for (const k in finalSet) {
          if (finalSet[k]) {
            const finalString = finalSet[k]
              .map((filterValue) => encodeURIComponent(filterValue))
              .join(',');
            if (finalString) {
              filtersResult.push({
                key: k,
                query: finalString,
              });
            }
          }
        }
      } else if (key === 'EMAIL') {
        const confidenceRange = getEmailConfidenceRange(subFilters);
        if (confidenceRange) {
          const finalString = confidenceRange
            .map((filterValue) => encodeURIComponent(filterValue))
            .join(',');
          if (finalString) {
            filtersResult.push({
              key: filter[key].key,
              query: finalString,
            });
          }
        }
      } else if (key === 'COMPANY_NAME_WEBSITE') {
        filtersResult.push({
          key: filter[key].key,
          query: subFilters.map((val) => encodeURIComponent(val.split('|')[0])).join(','),
        });
      } else if (key === 'MIN_FOUNDED_YEAR' || key === 'MAX_FOUNDED_YEAR') {
        const foundedYearParams = filtersResult.find(
          (params) => params.key === 'company_founded_at'
        );
        const query = {};
        query[key === 'MIN_FOUNDED_YEAR' ? 'from' : 'to'] = subFilters[0];
        if (foundedYearParams) {
          foundedYearParams.query = { ...foundedYearParams.query, ...query };
        } else
          filtersResult.push({
            key: 'company_founded_at',
            query,
          });
      } else if (key === 'PAST_COMPANY') {
        filtersResult.push({
          key: filter[key].key,
          query: subFilters.map((val) => encodeURIComponent(val.split('|').pop())).join(','),
        });
      } else if (key === 'MIN_JOBS_COUNT_RANGE') {
        const jobCountParams = filtersResult.find((param) => param.key === 'job_count');
        if (jobCountParams) {
          jobCountParams.query.from = subFilters[0];
        } else
          filtersResult.push({
            key: 'job_count',
            query: {
              from: subFilters[0],
            },
          });
      } else if (key === 'FUNDING_DATE_RANGE') {
        const days = {
          '15 Days': 15,
          '1 Month': 30,
          '3 Months': 90,
          '6 Months': 180,
          '1 Year': 365,
        };

        const day = days[Object.keys(days).find((item) => subFilters[0].includes(item))];
        const time = new Date(moment().subtract(day, 'd').format('YYYY-MM-DD')).getTime() / 1000;
        filtersResult.push({
          key: 'company_latest_funding_date',
          query: {
            from: subFilters[0].charAt(0) === '!' ? `!${time}` : time,
          },
        });
      } else if (key === 'MAX_JOBS_COUNT_RANGE') {
        const jobCountParams = filtersResult.find((param) => param.key === 'job_count');
        if (jobCountParams) {
          jobCountParams.query.to = subFilters[0];
        } else
          filtersResult.push({
            key: 'job_count',
            query: {
              to: subFilters[0],
            },
          });
      } else if (['ORDER', 'SORT'].includes(key)) {
        continue;
      } else {
        const finalString = subFilters
          .map((filterValue) => encodeURIComponent(filterValue))
          .join(',');
        if (finalString) {
          filtersResult.push({
            key: filter[key].key,
            query: finalString,
          });
        }
      }
    }
  }

  const mergedDuplicateFilters = filtersMergeDuplicate(filtersResult);
  return mergedDuplicateFilters;
};

const getCombinedParentAndChildSet = (values, { parent, child }) => {
  if (!Array.isArray(values)) {
    values = [values];
  }
  const parentList = {};
  values.forEach((element) => {
    const [parentValue, childValue] = element.split('|');
    if (parentValue && childValue) {
      if (!(parentValue in parentList)) {
        parentList[parentValue] = {
          key: parent,
          query: encodeURIComponent(parentValue),
          child: {
            key: child,
            query: encodeURIComponent(childValue),
          },
        };
      } else {
        parentList[parentValue].child.query += `,${encodeURIComponent(childValue)}`;
      }
    } else if (parentValue && !('onlyParents' in parentList)) {
      parentList.onlyParents = {
        key: parent,
        query: encodeURIComponent(parentValue),
      };
    } else if (parentValue) {
      parentList.onlyParents.query += `,${encodeURIComponent(parentValue)}`;
    }
  });
  return Object.values(parentList);
};

const getParentAndChildSet = (values) => {
  if (!Array.isArray(values)) {
    values = [values];
  }
  const parentList = [];
  const childList = [];
  for (let i = 0; i < values.length; i++) {
    const splitedValue = values[i].split('|');
    if (splitedValue.length && splitedValue.length === 2) {
      // if (!parentList.includes(splitedValue[0])) {
      //   parentList.push(splitedValue[0]);
      // }
      childList.push(splitedValue[1]);
    } else {
      parentList.push(splitedValue[0]);
    }
  }
  return { parentList, childList };
};

export const getParentAndChildSetExported = getParentAndChildSet;

const getEmailConfidenceRange = (emailPreferences) => {
  const EMAIL_CONFIDENCE_RANGES = {
    1: ['RANGE_A_PLUS'], //
    2: ['RANGE_A'], //
    3: ['RANGE_B'], //
  };

  let ranges = emailPreferences;
  const confidenceRanges = [];
  /// ///////////////////////////////////
  /// /// JUST FOR THE DEVELOPMENT //////
  /// /// REMOVE BEFORE DEPLOYMENT //////
  if (!Array.isArray(ranges)) {
    ranges = [emailPreferences];
  }
  /// ///////////////////////////////////
  // add all email confidence scores
  for (const confRange of ranges) {
    if (confRange && confRange in EMAIL_CONFIDENCE_RANGES) {
      confidenceRanges.push(...EMAIL_CONFIDENCE_RANGES[confRange]);
    }
  }
  // asc order sort
  if (confidenceRanges.length > 0) {
    confidenceRanges.sort((a, b) => a - b);
  }
  return confidenceRanges;
};

export const getPlanConfig = () => {
  const userInfo = getLoggedInUser();
  let total = 50;
  const pageSize = 25;
  if (userInfo?.userRole === USER_ROLES_MAP.PROFESSIONAL) {
    total = 2500;
  }
  if (userInfo?.userRole === USER_ROLES_MAP.SEED) {
    total = 250;
  }
  return {
    total,
    pageSize,
  };
};

export const hasFilters = (filter) =>
  !!Object.values(filter).some((item) => Object.keys(item.values).length);

export const hasValueinGroupFilters = (filter, groupId) =>
  !!Object.values(filter).some((item) => {
    return item?.groupId && item.groupId.includes(groupId) && Object.keys(item.values).length;
  });

export const mapSupportedFilters = (state, tabValue) => {
  if (tabValue === 'lead') {
    const cloneCompanyState1 = JSON.parse(JSON.stringify(state.companyFilters));
    const cloneLeadState1 = resetFilters(JSON.parse(JSON.stringify(state.leadFilters)));
    for (const key in cloneCompanyState1) {
      if (['SORT', 'ORDER'].includes(key)) {
        continue;
      }
      if (cloneCompanyState1[key] && cloneLeadState1[key]) {
        cloneLeadState1[key].values = cloneCompanyState1[key].values;
        // cloneCompanyState[key].values = [];
      }
    }
    return { leadFilters: cloneLeadState1 };
    // return { leadFilters: cloneLeadState, companyFilters: cloneCompanyState };
  }
  const cloneLeadState = JSON.parse(JSON.stringify(state.leadFilters));
  const cloneCompanyState = resetFilters(JSON.parse(JSON.stringify(state.companyFilters)));
  for (const key in cloneLeadState) {
    if (['SORT', 'ORDER'].includes(key)) {
      continue;
    }
    if (cloneLeadState[key] && cloneCompanyState[key]) {
      cloneCompanyState[key].values = cloneLeadState[key].values;
      // cloneLeadState[key].values = [];
    }
  }
  return { companyFilters: cloneCompanyState };
};

export const resetFilters = (filter) => {
  const returnFilter = filter;
  for (const key in filter) {
    if (filter[key]) {
      if (!returnFilter[key]) {
        returnFilter[key] = {};
      }
      returnFilter[key].values = [];
    }
  }
  return returnFilter;
};

export const clearFilters = (filters) => {
  const clearedFilters = { ...filters };
  for (const filterKey in clearedFilters) {
    clearedFilters[filterKey].values = [];
  }
  return clearedFilters;
};

export const clearFilterByGroupId = (groupId, filters) => {
  const currentFilters = { ...filters };
  for (const filterKey in currentFilters) {
    if (currentFilters[filterKey].groupId && currentFilters[filterKey].groupId.includes(groupId)) {
      currentFilters[filterKey].values = [];
    }
  }
  return currentFilters;
};

export const getFilterCount = (filter) => {
  return Object.values(filter).reduce((acc, item) => {
    return acc + item.values.length;
  }, 0);
};

export const createDummyData = (obj) => {
  return new Array(15).fill(obj).map((item, index) => {
    item.id = index + Math.random();
    return item;
  });
};

export const getInitials = (value) => {
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
  let initials = [...value.matchAll(rgx)] || [];
  initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
  return initials;
};

export const mapFilterKeys = (filter, savedFilters) => {
  const filterKeyMapper = Object.entries(filter).reduce((acc, entry) => {
    const [filterKey, filterObject] = entry;
    const mapper = {
      [filterKey]: savedFilters[filterObject.key] || [],
    };
    if (filterKey === 'COMPANY_NAME_WEBSITE' && 'company_name' in savedFilters) {
      mapper.COMPANY_NAME_WEBSITE = savedFilters[filterObject.key].map(
        (value, index) => `${value}|${savedFilters.company_name[index]}`
      );
    }
    return { ...acc, ...mapper };
  }, {});
  return filterKeyMapper;
};

export const getFilterLabelMappings = (key, value) => {
  switch (key) {
    case 'email_score': {
      const mapping = {
        3: 'B',
        2: 'A',
        1: 'A+',
      };
      return mapping[value];
    }
    case 'has_phone_number':
      return value ? 'True' : '';
    case 'has_leads':
      return value ? 'True' : '';
    case 'order':
      return SORT_ORDERS && SORT_ORDERS.find((el) => el.key === value)
        ? SORT_ORDERS.find((el) => el.key === value).label
        : '';
    case 'sort':
      return sortingOptions && sortingOptions.find((el) => el.key === value)
        ? sortingOptions.find((el) => el.key === value).label
        : '';
    default:
      return value;
  }
};

export const getClickableLink = (link) => {
  return link && (link.startsWith('http://') || link.startsWith('https://')) ? link : `//${link}`;
};

export const arrayToCommaSeperatedString = (value) => {
  return value.filter((item) => !!item).join(', ');
};

export const mapMetadata = (data, info) => {
  const mapper = (item) => {
    item.emailNotAvailable = false;
    item.phoneNotAvailable = false;
    if (info.emails.includes(item.id.value)) {
      item.emailNotAvailable = true;
    }
    if (info.phoneNumbers.includes(item.id.value)) {
      item.phoneNotAvailable = true;
    }

    if (item.email_score && item.email_score.value) {
      item.emailScore =
        // eslint-disable-next-line no-nested-ternary
        item.email_score.value === 99 ? 'A+' : item.email_score.value >= 85 ? 'A' : 'B';
    }
    return item;
  };
  return data.map(mapper);
};

export const formatNumberWithComma = (number) => {
  const internationalNumberFormat = new Intl.NumberFormat('en-US');
  return internationalNumberFormat.format(number);
};

/**
 *
 * @param {*} plan Basically the role of to identify its plan
 * @param {*} casing Boolean to request casing for display
 * @returns
 */
export const getPlanNamesByRole = (plan, casing) => {
  const planNames = {
    11: 'seed',
    10: 'free',
    9: 'pro',
  };
  return casing ? planNames[plan]?.toUpperCase() : planNames[plan];
};

export const openChromeExtension = (currentTab) => {
  if (chrome.runtime && chrome.runtime.sendMessage) {
    chrome.runtime.sendMessage(process.env.CHROME_EXTENSION_ID, { openApp: true }, (response) => {
      const lastError = chrome.runtime.lastError;
      if (lastError) {
        window.location.href = `${process.env.DASHBOARD_URL}/free-leads-extension`;
      }
    });
    const url = process.env.DEFAULT_LINKEDIN_URL;
    if (currentTab) {
      window.location.replace(url);
    } else {
      window.open(url, '_blank');
    }
  } else {
    window.location.href = `${process.env.DASHBOARD_URL}/free-leads-extension`;
  }
};

export const getSeconds = () => {
  return parseInt(Date.now() / 1000, 10);
};

export const mergeDedupe = (arr1, arr2) => {
  return [...new Set([...arr1].concat(...arr2))];
};

const hasFilterKey = (params, key) => params.filters?.findIndex((map) => map.key === key) > -1;

export const updateSortingForSearch = (params, type) => {
  let { sort, ...param } = params;
  if (sort && Array.isArray(sort) && sort.length > 0) {
    sort = sort.filter((sorting) => {
      // remove keys not present in sort of lead/company
      const isKeyNotMostRelevant = sorting.key !== 'Most Relevant';
      if (type === LEAD) {
        return isKeyNotMostRelevant && LEAD_SORTING_KEYS.includes(sorting.key);
      }
      if (type === COMPANY) {
        return isKeyNotMostRelevant && COMPANY_SORTING_KEYS.includes(sorting.key);
      }
      return isKeyNotMostRelevant;
    });
    if (sort.length === 1 && params.filters.length) {
      if (type === 'lead') sort.push({ key: 'lead_score', order: 'desc' });
    } else if (!hasFilterKey(params, 'boolean_search') && params?.filters?.length) {
      sort =
        type === 'lead'
          ? [
              { key: 'email_score', order: 'desc' },
              { key: 'lead_score', order: 'desc' },
            ]
          : [{ key: 'lead_count', order: 'desc' }];
    }
    if (sort.length > 0) {
      param = { ...param, sort };
    }
    return param;
  }
  return params;
};

export const modifyStaticFilters = (filters) => {
  filters.FUNDING_TIMELINE = DEFAULT_FUNDING_TIMELINES;
  const createOption = (value) => {
    return { label: value, value };
  };
  if (filters.TECHNOLOGY_RS_TIMELINE) {
    filters.TECHNOLOGY_RS_TIMELINE = filters.TECHNOLOGY_RS_TIMELINE.map(createOption);
  }
  if (filters.REVENUE_RANGE) {
    filters.REVENUE_RANGE = filters.REVENUE_RANGE.map(createOption);
  }
  if (filters.FUNDING_ROUND_TYPE_RANGE) {
    filters.FUNDING_ROUND_TYPE_RANGE = filters.FUNDING_ROUND_TYPE_RANGE.map(createOption);
  }
  if (filters.FISCAL_YEAR) {
    filters.FISCAL_YEAR = filters.FISCAL_YEAR.map(createOption);

    const months = DEFAULT_FISCAL_YEAR_MONTHS;
    filters.FISCAL_YEAR = filters.FISCAL_YEAR.sort((a, b) => {
      return months.indexOf(a.label.split('-')[0]) - months.indexOf(b.label.split('-')[0]);
    });
  }
  if (filters.MIN_JOB_COUNT) {
    filters.MIN_JOB_COUNT = filters.MIN_JOB_COUNT.map(createOption);
  }
  if (filters.MAX_JOB_COUNT) {
    filters.MAX_JOB_COUNT = filters.MAX_JOB_COUNT.map(createOption);
  }

  if (filters.LEAD_DIVISON_DATA) {
    const other = filters.LEAD_DIVISON_DATA.Other;
    delete filters.LEAD_DIVISON_DATA.Other;
    const sortable = Object.entries(filters.LEAD_DIVISON_DATA)
      .sort(([a, x], [b, y]) => {
        return a > b ? 1 : -1;
      })
      .reduce((r, [k, v]) => ({ ...r, [k]: v.sort() }), {});

    sortable.Other = other;
    filters.LEAD_DIVISON_DATA = sortable;
  }
  return filters;
};

export const getFiltersForLastActiveTab = (lastActive, currentFilter) => {
  return Object.keys(lastActive)
    .filter((filterKey) => {
      return lastActive[filterKey].values.length > 0 && !currentFilter[filterKey];
    })
    .map((filterKey) => ({
      key: filterKey,
      values: lastActive[filterKey].values.map((value) =>
        getFilterLabelMappings(lastActive[filterKey].key, value)
      ),
      searchKey: lastActive[filterKey].key,
    }));
};

export const getParagraphWithSafeHTML = (message) => {
  const jsStrippedHTML = new DOMParser().parseFromString(message || '', 'text/html');
  if (jsStrippedHTML.body.innerHTML && jsStrippedHTML.body.getElementsByTagName('a')[0]) {
    const { innerHTML } = jsStrippedHTML.body.getElementsByTagName('a')[0];
    jsStrippedHTML.body.getElementsByTagName('a')[0].innerText = innerHTML;
  }
  const html = { __html: jsStrippedHTML.body.innerHTML };
  return <p dangerouslySetInnerHTML={html} />;
};

export const generateLocation = (filter) => {
  let locationList = [];
  if (filter.locations && filter.locations.length) {
    locationList = locationList.concat(filter.locations);
  }
  if (filter.company_location_international && filter.company_location_international.length) {
    const { parentList, childList } = getParentAndChildSet(filter.company_location_international);
    if (parentList && parentList.length) {
      const mappedKeys = getAllLocationMappedKeys('COMPANY_LOCATION_INTERNATIONAL');
      locationList = locationList.concat(
        getAllLocationMappedValues('COMPANY_LOCATION_INTERNATIONAL', mappedKeys)
      );
    }
    if (childList && childList.length) {
      locationList = locationList.concat(
        getAllLocationMappedValues('COMPANY_LOCATION_INTERNATIONAL', childList)
      );
    }
  }
  if (filter.company_location_regions && filter.company_location_regions.length) {
    const { parentList, childList } = getParentAndChildSet(filter.company_location_regions);
    if (parentList && parentList.length) {
      const mappedKeys = getAllLocationMappedKeys('COMPANY_LOCATION_REGIONS');
      locationList = locationList.concat(
        getAllLocationMappedValues('COMPANY_LOCATION_REGIONS', mappedKeys)
      );
    }
    if (childList && childList.length) {
      locationList = locationList.concat(
        getAllLocationMappedValues('COMPANY_LOCATION_REGIONS', childList)
      );
    }
  }
  return locationList;
};

export const getEmailScoreValue = (emailScore) => {
  let value = emailScore >= 85 ? 'A' : 'B';
  value = emailScore === 99 ? 'A+' : value;
  return value;
};

export const separateString = (text = '', textSeparator) => {
  return text.split(textSeparator).map((subText) => subText.trim());
};

export const checkNotificationIsFromCE = ({ from_ce, exportType = '' }) => {
  const type = exportType.toLowerCase();
  if (
    (type !== 'hubspot' && from_ce) ||
    type === 'outreach' ||
    (type === 'hubspot' && from_ce === 'True')
  ) {
    return true;
  }
  return false;
};

export const objectFilter = (obj, predicate) => {
  return Object.fromEntries(Object.entries(obj).filter(predicate));
};

const currentYear = new Date().getFullYear();
export const getYearList = (maxYear = currentYear, minYear = 1900) => {
  const age = maxYear - minYear + 1;
  return Array.from(new Array(age), (_, i) => ({
    value: maxYear - i,
    label: `${maxYear - i}`,
  }));
};

export const getDate = (value) => {
  if (value.toString().length === 10) {
    value *= 1000;
  }
  const date = new Date(value);
  const resDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  return resDate;
};

export const getDateWithStandardFormat = (value) => {
  if (value.toString().length === 10) {
    value *= 1000;
  }
  return formatDateFromString(new Date(value));
};

export const getValueWithUnit = (value) => {
  const absoluteValue = Math.abs(Number(value));
  return absoluteValue >= 1e9
    ? `${absoluteValue / 1e9} B`
    : absoluteValue >= 1e6
    ? `${absoluteValue / 1e6} M`
    : absoluteValue >= 1e3
    ? `${absoluteValue / 1e3} K`
    : absoluteValue;
};

export const chromeSendMessage = (data) => {
  if (chrome?.runtime?.sendMessage) {
    chrome.runtime.sendMessage(CHROME_EXTENSION_ID, { data });
  }
};
export const getDiffInDays = (startDate) => {
  const mSecs = 1000;
  const fromDate = new Date(startDate * mSecs);
  const todayDate = new Date();
  const diffInMs = todayDate - fromDate;
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  return diffInDays;
};

export const formatFiltersForFetchingList = (listFilters, inputFilters) => {
  if (!inputFilters) {
    return {};
  }

  if (inputFilters.company_function || inputFilters.lead_divison) {
    const functionUniqueList = getUniqueListFormatFiltersList(
      listFilters.company_function_division
    );
    const finalList = getCombinedParentAndChildSet(functionUniqueList, {
      parent: 'company_function',
      child: 'lead_divison',
    });
    delete inputFilters.company_function;
    delete inputFilters.lead_divison;
    inputFilters.company_function_division = finalList;
  }
  if (inputFilters.company_sector || inputFilters.company_industry) {
    const functionUniqueList = getUniqueListFormatFiltersList(listFilters.company_sector_industry);
    const finalList = getCombinedParentAndChildSet(functionUniqueList, {
      parent: 'company_sector',
      child: 'company_industry',
    });
    delete inputFilters.company_sector;
    delete inputFilters.company_industry;
    inputFilters.company_sector_industry = finalList;
  }

  return inputFilters;
};

const getUniqueListFormatFiltersList = (list) => {
  const parentList = list.filter((item) => !item.includes('|') && item);

  const finalList = list.filter((item) => {
    const secItem = item.split('|')[0];
    if (
      (!item.includes('|') && parentList.includes(secItem)) ||
      (!parentList.includes(secItem) && item.includes('|'))
    ) {
      return true;
    }
    return false;
  });
  return finalList;
};

export const findIntegrationElementIndex = (array = [], record) => {
  const pKey = record.id ? 'id' : 'key';
  return array.findIndex((el) => el[pKey] === record[pKey]);
};

export const removeKeysFromURLSearchParams = (href, removedFilters = []) => {
  try {
    const url = new URL(href);
    if (url.searchParams.has('sort')) {
      url.searchParams.delete('sort');
    }
    if (url.searchParams.has('order')) {
      url.searchParams.delete('order');
    }
    removedFilters.forEach((removedFilter) => {
      if (url.searchParams.has(removedFilter.searchKey)) {
        url.searchParams.delete(removedFilter.searchKey);
      }
    });
    return url.search;
  } catch (ex) {
    return '';
  }
};

export const isCopyEnabled = () => {
  try {
    const { userEmail } = getLoggedInUser();
    const userDomain = getDomainFromEmail(userEmail);
    const domains = process.env.CPY_DOMAINS_LIST || [];
    return domains.includes(userDomain.trim().toLowerCase());
  } catch (ex) {
    return false;
  }
};

import React, { useEffect, useState } from 'react';
import { Collapse, Input, Select, Slider, InputNumber } from 'antd';
import './CollapseCard.scss';
import * as PropTypes from 'prop-types';
import { SearchIcon } from 'UI/CustomIcon/CustomIcon';
import Combobox from '../Combobox';

const { Panel } = Collapse;
const formatter = (value) => {
  return `${value}%`;
};

const getCount = (data) => {
  return data.reduce((acc, item) => {
    return acc + item.content.length;
  }, 0);
};

const CollapseCard = ({
  data = [],
  defaultActiveKey,
  className,
  activeKey,
  onSearch,
  emptyContent,
  expandIconPosition,
  collapseClassName,
  searchValue,
  handleSourceChange,
  sourceValues,
  sourceOptions,
  handleLastDetectedChange,
  lastDetectedOptions,
  lastDetectedValue,
  isDetailView,
  onSliderChange,
  sliderValue,
  statusOptions = null,
  currentStatus,
  handleStatus,
  sliderTitle,
  total,
  title,
}) => {
  const [active, setActive] = useState(activeKey);

  useEffect(() => {
    if (isDetailView) {
      // TODO: do nothing skip for lead/company details
    } else if (searchValue) {
      setActive(data.map((el) => el.key));
    } else {
      setActive(activeKey);
    }
  }, [data]);

  const onCollapseChange = (e) => {
    setActive(e);
  };

  return (
    <div className={className}>
      <div className="collapse-search">
        <div>
          {isDetailView && (
            <div className="pb-2">
              {title === 'TECHNOLOGY STACK' ? 'Search Technologies' : 'Search Psychographics'}
            </div>
          )}
          <Input
            className="mt-1"
            onChange={onSearch}
            value={searchValue}
            allowClear
            placeholder={
              title === 'TECHNOLOGY STACK' ? 'Search Technologies' : 'Search Psychographics'
            }
            prefix={<SearchIcon height="16px" width="16px" fill="#697390" />}
          />
        </div>
        {isDetailView && (
          <>
            <div>
              <div className="pb-2">{sliderTitle}</div>
              <Slider
                range
                value={sliderValue}
                onChange={onSliderChange}
                tooltipPlacement="bottom"
                tipFormatter={formatter}
                handleStyle={{
                  color: '#4A6EE5',
                  background: '#4A6EE5',
                  boxShadow: 'none',
                  borderColor: '#4A6EE5',
                }}
                trackStyle={{ background: '#4A6EE5', color: '#4A6EE5' }}
              />
              <div className="d-flex align-items-center">
                <InputNumber
                  min={0}
                  max={100}
                  style={{ margin: '0 16px' }}
                  value={sliderValue[0]}
                  onChange={(val) => {
                    onSliderChange([val, sliderValue[1]]);
                  }}
                />
                <div> to </div>
                <InputNumber
                  max={100}
                  min={0}
                  style={{ margin: '0 16px' }}
                  value={sliderValue[1]}
                  onChange={(val) => {
                    onSliderChange([sliderValue[0], val]);
                  }}
                />
              </div>
            </div>
            <div>
              <div className="pb-2">Source</div>
              <Combobox
                textSeparator=","
                staticOptions
                mode="multiple"
                onChange={handleSourceChange}
                value={sourceValues}
                options={sourceOptions}
                hasNegation={false}
              />
            </div>
            <div>
              <div className="pb-2">
                {title === 'TECHNOLOGY STACK' ? 'Technology' : 'Psychographics'} Detected Within{' '}
              </div>
              <Select
                getPopupContainer={(trigger) => (trigger ? trigger.parentElement : trigger)}
                className="mt-1"
                style={{ width: '100%' }}
                onChange={handleLastDetectedChange}
                options={lastDetectedOptions}
                value={lastDetectedValue}
              />
            </div>

            {statusOptions && (
              <div>
                <div className="pb-2">
                  {title === 'TECHNOLOGY STACK' ? 'Technology' : 'Psychographics'} Status
                </div>
                <Select
                  getPopupContainer={(trigger) => (trigger ? trigger.parentElement : trigger)}
                  style={{ width: '100%' }}
                  onChange={handleStatus}
                  options={statusOptions}
                  value={currentStatus}
                />
              </div>
            )}
          </>
        )}
      </div>
      {isDetailView && getCount(data) > 0 && (
        <div className="pb-3">
          Showing {getCount(data)} out of {total}{' '}
          {title === 'TECHNOLOGY STACK' ? 'Technologies' : 'Psychographics'}{' '}
        </div>
      )}
      <Collapse
        defaultActiveKey={defaultActiveKey}
        activeKey={active}
        expandIconPosition={expandIconPosition}
        onChange={onCollapseChange}
        className={!data.length ? 'empty-content' : collapseClassName}
      >
        {data.length
          ? data.map((el) => (
              <Panel
                header={
                  <>
                    {el.header} <span className="count-text"> ({el.content.length})</span>
                  </>
                }
                key={el.key}
              >
                {el.content}
              </Panel>
            ))
          : emptyContent}
      </Collapse>
    </div>
  );
};

CollapseCard.propTypes = {
  data: PropTypes.array,
  defaultActiveKey: PropTypes.array,
  activeKey: PropTypes.array,
  sourceOptions: PropTypes.array,
  className: PropTypes.string,
  collapseClassName: PropTypes.string,
  onSearch: PropTypes.func,
  expandIconPosition: PropTypes.string,
  searchValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  emptyContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

CollapseCard.defaultProps = {
  defaultActiveKey: [],
  className: 'collapse-card',
  expandIconPosition: 'right',
  collapseClassName: '',
  searchValue: '',
  activeKey: [],
  sourceOptions: [],
};

export default React.memo(CollapseCard);

import { NEWS_INIT, NEWS_SUCCESS, NEWS_FEED_RESET, NEWS_FAILURE } from 'store/actionTypes';

const initialState = {
  newsList: [],
  total: 0,
  errorMessage: '',
  responseTime: Date.now(),
};

const news = (state = initialState, action) => {
  switch (action.type) {
    case NEWS_INIT:
      return {
        ...state,
      };
    case NEWS_SUCCESS: {
      const {
        data: { newsFeeds, isFilterChange },
      } = action;
      let updatedNewsList = [];
      if (isFilterChange) {
        updatedNewsList = newsFeeds.data;
      } else {
        updatedNewsList = [...state.newsList, ...newsFeeds.data];
      }
      return {
        ...state,
        newsList: updatedNewsList,
        total: newsFeeds.total,
        responseTime: Date.now(),
        errorMessage: '',
      };
    }
    case NEWS_FAILURE: {
      const { error } = action;
      return {
        ...state,
        errorMessage: error,
        responseTime: Date.now(),
      };
    }
    case NEWS_FEED_RESET: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default news;

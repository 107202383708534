import { put, takeLatest, call, debounce } from 'redux-saga/effects';
import {
  LISTS_INIT,
  LISTS_DEBOUNCE_INIT,
  LIST_DELETE,
  LISTS_CREATE,
  FETCH_LIST_ITEMS,
  ADDTO_LIST,
  ADD_TO_LIST_JOBSPAGE,
} from 'store/actionTypes';
import { getRequest, deleteRequest, postRequest } from 'api/apiRequests';
import { ListService } from 'api/services';
import { baseURL, endPoints } from 'api/apiEndpoints';
import { closeAddtoList } from 'containers/discovery/discoveryActions';
import {
  smartListCreateSuccess,
  smartListSearchSuccess,
  smartListFailure,
  deleteListByIdSuccess,
  listItemsFailure,
  listItemsSuccess,
  initListItemFetch,
  addtoListStart,
  addtoListComplete,
} from './smartListActions';

function* fetchSmartLists(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.lists}`;
    const leadLists = yield call(getRequest, url, params, headers);
    if (!leadLists.error) {
      yield put(smartListSearchSuccess(leadLists));
    } else {
      yield put(smartListFailure(leadLists.message));
    }
  } catch (err) {
    yield put(smartListFailure(err.message));
  }
}

function* deleteSmartListById(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.lists}/${params.id}`;
    const response = yield call(deleteRequest, url, {}, headers);
    if (!response.error) {
      yield put(deleteListByIdSuccess(response));
    } else {
      yield put(smartListFailure(response.message));
    }
  } catch (err) {
    yield put(smartListFailure(err.message));
  }
}

function* createLeadList(action) {
  try {
    const { params, headers } = action.payload;
    const { history, ...requestParams } = params;
    const url = `${baseURL}${endPoints.lists}`;
    const response = yield call(postRequest, url, requestParams, headers);
    if (!response.error) {
      yield put(smartListCreateSuccess(response));
    } else {
      yield put(smartListFailure(response.message));
    }
  } catch (err) {
    yield put(smartListFailure(err.message));
  }
}

function* fetchListItems(action) {
  try {
    const { listId, params, headers } = action.payload;
    yield put(initListItemFetch());
    const url = `${baseURL}${endPoints.lists}/${listId}/items`;
    const response = yield call(postRequest, url, params, headers);
    if (!response.error) {
      const listItems = response;
      yield put(listItemsSuccess(listItems));
      return listItems;
    }
    const errorMessage = response.message;
    yield put(listItemsFailure());
    return errorMessage;
  } catch (err) {
    yield put(listItemsFailure(err.message));
  }
  return action;
}

function* addItemsToList(action) {
  try {
    const { params } = action.payload;
    yield put(addtoListStart());
    const response = yield call(ListService.addItemsToList, params); // Headers are not passed as service have this already
    yield put(addtoListComplete());
    if (!response.error) {
      yield put(closeAddtoList());
    }
    const errorMessage = response.message;
    yield put(addtoListComplete({ type: 'ERROR', message: errorMessage }));
  } catch (err) {
    yield put(addtoListComplete({ type: 'ERROR', message: err.message }));
  }
  return action;
}

function* addItemsToListJobsPage(action) {
  try {
    const { params } = action.payload;
    yield put(addtoListStart());
    const response = yield call(ListService.addToSmartListJobsPage, params); // Headers are not passed as service have this already
    yield put(addtoListComplete());
    if (!response.error) {
      yield put(closeAddtoList());
    }
    const errorMessage = response.message;
    yield put(addtoListComplete({ type: 'ERROR', message: errorMessage }));
  } catch (err) {
    yield put(addtoListComplete({ type: 'ERROR', message: err.message }));
  }
  return action;
}

export function* createLeadListSaga() {
  yield takeLatest(LISTS_CREATE, createLeadList);
}

export function* getListsSaga() {
  yield debounce(500, LISTS_INIT, fetchSmartLists);
}

export function* deleteSmartListSaga() {
  yield takeLatest(LIST_DELETE, deleteSmartListById);
}

export function* searchListsSaga() {
  yield debounce(1000, LISTS_DEBOUNCE_INIT, fetchSmartLists);
}

export function* fetchListItemsSaga() {
  yield takeLatest(FETCH_LIST_ITEMS, fetchListItems);
}

export function* addEntitiesTOList() {
  yield takeLatest(ADDTO_LIST, addItemsToList);
}

export function* addEntitiesToListFromJobPage() {
  yield takeLatest(ADD_TO_LIST_JOBSPAGE, addItemsToListJobsPage);
}

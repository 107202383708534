/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-unresolved */

import { generateLocation, getSubcatAndTechSetExported } from '../../../../../utils/common';

/**
 * ADAPTER
 * @param {Object} list
 */
export function _adapter_listsGetAPI_listsObject(list, type) {
  return {
    count: list.count,
    rows: list.rows,
    type,
  };
}

/**
 * ADAPTER - Add Bulk Items To List API
 * @param {Object} preferences User Preferences
 * @param {Array} subCategories Subcategories
 * @param {Number} timeFrom Unix Timestamp
 */
export function _adapter_addIntentBulkItemsToList(
  preferences,
  subCategories = [],
  timeFrom = null,
  type
) {
  let payload = {
    ...preferences,
    subCategories: subCategories.reduce((agg, v) => {
      if (v.label && v.label.toLowerCase() !== 'all') {
        agg.push(v.label);
      }
      return agg;
    }, []),
    locations: generateLocation(preferences),
    sizes: preferences.sizes,
    sectors: preferences.sectors,
    createdAt: timeFrom,
    keywords:
      type === 'filter' &&
      preferences.keywords &&
      preferences.keywords.map((v) => getSubcatAndTechSetExported('KEYWORDS', v.original)),
    techAdds:
      type === 'filter' &&
      preferences.techAdds &&
      preferences.techAdds.map((v) =>
        getSubcatAndTechSetExported('TECHNOLOGYRECENTLYSTARTED', v.original)
      ),
    techDrops:
      type === 'filter' && preferences.techDrops && preferences.techDrops.map((v) => v.original),
    technologies:
      type === 'filter' &&
      preferences.technologies &&
      preferences.technologies.map((v) => getSubcatAndTechSetExported('TECHNOLOGY', v.original)),
    filterType: type,
    latestFundingDate: preferences.latestFundingDate?.length
      ? preferences.latestFundingDate[0].replace(/Last |Within |/gi, () => {
          return '';
        })
      : [],
  };
  payload = removeKeysAddToList(payload);
  return payload;
}

const removeKeysAddToList = (payload) => {
  delete payload.company_location_international;
  delete payload.company_location_regions;
  return payload;
};

/* eslint-disable react/prop-types */
import React, { useState, useMemo } from 'react';
// import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import './CheckboxList.scss';

const CheckBoxList = ({ options, searchTerm, onChange, checkedList, className }) => {
  const [plainOptions] = useState(options || []);

  // useEffect(() => {
  //   if (options && options.length !== plainOptions.length) {
  //     setOptions(options);
  //   }
  // }, [options]);

  const filterdValues = useMemo(() => {
    return plainOptions.filter(
      (val) => val.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1
    );
  }, [searchTerm, plainOptions]);

  return (
    <>
      <Checkbox.Group
        className={className}
        options={filterdValues}
        onChange={(checked) => onChange(checked, filterdValues, plainOptions)}
        value={checkedList}
      />
    </>
  );
};

export default React.memo(
  CheckBoxList,
  (prevProps, nextProps) => prevProps.options.length !== nextProps.options.length
);
